<script lang="ts" setup>
import { useGetApiVideos } from '@/apis/streamladder-api/videos/videos'
import { computed, ref } from 'vue'
import { range } from 'lodash-es'
import VideoPreview from '@/areas/dashboard/components/ResultVideos/ResultVideoPreview.vue'
import GetQrCode from '@/components/ResultPage/GetQrCode.vue'
import { type VideoResultDto } from '@/apis/streamladder-api/model'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { useFilterVideos, videosFilter } from '@/Hooks/useFilterVideos'
import IconSaxVideo from '@/components/Icons/iconsax/IconSaxVideo.vue'
import { useGuard, canGuard } from '@/Hooks/useGuard'
import { upgradeDialog } from '@/helpers/upgradeDialog'
import { useIsIntersecting } from '@/areas/dashboard/hooks/useIsIntersecting'
import logging from '@/logging'
import NoContentClipsSection from '@/areas/dashboard/components/NoContentClipsSection.vue'
import { watchImmediate } from '@vueuse/core'

const emit = defineEmits<{
  (name: 'hasVideos', value: boolean): void
}>()

const props = defineProps<{ singleRow?: boolean }>()

const container = ref<HTMLDivElement>()
const {
  data,
  isLoading: isLoadingVideos,
  dataUpdatedAt,
} = useGetApiVideos(undefined,{
  query: { enabled: useIsIntersecting(container) },
})

const videos = computed(() => (data.value ?? []) as unknown as VideoResultDto[])
watchImmediate(videos, (videos: VideoResultDto[]) => {
  emit('hasVideos', !!videos.length);
});

const { filteredVideos, isLoadingPosts } = useFilterVideos(videos)

const modal = ref<GetQrCode>()

function openModal(video: VideoResultDto) {
  if (!canGuard('qr-code-download')) {
    upgradeDialog.open('qr-code-download')
  } else {
    modal.value.publish(video.resultUrl)
    logging.trackEvent('ResultVideo QrCode Generated', {
      Location: 'Dashboard My Clips Page',
      Destination: 'QrCode',
      IsPublic: false,
    })
  }
}

const previews = ref<VideoPreview[]>()
const previewVideos = computed<HTMLVideoElement[]>(() => {
  return (previews.value ?? []).map((p) => p.video).filter(Boolean)
})

function pauseOthers(id: string | null | undefined) {
  for (const video of previewVideos.value) {
    if (video.id !== id) {
      video.pause()
    }
  }
}

const isLoading = computed(() => isLoadingVideos.value || isLoadingPosts.value)
const amountOfVideos = computed(() => {
  if (isLoading.value) {
    return 4
  } else if (props.singleRow) {
    return Math.min(4, filteredVideos.value.length)
  } else {
    return filteredVideos.value.length
  }
})

const canSaveVideos = computed(() => useGuard('save-video').value)
</script>

<template>
  <div ref="container">
    <section v-if="!canSaveVideos">
      <ol class="single-row grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5">
        <li class="block">
          <VideoPreview placeholder-src="/images/posters/placeholder-1.jpg" />
        </li>
        <li class="block">
          <VideoPreview placeholder-src="/images/posters/placeholder-2.jpg" />
        </li>
        <li class="block">
          <VideoPreview placeholder-src="/images/posters/placeholder-3.jpg" />
        </li>
        <li class="block">
          <VideoPreview placeholder-src="/images/posters/placeholder-4.jpg" />
        </li>
      </ol>
    </section>

    <section v-else-if="amountOfVideos">
      <TransitionGroup
        :key="(videosFilter || 'none') + dataUpdatedAt"
        :class="{ 'grid-single-row': singleRow || isLoading }"
        appear
        class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5"
        name="grid"
        tag="ol"
      >
        <li v-for="i in range(amountOfVideos)" :key="i" class="block">
          <VideoPreview ref="previews" :video="filteredVideos[i]" @play="pauseOthers" @requestQr="openModal" />
        </li>
      </TransitionGroup>

      <div v-if="singleRow && !isLoading && amountOfVideos > 4" class="mt-4 flex flex-row justify-center gap-4">
        <RouterLink
          :to="{ name: dashboardRouteNames.videos }"
          class="active:bg-zinc-600/25 mt-4 flex items-center gap-2 rounded-xl border border-current px-3 py-1.5 font-semibold transition-all hover:border-transparent hover:bg-zinc-800 hover:text-zinc-100 active:scale-90 dark:hover:bg-zinc-200 dark:hover:text-zinc-800"
        >
          <IconSaxVideo />
          View all clips
        </RouterLink>
      </div>
    </section>

    <NoContentClipsSection
      v-else
      :totalAmountOfVideos="videos.length"
      :hideFileUpload="!!videos.length" />
    <GetQrCode ref="modal" />
  </div>
</template>

<style lang="scss"></style>
