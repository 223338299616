import transcodeService from './transcodeService'

export function setLayoutArguments(layout: string) {
  switch (layout) {
    case 'Split16x9':
      transcodeService.setSplitArguments('split16x9_fc', 'split16x9_gf')
      break
    case 'Split4x3':
      transcodeService.setSplitArguments('split4x3_fc', 'split4x3_gf')
      break
    case 'SplitFree':
      transcodeService.setSplitArguments('splitfree_fc', 'splitfree_gf')
      break

    case 'GameUi':
      transcodeService.setGameUiArguments()
      break
    case 'FullScreen':
      transcodeService.setFullscreenArguments()
      break
    case 'Square':
      transcodeService.setSquareArguments()
      break
    case 'BlurredBackground':
      transcodeService.setBlurredBackgroundArguments()
      break
    case 'SmallFacecam':
      transcodeService.setSmallfacecamArguments()
      break
    case 'CircleFacecam':
      transcodeService.setCircleFacecamArguments()
      break
  }
}
