import { streamLadderAxios } from './axios'

export default {
  async load() {
    const res = await streamLadderAxios.get('/api/customstickers')
    return res.data
  },
  async post(url) {
    await streamLadderAxios.post('/api/customstickers', {
      fileUrl: url,
    })
  },
  async destroy(id) {
    await streamLadderAxios.delete(`/api/customstickers/${id}`)
  },
}
