<script setup lang="ts">
import { shapes, type Shape } from '@/modules/CustomLayouts/@data/shapes'
import IconSaxTickCircle from '@/components/Icons/iconsax/IconSaxTickCircle.vue'
import { PopoverTrigger, PopoverRoot, PopoverPortal, PopoverContent } from 'radix-vue'
import { computed, ref, inject } from 'vue'
import ShapeIcon from '@/modules/CustomLayouts/Crop/CropToolbar/ShapeIcon.vue'
import IconSaxArrowCircleDown from '@/components/Icons/iconsax/IconSaxArrowCircleDown.vue'
import { contain, cover } from '@/modules/SLMovable/helpers/fit'
import { resizeAspectLockedFromCenter } from '@/modules/SLMovable/helpers/resize/resizeAspectLockedFromCenter'
import type { CropsStore } from '@/store/entity-system/useCropsStore'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import type { Size, Area } from '@/modules/SLMovable/@types/Movable'
import IconSaxArrowDown from '@/components/Icons/iconsax/IconSaxArrowDown.vue'
import IconSaxArrowDown2 from '@/components/Icons/iconsax/IconSaxArrowDown2.vue'
import { Button } from '@/components/ui/button'

const props = defineProps<{ id: string }>()
const cropsStore = inject<CropsStore>('cropsStore')!
const crop = cropsStore.selectById(props.id)
const shapeModel = cropsStore.selectShapeById(props.id)

const videoStore = useEditorVideoStore()

function containUpdatedShape(container: Size, area: Area, aspectLock: Size) {

  const old = {
    width: area.width * container.width,
    height: area.height * container.height,
    x: area.x * container.width,
    y: area.y * container.height,
  }

  // We first create a square area that covers the original crop area. Then we contain the new aspect ratio within
  // that square area. This creates a new crop that feels natural to the user. Besides, it prevents the crop from
  // growing or shrinking too much when changing shape multiple times.

  const outer = cover({ width: 1, height: 1 }, old)
  const inner = contain(aspectLock, outer)

  const oldCenterX = old.x + 0.5 * old.width
  const oldCenterY = old.y + 0.5 * old.height

  const point = { x: oldCenterX - 0.5 * inner.width, y: oldCenterY - 0.5 * inner.height }
  const newSize = resizeAspectLockedFromCenter(
    old,
    point,
    ['s', 'e'],
    { top: 0, left: 0, bottom: container.height, right: container.width },
    { width: aspectLock.width, height: aspectLock.height },
    null
  )

  return {
    x: newSize.x / container.width,
    y: newSize.y / container.height,
    width: newSize.width / container.width,
    height: newSize.height / container.height,
  }
}

function updateShape(shape: Shape) {

  const { aspectLock } = shapes.find((s) => s.value === shape)!
  if (!aspectLock) return

  const { videoWidth, videoHeight } = videoStore.videoElement!

  const newCrop = containUpdatedShape({ width: videoWidth, height: videoHeight }, crop, aspectLock)
  cropsStore.updateCropAreaById(props.id, newCrop)

  const newFeedData = containUpdatedShape({ width: 1080, height: 1920 }, crop.feedData, aspectLock)
  cropsStore.updateCropFeedDataById(props.id, newFeedData)

  // Model binding fails if parent element is immediately dismounted
  setTimeout(() => {
    isOpen.value = false
  }, 0)
}

const selected = computed(() => shapes.find((s) => s.value === shapeModel.value)!)
const isOpen = ref(false)
</script>

<template>
  <PopoverRoot v-model:open="isOpen">
    <PopoverTrigger>
      <Button variant="ghost" size="sm" class="font-medium rounded-md text-sm">
        <ShapeIcon :shape="selected.value" />
        <span>{{ selected.label }}</span>

        <IconSaxArrowDown2
          class="ml-auto h-4 w-4 shrink-0 transition-transform"
          :class="isOpen ? 'rotate-180' : ''"
        />
      </Button>
    </PopoverTrigger>
    <PopoverContent
      data-retain-focus
      :side-offset="8"
      class="layer-2 flex flex-col gap-1 rounded-md p-1 shadow-lg data-[state=open]:animate-[slideUpAndFade_150ms_cubic-bezier(0.4,_0,_0.2,_1)_forwards]"
      side="bottom"
      @click.stop
    >
      <Button
        :key="shape.value"
        v-for="shape in shapes"
        as="label"
        class="cursor-pointer font-medium rounded-md text-sm"
        variant="ghost"
        size="sm"
      >
        <input
          v-model="shapeModel"
          :value="shape.value"
          class="absolute opacity-0"
          type="radio"
          @click="updateShape(shape.value)"
        />
        <ShapeIcon :shape="shape.value" />
        {{ shape.label }}
        <IconSaxTickCircle
          :class="shape.value === selected.value ? 'visible' : 'invisible'"
          class="ml-auto h-4 w-4 shrink-0 text-sky-400"
        />
      </Button>
    </PopoverContent>
  </PopoverRoot>
</template>

<style scoped lang="scss"></style>
