<template>
  <div class="container mx-auto flex flex-col items-center gap-4 pt-8 text-center">
    <h1 class="text-xl font-bold">Download result</h1>

    <p>
      <strong>iOS users</strong>: open this url in <strong>Safari</strong>, it's the only way to actually download the
      file.
    </p>
    <input type="text" class="input-bordered input w-full" :value="downloadSrc" />

    <a v-if="downloadSrc" @click="onDownloadClicked" :href="downloadSrc" :download="downloadSrc" id="download-result-page-download-link" class="btn-primary btn-block btn">
      <i> <download-icon class="mr-2 h-4 w-4" /> </i>Download
    </a>
    <div class="align-items-center video-wrapper w-full">
      <video :src="downloadSrc" controls loop disableRemotePlayback />
    </div>
  </div>
</template>

<script>
import DownloadIcon from '../components/Icons/DownloadIcon.vue'

export default {
  components: {
    DownloadIcon,
  },
  head() {
    return {
      title: 'Download result',
      bodyAttrs: {
        class: 'bg-base-100',
      },
    }
  },
  data() {
    return {
      clipId: '',
      downloadSrc: '',
    }
  },
  mounted() {
    this.downloadSrc = decodeURIComponent(this.$route.query.downloadSrc)
  },
  methods: {
    onDownloadClicked() {
      this.$trackEvent('Video Result Downloaded', {
        location: 'QR Download Page',
      })
    },
  },
}
</script>

<style scoped>
.video-wrapper > video {
  height: 50vh;
  display: block;
  margin: auto;
}

.controls {
  display: flex;
  align-items: center;
}
</style>
