import type { Layout } from '@/store/entity-system/useLayoutsStore'
import type { Crop } from '@/store/entity-system/useCropsStore'
import {
  deleteApiCustomLayoutsLayoutId,
  putApiCustomLayoutsLayoutId, getApiCustomLayouts,
} from '@/apis/streamladder-api/custom-layouts/custom-layouts'
import * as Sentry from '@sentry/vue'
import { useLayoutsStore } from '@/store/entity-system/useLayoutsStore'
import { useCropsStore } from '@/store/entity-system/useCropsStore'
import { omit } from 'lodash-es'
import EventBus from '@/eventBus'
import mainEvents from '@/events/mainEvents'
import { extractCropsFromCustomLayouts } from '@/store/entity-system/_extractCropsFromCustomLayouts'
import type { LayoutWithCrops } from '@/store/entity-system/queries/useListCustomLayouts'

export async function updateCustomLayoutsStoresAsync() {

  const layoutsStore = useLayoutsStore()
  layoutsStore.isLoading = true

  try {

    const customLayouts = await getApiCustomLayouts().catch((error) => {
      console.error(error)
      throw error
    }) as LayoutWithCrops[]

    if (customLayouts) {
      updateCustomLayoutsStores(customLayouts)
    }

    layoutsStore.isLoading = false
    return { error: null }
  } catch (e) {
    Sentry.captureException(e)
    layoutsStore.isLoading = false
    return { error: e }
  }
}

export function updateCustomLayoutsStores(customLayouts: LayoutWithCrops[]) {

  const { layouts, crops } = extractCropsFromCustomLayouts(customLayouts)

  const layoutsStore = useLayoutsStore()
  for (const layout of layouts) {
    layoutsStore.upsertById(layout.id, layout)
  }

  const cropsStore = useCropsStore()
  for (const crop of crops) {
    cropsStore.upsertById(crop.id, crop)
  }
}

export async function updateCustomLayoutById(layoutId: string, payload: { layout: Layout, crops: Crop[] }) {

  try {

    const update = await putApiCustomLayoutsLayoutId(layoutId, {
      ...payload.layout,
      crops: payload.crops.map((crop) => omit(crop, 'layoutId')),
    }).catch((error) => {
      console.error(error)
      throw error
    }) as LayoutWithCrops

    const newLayout = omit(update, 'crops') as Layout
    const newCrops = update.crops.map((crop) => ({ ...crop, layoutId: update.id }))

    const layoutsStore = useLayoutsStore()
    layoutsStore.upsertById(layoutId, omit(newLayout, 'crops'))

    const cropsStore = useCropsStore()
    const oldCrops = cropsStore.idsWhereLayoutIdIs(layoutId).value
    const removedCrops = oldCrops.filter((id) => !newCrops.map((c) => c.id).includes(id))

    for (const id of removedCrops) {
      cropsStore.removeById(id)
    }

    for (const crop of newCrops) {
      cropsStore.upsertById(crop.id, crop)
    }

    return { error: null }
  } catch (e) {
    Sentry.captureException(e)
    return { error: e }
  }
}

export async function deleteCustomLayout(layoutId: string) {

  try {

    await deleteApiCustomLayoutsLayoutId(layoutId).catch((error) => {
      console.error(error)
      throw error
    })

    const layoutsStore = useLayoutsStore()
    layoutsStore.removeById(layoutId)

    const cropsStore = useCropsStore()
    const crops = cropsStore.idsWhereLayoutIdIs(layoutId).value
    for (const id of crops) {
      cropsStore.removeById(id)
    }

    return { error: null }
  } catch (e) {
    Sentry.captureException(e)
    EventBus.$emit(mainEvents.ERROR, 'Something went wrong. Please try again later.')
    return { error: e }
  }
}
