<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import type { Component } from "vue";
import { posthog, type Survey, SurveyQuestionType } from 'posthog-js'
import { Button } from "@/components/ui/button";
import MultipleChoiceQuestion from "@/components/Onboarding/surveyTypes/MultipleChoiceQuestion.vue";
import SingleChoiceQuestion from "@/components/Onboarding/surveyTypes/SingleChoiceQuestion.vue";
import TextQuestion from "@/components/Onboarding/surveyTypes/TextQuestion.vue";
import { CardContent, CardFooter, CardHeader } from "@/components/ui/card";
import LottieAnimation from "@/components/LottieAnimation.vue";
import IconSaxMessageQuestion from '@/components/Icons/iconsax/IconSaxMessageQuestion.vue'
import IconSaxArrowRight from '@/components/Icons/iconsax/IconSaxArrowRight.vue'

const emit = defineEmits<{
  (event: 'surveyEnd'): void
}>();

const surveyId = '019131ab-1f13-0000-63cb-cb7f77855d19';
const survey = ref<Survey | null>(null);

const questionIndex = ref(0);

const surveyShown = () => {
  posthog.capture('survey shown', {
    $survey_id: surveyId
  });
};

const dismissSurvey = () => {
  emit('surveyEnd');
};

onMounted(() => {
  posthog.getSurveys((allSurveys) => {
    const onboardingSurvey = allSurveys.find((survey) => survey.id === surveyId);
    if (onboardingSurvey) {
      survey.value = onboardingSurvey;
      surveyShown();
    } else {
      emit('surveyEnd');
    }
  });
});

const findComponentForSurveyType = (type: SurveyQuestionType | undefined): Component => {
  switch (type) {
    case 'multiple_choice':
      return MultipleChoiceQuestion;
    case 'single_choice':
      return SingleChoiceQuestion;
    case 'open':
      return TextQuestion;
    default:
      return TextQuestion;
  }
};

const skipNextQuestion = ref(false);
const surveyResponses = ref<{ [key: string]: string | string[] }>({});

const answered = (value: string | string[], index: number) => {

  if (!value || value.length === 0) {
    canContinue.value = false;
    return;
  }

  canContinue.value = true;

  if (index === 0) {
    surveyResponses.value = {
      ...surveyResponses.value,
      ['$survey_response']: value
    };
  } else {
    surveyResponses.value = {
      ...surveyResponses.value,
      [`$survey_response_${index}`]: value
    };
  }

  // Skip optional question if 'Other' option is not selected.
  if (survey.value?.questions[index + 1]?.optional && !value.includes('Other')) {
    skipNextQuestion.value = true;
  } else {
    skipNextQuestion.value = false;
  }
};

const submitSurvey = () => {
  posthog.capture('survey sent', {
    $survey_id: surveyId,
    ...surveyResponses.value
  });
  emit('surveyEnd');
};

const canBack = computed(() => questionIndex.value > 0);

const canContinue = ref(false);

const handleNextOrFinish = () => {
  canContinue.value = false;
  if (survey.value?.questions && questionIndex.value === survey.value?.questions.length - 1) {
    submitSurvey();
  } else if (skipNextQuestion.value) {
    questionIndex.value += 2;
    skipNextQuestion.value = false;
  } else {
    questionIndex.value += 1;
  }
};

const handleBack = () => {
  if (questionIndex.value > 0) {
    questionIndex.value -= 1;
    canContinue.value = false;
  }
};

const showConfirm = ref(false);
</script>

<template>
  <div class="flex flex-col rounded-lg w-full mt-4 min-h-[375px] relative">
    <CardHeader class="flex flex-col justify-center items-center gap-2 text-center">
      <template v-if="showConfirm">
        <img draggable="false" alt="StreamLadder branding" class="h-8 w-8 select-none" height="192" src="/images/logo.png" width="192" />
        <h2 class="text-2xl font-semibold">Welcome to StreamLadder</h2>
        <p class="font-light text-center">Tell us about yourself so we can customize your experience</p>
      </template>
      <template v-else>
        <IconSaxMessageQuestion class="w-7 h-7 text-gray-600" />
        <h2 class="text-lg font-semibold">{{ survey?.questions[questionIndex]?.question || 'Survey' }}</h2>
      </template>
    </CardHeader>
    <CardContent class="flex flex-col items-center justify-center min-h-[250px] gap-4">
      <template v-if="showConfirm">
        <Button @click="showConfirm = false" class="select-none">
          Start
          <IconSaxArrowRight class="w-4 h-4 ml-2" />
        </Button>
        <Button @click="dismissSurvey" variant="ghost" class="text-gray-500 font-light select-none" size="sm">
          Skip survey
        </Button>
      </template>
      <template v-else-if="survey?.questions[questionIndex]">
        <component
          :is="findComponentForSurveyType(survey?.questions[questionIndex]?.type)"
          :question="survey?.questions[questionIndex]"
          @answered="(value: string) => answered(value, questionIndex)"
          @invalidAnswer="canContinue = false"
        />
      </template>
      <div v-else class="bg-gray-400 rounded-full">
        <LottieAnimation class="w-12" url="/lottie/searching.json" />
      </div>
    </CardContent>
    <CardFooter class="flex justify-between" v-if="!showConfirm">
      <Button
        variant="ghost"
        @click="handleBack"
        :disabled="!canBack"
        :class="{ 'invisible pointer-events-none': questionIndex === 0 }"
        class="select-none"
      >
        Back
      </Button>
      <Button v-if="questionIndex === 0" @click="dismissSurvey" variant="ghost" class="text-gray-500 font-light select-none" size="sm">
        Skip survey
      </Button>
      <Button
        variant="default"
        @click="handleNextOrFinish"
        :disabled="!canContinue"
        class="select-none"
      >
        {{ survey?.questions && questionIndex === survey?.questions.length - 1 ? 'Finish' : 'Next'}}
      </Button>
    </CardFooter>
  </div>
</template>

<style scoped lang="scss"></style>
