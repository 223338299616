<template>
  <Card>
    <CardHeader>
      <CardTitle>Instagram reels title</CardTitle>
      <CardDescription>Craft an engaging title that captures the vibe of your Reel</CardDescription>
    </CardHeader>
    <CardContent>
      <label class="relative block">
        <span class="absolute left-3 top-2.5">
          <IconSaxWarning2 v-if="errors?.['instagram,title']" class="h-5 w-5 text-error" />
          <InstagramIcon class="h-5 w-5 text-special-instagram" />
        </span>
        <Input
          v-model="_title"
          placeholder="Instagram Title"
          class="pl-10 pr-24"
          :class="{ 'input-error': errors?.['instagram,title'] }"
          type="text"
        />

        <span class="absolute bottom-2.5 right-2 text-brand-state-text-placeholder">
          {{ _title.trim()?.length ?? 0 }} / {{ 100 }}
        </span>
      </label>
    </CardContent>
    <CardFooter>
      <span v-for="error of errors?.['instagram,title'] ?? []" :key="error.code" class="text-brand-state-error-text">
        {{ error.message }}
      </span>
    </CardFooter>
  </Card>
</template>
<script lang="ts" setup>
import { useVModel } from '@vueuse/core'
import InstagramIcon from '@/components/Icons/SocialMedia/InstagramIcon.vue'
import { Input } from '@/components/ui/input'
import { CardContent, CardHeader, Card, CardTitle, CardDescription, CardFooter } from '@/components/ui/card'
import IconSaxWarning2 from '@/components/Icons/iconsax/IconSaxWarning2.vue'

const props = defineProps<{
  title: string
  errors?: Record<string, { code: string; message: string }[]>
}>()
const emit = defineEmits(['update:title'])

const _title = useVModel(props, 'title', emit)
</script>
