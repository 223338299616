<script lang="ts" setup>
import { Dialog, DialogTrigger, DialogContent, DialogTitle } from '@/components/ui/dialog'
import { ref } from 'vue'
import { formatFileSize } from '@/helpers/fileSize'
import { Button } from '@/components/ui/button'
import IconSaxDocumentUpload from '@/components/Icons/iconsax/IconSaxDocumentUpload.vue'
import { useDropZone } from '@vueuse/core'
import { useUserInfoStore } from '@/store/user/userInfo'
import { useLocalFileValidator } from '@/Hooks/clip-form/useLocalFileValidator'
import { startFileUpload } from '@/components/Dialog/MultiUploadDialog/file-uploads/_api'
import uploadService from '@/services/uploadService'
import axios from 'axios'
import RadialProgress from '@/components/Dialog/RadialProgress.vue'
import * as Sentry from '@sentry/vue'
import EventBus from '@/eventBus'
import mainEvents from '@/events/mainEvents'
import { getGetApiVideosQueryKey, postApiVideos } from '@/apis/streamladder-api/videos/videos'
import { queryClient } from '@/services/QueryClient'

const emit = defineEmits<{ (name: 'select', event: { id: string; title: string; videoUrl: string }): void }>()

const isOpen = ref(false)
const dropzone = ref<HTMLLabelElement | null>(null)
const { isOverDropZone } = useDropZone(dropzone, { onDrop: updateFiles })
const validateLocalFile = useLocalFileValidator()

const isUploading = ref(false);
const uploadProgress = ref(0);

async function updateFiles(files: FileList | File[] | null) {

  if (!files || !await validateLocalFile(files[0])) {
    return;
  }

  isUploading.value = true;

  const file = files[0];

  const uploadResultStorage = await uploadService.getUploadResultSignedUrl();
  const upload = startFileUpload(uploadResultStorage, file, axios.CancelToken.source(), {
    onProgress: (progress) => { uploadProgress.value = progress },
  });

  await upload.then(async () => {

      const videoResponse = await postApiVideos({
        videoUrl: uploadResultStorage.resultUrl,
        title: file.name || 'StreamLadder video',
      });

      if (!videoResponse) {
        console.error('Failed to post video to api videos.');
        isUploading.value = false;
        isOpen.value = false;
        EventBus.$emit(mainEvents.ERROR, 'Failed to upload file, please try again.');
        Sentry.captureException(new Error('Failed to post video to api videos.'));
      }

      emit('select', {
        id: videoResponse.id!,
        title: file.name,
        resultUrl: uploadResultStorage.resultUrl,
      });

      isUploading.value = false;
      isOpen.value = false;
    })
    .catch((error) => {
      console.error(JSON.stringify(error));
      isUploading.value = false;
      isOpen.value = false;
      EventBus.$emit(mainEvents.ERROR, 'Failed to upload file, please try again.');
      Sentry.captureException(new Error(`Content Publisher User Upload failed`));
    });
}

const updateFilesFromEvent = async (event: Event) => {
  const target = event.target as HTMLInputElement
  await updateFiles(target.files)
};

const userInfoStore = useUserInfoStore();
</script>

<template>
  <Dialog v-model:open="isOpen">

    <DialogTrigger class="flex flex-auto" v-if="$slots.default">
      <slot />
    </DialogTrigger>

    <DialogContent class="max-w-3xl gap-8">
      <DialogTitle>Upload your media</DialogTitle>
      <label
        ref="dropzone"
        :class="{
          'dark:bg-sky-400/50 !border-solid bg-brand-state-hover-bg text-black dark:text-white': isOverDropZone,
          'cursor-pointer': !isUploading,
        }"
        class="flex flex-col items-center gap-4 rounded-lg border-2 p-8 transition-[color,border-color,background-color,transform]"
      >
        <template v-if="!isUploading">
          <input
            :multiple="false"
            accept=".mp4,.mov"
            class="invisible absolute inset-0"
            type="file"
            @change="updateFilesFromEvent"
          />
          <IconSaxDocumentUpload :class="isOverDropZone ? 'text-sky-300 dark:text-sky-700' : 'text-brand-state-link'" />
          <span class="flex flex-col gap-1">
            <span v-if="isOverDropZone" class="text-center text-sm"> Drop to upload </span>
            <span v-else class="text-center text-sm">
              Drag your file or
              <Button as="p" class="h-auto cursor-pointer p-0" variant="link">browse</Button>
            </span>
            <span class="text-center text-xs font-light opacity-50">
              Max {{ formatFileSize(userInfoStore.fileSizeLimit) }} per file is allowed. Only .mp4 and .mov files are supported.
            </span>
          </span>
        </template>
        <template v-else-if="isUploading">
          <DialogTitle>Uploading..</DialogTitle>
          <radial-progress :progress="uploadProgress" />
        </template>
      </label>
    </DialogContent>
  </Dialog>
</template>

<style lang="scss" scoped></style>
