<script setup lang="ts">
import { computed } from 'vue'
import RecentPaymentDetail from '@/areas/dashboard/components/RecentPayments/RecentPaymentDetail.vue'
import { usePayments } from '@/areas/dashboard/components/RecentPayments/usePayments'
import { range } from 'lodash-es'

const props = defineProps<{ subscriptionId: string }>()

const skeletons = 4

const { isLoading, data: payments } = usePayments(props.subscriptionId)
const amountToShow = computed(() => (isLoading.value ? skeletons : payments.value?.length))
</script>

<template>
  <section class="flex w-full flex-col" v-if="isLoading || payments.length">
    <header class="layer-2 grid grid-cols-12 overflow-hidden rounded-lg px-3 py-1">
      <span class="col-span-8 p-0 font-bold md:col-span-5">Date</span>
      <span class="p-0 font-bold">Amount</span>
    </header>
    <TransitionGroup name="list" tag="ol" class="flex w-full flex-col gap-2">
      <li v-for="i in range(amountToShow)" :key="i">
        <RecentPaymentDetail :payment="payments[i]" />
      </li>
    </TransitionGroup>
  </section>
</template>

<style scoped lang="scss"></style>
