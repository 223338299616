<template>
  <router-link v-if="isLink" :to="{ name: routeName }" class="breadcrumb-item">
    <span class="full-text">{{ fullText }}</span>
    <span class="mobile-text">{{ mobileText }}</span>
  </router-link>
  <span v-else @click="$emit('click')" :class="{ active: isActive }" class="breadcrumb-item">
    <span class="full-text">{{ fullText }}</span>
    <span class="mobile-text">{{ mobileText }}</span>
  </span>
</template>

<script>
export default {
  props: {
    stepName: String,
    fullText: String,
    mobileText: String,
    routeName: String,
    isActive: Boolean,
    isLink: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.mobile-text {
  display: none;
}
.full-text {
  display: inline;
}

@media (max-width: 767px) {
  .mobile-text {
    display: inline;
  }
  .full-text {
    display: none;
  }

  .active {
    .mobile-text {
      display: none;
    }
    .full-text {
      display: inline;
    }
  }
}

.breadcrumb-item {
  font-weight: 500;
  color: white;
  text-decoration: none;
  opacity: 0.5;

  &.active {
    font-weight: 600;
    opacity: 90%;
  }
}

a.breadcrumb-item {
  color: white;
  font-weight: 600;
}
</style>
