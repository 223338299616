// store.js
import { ref } from 'vue'
import { createGlobalState, useCloned } from '@vueuse/core'
import type { MaybeRef } from '@vueuse/shared'

export const useSplitterClone = createGlobalState((segments: MaybeRef<any>) => {
  const { cloned, sync } = useCloned(segments, { manual: true })
  return {
    cloned,
    sync,
  }
})
