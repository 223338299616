<script lang="ts" setup>
import { FocusTypes } from '@/store/editor/editorFocus'
import TimeLineTrack from '@/modules/SLTimeline/TimeLineTrack.vue'
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import StickerNode from '@/areas/editor/timeline/tracks/stickers/StickerNode.vue'

const stickersStore = useStickersStore()
const textStickers = stickersStore.whereFocusTypeIs(FocusTypes.TEXTSTICKER)
const socialStickers = stickersStore.whereFocusTypeIs(FocusTypes.STICKER)
</script>

<template>
  <TimeLineTrack class="relative h-full min-h-6 max-h-12 w-full" v-if="textStickers.length > 0">
    <StickerNode
      v-for="sticker in textStickers"
      :id="sticker.id"
      :key="sticker.id"
      :focus-type="FocusTypes.TEXTSTICKER"
    />
  </TimeLineTrack>
  <TimeLineTrack class="relative h-full min-h-6 max-h-12 w-full" v-if="socialStickers.length > 0">
    <StickerNode
      v-for="sticker in socialStickers"
      :id="sticker.id"
      :key="sticker.id"
      :focus-type="FocusTypes.STICKER"
    />
  </TimeLineTrack>
</template>
