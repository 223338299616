import { useConfirmDialog } from '@/components/Dialog/Confirm/useConfirmDialog'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { findLastIndex } from 'lodash-es'
import { useEditorVideoStore } from '@/store/editor/editorVideo'

export const useDeleteCaption = () => {
  const { reveal, isRevealed } = useConfirmDialog()
  const editorFocusStore = useEditorFocusStore()
  const editorVideoStore = useEditorVideoStore()
  const captionEditorStore = useEditorCaptionsStore()

  const confirmAndDelete = async () => {
    if (isRevealed.value) return
    if (editorFocusStore.selectedCaptions.length === 1) return

    let captionIds = editorFocusStore.selectedCaptions
    const focusedCaptionId = editorFocusStore.focus?.type === 'caption' ? editorFocusStore.focus?.key : undefined
    if (captionIds.length === 0 && focusedCaptionId) {
      captionIds = [focusedCaptionId]
    }
    if (captionIds.length === 0) {
      // find caption under cursor
      const currentTime = editorVideoStore.getExactTime()
      const caption = captionEditorStore.captions.find((caption) => {
        return caption.startMs <= currentTime && caption.endMs >= currentTime
      })
      if (caption) {
        captionIds = [caption.id]
      }
    }

    if (captionIds.length === 0) return

    const confirmed =
      editorFocusStore.selectedCaptions.length === 1
        ? true
        : await reveal({
            title: 'Delete Captions',
            message: 'Are you sure you want to delete selected captions?',
          })
    if (confirmed) {
      // remove all selectedCaptions from store~
      const firstCaption = captionEditorStore.captions.findIndex((caption) =>
        editorFocusStore.selectedCaptions.includes(caption.id)
      )
      const lastCaption = findLastIndex(captionEditorStore.captions, (caption) => {
        return editorFocusStore.selectedCaptions.includes(caption.id)
      })
      captionEditorStore.captions.splice(firstCaption, lastCaption - firstCaption + 1)
      editorFocusStore.selectedCaptions = []
    }
  }

  return confirmAndDelete
}
