import { onMounted, onUnmounted, reactive, ref } from 'vue'
import { createGlobalState } from '@vueuse/core'
import type { MaybeRefOrGetter } from '@vueuse/shared'

export const useDebugState = createGlobalState(() => {
  const debuggers = reactive<{ title: string; value: MaybeRefOrGetter; state: object }[]>([])
  const debugActions = reactive<{ title: string; action: () => void }[]>([])
  const showDebug = ref(true)
  return { debuggers, showDebug, debugActions }
})

export const useDebugPopoverAction = (title: string, value: MaybeRefOrGetter<() => string>) => {
  const { debugActions } = useDebugState()
  onMounted(() => {
    debugActions.push({
      title,
      action: value,
      state: reactive({
        title,
      }),
    })
  })
  onUnmounted(() => {
    debugActions.splice(
      debugActions.findIndex((d) => d.title === title),
      1
    )
  })
}

export const useDebugPopover = (title: string, value: MaybeRefOrGetter) => {
  const { debuggers } = useDebugState()
  onMounted(() => {
    debuggers.push({ title, value })
  })
  onUnmounted(() => {
    debuggers.splice(
      debuggers.findIndex((d) => d.title === title),
      1
    )
  })
}
