<template>
  <Card>
    <CardHeader>
      <CardTitle>YouTube Shorts</CardTitle>
      <CardDescription>Summarize your video with a catchy title using key themes, under 30 characters.</CardDescription>
    </CardHeader>
    <CardContent>
      <label class="relative block">
        <span class="absolute left-3 top-2.5">
          <IconSaxWarning2 v-if="errors?.['youtube,title']" class="h-5 w-5 text-error" />
          <YoutubeIcon v-else class="h-5 w-5 text-special-youtube dark:text-white" />
        </span>
        <Input
          v-model="_title"
          placeholder="YouTube Title"
          class="pl-12 pr-24 pt-2"
          :class="{ 'input-error': errors?.['youtube,title'] }"
          type="text"
          maxlength="100"
        />
        <span class="absolute bottom-3 right-2 text-brand-state-text-placeholder font-light text-sm"> {{ _title?.trim()?.length ?? 0 }} / {{ 100 }} </span>
      </label>
    </CardContent>
    <CardFooter>
      <span v-for="error of errors?.['youtube,title'] ?? []" :key="error.code" class="text-error">
        {{ error.message }}
      </span>
    </CardFooter>
    <CardHeader class="pt-0">
      <CardTitle>YouTube Shorts description</CardTitle>
      <CardDescription>
        Briefly describe your video with keywords (e.g., 'speedrun tips') to improve search visibility.
      </CardDescription>
    </CardHeader>
    <CardContent>
      <div class="relative">
        <span class="absolute left-3 top-3">
          <IconSaxWarning2 v-if="errors?.['youtube,description']" class="h-5 w-5 text-error" />
          <YoutubeIcon v-else class="h-5 w-5 text-special-youtube dark:text-white" />
        </span>
      </div>
      <SaveableTextarea
        v-model="_description"
        :max-text-length="1250"
        :class="{ 'textarea-error': errors?.['youtube,description'] }"
        class="h-44 pl-12 pt-3 w-full"
        tag="youtube"
        placeholder="Type your description here"
      />
    </CardContent>
    <CardFooter>
      <span v-for="error of errors?.['youtube,description'] ?? []" :key="error.code" class="text-brand-state-error-text">
        {{ error.message }}
      </span>
    </CardFooter>
  </Card>
</template>
<script lang="ts" setup>
import { useVModel } from '@vueuse/core'
import YoutubeIcon from '@/components/Icons/SocialMedia/YoutubeIcon.vue'
import { Input } from '@/components/ui/input'
import { CardContent, CardHeader, Card, CardTitle, CardDescription, CardFooter } from '@/components/ui/card'
import IconSaxWarning2 from '@/components/Icons/iconsax/IconSaxWarning2.vue'
import SaveableTextarea from '@/components/SaveableTextarea/SaveableTextarea.vue'

const props = defineProps<{
  title: string
  description: string
  errors?: Record<string, { code: string; message: string }[]>
}>()
const emit = defineEmits(['update:title', 'update:description'])

const _title = useVModel(props, 'title', emit)
const _description = useVModel(props, 'description', emit)
</script>
