import { ref, type Ref } from 'vue'
import { tryOnMounted, tryOnUnmounted, useResizeObserver } from '@vueuse/core'

export function useBoundingClientRect(element: Ref<HTMLElement | null>) {

  const x = ref(0)
  const y = ref(0)
  const width = ref(0)
  const height = ref(0)
  const top = ref(0)
  const right = ref(0)
  const bottom = ref(0)
  const left = ref(0)

  function updateBoundingRect() {
    const rect = element.value?.getBoundingClientRect()
    if (rect) {
      width.value = rect.width
      height.value = rect.height
      x.value = rect.x
      y.value = rect.y
      top.value = rect.top
      right.value = rect.right
      bottom.value = rect.bottom
      left.value = rect.left
    }
  }

  tryOnMounted(() => {
    updateBoundingRect()
    window.addEventListener('resize', updateBoundingRect)
    window.addEventListener('scroll', updateBoundingRect)
    window.visualViewport?.addEventListener('resize', updateBoundingRect)
  })

  tryOnUnmounted(() => {
    window.removeEventListener('resize', updateBoundingRect)
    window.removeEventListener('scroll', updateBoundingRect)
    window.visualViewport?.removeEventListener('resize', updateBoundingRect)
  })

  useResizeObserver(element, () => updateBoundingRect())

  return {
    x,
    y,
    width,
    height,
    top,
    right,
    bottom,
    left,
    forceUpdate() {
      updateBoundingRect()
    }
  }
}
