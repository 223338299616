<script setup lang="ts">
import { useConditionalEventListener } from '@/Hooks/useConditionalEventListener'
import { toRef } from 'vue'
import { PopoverRoot, PopoverTrigger, PopoverPortal, PopoverContent } from 'radix-vue'
import { useVModel } from '@vueuse/core'
import { useScreen } from '@/Hooks/useScreen'

const props = withDefaults(defineProps<{ arrow?: boolean; modelValue?: boolean }>(), { arrow: false, modelValue: false })
const emit = defineEmits<{ (event: 'update:modelValue', value: boolean): void }>()

useConditionalEventListener(toRef(props, 'modelValue'), 'keydown', (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    emit('update:modelValue', false)
  }
})

const model = useVModel(props, 'modelValue', emit)
const isLg = useScreen('lg')
</script>

<template>
  <div>
    <PopoverRoot v-model:open="model">
      <PopoverTrigger>
        <slot
          name="button"
          type="button"
          :class="['cursor-pointer w-full', { 'overflow-visible': modelValue }]"
        />

      </PopoverTrigger>

      <PopoverPortal>
        <PopoverContent
          :side="isLg ? 'right' : 'top'"
          class="
            group/popover
            data-[state=open]:data-[side=top]:animate-[slideDownAndFade_150ms_cubic-bezier(0.4,_0,_0.2,_1)_forwards]
            data-[state=open]:data-[side=right]:animate-[slideLeftAndFade_150ms_cubic-bezier(0.4,_0,_0.2,_1)_forwards]
            data-[state=open]:data-[side=left]:animate-[slideRightAndFade_150ms_cubic-bezier(0.4,_0,_0.2,_1)_forwards]
            data-[state=open]:data-[side=bottom]:animate-[slideUpAndFade_150ms_cubic-bezier(0.4,_0,_0.2,_1)_forwards]
          "
        >
          <div class="w-full drop-shadow dark:drop-shadow-[0_2px_5psx_theme(colors.zinc.200)] lg:w-auto lg:translate-y-[20px] lg:translate-x-1">
            <div
              v-if="arrow"
              class="layer-1 absolute left-1/2 h-6 w-6 origin-bottom-left -translate-x-1/2 -translate-y-2 -rotate-45 transform select-none rounded lg:bottom-1 lg:left-2 lg:translate-x-0 lg:translate-y-0"
            />
            <slot
              name="menu"
              class="max-w-screen layer-1 absolute bottom-full z-50 max-h-screen w-full flex-col gap-2 rounded-xl p-2 lg:bottom-0 lg:w-max"
            />
          </div>
        </PopoverContent>
      </PopoverPortal>
    </PopoverRoot>
  </div>
</template>

<style scoped lang="scss"></style>
