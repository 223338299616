<script setup lang="ts">
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'

const { timelineEl, dragging: timelineDragging, paddedTimelineWidth, timelinePadding } = useTimelineStore()
</script>

<template>
  <div
    ref="timelineEl"
    class="sl-timeline relative"
    :style="{
      width: `${paddedTimelineWidth}px`,
      paddingInline: `${timelinePadding}px`,
      touchAction: timelineDragging ? 'none' : 'auto',
    }"
  >
    <slot />
  </div>
</template>

<style scoped lang="scss"></style>
