import { useResetEditor } from '@/Hooks/useResetEditor'

const twitchClipRegex = /(twitch\.tv\/\w+\/clip\/|clips\.twitch\.tv\/)(?<clipslug>[\w-]+)/
const youtubeClipRegex = /(youtube\.com)\/clip\/(?<clipslug>[\w-]+)/
const youtubeVideoRegex = /(youtube.com\/watch\?v=|youtu.be\/)(?<clipslug>[\w-]+)/
const oldKickClipRegex = /(kick\.com)\/\w*\?clip=(?<clipslug>[\w]+)/
const newKickClipRegex = /(kick\.com)\/\w+\/clips\/(?<clipslug>[\w]+)/

export function validateClipUrl(clipUrl: string | undefined) {

  function tryParseClipId(expression: RegExp) {
    if (clipUrl && expression.test(clipUrl)) {
      return clipUrl.match(expression)?.groups?.['clipslug']
    }
    return undefined
  }

  const twitchClipId = tryParseClipId(twitchClipRegex)
  if (twitchClipId) {
    return {
      route: { name: 'editor', params: { clipId: twitchClipId, source: 'twitch-clip' } },
      error: null,
    }
  }

  const youtubeClipId = tryParseClipId(youtubeClipRegex)
  if (youtubeClipId) {
    return {
      route: { name: 'editor', params: { clipId: youtubeClipId, source: 'youtube-clip' } },
      error: null,
    }
  }

  const kickClipId = tryParseClipId(newKickClipRegex) ?? tryParseClipId(oldKickClipRegex)
  if (kickClipId) {
    return {
      route: { name: 'editor', params: { clipId: kickClipId, source: 'kick-clip' } },
      error: null,
    }
  }

  const youtubeVideoId = tryParseClipId(youtubeVideoRegex)
  if (youtubeVideoId) {
    return {
      youtubeVideoId: youtubeVideoId,
      route: null,
      error: {
        title: `Oops! Looks like you've entered a YouTube video URL. We need a clip URL from Twitch, YouTube, or Kick.`,
        message: 'clip-url-explanation',
      },
    }
  }

  if (!clipUrl?.trim()) {
    return {
      route: null,
      error: {
        title: 'Oops! Looks like you forgot to enter a URL. We need a clip URL from Twitch, YouTube, or Kick.',
        message: 'clip-url-explanation',
      },
    }
  }

  return {
    route: null,
    error: {
      title: 'Oops! Looks like you\'ve entered a URL we cannot process. We need a clip URL from Twitch, YouTube, or Kick.',
      message: 'clip-url-explanation',
    },
  }
}

export function useClipUrlValidator() {
  const resetEditor = useResetEditor()
  return function (clipUrl: string | undefined) {
    resetEditor()
    return validateClipUrl(clipUrl)
  }
}
