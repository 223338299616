<template>
  <div class="flex w-full flex-col items-center">
    <h3 class="mb-4 mt-2 py-0 text-4xl font-bold text-company-primary">{{ header }}</h3>

    <div :class="lottieStyles" class="mb-4 flex flex-shrink-0 items-center justify-center rounded-full">
      <lottie-animation :url="lottieUrl" :auto-play="true" />
    </div>
    <p class="text-center text-xl font-bold" v-if="title" v-html="title"></p>
    <slot name="footer"></slot>
  </div>
</template>
<script>
import LottieAnimation from '../../LottieAnimation.vue'

export default {
  components: { LottieAnimation },
  props: {
    header: { type: String, default: 'Success!' },
    title: { type: String },
    lottieUrl: { type: String, default: '/lottie/high-five.json' },
    lottieStyles: { type: String, default: 'w-24 h-24' },
  },
}
</script>
