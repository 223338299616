class TimeSpan extends Number {
  constructor(ms: number) {
    super(ms)
  }

  public inSeconds() {
    return this.valueOf() / 1000
  }

  public inMinutes() {
    return this.inSeconds() / 60
  }

  public inHours() {
    return this.inMinutes() / 60
  }

  public inDays() {
    return this.inHours() / 24
  }
}

export const maxOutputDuration = new TimeSpan(180_000)
export const defaultTrimLength = new TimeSpan(180_000)
