// useLocalstorage
import { ref } from 'vue'

export const useLocalStorage = <T>(key: string, initialValue: T) => {
  let _initialValue = initialValue
  try {
    const item = localStorage.getItem(key)
    if (item) {
      _initialValue = JSON.parse(item)
    }
  } catch (e) {
    console.error(e)
  }

  const value = ref(_initialValue)
  const set = (newValue: T) => {
    // @ts-ignore
    value.value = newValue
    localStorage.setItem(key, JSON.stringify(newValue))
  }

  return [value, set] as const
}
