<script setup lang="ts">
import { ref, computed } from 'vue'
import { useRouter, RouterLink } from 'vue-router'
import { postSupportForm } from '@/queries/n8n'
import { Input } from '@/components/ui/input'
import { CardFooter } from '@/components/ui/card'
import { Button } from '@/components/ui/button'

const description = ref('')
const router = useRouter()

const textarea = ref<HTMLTextAreaElement>()

const proceed = async () => {

  validate()
  if (!isValid.value) {
    textarea.value?.focus()
    return false
  }

  await postSupportForm(description.value, 'TechnicalDifficultiesFeedback')
  await router.push({ name: 'OfferHelp' })
}

const minLength = 20
const maxLength = 2_000

const minLengthValid = computed(() => {
  return description.value.length >= minLength
})

const maxLengthValid = computed(() => {
  return description.value.length <= maxLength
})

const isValid = ref(true)
function validate() {
  isValid.value = description.value.trim().length > 0 && minLengthValid.value && maxLengthValid.value
}
</script>

<template>
  <form @submit.prevent="proceed" class="panel relative min-h-[70vh] items-center justify-center">
    <div class="mx-auto flex w-full flex-col gap-8 lg:w-1/2">
      <div>
        <h1 class="text-3xl font-bold text-company-primary">What issue were you experiencing?</h1>
        <p>Sharing more can help us improve for you and other streamers.</p>
      </div>

      <div class="flex flex-col gap-2">

        <Input as="div" class="h-auto flex flex-col" :class="{ 'input-error': !isValid }">
          <textarea
            ref="textarea"
            v-model.trim="description"
            @change="isValid = true"
            class="bg-transparent border-none resize-none w-full h-full outline-none"
            placeholder="Could you describe in more detail what you experienced?"
            rows="5"
          />
          <span class="text-sm text-brand-state-text-placeholder self-end">{{ description.trim().length }} / {{ maxLength }}</span>
        </Input>

        <template v-if="!isValid">
          <span class="text-brand-state-error-text" v-if="!minLengthValid">
            Please enter at least {{ minLength }} characters
          </span>

          <span class="text-brand-state-error-text" v-if="!maxLengthValid">
            Please enter no more than {{ maxLength }} characters
          </span>
        </template>
      </div>

      <div class="flex w-full justify-between">
        <Button variant="outline" :as="RouterLink" :to="{ name: 'CancelSubscription' }">
          Back
        </Button>
        <Button>Continue</Button>
      </div>
    </div>
  </form>
</template>
