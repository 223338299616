
export function stringToSemiRandomNumber(str: string, min: number, max: number): number {
  // Hash the input string to get a numeric value
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i)
    hash = hash & hash // Convert to 32bit integer
  }

  // Normalize the hash to a value between 0 and 1
  const normalizedHash = (hash >>> 0) / 0xffffffff

  // Scale the normalized hash to the desired range
  const randomNumber = min + normalizedHash * (max - min)

  return randomNumber
}

export const colorOptions = [
  {
    "bg": "134 62% 31%",  // Dark Green
    "border": "134 61% 21%",
    "handle": "129 67% 13%"
  },
  {
    "bg": "188 78% 31%",  // Dark Cyan
    "border": "189 79% 23%",
    "handle": "192 81% 15%"
  },
  {
    "bg": "11 100% 40%",  // Dark Red
    "border": "11 100% 30%",
    "handle": "11 100% 20%"
  },
  {
    "bg": "274 100% 40%",  // Dark Purple
    "border": "273 100% 30%",
    "handle": "273 100% 20%"
  },
  {
    "bg": "335 100% 40%",  // Dark Pink
    "border": "335 100% 30%",
    "handle": "335 100% 20%"
  },
  {
    "bg": "165 100% 25%",  // Dark Teal
    "border": "167 100% 17%",
    "handle": "166 100% 12%"
  },
  {
    "bg": "313 100% 25%",  // Dark Magenta
    "border": "312 100% 17%",
    "handle": "312 100% 12%"
  },
  {
    "bg": "5 100% 69%",  // Coral
    "border": "5 55% 55%",
    "handle": "3 46% 41%"
  },
  {
    "bg": "210 100% 56%",  // Dodger Blue
    "border": "209 81% 44%",
    "handle": "209 84% 33%"
  },
  {
    "bg": "134 61% 41%",  // Green
    "border": "134 62% 31%",
    "handle": "134 61% 21%"
  },
  {
    "bg": "263 90% 51%",  // Indigo
    "border": "263 88% 40%",
    "handle": "263 88% 30%"
  },
  {
    "bg": "45 100% 51%",  // Yellow
    "border": "45 94% 41%",
    "handle": "46 95% 31%"
  },
  {
    "bg": "332 79% 58%",  // Pink
    "border": "331 58% 44%",
    "handle": "331 60% 32%"
  },
  {
    "bg": "27 98% 54%",  // Orange
    "border": "26 85% 43%",
    "handle": "16 86% 32%"
  },
  {
    "bg": "162 73% 46%",  // Teal
    "border": "167 72% 35%",
    "handle": "177 69% 23%"
  }
] as const

export function removeTags(str) {
  if (str === null || str === '' || str === undefined) return ''
  else str = str.toString()

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, '')
}
