<script setup lang="ts">
import { Dialog, DialogTrigger, DialogContent, DialogClose, DialogTitle, DialogDescription } from '@/components/ui/dialog'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { Button } from '@/components/ui/button'
import DiscordLogo from '@/components/Icons/DiscordLogo.vue'
import settings from '@/data/settings'
import { X } from 'lucide-vue-next'

const open = defineModel<boolean>('open', { required: true })
</script>

<template>
  <Dialog v-model:open="open">
    <DialogTrigger v-if="$slots.trigger">
      <slot name="trigger" />
    </DialogTrigger>
    <DialogContent class="items-center">
      <div class="w-32 h-32 overflow-hidden pointer-events-none grid place-items-center">
        <LottieAnimation
          class="h-64 w-64 absolute inset-auto"
          url="/lottie/error-cross.json"
          :loop="false"
          :autoPlay="true"
        />
      </div>

      <header class="flex flex-col gap-1.5 max-w-sm">
        <DialogTitle as="h3" class="font-semibold text-xl md:text-2xl lg:text-3xl text-center"><slot name="title" /></DialogTitle>
        <DialogDescription as="p" class="text-sm font-light text-center"><slot name="description" /></DialogDescription>
      </header>
      
      <div class="flex flex-col gap-2 min-h-0">
        <slot />
      </div>

      <div class="flex items-center justify-center gap-4">
        <slot name="actions">
          <DialogClose>
            <Button variant="depressed">
              <X class="w-4 h-4" />
              Close
            </Button>
          </DialogClose>
        </slot>
      </div>

      <p class="flex gap-2 items-center justify-center text-sm font-light text-center bg-zinc-400 px-6 py-3 -mb-6 -mx-6 w-[calc(100%_+_3rem)] rounded-b-lg">
        For further assistance, please 
        <Button as="a" :href="settings.discordInviteUrl" variant="discord" size="sm">
          <DiscordLogo class="w-4 h-4 fill-current" /> Join our Discord
        </Button>
      </p>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss">

</style>
