<script setup lang="ts">
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import SimpleTooltip from '@/components-v2/data-display/SimpleTooltip.vue'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import IconSaxScissor from '@/components/Icons/iconsax/IconSaxScissor.vue'
import TimelineAiSplitButton from '@/areas/editor/timeline/header/TimelineAiSplitButton.vue'
import { Button } from '@/components/ui/button'
import IconSaxArrowRightStop from '@/components/Icons/iconsax/IconSaxArrowRightStop.vue'
import IconSaxArrowLeftStop from '@/components/Icons/iconsax/IconSaxArrowLeftStop.vue'

const editorFocusStore = useEditorFocusStore()
const videoStore = useVideoStore()
</script>

<template>
  <div
    class="flex min-h-0 flex-row items-center justify-around border-t-2 border-t-gray-50 dark:border-none py-2 text-company-primary-500"
  >
    <TimelineAiSplitButton />
    <SimpleTooltip>
      <template v-slot:trigger>
        <Button @click="editorFocusStore.splitFocusModel(videoStore.getExactTime())" variant="ghost" size="sm">
          <IconSaxScissor class="w-4 h-4 dark:text-white" />
          <span class="hidden md:inline">Split</span>
        </Button>
      </template>
      <template v-slot:content>
        <span>Split clip at current time (s)</span>
      </template>
    </SimpleTooltip>
    <SimpleTooltip>
      <template v-slot:trigger>
        <Button @click="editorFocusStore.pullModel(videoStore.getExactTime(), 'right')" variant="ghost" size="sm" class="h-auto p-2">
          <IconSaxArrowRightStop class="h-4 w-4 dark:text-white" />
        </Button>
      </template>
      <template v-slot:content>
        <span>Trim to the left</span>
      </template>
    </SimpleTooltip>
    <SimpleTooltip>
      <template v-slot:trigger>
        <Button @click="editorFocusStore.pullModel(videoStore.getExactTime(), 'left')" variant="ghost" size="sm" class="h-auto p-2">
          <IconSaxArrowLeftStop class="h-4 w-4 dark:text-white" />
        </Button>
      </template>
      <template v-slot:content>
        <span>Trim to the right</span>
      </template>
    </SimpleTooltip>
    <SimpleTooltip v-if="editorFocusStore.canDeleteFocusModel">
      <template v-slot:trigger>
        <Button @click="editorFocusStore.deleteFocusModel" variant="ghostDestructive" size="sm" class="h-auto p-2">
          <IconSaxTrash class="h-4 w-4 dark:text-red-500" />
        </Button>
      </template>
      <template v-slot:content>
        <span>Delete selected</span>
      </template>
    </SimpleTooltip>
  </div>
</template>

<style scoped>
.btn.btn-ghost {
  background-color: transparent;
  border-color: transparent;
  padding-inline: 4px;
  gap: 0.25rem;
  display: flex;
  font-weight: 400;
}

.btn.btn-ghost.btn-info {
  background-color: #f2fcfe;
  color: #3b82f6;
}

.btn.btn-ghost.btn-success {
  background-color: #f0fdf4;
  color: #097c55;
}

.btn.btn-ghost:hover {
  background: #f3f4f6;
  border-color: transparent;
  color: black;
}

#zoomSlider {
  direction: rtl;
}

button > svg {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

/* The slider itself */
.zoom-range {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  overflow: hidden;
  width: 2rem; /* Full-width */
  height: 7px; /* Specified height */
  cursor: pointer;
}

/* Mouse-over effects */
.zoom-range:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.zoom-range::-webkit-slider-thumb {
  --range-shdw: black;
  appearance: none;
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
  color: var(--range-shdw);
  background-color: black;
  border-radius: 100%;
  --filler-size: 100rem;
  --filler-offset: 0.2rem;
  box-shadow: calc(var(--filler-size) * -1 - var(--filler-offset)) 0 0 var(--filler-size);
}

.zoom-range::-moz-range-thumb {
  top: 50%;
  color: var(--range-shdw);
  --filler-size: 100rem;
  --filler-offset: rem;
  box-shadow: calc(var(--filler-size) * -1 - var(--filler-offset)) 0 0 var(--filler-size);
}

.zoom-range::-webkit-slider-runnable-track {
  background-color: #00000088;
}
</style>
