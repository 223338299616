<script lang="ts" setup>
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import TimeLineTrack from '@/modules/SLTimeline/TimeLineTrack.vue'
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import { useStickersStore } from '@/store/entity-system/useStickersStore'
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { computed } from 'vue'
import StickerNode from '@/components/Editor/Timeline/tracks/StickerNode.vue'
import { removeTags, colorOptions, stringToSemiRandomNumber } from './stickerHelpers'
import IconSaxTextBlock from '@/components/Icons/iconsax/IconSaxTextBlock.vue'
import { useEditorStickersStore } from '@/store/editor/editorStickers'
import TimeLineTrackNode from '@/modules/SLTimeline/TimeLineTrackNode.vue'
import IconSaxSticker from '@/components/Icons/iconsax/IconSaxSticker.vue'

const editorFocusStore = useEditorFocusStore()
const editorVideoStore = useEditorVideoStore()

const { durationMs } = useTimelineStore()

const stickersStore = useStickersStore()
const useWebcodecRenderer = useFeatureFlagEnabled('new-editor-v2')

const webcodecTextStickers = computed(() => stickersStore.entities.filter((sticker) => 'htmlContent' in sticker && sticker.type !== 'social'))
const webcodecImageStickers = computed(() => stickersStore.entities.filter((sticker) => !webcodecTextStickers.value.some((textSticker) => textSticker.id === sticker.id)))

const editorStickersStore = useEditorStickersStore()

const stickers = computed(() => {
  return editorStickersStore.selectedStickers.filter((sticker) => {
    return sticker.start !== undefined && sticker.end !== undefined && !sticker.isTextSticker
  })
})

const textStickers = computed(() => {
  return editorStickersStore.selectedStickers.filter((sticker) => {
    return sticker.start !== undefined && sticker.end !== undefined && sticker.isTextSticker
  })
})
</script>

<template>
  <template v-if="useWebcodecRenderer">
    <TimeLineTrack v-if="webcodecTextStickers.length > 0" class="relative h-6 w-full">
      <StickerNode
        v-for="sticker in webcodecTextStickers"
        :id="sticker.id"
        :key="sticker.id"
        :focus-type="FocusTypes.TEXTSTICKER"
      />
    </TimeLineTrack>
    <TimeLineTrack v-if="webcodecImageStickers.length > 0" class="relative h-6 w-full">
      <StickerNode
        v-for="sticker in webcodecImageStickers"
        :id="sticker.id"
        :key="sticker.id"
        :focus-type="FocusTypes.STICKER"
      />
    </TimeLineTrack>
  </template>
  <template v-else>
    <TimeLineTrack v-if="stickers.length > 0" class="relative h-6 w-full">
      <TimeLineTrackNode
        class="group cursor-grab rounded-[5px] border-solid outline-white ring-offset-2"
        :class="{
          'border-[var(--random-bg-color)] bg-[var(--random-bg-color)] opacity-50 ring-[var(--random-border-color)] hover:border-[var(--random-border-color)] hover:opacity-80': editorFocusStore.focus?.key !== sticker.key,
          'border-[var(--random-bg-color)] bg-[var(--random-bg-color)] ring-[var(--random-border-color)] hover:border-[var(--random-border-color)] hover:bg-[var(--random-border-color)] ': editorFocusStore.focus?.key === sticker.key,
        }"
        :style="{
          '--random-bg-color': colorOptions[Math.floor(stringToSemiRandomNumber(sticker.key, 0, colorOptions.length))].bg,
          '--random-border-color': colorOptions[Math.floor(stringToSemiRandomNumber(sticker.key, 0, colorOptions.length))].border,
          '--random-handle-color': colorOptions[Math.floor(stringToSemiRandomNumber(sticker.key, 0, colorOptions.length))].handle,
        }"
        v-for="sticker in stickers"
        :key="sticker.key"
        :start-ms="sticker.start!"
        :end-ms="sticker.end!"
        :min-start-ms="0"
        :max-end-ms="durationMs"
        :height="25"
        seek-behavior="seek"
        :active="editorFocusStore.focus?.key === sticker.key"
        @resizeStop="(start, end) => editorStickersStore.updateSticker(sticker.key, { start, end })"
        @dragStop="(start, end) => editorStickersStore.updateSticker(sticker.key, { start, end })"
        @activated="() => {
          editorFocusStore.setFocus(FocusTypes.STICKER, sticker.key)
          editorVideoStore._currentTime = sticker.start! / 1000 + 0.01
        }"
      >
        <div class="flex h-full w-full items-center overflow-hidden rounded-[5px] text-sm text-white">
          <span
            class="mx-3 inline-flex max-h-full w-full select-none flex-row items-center justify-center gap-1 overflow-hidden whitespace-nowrap py-0.5"
          >
            <IconSaxSticker class="mb-0.5 h-4 w-4" />
            <span>{{ removeTags(sticker.htmlContent) }}</span>
          </span>
        </div>
        <template #handles>
          <div class="flex h-full flex-row gap-0.5 px-1 py-1">
            <div
              class="h-full w-1 rounded"
              :class="{
                'bg-gray-800': !sticker.key,
                'bg-[var(--random-handle-color)] ': !!sticker.key,
              }"
            ></div>
            <div
              class="h-full w-1 rounded"
              :class="{
                'bg-gray-800': !sticker.key,
                'bg-[var(--random-handle-color)] ': !!sticker.key,
              }"
            ></div>
          </div>
        </template>
      </TimeLineTrackNode>
    </TimeLineTrack>
    <TimeLineTrack v-if="textStickers.length > 0" class="relative h-6 w-full">
        <TimeLineTrackNode
          class="group cursor-grab rounded-[5px] border-solid outline-white ring-offset-2"
          :class="{ 
            'border-[var(--random-bg-color)] bg-[var(--random-bg-color)] opacity-50 ring-[var(--random-border-color)] hover:border-[var(--random-border-color)] hover:opacity-80': editorFocusStore.focus?.key !== sticker.key,
            'border-[var(--random-bg-color)] bg-[var(--random-bg-color)] ring-[var(--random-border-color)] hover:border-[var(--random-border-color)] hover:bg-[var(--random-border-color)]': editorFocusStore.focus?.key === sticker.key,
          }"
          :style="{
            '--random-bg-color': colorOptions[Math.floor(stringToSemiRandomNumber(sticker.key, 0, colorOptions.length))].bg,
            '--random-border-color': colorOptions[Math.floor(stringToSemiRandomNumber(sticker.key, 0, colorOptions.length))].border,
            '--random-handle-color': colorOptions[Math.floor(stringToSemiRandomNumber(sticker.key, 0, colorOptions.length))].handle,
          }"
          v-for="(sticker, i) in textStickers"
          :key="sticker.key"
          :start-ms="sticker.start!"
          :end-ms="sticker.end!"
          :min-start-ms="0"
          :max-end-ms="durationMs"
          :height="25"
          seek-behavior="seek"
          :active="editorFocusStore.focus?.key === sticker.key"
          @resizeStop="(start, end) => editorStickersStore.updateSticker(sticker.key, { start, end })"
          @dragStop="(start, end) => editorStickersStore.updateSticker(sticker.key, { start, end })"
          @activated="() => {
            editorFocusStore.setFocus(FocusTypes.TEXTSTICKER, sticker.key)
            editorVideoStore._currentTime = sticker.start! / 1000 + 0.01
          }"
        >
          <div class="flex h-full w-full items-center overflow-hidden rounded-[5px] text-sm text-white">
            <span
              class="mx-3 max-h-full w-full select-none overflow-hidden whitespace-nowrap py-0.5 text-center align-middle"
            >
              <IconSaxTextBlock class="mb-0.5 h-4 w-4" />
              {{ removeTags(sticker.htmlContent) }}
            </span>
          </div>
          <template #handles>
            <div class="flex h-full flex-row gap-0.5 px-1 py-1">
              <div
                class="h-full w-1 rounded"
                :class="{
                  'bg-gray-800': !sticker.key,
                  'bg-[var(--random-handle-color)] ': !!sticker.key,
                }"
              ></div>
              <div
                class="h-full w-1 rounded"
                :class="{
                  'bg-gray-800': !sticker.key,
                  'bg-[var(--random-handle-color)] ': !!sticker.key,
                }"
              ></div>
            </div>
          </template>
        </TimeLineTrackNode>
      </TimeLineTrack>
  </template>
</template>
