<script setup lang="ts">
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import TimeLineTrackNode from '@/modules/SLTimeline/TimeLineTrackNode.vue'
import TimeLineTrack from '@/modules/SLTimeline/TimeLineTrack.vue'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import IconSaxElement2 from '@/components/Icons/iconsax/IconSaxElement2.vue'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'

const editorFocusStore = useEditorFocusStore()
const editorVideoStore = useEditorVideoStore()
const editorFeedStore = useEditorFeedDataStore()
const editorClipInfoStore = useEditorClipInfoStore()

const { triggerSeek } = useTimelineStore()
</script>

<template>
  <TimeLineTrack class="group relative h-6 w-full">
    <div class="bg-red-100/50 absolute inset-x-0 inset-y-1 rounded border border-red-100" />
    <TimeLineTrackNode
      class="cursor-grab rounded-[5px] border-none bg-purple-800 outline-white ring-purple-800 ring-offset-2 hover:bg-purple-700"
      v-for="(segment, i) in editorFeedStore.segments"
      :key="segment.id"
      :start-ms="segment.start"
      :end-ms="segment.end"
      :min-start-ms="editorFeedStore.segments[i - 1] ? editorFeedStore.segments[i - 1].end : 0"
      :max-end-ms="
        editorFeedStore.segments[i + 1] ? editorFeedStore.segments[i + 1].start : editorVideoStore._duration * 1000
      "
      :height="25"
      :active="editorFocusStore.focus?.type === 'segment' && editorFocusStore.focus?.key === segment.id"
      seek-behavior="seek"
      @resizeStart="() => editorFocusStore.setFocus('segment', segment.id)"
      @resizeStop="
        (start, end) => {
          if (start > editorVideoStore.currentTime || end < editorVideoStore.currentTime) triggerSeek(start)
          editorFeedStore.updateSegment(segment.id, { start, end })
        }
      "
      @dragStart="() => editorFocusStore.setFocus('segment', segment.id)"
      @dragStop="
        (start, end) => {
          if (start > editorVideoStore.currentTime || end < editorVideoStore.currentTime) triggerSeek(start)
          editorFeedStore.updateSegment(segment.id, { start, end })
        }
      "
      @activated="
        () => {
          editorFocusStore.setFocus('segment', segment.id)
        }
      "
    >
      <div class="flex h-full w-full items-center overflow-hidden rounded-[5px] text-sm font-normal text-white">
        <span
          class="mx-3 max-h-full w-full select-none overflow-hidden whitespace-nowrap py-0.5 text-center align-middle font-normal"
        >
          <IconSaxElement2 class="stroke- mb-0.5 h-4 w-4" />
          {{ editorClipInfoStore.title }}
        </span>
      </div>
      <template #handles>
        <div class="flex h-full flex-row gap-0.5 px-1 py-1">
          <div class="h-full w-1 rounded bg-purple-900"></div>
          <div class="h-full w-1 rounded bg-purple-900"></div>
        </div>
      </template>
    </TimeLineTrackNode>
  </TimeLineTrack>
</template>
