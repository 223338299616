<template>
  <svg width="27" height="23" viewBox="0 0 27 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.813 9.26855C12.8663 9.21515 12.9295 9.17279 12.9992 9.14388C13.0689 9.11498 13.1435 9.1001 13.2189 9.1001C13.2944 9.1001 13.369 9.11498 13.4387 9.14388C13.5084 9.17279 13.5716 9.21515 13.6249 9.26855L17.0652 12.7088C17.1728 12.8165 17.2333 12.9625 17.2333 13.1148C17.2333 13.267 17.1728 13.413 17.0652 13.5207C16.9575 13.6284 16.8115 13.6889 16.6592 13.6889C16.5069 13.6889 16.3609 13.6284 16.2533 13.5207L13.7923 11.0586V21.142C13.7923 21.2941 13.7319 21.4399 13.6244 21.5475C13.5169 21.655 13.371 21.7154 13.2189 21.7154C13.0669 21.7154 12.921 21.655 12.8135 21.5475C12.706 21.4399 12.6456 21.2941 12.6456 21.142V11.0586L10.1846 13.5207C10.1313 13.574 10.068 13.6163 9.99839 13.6452C9.92874 13.674 9.85408 13.6889 9.77869 13.6889C9.7033 13.6889 9.62864 13.674 9.55899 13.6452C9.48934 13.6163 9.42605 13.574 9.37274 13.5207C9.31943 13.4674 9.27714 13.4041 9.24829 13.3345C9.21944 13.2648 9.20459 13.1901 9.20459 13.1148C9.20459 13.0394 9.21944 12.9647 9.24829 12.8951C9.27714 12.8254 9.31943 12.7621 9.37274 12.7088L12.813 9.26855Z"
      fill="transparant"
      stroke="currentColor"
      stroke-width="1.42919"
    />
    <path
      d="M13.2243 0.416748C17.181 0.416748 20.5588 2.90307 21.8845 6.39955C24.3592 7.05369 26.1856 9.31135 26.1856 11.9945C26.1856 15.1928 23.5951 17.7834 20.3996 17.7834H20.3388C19.7314 17.7834 19.239 17.2909 19.239 16.6835C19.239 16.076 19.7314 15.5836 20.3388 15.5836H20.3996C20.8707 15.5848 21.3374 15.4926 21.7726 15.3122C22.2079 15.1319 22.603 14.867 22.9352 14.5329C23.6125 13.8556 23.9858 12.9525 23.9858 11.9945C23.9858 10.3678 22.8888 8.94086 21.3201 8.51827L20.226 8.22883L19.8265 7.17236C19.5776 6.51532 19.2303 5.89591 18.7932 5.33729C18.3619 4.78445 17.8525 4.29819 17.2765 3.89297C16.0869 3.05647 14.686 2.61362 13.2243 2.61362C11.7626 2.61362 10.3617 3.05647 9.17212 3.89297C8.59802 4.29688 8.08691 4.7836 7.65543 5.33729C7.21837 5.89591 6.87104 6.51243 6.62212 7.17236L6.21979 8.23172L5.1228 8.51827C4.37025 8.71799 3.69295 9.16663 3.20958 9.78314C2.73778 10.3881 2.47729 11.1059 2.45124 11.87C2.4194 12.8483 2.7812 13.7919 3.46139 14.4924C4.14448 15.1957 5.06202 15.5836 6.03744 15.5836H6.09822C6.70567 15.5836 7.19811 16.076 7.19811 16.6835C7.19811 17.2909 6.70567 17.7834 6.09822 17.7834H6.03744C2.84198 17.7834 0.251465 15.1928 0.251465 11.9945C0.251465 9.30845 2.08364 7.0479 4.56417 6.39665C5.88982 2.90017 9.26763 0.416748 13.2243 0.416748Z"
      fill="currentColor"
    />
  </svg>
</template>
