<script setup lang="ts">
import { computed, nextTick, ref } from 'vue'
import { useMouseInElement, useParentElement } from '@vueuse/core'
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import { findClosestFittingLocation } from '@/modules/SLVideoplayer/helpers'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { canGuardWithPopup } from '@/Hooks/useGuard'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'

const editorFocusStore = useEditorFocusStore()
const segmentsStore = useSegmentsStore()
const zooms = segmentsStore.whereIsZoom()

const { pxToMs, msToPx, durationMs, dragging } = useTimelineStore()

const parentEl = useParentElement()
const { isOutside, elementX } = useMouseInElement(parentEl)

const width = ref(52)
const popupOpen = ref(false)
let storedLastPosition: { point: number; distance: number } | undefined

const positionOnTimeline = computed(() => {
  if (popupOpen.value && storedLastPosition != undefined) {
    return storedLastPosition
  }

  const closestFittingLocation = findClosestFittingLocation(
    elementX.value,
    width.value,
    0,
    msToPx(durationMs.value),
    zooms.value.map((zoom) => ({
      start: msToPx(zoom.startMs, true),
      end: msToPx(zoom.endMs, true),
    }))
  )

  if (popupOpen.value && storedLastPosition == undefined) {
    storedLastPosition = closestFittingLocation
    return storedLastPosition
  }

  // reset storedLastPosition if the popover is closed
  storedLastPosition = undefined

  return (
    closestFittingLocation || {
      point: elementX.value,
      distance: 0,
    }
  )
})

const left = computed(() => positionOnTimeline.value.point)

async function addZoom() {

  if (!canGuardWithPopup('zoom-segments')) {
    return
  }

  const targetMs = pxToMs(left.value, true)
  const duration = pxToMs(width.value, true)
  
  const id = segmentsStore.createZoom(targetMs, duration)
  await nextTick()

  editorFocusStore.setFocus('zoom', id)
  popupOpen.value = false
}
</script>

<template>
  <button
    ref="node"
    class="peer h-full absolute rounded-lg border-none bg-company-primary-300 hover:bg-company-primary-200 ring-company-primary-300] px-3 text-white"
    @click="addZoom"
    v-if="popupOpen || (!isOutside && positionOnTimeline.distance <= 0 && dragging?.type !== 'node')"
    :style="{
      left: `${left}px`,
      transform: `translateX(-50%)`,
      width: `${width}px`,
    }"
  >
    <IconSaxAdd class="w-4 h-4" />
  </button>
</template>

<style scoped lang="scss"></style>
