/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * StreamLadder Publisher Api
 * OpenAPI spec version: v1
 */

export type YouTubeVisibility = (typeof YouTubeVisibility)[keyof typeof YouTubeVisibility]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const YouTubeVisibility = {
  Public: 'Public',
  Private: 'Private',
  Unlisted: 'Unlisted',
} as const
