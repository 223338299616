<template>
  <Tile
    @click="selectSticker"
    @resize="onResize"
    :aspect="0.6"
    :class="{ 'text-sticker': isTextSticker, '!border-brand-state-active-border': isFocused }"
    feature="text"
  >
    <component
      ref="sticker"
      :is="sticker.component"
      :html-content="htmlContent"
      :color="selectedColor"
      :variant="sticker.variant"
      :icon="isMultiSticker ? JSON.stringify(activeSocials) : sticker.icon"
      :editable="false"
      @stickerLoaded="() => scaleSticker()"
      class="sticker [&_*]:!whitespace-pre"
      style="break-after: always"
    />

    <ColorPicker class="mt-2" v-if="sticker.colors" v-model="selectedColor" :options="sticker.colors" />

    <OverlayBadges :is-new="isNew" :tags="sticker.tags" />
  </Tile>
</template>

<script>
import ColorPicker from './ColorPicker.vue'
import EventBus from '../../eventBus'
import { mapState } from 'pinia'
import { useEditorStickersStore } from '@/store/editor/editorStickers'
import Tile from '@/components/Tile.vue'
import OverlayBadges from '@/components/OverlayBadges.vue'
import { useEditorMainStore } from '@/store/editor/editorMain'
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import { useStickersStore } from '@/store/entity-system/useStickersStore'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { useToast } from '@/Hooks/useToast'
import ToastEvents from '@/events/toastEvents'
import { useGuard } from '@/Hooks/useGuard'
import upgradeEvents from '@/events/upgradeEvents'
import compatabilityChecker from '@/services/compatabilityChecker.js'

export default {
  components: {
    OverlayBadges,
    Tile,
    ColorPicker,
  },
  props: {
    htmlContent: {
      type: String,
      required: false,
    },
    // One of the objects in StickerLibrary.js or textLibrary.js
    sticker: {
      type: Object,
      required: true,
    },
    timing: {
      type: String,
      required: false,
      default: 'default'
    },
    isTextSticker: {
      type: Boolean,
      default: false,
    },
    isMultiSticker: {
      type: Boolean,
      default: false,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    hasEditableText: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const editorFocusStore = useEditorFocusStore()
    const editorStickerStore = useEditorStickersStore()

    return {
      editorFocusStore,
      editorStickerStore
    }
  },
  computed: {
    ...mapState(useEditorStickersStore, ['activeSocials']),
    focusedSticker() {
      if (this.editorFocusStore.focus?.type === FocusTypes.TEXTSTICKER) {
        return this.editorStickerStore.selectedStickers.find(
          (sticker) => sticker.key === this.editorFocusStore.focus?.key
        )
      } else {
        return undefined
      }
    },
    isFocused() {
      return this.sticker.variant === this.focusedSticker?.variant && this.sticker.variant !== undefined
    },
  },
  data() {
    return {
      selectedColor: null,
      containerSize: null,
    }
  },
  mounted() {
    this.scaleSticker(this.containerSize)
  },
  created() {
    if (this.sticker.colors) {
      const keys = Object.keys(this.sticker.colors)
      this.selectedColor = keys.length > 0 ? keys[0] : null
    }
  },
  methods: {
    onResize(size) {
      this.containerSize = size
      this.scaleSticker(this.containerSize)
    },
    scaleSticker() {
      if (!this.$refs.sticker || !this.containerSize) {
        return
      }

      const stickerElement = this.$refs.sticker.$el
      const targetWidth = this.containerSize.width * (2 / 3)
      if (stickerElement.clientWidth >= targetWidth) {
        const stickerScale = targetWidth / stickerElement.clientWidth
        stickerElement.style.transform = `scale(${stickerScale})`
      } else {
        stickerElement.style.transform = null
      }
    },
    selectSticker() {

      const mainStore = useEditorMainStore()
      const editorVideoStore = useEditorVideoStore()

      const newSticker = {
        component: this.sticker.component,
        componentName: this.sticker.key,
        color: this.selectedColor,
        variant: this.sticker.variant,
        icon: this.isMultiSticker ? JSON.stringify(this.activeSocials) : this.sticker.icon,
        savedSticker: false,
        x: -1,
        y: -1,
        start: 0,
        end: mainStore.videoDuration,
        scale: -1,
        htmlContent: this.htmlContent,
        isTextSticker: this.isTextSticker,
        hasEditableText: this.hasEditableText,
        key: '',
        visible: true,
        animationStyle: 'none',
        animationTime: undefined,
      }
      
      const clientRect = this.$refs.sticker.$el.getBoundingClientRect()
      const drawWidth = 0.8 * 1080
      const drawHeight = clientRect.height * (drawWidth / clientRect.width)

      // Scroll to top when a sticker is added
      if (compatabilityChecker.isMobile()) {
        window.scrollTo(0, 0);
      }

      EventBus.$emit('editor/stickers/added', newSticker)
      const stickersStore = useStickersStore()

      const videoDurationMs = editorVideoStore._duration * 1000
      const startMs = this.timing === 'outro' ? videoDurationMs - 5000 : 0
      const endMs = this.timing === 'intro' ? 5000 : videoDurationMs
      const area = {
        x: 0.1,
        y: this.htmlContent !== 'Enter Text\n here' ? 0.1 : 0.1 + Math.random() * (0.9 - drawHeight / 1920),
        width: drawWidth / 1080,
        height: drawHeight / 1920,
      }

      if (this.isTextSticker) {
        const stickerId = stickersStore.createTextSticker({
          key: this.sticker.key,
          variant: this.sticker.variant,
          startMs: startMs,
          endMs: endMs,
          color: this.selectedColor,
          icon: this.isMultiSticker ? JSON.stringify(this.activeSocials) : this.sticker.icon,
          textContent: this.htmlContent,
          area: area
        })
        setTimeout(() => useEditorFocusStore().setFocus(FocusTypes.TEXTSTICKER, stickerId), 0)
      } else {
        const stickerId = stickersStore.createSocialSticker({
          key: this.sticker.key,
          startMs: startMs,
          endMs: endMs,
          color: this.selectedColor,
          icon: this.isMultiSticker ? JSON.stringify(this.activeSocials) : this.sticker.icon,
          textContent: this.htmlContent,
          area: area
        })
        setTimeout(() => useEditorFocusStore().setFocus(FocusTypes.STICKER, stickerId), 0)
      }

      if (this.isTextSticker) {
        this.$trackEvent('Editor TextSticker Added', {
          Variant: newSticker.variant,
          Color: newSticker.color,
          Tags: this.sticker.tags,
        })
      } else if (this.isMultiSticker) {
        this.$trackEvent('Editor MultiSticker Added', {
          Color: newSticker.color,
          Component: newSticker.componentName,
          socials: this.activeSocials,
          Tags: this.sticker.tags,
        })
      } else {
        this.$trackEvent('Editor Sticker Added', {
          Color: newSticker.color,
          Component: newSticker.componentName,
          Icon: newSticker.icon,
          Tags: this.sticker.tags,
        })
      }
      this.$emit('stickerPicked')
    },
  },
  watch: {
    htmlContent() {
      this.scaleSticker()
    },
    focusedSticker() {
      if (!this.focusedSticker || this.sticker.variant !== this.focusedSticker?.variant) return
      this.selectedColor = this.focusedSticker?.color
    },
    selectedColor() {
      if (this.isFocused) {
        this.editorStickerStore.updateSticker(this.focusedSticker?.key, {
          color: this.selectedColor,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.sticker {
  pointer-events: none;
}
</style>
