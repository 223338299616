import {
  type ApiErrorMessage,
  reconnectionRequiredErrorCodes,
  useExternalApiErrorCodes
} from '@/components-v2/content-publisher/hooks/useExternalApiErrorCodes'
import toastEvents from '@/events/toastEvents'
import { useToast } from '@/Hooks/useToast'
import { useContentPublisherStore } from '@/components-v2/content-publisher/_store'
import { addDays, startOfDay, subDays } from 'date-fns'
import { OverallPostStatus, type PostDto } from '@/apis/streamladder-publisher/model'
import { computed, ref, watch } from 'vue'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { useRouter } from 'vue-router'
import { useUserInfoStore } from '@/store/user/userInfo'
import { smartJoin } from '@/helpers/smartJoin'
import { uniq } from 'lodash-es'

export const useContentPublisherToasts = () => {

  const { showToast } = useToast();
  const findExternalApiError = useExternalApiErrorCodes()

  const router = useRouter();
  const store = useContentPublisherStore();
  const userInfo = useUserInfoStore();
  const shownFailedPostsIds = ref<string[]>([]);
  const hasLoaded = ref(false);

  const differenceInMs = (endOfDay: Date, value: Date) => {
    return endOfDay.getTime() - value.getTime();
  };

  const useCurrentDay = () => {

    const currentDay = ref(new Date());

    const updateAfterMidnight = () => {
      setTimeout(() => {
        currentDay.value = new Date();
        updateAfterMidnight();
      }, differenceInMs(startOfDay(addDays(currentDay.value, 1)), currentDay.value));
    };

    updateAfterMidnight();

    return currentDay;
  };

  const currentDay = useCurrentDay();
  const twoWeeksAgo = computed(() => subDays(currentDay.value, 14));
  const twoWeeksFromNow = computed(() => addDays(currentDay.value, 14));

  const postHasFailed = (post: PostDto) => {
    return post.status && (post.status.overallStatus === OverallPostStatus.Failed
      || post.status.overallStatus === OverallPostStatus.PartialFailure);
  };

  const postIsScheduled = (post: PostDto) => {
    return post.status && (post.status.overallStatus === OverallPostStatus.New
      || post.status.overallStatus === OverallPostStatus.Scheduled
      || post.status.overallStatus === OverallPostStatus.Publishing);
  };

  const postIsScheduledWithinTwoWeeksAgo = (post: PostDto) => {
    return post.scheduledAt
      && new Date(post.scheduledAt).getTime() >= twoWeeksAgo.value.getTime()
      && new Date(post.scheduledAt).getTime() <= twoWeeksFromNow.value.getTime();
  };

  const failedPosts = computed(() => store.posts
    .filter(postHasFailed)
    .filter(postIsScheduledWithinTwoWeeksAgo));

  const showSummaryOfAllFailedPosts = () => {

    hasShownSummaryOnce.value = true;

    if (failedPosts.value.length < 2) {
      return;
    }

    const failedPostsCount = failedPosts.value.length;

    showToast({
      type: toastEvents.TOAST_ERROR,
      title: `${failedPostsCount} posts failed to schedule or publish 😿`,
      subtitle: 'Please inspect the posts for more info.',
      timeout: 20000,
      view: async () => {
        if (isInEditor.value) {
          window.open(router.resolve({ name: dashboardRouteNames.contentPublisher.root }).href, '_blank');
        } else {
          await router.push({ name: dashboardRouteNames.contentPublisher.root, });
          await router.push({
            name: dashboardRouteNames.contentPublisher.root,
            query: {
              date: failedPosts.value[0].scheduledAt,
            }
          });
        }
      },
      viewTitle: 'View',
    });

    shownFailedPostsIds.value = failedPosts.value.map((post) => post.id!);
  };

  const showExternalApiErrorToasts = (failedPosts: PostDto[]) => {

    for (const post of failedPosts) {
      for (const target of post.targets!) {

        const error = findExternalApiError(target.status?.code);

        if (!error || wasAlreadyDisplayed(error) || isGenericError(error)) {
          continue
        }

        apiErrors.value.push(error)

        const account = userInfo.allSocials.find((account) => account.id === target.accountId)
        if (!account || !target.status?.code) {
          continue
        }

        const accountRequiresReconnection = reconnectionRequiredErrorCodes.includes(target.status.code)

        if (!target.status?.lastUpdated || !account.updatedAt) {
          continue
        }

        const postTargetUpdatedOn = new Date(target.status.lastUpdated).getTime()
        const accountUpdatedOn = new Date(account.updatedAt).getTime()

        const accountHasBeenReconnectedAfterPostFailed = accountRequiresReconnection && postTargetUpdatedOn < accountUpdatedOn
        if (accountHasBeenReconnectedAfterPostFailed) {
          continue
        }

        showToast({
          type: toastEvents.TOAST_ERROR,
          title: `${error.title}: ${account.displayName}`,
          timeout: 0,
          subtitle: error.description,
          view: () => {
            router.push({
              name: dashboardRouteNames.support,
            })
          },
          viewTitle: 'Support',
        })
      }
    }
  };

  function wasAlreadyDisplayed(error: ApiErrorMessage): boolean {
    return error && apiErrors.value.includes(error)
  }

  function isGenericError(error: ApiErrorMessage): boolean {
    return error && error.title === 'Something went wrong.'
  }

  const latestToastShownTime = ref<number>(new Date().getTime());

  const showToastForEveryFailedPostIn = (failedPosts: PostDto[]) => {

    if (failedPosts.length < 1) {
      return;
    }

    for (const post of failedPosts) {

      const date = new Date(post.scheduledAt!);
      const formattedDate = date.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      });

      const fiveSecondsAgo = new Date().getTime() - 5000;
      if (latestToastShownTime.value >= fiveSecondsAgo && failedPosts.length > 1) {

        showToast({
          type: toastEvents.TOAST_ERROR,
          title: 'More posts are failing to schedule or publish 😿',
          subtitle: 'Please inspect the posts for more info.',
          timeout: 20000,
          view: async () => {
            if (isInEditor.value) {
              window.open(router.resolve({ name: dashboardRouteNames.contentPublisher.root }).href, '_blank');
            } else {
              await router.push({
                name: dashboardRouteNames.contentPublisher.root,
                query: {
                  date: failedPosts[0].scheduledAt,
                }
              });
            }
          },
          viewTitle: 'View',
        });

        latestToastShownTime.value = new Date().getTime();
        shownFailedPostsIds.value.push(...failedPosts.map(post => post.id!));

        return;
      } else {
        const targets = post.targets!.map((target) => target.socialMedia);
        showToast({
          type: toastEvents.TOAST_ERROR,
          title: `Can't schedule or post a clip to ${smartJoin(uniq(targets) as string[])}.`,
          timeout: 20000,
          subtitle: `Post is or was scheduled for ${formattedDate}. Please inspect the post for more info.`,
          view: async () => {
            if (isInEditor.value) {
              window.open(router.resolve({ name: dashboardRouteNames.contentPublisher.root }).href, '_blank');
            } else {
              await router.push({ name: dashboardRouteNames.contentPublisher.root, });
              await router.push({
                name: dashboardRouteNames.contentPublisher.root,
                query: {
                  post: post.id,
                  date: post.scheduledAt,
                },
              });
            }
          },
          viewTitle: 'View',
        });
      }

      shownFailedPostsIds.value.push(post.id!);
      latestToastShownTime.value = new Date().getTime();
    }
  };

  const isInEditor = ref(false);

  const apiErrors = ref<ApiErrorMessage[]>([]);

  const newOrRetriedPosts = computed(() => store.posts
    .filter(postIsScheduled)
    .filter(postIsScheduledWithinTwoWeeksAgo));

  const hasShownSummaryOnce = ref(false);

  watch(router.currentRoute, (currentRoute) => {
    isInEditor.value = currentRoute.matched.some((route) => route.name?.toString().includes('editor'));
  }, { immediate: true });

  const isAuthenticated = computed(() => userInfo.isAuthenticated);

  watch(isAuthenticated, async () => {
    if (isAuthenticated.value && !hasShownSummaryOnce.value) {
      await store.fetch();
      showSummaryOfAllFailedPosts();
      hasLoaded.value = true;
    }
  }, { immediate: true });

  watch(newOrRetriedPosts, (newOrRetriedPosts) => {
    for (const post of newOrRetriedPosts) {
      if (shownFailedPostsIds.value.includes(post.id!)) {
        shownFailedPostsIds.value = shownFailedPostsIds.value.filter((id) => id !== post.id);
      }
    }
  }, { immediate: true });

  watch([() => failedPosts.value, () => hasLoaded.value], ([failedPosts, hasLoaded]) => {
    if (hasLoaded) {
      showExternalApiErrorToasts(failedPosts);
    }
  }, { immediate: true });

  watch([() => failedPosts.value, () => hasLoaded.value, () => shownFailedPostsIds.value], ([failedPosts, hasLoaded, shownFailedPostsIds]) => {
    if (hasLoaded) {
      const newFailedPosts = failedPosts.filter((post) => !shownFailedPostsIds.includes(post.id!));
      showToastForEveryFailedPostIn(newFailedPosts);
    }
  });
};