import type { DefineComponent, Ref } from 'vue'
import type { ComponentProps } from './types'
import { markRaw, reactive } from 'vue'

export type UseDialogWrapperReturn = {
  DialogsStore: DialogData<any>[]
  addDialog: (dialogData: DialogData<any>) => void
  removeDialog: (id: string) => void
  removeAll: () => void
}

export type DialogData<C extends DefineComponent<any, any, any, any, any, any>> = {
  id: string
  dialog: C
  isRevealed: Ref<boolean>
  confirm: (props?: ComponentProps<C>) => void
  cancel: (props?: ComponentProps<C>) => void
  props: ComponentProps<C>
  close: () => void
  revealed: Ref<boolean>
}

const DialogsStore: DialogData<any>[] = reactive([])

export const useDialogStore = function (): UseDialogWrapperReturn {
  const addDialog = function (dialogData: DialogData<any>) {
    DialogsStore.push(markRaw(dialogData))
  }

  const removeDialog = function (id: string) {
    const index = DialogsStore.findIndex((dialog) => dialog.id == id)
    DialogsStore.splice(index, 1)
  }

  const removeAll = function () {
    DialogsStore.splice(0)
  }

  return {
    DialogsStore,
    addDialog,
    removeDialog,
    removeAll,
  }
}
