<template>
  <div class="mb-8 flex w-full flex-col items-start gap-4 md:flex-row">
    <!-- Promotion section -->
    <div class="panel w-full 2xl:p-16">
      <div class="mb-16 flex flex-col lg:flex-row lg:gap-16">
        <div class="bg-mauve min-h-300 relative w-[300px] flex-shrink-0 overflow-hidden rounded-2xl">
          <LottieAnimation url="/lottie/cat-socials.json" class="aspect-[4/5]" />
        </div>
        <div class="flex flex-col gap-4">
          <h2 class="text-3xl leading-snug">We're sorry to hear that you didn't get enough use out of StreamLadder.</h2>
          <p class="text-lg font-light text-[#4D287F]">
            Did you know that social media can be a game-changer for your stream growth?
          </p>

          <ol class="flex flex-col gap-2 text-sm">
            <Feature type="gained">Average increase of 40% more views on their streams.</Feature>
            <Feature type="gained">Convert unlimited videos.</Feature>
            <Feature type="gained">No watermark.</Feature>
          </ol>
        </div>
      </div>

      <div class="mb-16 flex flex-col">
        <h2 class="mb-4 text-3xl font-bold">Some useful blogs</h2>
        <div class="grid grid-cols-1 gap-x-4 gap-y-4 lg:grid-cols-3">
          <blog-post-card v-for="blogItem in blogItems" :key="blogItem.id" :blog-post="blogItem" />
        </div>
      </div>

      <div class="flex flex-col text-center">
        <h2 class="mb-4 text-3xl font-bold">Get help from our community</h2>
        <p class="mx-auto mb-8 max-w-[680px] text-[#4D287F]">
          We understand that you may not be using StreamLadder to its full potential, but that's what our community on
          Discord is for! We have a group of like-minded streamers who come together to share tips, tricks, and
          strategies for growing their channels on social media.
        </p>

        <div class="flex w-full justify-center">
          <div class="flex flex-col self-start">
            <a :href="settings.discordInviteUrl" class="btn-lg btn gap-2 border-[#5B61F3] bg-[#5B61F3] text-white">
              Join us at Discord
              <discord-logo class="h-6 w-6 fill-current" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- end promotion section -->

    <cancel-options-panel :offer-keep-subscription="true" />
  </div>
</template>
<script lang="ts" setup>
import DiscordLogo from '@/components/Icons/DiscordLogo.vue'
import settings from '@/data/settings'
import CancelOptionsPanel from '@/components/Account/Cancel/CancelOptionsPanel.vue'
import BlogPostCard from '@/components-v2/modules/Blog/BlogPostCard.vue'
import { useBlogItems } from '@/queries/useBlogItems'
import Feature from '@/components-v2/lists/Feature.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'

const { data: blogItems } = useBlogItems(3)
</script>
