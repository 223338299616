import type { Area, Point, Directions, Bounds, Size } from '@/modules/SLMovable/@types/Movable'
import { keepPointInsideBoundsY, keepPointInsideBoundsX } from '@/modules/SLMovable/helpers/point'

export function resizeFromCenter(
  from: Area,
  to: Point,
  directions: Directions,
  bounds: Bounds,
  minSize: Size | null
): Area {

  const isResizingX = directions.includes('e') || directions.includes('w')
  const isResizingY = directions.includes('n') || directions.includes('s')

  if (isResizingX && isResizingY) {
    return resizeBothFromCenter(from, to, bounds, minSize)
  } else if (isResizingX) {
    return resizeXFromCenter(from, to, bounds, minSize)
  } else if (isResizingY) {
    return resizeYFromCenter(from, to, bounds, minSize)
  }

  throw new Error('At least one direction must be provided')
}

function resizeXFromCenter(from: Area, to: Point, bounds: Bounds, minSize: Size | null): Area {

  const anchorX = from.x + 0.5 * from.width
  const deltaX = _findDeltaX(anchorX, to, bounds)

  const newWidth = minSize
    ? Math.max(minSize.width, Math.abs(deltaX * 2))
    : Math.abs(deltaX * 2)

  const newX = anchorX - 0.5 * newWidth

  return {
    x: newX,
    y: from.y,
    width: newWidth,
    height: from.height,
  }
}

function resizeYFromCenter(from: Area, to: Point, bounds: Bounds, minSize: Size | null): Area {

  const anchorY = from.y + 0.5 * from.height
  const deltaY = _findDeltaY(anchorY, to, bounds)

  const newHeight = minSize
    ? Math.max(minSize.height, Math.abs(deltaY * 2))
    : Math.abs(deltaY * 2)

  const newY = anchorY - Math.abs(deltaY)

  return {
    x: from.x,
    y: newY,
    width: from.width,
    height: newHeight,
  }
}

function resizeBothFromCenter(from: Area, to: Point, bounds: Bounds, minSize: Size | null): Area {

  const anchorX = from.x + 0.5 * from.width
  const anchorY = from.y + 0.5 * from.height

  const deltaX = _findDeltaX(anchorX, to, bounds)
  const deltaY = _findDeltaY(anchorY, to, bounds)

  const newWidth = minSize
    ? Math.max(minSize.width, Math.abs(deltaX * 2))
    : Math.abs(deltaX * 2)

  const newHeight = minSize
    ? Math.max(minSize.height, Math.abs(deltaY * 2))
    : Math.abs(deltaY * 2)

  const newX = anchorX - 0.5 * newWidth
  const newY = anchorY - 0.5 * newHeight

  return {
    x: newX,
    y: newY,
    width: newWidth,
    height: newHeight,
  }
}

function _findDeltaX(anchorX: number, to: Point, bounds: Bounds) {

  const clampedX = keepPointInsideBoundsX(to.x, bounds)

  const candidates = [Math.abs(anchorX - clampedX)]


  if (bounds && bounds.left !== null) {
    candidates.push(Math.abs(anchorX - bounds.left))
  }

  if (bounds && bounds.right !== null) {
    candidates.push(Math.abs(anchorX - bounds.right))
  }

  return Math.min(...candidates)
}

function _findDeltaY(anchorY: number, to: Point, bounds: Bounds) {

  const clampedY = keepPointInsideBoundsY(to.y, bounds)

  const candidates = [Math.abs(anchorY - clampedY)]

  if (bounds && bounds.top !== null) {
    candidates.push(Math.abs(anchorY - bounds.top))
  }

  if (bounds && bounds.bottom !== null) {
    candidates.push(Math.abs(anchorY - bounds.bottom))
  }

  return Math.min(...candidates)
}
