<template>
  <div class="dropdown-group" :class="{ show: showDropdown }">
    <button
      @click.stop="toggleDropdown"
      ref="dropdownButton"
      type="button"
      :class="buttonClass"
      aria-haspopup="true"
      :aria-expanded="showDropdown"
    >
      {{ buttonText }}
      <caret-down-icon v-if="showArrow" width="13" height="8" fill="currentColor" />
    </button>
    <div v-show="showDropdown" class="menu z-20" ref="dropdownMenu" @click.stop="">
      <slot v-if="showDropdown"></slot>
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'
import CaretDownIcon from '../Icons/CaretDownIcon.vue'

export default {
  components: { CaretDownIcon },
  props: {
    buttonText: {
      type: String,
      default: '',
    },
    buttonClass: {
      type: String,
      default: '',
    },
    showArrow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showDropdown: false,
      popper: null,
    }
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown
      if (this.showDropdown) {
        this.popper = createPopper(this.$refs.dropdownButton, this.$refs.dropdownMenu, {
          placement: 'bottom',
        })
        document.addEventListener('click', this.closeDropdown)
      } else {
        this.popper.destroy()
        this.popper = null
        document.removeEventListener('click', this.closeDropdown)
      }
    },
    closeDropdown(e) {
      if (e.target == this.$refs.dropdownButton || e.target == this.$refs.dropdownMenu) {
        return
      }
      this.showDropdown = false
      document.removeEventListener('click', this.closeDropdown)
    },
  },
  beforeUnmount() {
    if (this.popper) {
      this.popper.destroy()
      this.popper = null
    }
    document.removeEventListener('click', this.closeDropdown)
  },
}
</script>

<style scoped lang="scss">
.dropdown-group {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  // padding: .5em;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;

  &.show {
    svg {
      transform: rotate(180deg);
    }
  }

  .menu {
    white-space: nowrap;
    min-width: 100%;
    width: auto;
    top: calc(100% - 0.5em);
    left: 0;
    padding: 1em;
    margin: 0;
    font-size: 1em;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 20px;

    > a {
      color: #4d287f;
      text-decoration: none;
      display: block;
      margin-bottom: 1em;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        font-weight: 700;
      }
    }
  }
}
</style>
