import { QueryClient } from '@tanstack/vue-query'

// Create a client
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // enabled: false,
    },
  },
})
