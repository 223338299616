<script setup lang="ts">
import type { BlogItem } from '@/queries/useBlogItems'
import IconSaxArrowCircleRight2 from '@/components/Icons/iconsax/IconSaxArrowCircleRight2.vue'
import { ref } from 'vue'

defineProps<{ post: BlogItem | undefined }>()
const loaded = ref(false)
</script>

<template>
  <a :href="post?.url" target="_blank" rel="noopener noreferrer" class="group flex w-full">
    <article
      class="relative flex aspect-square max-w-full flex-1 origin-bottom flex-col gap-2 rounded-xl bg-blue-100 p-4 text-brand-state-text-primary transition-all hover:scale-[1.015] hover:shadow-lg sm:p-6"
      :class="{ skeleton: !post }"
    >
      <h4 class="relative text-lg font-semibold text-brand-state-text-primary sm:text-xl md:text-2xl">{{ post?.title }}</h4>
      <IconSaxArrowCircleRight2
        class="ml-auto mt-auto h-12 w-12 -rotate-45 transition-transform group-hover:rotate-0"
      />
    </article>
  </a>
</template>

<style scoped lang="scss"></style>
