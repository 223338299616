<script setup lang="ts">
import { ref, onMounted, onUnmounted, watch, computed, type DeepReadonly } from 'vue'
import { useUserInfoStore, onUserInfoReady } from '@/store/user/userInfo'
import { useResetEditor } from '@/Hooks/useResetEditor'
import { useRoute } from 'vue-router'
import { type Clip } from '@/Hooks/useTwitchClips'
import unwrap from '@/helpers/unwrap'
import IconSaxArrowRight2 from '@/components/Icons/iconsax/IconSaxArrowRight2.vue'
import ClipUrlError from '@/Hooks/clip-form/ClipUrlError.vue'
import IconSaxSearchNormal1 from '@/components/Icons/iconsax/IconSaxSearchNormal1.vue'
import YoutubeIcon from '@/components/Icons/SocialMedia/YoutubeIcon.vue'
import IconSaxInfoCircle from '@/components/Icons/iconsax/IconSaxInfoCircle.vue'
import IconSaxGalleryAdd from '@/components/Icons/iconsax/IconSaxGalleryAdd.vue'
import TwitchIcon from '@/components/Icons/SocialMedia/TwitchIcon.vue'
import KickIcon from '@/components/Icons/SocialMedia/KickIcon.vue'
import ClipGoatPromotionModal from '@/pages/modals/ClipGoatPromotionModal.vue'
import MultiUploadDialog from '@/components/Dialog/MultiUploadDialog/MultiUploadDialog.vue'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import IconSaxArrowLeft from '@/components/Icons/iconsax/IconSaxArrowLeft.vue'
import SelectClipDialogLoginForm from '@/components-v2/data-query/SelectClipDialog/SelectClipDialogLoginForm.vue'
import { Select, SelectTrigger, SelectContent, SelectItem } from '@/components/ui/select'
import { useClipFormManager } from '@/Hooks/clip-form/useClipFormManager'
import { useStartup } from '@/areas/editor/startup'
import ClipPreviewCard from '@/components/Clips/ClipPreviewCard.vue'
import TwitchClipPreviewDialog from '@/components-v2/modules/HomePage/TwitchClipPreviewDialog.vue'
import { Skeleton } from '@/components/ui/skeleton'
import { useMyClips } from '@/queries/useClips'
import { useTwitchClipsFilter } from '@/Hooks/useTwitchClipsFilter'
import { range } from 'lodash-es'

const clipUrl = ref<string>()
const { submitClipUrl, error, youtubeVideoId } = useClipFormManager()

const isSearchStep = ref(false)
const userInfoStore = useUserInfoStore()

watch(isSearchStep, isSearchStep => {
  if (isSearchStep) {
    searchResult.fetchIfNull()
  }
})

const reset = useResetEditor()

const Query = {
  // Clip URL is given by WebFlow.
  CLIP_URL: 'clipUrl',
  // Search clips from Twitch is given by WebFlow
  SEARCH_FROM_TWITCH: 'searchFromTwitch',
}

const route = useRoute()
onMounted(() => {

  reset()

  const queryKeys = unwrap.keys(route.query) as (typeof Query)[keyof typeof Query][]

  const clipUrlQuery = route.query[Query.CLIP_URL] as string | undefined
  if (clipUrlQuery) {
    submitClipUrl(clipUrlQuery)
  } else if (queryKeys.includes(Query.SEARCH_FROM_TWITCH)) {
    isSearchStep.value = true
  }

  document.body.style.overflow = 'hidden'
})

onUnmounted(() => {
  document.body.style.overflow = ''
})

const { startupFromTwitchClipByClipId, startupFromLocalFileByClipId } = useStartup()
async function selectTwitchClip(clip: Clip) {
  reset()
  await startupFromTwitchClipByClipId(clip.id)
}

async function selectLocalFile(clip: { id: string, title: string, videoUrl: string }) {
  const resetEditor = useResetEditor()
  resetEditor()
  await startupFromLocalFileByClipId(clip.id)
}

const previewId = ref<string | null>(null)
function preview(clip: Clip) {
  previewId.value = clip.id
} 

const urlInput = ref<HTMLInputElement>()
const userNameInput = ref<HTMLInputElement>()
const { myClips, myMostRecentClips, previewClips, searchResult, searchQuery, search, searchHistory } = useMyClips()
const { twitchClipFilterKey, twitchClipsOptions } = useTwitchClipsFilter()

onUserInfoReady(() => {
  myMostRecentClips.fetchIfNull()
})
</script>

<template>
  <div class="flex flex-col gap-4 justify-center max-w-[720px] w-full h-full min-h-0">
    <template v-if="isSearchStep">
      <header class="flex flex-col gap-0 md:gap-2">
        <button
          class="flex items-center justify-center gap-2 self-start rounded-xl px-4 py-2 text-blue-600 outline outline-2 outline-offset-2 outline-transparent transition-all hover:bg-blue-600 hover:text-white focus:bg-blue-600 focus:text-white focus:outline-current active:scale-90"
          @click="isSearchStep = false"
        >
          <IconSaxArrowLeft class="h-4 w-4 [&>path]:stroke-2" />
          Back
        </button>
  
        <h4 class="text-light m-0 p-0 text-xl md:text-3xl lg:text-4xl">Select Clips from Twitch</h4>
        <p class="font-light opacity-75 text-sm md:text-base">Enter a username to find clips for editing</p>
      </header>
  
      <section class="w-full">
        <form
          :class="error
              ? 'border-rose-400 shadow shadow-rose-400/50'
              : 'border-surface-input-border [&:has(input:focus)]:shadow [&:has(input:focus)]:shadow-cyan-400/50'"
          class="layer-1 group flex items-stretch rounded-xl transition-all relative"
          @submit.prevent.stop="() => search()"
        >
          <div class="shrink-0 pr-0 absolute left-3 top-3 text-rose-500" v-if="error">
            <IconSaxInfoCircle />
          </div>
          <Input
            ref="userNameInput"
            v-model="searchQuery"
            :class="error ? 'pl-11 border-rose-400' : 'border-surface-input-border focus:border-cyan-400/50'"
            class="min-w-0 bg-transparent flex-auto rounded-l-xl rounded-r-none border border-r-0 p-3 text-left font-light text-zinc-900 outline-none"
            placeholder="Paste the URL of a Twitch, YouTube, or Kick clip here"
            type="text"
            @input="error = null"
          />
          <Button
            class="h-full group flex flex-nowrap items-center justify-center gap-2 whitespace-nowrap rounded-l-none rounded-r-xl px-6 py-3 text-white outline-none transition-colors"
            type="submit"
            @click="userNameInput?.focus()"
          >
            <span class="hidden md:inline">Search</span>
            <IconSaxArrowRight2 class="h-4 w-4 transition-transform group-hover:translate-x-2 group-focus:translate-x-2 md:mr-2" />
          </Button>
        </form>
  
        <div v-if="searchHistory.length > 0" class="flex flex-wrap gap-0.5 text-sm md:gap-2 pt-1 md:pt-2">
          <div>Recent searches:&nbsp;</div>
          <div class="flex flex-row gap-2 max-w-[90vw] overflow-y-auto">
            <button
              v-for="history in [...searchHistory].reverse()"
              :key="history"
              class="link-primary link"
              @click.prevent="search(history)"
            >
              {{ history }}
            </button>
          </div>
        </div>
      </section>

      <div class="flex flex-col gap-2 min-h-0" v-if="userInfoStore.isAuthenticated">
        <header class="flex flex-wrap items-center justify-between gap-2">
          <h5 class="flex-auto text-lg md:text-xl font-thin dark:text-white py-0.5 md:py-4 md:p-0">
            <template v-if="searchResult.isFetching">
              Finding clips for <span class="font-bold">"{{ searchQuery }}"</span>
            </template>
            <template v-else>
              {{ searchResult.amountOfClips }} results for <span class="font-bold">"{{ searchQuery }}"</span>
            </template>
          </h5>
          <div class="flex-1">
            <Select v-model="twitchClipFilterKey">
              <SelectTrigger class="whitespace-nowrap">
                {{ twitchClipsOptions.find((o) => o.value === twitchClipFilterKey)?.label ?? 'Filter clips' }}
              </SelectTrigger>
              <SelectContent>
                <SelectItem v-for="option in twitchClipsOptions" :key="option.value" :value="option.value">
                  {{ option.label }}
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
        </header>

        <div class="p-4 mt-0 -m-4 h-full flex-auto overflow-y-auto">
          <div class="grid grid-cols-2 md:grid-cols-3 gap-4 h-full">
            <template v-if="searchResult.isFetching">
              <article class="flex flex-col gap-1" v-for="i in 6" :key="i">
                <Skeleton class="w-full aspect-video rounded-xl" />
                <Skeleton class="h-6 w-3/4" />
              </article>
            </template>
            <template v-else-if="searchResult.ordered">
              <ClipPreviewCard v-for="clip in searchResult.ordered"
                :key="clip.id!" :clip="clip!"
                @select="selectTwitchClip" @preview="preview"
              />
            </template>
          </div>
        </div>
      </div>
      <div v-else class="relative flex-1 min-h-[420px]">
        <img alt="Preview of your clips" class="w-full p-4 blur-sm" height="631" src="/images/clip-grid.png" width="864" />
        <SelectClipDialogLoginForm />
      </div>
    </template>
    <template v-else>
      <header class="flex flex-col gap-2">
        <div class="flex flex-wrap-reverse items-end justify-between gap-2">
          <ol class="flex gap-1">
            <li class="flex items-center gap-1.5 rounded bg-[#9147FF] px-2 py-1 font-semibold text-white">
              <TwitchIcon class="h-5 w-5 shrink-0" />
              Twitch
            </li>
            <li class="flex items-center gap-1 rounded bg-[#F00] px-2 py-1 font-semibold text-white">
              <YoutubeIcon class="h-5 w-5 shrink-0" />
              <p class="translate-y-[1px]">YouTube</p>
            </li>
            <li class="flex items-center gap-2 rounded bg-black px-2 py-1 font-semibold text-white">
              <KickIcon class="h-4 w-4 shrink-0 text-[#0CFF7F]" />
              <p class="translate-y-[1px]">Kick</p>
            </li>
          </ol>
        </div>

        <h4 class="text-light m-0 p-0 text-3xl lg:text-4xl">Start by selecting a clip</h4>

        <p class="font-light opacity-75">
          Enter a Twitch, YouTube or Kick clip, upload your own, or find one on Twitch to begin your editing adventure!
        </p>
      </header>

      <section class="flex flex-col gap-4">
        <div class="flex flex-col gap-2">
          <form
            :class="error
              ? 'border-rose-400 shadow shadow-rose-400/50'
              : 'border-surface-input-border [&:has(input:focus)]:shadow [&:has(input:focus)]:shadow-cyan-400/50'"
            class="layer-1 group flex items-stretch rounded-xl transition-all relative"
            @submit.prevent.stop="() => submitClipUrl(clipUrl)"
          >
            <div class="shrink-0 pr-0 absolute left-3 top-3 text-rose-500" v-if="error">
              <IconSaxInfoCircle />
            </div>
            <Input
              ref="urlInput"
              v-model="clipUrl"
              :class="error ? 'pl-11 border-rose-400' : 'border-surface-input-border focus:border-cyan-400/50'"
              class="min-w-0 bg-transparent flex-auto rounded-l-xl rounded-r-none border border-r-0 p-3 text-left font-light text-zinc-900 outline-none"
              placeholder="Paste the URL of a Twitch, YouTube, or Kick clip here"
              type="text"
              @input="error = null"
            />
            <Button
              class="h-full group flex flex-nowrap items-center justify-center gap-2 whitespace-nowrap rounded-l-none rounded-r-xl px-6 py-3 text-white outline-none transition-all"
              type="submit"
              @click="urlInput?.focus?.()"
            >
              <span class="hidden md:inline">Start editing</span>
              <IconSaxArrowRight2 class="h-4 w-4 transition-transform group-hover:translate-x-2 group-focus:translate-x-2 md:mr-2" />
            </Button>
          </form>

          <ClipUrlError :error="error" />
        </div>

        <div class="flex flex-wrap gap-4">
          <MultiUploadDialog call-to-action="Start editing" @select="selectLocalFile" upload-in-background :max="10">
            <button class="layer-2 flex flex-auto items-center justify-center gap-2 rounded-xl border border-zinc-900/20 px-6 py-8 outline outline-2 outline-transparent transition-all hover:outline-offset-2 hover:outline-cyan-400 focus:outline-offset-2 focus:outline-cyan-400 active:scale-90 dark:border-zinc-200/20">
              <IconSaxGalleryAdd class="h-6 w-6 shrink-0 [&>path]:stroke-1" />
              Upload from computer
            </button>
          </MultiUploadDialog>
          <button
            class="layer-2 flex flex-auto items-center justify-center gap-2 rounded-xl border border-zinc-900/20 px-6 py-8 outline outline-2 outline-transparent transition-all hover:outline-offset-2 hover:outline-cyan-400 focus:outline-offset-2 focus:outline-cyan-400 active:scale-90 dark:border-zinc-200/20"
            @click="isSearchStep = true"
          >
            <IconSaxSearchNormal1 class="h-6 w-6 shrink-0 [&>path]:stroke-1" />
            Select from Twitch
          </button>
        </div>
      </section>

      <div class="flex flex-col min-h-0">
        <Skeleton v-if="myMostRecentClips.isFetching" class="h-7 w-[256px]" />
        <h4 v-else-if="userInfoStore.isAuthenticated && myMostRecentClips.amountOfClips > 0" class="m-0 p-0 text-lg font-normal lg:text-xl">
          Your most recent clips
        </h4>
        <h4 v-else class="text-lg font-normal lg:text-xl">
          Try out the editor with a sample clip
        </h4>

        <div class="pt-2 p-4 mt-0 -m-4 min-h-0 overflow-y-auto">
          <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
            <template v-for="i in range(6)" :key="i">
              <template v-if="myMostRecentClips.isFetching">
                <article class="flex flex-col gap-1">
                  <Skeleton class="w-full aspect-video rounded-xl" />
                  <Skeleton class="h-6 w-3/4" />
                </article>
              </template>
              <template v-else-if="previewClips.clips?.[i]">
                <ClipPreviewCard :clip="previewClips.clips?.[i] as Clip" @select="selectTwitchClip" @preview="preview" />
              </template>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
  <ClipGoatPromotionModal v-model="youtubeVideoId" />
  <TwitchClipPreviewDialog v-model="previewId"
    :clips="(isSearchStep ? searchResult.ordered ?? [] : previewClips.clips ?? []) as Clip[]"
    @confirm="selectTwitchClip" 
  />
</template>

<style scoped lang="scss">

</style>
