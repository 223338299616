import features from '../data/features'
import { useUserInfoStore } from '@/store/user/userInfo'
import { upgradeDialog } from '@/helpers/upgradeDialog'

export default {
  data() {
    return {
      features: features,
    }
  },
  methods: {
    userCan(feature) {
      const feat = this.getFeatureFromString(feature)

      if (feat === null) {
        return false
      }

      return this.userCanFromFeature(feat)
    },
    userCanFromFeature(feature) {
      const userInfoStore = useUserInfoStore()
      return userInfoStore.tier >= feature.tier
    },
    getFeatureFromString(feature) {
      const feat = this.features.find((f) => f.name === feature)

      if (typeof feat == 'undefined' || feat === null) {
        return null
      }

      return feat
    },
    openUpgradeDialog(feature = '', prod = 'gold') {
      upgradeDialog.open(feature)
    },
  },
}
