import { createGlobalState } from '@vueuse/core'
import { ref } from 'vue'

const focus = ref<string | null>(null)

export const useMovableFocus = createGlobalState(() => {
  return {
    focus,
    setFocus(id: string | null) {
      focus.value = id
    },
  }
})
