<template>
  <div
    class="video-placeholder video-container video-wrapper relative flex w-full flex-col items-center justify-center rounded-xl bg-gray-700"
    :class="clipClassObject"
    :key="clip.id"
    @mouseover="setHoverStatus(true)"
    @mouseleave="setHoverStatus(false)"
    @click.prevent="clipClicked"
    :data-event="getUserClipEventData(clip)"
  >
    <video
      class="rounded-xl"
      v-if="!loading && !clip.thumbnailUrl"
      :src="clip.resultUrl"
      disableRemotePlayback
      disablePictureInPicture
      muted
    />
    <img class="rounded-xl" v-else :src="clip.thumbnailUrl" @error="replaceByDefault" />
    <div
      class="flex w-full flex-1 animate-pulse select-none items-center justify-center bg-company-primary-hover"
      v-if="loading"
    >
      <div class="spinner-border border-3 inline-block h-6 w-6 animate-spin rounded-full" role="status">
        <span class="invisible">Loading...</span>
      </div>
    </div>

    <resource-control v-show="isHovering && !loading && hasResourceControl" ref="resourceControl">
      <div
        @click.prevent="schedule"
        class="flex items-center space-x-2 whitespace-nowrap px-4 py-2 text-xs text-company-primary hover:bg-company-primary hover:bg-opacity-10"
      >
        <img src="/images/icons/calendar-icon.svg" class="resource-icon h-4 w-4" alt="calendar icon" loading="lazy" />
        <span>Schedule Video</span>
      </div>
      <a
        :href="clip.resultUrl"
        class="flex items-center space-x-2 whitespace-nowrap px-4 py-2 text-xs text-company-primary hover:bg-company-primary hover:bg-opacity-10"
      >
        <img src="/images/icons/download-icon.svg" class="resource-icon h-4 w-4" alt="download icon" loading="lazy" />
        <span>Download Video</span>
      </a>
      <div
        @click.prevent="showQrCode"
        class="flex items-center space-x-2 whitespace-nowrap px-4 py-2 text-xs text-company-primary hover:bg-company-primary hover:bg-opacity-10"
      >
        <img src="/images/icons/qr-icon.svg" class="resource-icon h-4 w-4" alt="qr icon" loading="lazy" />
        <span>Get QR code</span>
      </div>
      <div
        @click.prevent="destroy"
        class="flex items-center space-x-2 whitespace-nowrap px-4 py-2 text-xs text-company-accent hover:bg-company-primary hover:bg-opacity-10"
      >
        <trashcan-icon class="resource-icon h-4 w-4 fill-current" />
        <span class="text-company-accent">Delete from library</span>
      </div>
    </resource-control>
  </div>
</template>
<script>
import TrashcanIcon from '../../Icons/TrashcanIcon.vue'
import ResourceControl from '../../Controls/ResourceControl.vue'
import { deleteApiVideosId, getGetApiVideosQueryKey } from '@/apis/streamladder-api/videos/videos'
import { queryClient } from '@/services/QueryClient'

export default {
  components: {
    TrashcanIcon,
    ResourceControl,
  },
  props: {
    clip: { type: Object },
    selected: { type: Object },
    draggable: { type: Boolean, default: false },
    selectable: { type: Boolean, default: false },
    hasResourceControl: { type: Boolean, default: true },
  },
  computed: {
    clipClassObject() {
      return {
        'cursor-pointer border-4 border-transparent': this.selectable && !this.isSelected && !this.isHovering,
        'cursor-grab': this.draggable,
        'cursor-pointer border-4 border-company-primary': this.selectable && (this.isSelected || this.isHovering),
      }
    },
    isSelected() {
      return this.selected?.id === this.clip.id
    },
  },
  data() {
    return {
      loading: false,
      draggableInstance: null,
      isHovering: false,
    }
  },
  methods: {
    getUserClipEventData(clip) {
      return JSON.stringify({
        id: clip.id,
        title: clip.title,
        create: false,
        url: clip.resultUrl,
        thumbnail: clip.thumbnailUrl,
      })
    },
    setHoverStatus(val) {
      this.isHovering = val

      if (!val) this.$refs.resourceControl.hideOptions()
    },
    replaceByDefault(e) {
      e.target.src = '/images/images/thumbnail.jpg'
    },
    async destroy() {
      this.loading = true
      await deleteApiVideosId(this.clip.id)
      await queryClient.invalidateQueries({
        queryKey: getGetApiVideosQueryKey(),
      })
      this.loading = false
    },
    schedule() {
      this.$router.push({
        name: 'schedule-video',
        query: { videoId: this.clip.id },
      })
    },
    showQrCode() {
      this.$emit('showQrCode', this.clip)
    },
    clipClicked() {
      this.$emit('clipClicked', this.clip)
    },
  },
  beforeUnmount() {
    if (this.draggableInstance) {
      this.draggableInstance.destroy()
      this.draggableInstance = null
    }
  },
}
</script>
<style lang="scss" scoped>
.video-placeholder {
  border-radius: 1rem;
}

.video-wrapper {
  video {
    width: 100%;
    border-radius: 0.75rem;
  }

  img:not(.resource-icon) {
    width: 100%;
  }
}

.selected {
  box-shadow: 0 0 5px 2px #8032dd;
}
</style>
