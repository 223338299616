<script setup lang="ts">
import settings from '@/data/settings'
import { computed, ref, watch } from 'vue'
import type { UpgradeDialogPayload } from '@/helpers/upgradeDialog'
import CrossIcon from '@/components/Icons/CrossIcon.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { type Tier } from '@/data/plans'
import { useCurrentCampaign } from '@/data/campaigns'
import { useDiscountCode } from '@/Hooks/useDiscountCode'
import { useConfirmDialog } from '@/components/Dialog/Confirm/useConfirmDialog'
import { usePlanUpgrade } from '@/Hooks/usePlanUpgrade'
import { posthog } from 'posthog-js'
import TwoStepsTierPrice from '@/components/Dialog/Gold/Tests/TwoStepsTierPrice.vue'
import TwoStepsTierCard from '@/components/Dialog/Gold/Tests/TwoStepsTierCard.vue'

const props = defineProps<{
  isLoading: string | null
  close: (_payload: UpgradeDialogPayload) => void
  handleClose: (payload: UpgradeDialogPayload) => void
  payload: UpgradeDialogPayload
  error: string | null
  reason: string
  tiers: Tier[]
}>();

const twoStepStepIndex = ref(0);

const currentTwoStepsActiveSelection = ref('gold');

const selectedInterval = ref('year');

const userInfoStore = useUserInfoStore()

const campaign = useCurrentCampaign()
const urlDiscountCode = useDiscountCode()

const discountCode = computed(() => {
  if (urlDiscountCode) return urlDiscountCode
  if (campaign.value?.discountCode) return campaign.value.discountCode
  return undefined
})

const { reveal } = useConfirmDialog()
const { isLoading, upgrade: _upgrade } = usePlanUpgrade()

const isUpgrading = ref(false);

const upgrade = async (interval: 'month' | 'year') => {

  if (isUpgrading.value) return;

  isUpgrading.value = true;

  const tierNumber = currentTwoStepsActiveSelection.value === 'silver' ? 10 : 100;

  if (userInfoStore.tier !== 0) {
    const confirm = await reveal({
      title: `Upgrade to ${currentTwoStepsActiveSelection.value} now`,
      message: `Warning: You will be charged immediately for the difference in price for the remainder of this ${selectedInterval.value}.`,
    })
    if (!confirm) {
      isUpgrading.value = false;
      return
    }
  }

  if (tierNumber > userInfoStore.tier) {
    const eventMeta = {
      tier: currentTwoStepsActiveSelection.value,
      monthlyOrYearly: interval,
      currentPlan: userInfoStore.plan,
      isCurrentlyInTrial: userInfoStore.trialStatus === 'active',
    }

    posthog.capture('SubscriptionUpgrade Clicked', eventMeta)
  }

  const getTier = (tier: string) => {
    return props.tiers.find(t => t.key === tier)
  }

  isUpgrading.value = false;

  return _upgrade(getTier(currentTwoStepsActiveSelection.value)!, props.reason, discountCode.value, interval)
}

watch(twoStepStepIndex, () => {
  if (twoStepStepIndex.value === 1) {
    selectedInterval.value = 'year';
  }
})

const handleCardClick = (tier: Tier) => {
  currentTwoStepsActiveSelection.value = tier.key;
  twoStepStepIndex.value = 1;
};
</script>

<template>
  <div class="modal-box max-h-screen xl:max-h-[95vh] w-full max-w-full gap-12 rounded-none p-0 md:rounded-lg min-[1175px]:max-w-[1175px] flex overflow-hidden">
    <div class="flex flex-col w-full max-h-full overflow-y-auto">
      <div
        v-if="!isLoading"
        class="absolute right-2 top-2 z-10 cursor-pointer p-2 text-company-primary hover:text-company-primary xl:text-gray-300"
        @click="close"
      >
        <cross-icon class="h-6 w-6 fill-current" />
      </div>

      <div class="flex-1 justify-center items-center gap-8 xl:min-w-[620px]">
        <div class="flex flex-col justify-center items-center gap-4 xl:gap-2 h-full">
          <div class="flex justify-center items-center overflow-hidden w-full p-4 xxl:p-16 min-h-[700px]">
            <template v-if="twoStepStepIndex === 0">
              <Transition
                appear
                enter-active-class="transition-[transform,_opacity] duration-300"
                leave-active-class="transition-[transform,_opacity] duration-300"
                enter-from-class="opacity-0 translate-y-4"
                enter-to-class="opacity-100 translate-y-0"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 -translate-y-4"
              >
                <div class="flex flex-col">
                  <header class="flex min-h-[110px] flex-col items-center justify-center text-center font-bold">
                    <h3 class="pb-2 pt-0 text-center text-2xl leading-[1.2] text-[#2F184E] lg:text-5xl">
                      Plans and pricing
                    </h3>
                    <p class="text-center text-lg font-thin text-[#2F184E] md:text-base 2xl:text-lg">
                      Choose the best plan for you
                    </p>
                  </header>
                  <div class="flex flex-col md:flex-row w-full gap-4 justify-center items-center py-8">
                    <TwoStepsTierCard
                      :reason="reason"
                      :tier="props.tiers.find(t => t.key === 'gold')!"
                      @click="handleCardClick(props.tiers.find(t => t.key === 'gold')!)"
                    />
                    <TwoStepsTierCard
                      :reason="reason"
                      :tier="props.tiers.find(t => t.key === 'silver')!"
                      @click="handleCardClick(props.tiers.find(t => t.key === 'silver')!)"
                    />
                  </div>
                </div>
              </Transition>
            </template>
            <template v-if="twoStepStepIndex === 1">
              <Transition
                appear
                enter-active-class="transition-[transform,_opacity] duration-300"
                leave-active-class="transition-[transform,_opacity] duration-300"
                enter-from-class="opacity-0 translate-y-4"
                enter-to-class="opacity-100 translate-y-0"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 -translate-y-4"
              >
                <div class="flex flex-col w-full h-full items-center justify-center">
                  <div class="flex items-center justify-center w-full">
                    <div class="flex flex-col gap-8 justify-center items-center w-full md:w-1/2">

                      <header class="flex min-h-[110px] flex-col items-center justify-center text-center font-bold">
                        <h3 class="pb-2 pt-0 text-center text-2xl leading-[1.2] text-[#2F184E] lg:text-4xl">
                          Choose yearly or monthly
                        </h3>
                        <p class="text-center text-lg font-thin text-[#2F184E] md:text-base 2xl:text-lg">
                          Choose the best {{ currentTwoStepsActiveSelection }} plan for you
                        </p>
                      </header>

                      <div
                        @click="upgrade('year')"
                        class="group transition-[background-color,transform,shadow] hover:bg-white hover:shadow-xl cursor-pointer border border-gray-300 rounded-xl px-8 py-5 w-full"
                        :class="{ '!border-[#33CFF2] bg-[#F5FDFEEB]': selectedInterval === 'year' }"
                      >
                        <TwoStepsTierPrice
                          :tier="currentTwoStepsActiveSelection"
                          :is-selected="selectedInterval === 'year'"
                          interval="year"
                        />
                      </div>

                      <div
                        @click="upgrade('month')"
                        class="group transition-[background-color,transform,shadow] hover:bg-white hover:shadow-xl cursor-pointer border border-gray-300 rounded-xl px-8 py-5 w-full md:w-2/3"
                        :class="{ 'text-white bg-gradient !border-none': selectedInterval === 'month' }"
                      >
                        <TwoStepsTierPrice
                          :tier="currentTwoStepsActiveSelection"
                          :is-selected="selectedInterval === 'month'"
                          interval="month"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col justify-center mt-10 gap-2 underline underline-offset-4">
                    <button @click="twoStepStepIndex = 0" class="mb-8 bg-transparent hover:opacity-80 transition-[underline] py-2 px-4" >
                      Back to plans
                    </button>
                  </div>
                </div>
              </Transition>
            </template>
          </div>

          <div v-if="error" class="alert alert-error">
            <div class="flex w-full flex-row gap-4">
              <svg
                class="flex-shrink-0"
                fill="none"
                height="33"
                viewBox="0 0 32 33"
                width="32"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="16" cy="16.5" fill="#FDCECE" r="16" />
                <path
                  d="M16 6.5C14.0222 6.5 12.0888 7.08649 10.4443 8.1853C8.79981 9.28412 7.51809 10.8459 6.76121 12.6732C6.00433 14.5004 5.8063 16.5111 6.19215 18.4509C6.578 20.3907 7.53041 22.1725 8.92894 23.5711C10.3275 24.9696 12.1093 25.922 14.0491 26.3078C15.9889 26.6937 17.9996 26.4957 19.8268 25.7388C21.6541 24.9819 23.2159 23.7002 24.3147 22.0557C25.4135 20.4112 26 18.4778 26 16.5C25.9949 13.8494 24.9397 11.3088 23.0655 9.43455C21.1912 7.56028 18.6506 6.50508 16 6.5ZM19.625 19.0288C19.7694 19.1747 19.8505 19.3717 19.8505 19.5769C19.8505 19.7822 19.7694 19.9792 19.625 20.125C19.478 20.2672 19.2814 20.3466 19.0769 20.3466C18.8724 20.3466 18.6759 20.2672 18.5288 20.125L16 17.5865L13.4712 20.125C13.3241 20.2672 13.1276 20.3466 12.9231 20.3466C12.7186 20.3466 12.522 20.2672 12.375 20.125C12.2306 19.9792 12.1495 19.7822 12.1495 19.5769C12.1495 19.3717 12.2306 19.1747 12.375 19.0288L14.9135 16.5L12.375 13.9712C12.2523 13.8217 12.1897 13.632 12.1991 13.4388C12.2086 13.2457 12.2896 13.063 12.4263 12.9263C12.563 12.7896 12.7457 12.7086 12.9388 12.6991C13.132 12.6896 13.3217 12.7523 13.4712 12.875L16 15.4135L18.5288 12.875C18.6783 12.7523 18.868 12.6896 19.0612 12.6991C19.2543 12.7086 19.437 12.7896 19.5737 12.9263C19.7104 13.063 19.7914 13.2457 19.8009 13.4388C19.8104 13.632 19.7477 13.8217 19.625 13.9712L17.0865 16.5L19.625 19.0288Z"
                  fill="#F30C0C"
                />
              </svg>
              <div class="w-full text-left">
                <span class="break-words sm:text-lg">Uh oh!</span>
                <p class="w-full max-w-full whitespace-pre-line break-all text-sm sm:break-normal">
                  <template v-if="error === 'configuration-unknown'">
                    This plan configuration could not be found. Please contact our support team through our&nbsp;
                    <a :href="settings.discordInviteUrl" target="_blank" class="link text-indigo-500 hover:no-underline"
                    >Discord</a
                    >.
                  </template>
                  <template v-if="error === 'checkout-failed'">
                    Something went wrong while updating your subscription. Please ensure you are logged in. If the
                    problem persists, please don't hesitate to contact us through our&nbsp;
                    <a :href="settings.discordInviteUrl" target="_blank" class="link text-indigo-500 hover:no-underline"
                    >Discord</a
                    >.
                  </template>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pb-10 bg-white">
        <header class="flex min-h-[110px] flex-col items-center justify-center text-center">
          <h3 class="pb-2 pt-0 text-center font-bold text-2xl text-[#2F184E] lg:text-3xl">
            Still not convinced? Read our <span class="text-[#9490ff]">customer stories.</span>
          </h3>
        </header>
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>