<script setup lang="ts">
import { ToolbarButton, ToolbarRoot } from 'radix-vue'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import { inject, toRefs } from 'vue'
import type { Ref } from 'vue'
import { Button } from '@/components/ui/button'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import type { Area } from '@/modules/SLMovable/@types/Movable'
import { useConfirmDialog } from '@/components/Dialog/Confirm/useConfirmDialog'
import { Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip'
import { useWorkspaceBoundingContext } from '@/areas/editor/context/workspaceSize'
import IconSaxEdit2 from '@/components/Icons/iconsax/IconSaxEdit2.vue'
import { useIsMobile } from '@/Hooks/useIsMobile'
import { useToolbarPosition } from '@/areas/editor/hooks/useToolbarPosition'

const props = defineProps<{ area: Area }>()
const editorWrapper = inject<Ref<HTMLElement>>('editorWrapper')!

const offset = 1
const { area } = toRefs(props)
const { top, left, width, element } = useToolbarPosition(area, editorWrapper, offset)
const { element: workspace } = useWorkspaceBoundingContext()!

const editorCaptionsStore = useEditorCaptionsStore()
const confirm = useConfirmDialog()

async function handleDelete() {
  const confirmed = await confirm.reveal({
    title: 'Delete Captions',
    message: 'Are you sure you want to delete all captions?',
  })
  if (confirmed) {
    editorCaptionsStore.$reset()
  }
}

const isMobile = useIsMobile()
const handleEdit = () => {
  editorCaptionsStore.isEditingOnMobile = true
};
</script>

<template>
  <Teleport :to="workspace">
    <ToolbarRoot
      ref="element"
      data-retain-focus
      :style="{ top: top + 'px', left: left - 0.5 * width + 'px' }"
      class="layer-1 !fixed mt-4 flex !min-w-max max-w-full animate-[slideUpAndFade_150ms_cubic-bezier(0.4,_0,_0.2,_1)_forwards] items-center gap-1 rounded-md py-1 px-1.5 shadow-lg z-40"
      @click.stop
    >
      <ToolbarButton v-if="isMobile">
        <Tooltip>
          <TooltipTrigger>
            <Button
              variant="ghost"
              size="sm"
              @click.stop="handleEdit"
            >
              <IconSaxEdit2 class="stoke-[1] h-4 w-4" />
            </Button>
          </TooltipTrigger>

          <TooltipContent>
            Edit
          </TooltipContent>
        </Tooltip>
      </ToolbarButton>

      <ToolbarButton>
        <Tooltip>
          <TooltipTrigger>
            <Button
              @click="handleDelete"
              variant="ghostDestructive"
              size="sm"
              class="disabled:pointer-events-none rounded-sm"
            >
              <IconSaxTrash class="stoke-[1] h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            Delete
          </TooltipContent>
        </Tooltip>
      </ToolbarButton>
    </ToolbarRoot>
  </Teleport>
</template>

<style lang="scss" scoped></style>
