<template>
  <svg width="40" height="29" viewBox="0 0 40 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.62988"
      y="1.92383"
      width="36.9071"
      height="25.452"
      rx="4"
      stroke="#47089E"
      stroke-width="2"
      stroke-dasharray="4 4"
    />
    <circle cx="20.08" cy="13.4365" r="4.00776" stroke="#47089E" stroke-width="2" />
    <path
      d="M28.1506 28.3763V25.762C28.1506 21.7204 26.983 18.4441 20.9877 18.4441H18.9415"
      stroke="#47089E"
      stroke-width="2"
    />
    <path
      d="M19.0148 18.4441C13.0194 18.4441 11.8519 21.7204 11.8519 25.762V28.3763"
      stroke="#47089E"
      stroke-width="2"
    />
  </svg>
</template>
