import { subDays } from 'date-fns'
import { OverallPostStatus } from '@/apis/streamladder-publisher/model'
import { computed } from 'vue'
import { useContentPublisherStore } from '@/components-v2/content-publisher/_store'
import { noop } from 'lodash-es'

export function useUpcomingPosts() {
  const contentPublisherStore = useContentPublisherStore()
  contentPublisherStore.fetch().then(noop)

  const isLoadingPosts = computed(() => contentPublisherStore.isPerformingBackgroundAction)

  const maxAmountOfPosts = 4

  const allowedStatuses = [
    OverallPostStatus.Failed,
    OverallPostStatus.PartialFailure,
    OverallPostStatus.Scheduled,
    OverallPostStatus.Publishing,
  ] as (string | undefined)[]

  const upcomingPosts = computed(() => {
    return contentPublisherStore.posts
      .filter((post) => allowedStatuses.includes(post.status.overallStatus))
      .filter((post) => new Date(post.scheduledAt).getTime() > subDays(new Date(), 7).getTime())
      .sort((a, b) => new Date(a.scheduledAt).getTime() - new Date(b.scheduledAt).getTime())
      .slice(0, maxAmountOfPosts)
  })

  return { isLoadingPosts, maxAmountOfPosts, upcomingPosts }
}
