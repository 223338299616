export function selectText(element: HTMLSpanElement | null) {
  setTimeout(() => {

    if (!element) return
    element.focus()

    const range = document.createRange()
    range.selectNodeContents(element)

    const selection = window.getSelection()
    selection?.removeAllRanges()
    selection?.addRange(range)
  }, 0)
}
