<template>
  <div class="flex w-full flex-col items-center gap-4">
    <!-- Confirmation message -->
    <div class="my-8 flex items-center space-y-1">
      <div class="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-green-600 text-white">
        <check-mark-icon class="h-3 w-3 fill-current" />
      </div>
      <p class="my-0 ml-2 font-normal">Your cancellation is confirmed.</p>
    </div>

    <!-- Farewell message -->
    <div class="w-50 panel mb-8 px-8 pt-8 text-center lg:px-16 lg:pt-16 flex flex-col gap-8">
      <h2 class="text-3xl font-bold">I guess all good things come to an end.</h2>
      <p class="mb-0 opacity-70">
        Come back anytime. You’ll be able to use StreamLadder {{ userInfoStore.plan }} until
        {{ endOfSubscriptionDate }}. If you change your mind, you can resume your subscription in
        <a class="text-cornflower-blue hover:text-cornflower-blue-600" target="_blank" href="/account/invoicing"
          >the Billing &amp; Invoice page.</a
        >
        Come back anytime. We'll welcome you back with open arms!
      </p>

      <div class="my-2 flex self-center">
        <router-link
          :to="{ name: 'Home' }"
          class="w-full rounded bg-company-primary-300 px-8 py-2 text-white hover:bg-company-primary-500"
        >
          <p class="mb-0">Back to homepage</p>
        </router-link>
      </div>

      <div class="mx-auto md:w-[200px] lg:w-[250px] xl:w-[400px] -mb-8">
        <LottieAnimation style="filter: hue-rotate(260deg)" url="/lottie/byebye.json" />
      </div>
    </div>

    <!-- Feature update offer -->
    <div class="mb-8 flex flex-col px-8 text-center lg:px-16">
      <p class="mb-2 text-xl">Stay up to date with Streamladder's new features!</p>
      <p class="mb-4 opacity-70">
        We’re constantly working on improving Streamladder and will launch new features soon.
      </p>
    </div>
  </div>
</template>
<script>
import CheckMarkIcon from '../../Icons/CheckMarkIcon.vue'
import { useUserInfoStore } from "@/store/user/userInfo"
import { format } from 'date-fns'
import LottieAnimation from '@/components/LottieAnimation.vue';

export default {
  components: { LottieAnimation, CheckMarkIcon },
  props: {
    subscriptionInfo: { type: Object, required: true },
  },
  setup() {
    const userInfoStore = useUserInfoStore()
    return { userInfoStore }
  },
  methods: {
    async redirectHome() {
      await this.$router.push('/')
    },
  },
  computed: {
    endOfSubscriptionDate() {
      if (!this.subscriptionInfo?.endDate?.date) return 'N/A'
      return format(Date.parse(this.subscriptionInfo?.endDate?.date), 'MMM dd, yyyy')
    },
  },
}
</script>
