<script setup lang="ts">
import { type VideoResultDto } from '@/apis/streamladder-api/model'
import { ref } from 'vue'
import { useMediaControls } from '@vueuse/core'
import ResultVideoPreviewContainer from '@/areas/dashboard/components/ResultVideos/partials/ResultVideoPreviewContainer.vue'

defineProps<{ video?: VideoResultDto | undefined; placeholderSrc?: string }>()
const emit = defineEmits<{
  (name: 'requestQr', video: VideoResultDto): void
  (name: 'play', id: string | null | undefined): void
}>()

const element = ref<HTMLVideoElement>()
const canplay = ref(false)
const { playing } = useMediaControls(element)

defineExpose({ video: element })

const loaded = ref(false)
const enabled = ref(false)

const isError = ref(false)
</script>

<template>
  <ResultVideoPreviewContainer v-if="placeholderSrc">
    <img
      :src="isError ? '/images/images/thumbnail.jpg' : placeholderSrc"
      @error="isError = true"
      class="w-full rounded-lg transition-all duration-300"
      loading="lazy"
      width="720"
      height="1280"
      alt="Placeholder video; upgrade to be able to save videos!"
    />
  </ResultVideoPreviewContainer>

  <ResultVideoPreviewContainer v-else-if="!video" class="skeleton pointer-events-none overflow-hidden" />

  <ResultVideoPreviewContainer
    v-else-if="!enabled"
    :video="video"
    :playing="playing"
    :class="{ 'pointer-events-none overflow-hidden': !loaded }"
    @request-qr="emit('requestQr', video)"
  >
    <div class="flex flex-col h-full justify-center items-center rounded-lg aspect-[9/16] overflow-hidden bg-black/90">
      <img
        v-if="!enabled"
        class="w-full rounded-lg transition-all duration-300"
        :class="{ 'scale-90 opacity-0': !loaded }"
        loading="lazy"
        width="720"
        height="1280"
        @click="enabled = true"
        @load.once="loaded = true"
        :src="isError ? '/images/images/thumbnail.jpg' : video?.thumbnailUrl!"
        @error="isError = true"
        :alt="`Poster for '${video?.title}'`"
      />
    </div>
  </ResultVideoPreviewContainer>

  <ResultVideoPreviewContainer
    v-else-if="video"
    :video="video"
    :playing="playing"
    :class="{ 'skeleton pointer-events-none overflow-hidden': !canplay }"
    @request-qr="emit('requestQr', video)"
  >
    <video
      ref="element"
      :id="video.id!"
      class="w-full rounded-lg transition-all duration-300"
      :class="{ 'scale-90 opacity-0': !canplay }"
      crossorigin="anonymous"
      disablePictureInPicture
      disableRemotePlayback
      loop
      playsinline
      autoplay
      controls
      width="720"
      height="1280"
      @play="() => emit('play', video?.id)"
      @canplay.once="canplay = true"
      @error="enabled = false"
      :src="video.resultUrl!"
    />
  </ResultVideoPreviewContainer>
</template>

<style scoped lang="scss"></style>
