<template>
  <div @click.self="$emit('cancel')" class="modal modal-open">
    <div class="modal-box text-center">
      <div class="inline-flex flex-col items-center justify-center gap-8">
        <div class="flex flex-col items-center justify-center">
          <div class="text-center text-2xl font-bold leading-10 text-brand-state-text-primary">
            Choose when to display your sticker
          </div>
          <div class="text-center text-base font-normal leading-tight text-brand-state-text-primary">
            The sticker will be displayed for 5 seconds at the selected timing.
          </div>
        </div>
        <div class="flex flex-col items-center justify-start gap-4">
          <div class="inline-flex items-start justify-start">
            <button
              @click="$emit('confirm', 'start')"
              :class="{
                'bg-violet-600 text-white': sticker.animationTime === 'start',
                'bg-white text-brand-state-text-primary hover:border-blue-400 hover:bg-blue-50 ': sticker.animationTime !== 'start',
              }"
              class="flex shrink grow basis-0 items-center justify-center gap-4 self-stretch rounded-bl-xl rounded-tl-xl border border-gray-200 px-4 py-3 transition-colors md:px-8 md:py-6"
            >
              <div class="inline-flex flex-col items-center justify-center gap-1">
                <div class="relative h-10 w-10">
                  <AlignLeft />
                </div>
                <div class="text-center text-lg font-bold">Begin</div>
              </div>
            </button>
            <button
              @click="$emit('confirm', 'middle')"
              :class="{
                'bg-violet-600 text-white ': sticker.animationTime === 'middle',
                'bg-white text-brand-state-text-primary hover:border-blue-400 hover:bg-blue-50': sticker.animationTime !== 'middle',
              }"
              class="flex shrink grow basis-0 items-center justify-center gap-4 self-stretch border border-gray-200 px-4 py-3 transition-colors md:px-8 md:py-6"
            >
              <div class="inline-flex flex-col items-center justify-center gap-1">
                <div class="relative h-10 w-10">
                  <AlignHorizon />
                </div>
                <div class="text-center text-lg font-bold">Middle</div>
              </div>
            </button>
            <button
              @click="$emit('confirm', 'end')"
              :class="{
                'bg-violet-600 text-white': sticker.animationTime === 'end',
                'bg-white text-brand-state-text-primary hover:border-blue-400 hover:bg-blue-50': sticker.animationTime !== 'end',
              }"
              class="flex shrink grow basis-0 items-center justify-center gap-4 self-stretch rounded-br-xl rounded-tr-xl border border-gray-200 px-4 py-3 transition-colors md:px-8 md:py-6"
            >
              <div class="inline-flex flex-col items-center justify-center gap-1">
                <div class="relative h-10 w-10">
                  <AlignRight />
                </div>
                <div class="text-center text-lg font-bold">End</div>
              </div>
            </button>
          </div>
          <button
            @click="this.$emit('confirm', undefined)"
            class="inline-flex items-center justify-start gap-1 text-indigo-500"
          >
            <ResetIcon class="pb-0.5" />
            <div class="text-center text-lg font-medium">Reset to full duration</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import ResetIcon from '@/components/Icons/ResetIcon.vue'
import type { StickerData } from '@/store/editor/editorStickers'
import AlignLeft from '@/components/Icons/Normalized/AlignLeft.vue'
import AlignHorizon from '@/components/Icons/Normalized/AlignHorizon.vue'
import AlignRight from '@/components/Icons/Normalized/AlignRight.vue'

export default defineComponent({
  components: { AlignRight, AlignHorizon, AlignLeft, ResetIcon },
  props: {
    sticker: {
      type: Object as PropType<StickerData>,
      required: true,
    },
  },
  emits: ['confirm', 'cancel'],
})
</script>
