<script setup lang="ts">

import { Button } from "@/components/ui/button";
import { ref, watch } from 'vue'
import type { MultipleSurveyQuestion } from 'posthog-js'

const props = defineProps<{
  question: MultipleSurveyQuestion
}>();

const emit = defineEmits<{
  (event: 'answered', value: string): void
  (event: 'invalidAnswer'): void
}>();

watch(() => props.question, () => {
  selectedChoiceIndex.value = null;
  emit('invalidAnswer');
});

const selectedChoiceIndex = ref<number | null>(null);

const selectedChoice = (index: number) => {
  selectedChoiceIndex.value = index;
  emit('answered', props.question.choices[index]);
};
</script>

<template>
  <div class="flex flex-row flex-wrap gap-4 justify-center">
    <Button
      v-for="(choice, index) in question.choices"
      :key="choice"
      variant="outline"
      class="font-light min-w-[200px]"
      :class="{ 'bg-company-primary-50 text-white pointer-events-none': selectedChoiceIndex === index }"
      @click="() => selectedChoice(index)"
    >
      {{ choice }}
    </Button>
  </div>
</template>

<style scoped>

</style>