<script setup lang="ts">
import IconSaxFlag from '@/components/Icons/iconsax/IconSaxFlag.vue'
import { PostHog } from '@/logging/post-hog'
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'

const isBetaTester = useFeatureFlagEnabled('IsBetaTesterV2')
</script>

<template>
  <button
    v-if="PostHog.instance && isBetaTester"
    class="btn-primary btn-lg btn overflow-hidden border-none bg-transparent p-[11px] text-[12px] shadow-none transition-all hover:bg-transparent"
    id="early-access-features-button"
  >
    <IconSaxFlag class="h-5 w-5" />
  </button>
</template>

<style scoped lang="scss"></style>
