<script lang="ts" setup>
import { computed, ref } from 'vue'
import { onClickOutside, useElementSize } from '@vueuse/core'
import FeedLayer from '@/components/Editor/FeedLayer.vue'
import StickerLayer from '@/components/Editor/StickerLayer.vue'
import CaptionLayer from '@/components/Editor/CaptionLayer.vue'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import IconSaxSetting2 from '@/components/Icons/iconsax/IconSaxSetting2.vue'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'
import TikTokSafeZone from '@/components/Editor/SafeZone/TikTokSafeZone.vue'
import YoutubeSafeZone from '@/components/Editor/SafeZone/YoutubeSafeZone.vue'
import InstagramSafeZone from "@/components/Editor/SafeZone/InstagramSafeZone.vue";

const props = defineProps<{
  enableSnapping: boolean
  safeZones: [{ key: 'youtube'; show: boolean }, { key: 'tiktok'; show: boolean }, { key: 'instagram'; show: boolean }]
}>()

const emit = defineEmits<{
  (event: 'advancedSettings'): void
}>()

const editorWrapper = ref<HTMLElement | null>(null)
const phoneWrapper = ref<HTMLElement | null>(null)

const { width: editorWidth, height: editorHeight } = useElementSize(editorWrapper)
const { width: phoneWidth, height: phoneHeight } = useElementSize(phoneWrapper)

const showYoutubeSafeZone = computed(() => props.safeZones.find((zone) => zone.key === 'youtube')?.show)
const showTiktokSafeZone = computed(() => props.safeZones.find((zone) => zone.key === 'tiktok')?.show)
const showInstagramSafeZone = computed(() =>  props.safeZones.find((zone) => zone.key === 'instagram')?.show)

const editorFocusStore = useEditorFocusStore()

onClickOutside(editorWrapper, (e) => {
  if (!e.target?.matches('[data-retain-focus], [data-retain-focus] *')) {
    editorFocusStore.unFocus()
  }
})

const hasThreeColumnLayout = useFeatureFlagVariantEnabled('three-column-layout', 'wide')
</script>

<template>
  <div
    class="rounded-lg"
    :class="
      hasThreeColumnLayout
        ? 'preview-container flex h-full min-h-0 flex-col gap-2 border-2 border-gray-100 bg-white p-4'
        : 'border-10 border-gray-900 bg-gray-900'
    "
    :style="`--phone-width: ${phoneWidth}px; --phone-height: ${phoneHeight}px;`"
  >
    <!--    <iphone14 class="mx-auto" :inner-width="editorWidth">-->
    <div
      ref="phoneWrapper"
      :class="{ 'contain-box grid h-full min-h-0 w-full flex-auto place-items-center': hasThreeColumnLayout }"
    >
      <div
        ref="editorWrapper"
        class="editor-wrapper relative notranslate"
        :class="hasThreeColumnLayout
          ? 'contain border-gray-900'
          : 'h-[480px] w-[274px] 2xl:h-[568px] 2xl:w-[320px]'"
      >
        <feed-layer
          class="static col-span-1 row-span-1 rounded"
          ref="feedLayer"
          :containerWidth="editorWidth"
          :containerHeight="editorHeight"
        />
        <sticker-layer
          class="static col-span-1 row-span-1"
          ref="stickerLayer"
          :containerWidth="editorWidth"
          :containerHeight="editorHeight"
          :enableSnapping="enableSnapping"
        />
        <caption-layer
          class="static col-span-1 row-span-1"
          ref="captionLayer"
          :containerWidth="editorWidth"
          :containerHeight="editorHeight"
        />
        <TikTokSafeZone class="absolute h-full w-full inset-0" v-show="showTiktokSafeZone" />
        <YoutubeSafeZone class="absolute h-full w-full inset-0" v-show="showYoutubeSafeZone" />
        <InstagramSafeZone class="absolute h-full w-full inset-0" v-show="showInstagramSafeZone" />
      </div>
    </div>

    <button
      v-if="hasThreeColumnLayout"
      class="btn inline-flex w-full gap-2 rounded border-transparent bg-transparent text-sm font-semibold text-gray-600 hover:border-transparent hover:bg-gray-100"
      @click="emit('advancedSettings')"
    >
      <IconSaxSetting2 />
      Advanced Settings
    </button>
  </div>
</template>

<style scoped>
:root {
  --phone-width: 1080px;
  --phone-height: 1920px;
}

.editor-wrapper {
  position: relative;
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.preview-container {
  max-height: min(100%, calc(calc(var(--phone-width) * calc(16 / 10))) + 9rem);
}

/* Prevent border from interfering width `.contain` aspect ratio */
.contain-box {
  --border-width: theme('spacing.2');
  padding: var(--border-width);
}

.contain {
  box-sizing: content-box;
  border-width: var(--border-width);
  margin: calc(-1 * var(--border-width));

  --scale: min(calc(var(--phone-width) / 1080), calc(var(--phone-height) / 1920));
  --width: calc(1080 * var(--scale));
  --height: calc(1920 * var(--scale));
  width: var(--width);
  height: var(--height);
}
</style>
