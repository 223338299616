<template>
  <div class="mt-5 text-center">
    <h2><strong>Remote authentication failed</strong></h2>
    <ErrorIcon />
    <p>You can close this page</p>
    <p>
      <button @click="close" type="button" class="w-full rounded bg-red-600 p-2 text-white">Close</button>
    </p>
  </div>
</template>

<script>
import ErrorIcon from "@/components/Dialog/Icons/ErrorIcon.vue";

export default {
  components: { ErrorIcon },
  methods: {
    close() {
      try {
        window.close()
      } catch (e) {
        this.$router.push({ name: 'Home' })
      }
    },
  },
}
</script>

<style></style>
