<template>
  <tw-base-dialog styles="max-w-3xl" :modelValue="modelValue" @update:modelValue="(e) => $emit('update:modelValue', e)">
    <template v-slot:header="{ close }">
      <div class="absolute right-4 top-4">
        <div class="cursor-pointer p-2 text-gray-300 hover:text-company-primary" @click="close()">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current" viewBox="0 0 512 512">
            <path
              d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"
            />
          </svg>
        </div>
      </div>
    </template>
    <div class="flex w-full flex-col flex-col-reverse lg:flex-row">
      <div class="flex w-full flex-col px-4 py-16 lg:p-12">
        <div class="main-content flex-1">
          <h2 class="mb-0 text-4xl font-bold text-company-primary">Select video</h2>
          <p class="opacity-70">Upload your video to create a clip</p>

          <div
            @dragover.prevent="isDraggingFile = true"
            @dragleave.prevent="isDraggingFile = false"
            @drop.prevent="handleOnFileDrop"
            @click="onDropzoneClick"
            :class="{
              'border-dashed border-company-primary': !isDraggingFile,
              'border-solid border-green-600': isDraggingFile,
            }"
            class="dropzone my-8 flex cursor-pointer flex-col items-center justify-center rounded-xl border-2 bg-company-primary bg-opacity-10 py-8"
          >
            <div class="flex w-full flex-col items-center justify-center">
              <lottie-animation class="w-1/4" url="/lottie/upload-to-server.json" :auto-play="true" />
              <p class="mb-0 text-xl" v-if="!isDraggingFile">Drop file</p>
              <p class="mb-0 text-xl text-green-600" v-else>Drop the file in here!</p>
              <p class="text-xs text-gray-700">(or click here!)</p>
              <p class="text-xs text-gray-700">Supported formats: .mp4,.mov</p>

              <input type="file" class="hidden" ref="fileInput" @change="fileUploaded" accept=".mp4,.mov" />
            </div>
          </div>
          <div class="flex w-full justify-between">
            <button class="btn-outline btn-primary btn" @click="close">Back</button>
          </div>
        </div>
      </div>
    </div>
  </tw-base-dialog>
</template>
<script>
import TwBaseDialog from '@/components/Dialog/TwBaseDialog.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'

export default {
  components: {
    TwBaseDialog,
    LottieAnimation,
  },
  props: {
    modelValue: { type: Boolean, required: true, default: false },
  },
  emits: ['update:modelValue', 'fileSelected'],
  data() {
    return {
      isDraggingFile: false,
    }
  },
  emits: ['update:modelValue', 'fileSelected'],
  methods: {
    handleOnFileDrop(e) {
      if (e.dataTransfer.items) {
        [...e.dataTransfer.items].every((item) => {
          // If dropped items aren't files, reject them
          if (item.kind === 'file') {
            this.$emit('fileSelected', item.getAsFile())
            this.close()
            return false
          }
          return true
        })
      } else {
        // Use DataTransfer interface to access the file(s)
        [...e.dataTransfer.files].every((file) => {
          this.$emit('fileSelected', file)
          this.close()
          return false
        })
        return true
      }

      this.isDraggingFile = false
    },
    close() {
      this.$emit('update:modelValue', false)
    },
    onDropzoneClick() {
      if (this.uploadedFile) return
      this.$refs.fileInput.click()
    },
    fileUploaded(event) {
      if (event.target.files.length === 0) {
        return
      }
      this.$emit('fileSelected', event.target.files[0])
      this.close()
    },
  },
}
</script>
