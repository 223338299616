<template>
  <svg width="25" height="28" viewBox="0 0 25 28" class="stroke-current" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 16C19 12.41 16.09 9.5 12.5 9.5C8.91 9.5 6 12.41 6 16"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.48999 8.98999L5.35999 8.85999M19.51 8.98999L19.64 8.85999L19.51 8.98999ZM12.5 6.07999V6V6.07999ZM2.58002 16H2.5H2.58002ZM22.5 16H22.42H22.5Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M4.5 19H20.5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M6.5 22H18.5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9.5 25H15.5" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M10.4766 4.81L12.5 6.83333L14.5233 4.81"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5 1.16667L12.5 6.77666"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
