<template>
  <div class="mb-4 flex w-full flex-col">
    <span class="mb-2 text-company-primary">{{ label }}</span>
    <input
      :type="type"
      :value="modelValue"
      @input="(e) => $emit('update:modelValue', e.target.value)"
      :class="errors === '' && !hasError ? 'border-company-primary-300' : 'focus:error-focus border-red-600'"
      class="focus:form-focus rounded border px-4 py-2 tracking-normal placeholder-company-primary placeholder-opacity-50"
      :placeholder="placeholder"
    />
    <slot></slot>
    <p class="mb-0 mt-1 text-red-600" v-if="errors">{{ errors }}</p>
  </div>
</template>
<script>
export default {
  props: {
    errors: { type: String, default: '' },
    hasError: { type: Boolean, default: false },
    placeholder: { type: String, default: null },
    label: { type: String, default: null },
    type: { type: String, default: 'text' },
    modelValue: { default: null },
  },
}
</script>
