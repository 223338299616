<script setup lang="ts">
import TimeLineTrack from '@/modules/SLTimeline/TimeLineTrack.vue'
import TimeLineAddNode from '@/areas/editor/timeline/tracks/TimeLineAddNode.vue'
import IconSaxSearchZoomIn from '@/components/Icons/iconsax/IconSaxSearchZoomIn.vue'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import ZoomNode from '@/areas/editor/timeline/tracks/zooms/ZoomNode.vue'

const segmentsStore = useSegmentsStore()
const zooms = segmentsStore.whereIsZoom()
</script>

<template>
  <TimeLineTrack class="group relative min-h-[25px] w-full" :class="zooms.length === 0 ? 'h-6' : 'h-full min-h-6 max-h-12'">
    <TimeLineAddNode />
    <div
      v-if="zooms.length === 0"
      class="absolute inset-0 flex items-center justify-center gap-1.5 rounded-lg border border-brand-state-hover-border bg-brand-state-hover-bg text-brand-state-hover-border group-hover:hidden peer-[]:hidden text-sm font-normal"
    >
      <IconSaxSearchZoomIn class="h-4 w-4" />
      <p class="mt-0.5">Add zoom</p>
    </div>
    <ZoomNode v-for="(zoom, i) in zooms" :key="zoom.id" :index="i" :id="zoom.id" :zooms="zooms" />
  </TimeLineTrack>
</template>
