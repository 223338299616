
export function stringToSemiRandomNumber(str: string, min: number, max: number): number {
  // Hash the input string to get a numeric value
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i)
    hash = hash & hash // Convert to 32bit integer
  }

  // Normalize the hash to a value between 0 and 1
  const normalizedHash = (hash >>> 0) / 0xffffffff

  // Scale the normalized hash to the desired range
  const randomNumber = min + normalizedHash * (max - min)

  return randomNumber
}

export const colorOptions = [
  {
    "bg": "#1E7E34",  // Dark Green
    "border": "#155724",
    "handle": "#0b3812"
  },
  {
    "bg": "#117A8B",  // Dark Cyan
    "border": "#0c5967",
    "handle": "#073844"
  },
  {
    "bg": "#CC2400",  // Dark Red
    "border": "#991b00",
    "handle": "#661200"
  },
  {
    "bg": "#7200CC",  // Dark Purple
    "border": "#540099",
    "handle": "#380066"
  },
  {
    "bg": "#CC0056",  // Dark Pink
    "border": "#990040",
    "handle": "#66002b"
  },
  {
    "bg": "#008061",  // Dark Teal
    "border": "#005946",
    "handle": "#003f30"
  },
  {
    "bg": "#800064",  // Dark Magenta
    "border": "#590047",
    "handle": "#3d0031"
  },
  {
    "bg": "#FF6F61",  // Coral
    "border": "#cc574d",
    "handle": "#993e39"
  },
  {
    "bg": "#1E90FF",  // Dodger Blue
    "border": "#1573cc",
    "handle": "#0d5699"
  },
  {
    "bg": "#28A745",  // Green
    "border": "#1e7e34",
    "handle": "#155724"
  },
  {
    "bg": "#6C757D",  // Gray
    "border": "#555e64",
    "handle": "#3e464b"
  },
  {
    "bg": "#6610F2",  // Indigo
    "border": "#520cc1",
    "handle": "#3d0991"
  },
  {
    "bg": "#FFC107",  // Yellow
    "border": "#cc9a06",
    "handle": "#997504"
  },
  {
    "bg": "#E83E8C",  // Pink
    "border": "#b12f6e",
    "handle": "#81204f"
  },
  {
    "bg": "#FD7E14",  // Orange
    "border": "#ca6210",
    "handle": "#97300b"
  },
  {
    "bg": "#20C997",  // Teal
    "border": "#19977b",
    "handle": "#12615d"
  }
] as const

export function removeTags(str) {
  if (str === null || str === '' || str === undefined) return ''
  else str = str.toString()

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, '')
}
