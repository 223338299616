<script setup lang="ts">
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import IconSaxVolumeHigh from '@/components/Icons/iconsax/IconSaxVolumeHigh.vue'
import IconSaxVolumeSlash from '@/components/Icons/iconsax/IconSaxVolumeSlash.vue'
import { computed, ref } from 'vue'
import IconSaxVolumeLow from '@/components/Icons/iconsax/IconSaxVolumeLow.vue'
import IconSaxVolumeLow1 from '@/components/Icons/iconsax/IconSaxVolumeLow1.vue'

const editorVideoStore = useEditorVideoStore()

const isShowingVolumeSlider = ref(false)

const toggleMute = () => {
  editorVideoStore.muted = !editorVideoStore.muted
}

const showSlider = () => {
  isShowingVolumeSlider.value = true
}

const hideSlider = () => {
  isShowingVolumeSlider.value = false
}

const localVolume = computed({
  get: () => (editorVideoStore.muted ? 0 : editorVideoStore.volume * 100),
  set: (value) => {
    editorVideoStore.volume = value / 100
  },
})
</script>

<template>
  <div class="relative flex items-center justify-start" @mouseleave="hideSlider" :class="isShowingVolumeSlider ? 'ml-16' : ''">
    <Transition name="fade">
      <div
        v-if="isShowingVolumeSlider"
        class="absolute right-full flex h-full w-16 items-center justify-start overflow-hidden py-2"
      >
        <input class="mx-2 cursor-pointer range range-xs range-primary" type="range" v-model="localVolume" min="0" max="100" />
      </div>
    </Transition>
    <button
      class="btn-ghost btn-sm btn flex flex-row gap-2 rounded-lg px-1 text-black md:block"
      @click="toggleMute"
      @mouseenter="showSlider"
    >
      <IconSaxVolumeSlash v-if="editorVideoStore.muted" class="p-[1px]" />
      <IconSaxVolumeHigh v-else-if="editorVideoStore.volume > 0.5" class="p-[1px]" />
      <IconSaxVolumeLow v-else-if="editorVideoStore.volume < 0.25" class="p-[1px]" />
      <IconSaxVolumeLow1 v-else class="p-[1px]" />
    </button>
  </div>
</template>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: width 0.1s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  width: 0;
}
</style>
