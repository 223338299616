<template>
  <Tile :aspect=".6" v-if="destroying" class="pointer-events-none border-rose-50 bg-rose-50 text-rose-600">
    <IconLoader2 :size="48" class="recycling animate-spin" />
    Deleting...
  </Tile>
  <Tile :aspect=".6" v-else :selected="selected" class="group" feature="brandkit" @click="selectStyle(sticker)" @resize="onResize">
    <div class="flex flex-row">
      <component
        :is="getComponent()"
        :id="id"
        ref="sticker"
        :editable="false"
        :font-family="getFontFamily"
        :icon="sticker.icon"
        :primary-color="getPrimaryColor"
        :secondary-color="getSecondaryColor"
        :html-content="htmlContent"
        :variant="sticker.variant"
        class="sticker"
        @stickerLoaded="scaleSticker"
      />

      <Popover>
        <PopoverTrigger @click.stop="showOptions = !showOptions">
          <div class="rounded px-2 py-0 text-cyan-400 hover:text-cyan-300 absolute right-0 bottom-0 opacity-0 group-hover:opacity-100 transition-all">
            <IconDots />
          </div>
        </PopoverTrigger>
        <PopoverContent class="flex flex-col dropdown-open p-0 w-auto overflow-hidden">
          <div
            class="flex items-center gap-2 whitespace-nowrap px-3 pr-5 py-3 hover:bg-company-primary hover:bg-opacity-10 font-light cursor-pointer transition-all"
            @click="selectStyle(sticker)"
          >
            <IconCirclePlus :size="16" />
            <span class="mt-0.5">Add to video</span>
          </div>
          <div
            class="flex items-center gap-2 whitespace-nowrap px-3 pr-5 py-3 hover:bg-company-primary hover:bg-opacity-10 font-light cursor-pointer transition-all"
            @click="edit(sticker)"
          >
            <IconEdit :size="16" />
            <span class="mt-0.5">Edit style</span>
          </div>
          <div
            class="flex items-center gap-2 whitespace-nowrap px-3 pr-5 py-3 text-rose-600 hover:bg-rose-600 hover:text-white font-light cursor-pointer transition-all"
            @click.stop="destroy(sticker)"
          >
            <IconTrash :size="16" />
            <span class="mt-0.5">Delete from library</span>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  </Tile>
</template>

<script>
import TextSticker from '../Stickers/TextSticker.vue'
import EventBus from '../../eventBus'
import brandkitStyleTypes from '../../enums/brandkitStyleTypes'
import brandKitEvents from '../../events/brandKitEvents'
import TrashcanIcon from '../Icons/TrashcanIcon.vue'
import { useUserBrandkitStore } from '@/store/user/userBrandkit'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { markRaw } from 'vue'
import Tile from '@/components/Tile.vue'
import { IconCirclePlus, IconDots, IconEdit, IconLoader2, IconTrash } from '@tabler/icons-vue'
import {useEditorMainStore} from "@/store/editor/editorMain";
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { useStickersStore } from '@/store/entity-system/useStickersStore'
import textLibrary from '@/components/Stickers/textLibrary'
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import compatabilityChecker from '@/services/compatabilityChecker.js'

export default {
  components: { PopoverTrigger, PopoverContent, Popover, Tile, TrashcanIcon, IconDots, IconCirclePlus, IconEdit, IconTrash, IconLoader2 },
  props: {
    htmlContent: {
      type: String,
    },
    id: {
      type: Number,
    },
    // One of the objects in StickerLibrary.js or textLibrary.js
    sticker: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
    },
    isTextSticker: {
      type: Boolean,
      default: false,
    },
    hasEditableText: {
      type: Boolean,
      default: false,
    },
    shouldPreviewInClip: {
      type: Boolean,
      default: false,
    },
    // Component can be used for stickers and for captions.
    styleType: {
      type: String,
      default: brandkitStyleTypes.STICKERS,
    },
    displayActions: {
      type: Boolean,
      default: false,
    },
    primaryColor: {
      type: String,
    },
    secondaryColor: {
      type: String,
    },
    fontFamily: {
      type: String,
    },
  },
  data() {
    return {
      containerSize: null,
      showOptions: false,
      destroying: false,
    }
  },
  computed: {
    getPrimaryColor() {
      return this.sticker.primaryColor || this.primaryColor
    },
    getSecondaryColor() {
      return this.sticker.secondaryColor || this.secondaryColor
    },
    getFontFamily() {
      return this.sticker.fontFamily || this.fontFamily
    },
  },
  methods: {
    selectStyle() {
      switch (this.styleType) {
        case brandkitStyleTypes.STICKERS:
          this.selectSticker(this.style)
          break
        case brandkitStyleTypes.CAPTIONS:
          this.selectCaption(this.style)
          break
      }
    },
    getComponent() {
      return this.sticker.component ?? TextSticker
    },
    onResize(size) {
      this.containerSize = size
      this.scaleSticker()
    },
    scaleSticker() {
      if (!this.$refs.sticker || !this.containerSize) {
        return
      }
      const stickerElement = this.$refs.sticker.$el
      const targetWidth = this.containerSize.width * 0.8
      if (stickerElement.clientWidth >= targetWidth) {
        const stickerScale = targetWidth / stickerElement.clientWidth
        stickerElement.style.transform = `scale(${stickerScale})`
      } else {
        stickerElement.style.transform = null
      }
    },
    selectSticker(sticker) {
      this.$emit('stickerPicked', this.sticker)

      if (!this.shouldPreviewInClip) return

      const mainStore = useEditorMainStore()

      const newSticker = {
        component: markRaw(this.getComponent(sticker)),
        componentName: this.sticker.key,
        primaryColor: this.sticker.primaryColor,
        secondaryColor: this.sticker.secondaryColor,
        fontFamily: this.sticker.fontFamily,
        variant: this.sticker.variant,
        icon: this.sticker.icon,
        savedSticker: false,
        x: -1,
        y: -1,
        start: 0,
        end: mainStore.videoDuration,
        scale: -1,
        htmlContent: this.htmlContent ?? 'Enter\n text here',
        isTextSticker: this.isTextSticker,
        hasEditableText: this.hasEditableText,
        key: '',
        visible: true,
        animationStyle: 'none',
        animationTime: undefined,
      }

      const clientRect = this.$refs.sticker.$el.getBoundingClientRect()
      const clientScale = Number(this.$refs.sticker.$el.style.transform.trim().replace('scale(', '').replace(')', ''))
      const drawWidth = 0.8 * 1080
      const drawHeight = clientRect.height * (drawWidth / clientRect.width)

      const stickersStore = useStickersStore()
      const stickerId = stickersStore.createBrandKitSticker({
        key: textLibrary.find(t => t.variant === this.sticker.variant).key,
        variant: this.sticker.variant,
        textContent: this.htmlContent ?? 'Enter\n text here',
        fontFamily: this.sticker.fontFamily,
        primaryColor: this.sticker.primaryColor,
        secondaryColor: this.sticker.secondaryColor,
        naturalWidth: clientRect.width / clientScale,
        naturalHeight: clientRect.height / clientScale,
        area: {
          x: 0.1,
          y: this.htmlContent !== 'Enter Text\n here' ? 0.1 : 0.1 + Math.random() * (0.9 - drawHeight / 1920),
          width: drawWidth / 1080,
          height: drawHeight / 1920,
        }
      })
      
      setTimeout(() => useEditorFocusStore().setFocus(FocusTypes.TEXTSTICKER, stickerId), 0)

      // Scroll to top when a sticker is added
      if (compatabilityChecker.isMobile()) {
        window.scrollTo(0, 0);
      }

      EventBus.$emit('editor/stickers/added', newSticker)

      this.$trackEvent('Editor Brandkit TextSticker Added', {
        Variant: newSticker.variant,
        Color: newSticker.color,
        Tags: this.sticker.tags,
      })
    },
    selectCaption() {
      this.$emit('captionSelected')

      if (!this.shouldPreviewInClip) return
      const editorCaptionsStore = useEditorCaptionsStore()
      editorCaptionsStore.setSelectedCaptionStyle(this.sticker)
    },
    destroy(e) {
      this.destroying = true
      const userBrandkitStore = useUserBrandkitStore()
      userBrandkitStore.destroy(this.sticker.id)
    },
    callback() {
      if (this.selected) {
        this.selectCaption()
      }
    },
    edit(style) {
      this.showActions = false

      EventBus.$emit(brandKitEvents.OPEN_BRAND_KIT_EVENT, {
        style: style,
        callback: () => this.callback && this.callback(),
      })
    },
  },
}
</script>

<style lang="scss" scoped></style>
