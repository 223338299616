import type { VideoWorkerManager } from '@/webcodec-renderer/VideoWorkerManager'
import * as Sentry from '@sentry/vue'

class Queue<T extends { id: string | null }> {

  items: T[] = [];

  enqueue(item: T): void {
    this.items.push(item);
  }

  dequeue(): T | undefined {
    return this.items.shift();
  }

  peek(): T | undefined {
    return this.items[0];
  }

  isEmpty(): boolean {
    return this.items.length === 0;
  }

  size(): number {
    return this.items.length;
  }

  getItemById = (id: string): T | undefined => {
    return this.items.find((item: T) => item.id === id);
  }
}

export class VideoWorkerManagerQueue extends Queue<VideoWorkerManager> {


  enqueue(item: VideoWorkerManager): void {
    this.items.push(item);

    if (this.items.length === 1) {
      this.startNextExport().catch(Sentry.captureException);
    }
  }

  terminateAndClearAllItems() {
    this.items.forEach((item) => {
      item.terminate();
    });
    this.items = [];
  }

  private async startNextExport() {

    const currentWorker = this.peek();

    const someWorkersRendering = this.items.some((item) => item.isRendering);
    const someWorkersExporting = this.items.some((item) => item.isExporting);

    if (currentWorker && !someWorkersRendering && !someWorkersExporting) {
      currentWorker.startExporting().catch(Sentry.captureException);
      this.monitorCurrentWorker(currentWorker);
    }
  }

  private monitorCurrentWorker(worker: VideoWorkerManager) {

    const checkInterval = setInterval(() => {

      if (worker.isFinished || worker.isTerminated) {

        clearInterval(checkInterval);

        this.dequeue();
        if (!this.isEmpty()) {
          this.startNextExport().catch(Sentry.captureException);
        }
      }
    }, 500);
  }
}

// For locally rendered videos, we use a queue in order to render one video at a time
export const videoWorkerManagerQueue = new VideoWorkerManagerQueue();