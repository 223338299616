<script setup lang="ts">
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'

const { zoomLevel, zoomToLevel } = useTimelineStore()
const editorVideo = useVideoStore()

const onZoom = (e: Event) => {
  const zoomLevel = 1 - Number((e.target as HTMLInputElement).value)
  zoomToLevel(zoomLevel, editorVideo.getExactTime())
}

function tryBlur(event: MouseEvent) {
  if (event.target instanceof HTMLInputElement) {
    event.target.blur()
  }
}
</script>

<template>
  <input
    type="range"
    :value="1 - zoomLevel"
    @input="onZoom"
    @mouseup="tryBlur"
    min="0"
    max="1"
    step="0.025"
    class="zoom-range hidden h-[3px] w-12 cursor-pointer md:block"
  />
</template>

<style scoped>
.zoom-range {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  border: none;
  border-radius: 3px;
  background: #d3d3d3;
}
</style>
