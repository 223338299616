import type { EditorFragment, VideoEffect, VideoFragment } from '@/modules/SLVideoplayer/types'

export const getVideoFragmentFromEditorFragment = (editorFragment: EditorFragment): VideoFragment => {
  const effects: VideoEffect[] = []
  if (editorFragment.cropOptions.isRound) effects.push({ type: 'rounded' })

  return {
    sourceEndMs: 0,
    sourceStartMs: 0,
    ...editorFragment,
    effect: effects,
  } as VideoFragment
}

export const getBackgroundCropData = (
  sourceWidth: number,
  sourceHeight: number,
  outputWidth: number,
  outputHeight: number,
  normalize = true
) => {
  const scale = Math.min(sourceWidth / outputWidth, sourceHeight / outputHeight)
  const top = sourceHeight / 2 - (outputHeight / 2) * scale
  const left = sourceWidth / 2 - (outputWidth / 2) * scale
  const crop = {
    x: left,
    y: top,
    w: sourceWidth - 2 * left,
    h: sourceHeight - 2 * top,
  }
  if (normalize) {
    crop.x /= sourceWidth
    crop.y /= sourceHeight
    crop.w /= sourceWidth
    crop.h /= sourceHeight
  }
  return crop
}

export function invertedClamp(value: number, min: number, max: number) {
  if (value < min) {
    return value
  } else if (value > max) {
    return value
  } else {
    // If value is within the bounds, clamp it to the nearest edge
    return value - min < max - value ? min : max
  }
}

export function findClosestFittingLocation(
  target: number,
  width: number,
  timelineStart: number,
  timelineEnd: number,
  nodes: { start: number; end: number }[]
) {
  let closestGap: { start: number; end: number; distance: number } | null = null
  // Check gaps between nodes and timeline boundaries
  for (let i = 0; i < nodes.length + 1; i++) {
    let start, end

    if (i === 0) {
      start = timelineStart
      end = nodes.length > 0 ? nodes[i].start : timelineEnd
    } else if (i === nodes.length) {
      start = nodes[i - 1].end
      end = timelineEnd
    } else {
      start = nodes[i - 1].end
      end = nodes[i].start
    }

    // Check if the gap is big enough to fit the width
    if (end - start >= width) {
      const distance = target >= start && target <= end ? 0 : Math.min(Math.abs(start - target), Math.abs(end - target))

      if (!closestGap || distance < closestGap.distance) closestGap = { start, end, distance }
    }
  }

  if (closestGap) {
    if (closestGap.start > target - width / 2)
      return { point: closestGap.start + width / 2, distance: closestGap.distance }
    if (closestGap.end < target + width / 2) return { point: closestGap.end - width / 2, distance: closestGap.distance }
    return { point: target, distance: closestGap.distance }
  }

  return { point: target, distance: 0 }
}
