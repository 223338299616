import { createInjectionState, toValue, useElementSize } from '@vueuse/core'
import type { Ref, MaybeRefOrGetter } from 'vue'
import { computed, nextTick, ref, watch } from 'vue'
import { easingsFunctions, interpolate, invlerp } from '@/components/Editor/Timeline/helpers'
import type { MaybeRef } from '@vueuse/shared'
import { clamp, isNumber, noop } from 'lodash-es'

type TimelineOptions = {
  onSeek?: (ms: number, scrubbing?: boolean) => void
  zoomLevel?: number
  timelinePadding?: number
  minVisibleMs?: number
  minVisiblePx?: number
  openDefault?: boolean
  maxTimelineWidth?: MaybeRefOrGetter<number | null>
}

const [useProvideTimelineStore, _useTimelineStore] = createInjectionState(
  (durationMs: MaybeRef<number>, options?: TimelineOptions) => {
    ///////// Width and Zoom \\\\\\\\\\

    const containerEl = ref<HTMLElement | null>(null)
    const timelineEl = ref<HTMLElement | null>(null)

    const open = ref(options?.openDefault ?? true)

    const zoomLevel = ref(options?.zoomLevel ?? 1)

    const { width: containerWidth } = useElementSize(containerEl)

    const timelinePadding = options?.timelinePadding ?? 20

    const minVisibleMs = options?.minVisibleMs ?? 1000
    const minVisiblePx = options?.minVisiblePx ?? 100
    
    const minTimelineWidth = computed(() => containerWidth.value - timelinePadding * 2)
    const maxTimelineWidth = computed(() => {
      const maxTimelineWidth = toValue(options?.maxTimelineWidth)
      if (typeof maxTimelineWidth === 'number') {
        return maxTimelineWidth
      } else {
        return Math.max((toValue(durationMs) / minVisibleMs) * minVisiblePx, minTimelineWidth.value)
      }
    })

    const timelineWidth = computed(() => interpolate(minTimelineWidth.value, maxTimelineWidth.value, zoomLevel.value))

    const paddedTimelineWidth = computed(() => timelineWidth.value + timelinePadding * 2)
    ///////// Conversion \\\\\\\\\\

    const calculateMsToPx = (
      ms: number,
      durationMs: number,
      timelineWidth: number,
      timelinePadding: number,
      absolute = false
    ) => {
      if (absolute) {
        return Math.round((ms / durationMs) * timelineWidth)
      }
      return Math.round((ms / durationMs) * timelineWidth + timelinePadding)
    }
    const msToPx = (ms: number, absolute = false) => {
      return calculateMsToPx(ms, toValue(durationMs), timelineWidth.value, timelinePadding, absolute)
    }
    const useMsToPx = (ms: number | Ref<number>, absolute = false) => {
      return computed(() => {
        return calculateMsToPx(toValue(ms), toValue(durationMs), timelineWidth.value, timelinePadding, absolute)
      })
    }

    const calculatePxToMs = (
      px: number,
      durationMs: number,
      timelineWidth: number,
      timelinePadding: number,
      absolute = false
    ) => {
      if (absolute) {
        return (px / timelineWidth) * durationMs
      }
      return ((px - timelinePadding) / timelineWidth) * durationMs
    }
    const pxToMs = (px: number, absolute = false) => {
      return calculatePxToMs(px, toValue(durationMs), timelineWidth.value, timelinePadding, absolute)
    }
    const usePxToMs = (px: number | Ref<number>, absolute = false) => {
      return computed(() => {
        return calculatePxToMs(toValue(px), toValue(durationMs), timelineWidth.value, timelinePadding, absolute)
      })
    }

    const scrollToPx = (px: number) => {
      containerEl.value?.scrollTo({ left: px })
    }

    const scrollToMs = (ms: number) => {
      scrollToPx(msToPx(ms))
    }

    ///////// Auto Scroll and Seeking \\\\\\\\\\
    const _autoScroll = ref<'idle' | 'disabled' | 'seeking'>('idle')
    const autoScroll = (ms: number) => {
      if (!containerEl.value) return
      if (_autoScroll.value === 'disabled') return
      const start = containerEl.value?.scrollLeft
      const end = start + containerWidth.value
      const currentSeekerOffset = msToPx(ms)
      if (currentSeekerOffset < start || currentSeekerOffset > end) {
        _autoScroll.value = 'seeking'
        scrollToPx(currentSeekerOffset)
      }
    }

    const fitToTimeline = (ms: number, endMs: number) => {
      const duration = endMs - ms
      const durationPercent = duration / toValue(durationMs)
      const targetTimelineWidth = minTimelineWidth.value / durationPercent
      zoomLevel.value = clamp(1 - invlerp(minTimelineWidth.value, maxTimelineWidth.value, targetTimelineWidth), 0, 1)
      nextTick(() => {
        // center the zoom target
        const targetPx = msToPx(ms)
        const targetWidth = msToPx(duration)
        const leftScroll = targetPx - containerWidth.value / 2 + targetWidth / 2 - timelinePadding / 2
        scrollToPx(leftScroll)
      })
    }

    const zoomToLevel = async (zoom: number, targetMs?: number) => {
      if (!isNumber(zoom)) return
      zoomLevel.value = zoom
      await nextTick()
      if (targetMs) {
        const targetPx = msToPx(targetMs)
        const leftScroll = targetPx - containerWidth.value / 2 - timelinePadding / 2
        scrollToPx(leftScroll)
      }
    }

    const dragging = ref<{
      type: 'node' | 'seeker'
      id?: string
    }>()

    const triggerSeek = (ms: number, scrubbing = false) => {
      options?.onSeek?.(ms, scrubbing)
      _autoScroll.value = 'idle'
    }

    const ready = computed(() => {
      if (toValue(durationMs) <= 0) {
        return false
      } else {
        return containerWidth.value > 0
      }
    })

    return {
      containerEl,
      timelineEl,
      zoomLevel,
      timelineWidth,
      paddedTimelineWidth,
      timelinePadding,
      containerWidth,
      dragging,
      durationMs: computed(() => toValue(durationMs)),
      msToPx,
      pxToMs,
      useMsToPx,
      usePxToMs,
      fitToTimeline,
      scrollToMs,
      scrollToPx,
      autoScroll,
      zoomToLevel,
      triggerSeek,
      ready,
      open,
      _autoScroll,
    }
  }
)

export { useProvideTimelineStore }

export function useTimelineStore() {
  return (
    _useTimelineStore() ?? {
      _autoScroll: ref(true),
      containerEl: ref<HTMLElement | null>(null),
      timelineEl: ref<HTMLElement | null>(null),
      zoomLevel: ref(1),
      timelineWidth: ref(0),
      paddedTimelineWidth: ref(0),
      timelinePadding: 10,
      containerWidth: ref(0),
      dragging: ref<{
        type: 'node' | 'seeker'
        id?: string
      }>(),
      open: ref(true),
      durationMs: computed(() => 0),
      ready: computed(() => false),
      msToPx: (ms: number) => ms,
      pxToMs: (px: number) => px,
      usePxToMs: (px: number | Ref<number>, absolute = false) => {
        return computed(() => {
          return toValue(px)
        })
      },

      useMsToPx: (px: number | Ref<number>, absolute = false) => {
        return computed(() => {
          return toValue(px)
        })
      },
      zoomToLevel: async (zoom: number, targetMs?: number) => {},
      fitToTimeline: (ms: number, endMs: number) => {},
      scrollToMs: (ms: number) => {},
      scrollToPx: (px: number) => {},
      autoScroll: (ms: number) => {},
      triggerSeek: (ms: number, scrubbing = false) => {},
    }
  )
}
