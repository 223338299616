<template>
  <div class="mb-4 flex w-52 cursor-pointer flex-col">
    <span class="mb-2 text-company-primary">{{ label }}</span>
    <div class="flex space-x-4 rounded-xl bg-gray-100 p-2">
      <div class="h-8 w-8 rounded-full" :style="`background-color: ${this.color}`" @click="triggerColorPicker">
        <input type="color" v-model="color" @input="onColorPicked" ref="colorPicker" class="opacity-0" />
      </div>

      <div class="flex w-32 cursor-pointer" @click="enableEditingStatus">
        <input
          v-model="color"
          @blur="onColorPicked"
          :readonly="!isEditing"
          type="text"
          class="w-full bg-transparent"
          ref="hexInput"
        />
      </div>
    </div>
    <div></div>
    <p class="mb-0 mt-1 text-red-600" v-if="errors.title">{{ errors.title }}</p>
    <p class="mb-0 mt-1 text-red-600" v-if="message">{{ message }}</p>
  </div>
</template>
<script>
import { isValidHex } from '../../services/hexColorService'

export default {
  props: {
    errors: {
      type: Object,
      required: false,
      default: () => {
        return {}
      },
    },
    label: { type: String, required: false, default: null },
    type: { type: String, required: false, default: 'text' },
    modelValue: { required: false, default: null },
  },
  data() {
    return {
      color: '',
      isEditing: false,
      message: null,
    }
  },
  methods: {
    isValidHex,
    enableEditingStatus() {
      this.isEditing = true
      this.$refs.hexInput.focus()
    },
    onColorPicked(e) {
      let val = e.target.value

      if (!val.startsWith('#')) {
        val = '#' + val
      }

      if (!this.isValidHex(val)) {
        this.message = 'Not a valid HEX'
        return
      }

      this.message = null
      this.color = val
      this.$emit('update:modelValue', val)
    },
    triggerColorPicker() {
      this.$refs.colorPicker.click()
    },
  },
  mounted() {
    this.color = this.modelValue
  },
}
</script>
