<script setup lang="ts">
import { useMovableContext } from '@/modules/SLMovable/useMovableContext'

defineProps<{ maskId: string | null }>()

const { width, height } = useMovableContext()!
</script>

<template>
  <svg :width="width" :height="height" :viewBox="`0 0 ${width} ${height}`" v-if="maskId">
    <mask :id="maskId">
      <rect width="100%" height="100%" fill="white" />
    </mask>
    <rect :mask="`url(#${maskId})`" width="100%" height="100%" fill="rgba(0 0 0 / 50%)" />
  </svg>
</template>

<style scoped lang="scss"></style>
