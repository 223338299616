<script setup lang="ts">
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import IconSaxSubtitle from '@/components/Icons/iconsax/IconSaxSubtitle.vue'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import TimeLineTrackNode from '@/modules/SLTimeline/TimeLineTrackNode.vue'
import TimeLineTrack from '@/modules/SLTimeline/TimeLineTrack.vue'
import { computed } from 'vue'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'

const editorCaptionStore = useEditorCaptionsStore()
const editorFocusStore = useEditorFocusStore()
const editorVideoStore = useEditorVideoStore()
const editorFeedStore = useEditorFeedDataStore()

const captionsWithInSegment = computed(() => {
  return editorCaptionStore.captions.map((caption) => {
    const segment = editorFeedStore.segments.find((segment) => {
      return caption.end >= segment.start && caption.start <= segment.end
    })
    return {
      ...caption,
      text: caption.words.map((word) => word.text).join(' '),
      segmentId: segment?.id,
    }
  })
})
</script>

<template>
  <TimeLineTrack v-if="editorCaptionStore.captions.length > 0 && !editorCaptionStore.captionOverride" class="relative h-6 w-full">
    <TimeLineTrackNode
      class="group cursor-grab rounded-[5px] border-solid outline-white ring-offset-2"
      :class="{
        'border-gray-100 bg-gray-100 ring-gray-200 hover:bg-gray-200': !caption.segmentId,
        'border-yellow-500 bg-yellow-500 ring-yellow-600 hover:border-yellow-400 hover:bg-yellow-400 ':
          !!caption.segmentId,
      }"
      v-for="(caption, i) in captionsWithInSegment"
      :key="caption.id"
      :start-ms="caption.start"
      :end-ms="caption.end"
      :min-start-ms="editorCaptionStore.captions[i - 1] ? editorCaptionStore.captions[i - 1].end : 0"
      :max-end-ms="
        editorCaptionStore.captions[i + 1]
          ? editorCaptionStore.captions[i + 1].start
          : editorVideoStore._duration * 1000
      "
      :height="25"
      seek-behavior="seek"
      :active="editorFocusStore.focus?.type === 'caption' && editorFocusStore.focus?.key === caption.id"
      @resizeStop="(start, end) => editorCaptionStore.updateCaption(caption.id, { start, end })"
      @dragStop="(start, end) => editorCaptionStore.updateCaption(caption.id, { start, end })"
      @activated="
        () => {
          editorFocusStore.setFocus('caption', caption.id)
          editorVideoStore._currentTime = caption.start / 1000 + 0.01
        }
      "
    >
      <div class="flex h-full w-full items-center overflow-hidden rounded-[5px] text-sm text-gray-950">
        <span
          class="mx-3 max-h-full w-full select-none overflow-hidden whitespace-nowrap py-0.5 text-center align-middle"
          :class="{
            'hidden group-hover:inline': !caption.segmentId,
          }"
        >
          <IconSaxSubtitle class="h-4 w-4 mb-0.5" />
          {{ caption.text }}
        </span>
      </div>
      <template #handles>
        <div class="flex h-full flex-row gap-0.5 px-1 py-1">
          <div
            class="h-full w-1 rounded"
            :class="{
              'bg-gray-600': !caption.segmentId,
              'bg-yellow-600 ': !!caption.segmentId,
            }"
          ></div>
          <div
            class="h-full w-1 rounded"
            :class="{
              'bg-gray-600': !caption.segmentId,
              'bg-yellow-600 ': !!caption.segmentId,
            }"
          ></div>
        </div>
      </template>
    </TimeLineTrackNode>
  </TimeLineTrack>
</template>
