<template>
  <div
    class="relative h-full w-full"
    :class="{
      'z-[28]': focusStore.focus?.type === FocusTypes.FEED,
      'z-[27]': focusStore.focus?.type !== FocusTypes.FEED,
    }"
  >
    <PreviewVideoRenderer
      class="absolute inset-0"
      @videoLoaded="videoLoaded"
      :background="editorMainStore.layoutHasBlurredBackground"
      :updateVideoData="updateVideoData"
      :autoPlay="autoPlay"
    />
    <Feed
      v-for="fragment in editableFragments"
      :key="fragment.key"
      :fragment="fragment"
      :containerWidth="containerWidth"
      :containerHeight="containerHeight"
    />
  </div>
</template>
<script lang="ts">
import Feed from '@/components/Editor/Feed.vue'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { defineComponent, defineAsyncComponent, computed } from 'vue'
import { useEditorMainStore } from '@/store/editor/editorMain'
import { useUserTemplatesStore } from '@/store/user/userTemplates'
import { useEditorFocusStore, FocusTypes } from '@/store/editor/editorFocus'
import { useLayoutsStore } from '@/store/entity-system/useLayoutsStore'
import { useUserInfoStore, onUserInfoReadyAsync } from '@/store/user/userInfo'
import EventBus from '@/eventBus'
import mainEvents from '@/events/mainEvents'
import templateService from '@/services/templateService'

const PreviewVideoRenderer = defineAsyncComponent(() => import('@/components/Editor/PreviewVideoRenderer.vue'))

export default defineComponent({
  components: { Feed, PreviewVideoRenderer },
  props: {
    containerWidth: {
      type: Number,
      default: 0,
    },
    containerHeight: {
      type: Number,
      default: 0,
    },
    updateVideoData: {
      type: Boolean,
      default: true,
    },
    autoPlay: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const editorMainStore = useEditorMainStore()
    const templatesStore = useUserTemplatesStore()
    const feedStore = useEditorFeedDataStore()
    const focusStore = useEditorFocusStore()
    const userInfoStore = useUserInfoStore()
    const layoutsStore = useLayoutsStore()

    const enabled = computed(() => !!editorMainStore.layoutId && userInfoStore.hasLoadedUserInfo)

    return {
      editorMainStore,
      templatesStore,
      feedStore,
      focusStore,
      enabled,
      customLayout: layoutsStore.selectById(editorMainStore.layoutId),
    }
  },
  data() {
    return {
      isVideoLoaded: false,
      layout: null,
      //
      videoFragments: [],
    }
  },
  computed: {
    FocusTypes() {
      return FocusTypes
    },
    editableFragments() {
      return this.isVideoLoaded
        ? this.feedStore.fragmentArray.filter((fragment) => {
          return fragment.feedOptions.draggable || fragment.feedOptions.resizable
        })
        : []
    },
  },
  methods: {
    async videoLoaded(videoElement: HTMLVideoElement) {
      if (this.editorMainStore.templateId || this.$route.query.savedTemplate) {

        await onUserInfoReadyAsync()

        // Await promise in getTemplates. Will not do additional request.
        if (this.templatesStore.isLoading) await templateService.getTemplates()

        const template = this.templatesStore.savedTemplates.find((x) => x.id == this.editorMainStore.templateId)

        if (!template) {
          return EventBus.$emit(mainEvents.ERROR, 'We encountered an issue while loading your template. Please try again '
            + 'later or contact support if the issue persists.')
        }

        this.feedStore.initializeForTemplateFeeds(
          template,
          videoElement.videoWidth,
          videoElement.videoHeight,
          this.containerWidth,
          this.containerHeight
        )
      } else if (this.customLayout) {
        this.initializeForCustomLayout()
      } else {
        this.feedStore.initializeForFeed(
          this.editorMainStore.layoutName,
          videoElement.videoWidth,
          videoElement.videoHeight,
          this.containerWidth,
          this.containerHeight
        )
      }

      await this.$nextTick()
      this.isVideoLoaded = true
      this.$emit('videoLoaded')
    },
    initializeForCustomLayout() {
      if (this.customLayout) {
        this.feedStore.initializeForCustomLayout(this.customLayout.id)
      }
    },
  },
  mounted() {
    // Sync templateId with query param
    this.editorMainStore.templateId = this.editorMainStore.templateId || this.$route.query.savedTemplate as string
    this.$router.replace({ name: this.$route.name as string, query: { ...this.$route.query, savedTemplate: this.editorMainStore.templateId }})
  },
  watch: {
    customLayout: {
      handler(layout) {
        if (layout && this.isVideoLoaded) {
          this.feedStore.initializeForCustomLayout(layout.id)
        }
      },
      immediate: true,
    },
  }
})
</script>
