// Url parsing and data fetching
import { useRoute, useRouter } from 'vue-router'
import { computed, onMounted, ref } from 'vue'
import { isArray } from 'lodash-es'
import type { DeepPartial } from 'ts-essentials'
import {
  type ContentPublisherFormState,
  MarketingHashtags,
} from '@/areas/dashboard/pages/contentPublishing/ContentPublishHelpers'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import localForage from 'localforage'
import EventBus from '@/eventBus'
import mainEvents from '@/events/mainEvents'

export const useContentPublisherFileData = () => {
  const route = useRoute()

  const fileId = computed(() => {
    if (isArray(route.params.fileId) || !route.params.fileId) {
      return undefined
    }
    return route.params.fileId
  })

  const fileUrl = ref<string>()
  onMounted(async () => {
    const blob = await localForage.getItem('resultBlob')
    if (!blob) {
      hasFile.value = false
    } else {
      fileUrl.value = URL.createObjectURL(blob) || undefined
    }
  })

  const hasFile = ref(true)

  const fileData = computed( () => {
    return {
      title: route.query.clipName,
      url: fileUrl.value,
    }
  })

  const getFormData = (): DeepPartial<ContentPublisherFormState> => {
    return {
      youtube: {
        title: fileData.value?.title || undefined,
        description: MarketingHashtags,
      },
      instagram: {
        title: fileData.value?.title || undefined,
      },
      tikTok: {
        title: fileData.value?.title + ' ' + MarketingHashtags || MarketingHashtags,
      },
    }
  }

  return {
    fileId,
    hasFile,
    fileData,
    getFormData,
  }
}
