import axios from 'axios'

export async function queryProductUpdates(since: Date): Promise<ProductUpdate[]> {
  const url = import.meta.env.VITE_PRODUCT_UPDATES_URL
  if (!url)
    throw new Error('Missing VITE_PRODUCT_UPDATES_URL environment variable. Please define this in your .env file.')

  const publicKey = import.meta.env.VITE_PRODUCT_UPDATES_PUBLIC_KEY
  if (!publicKey)
    throw new Error(
      'Missing VITE_PRODUCT_UPDATES_PUBLIC_KEY environment variable. Please define this in your .env file.'
    )

  const productUpdatesUrl = new URL(url)
  productUpdatesUrl.searchParams.set('product', 'eq.streamladder')
  productUpdatesUrl.searchParams.set('timestamp', 'gt.' + new Date(since).toISOString())
  productUpdatesUrl.searchParams.set('select', '*')

  const { data } = await axios.get<ProductUpdate[]>(productUpdatesUrl.href, {
    headers: {
      ApiKey: publicKey,
      Authorization: `Bearer ${publicKey}`,
    },
  })

  return data?.sort((a, b) => timeOf(b) - timeOf(a)) ?? []
}

function timeOf(productUpdate: ProductUpdate) {
  return new Date(productUpdate.timestamp).getTime()
}

type Author = {
  id: string
  username: string
  avatar: string
  discriminator: string
  public_flags: number
  premium_type: number
  flags: number
  banner: null | string
  accent_color: null | string
  global_name: string
  avatar_decoration_data: null | string
  banner_color: null | string
}

type Attachment = {
  id: string
  filename: string
  size: number
  url: string
  proxy_url: string
  width: number
  height: number
  content_type: string
  placeholder: string
  placeholder_version: number
}

type RawMessage = {
  id: string
  type: number
  content: string
  channel_id: string
  author: Author
  attachments: Attachment[]
  embeds: never[] // assuming this is always an empty array
  mentions: never[] // assuming this is always an empty array
  mention_roles: never[] // assuming this is always an empty array
  pinned: boolean
  mention_everyone: boolean
  tts: boolean
  timestamp: string
  edited_timestamp: null | string
  flags: number
  components: never[] // assuming this is always an empty array
}

export type ProductUpdate = {
  id: number
  timestamp: string
  content: string
  message_id: string
  edited_at: null | string
  raw_message: RawMessage
  attachments: Attachment[]
  product: string
}
