<template>
  <div class="flex w-full flex-col gap-2 rounded-xl border-2 border-gray-100 bg-white p-4 sm:flex-row md:flex-col">
    <div class="relative w-full">
      <div v-if="isLoadingClip" class="loader absolute inset-0 grid place-items-center">
        <div class="spinner-border h-8 w-8 text-[#2fc1fe]" role="status" />
      </div>
      <VideoPlayer2
        class="max-w-full"
        ref="videoPlayer"
        @loaded="videoLoaded"
        :is-preview="false"
        update-video-data
        auto-play
        controls
      />
    </div>
    <div class="flex w-full flex-shrink flex-col overflow-hidden sm:w-auto">
      <dl class="clip-info-block w-full">
        <dt>Creator name</dt>
        <dd class="broadcaster">{{ broadcasterName }}</dd>
        <dt>Title</dt>
        <dd class="title text-ellipsis">{{ title }}</dd>
        <dt>Game</dt>
        <dd>{{ gameName }}</dd>
        <dt>Viewcount</dt>
        <dd v-if="viewCount > 0">{{ viewCount }} times viewed</dd>
      </dl>
      <button class="btn-outline btn-primary btn self-end" @click="changeVideo">
        <edit-icon class="h-5 w-5" />
        <span class="ml-2">Change video</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import EditIcon from '../Icons/EditIcon.vue'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { useEditorMainStore } from '@/store/editor/editorMain'
import VideoPlayer2 from '@/components/VideoPlayer2.vue'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import Cropper from '@/components/Editor/Cropper.vue'

export default {
  components: {
    VideoPlayer2,
    EditIcon,
  },
  computed: mapState(useEditorClipInfoStore, [
    'title',
    'isLoadingClip',
    'mp4Url',
    'thumbnailUrl',
    'viewCount',
    'broadcasterName',
    'gameName',
  ]),
  methods: {
    async changeVideo() {
      await this.$router.push({ name: 'ClipEditor' })
    },
    videoLoaded(element) {
      // This is a workaround for when the event is fired, but we don't have the video element anymore
      const editorMainStore = useEditorMainStore()
      const editorFeedStore = useEditorFeedDataStore()

      editorFeedStore.initSegments(element.duration * 1000)
      // editorMainStore.videoDuration = Math.round(this.$refs.videoElement.duration * 1000)
      editorMainStore.videoHeight = element.videoHeight
      editorMainStore.videoWidth = element.videoWidth
    },
  },
}
</script>

<style lang="scss" scoped>
.video-loading {
  background-color: rgb(124, 124, 124);
}

.video-preview {
  background: white;
  border-radius: 15px;
  border: 2px solid #eff2f9;
}

.clip-info-block {
  margin-bottom: 1rem;

  dt {
    display: none;
  }

  dd {
    font-weight: 500;
    margin-bottom: 0;
  }

  .broadcaster {
    color: #33cff2;
  }

  .title {
    font-weight: 600;
    font-size: 1.2em;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
