<script lang="ts" setup>
import { onUserInfoReady } from '@/store/user/userInfo'
import { type ProductUpdate, queryProductUpdates } from '@/queries/queryProductUpdates'
import { computed, defineAsyncComponent, ref } from 'vue'
import { subDays } from 'date-fns'
import settings from '@/data/settings'
import DashboardProductUpdateItem from '@/areas/dashboard/components/DashboardProductUpdateItem.vue'

const data = ref<ProductUpdate[]>()

onUserInfoReady(async ({ userId }) => {
  if (!userId) return

  const daysToShow = 90
  const response = await queryProductUpdates(subDays(new Date(), daysToShow))
  if (!response || !response[0]) return
  data.value = response
})

const VueMarkdown = defineAsyncComponent(() => import('vue-markdown-render'))

const post = computed(() => data.value?.[0])
</script>

<template>
  <main class="flex flex-col gap-8 p-4 lg:gap-16 lg:p-12">
    <header class="flex w-full shrink-0 flex-row items-center justify-between">
      <div class="flex flex-col">
        <h1 class="text-3xl lg:text-5xl">What's new</h1>
        <p class="font-light text-opacity-50">New updates and improvements to Streamladder</p>
      </div>
      <a :href="settings.discordInviteUrl" target="_blank" class="text-brand-state-link underline underline-offset-2 hover:underline-offset-4 transition-all"> Bugs or feedback </a>
    </header>
    <div class="flex flex-col gap-6">
      <DashboardProductUpdateItem v-for="post in data" :key="post.id" :productUpdate="post" />
    </div>
  </main>
</template>

<style lang="scss" scoped></style>
