<script setup lang="ts">
import settings from '@/data/settings'
import CancelOptionsPanel from '@/components/Account/Cancel/CancelOptionsPanel.vue'
import DiscordLogo from '@/components/Icons/DiscordLogo.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import AccountAndBilling from '@/assets/user-guides/account-and-billing.png'
import SocialIntegrations from '@/assets/user-guides/social-integrations.png'
import Editing from '@/assets/user-guides/editing.png'
import Accordion from '@/components-v2/modules/Accordion/Accordion.vue'

const guides = [
  {
    url: 'https://streamladder.com/user-guides/account',
    img: AccountAndBilling,
    title: 'Account & Billing',
    description: 'Navigate your account settings and understand your billing options.',
  },
  {
    url: 'https://streamladder.com/user-guides/publishing',
    img: SocialIntegrations,
    title: 'Social integrations',
    description: 'Seamlessly connect and manage your social platforms.',
  },
  {
    url: 'https://streamladder.com/user-guides/editing',
    img: Editing,
    title: 'Editing',
    description: 'Get tips on rendering, adding captions, text, stickers, and more.',
  },
]
</script>
<template>
  <div class="flex w-full flex-col items-start gap-4 md:flex-row">
    <!-- Promotion section -->
    <div class="panel p-0">
      <div class="flex w-full flex-col px-6 py-8 md:flex-row-reverse md:px-20 md:py-24">
        <LottieAnimation url="/lottie/support.json" class="min-w-[40%] hue-rotate-[25deg]" />
        <div class="inline-flex flex-col items-start justify-start gap-6">
          <div class="flex flex-col items-start justify-start gap-6">
            <div class="flex flex-col items-start justify-start gap-3">
              <div class="text-4xl font-bold leading-snug">
                Before you leave, can we assist you with any unresolved issues?
              </div>
              <div class="text-lg font-normal leading-snug">
                We have a dedicated Discord where members of our team are actively answering questions and solving
                problems.
              </div>
            </div>
          </div>
          <div class="btn-lg btn gap-4 border-indigo-500 bg-indigo-500">
            <div class="text-base font-bold leading-tight text-stone-50">Get direct support on Discord</div>
            <a class="flex items-start justify-start gap-5" :href="settings.discordInviteUrl">
              <DiscordLogo class="relative h-6 w-6" />
            </a>
          </div>
        </div>
      </div>
      <div class="bg-stone-100 px-12 pb-20 pt-14">
        <div class="flex flex-col items-center justify-start text-center">
          <div class="text-3xl font-bold capitalize text-purple-900">Explore Our User guides</div>
          <div class="text-lg font-normal leading-snug text-purple-900">
            Find solutions to common issues and enhance your streaming experience.
          </div>
        </div>
        <div class="mt-12 grid items-start justify-start gap-4 md:grid-cols-3">
          <article
            v-for="guide in guides"
            :key="guide.url"
            class="flex flex-col items-center rounded-lg bg-white px-4 py-8"
          >
            <img class="mb-6 h-12 w-12" :alt="guide.title" :title="guide.title" :src="guide.img" />
            <span class="mb-1 text-xl font-bold text-purple-900">{{ guide.title }}</span>
            <p class="mb-8 max-w-[20ch] text-center">{{ guide.description }}</p>
            <a class="btn-outline btn-primary btn" :href="guide.url" target="_blank"> Explore {{ guide.title }} </a>
          </article>
        </div>
      </div>

      <div class="px-6 py-8 md:px-20 md:py-24">
        <h4>FAQ</h4>
        <p class="text-sm font-light">
          Are you getting the most out of StreamLadder? Don’t let your questions hold you back.
        </p>
        <ol class="mt-4 flex flex-col">
          <li>
            <Accordion title="Does StreamLadder reduce the quality of my clips?" class="bg-white">
              <p>
                If you feel like your clips don’t look as good as you expected them to be, check out our
                <a
                  class="link font-bold"
                  href="https://streamladder.com/user-guide/how-to-make-sure-you-get-the-highest-video-quality"
                  >user guide</a
                >
                to find out what the reason for this might be.
              </p>
            </Accordion>
          </li>
          <li>
            <Accordion title="Why aren’t my videos getting more views?" class="bg-white">
              <p>
                TikTok and YouTube are pretty secretive about their algorithms and how to manipulate them to your
                advantage. Luckily, we’ve done some research and are happy to share our findings. Try
                <a
                  class="link font-bold"
                  href="https://streamladder.com/user-guide/how-to-make-the-tiktok-and-youtube-shorts-algorithm-work-for-you"
                  >these steps</a
                >
                to get more views.
              </p>
            </Accordion>
          </li>
          <li>
            <Accordion title="Why aren’t my videos getting published to TikTok?" class="bg-white">
              <p>
                Using our Content Publisher, but your videos aren’t being published to TikTok? Check out what might be
                the cause
                <a
                  class="link font-bold"
                  href="https://streamladder.com/user-guide/publishing-to-tiktok-with-streamladder"
                  >here</a
                >
              </p>
            </Accordion>
          </li>
          <li>
            <Accordion title="Why won't my videos render?" class="bg-white">
              <p>
                We support a wide variety of input videos, but sometimes unexpected errors do occur. Try our
                <a class="link font-bold" href="https://app.streamladder.com/video-support">video support tool</a> why
                your video might fail to render.
              </p>
            </Accordion>
          </li>
          <li>
            <Accordion title="Is there an app that I can download?" class="bg-white">
              <p>
                No, we currently see no added value in creating an app, but the StreamLadder website works very well on
                a mobile device.
              </p>
            </Accordion>
          </li>
          <li>
            <Accordion title="How can I get access to all of the features?" class="bg-white">
              <p>
                Some features are only available with a Silver or Gold subscription. If you do have a paid subscription
                and still don’t have access to certain features, please contact us on
                <a class="link font-bold" :href="settings.discordInviteUrl" target="_blank">Discord</a>.
              </p>
            </Accordion>
          </li>
        </ol>
      </div>
    </div>
    <cancel-options-panel />
  </div>
</template>