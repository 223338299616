<script setup lang="ts">
import { computed, onMounted, onUnmounted } from 'vue'
import type { Area } from '@/modules/SLMovable/@types/Movable'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import MovableElement from '@/modules/SLMovable/MovableElement.vue'
import IconSaxArrowUp2 from '@/components/Icons/iconsax/IconSaxArrowUp2.vue'
import { useEditorFocusStore, FocusTypes } from '@/store/editor/editorFocus'
import { useMovableContext } from '@/modules/SLMovable/useMovableContext'
import CaptionsMovableToolbar from '@/areas/editor/workspaces/preview/layers/captions/CaptionElementToolbar.vue'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'

defineProps<{ snappingEnabled: boolean }>()

const editorCaptionsStore = useEditorCaptionsStore()
const baseCaptionsWrapperSize = {
  width: 220,
  height: 150
}

const area = computed({
  get: () => ({
    x: editorCaptionsStore.captionsWrapper.x,
    y: editorCaptionsStore.captionsWrapper.y,
    width: baseCaptionsWrapperSize.width / movableWidth.value * editorCaptionsStore.captionsWrapper.scale * movableWidth.value,
    height: baseCaptionsWrapperSize.height / movableHeight.value * editorCaptionsStore.captionsWrapper.scale * movableWidth.value,
  }),
  set: (area: Area) => {
    editorCaptionsStore.captionsWrapper.x = area.x
    editorCaptionsStore.captionsWrapper.y = area.y
    editorCaptionsStore.captionsWrapper.scale = area.width * movableWidth.value / baseCaptionsWrapperSize.width / movableWidth.value
  }
})

const { width: movableWidth, height: movableHeight, x: movableX, y: movableY } = useMovableContext()!

const editorFocusStore = useEditorFocusStore()
const focused = computed(() => {
  return editorFocusStore.focus && editorFocusStore.focus.type === FocusTypes.CAPTION
})

function setFocus() {
  editorFocusStore.focus = {
    type: FocusTypes.CAPTION
  }
}

function handleKeyPress(event: KeyboardEvent) {
  if (!focused.value) return
  if (event.key === 'Escape') {
    if (focused.value) {
      useEditorFocusStore().unFocus()
      event.preventDefault()
      return false
    }
  }
}

onMounted(() => {
  window.addEventListener('keypress', handleKeyPress)
  window.addEventListener('keydown', handleKeyPress)
})

onUnmounted(() => {
  window.removeEventListener('keypress', handleKeyPress)
  window.removeEventListener('keydown', handleKeyPress)
})

const historyStore = useHistoryStore()
const { isLayoutsStep } = useEditorStep()
</script>

<template>
  <div
    v-if="editorCaptionsStore.captions.length > 0"
    :style="{
      left: movableX + area.x * movableWidth + 'px',
      top: movableY + area.y * movableHeight + 'px',
      width: area.width * movableWidth + 'px',
      height: area.height * movableHeight + 'px'
    }"
    class="transition-opacity group fixed"
    :class="isLayoutsStep ? '-z-50' : 'z-50'"
    @click.stop
    @touchstart.stop="setFocus"
    @mousedown.stop="setFocus"
  >
    <MovableElement :id="'captions'" :move="!isLayoutsStep" :resize="!isLayoutsStep" class="absolute inset-0"
      v-model="area"
      shape="rectangle"
      @resize-end="historyStore.push()"
      @move-end="historyStore.push()"
      :aspectLock="baseCaptionsWrapperSize"
      :bounds="{ top: -0.10, right: 1.30, bottom: 1.10, left: -0.30 }"
      :snap="snappingEnabled"
      :min-size="0"
      :resize-handle-class="focused ? 'z-[250]' : ''"
      :handles="['e', 'w']"
    >
      <template #move>
        <div
          class="absolute -inset-px border-2 transition-[opacity] border-brand-state-active-border box-content"
          :class="{
            'opacity-100': focused,
            'opacity-0 group-hover:opacity-75': !focused,
          }"
        />
      </template>
      <template #resize-direction="{ angle }">
        <div
          :class="focused ? 'opacity-100' : 'opacity-0'"
          class="w-5 h-5 grid place-items-center rounded-full layer-1 transition-[opacity] text-brand-state-active-border drop-shadow"
        >
          <IconSaxArrowUp2 class="w-3 h-3" :style="{ transform: `rotate(${angle}deg)`}" />
        </div>
      </template>
    </MovableElement>

    <CaptionsMovableToolbar v-if="focused && !isLayoutsStep" :area="area" />
  </div>
</template>

<style scoped lang="scss">

</style>
