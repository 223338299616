<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue'

const tenantId = '18c3f0715bd01f6de2676d735e341b6a'
const embedId = '18c3f0c44c00542b19f767f44823d90d'

onMounted(() => {
  const script = document.createElement('script')
  script.id = `flockler-embed-script-${embedId}`
  script.src = `https://plugins.flockler.com/embed/${tenantId}/${embedId}`
  script.async = true

  document.body.insertAdjacentElement('beforeend', script)
})

onUnmounted(() => {
  const script = document.getElementById(`flockler-embed-script-${embedId}`)
  script?.remove()
})
</script>

<template>
  <div :id="`flockler-embed-${embedId}`" class="min-h-[540px]" />
</template>
