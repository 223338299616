import { ref, watch } from "vue";
import { useWindowFocus } from "@vueuse/core";

export function useTimeBasedGreetings() {
  updateGreeting()
  return greeting
}

function inRange(value: number, min: number, max: number) {
  return value >= min && value <= max
}

const greeting = ref<typeof greetings[number]['text'] | 'Welcome back'>()
const timeout = ref<NodeJS.Timeout>()

const greetings = [
  { from:  6, through: 10, text: 'Good morning' },
  { from: 12, through: 18, text: 'Good afternoon' },
  { from: 19, through: 23, text: 'Good evening' },
  { from:  0, through:  5, text: 'Good night' },
] as const

function updateGreeting() {
  clearTimeout(timeout.value)
  timeout.value = undefined

  const currentHours = new Date().getHours()
  const currentGreeting = greetings.find(g => {
    return inRange(currentHours, g.from, g.through)
  })

  const endTime = new Date()
  endTime.setMinutes(0)
  endTime.setSeconds(0)
  endTime.setMilliseconds(0)

  if (currentGreeting) {
    greeting.value = currentGreeting.text
    endTime.setHours(currentGreeting.through)
  } else {
    greeting.value = 'Welcome back'
    endTime.setHours(endTime.getHours() + 1)
  }

  timeout.value = setTimeout(updateGreeting, endTime.getTime() - new Date().getTime())
}

watch(useWindowFocus(), () => updateGreeting())
