<script lang="ts" setup>
import UploadWorkspace from '@/areas/editor/workspaces/UploadWorkspace.vue'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import MovableRoot from '@/modules/SLMovable/MovableRoot.vue'
import { useCropsStore, newCrop } from '@/areas/editor/store/useCropsStore'
import { useLayoutsStore } from '@/areas/editor/store/useLayoutsStore'
import { provide, ref, onMounted, computed, watch, onUnmounted } from 'vue'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { useRafFn, useLocalStorage } from '@vueuse/core'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import type { Size } from '@/modules/SLMovable/@types/Movable'
import CanvasPreview from '@/areas/editor/workspaces/preview/CanvasPreview.vue'
import { createWorkspaceBoundingContext } from '@/areas/editor/context/workspaceSize'
import CropElement from '@/areas/editor/workspaces/preview/cropper/CropElement.vue'
import { useWorkspaceSizes } from '@/areas/editor/workspaces/useWorkspaceSizes'
import { Button } from '@/components/ui/button'
import { Switch } from '@/components/ui/switch'
import YoutubeIcon from '@/components/Icons/SocialMedia/YoutubeIcon.vue'
import TikTokIcon from '@/components/Icons/SocialMedia/TikTokIcon.vue'
import InstagramIcon from '@/components/Icons/SocialMedia/InstagramIcon.vue'
import { Select, SelectTrigger, SelectContent, SelectItem } from '@/components/ui/select'
import { useCurrentSegment } from '@/areas/editor/store/useCurrentSegment'
import { useUserInfoStore } from '@/store/user/userInfo'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import WorkspaceSpinner from "@/areas/editor/workspaces/WorkspaceSpinner.vue";
import { clamp } from 'lodash-es'

const clipInfoStore = useEditorClipInfoStore()

const cropsStore = useCropsStore()
const layoutsStore = useLayoutsStore()
const segment = useCurrentSegment()
const layoutId = computed(() => segment.value?.layoutId)

const layout = computed(() => layoutsStore.selectById(layoutId.value))
const crops = cropsStore.whereLayoutIdIs(layoutId)

const editorFocusStore = useEditorFocusStore()

function onKeyDown(e: KeyboardEvent) {

  const ctrlKey = e.ctrlKey || e.metaKey
  const plusKey = e.key === '+' || e.key === '='
  const minusKey = e.key === '-' || e.key === '_'
  const zeroKey = e.key === '0'

  const options = [...previewScaleOptions.value.filter(o => o.value !== 'auto')]
    .sort((a, b) => (a.value as number) - (b.value as number))

  let index = 0
  for (let i = 0; i < options.length; i++) {
    const scale = options[i].value as number
    if (previewScaleSetting.value === 'auto') {
      if (scale >= autoPreviewScale.value) {
        index = Math.max(0, i - i)
        break
      }
    } else {
      if (options[i].value === previewScaleSetting.value) {
        index = i
        break
      }
    }
  }

  if (ctrlKey) {
    if (plusKey) {
      e.preventDefault()
      previewScaleSetting.value = options[Math.min(index + 1, options.length - 1)].value
    }

    if (minusKey) {
      e.preventDefault()
      previewScaleSetting.value = options[Math.max(index - 1, 0)].value
    }

    if (zeroKey) {
      e.preventDefault()
      previewScaleSetting.value = 'auto'
    }
  }
}

onMounted(() => {
  setTimeout(() => {
    const lastCrop = crops.value[crops.value.length - 1]
    if (lastCrop && editorFocusStore.focus?.type !== 'crop') {
      editorFocusStore.setFocus('crop', lastCrop.id)
    }
  }, 0)

  window.addEventListener('keydown', onKeyDown)
})

onUnmounted(() => {
  window.removeEventListener('keydown', onKeyDown)
})

const { currentStep } = useEditorStep()

const videoStore = useVideoStore()
const canvas = ref<HTMLCanvasElement | null>(null)

const container = ref<HTMLElement | null>(null)
const scrollContainer = ref<HTMLElement | null>(null)
const { forceUpdate, left, top } = createWorkspaceBoundingContext(container, scrollContainer)
const {
  cropperWidth, cropperHeight,
  previewWidth, previewHeight,
  previewScaleSettingString, previewScaleSetting,
  previewScaleLabel, previewScaleOptions, autoPreviewScale,
} = useWorkspaceSizes(container)

watch([previewScaleSetting, currentStep], () => {
  forceUpdate()
})

const minCropDensity = computed(() => {
  if (videoStore.videoSize) {
    const feedHeight = previewHeight.value
    const cropHeight = videoStore.videoSize.height
    return Math.min(...cropsStore.entities.map(crop => (crop.height * cropHeight) / (crop.feedData.height * feedHeight)))
  } else {
    return null
  }
})

const canvasSize = computed(() => {

  if (minCropDensity.value && videoStore.videoSize) {

    const { width, height } = videoStore.videoSize
    const maxUtilizedHeight = height / minCropDensity.value
    const maxUtilizedWidth = width / minCropDensity.value

    return {
      width: clamp(width, cropperWidth.value * window.devicePixelRatio, maxUtilizedWidth * window.devicePixelRatio),
      height: clamp(height, cropperHeight.value * window.devicePixelRatio, maxUtilizedHeight * window.devicePixelRatio)
    }
  }

  return null
})

watch(canvasSize, (size) => {
  if (size) {
    videoStore.resizeCanvas(size.width, size.height)
  }
})

useRafFn(() => {

  if (canvas.value && cropArea.value && videoStore.canvas) {

    if (!cropArea.value.width || !cropArea.value.height) return

    canvas.value.width = cropArea.value.width * Math.min(2, window.devicePixelRatio)
    canvas.value.height = cropArea.value.height * Math.min(2, window.devicePixelRatio)
    canvas.value.style.width = cropArea.value.width + 'px'
    canvas.value.style.height = cropArea.value.height + 'px'

    const ctx = canvas.value.getContext('2d')
    ctx?.drawImage(videoStore.canvas, 0, 0, canvas.value.width, canvas.value.height)
  }
})

provide('canvas', canvas)

const cropper = ref<HTMLElement | null>(null)

function computeCropArea({
  width,
  height,
}: Size) {
  if (!canvas.value) return 0
  const cropSize = {
    width: width * canvas.value.width,
    height: height * canvas.value.height,
  }
  return cropSize.width * cropSize.height
}

const sortedCropIds = computed(() => {
  return [...crops.value]
    .sort((a, b) => computeCropArea(b) - computeCropArea(a))
    .map(crop => crop.id)
})

provide('root', container)
provide('mouseover', ref<string | null>(null))

const step = computed(() => {
  if (clipInfoStore.loadingState) {
    return 'loading'
  } else if (!clipInfoStore.id) {
    return 'upload'
  } else {
    return 'edit'
  }
})

const safeZone = useLocalStorage<'youtube' | 'tiktok' | 'instagram' | 'none'>('safe-zone-preview', 'tiktok')
const safeZones = computed(() => [
  {
    key: 'none',
    show: safeZone.value === 'none',
  },
  {
    key: 'youtube',
    show: safeZone.value === 'youtube',
  },
  {
    key: 'tiktok',
    show: safeZone.value === 'tiktok',
  },
  {
    key: 'instagram',
    show: safeZone.value === 'instagram',
  },
])

const cropperVisible = computed(() => cropperWidth.value !== 0)

function addCrop() {

  if (!videoStore.videoSize) throw new Error('Video size is not set')

  const crop = newCrop(layoutId.value, videoStore.videoSize, crops.value.length)
  cropsStore.createById(crop.id, crop)

  const editorFocusStore = useEditorFocusStore()
  editorFocusStore.setFocus('crop', crop.id)
}

const enableSnapping = useLocalStorage('snapping', true)

const cropArea = computed(() => {
  if (!videoStore.videoSize) {
    return null
  } else {
    const scale = Math.min(cropperWidth.value / videoStore.videoSize.width, cropperHeight.value / videoStore.videoSize.height)
    return {
      width: videoStore.videoSize.width * scale,
      height: videoStore.videoSize.height * scale,
    }
  }
})

const cropAreaStyle = computed(() => {
  if (!cropArea.value) {
    return null
  } else {
    return {
      width: cropArea.value.width + 'px',
      height: cropArea.value.height + 'px',
    }
  }
})

const userInfoStore = useUserInfoStore()
</script>

<template>
  <div id="workspace" :key="step" ref="container" class="w-full h-full select-none overflow-hidden" @click="editorFocusStore.unFocus()">
    <div
      v-if="step === 'loading'"
      class="flex items-center justify-center p-8 w-full h-full"
    >
      <WorkspaceSpinner />
    </div>
    <div
      v-else-if="step === 'upload'"
      class="flex items-center justify-center p-8 w-full h-full"
    >
      <UploadWorkspace />
    </div>
    <div v-else-if="step === 'edit'" class="flex flex-col w-full h-full">
      <header v-if="!cropperVisible" class="w-full flex items-center justify-start p-2 pb-0">
        <slot name="collapse" />

        <div class="flex gap-2 ml-auto">
          <Select v-model="safeZone">
            <SelectTrigger>
                <span v-if="safeZone === 'none'" class="flex items-center gap-2">
                  No safe zones
                </span>
              <span v-if="safeZone === 'youtube'" class="flex items-center gap-2">
                  <YoutubeIcon class="text-[#F00] w-3 h-3" /> YouTube preview
                </span>
              <span v-if="safeZone === 'tiktok'" class="flex items-center gap-2">
                  <TikTokIcon class="w-3 h-3" /> TikTok preview
                </span>
              <span v-if="safeZone === 'instagram'" class="flex items-center gap-2">
                  <InstagramIcon class="w-3 h-3" /> Instagram preview
                </span>
            </SelectTrigger>
            <SelectContent>
              <SelectItem class="flex items-center gap-2" value="none">
                No safe zones
              </SelectItem>
              <SelectItem class="flex items-center gap-2" value="youtube">
                <YoutubeIcon class="text-[#F00] w-3 h-3" />
                YouTube preview
              </SelectItem>
              <SelectItem class="flex items-center gap-2" value="tiktok">
                <TikTokIcon class="w-3 h-3" />
                TikTok preview
              </SelectItem>
              <SelectItem class="flex items-center gap-2" value="instagram">
                <InstagramIcon class="w-3 h-3" />
                Instagram preview
              </SelectItem>
            </SelectContent>
          </Select>
          <Select v-model="previewScaleSettingString">
            <SelectTrigger class="w-48">
              {{ previewScaleLabel }}
            </SelectTrigger>
            <SelectContent>
              <SelectItem v-for="scale in previewScaleOptions" :key="scale.value" :value="scale.value.toString()">
                {{ scale.label }}
              </SelectItem>
            </SelectContent>
          </Select>
        </div>
      </header>
      <div ref="scrollContainer"
        class="w-full h-full grid place-items-center my-2"
        :class="previewScaleSetting !== 'auto' && !cropperVisible ? 'overflow-auto' : 'overflow-hidden'"
      >
        <div class="preview-grid items-center rounded-lg">
          <template v-if="cropperVisible">
            <h2 class="preview-grid__cropper-title">{{ layout.name }}</h2>
            <h2 class="preview-grid__preview-title">Preview</h2>
          </template>

          <div
            ref="cropper"
            :class="{ 'hidden': !cropperVisible }"
            :style="{ width: cropperWidth + 'px', height: cropperHeight + 'px' }"
            class="relative preview-grid__cropper box-content grid place-items-center p-2 bg-black rounded-xl mr-4"
          >
            <div :style="cropAreaStyle" class="grid place-items-center bg-black pointer-events-none">
              <canvas ref="canvas" class="rounded-lg overflow-hidden" />
            </div>

            <MovableRoot
              :blackout="sortedCropIds.length > 0"
              :style="cropAreaStyle"
              class="flex-0 absolute inset-auto"
              mask-class="rounded-lg overflow-hidden"
              container-selector="#workspace"
              :container-x="left" :container-y="top"
            >
              <template v-if="cropperVisible">
                <CropElement
                  v-for="cropIds in sortedCropIds"
                  :id="cropIds"
                  :key="cropIds"
                  :enable-snapping="enableSnapping"
                  @click.stop
                />
              </template>
            </MovableRoot>
          </div>

          <div
            id="preview-container"
            :style="{ width: previewWidth + 'px', height: previewHeight + 'px' }"
            class="relative box-content p-2 w-full rounded-2xl border-zinc-900 bg-black shadow-lg preview-grid__preview z-[10]"
          >
            <div class="h-0 pb-[177.78%] relative">
              <CanvasPreview :enable-snapping="enableSnapping" :safe-zones="safeZones" />
            </div>
          </div>

          <div v-if="cropperVisible" class="preview-grid__cropper-options flex items-center gap-2 mt-2 relative z-[10]">
            <Button v-if="userInfoStore.tier > 0 && layout.presetId !== 'split' && layout.presetId !== 'basecam'" @click="addCrop">
              + Add crop
            </Button>
            <label class="flex items-center gap-1">
              <Switch v-model:checked="enableSnapping" />
              Enable snapping
            </label>
          </div>
          <div v-if="currentStep === 'editor-layouts'" class="preview-grid__preview-options mt-2 relative z-[10]">
            <Select v-model="safeZone">
              <SelectTrigger>
                <span v-if="safeZone === 'none'" class="flex items-center gap-2">
                  No safe zones
                </span>
                  <span v-if="safeZone === 'youtube'" class="flex items-center gap-2">
                  <YoutubeIcon class="text-[#F00] w-3 h-3" /> YouTube preview
                </span>
                  <span v-if="safeZone === 'tiktok'" class="flex items-center gap-2">
                  <TikTokIcon class="w-3 h-3" /> TikTok preview
                </span>
                  <span v-if="safeZone === 'instagram'" class="flex items-center gap-2">
                  <InstagramIcon class="w-3 h-3" /> Instagram preview
                </span>
              </SelectTrigger>
              <SelectContent>
                <SelectItem class="flex items-center gap-2" value="none">
                  No safe zones
                </SelectItem>
                <SelectItem class="flex items-center gap-2" value="youtube">
                  <YoutubeIcon class="text-[#F00] w-3 h-3" />
                  YouTube preview
                </SelectItem>
                <SelectItem class="flex items-center gap-2" value="tiktok">
                  <TikTokIcon class="w-3 h-3" />
                  TikTok preview
                </SelectItem>
                <SelectItem class="flex items-center gap-2" value="instagram">
                  <InstagramIcon class="w-3 h-3" />
                  Instagram preview
                </SelectItem>
              </SelectContent>
            </Select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.preview-grid {
  display: grid;
  justify-content: center;

  // CSS calculation that accounts for borders while maintaining aspect-ratio,
  // read as: [border-left] [crop] [border-right] [gap] [border-left] [preview] [border-right].
  grid-template-columns: auto auto;
  grid-template-areas:
    'crop-title preview-title'
    'crop preview'
    'crop-options preview-options';
}

.preview-grid__cropper-title {
  grid-area: crop-title;
}

.preview-grid__preview-title {
  grid-area: preview-title;
}

.preview-grid__cropper {
  grid-area: crop;
}

.preview-grid__preview {
  grid-area: preview;
}

.preview-grid__cropper-options {
  grid-area: crop-options;
}

.preview-grid__preview-options {
  grid-area: preview-options;
}
</style>
