<script setup lang="ts">
import { useDark, useScriptTag } from '@vueuse/core'
import { computed } from 'vue'

useScriptTag(
  'https://static.senja.io/dist/platform.js',
  // on script tag loaded.
  (el: HTMLScriptElement) => {
    console.log('Senja script loaded')
  }
)

const isDarkMode = useDark()

const dataId = computed(() =>
  isDarkMode.value ? '3b998a20-3cd4-496d-9c90-380993b872c7' : 'a17362fa-10ce-44c9-a7a0-61dab63f2982'
)
</script>

<template>
  <div
    class="senja-embed w-full"
    :data-id="dataId"
    data-mode="shadow"
    data-lazyload="false"
  ></div>
</template>

<style scoped lang="scss"></style>
