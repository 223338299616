<script setup lang="ts">
import MultiUploadDialog from '@/components/Dialog/MultiUploadDialog/MultiUploadDialog.vue'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { updateUploadedVideo } from '@/components/Dialog/MultiUploadDialog/file-uploads/useUploadedVideos'
import { useUserInfoStore } from '@/store/user/userInfo'
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { useResetEditor } from '@/Hooks/useResetEditor'
import { useRouter } from 'vue-router'
import { useStartup } from '@/areas/editor/startup'

const editorClipInfoStore = useEditorClipInfoStore()
const userInfoStore = useUserInfoStore()

const router = useRouter()
const useNewEditor = useFeatureFlagEnabled('new-editor-v2')
const { startupFromLocalFileByClipId } = useStartup()
async function onSelect(clip: { id: string, title: string, videoUrl: string }) {

  const resetEditor = useResetEditor()
  resetEditor()

  if (useNewEditor.value) {
    await startupFromLocalFileByClipId(clip.id)
  } else {
    editorClipInfoStore.$patch({
      id: clip.id,
      title: clip.title,
      mp4Url: clip.videoUrl,
      isLocalFile: true,
      source: 'local-file',
    })
    
    if (userInfoStore.isLoggedIn) {
      await updateUploadedVideo(clip.id, { name: clip.title })
    }
    await router.push({ name: 'editor', params: { clipId: clip.id, source: 'local-file' }})
  }
}
</script>

<template>
  <MultiUploadDialog call-to-action="Start editing" @select="onSelect" upload-in-background :max="10">
    <slot />
  </MultiUploadDialog>
</template>

<style scoped lang="scss">

</style>
