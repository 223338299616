<template>
  <div @click="showOptions" class="absolute right-1 top-1 flex">
    <div
      class="absolute right-0 right-1 top-1 flex rounded bg-company-primary bg-opacity-75 p-2"
      ref="button"
      style="z-index: 99999"
    >
      <img src="/images/svgs/three-dots.svg" loading="lazy" alt="three dots" />
    </div>

    <div
      @click="showOptions"
      v-click-outside="this.hideOptions"
      :class="{ 'lg:hidden': !isActive }"
      class="absolute flex cursor-pointer flex-col rounded-lg bg-white text-left shadow"
      ref="dropdown"
      style="z-index: 999999"
    >
      <slot></slot>
    </div>
  </div>
</template>
<script>
import { createPopper } from '@popperjs/core'

export default {
  data() {
    return {
      isActive: false,
      pop: null,
    }
  },
  methods: {
    showOptions(e) {
      e.stopPropagation()
      this.isActive = true
      this.pop.update()
    },
    hideOptions() {
      if (this.isActive) {
        this.isActive = false
        this.pop.update()
      }
    },
    createPopperInstance() {
      this.pop = createPopper(this.$refs.button, this.$refs.dropdown, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [2, 2],
            },
          },
        ],
      })
    },
  },
  mounted() {
    this.createPopperInstance()
  },
}
</script>
