<template>
  <div class="success-icon">
    <CheckMarkRoundFill class="w-[1em] h-[1em] inline fill-green-600" />
  </div>
</template>

<script>
import CheckMarkRoundFill from "@/components/Icons/Normalized/CheckMarkRoundFill.vue";

export default {
  components: { CheckMarkRoundFill }
}
</script>

<style lang="scss">
.success-icon {
  font-size: 3em;
}
</style>
