import { addMinutes, format, startOfMonth, subMonths, endOfMonth, addMonths, startOfDay, isSameDay } from 'date-fns'
import type { TargetListingDto, SocialMedia, PostDto } from '@/apis/streamladder-publisher/model'
import type { EventInput } from '@fullcalendar/core'

export const getValidRange = () => {
  return {
    start: startOfMonth(subMonths(new Date(), 2)),
    end: endOfMonth(addMonths(new Date(), 2)),
  }
}

export const toPostCalendarEvent = (post: Required<PostDto>): ContentPublisherEvent => {
  if (!post || !post.id || !post.scheduledAt) {
    throw new Error('Invalid Post object passed to toPostCalendarEvent')
  }

  const calendarDate = new Date(post.scheduledAt)
  const firstTarget = post.targets?.[0]
  const title = firstTarget?.title ?? 'T.B.D.'
  const thumbnail = firstTarget?.thumbnailUrl ?? '/images/images/thumbnail.jpg'
  const platforms = ((post.targets ?? []) as TargetListingDto[])
    .map((postTarget) => postTarget.socialMedia)
    .filter((socialMedia): socialMedia is SocialMedia => Boolean(socialMedia))

  const dateString = format(calendarDate, 'yyyy-MM-dd HH:mm:ssxx')

  return {
    id: post.id,
    title: title,
    startEditable: calendarDate.getTime() > addMinutes(new Date(), 15).getTime(),
    start: dateString,
    date: dateString,
    end: dateString,
    extendedProps: {
      thumbnail: thumbnail,
      platforms: platforms,
      apiData: post,
    },
  }
}

export function addGridRowProperty(events: ContentPublisherEvent[]) {
  const eventsWithRow: ContentPublisherEventWithRow[] = []

  for (const event of events) {
    const scheduledAt = event.extendedProps.apiData?.scheduledAt
    if (!scheduledAt) {
      throw new Error('Invalid Event object passed to addGridRowProperty')
    }

    const date = new Date(scheduledAt)

    // Row is used for grid-row-start in the CSS, which is 1-indexed.
    let row = Math.max(Math.round((date.getTime() - startOfDay(date).getTime()) / 1000 / 60 / 60), 1);
    while (anyEventInThisSlot({ row, column: date }, eventsWithRow)) {
      row += 1
    }

    eventsWithRow.push({ ...event, extendedProps: { ...event.extendedProps, row } })
  }

  return eventsWithRow
}

function anyEventInThisSlot(slot: Slot, events: ContentPublisherEventWithRow[]) {
  for (const event of events) {
    if (occupySameSlot(slot, { row: event.extendedProps.row, column: new Date(event.date) })) {
      return true
    }
  }

  return false
}

function occupySameSlot(a: Slot, b: Slot) {
  return a.row === b.row && isSameDay(a.column, b.column)
}

type Slot = {
  row: number
  column: Date
}

export function orderByDate(posts: Required<PostDto>[]) {
  return [...posts].sort((a, b) => {
    if (!a.scheduledAt) return 1
    if (!b.scheduledAt) return -1

    return new Date(a.scheduledAt).getTime() - new Date(b.scheduledAt).getTime()
  })
}

export interface ContentPublisherEventWithRow extends ContentPublisherEvent {
  extendedProps: ContentPublisherEvent['extendedProps'] & {
    row: number
  }
}

export interface ContentPublisherEvent extends EventInput {
  id: string
  title: string
  startEditable: boolean
  date: string
  start: string
  end: string
  extendedProps: {
    platforms: string[]
    thumbnail: string
    apiData: PostDto
  }
}
