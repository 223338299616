<script lang="ts" setup>
import IconSaxCloseSquare from '@/components/Icons/iconsax/IconSaxCloseSquare.vue'
import ClipGoatLogo from '@/components/Icons/ClipGoatLogo.vue'
import { useConditionalEventListener } from '@/Hooks/useConditionalEventListener'
import { computed } from 'vue'
import { Button } from '@/components/ui/button'

const props = defineProps<{
  modelValue: string | null
}>()

const emit = defineEmits<{
  (name: 'update:modelValue', value: string | null): void
}>()

function close() {
  emit('update:modelValue', null)
}

useConditionalEventListener(computed(() => props.modelValue !== null), 'keydown', (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    close()
  }
})
</script>

<template>
  <Teleport to="body">
    <div :class="{ 'modal-open': modelValue }" class="modal modal-bottom md:modal-middle" @click="close">
      <div
        class="modal-box relative flex flex-col rounded-2xl !bg-black p-0 text-zinc-100 md:!max-w-screen-md"
        @click.stop
      >
        <div v-if="modelValue" class="aspect-video bg-gradient-to-br from-amber-300 to-emerald-300">
          <video
            autoplay
            class="aspect-video w-full"
            crossorigin="anonymous"
            disablePictureInPicture=""
            disableRemotePlayback=""
            height="1080"
            loop
            muted
            playsinline
            src="/videos/clipgoat.mp4"
            width="1920"
          />
        </div>

        <div class="flex flex-col items-start gap-6 px-12 pb-12">
          <div class="flex w-full items-center justify-between gap-2">
            <ClipGoatLogo class="h-24 w-80" />
            <div class="rounded-xl border-2 border-current bg-teal-50/5 px-3 py-2 text-sm font-bold text-teal-400">
              BETA
            </div>
          </div>
          <p class="break-words leading-snug sm:text-lg text-white/75">Looks like you're attempting to link a YouTube video</p>
          <p class="w-full max-w-full whitespace-pre-line break-all font-light leading-6 sm:break-normal text-white/75">
            YouTube video links can't be used directly in StreamLadder. However, if you have a YouTube clip link, that's
            perfect for us! Alternatively, use our dedicated tool to create clips from your full YouTube video. Ready to
            create?
            <a
              class="link inline-flex hover:no-underline"
              href="https://clipgoat.com/"
              target="_blank"
              title="ClipGOAT"
            >
              Learn more
            </a>
            about our clip generator.
          </p>
          <div class="flex w-full flex-col items-center justify-between gap-2 md:flex-row">
            <Button
              as="a"
              variant="clipgoat"
              :href="`https://app.clipgoat.com/youtube/${modelValue}`"
              class="flex-auto"
              target="_blank"
              title="ClipGOAT"
            >
              Convert with ClipGOAT
            </Button>
            <span class="mt-2 text-zinc-100/50 md:hidden">or</span>
            <button
              class="flex-auto rounded-full px-6 py-3 text-center font-bold text-white transition-all hover:bg-primary active:scale-90"
              @click="close"
            >
              Keep using StreamLadder
            </button>
          </div>
        </div>

        <button
          class="absolute right-4 top-4 h-10 w-10 rounded-xl bg-zinc-500/25 text-white transition-all hover:bg-zinc-500/50 active:scale-90"
          @click="close"
        >
          <IconSaxCloseSquare />
        </button>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped></style>
