<script lang="ts" setup>

import { ref, computed } from 'vue'
import { useElementSize } from '@vueuse/core'
import VideoPlayer2 from '@/components/VideoPlayer2.vue'

defineProps({
  updateVideoData: {
    type: Boolean,
    default: true,
  },
  allowUnmute: {
    type: Boolean,
    default: true,
  },
  autoPlay: {
    type: Boolean,
    default: false,
  },
  hidden: {
    type: Boolean,
    default: false,
  },
  controls: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits<{
  (event: 'loaded', videoElement: HTMLVideoElement): void
}>()

const container = ref<HTMLElement | null>(null)

const { width: containerWidth, height: containerHeight } = useElementSize(container)
const width = ref<number>()
const height = ref<number>()

function videoLoaded(videoElement: HTMLVideoElement) {
  width.value = videoElement.videoWidth
  height.value = videoElement.videoHeight

  emit('loaded', videoElement)
}

const containSize = computed(() => {
  if (!containerWidth.value || !containerHeight.value || !width.value || !height.value) return null
  const scale = Math.min(containerWidth.value / width.value, containerHeight.value / height.value)
  return {
    width: width.value * scale,
    height: height.value * scale,
  }
})
</script>

<template>
  <div ref="container" class="relative grid h-full w-full place-items-center overflow-hidden">
    <VideoPlayer2
      @loaded="videoLoaded"
      :style="containSize && { width: containSize.width + 'px', height: containSize.height + 'px' }"
      :allow-unmute="allowUnmute"
      :auto-play="autoPlay"
      :hidden="hidden"
      :controls="controls"
    />
    <div
      v-if="$slots?.default"
      :style="containSize && { width: containSize.width + 'px', height: containSize.height + 'px' }"
      class="absolute inset-auto"
    >
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>

</style>
