<script setup lang="ts">
import { computed, h, ref, watch } from 'vue'
import DynamicPlanButtonWithTooltip from '@/components/Account/Upgrade/DynamicPlanButtonWithTooltip.vue'
import LightSwitch from '@/components-v2/data-input/boolean/LightSwitch.vue'
import { canGuard, canGuardWithPopup } from '@/Hooks/useGuard'
import ProgressDialog from '@/components/Dialog/ProgressDialog.vue'
import { useEditorMainStore } from '@/store/editor/editorMain'
import { watchImmediate } from '@vueuse/core'
import settings from '@/data/settings'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { useUserInfoStore } from '@/store/user/userInfo'
import { useFileUploads } from '@/components/Dialog/MultiUploadDialog/file-uploads/useFileUploads'
import { useRoute } from 'vue-router'

const editorMainStore = useEditorMainStore()
const { tier } = useUserInfoStore();

const props = defineProps<{ startRender: boolean; saveTemplate: () => void }>()
const selectedResolution = ref(localStorage.getItem(settings.outputResolution) ?? '720')
const isHighQuality = computed(() => selectedResolution.value === '1080')

const outputResolutions = computed(() => [
  {
    key: '720p',
    value: '720',
    label: '720p / 60fps',
  },
  {
    key: '1080p',
    value: '1080',
    disabled: !canGuard('resolution-1080'),
    label: () =>
      h('div', { class: 'flex items-center gap-2' }, [
        h('span', ['1080p / 60fps']),
        h(DynamicPlanButtonWithTooltip, { feature: 'resolution-1080' }),
      ]),
  },
])

watchImmediate(selectedResolution, (resolution) => {
  if (resolution == '1080') {
    editorMainStore.setVideoOutputDimensions({
      width: 1080,
      height: 1920,
    })
  } else {
    editorMainStore.setVideoOutputDimensions({
      width: 720,
      height: 1280,
    })
  }
  localStorage.setItem(settings.outputResolution, resolution)
})

const fileUploads = useFileUploads()
const route = useRoute()
const activeUpload = fileUploads.selectById(route.params.clipId)
const progressShouldBeVisible = computed(() => {
  return activeUpload.value && activeUpload.value.status === 'in-progress' && props.startRender
})

const fileUploadDialog = ref<ProgressDialog | null>(null)

watch(progressShouldBeVisible, (visible) => {
  if (visible) {
    fileUploadDialog.value?.open()
  } else {
    fileUploadDialog.value?.close()
  }
})

const hasThreeColumnLayout = useFeatureFlagVariantEnabled('three-column-layout', 'wide')
</script>

<template>
  <section
    id="export-step"
    class="flex flex-col items-start justify-start gap-4 rounded-lg bg-white p-4"
    :class="hasThreeColumnLayout ? 'layer-2 min-h-0 overflow-y-auto' : ''"
  >
    <div v-if="tier !== 0" class="rounded-lg border-2 border-gray-100 p-4">
      <div class="mb-2 flex flex-col items-start justify-start">
        <h3 class="p-0 text-lg font-semibold text-indigo-950">Select video quality</h3>
        <p class="text-sm font-semibold text-brand-state-text-secondary">
          {{ isHighQuality ? 'Best' : 'Good' }} quality for Social Media.
        </p>
      </div>
      <span class="cursor-pointer" @click="() => canGuardWithPopup('resolution-1080')">
        <LightSwitch
          :background="'bg-emerald-600'"
          class="whitespace-nowrap text-sm outline-1 outline-offset-2 outline-[#059669] focus-within:outline"
          :options="outputResolutions"
          v-model:value="selectedResolution"
          labelClasses="text-white"
        />
      </span>
      <div class="rounded-xl border-2 border-white">
        <div class="relative mt-4 max-w-full overflow-hidden rounded-xl">
          <img
            class="max-w-full overflow-hidden rounded-xl"
            src="/images/quality-1080.jpg"
            alt="video quality comparison"
          />

          <div
            class="absolute -bottom-px -left-px origin-bottom-left overflow-hidden rounded-xl border-2 border-white shadow-[0_0_999px_999px_rgba(0,0,0,0.75)] transition-all duration-500"
            :class="isHighQuality ? 'scale-100' : 'scale-[0.6]'"
          >
            <img
              :style="{ imageRendering: isHighQuality ? 'auto' : 'pixelated' }"
              class="max-w-full transition-all"
              width="1920"
              height="1080"
              :src="`/images/quality-${isHighQuality ? '1080' : '720'}.jpg`"
              alt="video quality comparison"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-4 rounded-lg border-2 border-gray-100 p-6">
      <div class="inline-flex flex-col items-start justify-start gap-3">
        <div class="flex flex-col items-start justify-start">
          <h3 class="p-0 text-lg font-semibold text-indigo-950">Save Template</h3>
          <p class="text-sm font-semibold text-brand-state-text-secondary">
            Save your current settings, crops and stickers as a template to save time on your next video.
          </p>
        </div>
        <ol class="flex flex-col gap-2 text-[14px] font-thin text-[#78767B]">
          <li
            v-for="feature in [
              'Save your current clip settings as a reusable template.',
              'Instantly apply the template to future clip projects with a single click.',
              'Preserve edits, customizations, and effects for consistent and efficient results.',
            ]"
            v-bind:key="feature"
            class="flex items-center gap-2"
          >
            <svg
              class="min-w-6 xl:min-w-4 2xl:min-w-6 -mt-0.5 w-6 flex-shrink-0 flex-grow-0 text-[#6080FE] xl:w-4 2xl:w-6"
              height="24"
              viewBox="0 0 24 24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clip-rule="evenodd"
                d="M20.9557 5.90309L9.05079 19.5087L3.04214 13.5001L4.50055 12.0417L8.95032 16.4915L19.4035 4.54492L20.9557 5.90309Z"
                fill="currentColor"
                fill-rule="evenodd"
              />
            </svg>

            {{ feature }}
          </li>
        </ol>
        <button class="btn-primary btn gap-2" @click="saveTemplate">
          Save template
          <DynamicPlanButtonWithTooltip size="16" feature="save-template" class="fill-white" />
        </button>
      </div>
    </div>
    <ProgressDialog
      ref="fileUploadDialog"
      title="Hold tight! ✨"
      :error-message="'An error occurred while uploading your video'"
      :loading-message="'We\'re zipping your video over to our ultra-fast rendering buddy.'"
      :success-message="'Your video is ready to be rendered. 🚀'"
    />
  </section>
</template>

<style scoped lang="scss"></style>
