<template>
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.61781 13.3501C1.76988 13.3501 1.91572 13.4105 2.02325 13.518C2.13078 13.6256 2.19119 13.7714 2.19119 13.9235V16.7904C2.19119 17.0945 2.312 17.3862 2.52706 17.6012C2.74212 17.8163 3.0338 17.9371 3.33794 17.9371H17.099C17.4031 17.9371 17.6948 17.8163 17.9098 17.6012C18.1249 17.3862 18.2457 17.0945 18.2457 16.7904V13.9235C18.2457 13.7714 18.3061 13.6256 18.4137 13.518C18.5212 13.4105 18.667 13.3501 18.8191 13.3501C18.9712 13.3501 19.117 13.4105 19.2245 13.518C19.3321 13.6256 19.3925 13.7714 19.3925 13.9235V16.7904C19.3925 17.3986 19.1508 17.982 18.7207 18.4121C18.2906 18.8422 17.7072 19.0839 17.099 19.0839H3.33794C2.72966 19.0839 2.1463 18.8422 1.71619 18.4121C1.28607 17.982 1.04443 17.3986 1.04443 16.7904V13.9235C1.04443 13.7714 1.10484 13.6256 1.21237 13.518C1.3199 13.4105 1.46574 13.3501 1.61781 13.3501Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="1.42919"
    />
    <path
      d="M10.625 13.1818C10.5717 13.2352 10.5085 13.2775 10.4388 13.3064C10.3691 13.3353 10.2945 13.3502 10.219 13.3502C10.1436 13.3502 10.0689 13.3353 9.99929 13.3064C9.92963 13.2775 9.86635 13.2352 9.81309 13.1818L6.37283 9.74151C6.26517 9.63385 6.20468 9.48782 6.20468 9.33556C6.20468 9.1833 6.26517 9.03728 6.37283 8.92961C6.4805 8.82195 6.62652 8.76146 6.77878 8.76146C6.93105 8.76146 7.07707 8.82195 7.18473 8.92961L9.64566 11.3917L9.64567 1.3083C9.64567 1.15623 9.70607 1.01039 9.8136 0.902858C9.92113 0.79533 10.067 0.73492 10.219 0.73492C10.3711 0.73492 10.517 0.79533 10.6245 0.902858C10.732 1.01039 10.7924 1.15623 10.7924 1.3083L10.7924 11.3917L13.2533 8.92961C13.3067 8.8763 13.3699 8.83401 13.4396 8.80516C13.5093 8.77631 13.5839 8.76146 13.6593 8.76146C13.7347 8.76146 13.8093 8.77631 13.879 8.80516C13.9487 8.83401 14.0119 8.8763 14.0652 8.92961C14.1186 8.98292 14.1608 9.04621 14.1897 9.11586C14.2185 9.18552 14.2334 9.26017 14.2334 9.33556C14.2334 9.41095 14.2185 9.48561 14.1897 9.55526C14.1608 9.62491 14.1186 9.6882 14.0652 9.74151L10.625 13.1818Z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="1.42919"
    />
  </svg>
</template>
