<script lang="ts" setup>
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import NoContentSection from '@/components-v2/data-display/NoContentSection.vue'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import { range } from 'lodash-es'
import { computed, ref } from 'vue'
import ScheduledPostItem from '@/areas/dashboard/components/ScheduledPosts/ScheduledPostItem.vue'
import { useUpcomingPosts } from '@/areas/dashboard/hooks/useUpcomingPosts'
import { useGuard } from '@/Hooks/useGuard'
import { type PostDto, OverallPostStatus } from '@/apis/streamladder-publisher/model'
import { addDays } from 'date-fns'
import { v4 as uuid } from 'uuid'
import ContentPublisherPostDialog from '@/components-v2/content-publisher/ContentPublisherPostDialog.vue'

const { isLoadingPosts, maxAmountOfPosts, upcomingPosts } = useUpcomingPosts()

const managePostId = ref<string | null>(null)

const placeholderPosts: Partial<PostDto>[] = [
  {
    targets: [{ title: 'My first post', socialMedia: 'Youtube' }, { socialMedia: 'Tiktok' }],
    scheduledAt: addDays(new Date(), 3).toISOString(),
    id: uuid(),
    status: { overallStatus: OverallPostStatus.Scheduled },
  },
  {
    targets: [{ title: 'My second post', socialMedia: 'Youtube' }, { socialMedia: 'Tiktok' }],
    scheduledAt: addDays(new Date(), 4).toISOString(),
    id: uuid(),
    status: { overallStatus: OverallPostStatus.Scheduled },
  },
  {
    targets: [{ title: 'My third post', socialMedia: 'Youtube' }, { socialMedia: 'Tiktok' }],
    scheduledAt: addDays(new Date(), 5).toISOString(),
    id: uuid(),
    status: { overallStatus: OverallPostStatus.Scheduled },
  },
  {
    targets: [{ title: 'My fourth post', socialMedia: 'Youtube' }, { socialMedia: 'Tiktok' }],
    scheduledAt: addDays(new Date(), 6).toISOString(),
    id: uuid(),
    status: { overallStatus: OverallPostStatus.Scheduled },
  },
]

const canSchedule = computed(() => useGuard('scheduler').value)
</script>

<template>
  <template v-if="!canSchedule">
    <ol class="flex flex-col gap-2">
      <li v-for="i in range(4)" :key="'post-' + i">
        <ScheduledPostItem :post="placeholderPosts[i]" />
      </li>
    </ol>
  </template>

  <TransitionGroup
    v-else-if="isLoadingPosts || upcomingPosts.length > 0"
    class="flex flex-col gap-2"
    name="grid"
    tag="ol"
  >
    <li v-for="i in range(isLoadingPosts ? maxAmountOfPosts : upcomingPosts.length)" :key="'post-' + i">
      <ScheduledPostItem :post="upcomingPosts[i]" @click="(id) => (managePostId = id)" />
    </li>
  </TransitionGroup>

  <NoContentSection
    v-else
    subtitle="Ready to boost your social presence? Schedule your first clip now! Users find posting at least once daily effective, but feel free to experiment – even up to 5 times a day."
    title="No upcoming posts this week"
  >
    <RouterLink :to="{ name: dashboardRouteNames.contentPublisher.root }" class="shadow-lg">
      <GradientButton>
        New post
        <IconSaxAdd />
      </GradientButton>
    </RouterLink>
  </NoContentSection>

  <ContentPublisherPostDialog v-model="managePostId" />
</template>

<style lang="scss" scoped></style>
