import { createInjectionState, useEventListener } from '@vueuse/core'
import type { Ref } from 'vue'
import { useBoundingClientRect } from '@/Hooks/useBoundingClientRect'
import { ref } from 'vue'

export const [createWorkspaceBoundingContext, useWorkspaceBoundingContext] = createInjectionState((
  workspace: Ref<HTMLElement | null>, 
  scrollContainer?: Ref<HTMLElement | null>,
) => {

  const {
    x, y,
    top, left, right, bottom,
    width, height, forceUpdate
  } = useBoundingClientRect(workspace)

  const scrollX = ref(0)
  const scrollY = ref(0)
  if (scrollContainer) {
    useEventListener(scrollContainer, 'scroll', () => {
      scrollX.value = scrollContainer.value?.scrollLeft ?? 0
      scrollY.value = scrollContainer.value?.scrollTop ?? 0
    })
  }
  
  return {
    x, y, scrollX, scrollY,
    top, left, right, bottom,
    width, height, 
    forceUpdate,
    element: workspace,
  }
})
