import { watch, type Ref } from 'vue'
import type { MaybeRefOrGetter } from '@vueuse/shared'
import { toValue } from '@vueuse/core'

export function useConditionalEventListener<E extends Event>(
  condition: Ref<boolean>,
  event: E['type'],
  listener: (e: E) => void,
  options: {
    target?: MaybeRefOrGetter<HTMLElement>
  } = {}
) {
  watch(condition, value => {
    const element = toValue(options.target ?? window)
    if (value) {
      element.addEventListener(event, listener)
    } else {
      element.removeEventListener(event, listener)
    }
  }, { immediate: true })
}
