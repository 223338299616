<template>
  <div class="panel w-full flex-col space-y-3 lg:w-2/5">
    <h4 class="mb-4 text-xl lg:text-3xl">Are you sure you want to cancel?</h4>

    <!-- paypal subscriptions cannot be paused -->
    <router-link
      :to="{ name: 'ReviewDowngrade' }"
      v-if="showDowngradeOption"
      @click="$emit('downgradeSubscription')"
      class="btn-primary btn rounded"
    >
      <p class="mb-0">Downgrade to Silver</p>
    </router-link>

    <router-link
      :to="{ name: 'ReviewCancel', query: { reason: $route.query.reason } }"
      @click="$emit('cancelSubscription')"
      class="btn-primary btn rounded"
      :class="{ 'btn-outline': showDowngradeOption }"
    >
      <p class="mb-0">Cancel your subscription</p>
    </router-link>

    <router-link :to="{ name: 'Home' }" v-if="offerKeepSubscription" class="btn-outline btn-primary btn rounded">
      <p class="mb-0">Keep your subscription</p>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    offerDowngrade: {
      type: Boolean,
      default: false,
    },
    offerKeepSubscription: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    showDowngradeOption() {
      if (!this.offerDowngrade) return false

      // check if user is allowed to downgrade
      return true
    },
  },
}
</script>

<style></style>
