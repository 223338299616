import type { Tier, TierLevel } from '@/data/plans'
import { computed, ref } from 'vue'
import { useSubscriptionIntervals } from '@/Hooks/useSubscriptionIntervals'
import { useUserInfoStore } from '@/store/user/userInfo'
import CanUpgradeAccounts from '@/mixins/canUpgradeAccounts.js'
import * as Sentry from '@sentry/vue'
import { useUserSubscriptionStore } from '@/store/user/userSubscription'
import { accountsAxios } from '@/services/tokens'

const isLoading = ref<TierLevel | null>(null)
const error = ref<'configuration-unknown' | 'checkout-failed' | null>(null)

const { interval } = useSubscriptionIntervals()

const upgrade = async (tier: Tier, reason: string, discountCode?: string | null, intervalOverride?: 'month' | 'year') => {
  if (isLoading.value === null) {

    error.value = null

    const userInfoStore = useUserInfoStore()
    if (userInfoStore.trialStatus !== 'active' && tier.tier <= userInfoStore.tier) {
      return
    }

    const subscriptionStore = useUserSubscriptionStore();
    const subscription = computed(() => subscriptionStore.subscription);
    const status = computed(() => subscription.value?.status?.toLowerCase());

    const intervalOrOverride = intervalOverride
      ? intervalOverride
      : interval.value;

    isLoading.value = tier.key

    const plans = [...tier.plans]
    const plan = plans.find((p) => p.key === intervalOrOverride)

    if (plan === undefined) {
      error.value = 'configuration-unknown'
      return
    }

    if (subscription.value && (status.value === 'paused' || status.value === 'cancelled')) {
      try {
        await accountsAxios.put(`/api/subscription/${subscription.value!.id}/resume`);
      } catch (e) {
        console.error(JSON.stringify(e));
        Sentry.captureException(new Error('Resuming plan failed while upgrading'));
        error.value = 'checkout-failed';
        return;
      }
    }

    const meta = {
      fromTier: userInfoStore.tier,
      toTier: tier.tier,
      interval: intervalOrOverride,
    }

    try {
      await CanUpgradeAccounts.methods.attemptCheckout(plan.paddle, discountCode, reason, meta)
    } catch (e) {
      console.error(JSON.stringify(e));
      Sentry.captureException(new Error('Checkout failed'));
      error.value = 'checkout-failed';
    }

    isLoading.value = null
  }
}

export const usePlanUpgrade = () => {
  return { isLoading, error, upgrade }
}
