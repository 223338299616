<script setup lang="ts">
import { useBlogItems } from '@/queries/useBlogItems'
import { useIsIntersecting } from '@/areas/dashboard/hooks/useIsIntersecting'
import { ref, computed } from 'vue'
import { range } from 'lodash-es'
import BlogPostCard from './BlogPostCard.vue'

const amountOfBlogPosts = 5

const container = ref<HTMLDivElement>()
const { data } = useBlogItems(amountOfBlogPosts, { enabled: useIsIntersecting(container) })
const posts = computed(() => data.value ?? [])
</script>

<template>
  <section ref="container" class="flex flex-col gap-4">
    <header class="flex flex-wrap items-center justify-between gap-2">
      <h2 class="text-2xl lg:text-3xl">Growth tips</h2>
      <a
        href="https://streamladder.com/blog"
        target="_blank"
        class="link font-light text-indigo-500 hover:no-underline"
      >
        Browse all
      </a>
    </header>

    <TransitionGroup
      appear
      name="grid"
      class="grid-single-row -m-2 grid grid-cols-2 gap-x-4 p-2 pb-0 md:grid-cols-[repeat(auto-fill,_minmax(256px,_2fr))]"
      tag="ol"
    >
      <li class="flex" v-for="i in range(amountOfBlogPosts)" :key="`post-${i}`">
        <BlogPostCard :post="posts[i]" />
      </li>
    </TransitionGroup>
  </section>
</template>

<style scoped lang="scss"></style>
