<template>
  <div class="w-full text-start">
    <h4>Select facecam ratio</h4>
    <div class="button-selector" role="group" aria-label="Basic radio toggle button group">
      <input
        @click="changeRatio('16x9')"
        value="16x9"
        type="radio"
        class="hidden"
        name="btnradio"
        id="btnradio1"
        autocomplete="off"
        :checked="selectedCropper === '16x9'"
      />
      <label class="" for="btnradio1">16:9</label>

      <input
        @click="changeRatio('4x3')"
        value="4x3"
        type="radio"
        class="hidden"
        name="btnradio"
        id="btnradio2"
        autocomplete="off"
        :checked="selectedCropper === '4x3'"
      />
      <label class="" for="btnradio2">4:3</label>

      <input
        @click="changeRatio('Free')"
        value="freeform"
        type="radio"
        class="hidden"
        name="btnradio"
        id="btnradio3"
        autocomplete="off"
        :checked="selectedCropper === 'Free'"
      />
      <label class="" for="btnradio3">Freeform</label>
    </div>
  </div>
</template>
<script>
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { feedTypes } from '@/modules/SLVideoplayer/types'
import { merge } from 'lodash-es'
import { useEditorMainStore } from '@/store/editor/editorMain'

export default {
  name: 'SplitViewSplitser',
  props: {},
  data() {
    return {
      selectedCropper: this.$route.params.layout.replace('Split', '') || '16x9',
    }
  },
  methods: {
    changeRatio(ratio) {
      if (ratio === this.selectedCropper) return
      this.selectedCropper = ratio
      this.$emit('cropperRatioChanged', ratio)
    },
  },
}
</script>
