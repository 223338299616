import type { LayoutsStore } from '@/store/entity-system/useLayoutsStore'
import type { CropsStore } from '@/store/entity-system/useCropsStore'
import { useLayoutsStore } from '@/store/entity-system/useLayoutsStore'
import { useCropsStore } from '@/store/entity-system/useCropsStore'
import { copyRef } from '@/store/entity-system/_copyRef'

export function snapshotLayoutById(layoutId: string, layoutsStore?: LayoutsStore, cropsStore?: CropsStore) {

  layoutsStore = (layoutsStore ?? useLayoutsStore()) as LayoutsStore
  cropsStore = (cropsStore ?? useCropsStore()) as CropsStore

  const layout = layoutsStore.selectById(layoutId)
  const crops = cropsStore.whereLayoutIdIs(layoutId).value.map((crop) => copyRef(crop))

  return {
    layout: copyRef(layout),
    crops: copyRef(crops),
  }
}
