import { useGetApiSubscriptionIdPayments } from '@/apis/streamladder-accounts/subscription/subscription'
import { computed } from 'vue'
import type { MaybeRef } from '@vueuse/shared'

export function usePayments(subscriptionId: MaybeRef<string>) {
  const {
    data,
    isError,
    isSuccess,
    isLoading,
    error
  } = useGetApiSubscriptionIdPayments(subscriptionId)

  const payments = computed<Payment[]>(() => {
    return (data.value ?? [] as unknown as ExternalPayment[]).map((p) => ({
      id: p.id,
      subscriptionId: p.subscription_id,
      currency: p.currency,
      amount: p.amount,
      payoutDate: p.payout_date,
      isPaid: p.is_paid,
      isOneOffCharge: p.is_one_off_charge,
      receiptUrl: p.receipt_url,
    }))
  })

  return {
    data: payments,
    error,
    isError,
    isSuccess,
    isLoading,
  }
}

type ExternalPayment = {
  id: number
  subscription_id: number
  currency: string
  amount: number
  payout_date: string
  is_paid: boolean
  is_one_off_charge: boolean
  receipt_url: string | null
}

export type Payment = {
  id: number
  subscriptionId: number
  currency: string
  amount: number
  payoutDate: string
  isPaid: boolean
  isOneOffCharge: boolean
  receiptUrl: string | null
}
