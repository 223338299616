<template>
  <div>
    <pre>{{ formattedData }}</pre>
  </div>
</template>

<script>
import { fontsData } from '@/data/fonts'
import { useFontsStore } from '@/store/fonts'

export default {
  components: {},
  data() {
    return {
      data: null,
    }
  },
  computed: {
    formattedData() {
      let str = JSON.stringify(this.data, null, 4)
      str = str.replaceAll('"url"', 'url')
      str = str.replaceAll('"label"', 'label')
      str = str.replaceAll('"lineHeightFactor"', 'lineHeightFactor')
      str = str.replaceAll('"preloaded"', 'preloaded')
      return str
    },
  },
  async mounted() {
    const fontsStore = useFontsStore()
    await fontsStore.initialize()

    this.data = fontsData.fonts.map((e) => {
      return {
        ...e,
        lineHeightFactor: this.getFontLineHeight(e.label, false), // For this page we don't want to remove the render
      }
    })
  },
  methods: {
    // TODO should probably be moved
    getFontLineHeight(familyName, removeChild = true) {
      // Create styled element with content
      const newDiv = document.createElement('div')
      newDiv.style.fontFamily = familyName
      newDiv.style.fontSize = '16px'
      newDiv.style.lineHeight = 'normal'
      if (removeChild) newDiv.style.opacity = '0'
      const newContent = document.createTextNode(`ABCDEFGHIJKLMNOPQRSTUVXYZ - ${familyName}`)
      newDiv.appendChild(newContent)

      // Add element to body, and get the rendered height
      document.body.append(newDiv)
      const result = newDiv.getClientRects()[0].height
      if (removeChild) document.body.removeChild(newDiv)

      // Return factor, fontSize is 16px
      return Math.round(result - 0.001) / 16
    },
  },
}
</script>
