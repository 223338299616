<script lang="ts" setup>
import IconSaxArrowLeft from '@/components/Icons/iconsax/IconSaxArrowLeft.vue'
import IconSaxArrowRight2 from '@/components/Icons/iconsax/IconSaxArrowRight2.vue'
import { ref, onMounted } from 'vue'
import { useUserInfoStore, onUserInfoReady } from '@/store/user/userInfo'
import type { Clip } from '@/Hooks/useTwitchClips'
import SearchResultsClipGrid from '@/components-v2/data-query/SelectClipDialog/SearchResultsClipGrid.vue'
import { useStorage } from '@vueuse/core'
import SelectClipDialogLoginForm from '@/components-v2/data-query/SelectClipDialog/SelectClipDialogLoginForm.vue'

defineProps<{ modelValue: boolean }>()
const emit = defineEmits<{
  (name: 'update:modelValue', value: boolean): void
  (name: 'select', clip: Clip): void
}>()

const userInfoStore = useUserInfoStore()
const userName = ref<string>()

function select(clip: Clip) {
  emit('select', clip)
}

const prompt = ref<string>()
const input = ref<HTMLInputElement>()

function selectLastThreeValidSearches(searches: string[]) {
  return searches
    .map((s) => s.trim())
    .filter(Boolean)
    .slice(-5)
}

const searchHistory = useStorage<string[]>('searchHistory', [])

function submit(query = '') {
  userName.value = query
  prompt.value = query
  searchHistory.value = selectLastThreeValidSearches([
    ...searchHistory.value.filter((s) => s !== userName.value),
    userName.value,
  ])
}

onMounted(() => input.value?.focus())

onUserInfoReady((state) => {
  if (state.userName && !userName.value) {
    submit(state.userName)
  }
})
</script>

<template>
  <header class="flex flex-col gap-0 md:gap-2">
    <button
      :class="[
        'flex items-center justify-center gap-2',
        'self-start rounded-xl px-4 py-2 text-blue-600',
        'outline outline-2 outline-offset-2 outline-transparent transition-all',
        'hover:bg-blue-600 hover:text-white',
        'focus:bg-blue-600 focus:text-white focus:outline-current',
        'active:scale-90',
      ]"
      @click="emit('update:modelValue', false)"
    >
      <IconSaxArrowLeft class="h-4 w-4 [&>path]:stroke-2" />
      Back
    </button>

    <h4 class="text-light m-0 p-0 text-xl md:text-3xl lg:text-4xl">Select Clips from Twitch</h4>
    <p class="font-light opacity-75 text-sm md:text-base">Enter a username to find clips for editing</p>
  </header>

  <section>
    <form
      class="layer-1 flex items-stretch rounded-xl outline outline-2 outline-transparent transition-all focus-within:outline-offset-2 focus-within:outline-cyan-400"
      @submit.prevent.stop="submit(userName)"
    >
      <input
        ref="input"
        v-model="userName"
        class="min-w-0 flex-auto rounded-l-xl rounded-r-none border border-r-0 border-zinc-900/20 p-3 text-left font-light text-zinc-900 outline-none dark:border-zinc-200/20"
        placeholder="Enter your Twitch username"
        type="text"
      />
      <button
        :disabled="!userName?.trim()"
        class="group flex flex-nowrap items-center justify-center gap-2 whitespace-nowrap rounded-l-none rounded-r-xl bg-purple-700 px-6 py-3 text-white outline-none transition-colors hover:bg-purple-600 focus:bg-purple-600"
        type="submit"
        @click="input?.focus()"
      >
        <span class="hidden md:inline">Search clips</span>
        <IconSaxArrowRight2
          class="h-4 w-4 transition-transform group-hover:translate-x-2 group-focus:translate-x-2 md:mr-2"
        />
      </button>
    </form>

    <div v-if="searchHistory.length > 0" class="flex flex-col flex-wrap gap-0.5 text-sm md:gap-2 pt-1 md:pt-2">
      <div>Recent searches:&nbsp;</div>
      <div class="flex flex-row gap-2 max-w-[90vw] overflow-y-auto px-2 py-1 rounded">
        <button
          v-for="history in [...searchHistory].reverse()"
          :key="history"
          class="text-company-primary-50 underline underline-offset-2"
          @click.prevent="submit(history)"
        >
          {{ history }}
        </button>
      </div>
    </div>
  </section>

  <SearchResultsClipGrid v-if="userInfoStore.isAuthenticated || prompt" :user-name="prompt" @select="select" />
  <div v-else class="relative flex-1 min-h-[420px]">
    <img alt="Preview of your clips" class="w-full p-4 blur-sm" height="631" src="/images/clip-grid.png" width="864" />
    <SelectClipDialogLoginForm />
  </div>
</template>

<style lang="scss" scoped></style>
