import { addMilliseconds } from 'date-fns'

const createCacheDefaultOptions = {
  expiryInMs: 24 * 60 * 60 * 1000,
}

export function createCache<T>(key: string, options: CreateCacheOptions = createCacheDefaultOptions) {
  options = { ...createCacheDefaultOptions, ...options }

  return {
    get() {
      const all = localStorage.getItem(key)
      if (all !== null) {
        return JSON.parse(all) as Record<string, Cached<T>>
      }
      return null
    },
    getById(id: string) {
      const item = this.get()?.[id]
      if (item && new Date(item.expiry) > new Date()) {
        return item
      }
      return null
    },
    set(id: string, value: T) {
      const all = this.get() ?? {}
      all[id] = {
        expiry: addMilliseconds(new Date(), options.expiryInMs).toString(),
        ...value,
      }
      localStorage.setItem(key, JSON.stringify(all))
    },
  }
}

type CreateCacheOptions = {
  expiryInMs: number
}

type Cached<T> = T & { expiry: string }
