<template>
  <div
    v-if="[LayoutKeys.SplitFree, LayoutKeys.Split16x9, LayoutKeys.Split4x3].includes(layout)"
    class="flex flex-col overflow-y-auto rounded-lg border-2 border-gray-100 bg-white p-4"
  >
    <div class="panel-content">
      <strong>Facecam position</strong>
      <div class="layout-options">
        <LightSwitch
          class="text-sm"
          background="bg-[#8833ff]"
          :options="switchFacecamPositionOptions"
          v-model:value="faceCamPosition"
          @update:value="(val) => faceCamPositionUpdated(val)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { useEditorMainStore } from '@/store/editor/editorMain'
import { LayoutKeys } from '@/data/layoutData'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import LightSwitch from '@/components-v2/data-input/boolean/LightSwitch.vue'
import { feedTypes } from '@/modules/SLVideoplayer/types'

export default {
  components: { LightSwitch },
  data() {
    return {
      layout: '',
      faceCamPosition: 'top',
      switchFacecamPositionOptions: [
        {
          label: 'Top',
          key: 'facecam-top',
          value: 'top',
        },
        {
          label: 'Bottom',
          key: 'facecam-bottom',
          value: 'bottom',
        },
      ],
    }
  },
  computed: {
    LayoutKeys() {
      return LayoutKeys
    },
  },
  methods: {
    faceCamPositionUpdated(value) {
      const feedStore = useEditorFeedDataStore()
      const editorMainStore = useEditorMainStore()

      this.faceCamPosition = value
      editorMainStore.switchFeeds = value === 'bottom'

      feedStore.fragmentArray.forEach((fragment) => {
        if (fragment.feedOptions.type === feedTypes.FaceCam) {
          const y = value === 'bottom' ? 1 - fragment.feedData.h : 0
          feedStore.updateFeed(fragment.key, { ...fragment.feedData, y })
        }
        if (fragment.feedOptions.type === feedTypes.GameFeed) {
          const y = value === 'top' ? 1 - fragment.feedData.h : 0
          feedStore.updateFeed(fragment.key, { ...fragment.feedData, y })
        }
      })
    },
  },
  mounted() {
    const editorMainStore = useEditorMainStore()
    this.layout = editorMainStore.layoutName
  },
}
</script>
