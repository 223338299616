<script setup lang="ts">
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import { syncRefs, useIntervalFn, useWindowSize } from '@vueuse/core'
import { reactiveTransform, useSpring } from '@vueuse/motion'
import { computed, ref, watch } from 'vue'
import { useDrag } from '@vueuse/gesture'
import { clamp } from 'lodash-es'

const props = defineProps<{
  startMs: number
  endMs: number
  minStartMs: number
  maxEndMs: number
  active?: boolean
  height?: number
  seekBehavior?: 'seek' | 'none'
}>()

const emit = defineEmits<{
  resizeStart: [startMs: number, endMs: number]
  dragStart: [startMs: number, endMs: number]
  dragging: [startMs: number, endMs: number]
  dragStop: [startMs: number, endMs: number]
  resize: [startMs: number, endMs: number]
  resizeStop: [startMs: number, endMs: number]
  activated: []
}>()

const {
  pxToMs,
  useMsToPx,
  dragging: timelineDragging,
  triggerSeek: _triggerSeek,
  containerWidth,
  durationMs,
  containerEl,
  msToPx,
  ready,
} = useTimelineStore()

const triggerSeek = (ms: number) => {
  if (props.seekBehavior === 'seek') _triggerSeek(ms)
}

const minWith = computed(() => 4)

const active = ref(props.active)
syncRefs(
  computed(() => props.active),
  active
)

const bodyEl = ref<HTMLElement>()

const _minStartPx = useMsToPx(
  computed(() => props.minStartMs),
  true
)
const _maxEndPx = useMsToPx(
  computed(() => props.maxEndMs),
  true
)

const _startPx = useMsToPx(
  computed(() => props.startMs),
  true
)

const _endPx = useMsToPx(
  computed(() => props.endMs),
  true
)

const _height = ref(props.height)
const _width = ref(msToPx(props.endMs - props.startMs, true) || 100)

const { state, transform } = reactiveTransform({
  x: _startPx.value || 0,
  y: 0,
})

watch([_startPx, _endPx], ([x, e]) => {
  if (x) state.x = x
  if (e - x) _width.value = e - x
})

const { set } = useSpring(state, {
  stiffness: 2000,
  damping: 100,
  mass: 1,
  velocity: 0,
  restSpeed: 0.01,
  restDelta: 0.01,
})

const initialPos = ref({
  x: state.x,
  width: _width,
})
const getDragByMovement = (movement: number): { x; width } => {
  const x = clamp(initialPos.value.x + movement, _minStartPx.value, _maxEndPx.value - initialPos.value.width)
  const width = _width.value
  return { x, width }
}

const getResizeByMovement = (movement: number, type: 'hr' | 'hl'): { x; width } => {
  if (type === 'hr') {
    const width = clamp(initialPos.value?.width + movement, minWith.value, _maxEndPx.value - initialPos.value.x)
    return { x: initialPos.value.x, width }
  } else {
    const x = clamp(
      initialPos.value.x + movement,
      _minStartPx.value,
      initialPos.value.x + initialPos.value.width - minWith.value
    )
    const width = initialPos.value.width - (x - initialPos.value.x)
    return { x, width }
  }
}

const dragType = ref<'body' | 'hl' | 'hr'>()

const direction = -1
const lastX = 0
const distance = 5
const { pause, resume, isActive } = useIntervalFn(
  () => {
    containerEl.value?.scrollBy({
      left: direction * distance,
    })
    if (dragType.value === 'body') {
      initialPos.value = {
        x: initialPos.value.x + direction * distance,
        width: initialPos.value.width,
      }
      const clampedMovement = getDragByMovement(lastX)
      state.x = clampedMovement.x
      emit('dragging', pxToMs(clampedMovement.x, true), pxToMs(clampedMovement.x + clampedMovement.width, true))
    } else {
      if (dragType.value === 'hl')
        initialPos.value = {
          x: initialPos.value.x + direction * distance,
          width: initialPos.value.width - direction * distance,
        }
      if (dragType.value === 'hr')
        initialPos.value = {
          x: initialPos.value.x,
          width: initialPos.value.width + direction * distance,
        }

      const clampedMovement = getResizeByMovement(lastX, dragType.value)
      state.x = clampedMovement.x
      _width.value = clampedMovement.width
      emit('resizing', pxToMs(clampedMovement.x, true), pxToMs(clampedMovement.x + clampedMovement.width, true))
    }
  },
  50,
  {
    immediate: false,
  }
)

useDrag(
  ({ movement: [xM], dragging, first, last, xy: [x], _dragTarget, tap, canceled, event, scrolling }) => {
    if (tap) {
      emit('activated')
      return
    }

    event.preventDefault()

    if (first && dragging) {
      const target = _dragTarget || (event.target as HTMLElement)
      dragType.value = ((target?.closest('[data-drag-type]') as HTMLElement)?.dataset.dragType as any) || 'body'
      timelineDragging.value = { type: 'node' }
      initialPos.value = {
        x: state.x,
        width: _width.value,
      }
    }

    // auto scrolling when dragging near end of timeline
    // lastX = xM
    // if (dragging) {
    //   const boundary = containerWidth.value * 0.1
    //   const currentScroll = containerEl.value?.scrollLeft
    //   if (
    //     !(dragType.value === 'hl' && currentScroll === 0) &&
    //     !(dragType.value === 'hr' && currentScroll === containerEl.value?.scrollWidth)
    //   ) {
    //     if (x < boundary) {
    //       direction = -1
    //       if (!isActive.value) resume()
    //     } else if (x > containerWidth.value - boundary) {
    //       direction = 1
    //       if (!isActive.value) resume()
    //     } else {
    //       if (isActive.value) pause()
    //     }
    //   }
    // }
    // if (last && isActive.value) pause()
    const inBounds = isActive.value

    if (dragType.value == 'body') {
      const clampedMovement = getDragByMovement(xM)
      if (!inBounds) {
        state.x = clampedMovement.x
      }
      if (first) emit('dragStart', props.startMs, props.endMs)
      if (dragging)
        emit('dragging', pxToMs(clampedMovement.x, true), pxToMs(clampedMovement.x + clampedMovement.width, true))
      if (last) {
        emit('dragStop', pxToMs(clampedMovement.x, true), pxToMs(clampedMovement.x + clampedMovement.width, true))
      }
    } else {
      const clampedMovement = getResizeByMovement(xM, dragType.value)
      if (!inBounds) {
        state.x = clampedMovement.x
        _width.value = clampedMovement.width
      }
      if (first) emit('resizeStart', props.startMs, props.endMs)
      if (dragging)
        emit('resize', pxToMs(clampedMovement.x, true), pxToMs(clampedMovement.x + clampedMovement.width, true))
      if (last) {
        if (dragType.value === 'hl') triggerSeek(pxToMs(clampedMovement.x, true))
        emit('resizeStop', pxToMs(clampedMovement.x, true), pxToMs(clampedMovement.x + clampedMovement.width, true))
      }
    }

    if (last) {
      timelineDragging.value = undefined
      dragType.value = undefined
    }
  },
  {
    domTarget: bodyEl,
    eventOptions: {
      passive: false,
      capture: true,
    },
  }
)

const smallHandleSize = 40
const useHandles = computed(() => _width.value > smallHandleSize)
</script>

<template>
  <div
    ref="bodyEl"
    data-track-node
    :style="{ transform, height: `${_height}px`, width: `${_width}px` }"
    :data-state="active ? 'active' : 'inactive'"
    class="absolute top-0 select-none group/node"
    @click.stop
  >
    <div class="relative h-full w-full">
      <slot class="pointer-events-none" />
      <div class="absolute inset-0 opacity-0 group-hover/node:opacity-100 group-data-[state=active]/node:opacity-100 transition-opacity">
        <div
          data-drag-type="hr"
          class="absolute !-right-4 pr-4 h-full cursor-w-resize"
          :class="{
            'w-[2px]': !useHandles,
          }"
        >
          <slot v-if="useHandles" name="handles">
            <div class="absolute flex h-full flex-row gap-0.5 px-1 py-1">
              <div class="h-full w-1 rounded bg-gray-700"></div>
              <div class="h-full w-1 rounded bg-gray-700"></div>
            </div>
          </slot>
        </div>
        <div
          data-drag-type="hl"
          class="absolute !-left-4 pl-4 h-full cursor-e-resize"
          :class="{
            'w-[4px]': !useHandles,
          }"
        >
          <slot v-if="useHandles" name="handles">
            <div class="absolute flex h-full flex-row gap-0.5 px-1 py-1">
              <div class="h-full w-1 rounded bg-gray-700"></div>
              <div class="h-full w-1 rounded bg-gray-700"></div>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
