<template>
  <div class="flex w-full flex-col items-start gap-4 md:flex-row">
    <!-- Promotion section -->
    <div class="panel flex w-full flex-row lg:p-12">
      <LottieAnimation url="/lottie/cat-socials.json" class="aspect-[4/5]" />
      <div class="flex flex-col gap-2">
        <h2 class="text-4xl font-bold leading-snug">
          Keep the Stream Alive: Downgrade Your Subscription and Keep Converting!
        </h2>

        <p class="text-lg font-thin text-[#4D287F]">
          Don't let high costs interrupt your content creation. Keep enjoying Streamladder's powerful features and save
          money with our downgrading option.
        </p>

        <strong class="mt-4 text-sm">Downgrade and keep these features:</strong>
        <ol class="flex w-full flex-col space-y-2">
          <Feature type="gained" v-for="feature in features" :key="feature">
            {{ feature }}
          </Feature>
        </ol>
      </div>
    </div>

    <cancel-options-panel :offer-downgrade="true" />
  </div>
</template>
<script>
import plans from '@/data/plans'
import settings from '@/data/settings'
import CancelOptionsPanel from '@/components/Account/Cancel/CancelOptionsPanel.vue'
import Feature from '@/components-v2/lists/Feature.vue'
import { allFeaturesByTier } from '@/data/allFeaturesByTier'
import LottieAnimation from "@/components/LottieAnimation.vue";

export default {
  components: { LottieAnimation, Feature, CancelOptionsPanel },
  data() {
    return {
      settings,
      plans,
    }
  },
  computed: {
    features() {
      return allFeaturesByTier.silver
    },
  },
}
</script>
