<script setup lang="ts">
import {
  ToolbarButton,
  ToolbarRoot,
  ToolbarSeparator,
} from 'radix-vue'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import ShapeSelect from '@/modules/CustomLayouts/Crop/CropToolbar/ShapeSelect.vue'
import type { CropsStore } from '@/store/entity-system/useCropsStore'
import { inject, computed, ref } from 'vue'
import type { Ref } from 'vue'
import { useElementSize, useWindowSize, useResizeObserver, useWindowScroll } from '@vueuse/core'
import IconSaxDocumentCopy from '@/components/Icons/iconsax/IconSaxDocumentCopy.vue'
import IconBringToFront from '@/components/Icons/IconBringToFront.vue'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { Button } from '@/components/ui/button'
import { clamp } from 'lodash-es'
import CropToolbarTooltip from '@/modules/CustomLayouts/Crop/CropToolbar/CropToolbarTooltip.vue'

const props = defineProps<{ id: string }>()
const cropsStore = inject<CropsStore>('cropsStore')!

const crop = cropsStore.selectById(props.id)

const canvas = inject<Ref<HTMLCanvasElement>>('canvas')!

const element = ref<HTMLElement | null>(null)
const { width, height } = useElementSize(element)

const { width: windowWidth, height: windowHeight } = useWindowSize()
const canvasBounding = ref({ width: 0, height: 0, top: 0, left: 0 })
useResizeObserver(canvas, () => {
  canvasBounding.value = canvas.value.getBoundingClientRect()
})

useWindowScroll(() => {
  if (canvas.value) {
    canvasBounding.value = canvas.value.getBoundingClientRect()
  }
})

const offset = 16

const top = computed(() => {

  const topValue = (crop.y + crop.height) * canvasBounding.value.height + canvasBounding.value.top
  const topMin = offset
  const topMax = windowHeight.value - height.value - 2 * offset

  if (topValue < topMin) {
    return topMin
  } else if (topValue > topMax) {
    return topMax - ((1 - (crop.y + crop.height)) * canvasBounding.value.height) - height.value
  } else {
    return topValue
  }
})

const left = computed(() => {

  const leftValue = (crop.x + 0.5 * crop.width) * canvasBounding.value.width + canvasBounding.value.left
  const leftMin = offset + 0.5 * width.value
  const leftMax = windowWidth.value - 0.5 * width.value

  return clamp(leftValue, leftMin, leftMax)
})

const currentCrops = cropsStore.idsWhereLayoutIdIs(crop.layoutId)

const editorFocusStore = useEditorFocusStore()
function duplicate() {
  const duplicateId = cropsStore.duplicateCropById(props.id)
  if (duplicateId) {
    editorFocusStore.setFocus('crop', duplicateId)
  }
}

const root = inject<string>('root')!
</script>

<template>
  <Teleport :to="root">
    <ToolbarRoot
      ref="element"
      data-retain-focus
      :style="{ top: top + 'px', left: left - 0.5 * width + 'px' }"
      class="layer-1 !fixed mt-4 flex !min-w-max max-w-full animate-[slideUpAndFade_150ms_cubic-bezier(0.4,_0,_0.2,_1)_forwards] items-center gap-1 rounded-md py-1 px-1.5 shadow-lg z-[250]"
      @click.stop
    >
      <ShapeSelect :id="id" />

      <ToolbarSeparator class="h-6 w-px bg-zinc-200" />

      <ToolbarButton>
        <CropToolbarTooltip>
          <template #trigger>
            <Button
              variant="ghost"
              size="sm"
              @click.stop="cropsStore.moveToForeground(id)"
            >
              <IconBringToFront class="stoke-[1] h-4 w-4" />
            </Button>
          </template>

          <template #tip>
            Bring to front
          </template>
        </CropToolbarTooltip>
      </ToolbarButton>

      <ToolbarSeparator class="h-6 w-px bg-zinc-200" />

      <ToolbarButton>
        <CropToolbarTooltip :disabled="currentCrops.length === 10">
          <template #trigger="{ disabled }">
            <Button
              :disabled="disabled"
              variant="ghost"
              size="sm"
              @click.stop="duplicate"
            >
              <IconSaxDocumentCopy class="stoke-[1] h-4 w-4" />
            </Button>
          </template>

          <template #tip="{ disabled }">
            <template v-if="disabled">Cannot add more than 10 crops</template>
            <template v-else>Duplicate crop</template>
          </template>
        </CropToolbarTooltip>
      </ToolbarButton>

      <ToolbarSeparator class="h-6 w-px bg-zinc-200" />

      <ToolbarButton>
        <CropToolbarTooltip :disabled="currentCrops.length === 1">
          <template #trigger="{ disabled }">
            <Button
              :disabled="disabled"
              variant="ghost"
              size="sm"
              class="text-rose-500 hover:bg-rose-50 disabled:pointer-events-none"
              @click.stop="cropsStore.removeById(id)"
            >
              <IconSaxTrash class="stoke-[1] h-4 w-4" />
            </Button>
          </template>

          <template #tip="{ disabled }">
            <template v-if="disabled">Cannot remove the last crop</template>
            <template v-else>Remove crop</template>
          </template>
        </CropToolbarTooltip>
      </ToolbarButton>
    </ToolbarRoot>
  </Teleport>
</template>

<style lang="scss" scoped></style>
