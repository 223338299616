<template>
  <svg width="24" height="24" viewBox="0 0 24 24" class="stroke-current" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M18 12H6.00005" stroke-width="1.06065" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M16 14L18 12L16 10" stroke-width="1.06065" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8 10L6.00002 12L8 14" stroke-width="1.06065" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
