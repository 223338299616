<template>
  <div class="absolute inset-0 h-full w-full overflow-hidden rounded-lg" ref="container">
    <div class="cropper" :class="{ rounded: fragment.cropOptions.isRound }" ref="cropper" />
  </div>
</template>

<script lang="ts">
import Moveable from 'moveable'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { defineComponent } from 'vue'
import type { EditorFragment } from '@/modules/SLVideoplayer/types'
import type { PropType } from 'vue'
import { clamp } from '@/components/Editor/Timeline/helpers'
import { useResizeObserver } from '@vueuse/core'

export default defineComponent({
  props: {
    fragment: {
      type: Object as PropType<EditorFragment>,
      required: true,
    },
  },
  data() {
    return {
      cropperX: 0,
      cropperY: 0,
      containerWidth: 0,
      containerHeight: 0,
      cropperW: 0,
      cropperH: 0,
    }
  },
  $cropper: null,
  $cropperData: {
    x: 0,
    y: 0,
    w: 0,
    h: 0,
  },
  computed: {
    container() {
      return this.$refs.container as HTMLElement
    },
    options() {
      return this.fragment?.cropOptions
    },
  },
  mounted() {
    this.containerWidth = this.container.clientWidth
    this.containerHeight = this.container.clientHeight

    this.initMoveable()
    this.setInitialCropperData()

    useResizeObserver(this.container, this.onResize)
  },
  methods: {
    setInitialCropperData() {
      let h = this.containerHeight * this.options.initialCropSize
      let w = h * this.options.aspectRatio
      if (w > this.containerWidth) {
        w = this.containerWidth * this.options.initialCropSize
        h = w / this.options.aspectRatio
      }
      const {
        cropData = {
          x: (1 - w) / 2,
          y: (1 - h) / 2,
          w: w,
          h: h,
        },
      } = this.fragment

      this.setCropperData(cropData.x, cropData.y, cropData.w, cropData.h)
    },
    initMoveable() {
      this.$options.cropper = new Moveable(this.container, {
        className: 'moveable-cropper',
        target: this.$refs.cropper,
        container: this.container,
        draggable: true,
        resizable: true,
        scalable: false,
        rotatable: false,
        warpable: false,
        pinchable: true,
        origin: false,
        keepRatio: this.options.fixedRatio,
        renderDirections: ['nw', 'ne', 'sw', 'se'],
        zoom: 1,
        edge: false,
        throttleDrag: 0,
        throttleResize: 0,
        throttleScale: 0,
        throttleRotate: 0,
        snappable: true,
        snapThreshold: 5,
        snapElement: false,
        snapGap: false,
        snapCenter: true,
        bounds: {
          left: 0,
          right: this.containerWidth,
          top: 0,
          bottom: this.containerHeight,
        },
      })

      if (this.options.showSnapping) {
        this.$options.cropper.verticalGuidelines = [this.containerWidth / 2]

        this.$options.cropper.snapDirections = { center: true }
      }

      this.$options.cropper.on('drag', this.handleDragCropper)
      this.$options.cropper.on('dragEnd', this.saveCropData)
      this.$options.cropper.on('resize', this.handleResizeCropper)
      this.$options.cropper.on('resizeEnd', this.saveCropData)
    },
    saveCropData() {
      if (this.$options.cropperData.w < 10) {
        this.$options.cropperData.w = 10
        this.setCropperData(
          this.$options.cropperData.x / this.containerWidth,
          this.$options.cropperData.y / this.containerHeight,
          this.$options.cropperData.w / this.containerWidth,
          this.$options.cropperData.h / this.containerHeight
        )
      }

      if (this.$options.cropperData.h < 10) {
        this.$options.cropperData.h = 10
        this.setCropperData(
          this.$options.cropperData.x / this.containerWidth,
          this.$options.cropperData.y / this.containerHeight,
          this.$options.cropperData.w / this.containerWidth,
          this.$options.cropperData.h / this.containerHeight
        )
      }

      if (this.isInvalidRatio(this.$options.cropperData.w, this.$options.cropperData.h)) {
        this.setCropperData(
          this.$options.cropperData.x / this.containerWidth,
          this.$options.cropperData.y / this.containerHeight,
          this.$options.cropperData.w / this.containerWidth,
          this.$options.cropperData.h / this.containerHeight
        )
      }

      // make sure the ratio's are never below 0 and above 1
      const x = clamp(this.$options.cropperData.x / this.containerWidth, 0, 1)
      const y = clamp(this.$options.cropperData.y / this.containerHeight, 0, 1)
      const w = clamp(this.$options.cropperData.w / this.containerWidth, 0, 1 - x)
      const h = clamp(this.$options.cropperData.h / this.containerHeight, 0, 1 - y)

      const editorFeedDataStore = useEditorFeedDataStore()
      editorFeedDataStore.updateCrop(this.fragment?.key, {
        x,
        y,
        w,
        h,
        ratio: this.$options.cropperData.w / this.$options.cropperData.h,
      })
    },
    setCropperData(x: number, y: number, w: number, h: number) {
      this.$options.cropperData = {
        x: x * this.containerWidth,
        y: y * this.containerHeight,
        w: w * this.containerWidth,
        h: h * this.containerHeight,
      }
      if (this.isInvalidRatio(this.$options.cropperData.w, this.$options.cropperData.h)) {
        // reset height to max value
        this.$options.cropperData.h =
          this.$options.cropperData.w /
          (this.options?.fixedRatio ? this.options.aspectRatio : this.options.minimalRatio)
      }
      this.$options.cropper.target.style.width = this.$options.cropperData.w + 'px'
      this.$options.cropper.target.style.height = this.$options.cropperData.h + 'px'
      this.$options.cropper.target.style.transform = `translate(${this.$options.cropperData.x}px, ${this.$options.cropperData.y}px)`
      this.$options.cropper.updateTarget()
    },
    handleDragCropper({ target, translate, transform }) {
      target.style.transform = transform

      this.$options.cropperData.x = translate[0]
      this.$options.cropperData.y = translate[1]
    },
    handleResizeCropper({ target, width, height, drag }) {
      const beforeTranslate = drag.beforeTranslate

      target.style.width = `${width}px`
      target.style.height = `${height}px`

      this.$options.cropperData.w = width
      this.$options.cropperData.h = height
      this.$options.cropperData.x = beforeTranslate[0]
      this.$options.cropperData.y = beforeTranslate[1]

      target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px)`
    },
    isInvalidRatio(w: number, h: number) {
      // cropper can't be too 'vertical' because it will look very bad in the result.
      if (this.options?.fixedRatio && w / h !== this.options.aspectRatio) return true
      return this.options.minimalRatio > w / h
    },
    isTooSmall(w: number, h: number) {
      return w < 5 || h < 5
    },
    onResize() {
      // size changed, need to recalculate
      this.containerWidth = this.container.clientWidth
      this.containerHeight = this.container.clientHeight
      this.$options.cropper.bounds.right = this.containerWidth
      this.$options.cropper.bounds.bottom = this.containerHeight
    },
  },
  beforeUnmount() {
    this.$options.cropper?.destroy()
  },
  watch: {
    fragment(fragment: EditorFragment) {
      // if cropperdata is different from the one in the store, update it
      if (
        fragment?.cropData?.x != this.$options.cropperData.x / this.containerWidth ||
        fragment?.cropData?.y != this.$options.cropperData.y / this.containerHeight ||
        fragment?.cropData?.w != this.$options.cropperData.w / this.containerWidth ||
        fragment?.cropData?.h != this.$options.cropperData.h / this.containerHeight
      ) {
        this.setCropperData(
          fragment?.cropData?.x || 0,
          fragment?.cropData?.y || 0,
          fragment?.cropData?.w || 1,
          fragment?.cropData?.h || 1
        )
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.cropper {
  position: absolute;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.6);
  top: 0;
  outline: 2px dashed #33cff2;

  cursor: grab;

  &:active {
    cursor: grabbing;
  }

  &.rounded {
    border-radius: 50% !important;
    // border: 1px solid white;
  }
}
</style>

<style lang="scss">
.moveable-control-box.moveable-cropper {
  --moveable-color: #33cff2;

  .moveable-line {
    background: transparent;

    &.moveable-guideline {
      // show vertical snap line
      width: 2px;
      background: var(--moveable-color);
    }
  }

  .moveable-control.moveable-direction {
    border-radius: 0;
    border: none;
    width: 8px;
    height: 8px;
    margin-top: -6px;
    margin-left: -6px;

    background: transparent;
    border: 2px solid var(--moveable-color);

    &.moveable-se {
      width: 14px;
      height: 14px;
      margin-top: -7px;
      margin-left: -7px;

      &::after {
        content: '';
        width: 40px;
        height: 40px;
        position: absolute;
      }
    }
  }
}
</style>
