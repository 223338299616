<template>
  <teleport to="body">
    <div @click.self="this.$emit('cancel')" class="modal modal-open">
      <Transition appear name="fade">
        <div class="layer-2 text-brand-state-text-primary modal-box text-center">
          <h3 class="text-lg font-bold">{{ title }}</h3>
          <p class="py-2" v-if="message">{{ message }}</p>
          <div class="modal-action justify-center space-x-4">
            <Button variant="outline" @click="this.$emit('confirm')">Yes</Button>
            <Button variant="primary" @click="this.$emit('cancel')">No</Button>
          </div>
        </div>
      </Transition>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Button } from '@/components/ui/button'

export default defineComponent({
  components: { Button },
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      default: '',
    },
  },
  emits: ['confirm', 'cancel'],
})
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>