<script setup lang="ts">
import { useInfiniteQuery } from '@tanstack/vue-query'
import { GiphyService } from '@/components/Giphy/GiphyService'
import { computed, ref } from 'vue'
import { Input } from '@/components/ui/input'
import { debouncedRef } from '@vueuse/core'
import { useStickersStore } from '@/store/entity-system/useStickersStore'
import { v4 } from 'uuid'
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import { Button } from '@/components/ui/button'
import GiphyElement from '@/components/Giphy/GiphyElement.vue'
import { IconLoaderQuarter } from '@tabler/icons-vue'
import compatabilityChecker from '@/services/compatabilityChecker'
import { useUserInfoStore } from '@/store/user/userInfo'
import { tiers } from '@/enums/tiers'
import type { IGif } from '@giphy/js-types'
import { posthog } from 'posthog-js'

type Giph = {
  id: string;
  url: string;
  gif: IGif;
};

const userInfoStore = useUserInfoStore();
const isGold = computed(() => userInfoStore.tier === tiers.GOLD);

const search = ref('')
const selectedFilter = ref('stickers')

const throttledSearch = debouncedRef(search, 1000)
const { data, fetchNextPage, isFetchingNextPage } = useInfiniteQuery({
  queryKey: ['giphy', selectedFilter, throttledSearch],
  queryFn: async (params) => {
    if (throttledSearch.value) {
      return GiphyService.search(throttledSearch.value, {
        type: selectedFilter.value,
        offset: params.pageParam,
        limit: isGold.value ? 20 : 10,
      })
    }
    return GiphyService.search('Twitch',{
      type: selectedFilter.value,
      offset: params.pageParam,
      limit: isGold.value ? 20 : 10,
    })
  },
  initialPageParam: 0,
  getNextPageParam: (lastPage) => {
    return lastPage.pagination.offset + lastPage.pagination.count
  },
  placeholderData: (data) => data,
  staleTime: 1000 * 60 * 5 // 5 minutes
})

const giphys = computed(() => {
  return data.value?.pages
    .map(page => page.data.map((gif) => {
        return {
          id: gif.id,
          url: gif.images.downsized_medium.url,
          gif: gif,
        } as Giph
      }) ?? []
    ).flat()
});

const emit = defineEmits<{
  (name: 'selectGif', gif: Giph): void
}>()

const stickersStore = useStickersStore()
const focusStore = useEditorFocusStore()

function giphSelected(gif: Giph) {

  posthog.capture('Giphy Stickers: added sticker', {
    stickerId: gif.id,
    stickerUrl: gif.url,
  });

  const giphyMeta = gif.gif
  const aspectRatio = giphyMeta.images.original.width / giphyMeta.images.original.height
  const screenWidth = 1080
  const screenHeight = 1920
  const pixWidth = screenWidth * 0.6
  const pixHeight = pixWidth / aspectRatio

  const area = {
    x: 0.2,
    y: 0.2,
    width: pixWidth / screenWidth,
    height: pixHeight / screenHeight,
  }

  const stickerId = stickersStore.createGiphySticker({
    editing: true,
    imageUrl: giphyMeta.images.original.webp,
    scale: undefined,
    key: v4(),
    startMs: 0,
    area: area,
    naturalWidth: giphyMeta.images.original.width,
    naturalHeight: giphyMeta.images.original.height,
  })

  if (compatabilityChecker.isMobile()) {
    window.scrollTo(0, 0);
  }

  setTimeout(() => focusStore.setFocus(FocusTypes.STICKER, stickerId), 0)
  emit('selectGif', gif)
}

const filters = [
  {
    key: 'stickers',
    name: 'Stickers',
  },
  {
    key: 'gifs',
    name: 'Gifs',
  },
]

const grid = ref()
</script>

<template>
  <div class="flex w-full flex-col gap-2 p-4">
    <div class="flex flex-col gap-2">
      <div class="flex flex-wrap gap-2">
        <button
          v-for="filter in filters"
          :key="filter.key"
          @click="() => { selectedFilter = filter.key }"
          :class="{ 'btn-active !bg-zinc-200': selectedFilter === filter.key }"
          class="btn-ghost btn-sm btn bg-zinc-50 font-light hover:bg-zinc-100 active:bg-zinc-200"
        >
          {{ filter.name }}
        </button>
        <div class="flex-grow" />
        <a href="https://giphy.com/" target="_blank" class="w-24">
          <img src="/images/giphy/powered-by.png" alt="Powered by Giphy" class="max-w-full rounded hover:scale-105 transition-all" />
        </a>
      </div>
      <Input v-if="isGold" class="font-light transition-all" v-model="search" placeholder="Search Giphy" />
      <Input v-else class="font-light pointer-events-none" placeholder="Search Giphy" disabled />
    </div>

    <div ref="grid">
      <div class="p-1 grid gap-[8px] grid-rows-[repeat(auto-fill,_4px)] grid-cols-[repeat(auto-fill,_minmax(200px,_1fr))]">
        <GiphyElement v-for="item in giphys" :key="item.id" :item="item" @click="giphSelected(item)" />
      </div>

      <Button v-if="isFetchingNextPage" variant="ghost" class="w-full" @click="fetchNextPage">
        <IconLoaderQuarter class="animate-spin h-6 w-6 text-company-primary-50" />
      </Button>

      <Button v-else-if="isGold && giphys && giphys.length > 3" variant="outline" class="w-full" @click="fetchNextPage">
        Load more
      </Button>

      <div v-else-if="!giphys || giphys?.length === 0" class="flex justify-center items-center text-center text-sm font-light min-h-[50px]">
        No results found 🙁
      </div>
    </div>

  </div>
</template>

<style scoped lang="scss"></style>
