<script setup lang="ts" xmlns="http://www.w3.org/1999/html">
import ArrowRightIcon from '@/components/Icons/ArrowRightIcon.vue'
import MagicIcon from '@/components/Icons/Normalized/MagicIcon.vue'
import { computed, ref } from 'vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { tiers } from '@/enums/tiers'
import { storeToRefs } from 'pinia'
import DropdownMenu from '@/components-v2/modules/DropdownMenu/DropdownMenu.vue'

const props = defineProps<{ links: any[]; userLinks: any[]; offset: number }>()
const emit = defineEmits<{
  (event: 'login'): void
  (event: 'logout'): void
  (event: 'upgrade'): void
}>()

const { isAuthenticated, userName, tier } = storeToRefs(useUserInfoStore())
const isGoldUser = computed(() => tier.value === tiers.GOLD)

const style = computed(() => {
  if (!props.offset) return null
  return { top: props.offset + 12 + 'px' }
})
const isOpen = ref(false)
</script>

<template>
  <DropdownMenu class="lg:hidden" :arrow="!style" v-model="isOpen">
    <template #button="{ ...props }">
      <button
        v-bind="props"
        type="button"
        class="inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-primary hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
        aria-controls="mobile-menu"
        aria-expanded="false"
      >
        <span class="sr-only">Open main menu</span>
        <!--
        Icon when menu is closed.

        Menu open: "hidden", Menu closed: "block"
      -->
        <svg
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          aria-hidden="true"
          :class="isOpen ? 'hidden' : 'block'"
        >
          <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
        <!--
        Icon when menu is open.

        Menu open: "block", Menu closed: "hidden"
      -->
        <svg
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          aria-hidden="true"
          :class="isOpen ? 'block' : 'hidden'"
        >
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </template>
    <template #menu="{ ...props }">
      <nav
        v-bind="props"
        class="left-auto flex w-[calc(100vw-1rem)] flex-col gap-2 text-company-primary sm:w-[calc(theme(screens.sm)-1rem)] md:w-[calc(theme(screens.md)-1rem)]"
        :style="style"
        id="mobile-menu"
      >
        <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->

        <template v-for="link in links">
          <router-link
            v-if="link.internal"
            :to="link.url"
            :key="link.name"
            :class="link.class"
            exact-active-class="bg-primary text-white"
            class="rounded bg-gray-100 px-4 py-2 transition hover:bg-primary hover:text-white active:bg-primary active:text-white"
          >
            {{ link.name }}
          </router-link>
          <a
            v-else
            :href="link.url"
            :key="link.name"
            class="rounded bg-gray-100 px-4 py-2 transition hover:bg-primary hover:text-white active:bg-primary active:text-white"
          >
            {{ link.name }}
          </a>
        </template>

        <hr class="my-2 border-secondary" />

        <template v-if="isAuthenticated">
          <div class="flex justify-between px-2">
            <span class="text-xl font-bold">{{ userName }}</span>
            <button v-if="isAuthenticated && !isGoldUser" @click="emit('upgrade')" class="btn-success btn-sm btn gap-1">
              <span>Upgrade</span>
              <MagicIcon class="h-4 w-4" />
            </button>
          </div>

          <router-link
            v-for="link in userLinks"
            :key="link.name"
            exact-active-class="bg-primary text-white"
            class="rounded bg-gray-100 px-4 py-2 transition hover:bg-primary hover:text-white active:bg-primary active:text-white"
            :to="link.url"
            :title="link.description"
          >
            {{ link.name }}
          </router-link>
          <button
            class="content-start rounded bg-gray-100 px-4 py-2 text-start text-red-600 transition hover:bg-red-500 hover:text-white active:bg-primary active:text-white"
            @click.prevent="emit('logout')"
          >
            Logout
          </button>
        </template>
        <button v-else class="btn-outline btn m-1 flex-nowrap gap-2" @click="emit('login')">
          Login
          <arrow-right-icon />
        </button>
      </nav>
    </template>
  </DropdownMenu>
</template>

<style scoped lang="scss"></style>
