<template>
  <AppPage :has-footer="false">
    <template v-slot:content v-if="!isLoading">
      <discount-conclusion v-if="to === 'discount'" />

      <div v-else class="container mx-auto flex flex-grow flex-col">
        <!-- If the user cancelled their subscription -->
        <cancelled-conclusion v-if="to === 'cancelled'" :subscription-info="subscription" />

        <!-- If the user downgraded their subscription -->
        <downgrade-conclusion v-if="to === 'downgraded'" :subscription-info="subscription" />

        <div class="mb-8 flex w-full items-center justify-center space-x-4">
          <a :href="settings.discordInviteUrl" class="hover:text-company-primary-600" target="_blank">
            <discord-logo class="h-10 w-10 fill-current" />
          </a>
          <a :href="settings.tiktokUrl" class="hover:text-company-primary-600" target="_blank">
            <tik-tok-icon class="h-8 w-8 fill-current" />
          </a>
          <a :href="settings.twitterUrl" class="hover:text-company-primary-600" target="_blank">
            <twitter-icon class="h-10 w-10 fill-current" />
          </a>
        </div>
      </div>
    </template>
  </AppPage>
</template>
<script>
import DiscordLogo from '@/components/Icons/DiscordLogo.vue'
import TwitterIcon from '@/components/Icons/TwitterIcon.vue'
import settings from '@/data/settings'
import TikTokIcon from '@/components/Icons/SocialMedia/TikTokIcon.vue'
import CancelledConclusion from '@/components/Account/Cancel/CancelledConclusion.vue'
import DowngradeConclusion from '@/components/Account/Cancel/DowngradeConclusion.vue'
import { useUserSubscriptionStore } from '@/store/user/userSubscription'
import { mapState } from 'pinia/dist/pinia'
import DiscountConclusion from '@/components/Account/Cancel/DiscountConclusion.vue'
import AppPage from '@/components/AppPage.vue'

export default {
  components: {
    AppPage,
    DiscountConclusion,
    DowngradeConclusion,
    CancelledConclusion,
    TikTokIcon,
    TwitterIcon,
    DiscordLogo,
  },
  data() {
    return {
      settings,
      to: 'cancelled',
    }
  },
  computed: {
    ...mapState(useUserSubscriptionStore, ['isLoading', 'subscription', 'isPaddleSubscription']),
  },
  async mounted() {
    this.to = this.$route.query.to

    const subscriptionStore = useUserSubscriptionStore()
    await subscriptionStore.fetchSubscriptionData()
  },
}
</script>
