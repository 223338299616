import { useUserInfoStore } from '@/store/user/userInfo'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'

export default {
  // returns text for the stickers
  getInitialStickerText(clipSource) {
    // initial sticker text is based on:
    const userInfoStore = useUserInfoStore()
    const editorClipInfo = useEditorClipInfoStore()

    if (clipSource == 'TwitchClip' && editorClipInfo.broadcasterName) {
      return editorClipInfo.broadcasterName
    } else if (userInfoStore.userName) {
      return userInfoStore.userName
    } else {
      return 'Your Name'
    }
  },
}
