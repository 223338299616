import { ref } from 'vue'

const intervals = {
  month: {
    key: 'month',
    value: 'month',
    label: 'Monthly',
  },
  year: {
    key: 'year',
    value: 'year',
    label: 'Yearly',
  },
} as const

export type SubscriptionIntervals = keyof typeof intervals

const defaultInterval = 'year'

const interval = ref<SubscriptionIntervals>(defaultInterval)

export const useSubscriptionIntervals = () => {
  return { interval, intervals }
}
