<template>
  <svg width="24" height="24" viewBox="0 0 24 24" class="fill-current" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.0472 16.4729C18.8435 14.9996 17.9936 14.2496 17.9936 10.1879C17.9936 6.46838 16.0943 5.14323 14.531 4.49963C14.3233 4.41432 14.1279 4.21838 14.0646 4.0051C13.7904 3.07182 13.0216 2.24963 11.9997 2.24963C10.9779 2.24963 10.2086 3.07229 9.93724 4.00604C9.87396 4.22166 9.67849 4.41432 9.47083 4.49963C7.90568 5.14416 6.00818 6.46463 6.00818 10.1879C6.00584 14.2496 5.15599 14.9996 3.95224 16.4729C3.45349 17.0832 3.89037 17.9996 4.76271 17.9996H19.2415C20.1091 17.9996 20.5432 17.0804 20.0472 16.4729Z"
      stroke="current-color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15 17.9988V18.7488C15 19.5444 14.6839 20.3075 14.1213 20.8701C13.5587 21.4327 12.7956 21.7488 12 21.7488C11.2043 21.7488 10.4413 21.4327 9.87868 20.8701C9.31607 20.3075 9 19.5444 9 18.7488V17.9988"
      stroke="current-color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
