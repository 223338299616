<script lang="ts" setup>
import { useUserInfoStore } from '@/store/user/userInfo'
import { ref } from 'vue'
import DashboardHamburgerButton from '@/areas/dashboard/layout/nav/DashboardHamburgerButton.vue'
import { useRouter } from 'vue-router'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { tiers } from '@/enums/tiers'
import IconSaxHappyemoji from '@/components/Icons/iconsax/IconSaxHappyemoji.vue'
import DashboardAccountInfoFlyout from '@/areas/dashboard/layout/nav/DashboardAccountInfoFlyout.vue'
import IconSaxDiagram from '@/components/Icons/iconsax/IconSaxDiagram.vue'
import IconSaxHomeTrendUp from '@/components/Icons/iconsax/IconSaxHomeTrendUp.vue'
import IconSaxShare from '@/components/Icons/iconsax/IconSaxShare.vue'
import IconSaxClipboard from '@/components/Icons/iconsax/IconSaxClipboard.vue'
import IconSaxExportSquare from '@/components/Icons/iconsax/IconSaxExportSquare.vue'
import IconSaxMobile from '@/components/Icons/iconsax/IconSaxMobile.vue'
import DashboardNotificationFlyout from '@/areas/dashboard/layout/nav/DashboardNotificationFlyout.vue'
import IconSaxCalendarEdit from '@/components/Icons/iconsax/IconSaxCalendarEdit.vue'
import DashboardNavigationItem from '@/areas/dashboard/layout/nav/DashboardNavigationItem.vue'
import { upgradeDialog } from '@/helpers/upgradeDialog'
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import SilverPlanButton from '@/components/Account/Upgrade/SilverPlanButton.vue'
import GoldPlanButton from '@/components/Account/Upgrade/GoldPlanButton.vue'
import DashboardNavigationBanner from '@/areas/dashboard/layout/nav/DashboardNavigationBanner.vue'
import IconSaxVideoVertical from '@/components/Icons/iconsax/IconSaxVideoVertical.vue'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'
import compatabilityChecker from '@/services/compatabilityChecker'
import IconSaxGhost from '@/components/Icons/iconsax/IconSaxGhost.vue'

const userInfo = useUserInfoStore()

const isOpen = ref(false)
useRouter().afterEach(() => {
  isOpen.value = false
})

function openUpgradeDialog() {
  upgradeDialog.open({
    tier: 100,
    reason: 'Dashboard navigation banner',
    subtitle: undefined,
    title: undefined,
  })
}

const hasClipGPT = false
// const hasClipGPT = useFeatureFlagVariantEnabled('clip-gpt', 'clipgpt')
</script>

<template>
  <section
    v-click-outside="() => (isOpen = false)"
    :class="isOpen ? 'max-lg:min-h-screen' : 'max-lg:min-h-0'"
    class="flex flex-col overflow-hidden transition-all lg:h-full lg:max-h-screen lg:justify-between lg:overflow-visible"
  >
    <header class="flex items-center justify-between">
      <RouterLink
        :to="{ name: dashboardRouteNames.dashboard }"
        class="flex flex-nowrap items-center gap-4 overflow-x-hidden rounded-lg px-4 py-2 text-brand-state-text-primary lg:w-full"
      >
        <img alt="StreamLadder branding" class="h-8 w-8" height="192" src="/images/logo.png" width="192" />
        <span class="flex items-center gap-2">
          <span class="h-4 text-lg font-semibold leading-[1]">StreamLadder</span>
          <GoldPlanButton class="pointer-events-none h-4 w-4" small v-if="userInfo.tier === tiers.GOLD" />
          <SilverPlanButton class="pointer-events-none h-4 w-4" small v-else-if="userInfo.tier === tiers.SILVER" />
          <span class="flex rounded bg-white px-1 py-0.5 text-xs font-semibold uppercase text-indigo-500" v-else>
            {{ userInfo.plan }}
          </span>
        </span>
      </RouterLink>

      <DashboardHamburgerButton v-model="isOpen" />
    </header>
    <section
      :class="isOpen ? 'max-lg:max-h-[150vh]' : 'max-lg:max-h-0'"
      class="flex flex-auto flex-col gap-4 transition-all lg:gap-6"
    >
      <nav class="flex flex-col gap-2 pt-6">
        <RouterLink :to="{ name: dashboardRouteNames.editor }" class="mb-4 rounded-lg">
          <GradientButton
            class="w-full !justify-start gap-4 rounded-lg !px-4 !font-semibold tracking-wide transition-all hover:overflow-visible"
          >
            <IconSaxAdd class="shrink-0 [&>path]:stroke-2" />
            <span>Create clip</span>
          </GradientButton>
        </RouterLink>
        <DashboardNavigationItem :route="dashboardRouteNames.dashboard">
          <IconSaxHomeTrendUp class="shrink-0" />
          <span>Dashboard</span>
        </DashboardNavigationItem>

        <DashboardNavigationItem :route="dashboardRouteNames.editor">
          <IconSaxClipboard class="shrink-0" />
          <span>Clip Editor</span>
        </DashboardNavigationItem>

        <DashboardNavigationItem :route="dashboardRouteNames.montageMaker">
          <IconSaxVideoVertical class="shrink-0" />
          <span class="flex w-full items-center justify-between gap-1">
            Montage Maker
          </span>
        </DashboardNavigationItem>

        <DashboardNavigationItem v-if="hasClipGPT" :route="dashboardRouteNames.clipGPT.root">
          <IconSaxGhost class="shrink-0" />
          <span class="flex w-full items-center justify-between gap-1">
            ClipGPT
            <span class="rounded-lg border-[1px] border-white/20 p-1 text-[9px]">BETA</span>
          </span>
        </DashboardNavigationItem>

        <DashboardNavigationItem href="https://emotes.streamladder.com/" target="_blank">
          <IconSaxHappyemoji class="shrink-0" />
          <span class="flex w-full items-center justify-between gap-1">
            Emote Maker
            <IconSaxExportSquare class="ml-auto h-4 w-4" />
          </span>
        </DashboardNavigationItem>

        <DashboardNavigationItem :route="dashboardRouteNames.contentPublisher.root">
          <IconSaxCalendarEdit class="shrink-0" />
          <span> Content Publisher </span>
        </DashboardNavigationItem>

        <DashboardNavigationItem :route="dashboardRouteNames.analytics">
          <IconSaxDiagram class="shrink-0" />
          <span> Analytics </span>
        </DashboardNavigationItem>

        <DashboardNavigationItem :route="dashboardRouteNames.videos">
          <IconSaxMobile class="shrink-0" />
          <span>My Videos</span>
        </DashboardNavigationItem>
        <DashboardNavigationItem :route="dashboardRouteNames.socials">
          <IconSaxShare class="shrink-0" />
          <span>Social Media Connections</span>
        </DashboardNavigationItem>
      </nav>

      <nav class="relative mt-auto flex flex-col gap-0.5">
        <div class="flex flex-col gap-1 sm:flex-row lg:flex-col">
          <button v-if="userInfo.tier < tiers.GOLD" class="flex w-full overflow-hidden" @click="openUpgradeDialog">
            <DashboardNavigationBanner
              call-to-action="Upgrade now"
              class="bg-[#663DF0] text-sm"
              title="Get access to all features on StreamLadder"
            />
          </button>
        </div>

        <DashboardNotificationFlyout />
        <!--<DashboardNavigationItem :route="dashboardRouteNames.whatsNew">
          <IconSaxInformation class="shrink-0" />
          <span>What’s new?</span>
        </DashboardNavigationItem>-->
      </nav>

      <DashboardAccountInfoFlyout />
    </section>
  </section>
</template>

<style lang="scss" scoped></style>
