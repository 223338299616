<script setup lang="ts">
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import { computed, ref, watch } from 'vue'

const props = defineProps<{
  currentTime: number
}>()

const seekerEl = ref<HTMLElement | null>(null)
const { useMsToPx } = useTimelineStore()
const left = useMsToPx(computed(() => props.currentTime), false)
</script>

<template>
  <div
    ref="seekerEl"
    class="absolute"
    :style="{
      left: left + 'px',
    }"
  >
    <slot />
  </div>
</template>

<style scoped lang="scss"></style>
