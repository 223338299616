import { defineStore, acceptHMRUpdate } from 'pinia'

interface EditorTimelineState {
  zoomLevel: number
}

export const useEditorTimelineStore = defineStore('editorTimeline', {
  state: (): EditorTimelineState => {
    return {
      zoomLevel: 100,
    }
  },
  getters: {},
  actions: {},
})

// Allows hot-reloading of the store
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useEditorTimelineStore, import.meta.hot))
}
