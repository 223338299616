<script setup lang="ts">
import { useEditorMainStore } from '@/store/editor/editorMain'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { onKeyStroke } from '@vueuse/core'
import { interpolate, isInputTarget, msToTime } from '@/components/Editor/Timeline/helpers'
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import IconSaxAddCircle from '@/components/Icons/iconsax/IconSaxAddCircle.vue'
import SimpleTooltip from '@/components-v2/data-display/SimpleTooltip.vue'
import TimelineZoomSlider from '@/components/Editor/Timeline/TimelineZoomSlider.vue'
import IconSaxMinusCirlce from '@/components/Icons/iconsax/IconSaxMinusCirlce.vue'
import IconCustomMaximize from '@/components/Icons/Normalized/IconCustomMaximize.vue'
import IconSaxPause from '@/components/Icons/iconsax/IconSaxPause.vue'
import IconSaxPlay from '@/components/Icons/iconsax/IconSaxPlay.vue'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import IconSaxScissor from '@/components/Icons/iconsax/IconSaxScissor.vue'
import AutoSplitterButton from '@/components/Editor/RemoveParts/AutoSplitterButton.vue'
import { computed } from 'vue'
import IconSaxStatus from '@/components/Icons/iconsax/IconSaxStatus.vue'
import VolumeButton from '@/components/Editor/VolumeButton.vue'
import IconSaxArrowSquareDown from '@/components/Icons/iconsax/IconSaxArrowSquareDown.vue'
import IconSaxArrowLeftStop from '@/components/Icons/iconsax/IconSaxArrowLeftStop.vue'
import IconSaxArrowRightStop from '@/components/Icons/iconsax/IconSaxArrowRightStop.vue'

const editorMainStore = useEditorMainStore()
const editorFocusStore = useEditorFocusStore()
const editorVideoStore = useEditorVideoStore()

const { zoomToLevel, zoomLevel, fitToTimeline, _autoScroll, open } = useTimelineStore()

const togglePlaying = () => {
  _autoScroll.value = 'idle'
  editorVideoStore.playing = !editorVideoStore.playing
  editorVideoStore.preservedPaused = !editorVideoStore.preservedPaused
}

const splitSegment = () => {
  editorFocusStore.splitFocusModel(editorVideoStore.getExactTime())
}

const pullLeft = () => {
  editorFocusStore.pullModel(editorVideoStore.getExactTime(), 'left')
}

const pullRight = () => {
  editorFocusStore.pullModel(editorVideoStore.getExactTime(), 'right')
}

onKeyStroke(['['], (e) => {
  // if event is not triggered on a html content editable or text input prevent
  if (isInputTarget(e)) return
  e.preventDefault()
  pullLeft()
})

onKeyStroke([']'], (e) => {
  // if event is not triggered on a html content editable or text input prevent
  if (isInputTarget(e)) return
  e.preventDefault()
  pullRight()
})

const nudgeZoom = (_nudgeAmount: number) => {
  const zoomedLevel = Math.min(Math.max(zoomLevel.value + _nudgeAmount, 0), 1)
  zoomToLevel(zoomedLevel, editorVideoStore.getExactTime())
}

const nudgeAmount = computed(() => {
  const minStep = 5 / 100
  const minDuration = 10000
  const maxStep = 20 / 100
  const maxDuration = 60000
  const duration = editorVideoStore.duration
  const step = Math.max(0, Math.min(1, duration - minDuration / maxDuration - minDuration))
  return interpolate(minStep, maxStep, step)
})

const fitCrop = () => {
  fitToTimeline(editorMainStore.trimmedStartTime, editorMainStore.trimmedEndTime)
}
</script>

<template>
  <div class="flex items-center justify-between py-2">
    <div class="hidden flex-row flex-wrap items-center text-company-primary-500 md:flex md:basis-4/6">
      <AutoSplitterButton v-if="open && false" />
      <SimpleTooltip v-if="open">
        <template v-slot:trigger>
          <button @click="splitSegment" class="btn-ghost btn-sm btn flex flex-row gap-2 rounded-lg text-black md:block">
            <IconSaxScissor class="p-[1px]" />
            <span class="hidden md:inline">Split</span>
          </button>
        </template>
        <template v-slot:content>
          <span>Split clip at current time (s)</span>
        </template>
      </SimpleTooltip>
      <SimpleTooltip v-if="open">
        <template v-slot:trigger>
          <button @click="pullRight" class="btn-ghost btn-sm btn flex flex-row gap-2 rounded-lg text-black md:block">
            <IconSaxArrowRightStop class="p-[1px]" />
          </button>
        </template>
        <template v-slot:content>
          <span>Trim to the left</span>
        </template>
      </SimpleTooltip>
      <SimpleTooltip v-if="open">
        <template v-slot:trigger>
          <button @click="pullLeft" class="btn-ghost btn-sm btn flex flex-row gap-2 rounded-lg text-black md:block">
            <IconSaxArrowLeftStop class="p-[1px]" />
          </button>
        </template>
        <template v-slot:content>
          <span>Trim to the right</span>
        </template>
      </SimpleTooltip>
      <SimpleTooltip v-if="editorFocusStore.canDeleteFocusModel">
        <template v-slot:trigger>
          <button
            @click="editorFocusStore.deleteFocusModel"
            class="btn-ghost btn-sm btn flex flex-row gap-2 rounded-lg text-black md:block"
          >
            <IconSaxTrash class="p-[1px]" />
          </button>
        </template>
        <template v-slot:content>
          <span>Delete selected</span>
        </template>
      </SimpleTooltip>
    </div>

    <div class="flex h-8 flex-row items-center justify-center gap-1 leading-none md:basis-2/6 md:gap-2">
      <!--      <button @click="nudge(-nudgeAmount)" class="flex h-6 w-6 text-gray-300">-->
      <!--        <Backward />-->
      <!--      </button>-->
      <!--      <button @click="nudge(nudgeAmount)" class="flex h-6 w-6 text-gray-300">-->
      <!--        <Forward />-->
      <!--      </button>-->
      <SimpleTooltip>
        <template v-slot:trigger>
          <button class="flex h-6 w-6 text-black" @click="togglePlaying">
            <IconSaxStatus class="h-6 w-6 animate-spin" v-if="editorVideoStore.waiting"></IconSaxStatus>
            <IconSaxPlay class="h-6 w-6 fill-current" v-else-if="!editorVideoStore.playing" />
            <IconSaxPause type="bold" class="h-6 w-6" v-else />
          </button>
        </template>
        <template v-slot:content>
          <span v-if="!editorVideoStore.playing">Play (space)</span>
          <span v-else>Pause (space)</span>
        </template>
      </SimpleTooltip>

      <span class="min-w-[5ch] font-semibold text-gray-800">
        {{ msToTime(editorVideoStore._currentTime * 1000) }}
      </span>
      <span class="hidden text-gray-500 md:inline">/</span>
      <SimpleTooltip>
        <template #trigger>
          <span class="hidden min-w-[5ch] font-normal text-gray-500 min-[380px]:inline">
            {{ msToTime(editorVideoStore._duration * 1000) }}
          </span>
        </template>
        <template #content>
          <span>Final video duration</span>
          <span class="font-semibold text-gray-200"> {{ msToTime(editorVideoStore.duration) }}</span>
        </template>
      </SimpleTooltip>
    </div>

    <div class="audio relative flex flex-row flex-wrap justify-end text-black md:basis-4/6">
      <SimpleTooltip>
        <template v-slot:trigger>
          <VolumeButton />
        </template>
        <template v-slot:content>
          <span v-if="editorVideoStore.muted">Unmute (m)</span>
          <span v-else>Mute (m)</span>
        </template>
      </SimpleTooltip>
      <SimpleTooltip v-if="open">
        <template v-slot:trigger>
          <button
            @click="fitCrop"
            class="btn-ghost btn-sm btn flex flex-row gap-2 rounded-lg font-normal text-black md:block"
          >
            <IconCustomMaximize class="p-[1px]" />
            <span class="hidden md:inline">Fit</span>
          </button>
        </template>
        <template v-slot:content>
          <span>Zoom to fit clip to timeline</span>
        </template>
      </SimpleTooltip>

      <div class="relative flex items-center gap-0 md:basis-auto" v-if="open">
        <SimpleTooltip>
          <template v-slot:trigger>
            <button
              class="btn-ghost btn-sm btn flex flex-row gap-2 rounded-lg text-black md:block"
              :class="{
                'opacity-40': zoomLevel === 1,
              }"
              @click="nudgeZoom(nudgeAmount)"
            >
              <IconSaxMinusCirlce class="h-6 w-6 p-[1px]" />
            </button>
          </template>
          <template v-slot:content>
            <span>Zoom out</span>
          </template>
        </SimpleTooltip>
        <TimelineZoomSlider />
        <!--        <input-->
        <!--          type="range"-->
        <!--          v-model.number="editorTimelineStore.zoomLevel"-->
        <!--          min="0"-->
        <!--          max="100"-->
        <!--          id="zoomSlider"-->
        <!--          class="zoom-range hidden h-6 w-full appearance-none bg-transparent p-0 focus:shadow-none focus:outline-none focus:ring-0 md:block"-->
        <!--        />-->
        <SimpleTooltip>
          <template v-slot:trigger>
            <button
              class="btn-ghost btn-sm btn flex flex-row gap-2 rounded-lg text-black md:block"
              :class="{
                'opacity-40': zoomLevel === 0,
              }"
              @click="nudgeZoom(nudgeAmount * -1)"
            >
              <IconSaxAddCircle class="h-6 w-6 p-[1px]" />
            </button>
          </template>
          <template v-slot:content>
            <span>Zoom in</span>
          </template>
        </SimpleTooltip>
      </div>
      <SimpleTooltip>
        <template v-slot:trigger>
          <button class="btn-ghost btn-sm btn flex flex-row gap-2 rounded-lg text-black md:block" @click="open = !open">
            <IconSaxArrowSquareDown
              class="h-6 w-6 transform p-[1px] transition"
              :class="{
                'rotate-180': !open,
              }"
            />
          </button>
        </template>
        <template v-slot:content>
          <span v-if="open">Close</span>
          <span v-else>Open</span>
        </template>
      </SimpleTooltip>
    </div>
  </div>
</template>

<style scoped>
.btn.btn-ghost {
  background-color: transparent;
  border-color: transparent;
  padding-inline: 4px;
  gap: 0.25rem;
  display: flex;
  font-weight: 400;
}

.btn.btn-ghost.btn-info {
  background-color: #f2fcfe;
  color: #3b82f6;
}

.btn.btn-ghost.btn-success {
  background-color: #f0fdf4;
  color: #097c55;
}

.btn.btn-ghost:hover {
  background: #f3f4f6;
  border-color: transparent;
  color: black;
}

#zoomSlider {
  direction: rtl;
}

button > svg {
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}
</style>
