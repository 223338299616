<script setup lang="ts">
import { onMounted, ref } from 'vue'
import ConvertInBackgroundAlert from '@/components/Clip2TikTok/ConvertInBackgroundAlert.vue'
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { posthog } from 'posthog-js'

const props = defineProps<{
  isWebcodecRenderer?: boolean
}>()

function random<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)]
}

const features = [
  {
    title: 'Content Publisher',
    subtitle:
      'Upload, schedule and post your videos to both TikTok and YouTube Shorts in advance with our scheduler tool.',
    href: 'https://streamladder.com/features/content-planner',
    video: {
      src: 'videos/new-spotlight/content-publisher.mp4',
      class: 'aspect-[1720/1080]',
      width: 1720,
      height: 1080,
    },
  },
  {
    title: 'Save Templates',
    subtitle: 'Save 80% of your time by creating templates and create clips super fast.',
    // href: 'https://www.streamladder.com/features/save-templates',
    video: {
      src: 'videos/new-spotlight/save-templates.mp4',
      class: 'aspect-[1784/1080]',
      width: 1784,
      height: 1080,
    },
  },
  {
    title: 'AI Automated Captions',
    subtitle:
      'Generate captions for your video with just the click of a button. Our AI will generate the words and add ' +
      'highlights and emojis, which you can edit however you want.',
    href: 'https://streamladder.com/features/automated-captions',
    video: {
      src: 'videos/new-spotlight/captions.mp4',
      class: 'aspect-[1784/1080]',
      width: 1784,
      height: 1080,
    },
  },
  {
    title: 'Brand New Editor',
    subtitle:
      'We have a brand new editor with a fresh look and feel. It’s easier to use and has more features than ever before.',
    video: {
      src: 'videos/new-spotlight/new-editor.mp4',
      class: 'aspect-[1784/1080]',
      width: 1784,
      height: 1080,
    },
  },
  {
    title: 'Add Animated Giphy Stickers',
    subtitle:
      'Bring your clips to life with animated Giphy stickers.',
    href: 'https://app.streamladder.com/upgrade',
    video: {
      src: 'videos/new-spotlight/gifs.mp4',
      class: 'aspect-[1784/1080]',
      width: 1784,
      height: 1080,
    },
  },
  {
    title: 'EmoteMaker',
    subtitle: 'Bring your chat to life with your own animated emotes created from your Twitch clips.',
    href: 'https://emotes.streamladder.com/',
    video: {
      src: 'videos/spotlight/emote-maker.mp4',
      class: 'aspect-[1784/1080]',
      width: 1784,
      height: 1080,
    },
  },
];

const gradients = [
  'bg-gradient-to-br from-red-400 to-purple-500',
  'bg-gradient-to-br from-red-400 to-amber-400',
  'bg-gradient-to-br from-blue-600 to-emerald-300',
  'bg-gradient-to-br from-violet-300 to-blue-700',
  'bg-gradient-to-br from-cyan-300 to-purple-600',
  'bg-gradient-to-br from-emerald-700 to-lime-300',
  'bg-gradient-to-br from-pink-400 to-yellow-400',
];

const feature = ref(random(features));
const gradient = ref(random(gradients));

onMounted(() => {

  if (props.isWebcodecRenderer) {

    feature.value = random(features.filter(f => f.title !== 'Render in the Background'));

    setInterval(() => {
      feature.value = random(features.filter(f => f.title !== 'Render in the Background'))
      gradient.value = random(gradients);
    }, 10_000);
  }
});

const loaded = ref(false)

const showElgatoContest = useFeatureFlagEnabled('elgato-contest');

const openContestInNewTab = () => {
  posthog.capture('Elgato Banner Clicked', { location: 'Generate Page' });
  window.open('https://streamladder.com/contest2024/?utm_source=sl-website&utm_campaign=contest2024&utm_content=cta-bottom', '_blank');
};
</script>

<template>
  <template v-if="showElgatoContest">
    <article class="grid h-full min-h-screen grid-cols-10" :class="gradient">
      <div class="col-span-10 h-full bg-white p-16 md:p-8 lg:col-span-4 xl:col-span-3 2xl:px-20 2xl:pb-20">
        <div class="flex h-full flex-col justify-center gap-8">
          <slot />
        </div>
      </div>
      <div class="col-span-10 lg:col-span-6 xl:col-span-7">
        <div class="relative flex justify-center items-center bg-white w-full h-full">
          <img
            v-if="showElgatoContest"
            @click="openContestInNewTab"
            class="hidden lg:block absolute object-center inset-auto w-full h-full max-w-[1500px] object-cover cursor-pointer"
            src="/images/contest/feature-showcase.png"
            alt="Elgato Contest Promo Banner"
          />
          <img
            v-if="showElgatoContest"
            @click="openContestInNewTab"
            class="block lg:hidden absolute object-center inset-auto w-full h-full max-w-[1500px] object-cover cursor-pointer"
            src="/images/contest/banner.png"
            alt="Elgato Contest Promo Banner"
          />
        </div>
      </div>
    </article>
  </template>
  <template v-else>
    <article class="grid h-full min-h-screen grid-cols-10" :class="gradient">
      <div class="col-span-10 h-full bg-white p-16 md:p-8 lg:col-span-4 xl:col-span-3 2xl:px-20 2xl:pb-20">
        <div class="flex h-full flex-col justify-center gap-8">
          <slot />

          <ConvertInBackgroundAlert v-if="!isWebcodecRenderer" />
        </div>
      </div>
      <div class="col-span-10 p-6 md:p-12 lg:col-span-6 xl:col-span-7 2xl:p-36">
        <div class="flex h-full flex-col items-center justify-center gap-16">
          <header class="flex flex-col items-center justify-start gap-6">
            <div
              class="rounded-full bg-opacity-20 bg-gradient-to-br from-stone-600/20 via-stone-500/20 to-stone-600/20 px-6 py-3"
            >
              <span class="text-lg font-medium leading-none text-white">Feature spotlight</span>
            </div>
            <h1 class="text-center text-6xl font-bold text-white">{{ feature.title }}</h1>
            <p class="text-center text-xl font-normal leading-snug text-white" style="text-wrap: balance">
              {{ feature.subtitle }}
            </p>
            <a
              v-if="'href' in feature"
              target="_blank"
              :href="feature.href"
              class="btn-xl btn-outline btn-accent btn uppercase"
            >
              Discover more
            </a>
          </header>

          <div class="grid max-h-[35vh] max-w-[calc(35vh*calc(16/9))] place-items-center">
            <div :class="feature.video.class" class="relative w-full">
              <div
                :class="[feature.video.class, { 'opacity-0': loaded }]"
                class="skeleton !absolute inset-0 rounded-lg bg-white/50 backdrop-blur transition-opacity"
              />
              <video
                class="relative max-w-full rounded-lg shadow-2xl transition-opacity"
                :class="{ 'opacity-0': !loaded }"
                @canplay.once="loaded = true"
                :src="feature.video.src"
                :width="feature.video.width"
                :height="feature.video.height"
                autoplay
                loop
                muted
                playsinline
                disablePictureInPicture
                disableRemotePlayback
              />
            </div>
          </div>
        </div>
      </div>
    </article>
  </template>
</template>

<style scoped lang="scss">
// Stolen from DaisyUI 4.x.x
.skeleton {
  --tw-bg-opacity: 1;
  will-change: background-position;
  animation: skeleton 1.8s ease-in-out infinite;
  background-image: linear-gradient(
    105deg,
    transparent 0%,
    transparent 40%,
    #f2f2f233,
    transparent 60%,
    transparent 100%
  );
  background-size: 200% auto;
  background-repeat: no-repeat;
  background-position-x: -50%;
}

@media (prefers-reduced-motion) {
  .skeleton {
    animation-duration: 15s;
  }
}

@keyframes skeleton {
  0% {
    background-position: 150%;
  }

  to {
    background-position: -50%;
  }
}
</style>
