<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { posthog, type Survey } from 'posthog-js'
import { Textarea } from '@/components/ui/textarea'
import { Button } from '@/components/ui/button'

const emit = defineEmits<{
  (event: 'next'): void
  (event: 'cancel'): void
  (event: 'previous'): void
}>()

const surveyName = 'Cancellation Survey - Other';

const survey = ref<Survey | null>(null);

const description = ref<string>('');

onMounted(() => {
  posthog.getActiveMatchingSurveys((allSurveys) => {
    const cancellationSurvey = allSurveys.find((survey) => survey.name === surveyName);
    if (cancellationSurvey) {
      survey.value = cancellationSurvey;
    }
  });
});

const sendResponse = () => {

  if (survey.value) {
    posthog.capture("survey sent", {
      $survey_id: survey.value.id,
      $survey_response: description.value
    });
  }

  emit('next');
};
</script>

<template>
  <div class="flex p-4 lg:p-10">
    <div class="relative flex flex-col gap-8 items-center text-center p-8 w-full">

      <div class="max-w-xl gap-2 flex flex-col">
        <h3 class="text-2xl sm:text-3xl lg:text-4xl">
          What made you decide to cancel?
        </h3>
        <p class="font-light">
          Your specific reasons can help us improve.
        </p>
      </div>

      <div class="flex flex-col gap-4 w-full max-w-xl">
        <div class="relative">
          <Textarea
            v-model.trim="description"
            class="p-4 w-full h-full min-h-[200px] resize-none"
            placeholder="Type your feedback here"
          ></Textarea>
          <span class="absolute bottom-2 right-2 text-brand-state-text-placeholder">
            {{ description?.length }} / {{ 5000 }}
          </span>
        </div>
      </div>

      <div class="flex w-full justify-between absolute bottom-0">
        <Button
          variant="outline"
          @click="emit('previous')">
          Back
        </Button>
        <Button
          variant="primary"
          :disabled="description.length < 5"
          @click="sendResponse">
          Next
        </Button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>