<template>
  <Feed
    v-for="fragment in editableFragments.filter(frag => frag.feedData)"
    :key="fragment.key"
    :fragment="fragment"
    :containerWidth="containerWidth"
    :containerHeight="containerHeight"
  />
</template>
<script lang="ts">
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { defineComponent, computed } from 'vue'
import { useEditorMainStore } from '@/store/editor/editorMain'
import { useUserTemplatesStore } from '@/store/user/userTemplates'
import { useEditorFocusStore, FocusTypes } from '@/store/editor/editorFocus'
import { useUserInfoStore } from '@/store/user/userInfo'
import Feed from '@/components/Editor/Feed.vue'
import { useLayoutsStore } from '@/store/entity-system/useLayoutsStore'

export default defineComponent({
  components: { Feed },
  props: {
    containerWidth: {
      type: Number,
      default: 0,
    },
    containerHeight: {
      type: Number,
      default: 0,
    },
    updateVideoData: {
      type: Boolean,
      default: true,
    },
    autoPlay: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const editorMainStore = useEditorMainStore()
    const templatesStore = useUserTemplatesStore()
    const feedStore = useEditorFeedDataStore()
    const focusStore = useEditorFocusStore()
    const userInfoStore = useUserInfoStore()
    const layoutsStore = useLayoutsStore()

    const enabled = computed(() => !!editorMainStore.layoutId && userInfoStore.hasLoadedUserInfo)

    return {
      editorMainStore,
      templatesStore,
      feedStore,
      focusStore,
      enabled,
      customLayout: layoutsStore.selectById(editorMainStore.layoutId),
    }
  },
  data() {
    return {
      isVideoLoaded: false,
      layout: null,
      //
      videoFragments: [],
    }
  },
  computed: {
    FocusTypes() {
      return FocusTypes
    },
    editableFragments() {
      return this.feedStore.fragmentArray.filter((fragment) => {
        return fragment.feedOptions.draggable || fragment.feedOptions.resizable
      })
    },
  },
  methods: {
    initializeForCustomLayout() {
      if (this.customLayout) {
        this.feedStore.initializeForCustomLayout(this.customLayout.id)
      }
    },
  },
  mounted() {
    // Sync templateId with query param
    this.editorMainStore.templateId = this.editorMainStore.templateId || this.$route.query.savedTemplate as string
    this.$router.replace({ name: this.$route.name as string, query: { ...this.$route.query, savedTemplate: this.editorMainStore.templateId }})
  },
  watch: {
    customLayout: {
      handler(layout) {
        if (layout && this.isVideoLoaded) {
          this.feedStore.initializeForCustomLayout(layout.id)
        }
      },
      immediate: true,
    },
  }
})
</script>
