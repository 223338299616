<script setup lang="ts">
import IconSaxDocumentDownload from '@/components/Icons/iconsax/IconSaxDocumentDownload.vue'
import QrIcon from '@/components/Icons/QrIcon.vue'
import IconSaxPlay from '@/components/Icons/iconsax/IconSaxPlay.vue'
import type { VideoResultDto } from '@/apis/streamladder-api/model'
import logging from '@/logging'
import { computed, ref } from 'vue'
import { IconDotsVertical, IconTrash } from '@tabler/icons-vue'
import { useConfirmDialog } from '@vueuse/core'
import ConfirmDialog from '@/components/Dialog/Confirm/ConfirmDialog.vue'
import { deleteApiVideosId, getGetApiVideosQueryKey } from '@/apis/streamladder-api/videos/videos'
import { useQueryClient } from '@tanstack/vue-query'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import SendIcon from '@/components/Icons/SendIcon.vue'
import IconSaxVideoVertical from '@/components/Icons/iconsax/IconSaxVideoVertical.vue'
import DynamicPlanButton from '@/components/Account/Upgrade/DynamicPlanButton.vue'
import { Button } from '@/components/ui/button'
import { RouterLink } from 'vue-router'
import { addDays, formatDistanceToNow } from 'date-fns'
import { MAX_VIDEO_AGE } from '@/data/maxVideoAge'

const props = defineProps<{
  playing?: boolean
  video?: VideoResultDto
}>()

const emit = defineEmits<{
  (name: 'requestQr'): void
}>()

function onDownloadClick() {
  const { video } = props
  if (video) {
    logging.trackEvent('Video Result Downloaded', {
      location: 'Dashboard My Clips Page',
    })
  }
}

const isRemoving = ref<boolean>(false)

const contextMenuOpen = ref<boolean>(false)

const queryClient = useQueryClient()
const { isRevealed, reveal, confirm, cancel, onConfirm } = useConfirmDialog()

const timeLeft = computed(() => {

  if (!props.video?.createdAt) return

  const createdAtDate = new Date(props.video?.createdAt)
  const removeDate = addDays(createdAtDate, MAX_VIDEO_AGE)
  return formatDistanceToNow(removeDate, { addSuffix: false })
})

onConfirm(async () => {
  if (!props.video?.id) return
  isRemoving.value = true
  logging.trackEvent('Video Result Deleted', {
    location: 'Dashboard My Clips Page',
  })

  await deleteApiVideosId(props.video.id)
  await queryClient.invalidateQueries(getGetApiVideosQueryKey())

  isRemoving.value = false
})
</script>

<template>
  <article
    class="layer-2 group relative flex flex-col gap-1 rounded-xl p-4 text-sm transition-all"
    :class="isRemoving ? 'pointer-events-none opacity-75' : ''"
  >
    <header class="flex gap-2 text-lg text-rose-400 md:text-xl" v-if="isRemoving">
      <div class="spinner spinner-border border-px !h-4 !w-4 border" />
      <span class="mb-2 min-h-[1em] truncate p-0 text-sm">Deleting...</span>
    </header>
    <header class="flex" v-else>
      <div class="flex w-full flex-col overflow-hidden">
        <span class=" text-xs text-gray-400">Available for {{ timeLeft }}</span>
        <h3 class="mb-0 mt-2 h-8 truncate p-0 text-lg md:text-sm" :title="video?.title!">
          {{ video?.title! }}
        </h3>
      </div>
      <div class="relative ml-auto" v-click-outside="() => (contextMenuOpen = false)" v-if="video?.id">
        <button class="btn-ghost btn-square btn-sm btn rounded p-1" @click="contextMenuOpen = !contextMenuOpen">
          <IconDotsVertical />
        </button>

        <div
          :class="contextMenuOpen ? 'translate-y-0' : 'invisible translate-y-2 scale-90 opacity-0'"
          class="absolute -right-1 top-full z-[1] flex flex-col rounded-xl shadow-lg transition-all"
        >
          <div
            class="layer-1 absolute right-0 h-3 w-3 origin-bottom-left -translate-x-1/2 -translate-y-0.5 -rotate-45 transform select-none rounded"
          />
          <ol class="layer-1 relative z-[1] rounded-xl" tabindex="0">
            <li>
              <button
                class="flex w-full items-center justify-start gap-2 whitespace-nowrap rounded-xl px-4 py-2 text-left text-rose-500 hover:bg-rose-500 hover:text-white"
                @click="reveal"
              >
                <IconTrash />
                <span>Delete video</span>
              </button>
            </li>
          </ol>
        </div>
      </div>
    </header>

    <div
      class="layer-1 relative flex aspect-[9/16] h-full w-full cursor-pointer flex-col items-center justify-center rounded-lg"
    >
      <slot />

      <div class="pointer-events-none absolute inset-0 flex flex-col items-center justify-center">
        <button
          :class="{ 'invisible scale-90 opacity-0': playing }"
          class="bg-zinc-700/75 rounded-full p-3 transition-all"
        >
          <IconSaxPlay class="h-8 w-8 fill-current stroke-0 text-white" />
        </button>
      </div>
    </div>

    <div class="mt-2 flex flex-col gap-2">
      <Button
        :as="RouterLink"
        data-tip="Publish video"
        :to="video?.id ? { name: dashboardRouteNames.contentPublisher.video, params: { videoId: video.id } } : ''"
        :class="{ 'pointer-events-none': !video }"
        variant="gradient"
        class="h-10 gap-2"
      >
        Schedule or post
        <SendIcon class="h-4 fill-current" />
      </Button>
      <Button
        :as="RouterLink"
        :to="video?.id ? { name: 'montage-maker' } : ''"
        :class="{ 'pointer-events-none': !video }"
        variant="outline"
        class="h-10 gap-2"
        @click="logging.trackEvent('Clicked Edit as Montage')"
      >
        Edit as Montage
        <IconSaxVideoVertical class="h-4" />
        <DynamicPlanButton :size="4" small feature="montage-maker" />
      </Button>
      <Button
        @click="emit('requestQr')"
        :class="{ 'pointer-events-none': !video }"
        class="h-10 gap-2"
        variant="outline"
      >
        Send to mobile
        <QrIcon class="h-4" />
      </Button>
      <Button
        as="a"
        @click="onDownloadClick"
        :href="video?.resultUrl"
        :download="`streamladder-${video?.title}.mp4`"
        :class="{ 'pointer-events-none': !video }"
        variant="outline"
        class="h-10 gap-2"
      >
        Download
        <IconSaxDocumentDownload class="h-4" />
      </Button>
    </div>

    <teleport to="body">
      <ConfirmDialog
        v-if="isRevealed"
        @confirm="confirm"
        @cancel="cancel"
        title="Delete video"
        message="Are you sure you want to delete this video?"
      />
    </teleport>
  </article>
</template>

<style scoped lang="scss"></style>
