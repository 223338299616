<script lang="ts" setup>
import TimelineRoot from '@/modules/SLTimeline/TimelineRoot.vue'
import TimelineContainer from '@/modules/SLTimeline/TimelineContainer.vue'
import Timeline from '@/modules/SLTimeline/Timeline.vue'
import { useProvideTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { computed, ref, watch, reactive } from 'vue'
import TimeLinePlayHead from '@/modules/SLTimeline/TimeLinePlayHead.vue'
import { useRafFn, watchDeep, useElementSize } from '@vueuse/core'
import TimelineHeader from '@/areas/editor/timeline/header/TimelineHeader.vue'
import TimeLineDialsTrack from '@/modules/SLTimeline/TimeLineDialsTrack.vue'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { onKeyStroke } from '@vueuse/core/index'
import TimelineFooter from '@/areas/editor/timeline/footer/TimelineFooter.vue'
import { isInputTarget, isForeignTarget, isInputTargetOrRangeSelection } from '@/areas/editor/timeline/helpers'
import CaptionTrack from '@/areas/editor/timeline/tracks/captions/CaptionTrack.vue'
import ZoomTrack from '@/areas/editor/timeline/tracks/zooms/ZoomTrack.vue'
import SegmentTrack from '@/areas/editor/timeline/tracks/segments/SegmentTrack.vue'
import StickerTrack from '@/areas/editor/timeline/tracks/stickers/StickerTrack.vue'
import FlatSegmentsTrack from '@/areas/editor/timeline/tracks/segments/FlatSegmentsTrack.vue'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { minBy } from 'lodash-es'
import { useStripPunctuation } from '@/components/Captions/useStripPunctuation'

const props = defineProps<{
  step: 'layout' | 'crop' | 'editor'
  defaultOpen?: boolean
}>()

const videoStore = useVideoStore()
const editorFocusStore = useEditorFocusStore()

const totalDurationMs = computed(() => videoStore._duration * 1000)

const updateFunction = () => {
  updateWordsTimer(videoStore.getExactTime())
}

const { pause, resume } = useRafFn(updateFunction, { immediate: false })

const resumeVideoAndTimeline = () => {
  resume()
  videoStore.playing = true
}

const pauseVideoAndTimeline = () => {
  pause()
  videoStore.playing = false
}

watchDeep(() => editorFocusStore.focus, () => {
  updateWordsTimer(videoStore.getExactTime() + 0.001)
  autoScroll(videoStore.getExactTime() + 0.001 - pxToMs(0.5 * containerWidth.value))
})

let wasPlaying = false
const commitSeek = (ms: number, scrubbing = false) => {
  videoStore.scrubbing = scrubbing
  if (scrubbing && videoStore.playing) {
    wasPlaying = true
    videoStore.playing = false
  }
  if (!scrubbing && wasPlaying) {
    videoStore.playing = true
    wasPlaying = false
  }
  currentTimeMs.value = ms
  videoStore._currentTime = ms / 1000
}

const editorCaptionsStore = useEditorCaptionsStore()
const words = computed(() => editorCaptionsStore.groupedCaptions.flatMap(c => c.words))

const strip = useStripPunctuation()
const {
  zoomLevel,
  autoScroll, pxToMs, containerWidth,
  open: timelineOpen, 
} = useProvideTimelineStore(totalDurationMs, {
  minVisibleMs: 500,
  onSeek: commitSeek,
  openDefault: props.defaultOpen,
  maxTimelineWidth: computed(() => {
    const shortest = minBy(words.value, w => strip.value(w).length)
    if (shortest) {
      const width = shortest.text.length * 24 + 75
      return width / (shortest.end - shortest.start) * totalDurationMs.value
    } else {
      return null
    }
  })
})

watch(
  () => videoStore.playing,
  (value) => {
    value && !videoStore.scrubbing && !videoStore.preservedPaused
      ? resumeVideoAndTimeline()
      : pauseVideoAndTimeline()
  },
)

onKeyStroke(['Backspace', 'Delete', 'Del'], (e) => {
  if (isInputTargetOrRangeSelection(e)) return
  editorFocusStore.deleteFocusModel()
})

onKeyStroke(['s'], async (e) => {
  if (isInputTarget(e)) return
  editorFocusStore.splitFocusModel(currentTimeMs.value)
})

onKeyStroke([' '], (e) => {
  // if event is not triggered on a html content editable or text input prevent
  if (isInputTarget(e)) return
  if (isForeignTarget(e)) return

  e.preventDefault()
  videoStore.preservedPaused = !videoStore.preservedPaused
  videoStore.playing = !videoStore.playing
})

onKeyStroke(['m'], (e) => {
  if (isInputTarget(e)) return
  videoStore.muted = !videoStore.muted
})

const currentTimeMs = ref(0)
watch(() => videoStore.currentTimeMs, () => {
  currentTimeMs.value = videoStore.currentTimeMs
})

const updateWordsTimer = (currentTime: number) => {
  if (videoStore.seeking) return
  if (currentTime !== currentTimeMs.value) {
    currentTimeMs.value = currentTime
    if (!videoStore.scrubbing) {
      autoScroll(currentTimeMs.value)
    }
  }
}

const dialStepSize = computed(() => {
  return zoomLevel.value < 0.5
    ? 500
    : 1000
})

const dialNumberInterval = computed(() => {
  return zoomLevel.value < 0.5
    ? 10
    : 5
})

const showZoomTrack = computed(() => {
  return props.step === 'editor'
})

const inputBindings = reactive(videoStore.currentTimeBindings)

const container = ref<InstanceType<typeof TimelineContainer>>()
const { height: timelineHeight } = useElementSize(computed(() => container.value?.$el))
</script>

<template>
  <TimelineRoot
    class="flex layer-1 flex-col border-surface-input-border transition-[height,_margin-bottom]"
    :style="{ marginBottom: timelineOpen ? '0' : `-${timelineHeight}px` }"
  >
    <div
      v-if="!timelineOpen"
      class="relative flex h-2 md:h-1.5 origin-bottom transform border-transparent transition-[height] hover:h-2.5"
    >
      <div class="absolute inset-0 bg-zinc-100" />
      <div :style="{ width: inputBindings.progress + '%' }" class="absolute inset-y-0 left-0 bg-company-primary-100" />
      <input
        v-model="inputBindings.model"
        class="absolute inset-0 w-full overflow-hidden opacity-0 cursor-pointer"
        type="range"
        v-bind="inputBindings"
        @mousedown="inputBindings.onMouseDown"
        @mouseup="inputBindings.onMouseUp"
      />
    </div>
    <TimelineHeader />
    <TimelineContainer
      ref="container"
      class="relative flex w-full flex-col overflow-y-hidden border-t border-surface-panel bg-[#FBF9F9] dark:bg-gray-950 cursor-col-resize outline-0"
    >
      <Timeline class="relative flex md:h-[13.5rem] flex-grow flex-col gap-2 pb-2 outline-0">
        <TimeLineDialsTrack
          :duration="videoStore._duration"
          :number-interval="dialNumberInterval"
          :stepSize="dialStepSize"
          class="h-6 w-full pt-2 text-xs font-normal shrink-0"
        />
        <div class="flex flex-col justify-center gap-2 h-full">
          <StickerTrack />
          <CaptionTrack />
          <ZoomTrack v-if="showZoomTrack" />
          <SegmentTrack />
          <FlatSegmentsTrack v-if="false" />
        </div>
        <div class="flex h-4 w-full shrink-0" />
        <TimeLinePlayHead
          :current-time="currentTimeMs"
          class="no-drag absolute pointer-events-none inset-y-0 w-[3px] box-content px-[12px] z-20 flex flex-col h-full -translate-x-1/2 transform"
        >
          <div class="h-full w-full bg-gray-950 dark:bg-gray-200" />
        </TimeLinePlayHead>
      </Timeline>
    </TimelineContainer>
    <TimelineFooter v-if="timelineOpen" class="md:hidden" />
  </TimelineRoot>
</template>

<style lang="scss" scoped></style>
