<template>
  <template v-if="!loading">
    <template v-if="stickers.length > 0">
      <div class="mb-2">
        <b>My library</b>
      </div>
      <div
        class="grid gap-2"
        :class="hasThreeColumnLayout ? 'grid-cols-2 lg:grid-cols-[repeat(auto-fill,_minmax(150px,_1fr))]' : 'grid-cols-2'"
      >
        <custom-sticker-selectable :sticker="sticker" v-for="sticker in stickers" :key="sticker.id" />
      </div>
    </template>
    <div v-else>
      <p class="text-company-primary opacity-70">
        Oh no, your library is empty! Upload a file to add your first sticker!
      </p>
    </div>
  </template>

  <div class="flex w-full flex-col items-center justify-center" v-else>
    <lottie-animation class="w-1/4 grayscale" url="/lottie/hour-glass-loading.json" :auto-play="true" />
    <p>Loading your custom stickers...</p>
  </div>
</template>
<script>
import CustomStickerSelectable from './CustomStickerSelectable.vue'
import customStickerService from '../../../services/customStickerService'
import LottieAnimation from '../../LottieAnimation.vue'
import customStickerEvents from '../../../events/customStickerEvents'
import EventBus from '../../../eventBus'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'

export default {
  components: { CustomStickerSelectable, LottieAnimation },
  data() {
    return {
      loading: true,
      stickers: [],
    }
  },
  setup() {
    return {
      hasThreeColumnLayout: useFeatureFlagVariantEnabled('three-column-layout', 'wide'),
    }
  },
  methods: {
    async load() {
      this.loading = true
      this.stickers = await customStickerService.load()
      this.loading = false
    },
    deleteSticker(id) {
      this.stickers = this.stickers.filter((sticker) => sticker.id !== id)
    },
  },
  async mounted() {
    EventBus.$on(customStickerEvents.CUSTOM_STICKER_UPLOADED, this.load)
    EventBus.$on(customStickerEvents.STICKER_DELETED, this.deleteSticker)
    await this.load()
  },
  beforeUnmount() {
    EventBus.$off(customStickerEvents.CUSTOM_STICKER_UPLOADED, this.load)
    EventBus.$off(customStickerEvents.STICKER_DELETED, this.deleteSticker)
  },
}
</script>
