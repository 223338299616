import { computed, type Ref, ref, watch } from 'vue'
import { cover } from '@/modules/SLMovable/helpers/fit'
import { useElementSize, useLocalStorage } from '@vueuse/core'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'

// Hardcoded reserved space for UI elements
const reservedSpaceX = 50
const reservedSpaceY = 100

// Hardcoded max workspace size
const maxHeight = Infinity
const maxWidth = 1280

const previewScaleOptions = [
  { value: 'auto', label: 'Fit to window' },
  { value: 3, label: '300%' },
  { value: 2.5, label: '250%' },
  { value: 2, label: '200%' },
  { value: 1.75, label: '175%' },
  { value: 1.5, label: '150%' },
  { value: 1.25, label: '125%' },
  { value: 1, label: '100%' },
  { value: 0.75, label: '75%' },
  { value: 0.5, label: '50%' },
  { value: 0.33, label: '33%' },
  { value: 0.25, label: '25%' },
] as const

const previewScale = useLocalStorage<(typeof previewScaleOptions)[number]['value']>('previewScale', 'auto')

export function useWorkspaceSizes(workspace: Ref<HTMLElement | null>) {

  const { width: workspaceWidth, height: workspaceHeight } = useElementSize(workspace)

  const availableWorkspaceWidth = computed(() => Math.min(workspaceWidth.value - reservedSpaceX, maxWidth))

  // Disgusting fix for a behavior I spent 2 hours trying to understand; when collapsing the sidebar, the workspace 
  // height changes by 15px. This is a workaround to prevent the workspace from resizing incorrectly when the sidebar is
  // collapsed. TODO: Spend more time on this later.
  const availableWorkspaceHeight = ref(Math.min(workspaceHeight.value - reservedSpaceY, maxHeight))
  watch(workspaceHeight, (newValue, oldValue) => {
    if (Math.abs(newValue - oldValue) !== 15) {
      availableWorkspaceHeight.value = Math.min(newValue - reservedSpaceY, maxHeight)
    }
  })

  const previewWidth = 1080;
  const previewHeight = 1920;
  const { width: cropperWidth, height: cropperHeight } = cover(
    { width: 1920, height: 1080 },
    { width: previewWidth, height: previewHeight })

  const totalWidth = cropperWidth + previewHeight
  const scale = computed(() => Math.min(availableWorkspaceWidth.value / totalWidth, availableWorkspaceHeight.value / cropperHeight))
  
  const { isLayoutsStep } = useEditorStep()
  const autoPreviewScale = computed(() => {
    return Math.min(availableWorkspaceWidth.value / previewWidth, availableWorkspaceHeight.value / previewHeight)
  })
  
  const previewScalePreference = computed(() => {
    if (previewScale.value === 'auto') {
      return autoPreviewScale.value * devicePixelRatio
    } else {
      return previewScale.value
    }
  })
  
  return {
    autoPreviewScale: autoPreviewScale,
    previewScaleSetting: computed<typeof previewScale['value']>({
      get() {
        return previewScale.value
      },
      set(value) {
        previewScale.value = value
      }
    }),
    previewScaleSettingString: computed({
      get() {
        return previewScale.value.toString()
      },
      set(value: string) {
        if (value === 'auto') {
          previewScale.value = value
        } else {
          const number = Number(value)
          if (previewScaleOptions.some(option => option.value === number)) {
            previewScale.value = number as typeof previewScale.value
          }
        }
      }
    }),
    previewScaleOptions: computed(() => {
      return previewScaleOptions.filter(option => {
        if (option.value === 'auto') {
          return true
        } else {
          return (option.value / devicePixelRatio) * previewWidth <= (availableWorkspaceWidth.value) * 1.5
        }
      })
    }),
    previewScaleLabel: computed(() => {
      if (previewScale.value === 'auto') {
        return `Fit (${((autoPreviewScale.value * devicePixelRatio) * 100).toFixed(1)}%)`
      } else {
        return Math.round(previewScale.value * 100) + '%'
      }
    }),
    
    cropperWidth: computed(() => {
      if (isLayoutsStep.value) {
        return cropperWidth * scale.value
      } else {
        return 0
      }
    }),
    cropperHeight: computed(() => {
      if (isLayoutsStep.value) {
        return cropperHeight * scale.value
      } else {
        return 0
      }
    }),

    previewWidth: computed(() => {
      if (isLayoutsStep.value) {
        return previewWidth * scale.value
      } else {
        return previewWidth * (previewScalePreference.value / devicePixelRatio)
      }
    }),

    previewHeight: computed(() => {
      if (isLayoutsStep.value) {
        return previewHeight * scale.value
      } else {
        return previewHeight * (previewScalePreference.value / devicePixelRatio)
      }
    }),
  }
}
