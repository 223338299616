<template>
  <div class="fixed right-4 bottom-2 min-w-1/4 max-w-[400px] transform" style="z-index: 999999">
    <transition-group
      class="flex flex-col-reverse items-end"
      enter-active-class="animate__animated animate__fadeInRight"
      leave-active-class="animate__animated animate__fadeOutRight pointer-events-none"
      name="list"
      tag="span"
    >
      <toast-message
        v-for="toast in toasts.slice(-5)"
        :id="toast.id"
        :key="toast.id"
        :status="toast.status"
        :subtitle="toast.subtitle"
        :title="toast.title"
        :view="toast.view"
        :viewTitle="toast.viewTitle"
        :retry="toast.retry"
        :retryTitle="toast.retryTitle"
        :timeout="toast.timeout"
        :removeMessage="removeMessage"
        @click="removeMessage"
      />
    </transition-group>
  </div>
</template>
<script setup lang="ts">
import ToastMessage from './ToastMessage.vue'
import EventBus from '../../eventBus'
import ToastEvents from '../../events/toastEvents'

import { ref, onMounted, onBeforeUnmount } from 'vue'
import type { Toast } from '@/Hooks/useToast'

type Message = Toast & { id: number, status: 'success' | 'error' | 'info' | 'premium' };

const toasts = ref<Message[]>([]);

let nextId = 0;

const addToast = (type: 'success' | 'error' | 'info' | 'premium', toast: Toast) => {

  if (type === 'premium' && toasts.value.some((m) => m.status === 'premium')) {
    return;
  }

  const id = nextId++;

  toasts.value.push({ ...toast, id, status: type });

  if (toast.timeout !== 0 && toast.timeout !== null) {
    setTimeout(() => removeMessage(id), toast.timeout);
  }
};

const removeMessage = (id: number) => {
  toasts.value = toasts.value.filter((m) => m.id !== id);
};

const addInfoMessage = (toast: Toast) => {
  if (toast.timeout === undefined) toast.timeout = 8000;
  addToast( 'info', toast);
};

const addErrorMessage = (toast: Toast) => {
  if (toast.timeout === undefined) toast.timeout = 16000;
  addToast( 'error', toast);
};

const addSuccessMessage = (toast: Toast) => {
  if (toast.timeout === undefined) toast.timeout = 8000;
  addToast( 'success', toast);
};

const addPremiumMessage = (toast: Toast) => {

  if (toast.timeout === undefined) {
    toast.timeout = 16000;
  }

  // If there is already a premium message, don't add another one
  if (toasts.value.some((m) => m.status === 'premium')) {
    return;
  }

  addToast( 'premium', toast);
};

const dismissPremiumToasts = () => {
  toasts.value = toasts.value.filter((m) => m.status !== 'premium');
};

onMounted(() => {
  EventBus.$on(ToastEvents.TOAST_PREMIUM, addPremiumMessage);
  EventBus.$on(ToastEvents.TOAST_ERROR, addErrorMessage);
  EventBus.$on(ToastEvents.TOAST_SUCCESS, addSuccessMessage);
  EventBus.$on(ToastEvents.TOAST, addInfoMessage);
  EventBus.$on(ToastEvents.DISMISS_PREMIUM_TOASTS, dismissPremiumToasts);
});

onBeforeUnmount(() => {
  EventBus.$off(ToastEvents.TOAST_PREMIUM, addPremiumMessage);
  EventBus.$off(ToastEvents.TOAST_ERROR, addErrorMessage);
  EventBus.$off(ToastEvents.TOAST_SUCCESS, addSuccessMessage);
  EventBus.$off(ToastEvents.TOAST, addInfoMessage);
  EventBus.$off(ToastEvents.DISMISS_PREMIUM_TOASTS, dismissPremiumToasts);
});
</script>

<style scoped>
:root {
  --animate-duration: 0.35s;
  --animate-delay: 0.35s;
  --animate-repeat: 1;
}

.animate__animated {
  -webkit-animation-duration: 0.35s;
  animation-duration: 0.35s !important;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.animate__fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}
</style>
