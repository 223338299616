<script setup lang="ts">
import { ref } from 'vue'
import NotificationBell from '@/components/Icons/NotificationBell.vue'
import Spinner from '@/components/Icons/Spinner.vue'
import DropdownMenu from '@/components-v2/modules/DropdownMenu/DropdownMenu.vue'
import { useNotifications, NotificationQueryKey } from '@/modules/NotificationService/NotificationService'
import DashboardNotificationFlyoutItem from '@/areas/dashboard/layout/nav/DashboardNotificationFlyoutItem.vue'
import { queryClient } from '@/services/QueryClient'

const { notifications, isLoading } = useNotifications()

const isOpen = ref(false)

function onChange(open: boolean) {
  if (open)  {
    queryClient.invalidateQueries(NotificationQueryKey)
  }
}
</script>

<template>
  <DropdownMenu class="relative h-10 w-8" v-model="isOpen" arrow @update:model-value="onChange">
    <template #button="{ ...props }">
      <button v-bind="props" type="button" class="flex h-full w-full items-center justify-center">
        <span class="relative text-white">
          <NotificationBell />
          <span
            v-if="notifications.length > 0"
            class="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xxs text-white"
          >
            {{ notifications.length }}
          </span>
        </span>
      </button>
    </template>
    <template #menu="{ ...props }">
      <ul v-bind="props" class="flex w-80 flex-col gap-2 text-company-primary">
        <template v-if="notifications.length > 0">
          <DashboardNotificationFlyoutItem
            v-for="notification in notifications"
            :key="notification.id"
            :notification="notification"
            :videos="[]"
          />
        </template>
        <template v-else>
          <p class="mb-0 flex min-w-[300px] items-center justify-center p-4 text-company-primary">
            <template v-if="isLoading">
              <Spinner class="h-8 w-8 animate-spin" />
            </template>
            <template v-else>You are all up to date with your notifications!</template>
          </p>
        </template>
      </ul>
    </template>
  </DropdownMenu>
</template>
