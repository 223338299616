import { useEditorMainStore } from '@/store/editor/editorMain'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { useEditorStickersStore } from '@/store/editor/editorStickers'
import { useStickersStore } from '@/store/entity-system/useStickersStore'

export const useResetEditor = () => {
  const mainStore = useEditorMainStore()
  const clipInfoStore = useEditorClipInfoStore()
  const feedDataStore = useEditorFeedDataStore()
  const captionsStore = useEditorCaptionsStore()
  const focusedStore = useEditorFocusStore()
  const editorStickersStore = useEditorStickersStore()
  const stickersStore = useStickersStore()

  return (exclude: ('stickers' | 'captions' | 'focus' | 'feedData' | 'clipInfo' | 'main')[] = []) => {
    if (!exclude.includes('main')) mainStore.$reset()
    if (!exclude.includes('clipInfo')) clipInfoStore.$reset()
    if (!exclude.includes('feedData')) feedDataStore.$reset()
    if (!exclude.includes('captions')) captionsStore.$reset()
    if (!exclude.includes('focus')) focusedStore.$reset()
    if (!exclude.includes('stickers')) {
      editorStickersStore.$reset()
      stickersStore.$reset()
    }
  }
}
