<script setup lang="ts">
import IconSaxEdit from '@/components/Icons/iconsax/IconSaxEdit.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { useFocus } from '@vueuse/core'
import { computed, ref } from 'vue'
import { usePutUpdateEmail } from '@/apis/streamladder-accounts/user/user'
import * as Sentry from '@sentry/vue'
import { cn } from '@/lib/utils'

const input = ref<HTMLInputElement>()
const { focused } = useFocus(input)
const userInfo = useUserInfoStore()

const errorText = ref<string | null>(null)
const invalidFormat = ref(false)

const {
  isLoading,
  isSuccess,
  mutate: updateEmail,
  isError,
} = usePutUpdateEmail({
  mutation: {
    onSuccess(data) {
      const { email } = data as unknown as { email: string }
      userInfo.updateEmail(email)
      errorText.value = null
    },
    onError(error) {
      if (error?.response?.data === 'User with this email already exists') {
        errorText.value = 'User with this email already exists.'
      } else {
        Sentry.captureException(error)
      }
    },
  },
})

function onSubmitEmail() {
  if (isValidEmail(email.value)) {
    updateEmail({ data: { email: email.value } })
  } else {
    invalidFormat.value = true
  }
}

function isValidEmail(email: string) {
  // Taken from https://v2.vuejs.org/v2/cookbook/form-validation
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

function onChange() {
  isError.value = false
  isSuccess.value = false
  invalidFormat.value = false
}

const email = ref(userInfo.email)

const minEmailLength = 3
const maxEmailLength = 254

const lengthIsOutOfRange = computed(
  () => (email.value?.length > maxEmailLength) || email.value?.length < minEmailLength
)

const hasError = computed(() => isError.value || lengthIsOutOfRange.value || invalidFormat.value)
</script>

<template>
  <form @submit.prevent="onSubmitEmail">
    <label class="flex max-w-[50ch] flex-col">
      <span class="text-lg">Email address</span>
      <span
        class="mt-4"
        :class="
          cn(
            'flex h-12 w-full rounded-md border border-surface-input-border bg-surface-panel-100 text-base text-brand-state-text-primary ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-brand-state-text-placeholder hover:shadow-hover hover:ring-2 hover:ring-brand-state-hover-border focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-state-active-border disabled:cursor-not-allowed disabled:opacity-50',
            { 'outline-rose-400': hasError }
          )
        "
      >
        <span class="layer-1 flex flex-auto items-center rounded-l-lg">
          <input
            ref="input"
            type="text"
            v-model="email"
            @change="onChange"
            class="flex-auto select-all bg-transparent px-3 py-2 outline-none"
          />
          <span
            class="px-3 text-xs font-light"
            :class="{ 'text-rose-400': lengthIsOutOfRange }"
            v-if="email !== userInfo.email"
          >
            {{ email.length }} / {{ maxEmailLength }}
          </span>
        </span>

        <button
          v-if="isLoading"
          disabled
          type="button"
          class="layer-1 flex items-center gap-2 rounded-r-lg px-3 py-2 hover:bg-zinc-200 disabled:pointer-events-none disabled:opacity-75 dark:hover:bg-zinc-900"
        >
          <span class="spinner spinner-border !h-4 !w-4 !border-2" />
          Saving
        </button>

        <span
          v-else-if="email === userInfo.email && !focused"
          class="layer-1 flex cursor-pointer items-center gap-2 rounded-r-lg px-3 py-2 hover:bg-zinc-200 disabled:pointer-events-none disabled:opacity-75 dark:hover:bg-zinc-900"
        >
          <IconSaxEdit />
        </span>

        <button
          :disabled="lengthIsOutOfRange"
          type="submit"
          v-else
          class="layer-1 flex cursor-pointer items-center gap-2 rounded-r-lg px-3 py-2 hover:bg-zinc-200 disabled:pointer-events-none disabled:opacity-75 dark:hover:bg-zinc-900"
        >
          Save
        </button>
      </span>
    </label>

    <ul v-if="hasError" class="mx-4 my-2 flex list-disc flex-col gap-1 text-rose-400">
      <li v-if="isError">
        {{ errorText || 'Something went wrong while saving your new email address. Please try again later.' }}
      </li>
      <li v-if="invalidFormat">Your email address is not in a valid format.</li>
      <li v-if="email.length < minEmailLength">
        Your email address should not be shorter than {{ minEmailLength }} characters.
      </li>
      <li v-if="email.length > maxEmailLength">
        Your email address should not be longer than {{ maxEmailLength }} characters.
      </li>
    </ul>
  </form>
</template>

<style scoped lang="scss"></style>
