<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
import { noop } from 'lodash-es'
import EventBus from '@/eventBus'
import authEvents from '@/events/authEvents'
import LoginDialog from '@/pages/auth/LoginDialog.vue'

const onClose = ref<() => void>(noop)
const isOpen = ref(false)
const title = ref<string | undefined>()

function onOpenDialog(payload?: { callback: () => void, title?: string }) {
  isOpen.value = true
  onClose.value = payload?.callback ?? noop
  title.value = payload?.title ?? undefined
}

function onCloseDialog() {
  isOpen.value = false
  onClose.value()
  onClose.value = noop
  title.value = undefined
}

function onToggle(open: boolean) {
  if (open) {
    onOpenDialog()
  } else {
    onCloseDialog()
  }
}

onMounted(() => {
  EventBus.$on(authEvents.OPEN_LOGIN_DIALOG, onOpenDialog)
  EventBus.$on(authEvents.CLOSE_LOGIN_DIALOG, onCloseDialog)
})

onUnmounted(() => {
  EventBus.$off(authEvents.OPEN_LOGIN_DIALOG, onOpenDialog)
  EventBus.$off(authEvents.CLOSE_LOGIN_DIALOG, onCloseDialog)
})
</script>

<template>
  <LoginDialog v-model:open="isOpen" :title="title" @update:open="onToggle" />
</template>

<style scoped lang="scss">

</style>
