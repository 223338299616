<template>
  <div class="panel relative min-h-[70vh] items-center justify-center">
    <div class="mx-auto flex w-full flex-col lg:w-1/2">
      <h2 class="mb-8 text-3xl font-bold text-company-primary">What is your reason for saying goodbye?</h2>

      <div class="mb-4 flex flex-col gap-2 space-y-2">
        <div
          v-for="(reason, i) in cancelReasons"
          :key="i"
          class="flex cursor-pointer items-center space-x-2"
          @click="selectedReason = reason"
        >
          <div
            :class="{ 'bg-company-primary text-white': reason === selectedReason }"
            class="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border border-gray-300"
          >
            <check-mark-icon v-if="reason === selectedReason" class="h-3 w-3 fill-current" />
          </div>
          <p class="mb-0">{{ reason.value }}</p>
        </div>
      </div>

      <div :class="{ 'opacity-30': selectedReason?.value !== 'Other' }" class="mb-4 flex w-full flex-col">
        <textarea
          v-model="description"
          :disabled="selectedReason?.value !== 'Other'"
          class="placeholder-text-gray-700 rounded bg-gray-100 p-4"
          placeholder="Please tell us why you want to unsubscribe"
          rows="3"
          type="text"
        />
      </div>

      <div class="flex w-full justify-between">
        <router-link
          :to="{ name: 'Invoicing' }"
          class="cursor-pointer rounded-full px-4 py-2 text-gray-700 hover:bg-gray-200"
        >
          Back
        </router-link>

        <button class="btn-primary btn" @click="goToNextPage">Continue</button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'pinia'
import { useUserInfoStore } from '@/store/user/userInfo'
import CheckMarkIcon from '@/components/Icons/CheckMarkIcon.vue'
import { useUserSubscriptionStore } from '@/store/user/userSubscription'
import LottieAnimation from '@/components/LottieAnimation.vue'
import TwBaseDialog from '@/components/Dialog/TwBaseDialog.vue'
import { useRouter } from 'vue-router'
import Feature from '@/components-v2/lists/Feature.vue'
import settings from '@/data/settings'
import CrossIcon from '@/components/Icons/CrossIcon.vue'
import { useProductsStore } from '@/store/products'
import { postCancelReasons } from '@/queries/n8n'

export default {
  components: { CrossIcon, Feature, TwBaseDialog, LottieAnimation, CheckMarkIcon },
  computed: {
    settings() {
      return settings
    },
    ...mapState(useUserInfoStore, ['isAuthenticated', 'email', 'plan', 'userId']),
    ...mapState(useUserSubscriptionStore, ['subscription', 'canDowngrade']),
    ...mapState(useProductsStore, ['products']),
    nextPagePath() {
      if (!this.selectedReason) return 'ReviewCancel'
      if (this.selectedReason.mixPanelReason === 'too_expensive') {
        // check if user can downgrade
        return this.canDowngrade ? 'OfferDowngrade' : 'ReviewCancel'
      }
      return this.selectedReason?.pathName
    },
    mixPanelReason() {
      if (!this.selectedReason) return 'other'

      return this.selectedReason.mixPanelReason
    },
  },
  setup() {
    const router = useRouter()
    return { router }
  },
  methods: {
    async goToNextPage() {
      if (this.mixPanelReason === 'other' && this.description) {
        await postCancelReasons(this.description)
      }

      await this.router.push({ name: this.nextPagePath, query: { reason: this.mixPanelReason } })
    },
  },
  data() {
    return {
      showModal: false,
      error: false,
      cancelReasons: [
        {
          key: 'too_expensive',
          value: 'I want to save money',
          pathName: 'OfferDowngrade',
          mixPanelReason: 'too_expensive',
        },
        {
          key: 'not_using',
          value: "I don't use it (enough)",
          pathName: 'OfferFeatures',
          mixPanelReason: 'unused_features',
        },
        {
          key: 'problems',
          value: "It's not working properly",
          pathName: 'TechnicalDifficultiesFeedback',
          mixPanelReason: 'technical_problems',
        },
        {
          key: 'stopped_streaming',
          value: 'I stopped streaming',
          pathName: 'ReviewCancel',
          mixPanelReason: 'stopped_streaming',
        },
        {
          key: '1_month_trial',
          value: 'I wanted to try it for 1 month',
          pathName: 'ReviewCancel',
          mixPanelReason: 'trail_1_month',
        },
      ],
      description: '',
      selectedReason: null,
    }
  },
  async mounted() {
    // shuffle the reasons
    this.cancelReasons = this.cancelReasons.sort(() => Math.random() - 0.5)
    this.cancelReasons.push({
      key: 'other',
      value: 'Other',
      pathName: 'ReviewCancel',
      mixPanelReason: 'other',
    })
  },
}
</script>
