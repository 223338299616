<template>
  <app-page :has-footer="false">
    <template v-slot:content>
      <div v-if="!loading" class="flex w-full flex-col">
        <router-view />

        <!-- 
          Only available when the user chooses the option:
            - Not enough use out of StreamLadder
            - StreamLadder costs to much
        -->

        <!-- Review the changes the user is about to make -->
        <!-- <review-cancel @pauseSubscription="onPauseSubscriptionHandler"
                       @cancelSubscription="onCancelSubscriptionHandler"
                       @downgradeConfirm="onDowngradeConfirmHandler"
                       :is-processing="isProcessing"
                       :wants-downgrade="wantsDowngrade"
                       :is-paypal-subscription="isPaypalSubscription"
                       v-if="step === 4"
        /> -->
      </div>
      <div v-else class="flex h-full w-full flex-col items-center justify-center">
        <!-- <spinner class="w-12 h-12"/> -->
      </div>
    </template>
  </app-page>
</template>

<script>
import { useUserSubscriptionStore } from '@/store/user/userSubscription'
import AppPage from '@/components/AppPage.vue'
import { mapState } from 'pinia/dist/pinia'
import Spinner from '@/components/Icons/Spinner.vue'

export default {
  components: {
    Spinner,
    AppPage,
  },
  data() {
    return {
      step: 2,
      loading: true,
      isProcessing: false,
      pickedReason: null,
      nextPlanStatus: null, // Paused | Cancelled | Downgraded
      cancelDescription: null,
      isPaypalSubscription: false,
      wantsDowngrade: false,
    }
  },
  async mounted() {
    const userSubscriptionStore = useUserSubscriptionStore()
    await userSubscriptionStore.fetchSubscriptionData()
    this.loading = false
  },
}
</script>
