import { sum, range } from 'lodash-es'
import { capitalize, ref, computed } from 'vue'
import { subDays } from 'date-fns'

const filters = [
  { label: 'Last week', sinceDays: 7 },
  { label: 'Last four weeks', sinceDays: 28 },
  { label: 'Last two months', sinceDays: 60 },
]

export function useMetrics() {
  const followers = computed(() => newGrowthIndex('followers', currentFollowers.value, followersGainedPerDay.value))
  const likes = computed(() => newGrowthIndex('likes', currentLikes.value, likesGainedPerDay.value))
  const videos = computed(() => newGrowthIndex('videos', currentVideos.value, videosGainedPerDay.value))

  const labels = computed(() => {
    return range(sinceDays.value).map((daysAgo) => {
      return subDays(new Date(), daysAgo).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
    })
  })

  const sinceDays = ref(filters[0].sinceDays)
  const metrics = computed(() => [followers.value, likes.value, videos.value])

  return {
    metrics: metrics,
    filters: filters,
    sinceDays: sinceDays,
    xAxisLabels: labels,
  }
}

function computeGrowth(from: number, to: number) {
  return (to - from) / from
}

const percentageFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

const compactFormatter = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  compactDisplay: 'short',
  maximumFractionDigits: 2,
})

function newGrowthIndex(name: string, current: number, dataPoints: number[]) {
  const previous = current - sum(dataPoints)
  const ratio = computeGrowth(previous, current)
  const percentage = ratio > 0 ? '+' + percentageFormatter.format(ratio) : percentageFormatter.format(ratio)

  const dataset = [
    {
      name: capitalize(name),
      series: dataPoints,
      type: 'bar',
      color: '#E1057C',
    },
  ]

  return {
    title: capitalize(name),
    current: compactFormatter.format(current),
    ratio,
    percentage,
    dataset,
  }
}

const currentFollowers = ref<number>(5_120)
const currentLikes = ref<number>(420_000)
const currentVideos = ref<number>(1_156)

// get daily growth for the past 7 days
const followersGainedPerDay = ref([20, 20, 100, 120, 75, 80, 90])
const likesGainedPerDay = ref([35, 0, 900, 2_200, 1_500, 1_800, 1_900])
const videosGainedPerDay = ref([0, 0, 1, 3, 1, 2, 5])
