<template>
  <div
    v-for="(value, key) in data"
    :key="key"
    class="item flex w-full flex-wrap border-b-2 bg-gray-200/5 text-sm last:border-b-0"
  >
    <div class="key text-md font-bold uppercase">{{ key }}</div>
    <div class="value flex-grow pl-1">
      <!-- Check if the value is an object, and if so, render it recursively -->
      <DynamicVisualizer v-if="isObject(value)" :data="value" />
      <!-- If not an object, display the value -->
      <span v-else-if="isDate(value)" class="">{{ formatDistanceToNow(Date.parse(value)) }} ago</span>
      <span v-else class="">{{ value }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { formatDistanceToNow } from 'date-fns'

export default defineComponent({
  props: {
    data: {
      type: Object as { [key: string]: any },
      required: true,
    },
  },
  methods: {
    formatDistanceToNow,
    isDate(value: any): boolean {
      // format "2023-11-14T08:43:29.305494+00:00"
      const regex =
        /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/
      return typeof value === 'string' && regex.test(value)
    },
    // Check if a value is an object
    isObject(value: any): boolean {
      return typeof value === 'object' && value !== null
    },
  },
})
</script>
