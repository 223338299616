<script setup lang="ts">
import IconSaxFormatCircle from '@/components/Icons/iconsax/IconSaxFormatCircle.vue'
import { type Shape, shapes } from '@/modules/CustomLayouts/@data/shapes'
import { computed, toRef } from 'vue'
import { contain } from '@/modules/SLMovable/helpers/fit'
import IconSaxLock1 from '@/components/Icons/iconsax/IconSaxLock1.vue'

const props = withDefaults(defineProps<{ shape: Shape; strokeWidth?: number }>(), { strokeWidth: 1 })
const shape = computed(() => shapes.find((s) => s.value === props.shape)!)
const strokeWidth = toRef(props, 'strokeWidth')

const outerSize = computed(() => ({
  width: 24 - 2 * strokeWidth.value,
  height: 24 - 2 * strokeWidth.value,
}))

const size = computed(() => {
  const { aspectLock } = shape.value
  if (aspectLock === null) return null
  return contain(aspectLock, outerSize.value)
})
</script>

<template>
  <IconSaxFormatCircle class="h-4 w-4" v-if="shape.value === 'freeform'" :style="{ strokeWidth }" />
  <IconSaxLock1 class="h-4 w-4" v-else-if="shape.value === 'lock'" :style="{ strokeWidth }" />
  <svg
    class="h-4 w-4"
    stroke="currentColor"
    :stroke-width="strokeWidth"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    v-else
  >
    <g v-if="shape.value === 'circle'">
      <ellipse
        fill="none"
        :cx="strokeWidth + 0.5 * outerSize.width"
        :cy="strokeWidth + 0.5 * outerSize.height"
        :rx="0.5 * size.width"
        :ry="0.5 * size.height"
      />
      <ellipse
        fill="currentColor"
        class="opacity-20"
        :cx="strokeWidth + 0.5 * outerSize.width"
        :cy="strokeWidth + 0.5 * outerSize.height"
        :rx="0.5 * size.width"
        :ry="0.5 * size.height"
      />
    </g>
    <g v-else>
      <rect
        fill="none"
        :x="strokeWidth + 0.5 * (outerSize.width - size.width)"
        :y="strokeWidth + 0.5 * (outerSize.height - size.height)"
        :width="size.width"
        :height="size.height"
        rx="2"
      />
      <rect
        fill="currentColor"
        class="opacity-20"
        :x="strokeWidth + 0.5 * (outerSize.width - size.width)"
        :y="strokeWidth + 0.5 * (outerSize.height - size.height)"
        :width="size.width"
        :height="size.height"
        rx="2"
      />
    </g>
  </svg>
</template>

<style scoped lang="scss"></style>
