import { useQuery, type UseQueryOptions } from "@tanstack/vue-query";
import { streamLadderAxios } from '@/services/axios'
import type { QueryOptions } from '@tanstack/vue-query'

interface BlogItemsResponse {
  requestId: string
  value: BlogItemsResponseValue
}

interface BlogItemsResponseValue {
  items: BlogItem[]
  totalCount: number
}

export interface BlogItem {
  htmlContent: string
  id: string
  imageUrl: string
  title: string
  author: BlogAuthor
  readTime: string
  url: string
}

export interface BlogAuthor {
  email?: string
  name: string
  pictureUrl?: string
}

export const useBlogItems = (amount: number, options: UseQueryOptions<BlogItem[]> = {}) => {
  // Cache optimization: we don't want to individually cache each blog items query's of 5 or less
  const minAmount = 5
  const amountToUse = amount < minAmount ? minAmount : amount
  return useQuery<BlogItem[]>({
    queryKey: ['useBlogItems', amountToUse],
    queryFn: async () => {
      const result = await streamLadderAxios.get<BlogItemsResponse>('/api/blogposts', {
        params: {
          Top: amountToUse,
        },
      })
      return result.data.value.items.slice(0, amount)
    },
    staleTime: 1000 * 60,
    enabled: true, // Overriding the defaultOptions from QueryClient.ts since this is a public api
    ...options,
  })
}
