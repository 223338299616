<script setup lang="ts">
import { Button } from '@/components/ui/button'
import DiscordLogo from '@/components/Icons/DiscordLogo.vue'
import settings from '@/data/settings'

const emit = defineEmits<{
  (event: 'next'): void
  (event: 'cancel'): void
  (event: 'previous'): void
}>()
</script>

<template>
  <div class="flex p-4 lg:p-10">
    <div class="relative flex flex-col gap-8 items-center text-center w-full">

      <h3 class="text-3xl lg:text-4xl max-w-xl">
        We take bugs seriously and will address them immediately.
      </h3>

      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-2">
          <div class="flex flex-col gap-2 rounded-lg border-2 border-brand-state-hover-border bg-brand-state-hover-bg p-4 max-w-xs">
            <p class="text-brand-state-link">
              Our dedicated support team is ready to fix issues as soon as possible.
            </p>
            <Button
              as="a"
              :href="settings.discordInviteUrl"
              target="_blank"
              class="flex items-center gap-2 whitespace-nowrap rounded-xl bg-indigo-500 px-3 py-2 text-white transition-all active:scale-90">
              <DiscordLogo class="w-6 h-6 mr-2" />
              Get help on Discord
            </Button>
          </div>
        </div>
      </div>
      <div class="flex w-full justify-between absolute bottom-0">
        <Button
          variant="outline"
          @click="emit('previous')">
          Back
        </Button>
        <Button
          variant="primary"
          @click="emit('next')">
          Next
        </Button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>