<script lang="ts" setup>
import type { ProductUpdate } from '@/queries/queryProductUpdates'
import { computed, defineAsyncComponent, ref } from 'vue'
import { format } from 'date-fns'

const props = defineProps<{
  productUpdate?: ProductUpdate
}>()

const attachmentFailed = ref(false)

const attachment = computed(() => props.productUpdate?.attachments?.[0])
const isVideoAttachment = computed(() => attachment.value?.content_type?.startsWith('video/'))
const isImageAttachment = computed(() => attachment.value?.content_type?.startsWith('image/'))

const VueMarkdown = defineAsyncComponent(() => import('vue-markdown-render'))
</script>

<template>
  <article class="layer-2 flex flex-row gap-2 rounded-2xl p-10">
    <div class="div">
      <span class="text-muted-foreground text-sm">{{
        productUpdate?.timestamp ? format(new Date(productUpdate.timestamp), 'd MMM yyyy') : ''
      }}</span>
    </div>
    <div class="div">
      <figure v-if="attachment && !attachmentFailed">
        <img
          v-if="isImageAttachment"
          class="w-full"
          :src="attachment.url"
          :alt="attachment.filename"
          :width="attachment.width"
          :height="attachment.height"
          crossorigin="anonymous"
          @error="attachmentFailed = true"
        />
        <video
          autoplay
          playsinline
          loop
          muted
          preload="auto"
          disableRemotePlayback
          disablePictureInPicture
          v-if="isVideoAttachment"
          class="w-full"
          :src="attachment.url.replace('cdn.discordapp.com', 'discord-productupdates.b-cdn.net')"
          :width="attachment.width"
          :height="attachment.height"
          crossorigin="anonymous"
          @error="attachmentFailed = true"
        />
      </figure>
      <div class="prose prose-zinc m-8 dark:prose-invert hover:prose-a:no-underline">
        <VueMarkdown
          v-if="productUpdate?.content"
          :source="productUpdate.content"
          :options="{
            breaks: true,
            linkify: true,
            typographer: true,
          }"
        />
      </div>
    </div>
  </article>
</template>
