import type { RouteRecordRaw } from 'vue-router'
import DashboardTemplatesOverviewPage from '@/areas/dashboard/pages/account/DashboardTemplatesOverviewPage.vue'
import DashboardLayout from '@/areas/dashboard/layout/DashboardLayout.vue'
import DashboardIndex from '@/areas/dashboard/pages/index/DashboardIndex.vue'
import DashboardEditorPage from '@/areas/dashboard/pages/DashboardEditorPage.vue'
import DashboardAnalyticsPage from '@/areas/dashboard/pages/analytics/DashboardAnalyticsPage.vue'
import DashboardContentPublisherPage from '@/areas/dashboard/pages/contentPublishing/DashboardContentPublisherPage.vue'
import DashboardVideosOverviewPage from '@/areas/dashboard/pages/DashboardVideosOverviewPage.vue'
import DashboardSocialsPage from '@/areas/dashboard/pages/account/DashboardSocialsPage.vue'
import DashboardSupportPage from '@/areas/dashboard/pages/support/DashboardSupportPage.vue'
import DashboardAccountLayout from '@/areas/dashboard/pages/account/DashboardAccountLayout.vue'
import DashboardAccountIndex from '@/areas/dashboard/pages/account/DashboardAccountIndex.vue'
import DashboardBillingPage from '@/areas/dashboard/pages/account/DashboardBillingPage.vue'
import { dashboardRouteNames } from './routeNames'
import DashboardContentPublishPage from '@/areas/dashboard/pages/contentPublishing/DashboardContentPublishPage.vue'
import DashboardMontageMakerPage from '@/areas/dashboard/pages/DashboardMontageMakerPage.vue'
import DashboardWhatsNewPage from '@/areas/dashboard/pages/support/DashboardWhatsNewPage.vue'
import DashboardEditorResultPage from '@/areas/dashboard/pages/DashboardEditorResultPage.vue'
import DashboardClipGPTPage from '@/areas/dashboard/pages/clipGPT/DashboardClipGPTPage.vue'
import DashboardClipGPTResultPage from "@/areas/dashboard/pages/clipGPT/DashboardClipGPTResultPage.vue";
import DashboardBrandPartnersPage from './pages/account/DashboardBrandPartnersPage.vue'

export const routes: RouteRecordRaw = {
  path: '/',
  component: DashboardLayout,
  name: dashboardRouteNames.root,
  meta: { requiresAuth: true },
  children: [
    {
      path: 'dashboard',
      name: dashboardRouteNames.dashboard,
      component: DashboardIndex,
    },
    {
      path: 'edit-clip',
      name: dashboardRouteNames.editor,
      component: DashboardEditorPage,
    },
    {
      path: 'edit-clip-result',
      name: dashboardRouteNames.editorResult,
      component: DashboardEditorResultPage,
    },
    {
      path: 'montage-maker',
      name: dashboardRouteNames.montageMaker,
      component: DashboardMontageMakerPage,
    },
    {
      path: 'analytics',
      name: dashboardRouteNames.analytics,
      component: DashboardAnalyticsPage,
    },
    {
      path: 'content-publisher',
      name: dashboardRouteNames.contentPublisher.root,
      component: DashboardContentPublisherPage,
    },
    {
      path: 'content-publisher/render/:renderId?',
      name: dashboardRouteNames.contentPublisher.render,
      component: DashboardContentPublishPage,
    },
    {
      path: 'content-publisher/post/:postId?',
      name: dashboardRouteNames.contentPublisher.post,
      component: DashboardContentPublishPage,
    },
    {
      path: 'content-publisher/video/:videoId?',
      name: dashboardRouteNames.contentPublisher.video,
      component: DashboardContentPublishPage,
    },
    // {
    //   path: 'clip-gpt',
    //   name: dashboardRouteNames.clipGPT.root,
    //   component: DashboardClipGPTPage,
    // },
    // {
    //   path : 'clip-gpt/:videoId',
    //   name: dashboardRouteNames.clipGPT.result,
    //   component: DashboardClipGPTResultPage,
    // },
    {
      path: 'videos',
      name: dashboardRouteNames.videos,
      component: DashboardVideosOverviewPage,
    },
    {
      path: 'socials',
      name: dashboardRouteNames.socials,
      component: DashboardSocialsPage,
    },
    {
      path: 'support',
      name: dashboardRouteNames.support,
      component: DashboardSupportPage,
    },
    {
      path: 'whats-new',
      name: dashboardRouteNames.whatsNew,
      component: DashboardWhatsNewPage,
    },
    {
      path: 'account',
      name: dashboardRouteNames.account.root,
      component: DashboardAccountLayout,
      children: [
        {
          path: '',
          name: dashboardRouteNames.account.settings,
          component: DashboardAccountIndex,
        },
        {
          path: 'invoicing',
          name: dashboardRouteNames.account.invoicing,
          component: DashboardBillingPage,
        },
        {
          path: 'templates',
          name: dashboardRouteNames.account.templates,
          component: DashboardTemplatesOverviewPage,
        },
        {
          path: 'brand-partners',
          name: dashboardRouteNames.account.brandPartners,
          component: DashboardBrandPartnersPage,
        }
      ],
    },
  ],
}
