import type { Area, Point, Bounds, Snap } from '@/modules/SLMovable/@types/Movable'
import { keepAreaInsideBounds } from '@/modules/SLMovable/helpers/area'

export function moveBy(source: Area, delta: Point): Area {
  return moveTo(source, {
    x: source.x + delta.x,
    y: source.y + delta.y,
  })
}

export function moveTo(source: Area, target: Point): Area {
  return {
    x: target.x,
    y: target.y,
    width: source.width,
    height: source.height,
  }
}

export function moveWithBoundingAndSnapping(
  source: Area,
  target: Point,
  bounds: Bounds,
  snap: Snap,
  snapThreshold?: Point
): Area {

  const moved = moveTo(source, target)

  if (snap && snapThreshold) {
    const closestX = _findClosestSnapX(snap.x ?? [], moved, snapThreshold.x, [0, 0.5, 1])
    if (closestX !== null) {
      moved.x = closestX
    }

    const closestY = _findClosestSnapY(snap.y ?? [], moved, snapThreshold.y, [0, 0.5, 1])
    if (closestY !== null) {
      moved.y = closestY
    }
  }

  return keepAreaInsideBounds(moved, bounds)
}

function _findClosestSnapX(snap: number[], area: Area, threshold: number, origins: number[]): number | null {

  let closest = null
  let distance = threshold

  for (const origin of origins) {
    const point = area.x + origin * area.width
    for (const value of snap) {
      const currentDistance = Math.abs(value - point)
      if (currentDistance < distance) {
        closest = value - origin * area.width
        distance = currentDistance
      }
    }
  }

  return closest
}

function _findClosestSnapY(snap: number[], area: Area, threshold: number, origins: number[]): number | null {

  let closest = null
  let distance = threshold

  for (const origin of origins) {

    const point = area.y + origin * area.height

    for (const value of snap) {
      const currentDistance = Math.abs(value - point)
      if (currentDistance < distance) {
        closest = value - origin * area.height
        distance = currentDistance
      }
    }
  }

  return closest
}
