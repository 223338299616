/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * StreamLadder Publisher Api
 * OpenAPI spec version: v1
 */
import type {
  VideoStatus,
  CommentDtoPagedResponse,
  GetClipsVideoIdChatMessagesParams,
  ClipSuggestion,
  GetClipsVideoIdAnalyseChatParams,
  ClipDto,
  GetClipsVideoIdCreateClipParams,
} from './model'
import { clipGPTAxiosInstance } from '../../services/axios'

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never

export const getClipsVideoIdStatus = (videoId: string, options?: SecondParameter<typeof clipGPTAxiosInstance>) => {
  return clipGPTAxiosInstance<VideoStatus>({ url: `/Clips/${videoId}/Status`, method: 'get' }, options)
}
export const postClipsVideoId = (videoId: string, options?: SecondParameter<typeof clipGPTAxiosInstance>) => {
  return clipGPTAxiosInstance<VideoStatus>({ url: `/Clips/${videoId}`, method: 'post' }, options)
}
export const getClipsVideoIdChatMessages = (
  videoId: string,
  params?: GetClipsVideoIdChatMessagesParams,
  options?: SecondParameter<typeof clipGPTAxiosInstance>
) => {
  return clipGPTAxiosInstance<CommentDtoPagedResponse>(
    { url: `/Clips/${videoId}/ChatMessages`, method: 'get', params },
    options
  )
}
export const getClipsVideoIdAnalyseChat = (
  videoId: string,
  params?: GetClipsVideoIdAnalyseChatParams,
  options?: SecondParameter<typeof clipGPTAxiosInstance>
) => {
  return clipGPTAxiosInstance<ClipSuggestion[]>(
    { url: `/Clips/${videoId}/AnalyseChat`, method: 'get', params },
    options
  )
}
export const getClipsVideoIdCreateClip = (
  videoId: string,
  params?: GetClipsVideoIdCreateClipParams,
  options?: SecondParameter<typeof clipGPTAxiosInstance>
) => {
  return clipGPTAxiosInstance<ClipDto>({ url: `/Clips/${videoId}/CreateClip`, method: 'get', params }, options)
}

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never
