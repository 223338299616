<script setup lang="ts">
import MovableRoot from '@/modules/SLMovable/MovableRoot.vue'
import CustomLayoutsPreview from '@/modules/CustomLayouts/CustomLayoutsPreview.vue'
import CropElement from '@/modules/CustomLayouts/Crop/CropElement.vue'
import LightSwitch from '@/components-v2/data-input/boolean/LightSwitch.vue'
import { useScreen } from '@/Hooks/useScreen'
import { ref, computed, provide, inject, onMounted, watch } from 'vue'
import { useEventListener, useElementSize, useRafFn, useFullscreen } from '@vueuse/core'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { contain } from '@/modules/SLMovable/helpers/fit'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import type { CropsStore } from '@/store/entity-system/useCropsStore'
import type { Size } from '@/modules/SLMovable/@types/Movable'
import { Button } from '@/components/ui/button'
import IconSaxMaximize2 from '@/components/Icons/iconsax/IconSaxMaximize2.vue'
import logging from '@/logging'
import IconSaxMinimize from '@/components/Icons/iconsax/IconSaxMinimize.vue'

const cropsStore = inject<CropsStore>('cropsStore')!
const props = defineProps<{ id: string, enableSnapping: boolean }>()
const crops = cropsStore.whereLayoutIdIs(props.id)

const isDesktop = useScreen('md')
const tab = ref<'preview' | 'crop'>('crop')

const editorFocusStore = useEditorFocusStore()
function unfocus() {
  editorFocusStore.unFocus()
}

onMounted(() => {
  setTimeout(() => {
    const lastCrop = crops.value[crops.value.length - 1]
    if (lastCrop && editorFocusStore.focus?.type !== 'crop') {
      editorFocusStore.setFocus('crop', lastCrop.id)
    }
  }, 0)
})

const editorVideoStore = useEditorVideoStore()
const canvas = ref<HTMLCanvasElement | null>(null)

useRafFn(() => {

  const video = editorVideoStore.videoElement

  if (canvas.value && canvasSize.value && video) {

    canvas.value.width = canvasSize.value.width
    canvas.value.height = canvasSize.value.height

    const ctx = canvas.value.getContext('2d')
    ctx?.drawImage(video, 0, 0, canvasSize.value.width, canvasSize.value.height)
  }
})

provide('canvas', canvas)

const cropper = ref<HTMLElement | null>(null)
const { width: cropperWidth } = useElementSize(cropper)
const cropperHeight = computed(() => cropperWidth.value * (9 / 16))

const relativeVideoSize = computed(() => {
  if (editorVideoStore.videoSize) {
    return contain(editorVideoStore.videoSize, { width: cropperWidth.value, height: cropperHeight.value })
  } else {
    return null
  }
})

const canvasSize = computed(() => {
  if (!relativeVideoSize.value || !editorVideoStore.videoSize) {
    return null
  } else {
    return contain(editorVideoStore.videoSize, relativeVideoSize.value!)
  }
})

const width = computed(() => relativeVideoSize.value?.width)
const height = computed(() => relativeVideoSize.value?.height)
useEventListener(['click', 'touch'], unfocus, { target: document.body })

function computeCropArea({ width, height }: Size) {

  if (!canvasSize.value) return 0

  const cropSize = { width: width * canvasSize.value.width, height: height * canvasSize.value.height }
  return cropSize.width * cropSize.height
}

const sortedCrops = computed(() => {
  return [...crops.value]
    .sort((a, b) => computeCropArea(b) - computeCropArea(a))
    .map(crop => crop.id)
})

const customLayoutsContainer = ref<HTMLElement | null>(null)
const { toggle, isFullscreen, isSupported } = useFullscreen(customLayoutsContainer)

watch(() => isFullscreen.value, () => {
  if (isFullscreen.value) {
    logging.trackEvent('Custom Layout Fullscreen Entered')
  }
})

const root = computed(() => isFullscreen.value ? '#custom-layouts-container' : 'body')
provide('root', root)
provide('mouseover', ref<string | null>(null))

const mouseOver = ref(false)
</script>

<template>
  <div ref="customLayoutsContainer" id="custom-layouts-container" class="w-full" :class="{ 'flex items-center justify-center h-screen w-screen': isFullscreen }" v-if="isDesktop">
    <div class="preview-grid w-full" :class="{ 'max-w-[calc(calc(100vh_/_9)_*_21)]': isFullscreen }">
      <div
        ref="cropper"
        class="relative grid place-items-center box-content preview-grid__cropper p-2 bg-zinc-1000 rounded-xl"
        :style="{ maxHeight: height + 'px' }"
      >

        <div class="w-full h-full grid place-items-center bg-zinc-1000">
          <canvas ref="canvas" class="rounded-lg overflow-hidden" />
        </div>

        <MovableRoot
          :blackout="sortedCrops.length > 0"
          class="flex-0 absolute inset-auto"
          mask-class="rounded-lg overflow-hidden"
          :style="{ width: width + 'px', height: height + 'px' }"
        >

          <div class="absolute top-4 right-4 z-[1]" v-if="isSupported" @mouseover="mouseOver = true" @mouseleave="mouseOver = false">
            <Button variant="ghost" size="sm" class="p-2 h-auto text-white bg-black/50" @click="toggle">
              <IconSaxMaximize2 v-if="!isFullscreen" class="w-4 h-4" />
              <IconSaxMinimize v-else class="w-4 h-4" />
            </Button>

            <div
              :class="mouseOver ? 'translate-y-2 visible opacity-100' : 'translate-y-3 invisible opacity-0 scale-90'"
              class="absolute top-full whitespace-nowrap transition-[opacity,_transform] left-1/2 -translate-x-1/2 z-[250] select-none rounded-[4px] bg-[#333333] p-2 text-sm leading-none text-white shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity]"
            >
              <template v-if="!isFullscreen">Enter fullscreen</template>
              <template v-else>Exit fullscreen</template>
            </div>
          </div>

          <CropElement v-for="crop in sortedCrops" :key="crop" :id="crop" :enable-snapping="enableSnapping" @click.stop />
        </MovableRoot>
      </div>
      <div
        class="relative border-box p-2 w-full rounded-2xl border-zinc-900 bg-zinc-1000 shadow-lg preview-grid__preview"
      >
        <div class="h-0 pb-[177.78%] relative">
          <CustomLayoutsPreview class="absolute inset-0 overflow-hidden rounded-xl" :id="props.id" :enable-snapping="enableSnapping" />
        </div>
      </div>
    </div>
  </div>
  <div id="custom-layouts-container" class="w-full flex flex-col gap-2" v-else>
    <LightSwitch @click.stop
      :options="[ { value: 'crop', label: 'Crop' }, { value: 'preview', label: 'Preview' }, ]"
      v-model:value="tab"
      background="bg-gradient"
      label-classes="text-white"
    />
    <div
      v-if="tab === 'crop'"
      ref="cropper"
      class="relative grid place-items-center box-content"
      :style="{ maxHeight: height + 'px' }"
    >
      <div class="w-full h-full grid place-items-center bg-zinc-1000 rounded-xl overflow-hidden">
        <canvas ref="canvas" class="rounded-xl overflow-hidden" />
      </div>

      <MovableRoot
        :blackout="sortedCrops.length > 0"
        class="flex-0 absolute inset-auto"
        mask-class="rounded-xl overflow-hidden"
        :style="{ width: width + 'px', height: height + 'px' }"
      >
        <CropElement v-for="crop in sortedCrops" :key="crop" :enable-snapping="enableSnapping" :id="crop" @click.stop />
      </MovableRoot>
    </div>
    <div
      v-else-if="tab === 'preview'"
      class="relative flex h-0 w-full rounded-2xl border-[0.5rem] border-zinc-900 bg-zinc-1000 pb-[177.78%] shadow-lg"
    >
      <CustomLayoutsPreview class="absolute inset-0 overflow-hidden rounded-xl" :id="props.id" :enable-snapping="enableSnapping" />
    </div>
  </div>
</template>

<style scoped lang="scss">

.preview-grid {
  display: grid;

  // CSS calculation that accounts for borders while maintaining aspect-ratio,
  // read as: [border-left] [crop] [border-right] [gap] [border-left] [preview] [border-right].
  grid-template-columns: 0.5rem 16fr 0.5rem 2rem 0.5rem calc(calc(9fr / 16) * 9) 0.5rem;
  grid-template-areas: 'crop crop crop . preview preview preview';
  padding: 0.5rem;
}

.preview-grid__cropper {
  grid-area: crop;
}

.preview-grid__preview {
  grid-area: preview;
}
</style>
