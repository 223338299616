<script setup lang="ts">
import { ref, provide, defineAsyncComponent, computed } from 'vue'
import CustomLayoutsPlayBar from '@/modules/CustomLayouts/CustomLayoutsPlayBar.vue'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import type { Layout, LayoutsStore } from '@/store/entity-system/useLayoutsStore'
import type { Crop, CropsStore } from '@/store/entity-system/useCropsStore'
import { newCrop } from '@/store/entity-system/useCropsStore'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { PopoverRoot, PopoverTrigger, PopoverContent, PopoverPortal } from 'radix-vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import useLogin from '@/Hooks/useLogin'
import { canGuardWithPopup, useGuard } from '@/Hooks/useGuard'
import GoldPlanButton from '@/components/Account/Upgrade/GoldPlanButton.vue'
import CustomLayoutsGrid from '@/modules/CustomLayouts/CustomLayoutsGrid.vue'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import IconSaxEdit2 from '@/components/Icons/iconsax/IconSaxEdit2.vue'
import IconSaxArrowRight from '@/components/Icons/iconsax/IconSaxArrowRight.vue'
import IconSaxCloseCircle from '@/components/Icons/iconsax/IconSaxCloseCircle.vue'
import { snapshotLayoutById } from '@/store/entity-system/actions/snapshotLayoutById'
import { useEventListener } from '@vueuse/core'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'

const props = withDefaults(defineProps<{
  id: string
  layoutsStore: LayoutsStore
  cropsStore: CropsStore
  saveButtonText?: string
}>(), { saveButtonText: 'Continue to edit clip' })

// eslint-disable-next-line vue/no-setup-props-destructure
const { layoutsStore, cropsStore } = props

const emit = defineEmits<{
  (event: 'save', layout: Layout, crops: Crop[]): void
  (event: 'close'): void
}>()

const editorVideoStore = useEditorVideoStore()

provide('layoutsStore', layoutsStore)
provide('cropsStore', cropsStore)

const layout = layoutsStore.selectById(props.id)
const layoutName = layoutsStore.selectNameById(props.id)

const crops = cropsStore.idsWhereLayoutIdIs(props.id)

function addCrop() {

  if (!editorVideoStore.videoSize) throw new Error('Video size is not set')

  const crop = newCrop(props.id, editorVideoStore.videoSize, crops.value.length)
  cropsStore.createById(crop.id, crop)

  const editorFocusStore = useEditorFocusStore()
  editorFocusStore.setFocus('crop', crop.id)
}

const root = ref<HTMLElement | null>(null)

const userInfoStore = useUserInfoStore()
const { openLoginModal } = useLogin()

const customLayoutsUnlocked = useGuard('custom-layouts')

async function commit() {

  if (!userInfoStore.isLoggedIn) {
    await openLoginModal()
  }

  if (!userInfoStore.isLoggedIn) {
    return
  }

  if (!canGuardWithPopup('custom-layouts')) {
    return
  }

  const snapshot = snapshotLayoutById(layout.id, layoutsStore, cropsStore)
  emit('save', snapshot.layout, snapshot.crops)
}

const EmojiPicker = defineAsyncComponent(() => import('vue3-emoji-picker'))
const emojiPickerOpen = ref(false)

function selectEmoji(emoji: { i: string }) {
  layoutsStore.updateEmojiById(props.id, emoji.i)
  emojiPickerOpen.value = false
}

const label = ref<HTMLSpanElement | null>(null)
const enableSnapping = ref(true)

const editorFocusStore = useEditorFocusStore()
useEventListener('keydown', (event) => {
  if (event.key === 'Escape' && editorFocusStore.focus?.type !== 'crop') {
    emit('close')
  }
})

const isEditing = ref(false)

const minLayoutNameLength = 3
const maxLayoutNameLength = 30

const hasError = computed(() => {
  return layoutName.value.trim().length < minLayoutNameLength || layoutName.value.trim().length > maxLayoutNameLength
})
</script>

<template>
  <article class="modal modal-open z-[0]" data-retain-focus>
    <div class="layer-1 h-screen w-screen max-w-[100vw] max-h-screen modal-box flex lg:h-auto lg:max-h-[90vh] lg:min-w-[40vw] lg:!max-w-[calc(133.33vh_-_175px)] flex-col gap-6" ref="root">
      <section class="flex flex-col gap-2 relative">
        <header class="text-3xl flex flex-row items-center" v-click-outside="() => isEditing = false">
          <PopoverRoot v-model:open="emojiPickerOpen">
            <PopoverTrigger>
              <span class="mr-2 inline-block">
                {{ layout.emoji }}
              </span>
            </PopoverTrigger>

            <PopoverPortal>
              <PopoverContent side="right" :side-offset="2">
                <EmojiPicker :native="true" @select="selectEmoji" />
              </PopoverContent>
            </PopoverPortal>
          </PopoverRoot>
          <template v-if="!isEditing">
            <span ref="label">
              {{ layout.name }}
            </span>

            <Button @click="isEditing = true" variant="ghost">
              <IconSaxEdit2 class="cursor-pointer w-4 h-4" />
            </Button>
          </template>

          <template v-else>
            <Input
              as="span"
              :class="{ '!outline-rose-400': hasError }"
            >
                <label class="flex items-center w-full" :class="{ '!bg-rose-50': hasError }">
                  <input
                    @keydown.esc.stop="isEditing = false"
                    v-focus
                    type="text"
                    v-model="layoutName"
                    class="flex-auto select-all bg-transparent px-3 py-2 outline-none min-w-0 basis-0 w-0"
                  />

                  <span
                    class="px-3 text-xs font-light whitespace-nowrap"
                    :class="{ 'text-rose-400': hasError }"
                  >
                    {{ layoutName.trim().length }} / {{ maxLayoutNameLength }}
                  </span>
                </label>

                <Button
                  type="submit"
                  variant="ghost"
                  size="lg"
                  class="rounded-l-none rounded-r-md -my-2 -mr-3"
                  :disabled="hasError"
                  @click="isEditing = false"
                >
                  Save
                </Button>
              </Input>
          </template>
        </header>

        <p class="max-w-[50ch] font-light opacity-75">
          Click 'Add New Crop' to manually define and refine areas within your clip for a tailored vertical mobile viewing
          viewing experience.
        </p>

        <Button variant="ghost" v-if="!isEditing" @click="emit('close')" class="p-2 rounded-full absolute top-0 right-0">
          <IconSaxCloseCircle />
        </Button>
      </section>

      <CustomLayoutsGrid :id="layout.id" :enable-snapping="enableSnapping" />

      <div class="flex items-center gap-6 justify-center">
        <Button @click.stop="addCrop" :disabled="crops.length >= 10">
          <IconSaxAdd class="[&>path]:stroke-2" />
          Add new crop
        </Button>

        <label class="flex items-center gap-2 cursor-pointer">
          <input type="checkbox" class="toggle toggle-primary" v-model="enableSnapping" />
          <span class="ml-2">Snap to grid</span>
        </label>
      </div>

      <div class="flex items-center gap-4 flex-wrap md:flex-nowrap">
        <CustomLayoutsPlayBar />
        <Button @click="commit" class="h-14 px-6 gap-2">
          <GoldPlanButton small v-if="!customLayoutsUnlocked" />
          {{ saveButtonText }}
          <IconSaxArrowRight />
        </Button>
      </div>
    </div>
  </article>
</template>

<style scoped lang="scss"></style>
