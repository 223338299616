<script setup lang="ts">
import CustomLayoutsModal from '@/modules/CustomLayouts/CustomLayoutsModal.vue'
import { useLayoutsStoreSetup, newLayout, type Layout } from '@/store/entity-system/useLayoutsStore'
import { useCropsStoreSetup, type Crop, newCrop } from '@/store/entity-system/useCropsStore'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { contain } from '@/modules/SLMovable/helpers/fit'
import { useConfirmDialog } from '@vueuse/core'
import { ref, defineAsyncComponent, computed } from 'vue'
import { PopoverTrigger, PopoverRoot, PopoverContent, PopoverPortal } from 'radix-vue'
import { snapshotLayoutById } from '@/store/entity-system/actions/snapshotLayoutById'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import IconSaxCloseCircle from '@/components/Icons/iconsax/IconSaxCloseCircle.vue'

const layout = newLayout()
const layoutsStore = useLayoutsStoreSetup({ [layout.id]: layout })
const layoutName = layoutsStore.selectNameById(layout.id)

const editorVideoStore = useEditorVideoStore()
const { videoWidth, videoHeight } = editorVideoStore.videoElement!

const videoSize = { width: videoWidth, height: videoHeight }

const gamefeedAspectRatio = { width: 9, height: 11 }
const gamefeedCropSize = contain(gamefeedAspectRatio, videoSize)
const gamefeedCropWidth = gamefeedCropSize.width * 0.8 / videoWidth
const gamefeedCropHeight = gamefeedCropSize.height * 0.8 / videoHeight

const gamefeedFeedSize = contain(gamefeedAspectRatio, { width: 1080, height: 1920 })
const gamefeedFeedWidth = gamefeedFeedSize.width / 1080
const gamefeedFeedHeight = gamefeedFeedSize.height / 1920

const gamefeed = newCrop(layout.id, { width: videoWidth, height: videoHeight }, 0, {
  x: 0.5 * (1 - gamefeedCropWidth),
  y: 0.5 * (1 - gamefeedCropHeight),
  width: gamefeedCropWidth,
  height: gamefeedCropHeight,
}, {
  x: 1 - gamefeedFeedWidth,
  y: 1 - gamefeedFeedHeight,
  width: gamefeedFeedWidth,
  height: gamefeedFeedHeight,
})

gamefeed.input.name = "Gamefeed"

const facecamAspectRatio = { width: 16, height: 9 }
const facecamCropSize = contain(facecamAspectRatio, videoSize)
const facecamCropWidth = facecamCropSize.width * 0.2 / videoWidth
const facecamCropHeight = facecamCropSize.height * 0.2 / videoHeight

const facecamFeedSize = contain(facecamCropSize, { width: 1080, height: 1920 })
const facecamFeedWidth = facecamFeedSize.width / 1080
const facecamFeedHeight = facecamFeedSize.height / 1920

const facecamOffset = 0.05 * videoHeight

const facecam = newCrop(layout.id, { width: videoWidth, height: videoHeight }, 1, {
  x: (1 - facecamOffset / videoWidth) - facecamCropWidth,
  y: (1 - facecamOffset / videoHeight) - facecamCropHeight,
  width: facecamCropWidth,
  height: facecamCropHeight,
}, {
  x: 1 - facecamFeedWidth,
  y: 0,
  width: facecamFeedWidth,
  height: facecamFeedHeight,
})

facecam.input.name = "Facecam"

const cropsStore = useCropsStoreSetup({
  [gamefeed.id]: gamefeed,
  [facecam.id]: facecam,
})

const emit = defineEmits<{
  (event: 'save', layout: Layout, crops: Crop[]): void
  (event: 'close'): void
}>()

function close() {
  if (!isRevealed.value) emit('close')
}

const { isRevealed, reveal, confirm, cancel } = useConfirmDialog()

function confirmNewLayoutName() {

  if (layoutName.value.trim().length < minLayoutNameLength || layoutName.value.trim().length > maxLayoutNameLength) {
    return
  }

  confirm()
}

async function save(layout: Layout, crops: Crop[]) {
  if (layout.name === 'New Layout') {
    layoutName.value = ''
    await new Promise(resolve => setTimeout(resolve, 0))
    const { isCanceled } = await reveal()

    layoutName.value = layoutName.value.trim() || layout.name

    if (!isCanceled) {
      const snapshot = snapshotLayoutById(layout.id, layoutsStore, cropsStore)
      emit('save', snapshot.layout, snapshot.crops)
    }
  } else {
    emit('save', layout, crops)
  }
}

const emojiPickerOpen = ref(false)
const EmojiPicker = defineAsyncComponent(() => import('vue3-emoji-picker'))

function selectEmoji(emoji: { i: string }) {
  layoutsStore.updateEmojiById(layout.id, emoji.i)
  emojiPickerOpen.value = false
}

const minLayoutNameLength = 3
const maxLayoutNameLength = 30

const hasError = computed(() => {
  return !!layoutName.value.trim() &&
    (layoutName.value.trim().length < minLayoutNameLength || layoutName.value.trim().length > maxLayoutNameLength)
})
</script>

<template>
  <CustomLayoutsModal
    :id="layout.id"
    :layouts-store="layoutsStore"
    :crops-store="cropsStore"
    @save="save"
    @close="close"
  />

  <Teleport to="body">
    <article class="modal z-[0]" :class="{ 'modal-open': isRevealed }" @keydown.esc.stop="cancel">
      <form class="modal-box layer-2 flex flex-col gap-8" @submit.prevent.stop="confirmNewLayoutName">
        <header class="flex justify-between relative">
          <div class="flex flex-col">
            <h2 class="text-2xl">Give your layout a name</h2>
            <p class="text-opacity-50 font-light text-sm">Make it memorable!</p>
          </div>

          <Button variant="ghost" @click="cancel" class="p-2 rounded-full absolute top-0 right-0" type="button">
            <IconSaxCloseCircle />
          </Button>
        </header>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-4" v-if="isRevealed">
          <label class="flex flex-1 flex-col">
            <span>Layout name</span>
            <span
              :class="{ '!outline-rose-400': hasError }"
              class="mt-2 max-w-full rounded-lg outline outline-1 outline-offset-2 outline-transparent focus-within:outline-zinc-900 dark:focus-within:outline-white"
            >
              <Input as="label" class="flex items-center rounded-lg" :class="{ '!bg-rose-50': hasError }">
                <input
                  v-focus
                  type="text"
                  v-model="layoutName"
                  class="flex-auto select-all bg-transparent px-3 py-2 outline-none min-w-0"
                />

                <span
                  class="px-3 text-xs font-light whitespace-nowrap"
                  :class="{ 'text-rose-400': hasError }"
                >
                  {{ layoutName.trim().length }} / {{ maxLayoutNameLength }}
                </span>
              </Input>
            </span>
          </label>
          <label class="flex flex-1 flex-col">
            <span>Emoji</span>
            <span
              class="mt-2 flex rounded-lg outline outline-1 outline-offset-2 outline-transparent focus-within:outline-zinc-900 dark:focus-within:outline-white"
            >
              <Input as="label" class="flex flex-auto items-center" tabindex="0" @focus="emojiPickerOpen = true">
                <PopoverRoot v-model:open="emojiPickerOpen">
                  <PopoverTrigger tabindex="-1">
                    <input
                      type="text"
                      disabled
                      tabindex="-1"
                      :value="layout.emoji"
                      class="flex-auto select-all bg-transparent px-3 py-2 outline-none pointer-events-none"
                    />
                  </PopoverTrigger>

                  <PopoverPortal to="body">
                    <PopoverContent side="bottom" :side-offset="2">
                      <EmojiPicker :native="true" @select="selectEmoji" />
                    </PopoverContent>
                  </PopoverPortal>
                </PopoverRoot>
              </Input>
            </span>
          </label>
        </div>
        <div class="flex gap-2">
          <Button
            type="button"
            class="flex-1"
            variant="outline"
            @click="cancel"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            :disabled="!layoutName || hasError"
            class="flex-1"
            @click="confirmNewLayoutName"
          >
            Save
          </Button>
        </div>
      </form>
    </article>
  </Teleport>
</template>

<style scoped lang="scss">

</style>
