/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * StreamLadder.Accounts.Api
 * OpenAPI spec version: 1.0
 */

export type AccountState = (typeof AccountState)[keyof typeof AccountState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AccountState = {
  active: 'active',
  inactive: 'inactive',
  unathorized: 'unathorized',
  disconnected: 'disconnected',
} as const
