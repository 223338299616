import { findProduct } from '@/store/products'
import { useUserInfoStore } from '@/store/user/userInfo'
import { useUserSubscriptionStore } from '@/store/user/userSubscription'
import axios from 'axios'

const baseUrl = 'https://streamladder.app.n8n.cloud/webhook'

export async function postCancelReasons(reason: string) {
  await axios.post(`${baseUrl}/5abf73f0-a182-4ec1-9bcc-49c87316d4aa`, {
    ...snapshotRequestMeta(),
    reason: reason,
  })
}

export async function postSupportForm(message: string, origin: string, referrer?: string) {
  await axios.post(`${baseUrl}/2c53f7c3-feb6-4385-a216-10c891b2004b`, {
    ...snapshotRequestMeta(),
    message: message,
    origin: origin,
    referrer: referrer,
  })
}

function snapshotRequestMeta() {
  const { userId, email, plan } = useUserInfoStore()
  const { subscription } = useUserSubscriptionStore()

  const product = findProduct(Number(subscription?.subscriptionPlanId))

  return {
    userId: userId,
    email: email,
    plan: plan,
    interval: product?.interval,
  }
}
