<script lang="ts" setup>
import GrowthIcon from '@/areas/dashboard/components/GrowthIcon.vue'
import { computed, defineAsyncComponent } from 'vue'
import { useMetrics } from '@/areas/dashboard/pages/analytics/hooks/useMetrics'
const VueUiXy = defineAsyncComponent(() => import('vue-data-ui').then(m => m.VueUiXy))

const { metrics, xAxisLabels } = useMetrics()

const config = computed(() => ({
  useCssAnimation: true,
  useCanvas: false,
  chart: {
    width: 500,
    height: 200,
    color: 'currentColor',
    backgroundColor: 'transparent',
    legend: {
      show: false,
      useDiv: false,
    },
    title: {
      show: false,
    },
    grid: {
      stroke: '#E1057C',
      labels: {
        show: true,
        color: 'currentColor',
        fontSize: 8,
        xAxisLabels: {
          values: xAxisLabels.value,
          fontSize: 8,
          color: 'currentColor',
        },
      },
    },
    zoom: { show: false },
    highlightArea: { show: false },
    tooltip: { show: false },
    userOptions: { show: false },
  },
  bar: {
    borderRadius: 4,
    useGradient: false,
    labels: {
      show: true,
      color: 'currentColor',
    },
  },
}))
</script>

<template>
  <section
    v-for="({ title, current, percentage, ratio, dataset }) in metrics"
    :key="'graph-' + title"
    class="layer-2 flex flex-col items-center gap-4 rounded-2xl p-4"
  >
    <header class="flex justify-center items-center gap-2">
      <h3>{{ current }} {{ title }}</h3>

      <p :class="ratio > 0 ? 'text-emerald-400' : 'opacity-50'" class="flex items-center gap-1 text-sm">
        {{ percentage }}
        <GrowthIcon v-if="ratio !== 0" :class="{ '-scale-y-100': ratio < 0 }" />
      </p>
    </header>

    <VueUiXy :config="config" :dataset="dataset" class="tiktok-graph layer-2 fill-current" />
  </section>
</template>

<style lang="scss">
.tiktok-graph {
  .vue-ui-xy-grid line:not(:first-child) {
    display: block;
  }

  line {
    display: none;
  }
}
</style>
