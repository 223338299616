<template>
  <img
    v-if="billingInfo.paymentMethod.toLowerCase() === 'paypal'"
    src="/images/payment-methods/paypal.svg"
    class="h-[40px]"
    alt="PayPal"
    loading="lazy"
  />
  <img
    v-else-if="billingInfo.paymentMethod.toLowerCase() === 'card' && billingInfo.cardType === 'visa'"
    src="/images/payment-methods/visa.svg"
    class="h-6"
    alt="Visa card"
    loading="lazy"
  />
  <img
    v-else-if="billingInfo.paymentMethod.toLowerCase() === 'card' && billingInfo.cardType === 'master'"
    src="/images/payment-methods/mastercard.svg"
    class="h-[40px]"
    alt="Mastercard"
    loading="lazy"
  />
  <img v-else src="/images/payment-methods/creditcard.svg" class="h-[40px]" alt="Credit card" loading="lazy" />
</template>

<script>
export default {
  props: {
    billingInfo: Object,
  },
}
</script>

<style></style>
