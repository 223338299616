<script setup lang="ts">
import { ref, computed } from 'vue'
import { useUserInfoStore, type TypedOutboundSocialAccount } from '@/store/user/userInfo'
import ProfileImage from '@/areas/dashboard/components/ProfileImage.vue'
import GrowthIcon from '@/areas/dashboard/components/GrowthIcon.vue'
import DashboardAnalyticsGraph from '@/areas/dashboard/pages/analytics/DashboardAnalyticsGraph.vue'
import { useMetrics } from '@/areas/dashboard/pages/analytics/hooks/useMetrics'
import settings from '@/data/settings'
import DiscordLogo from '@/components/Icons/DiscordLogo.vue'
import { useHead } from "@unhead/vue";

const userInfoStore = useUserInfoStore()
const currentAccountId = ref<string>(userInfoStore.tikTokAccounts[0]?.id)

const account = computed<TypedOutboundSocialAccount | undefined>(() => {
  return userInfoStore.findSocialById(currentAccountId.value)
})

const { metrics, filters, sinceDays } = useMetrics()

useHead({
  title: 'TikTok Analytics',
  meta: [
    {
      name: 'description',
      content: '✨ Coming Soon! ✨ Keep an eye on how your clips perform with our easy-to-use analytics. Understand your audience better and make your TikTok journey even more successful.'
    },
  ],
})
</script>

<template>
  <main class="relative flex max-h-[calc(100vh-3.1rem)] overflow-hidden rounded-2xl flex-col gap-8 p-4 lg:gap-16 lg:p-12">
    <article class="modal modal-open absolute backdrop-blur z-[1]">
      <div class="layer-2 modal-box flex flex-col gap-2 rounded-2xl p-10">
        <span class="self-center rounded bg-green-700 px-3 py-1 font-bold text-white"> Coming soon! </span>
        <h3 class="m-0 self-center p-0">TikTok Analytics</h3>
        <p class="self-center text-center font-light">
          Keep an eye on how your clips perform with our easy-to-use analytics. Understand your audience better and make
          your TikTok journey even more successful.
        </p>
        <div class="mt-6 flex items-center flex-col md:flex-row gap-4 rounded-lg bg-indigo-200/50 p-6">
          <p class="font-normal">Stay up to date with the latest features.</p>
          <a
            :href="settings.discordInviteUrl"
            target="_blank"
            class="flex items-center gap-2 whitespace-nowrap rounded-xl bg-indigo-500 px-3 py-2 text-white transition-all active:scale-90"
          >
            <DiscordLogo class="h-6 w-6" />
            Join discord now
          </a>
        </div>
      </div>
    </article>

    <header class="flex w-full shrink-0 flex-col gap-2">
      <h1 class="text-3xl lg:text-5xl">TikTok Analytics</h1>
      <ol class="flex flex-row gap-2">
        <li v-for="option in filters" :key="option.sinceDays">
          <label
            class="cursor-pointer rounded-full bg-white px-3 py-1 text-xs font-semibold outline outline-1 outline-offset-2 outline-transparent focus-within:outline-zinc-900 dark:focus-within:outline-zinc-50"
            :class="
              option.sinceDays === sinceDays
                ? 'bg-zinc-950 text-zinc-50 dark:bg-zinc-50 dark:text-zinc-900'
                : 'bg-zinc-50 text-zinc-900 dark:bg-zinc-950 dark:text-zinc-50'
            "
          >
            <input
              type="radio"
              :value="option.sinceDays"
              v-model="sinceDays"
              name="since-days"
              class="absolute opacity-0"
            />
            {{ option.label }}
          </label>
        </li>
      </ol>
    </header>

    <section class="layer-2 flex flex-col flex-wrap items-center gap-6 rounded-2xl p-8 md:flex-row">
      <template v-if="account">
        <ProfileImage class="mr-0 md:mr-3" :account="account" />

        <header class="flex flex-col items-center justify-center gap-1 md:items-start">
          <h2 class="text-base font-normal">@{{ account.displayName }}</h2>
          <p class="text-sm">TikTok</p>
        </header>
      </template>

      <div class="grid flex-auto gap-4 self-stretch sm:grid-cols-3">
        <section
          class="layer-1 flex flex-col gap-4 rounded-xl p-6"
          v-for="{ title, current, percentage, ratio } in metrics"
          :key="'summary-' + title"
        >
          <h4 class="text-sm font-semibold">{{ title }}</h4>
          <div class="flex items-center justify-between">
            <p class="text-xl font-semibold">{{ current }}</p>
            <p class="flex items-center gap-1 text-sm" :class="ratio > 0 ? 'text-emerald-400' : 'opacity-50'">
              {{ percentage }}
              <GrowthIcon v-if="ratio !== 0" :class="{ '-scale-y-100': ratio < 0 }" />
            </p>
          </div>
        </section>
      </div>
    </section>

    <DashboardAnalyticsGraph />
  </main>
</template>

<style lang="scss"></style>
