<script lang="ts" setup>
import { useUserInfoStore } from '@/store/user/userInfo'
import { selectRandomTip } from '@/areas/dashboard/helpers/tips'
import HighlightPartial from '@/areas/dashboard/pages/index/partial/HighlightPartial.vue'
import ClipForm from '@/areas/dashboard/components/ClipForm.vue'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import SelectDropdown from '@/components-v2/data-input/SelectDropdown.vue'
import ResultVideosList from '@/areas/dashboard/components/ResultVideos/ResultVideosList.vue'
import { useTimeBasedGreetings } from '@/areas/dashboard/hooks/useTimeBasedGreetings'
import ScheduledPostsList from '@/areas/dashboard/components/ScheduledPosts/ScheduledPostsList.vue'
import { videosFilter, videosFilterOptions } from '@/Hooks/useFilterVideos'
import { useUpcomingPosts } from '@/areas/dashboard/hooks/useUpcomingPosts'
import { useHead } from '@unhead/vue'
import WithUpgradeOverlay from '@/areas/dashboard/components/WithUpgradeOverlay.vue'
import settings from '@/data/settings'
import DiscordLogo from '@/components/Icons/DiscordLogo.vue'
import BlogPostsSection from '@/areas/dashboard/components/BlogPosts/BlogPostsList.vue'
import DashboardTwitchClipsGrid from '@/areas/dashboard/components/DashboardTwitchClipsGrid.vue'
import PatchNotesDialog from '@/components-v2/data-display/PatchNotesDialog.vue'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { Button } from "@/components/ui/button";
import { useMyClips } from '@/queries/useClips'
import { Skeleton } from '@/components/ui/skeleton'
import { SelectContent, Select, SelectTrigger, SelectItem } from '@/components/ui/select'
import ClipPreviewCard from '@/components/Clips/ClipPreviewCard.vue'
import { useTwitchClipsFilter } from '@/Hooks/useTwitchClipsFilter'
import NoContentSection from '@/components-v2/data-display/NoContentSection.vue'
import ClipEditorMultiUploadDialog from '@/components/Dialog/MultiUploadDialog/ClipEditorMultiUploadDialog.vue'
import TwitchClipPreviewDialog from '@/components-v2/modules/HomePage/TwitchClipPreviewDialog.vue'
import ClipUrlError from '@/Hooks/clip-form/ClipUrlError.vue'
import { useScreenName } from '@/Hooks/useScreen'
import { useClipFormManager } from '@/Hooks/clip-form/useClipFormManager'
import { computed, ref, onMounted } from 'vue'
import type { Clip } from '@/Hooks/useTwitchClips'
import { useResetEditor } from '@/Hooks/useResetEditor'
import { useStartup } from '@/areas/editor/startup'
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { useRouter } from 'vue-router'
import { posthog } from 'posthog-js'

const userInfo = useUserInfoStore()

const greeting = useTimeBasedGreetings()
const { isLoadingPosts, upcomingPosts } = useUpcomingPosts()

useHead({
  title: 'Dashboard',
  meta: [
    {
      name: 'description',
      content: 'Keep track of your clips, schedule posts and more.',
    },
  ],
})

const { myClips } = useMyClips()
const { twitchClipsOptions, twitchClipFilterKey } = useTwitchClipsFilter()

onMounted(() => {
  myClips.fetchIfNull()
})

const screen = useScreenName()
const itemsPerRowByScreenSize = {
  xs: 2,
  sm: 2,
  md: 2,
  lg: 3,
  xl: 3,
  '2xl': 4,
}
const items = computed(() => 2 * itemsPerRowByScreenSize[screen.value])

const { error } = useClipFormManager()
const previewId = ref<string | null>(null)
const resetEditor = useResetEditor()
const { startupFromTwitchClipByClipId } = useStartup()
const useNewEditor = useFeatureFlagEnabled('new-editor-v2')
const router = useRouter()
async function onClipSelected(clip: Clip) {
  resetEditor()
  if (useNewEditor.value) {
    await startupFromTwitchClipByClipId(clip.id)
  } else {
    await router.push({ name: 'editor', params: { clipId: clip.id, source: 'twitch-clip' } })
  }
}

function preview(clip: Clip) {
  previewId.value = clip.id
}

const showElgatoContest = useFeatureFlagEnabled('elgato-contest');

const openContestInNewTab = () => {
  posthog.capture('Elgato Banner Clicked', { location: 'Dashboard' });
  window.open('https://streamladder.com/contest2024/?utm_source=sl-website&utm_campaign=contest2024&utm_content=cta-bottom', '_blank');
};
</script>

<template>
  <main class="flex flex-col gap-8 p-4 lg:gap-16 lg:p-12">
    <div class="flex flex-wrap items-stretch gap-6">
      <header class="flex w-full shrink-0 flex-col">
        <h1 class="text-3xl lg:text-5xl">
          {{ greeting }},
          <span class="font-bold">{{ userInfo.userName }}!</span>
        </h1>
        <p class="text-style-base text-brand-state-text-secondary">
          {{ selectRandomTip() }}
        </p>
      </header>

      <img
        v-if="showElgatoContest"
        @click="openContestInNewTab"
        class="w-full h-auto rounded-xl cursor-pointer hover:scale-[101%] transition-transform"
        src="/images/contest/banner.png"
        alt="Elgato Contest Promo Banner"
      />

      <section
        class="min-w-3/4 flex max-w-full shrink-0 grow flex-col gap-4 rounded-xl bg-gradient-to-br from-purple-800 to-indigo-500 dark:from-purple-200 dark:to-indigo-400 p-6 md:p-10 lg:p-14"
      >
        <header>
          <h3 class="leading-1 py-0 text-style-lg !text-white">Clip Editor</h3>
          <p class="font-light text-white">Convert your clips to viral TikTok, Reels and YouTube Shorts</p>
        </header>
        <ClipForm />
      </section>

      <a
        class="flex-1 lg:min-w-[400px]"
        href="https://emotes.streamladder.com/"
        target="_blank"
      >
        <HighlightPartial
          badge="Free!"
          call-to-action="Create animated emotes"
          gradient="bg-gradient-to-br from-[#F66565] to-[#FFB21D]"
          subtitle="Perfect to add that personal touch to your stream"
          title="EmoteMaker"
        />
      </a>

      <RouterLink
        :to="{ name: dashboardRouteNames.montageMaker }"
        class="flex-1 lg:min-w-[400px]"
      >
        <HighlightPartial
          badge="BETA"
          call-to-action="Create a montage"
          gradient="bg-gradient-to-br from-[#65A8F6] to-[#2F1DFF]"
          subtitle="Combine your favorite video clips into a montage"
          title="MontageMaker"
        />
      </RouterLink>
    </div>

    <section class="flex flex-col gap-2">
      <header class="flex items-center justify-between">
        <h2 class="mb-0 text-3xl font-normal text-brand-state-text-primary flex-auto">
          <template v-if="myClips.isFetching">
            Finding clips from <span class="font-bold">{{ userInfo.userName }}</span>
          </template>
          <template v-else>
            Showing clips from <span class="font-bold">{{ userInfo.userName }}</span>
          </template>
        </h2>

        <SelectDropdown v-model="twitchClipFilterKey" :options="twitchClipsOptions" class="ml-auto" />
      </header>

      <div class="p-4 mt-0 -m-4 h-full flex-auto" v-if="myClips.isFetching || (myClips.ordered && myClips.amountOfClips > 0)">
        <div class="grid grid-cols-2 gap-2 md:gap-4 lg:grid-cols-3 2xl:grid-cols-4">
          <template v-if="myClips.isFetching">
            <article class="flex flex-col gap-1" v-for="i in items" :key="i">
              <Skeleton class="w-full aspect-video rounded-xl" />
              <Skeleton class="h-6 w-3/4" />
            </article>
          </template>
          <template v-else-if="myClips.ordered">
            <ClipPreviewCard v-for="clip in myClips.ordered.slice(0, items)"
              :key="clip.id!" :clip="clip!"
              @select="onClipSelected" @preview="preview"
            />
          </template>
        </div>
      </div>

      <NoContentSection
        v-else-if="twitchClipFilterKey === 'views-all'"
        subtitle="Try uploading a local file"
        title="The selected channel does not have any clips yet"
      >
        <ClipEditorMultiUploadDialog>
          <Button variant="gradient" size="lg">Upload a local file</Button>
        </ClipEditorMultiUploadDialog>
        <ClipUrlError :error="error" />
      </NoContentSection>

      <NoContentSection
        v-else
        subtitle="Check out all clips from this channel instead"
        title="No clips were created in this time period"
      >
        <Button variant="gradient" size="lg" @click="twitchClipFilterKey = 'views-all'">Show all</Button>
      </NoContentSection>

      <TwitchClipPreviewDialog v-model="previewId" :clips="myClips.ordered?.slice(items) ?? []" @confirm="onClipSelected" />
    </section>

    <WithUpgradeOverlay
      feature="scheduler"
      subtitle="Effortlessly plan and schedule your content with a simple click. It's that straightforward!"
      title="Plan and schedule your content"
    >
      <div class="flex flex-col gap-4">
        <section class="flex flex-wrap items-center justify-between gap-2">
          <header class="flex flex-col">
            <h2 class="text-2xl lg:text-3xl">Scheduled posts</h2>
            <p class="font-light text-opacity-50">These posts are scheduled and waiting to be published.</p>
          </header>

          <RouterLink
            v-if="!isLoadingPosts && upcomingPosts.length > 0"
            :to="{ name: dashboardRouteNames.contentPublisher.root }"
            class="shadow-lg"
          >
            <Button variant="gradient" size="lg">
              New post
              <IconSaxAdd />
            </Button>
          </RouterLink>
        </section>

        <ScheduledPostsList />
      </div>
    </WithUpgradeOverlay>

    <WithUpgradeOverlay
      feature="save-video"
      subtitle="Unlock extended clip storage. With Silver & Gold Membership, your clips are safe for a whole week!"
      title="Upgrade today and save your clips for 7 days"
    >
      <div class="flex flex-col gap-4">
        <section class="flex flex-wrap items-center justify-between gap-2">
          <header class="flex flex-col">
            <h2 class="text-2xl lg:text-3xl">My Videos</h2>
            <p class="font-light text-opacity-50">Below are the videos you created in the last 7 days.</p>
          </header>

          <label class="flex items-center gap-2">
            <span class="text-sm font-light">Filter:</span>
            <SelectDropdown v-model="videosFilter" :options="videosFilterOptions" class="ml-auto" />
          </label>
        </section>

        <ResultVideosList single-row />
      </div>
    </WithUpgradeOverlay>

    <section
      class="flex flex-col justify-between gap-12 rounded-xl bg-gradient-to-br from-[#F66565] to-[#B665F6] px-12 py-8 text-white lg:flex-row-reverse lg:justify-center lg:px-16 lg:py-12"
    >
      <div class="flex flex-col justify-between gap-3 sm:flex-row lg:flex-col">
        <div class="flex flex-col">
          <p class="text-5xl">300k+</p>
          <p class="whitespace-nowrap text-sm font-light uppercase">in our community</p>
        </div>
        <div class="flex flex-col">
          <p class="text-5xl">200k+</p>
          <p class="whitespace-nowrap text-sm font-light uppercase">monthly clips created</p>
        </div>
        <div class="flex flex-col">
          <p class="text-5xl">968M+</p>
          <p class="whitespace-nowrap text-sm font-light uppercase">views on social media</p>
        </div>
      </div>

      <div class="flex flex-col gap-4">
        <ol class="avatar-group -space-x-6">
          <li class="layer-1 avatar aspect-square h-16 w-16">
            <img alt="Ninja" height="72" loading="lazy" src="/images/user-avatars/ninja.png" width="72" />
          </li>
          <li class="layer-1 avatar aspect-square h-16 w-16">
            <img alt="gmhikaru" height="70" loading="lazy" src="/images/user-avatars/gmhikaru.png" width="70" />
          </li>
          <li class="layer-1 avatar aspect-square h-16 w-16">
            <img alt="phonecats" height="70" loading="lazy" src="/images/user-avatars/phonecats.png" width="70" />
          </li>
          <li class="layer-1 avatar aspect-square h-16 w-16">
            <img alt="Teddy127" height="70" loading="lazy" src="/images/user-avatars/teddy-127.png" width="70" />
          </li>
        </ol>

        <header class="flex max-w-[50ch] flex-col gap-2">
          <h3 class="m-0 p-0 text-3xl leading-10 !text-white">Join the community of Streamers that wants to grow.</h3>
          <p class="font-light !text-white">
            Connect with fellow Streamers! Explore creative insights, share ideas, and grow your channel.
          </p>
        </header>

        <a
          :href="settings.discordInviteUrl"
          class="flex items-center gap-2 self-start rounded-xl bg-white px-6 py-2 font-bold text-indigo-500 transition-all hover:text-indigo-600 active:scale-90"
          target="_blank"
        >
          <DiscordLogo class="h-6 w-6 fill-current" />
          Join our Discord
        </a>
      </div>
    </section>

    <BlogPostsSection />

    <PatchNotesDialog v-if="false" />
  </main>
</template>

<style lang="scss" scoped>
.elgato {
  box-shadow: 0 0 5px 2px #8032dd;
}
</style>
