import type { NotificationAdapter, Notification } from '@/modules/NotificationService/NotificationAdapter'
import { useQuery } from '@tanstack/vue-query'
import { SLVideoRendersAdapter } from '@/modules/NotificationService/adapters/SLVideoRendersAdapter'
import { useUserInfoStore } from '@/store/user/userInfo'
import { computed, type ComputedRef } from 'vue'
import { queryClient } from '@/services/QueryClient'
import { MontageAdapter } from '@/modules/NotificationService/adapters/MontageAdapter'
import { SocialConnectionsAdapter } from '@/modules/NotificationService/adapters/SocialConnectionsAdapter'
import { createGlobalState } from '@vueuse/core'

const adapters = [
  new SLVideoRendersAdapter(),
  new MontageAdapter(),
  new SocialConnectionsAdapter(),
] satisfies NotificationAdapter[];

type NotificationTypes = (typeof adapters)[number]['name']

export const NotificationQueryKey = ['notifications']

const notificationState = createGlobalState(() => {

  const userInfoStore = useUserInfoStore()
  const { data, isLoading, error } = useQuery<Notification[]>({
    queryKey: NotificationQueryKey,
    queryFn: async () => {
      const notifications = await Promise.all(adapters.map((adapter) => adapter.getUnreadNotifications()))
      return notifications.flat()
    },
    enabled: computed(() => userInfoStore.isAuthenticated),
    queryClient: queryClient,
    staleTime: 60 * 1000,
  })
  
  return { data, isLoading, error }
})

export const useNotifications = () => {
  const { data, isLoading, error } = notificationState()
  const notifications = computed(() => data.value || []) as ComputedRef<Notification[]>
  return { notifications, isLoading, error }
}

export const useMarkNotificationAsReadByType = (type: NotificationTypes) => {

  const userInfoStore = useUserInfoStore()
  const adapter = adapters.find((a) => a.name === type)
  if (!adapter) throw new Error(`No adapter found for notification type: ${type}`)

  return async (id: string) => {
    if (userInfoStore.isAuthenticated) {
      if (adapter && 'markNotificationAsRead' in adapter) {
        await adapter.markNotificationAsRead(id)
        await queryClient.invalidateQueries(NotificationQueryKey)
      }
    }
  }
}

export const useMarkNotificationAsRead = () => {
  const userInfoStore = useUserInfoStore()
  return async (notificationId: string) => {
    if (userInfoStore.isAuthenticated) {
      for (const adapter of adapters) {
        if (notificationId.startsWith(adapter.getIdentifier())) {
          if ('markNotificationAsRead' in adapter) {
            await adapter.markNotificationAsRead(notificationId)
            await queryClient.invalidateQueries(NotificationQueryKey)
          }
        }
      }
    }
  }
}

export const useMarkAllNotificationsAsRead = () => {
  const userInfoStore = useUserInfoStore()
  return async () => {
    if (userInfoStore.isAuthenticated) {
      for (const adapter of adapters) {
        if ('markAllNotificationsAsRead' in adapter) {
          await adapter.markAllNotificationsAsRead()
        }
      }
      await queryClient.invalidateQueries(NotificationQueryKey)
    }
  }
}
