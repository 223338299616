import { accountsAxios } from './axios'
import EventBus from '../eventBus'
import connectionTypes from '../enums/connectionTypes'
import accountEvents from '../events/accountEvents'
import { useUserInfoStore } from '@/store/user/userInfo'

export default {
  openConnectTikTok(data) {
    const connectData = {
      ...data,
      platform: connectionTypes.TIKTOK,
    }
    EventBus.$emit(accountEvents.OPEN_CONNECT_SOCIAL_MEDIA_DIALOG, connectData)
  },
  openConnectYoutube(data) {
    EventBus.$emit(accountEvents.OPEN_CONNECT_SOCIAL_MEDIA_DIALOG, data)
  },
  /**
   * Emits the revoke event with a revoke-callback
   *
   * @param connectionType
   * @param account
   * @returns {Promise<void>}
   */
  async confirmRevoke(connectionType, account) {
    const callback = this.getRevokeCallback(connectionType, account.id)

    EventBus.$emit(accountEvents.OPEN_CONFIRM_CONNECTION_REVOKE, {
      callback: callback,
      handle: account.displayName ?? `@${connectionType}`,
    })
  },
  /**
   *
   * @param id
   * @param connectionType
   * @returns {Promise<number>}
   */
  async revokeSocial(id, connectionType) {
    const userInfoStore = useUserInfoStore()
    const url = connectionType === 'tiktok' ? `/api/tiktok/v2/revoke/${id}` : `/api/${connectionType}/revoke/${id}`
    const res = await accountsAxios.post(url)
    await userInfoStore.updateUserInfo()
    return res.status
  },
  /**
   * Creates a callback method that gets executed when the user confirms the revoke.
   *
   * @param connectionType
   * @param id
   * @returns {function(): Promise<number>}
   */
  getRevokeCallback(connectionType, id) {
    return async () => {
      return await this.revokeSocial(id, connectionType)
    }
  },
}
