export const productIds = {
  SILVER: {
    MONTHLY: 794145,
    YEARLY: 794144,
  },
  GOLD: {
    MONTHLY: 777395,
    YEARLY: 777416,
  },
  PLUS: {
    MONTHLY: 739105,
    YEARLY: 739106,
  },
} as const
