/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * StreamLadder Publisher Api
 * OpenAPI spec version: v1
 */

export type OverallPostStatus = (typeof OverallPostStatus)[keyof typeof OverallPostStatus]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OverallPostStatus = {
  New: 'New',
  Scheduled: 'Scheduled',
  Publishing: 'Publishing',
  Published: 'Published',
  PartialFailure: 'PartialFailure',
  Failed: 'Failed',
} as const
