<script setup lang="ts">
import { type VideoResultDto } from '@/apis/streamladder-api/model'
import { computed, ref, watch } from 'vue'
import { useMediaControls } from '@vueuse/core'
import MontageMakerVideoPreviewContainer from '@/areas/dashboard/components/MontageMaker/MontageMakerVideoPreviewContainer.vue'
import MontageMakerVideoButton from '@/areas/dashboard/components/MontageMaker/MontageMakerVideoButton.vue'
import { addDays, formatDistanceToNow } from 'date-fns'
import { MAX_VIDEO_AGE } from '@/data/maxVideoAge'

const props = defineProps<{
  selectedVideoUrls?: string[]
  video?: VideoResultDto | undefined
  placeholderSrc?: string
}>()

const emit = defineEmits<{
  (name: 'addToMontage', url: string): void
  (name: 'removeFromMontage', url: string): void
  (name: 'play', id: string): void
}>()

const element = ref<HTMLVideoElement>()
const canplay = ref(false)
const { playing } = useMediaControls(element)

defineExpose({ video: element })

const loaded = ref(false)
const enabled = ref(false)
const isError = ref(false)
const isSelected = ref(false)
const disabled = ref(false)

const handleClick = (url: string | null | undefined) => {
  if (!isSelected.value && url) {
    isSelected.value = true
    playing.value = false
    enabled.value = false
    if (!props.selectedVideoUrls?.includes(url)) {
      return emit('addToMontage', url)
    }
  } else if (url) {
    isSelected.value = false
    return emit('removeFromMontage', url)
  }
}

watch(
  () => props.selectedVideoUrls?.length,
  () => {
    disabled.value = !!props.selectedVideoUrls?.length && props.selectedVideoUrls?.length >= 10
  }
)

const timeLeft = computed(() => {

  if (!props.video?.createdAt) return

  const createdAtDate = new Date(props.video?.createdAt)
  const removeDate = addDays(createdAtDate, MAX_VIDEO_AGE)
  return formatDistanceToNow(removeDate, { addSuffix: false })
})
</script>

<template>
  <MontageMakerVideoPreviewContainer
    v-if="placeholderSrc"
    :disable-editing="true"
    :isSelected="isSelected"
    :title="video?.title!"
    :timeLeft="timeLeft"
  >
    <img
      :src="isError ? '/images/images/thumbnail.jpg' : placeholderSrc"
      @error="isError = true"
      class="aspect-[9/16] w-full rounded-lg transition-all duration-300"
      loading="lazy"
      width="720"
      height="1280"
      alt="Placeholder video; upgrade to be able to save videos!"
    />
  </MontageMakerVideoPreviewContainer>

  <MontageMakerVideoPreviewContainer
    v-else-if="!video"
    class="skeleton pointer-events-none overflow-hidden"
    :disable-editing="true"
    :isSelected="isSelected"
    title=""
    :timeLeft="timeLeft"
  >
    <span class="aspect-[9/16] w-full rounded-lg"></span>
  </MontageMakerVideoPreviewContainer>

  <MontageMakerVideoPreviewContainer
    v-else-if="!enabled"
    :video="video"
    :playing="playing"
    :class="{ 'pointer-events-none overflow-hidden': !loaded }"
    :disable-editing="true"
    :isSelected="isSelected"
    :title="video?.title!"
    :timeLeft="timeLeft"
  >
    <div class="flex flex-col justify-center items-center rounded-lg aspect-[9/16] overflow-hidden bg-black/90">
      <img
        v-if="!enabled"
        class="w-full transition-all duration-300"
        :class="{ 'scale-90 opacity-0': !loaded }"
        loading="lazy"
        width="720"
        height="1280"
        @click="enabled = true"
        @load.once="loaded = true"
        :src="isError ? '/images/images/thumbnail.jpg' : video?.thumbnailUrl!"
        @error="isError = true"
        :alt="`Poster for '${video?.title}'`"
      />
    </div>
    <div
      v-if="isSelected && selectedVideoUrls"
      class="absolute top-[36px] flex aspect-[9/16] w-full items-center justify-center rounded-lg bg-gray-900/90 text-6xl font-bold"
    >
      <span class="text-white">{{ selectedVideoUrls.indexOf(video?.resultUrl!) + 1 }}</span>
    </div>
    <MontageMakerVideoButton
      @click="() => handleClick(video?.resultUrl)"
      :isSelected="isSelected"
      :disabled="disabled"
    />
  </MontageMakerVideoPreviewContainer>

  <MontageMakerVideoPreviewContainer
    v-else-if="video"
    :video="video"
    :playing="playing"
    :class="{ 'skeleton pointer-events-none overflow-hidden': !canplay, '!cursor-default': isSelected }"
    :disable-editing="true"
    :isSelected="isSelected"
    :title="video?.title!"
    :timeLeft="timeLeft"
  >
    <video
      ref="element"
      :id="video.id!"
      class="aspect-[9/16] w-full rounded-lg transition-all duration-300"
      :class="{ 'scale-90 opacity-0': !canplay }"
      crossorigin="anonymous"
      disablePictureInPicture
      disableRemotePlayback
      loop
      playsinline
      autoplay
      controls
      width="720"
      height="1280"
      @play="() => emit('play', video?.id!)"
      @canplay.once="canplay = true"
      @error="enabled = false"
      :src="video.resultUrl!"
    />
    <MontageMakerVideoButton @click="() => handleClick(video?.resultUrl)" :isSelected="isSelected" />
  </MontageMakerVideoPreviewContainer>
</template>

<style scoped lang="scss"></style>
