<template>
  <svg class="fill-current" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.5495 7.57969H15.9695C15.5795 7.57969 15.2695 7.26969 15.2695 6.87969C15.2695 6.48969 15.5795 6.17969 15.9695 6.17969H21.5495C21.9395 6.17969 22.2495 6.48969 22.2495 6.87969C22.2495 7.26969 21.9395 7.57969 21.5495 7.57969Z"
    />
    <path
      d="M6.67 7.57969H2.95C2.56 7.57969 2.25 7.26969 2.25 6.87969C2.25 6.48969 2.56 6.17969 2.95 6.17969H6.67C7.06 6.17969 7.37 6.48969 7.37 6.87969C7.37 7.26969 7.05 7.57969 6.67 7.57969Z"
    />
    <path
      d="M10.3914 10.8297C12.5729 10.8297 14.3414 9.06121 14.3414 6.87969C14.3414 4.69816 12.5729 2.92969 10.3914 2.92969C8.20988 2.92969 6.44141 4.69816 6.44141 6.87969C6.44141 9.06121 8.20988 10.8297 10.3914 10.8297Z"
    />
    <path
      d="M21.5489 17.8102H17.8289C17.4389 17.8102 17.1289 17.5002 17.1289 17.1102C17.1289 16.7202 17.4389 16.4102 17.8289 16.4102H21.5489C21.9389 16.4102 22.2489 16.7202 22.2489 17.1102C22.2489 17.5002 21.9389 17.8102 21.5489 17.8102Z"
    />
    <path
      d="M8.53 17.8102H2.95C2.56 17.8102 2.25 17.5002 2.25 17.1102C2.25 16.7202 2.56 16.4102 2.95 16.4102H8.53C8.92 16.4102 9.23 16.7202 9.23 17.1102C9.23 17.5002 8.91 17.8102 8.53 17.8102Z"
    />
    <path
      d="M14.1102 21.0699C16.2917 21.0699 18.0602 19.3014 18.0602 17.1199C18.0602 14.9384 16.2917 13.1699 14.1102 13.1699C11.9286 13.1699 10.1602 14.9384 10.1602 17.1199C10.1602 19.3014 11.9286 21.0699 14.1102 21.0699Z"
    />
  </svg>
</template>
