<script setup lang="ts">
import type { TypedOutboundSocialAccount } from '@/store/user/userInfo'
import { computed, ref } from 'vue'
import ConnectionTypes from '@/enums/connectionTypes'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import accountService from '@/services/accountService'
import { useConfirmDialog } from '@/components/Dialog/Confirm/useConfirmDialog'
import ProfileImage from '@/areas/dashboard/components/ProfileImage.vue'
import IconSaxRefresh from '@/components/Icons/iconsax/IconSaxRefresh.vue'
import useCreateSocialConnection from '@/Hooks/useCreateSocialConnection'
import {Button} from "@/components/ui/button";
import ReconnectionNecessary from '@/areas/dashboard/components/ReconnectionNecessary.vue'

const props = defineProps<{ account: TypedOutboundSocialAccount }>()

const mustReconnectTikTokAccount = computed(() => {
  const isUnauthorized = !props.account.scopes?.includes('user.info.stats') || props.account.state === 'unathorized' || !props.account.hasAccess;
  return props.account.type === ConnectionTypes.TIKTOK && isUnauthorized;
});

const confirmDialog = useConfirmDialog()

const isRemoving = ref<boolean>(false)

confirmDialog.onConfirm(async () => {
  isRemoving.value = true
  await accountService.revokeSocial(props.account.id, props.account.type)
  isRemoving.value = false
})

confirmDialog.onCancel(() => {
  isRemoving.value = false
})

async function remove() {
  await confirmDialog.reveal({
    title: `Are you sure you want to remove this account?`,
    message: 'This action cannot be undone, \nyou will have to reconnect your account manually.',
  })
}

const { connect } = useCreateSocialConnection()
async function reconnect() {
  await accountService.revokeSocial(props.account.id, props.account.type)
  await connect(props.account.type)
}
</script>

<template>
  <article class="layer-2 flex flex-col gap-4 rounded-xl px-6 py-4">
    <div class="grid grid-cols-2 items-center gap-4 md:grid-cols-3">
      <div class="col-span-2 flex items-center gap-4 lg:col-span-1">
        <ProfileImage :account="account" />

        <header class="flex flex-col">
          <h3 class="m-0 p-0 text-lg font-normal">
            <template v-if="account.type === ConnectionTypes.YOUTUBE">{{ account.displayName }}</template>
            <template v-else>@{{ account.displayName }}</template>
          </h3>
          <span class="text-sm font-light">
            <template v-if="account.type === ConnectionTypes.YOUTUBE">YouTube</template>
            <template v-if="account.type === ConnectionTypes.INSTAGRAM">Instagram</template>
            <template v-if="account.type === ConnectionTypes.TIKTOK">TikTok</template>
          </span>
          <span class="text-brand-state-text-secondary lg:hidden">
            <template v-if="!account.hasAccess">Reconnection needed</template>
            <template v-if="account.hasAccess && account.state === 'active'">Connected</template>
            <template v-if="account.hasAccess && account.state === 'inactive'">Disconnected</template>
            <template v-if="account.hasAccess && account.state === 'unathorized'">Disconnected</template>
          </span>
        </header>
      </div>

      <span class="hidden place-self-center text-brand-state-text-secondary lg:inline">
        <template v-if="!account.hasAccess">Reconnection needed</template>
        <template v-if="account.hasAccess && account.state === 'active'">Connected</template>
        <template v-if="account.hasAccess && account.state === 'inactive'">Disconnected</template>
        <template v-if="account.hasAccess && account.state === 'unathorized'">Disconnected</template>
      </span>

      <div class="col-span-2 flex items-center justify-between gap-2 md:col-span-1 lg:justify-self-end">
        <Button
          variant="ghost"
          class="text-sm"
          v-if="mustReconnectTikTokAccount || !account.hasAccess"
          @click="reconnect">
          <IconSaxRefresh class="h-4 w-4" />
          Reconnect
        </Button>
        <Button
          :disabled="isRemoving"
          @click="remove"
          variant="ghost"
          size="square"
          data-tip="Remove social media connection"
          class="tooltip tooltip-left text-red-400 "
        >
          <span v-if="isRemoving" class="spinner spinner-border !h-6 !w-6 border-2" />
          <IconSaxTrash v-else />
        </Button>
      </div>
    </div>

    <ReconnectionNecessary :account="account" />
  </article>
</template>

<style scoped lang="scss"></style>
