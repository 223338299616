<script setup lang="ts">
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import IconSaxVolumeHigh from '@/components/Icons/iconsax/IconSaxVolumeHigh.vue'
import IconSaxVolumeSlash from '@/components/Icons/iconsax/IconSaxVolumeSlash.vue'
import { computed, ref } from 'vue'
import IconSaxVolumeLow from '@/components/Icons/iconsax/IconSaxVolumeLow.vue'
import IconSaxVolumeLow1 from '@/components/Icons/iconsax/IconSaxVolumeLow1.vue'
import { Button } from '@/components/ui/button'
import { Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip'
import { useIsMobile } from '@/Hooks/useIsMobile'

const isMobile = useIsMobile();

const videoStore = useVideoStore()

const isShowingVolumeSlider = ref(isMobile.value)

const toggleMute = () => {
  videoStore.muted = !videoStore.muted
}

const showSlider = () => {
  isShowingVolumeSlider.value = true
}

const hideSlider = () => {
  isShowingVolumeSlider.value = false
}

const localVolume = computed({
  get: () => (videoStore.muted ? 0 : videoStore.volume * 100),
  set: (value) => {
    videoStore.muted = value === 0
    videoStore.volume = value / 100
  },
})
</script>

<template>
  <div
    class="relative flex items-center justify-start"
    @mouseleave="() => { if (!isMobile) hideSlider }"
    :class="{ 'ml-16': isShowingVolumeSlider, 'bg-zinc-200 dark:bg-surface-panel-50 rounded-r-2xl': isMobile }"
  >
    <Transition name="fade">
      <div
        v-if="isShowingVolumeSlider"
        class="absolute right-full flex h-full w-16 items-center justify-start overflow-hidden py-2"
        :class="{ 'bg-zinc-200 dark:bg-surface-panel-50 rounded-l-2xl': isMobile }"
      >
        <input class="mx-2 cursor-pointer range range-xs range-primary" type="range" v-model.number="localVolume" min="0" max="100" />
      </div>
    </Transition>

    <Tooltip>
      <TooltipTrigger>
        <Button
          variant="ghost"
          size="sm"
          class="px-0 w-8 flex items-center justify-center"
          @click="toggleMute"
          @mouseenter="showSlider"
        >
          <IconSaxVolumeSlash v-if="videoStore.muted" class="w-4" />
          <IconSaxVolumeHigh v-else-if="videoStore.volume > 0.5" class="w-4" />
          <IconSaxVolumeLow v-else-if="videoStore.volume < 0.25" class="w-4" />
          <IconSaxVolumeLow1 v-else class="w-4" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        <span v-if="videoStore.muted">Unmute (m)</span>
        <span v-else>Mute (m)</span>
      </TooltipContent>
    </Tooltip>
  </div>
</template>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: width 0.1s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  width: 0;
}
</style>
