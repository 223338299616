<template>
  <div class="mt-5 text-center">
    <h2><strong>Connection success!</strong></h2>
    <SuccessIcon />
    <p>You can close this window, it will be closed automatically</p>
    <p>
      <button class="w-[100px] rounded bg-green-600 p-2 text-white hover:bg-green-800" @click="closeWindow">
        Close
      </button>
    </p>
  </div>
</template>

<script>
import SuccessIcon from "@/components/Dialog/Icons/SuccessIcon.vue";

export default {
  components: { SuccessIcon },
  async mounted() {
    if (window.opener) {
      window.opener.postMessage('connection-success', '*')
    }
  },
  methods: {
    closeWindow() {
      window.close()
    },
  },
}
</script>

<style></style>
