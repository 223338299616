<template>
  <tw-base-dialog
    styles="max-w-5xl"
    :background="'layer-1'"
    :modelValue="modelValue"
    @update:modelValue="(e) => $emit('update:modelValue', e)"
  >
    <template v-slot:header="{ close }">
      <div class="absolute right-4 top-4">
        <div class="cursor-pointer p-2 text-gray-300 hover:text-company-primary" @click="close()">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 fill-current" viewBox="0 0 512 512">
            <path
              d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"
            />
          </svg>
        </div>
      </div>
    </template>
    <div class="flex w-full flex-col lg:min-h-[900px] lg:flex-row">
      <div class="flex w-full flex-col px-4 py-16 lg:p-12">
        <div class="main-content flex flex-1 flex-col">
          <h2 class="mb-0 text-4xl font-bold">Choose which video you want to post</h2>
          <p class="opacity-70">Select a video to start scheduling</p>
          <hr class="mb-4" />
          <SelectDropdown :options="videosFilterOptions" v-model="videosFilter" class="self-end" />
          <ScrollArea>
            <div class="video-wrapper grid w-full grid-cols-2 gap-4 p-4 py-4 md:grid-cols-3 lg:grid-cols-5">
              <template v-if="isLoading">
                <div
                  v-for="i in 6"
                  :key="i"
                  class="video-placeholder video-wrapper flex aspect-[9/16] w-full animate-pulse select-none flex-col items-center justify-center rounded-xl bg-gray-300"
                >
                  &nbsp;
                </div>
              </template>
              <template v-else-if="userClips.length > 0">
                <div v-for="clip in userClips" :key="clip.id">
                  <clip
                    :draggable="false"
                    :selectable="true"
                    :clip="clip"
                    :has-resource-control="false"
                    @clipClicked="(e) => $emit('clipSelected', e)"
                  />
                </div>
              </template>
            </div>
            <NoContentClipsSection
              v-if="userClips.length === 0"
              :totalAmountOfVideos="allVideos.length"
              :hideFileUpload="true"
              :hideScheduleButton="true" />
          </ScrollArea>
        </div>
      </div>
    </div>
  </tw-base-dialog>
</template>
<script lang="ts">
import TwBaseDialog from '../TwBaseDialog.vue'
import Clip from '../../Scheduler/Clips/Clip.vue'
import { computed } from 'vue'
import { useGetApiVideos } from '@/apis/streamladder-api/videos/videos'
import { useUserInfoStore } from '@/store/user/userInfo'
import SelectDropdown from '@/components-v2/data-input/SelectDropdown.vue'
import { useFilterVideos, videosFilter, videosFilterOptions } from '@/Hooks/useFilterVideos'
import type { VideoResultDto } from '@/apis/streamladder-api/model'
import { ScrollArea } from '@/components/ui/scroll-area'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import NoContentClipsSection from '@/areas/dashboard/components/NoContentClipsSection.vue'

export default {
  computed: {
    dashboardRouteNames() {
      return dashboardRouteNames
    }
  },
  components: {
    NoContentClipsSection,
    ScrollArea,
    SelectDropdown,
    Clip,
    TwBaseDialog,
  },
  props: {
    modelValue: { type: Boolean, default: null },
  },
  emits: ['update:modelValue', 'clipSelected'],
  setup() {
    const userInfo = useUserInfoStore()
    const isAuthenticated = computed(() => userInfo.isAuthenticated)
    const { isLoading: isLoadingVideos, data } = useGetApiVideos(undefined, {
      query: {
        enabled: isAuthenticated,
      },
    })

    const allVideos = computed<VideoResultDto[]>(() => data.value ?? [])

    const { filteredVideos, isLoadingPosts } = useFilterVideos(computed<VideoResultDto[]>(() => data.value ?? []))

    return {
      isLoading: computed(() => isLoadingPosts.value || isLoadingVideos.value),
      userClips: filteredVideos,
      allVideos,
      videosFilterOptions,
      videosFilter,
    }
  },
  data() {
    return {
      selectedClip: null,
    }
  },
}
</script>

<style scoped lang="scss">
.video-wrapper {
  max-height: 70vh;
}

.video-wrapper::-webkit-scrollbar {
  width: 7px !important;
}

.video-wrapper::-webkit-scrollbar-track {
  background: rgba(70, 21, 128, 0.15);
  border-radius: 12px;
}

.video-wrapper::-webkit-scrollbar-thumb {
  background: #461580;
  border-radius: 0.75rem;
}

.video-placeholder {
  min-height: 130px;
}
</style>
