/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * StreamLadder.Accounts.Api
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/vue-query'
import type { UseQueryOptions, QueryFunction, QueryKey, UseQueryReturnType } from '@tanstack/vue-query'
import { unref } from 'vue'
import type { MaybeRef } from '@tanstack/vue-query/build/lib/types'
import type { ReferralDtoPageApiResponse, ApiError, GetApiUsersUserIdReferralsParams } from '.././model'
import { accountsAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never

export const getApiUsersUserIdReferrals = (
  userId: MaybeRef<string>,
  params?: MaybeRef<GetApiUsersUserIdReferralsParams>,
  options?: SecondParameter<typeof accountsAxiosInstance>,
  signal?: AbortSignal
) => {
  return accountsAxiosInstance<ReferralDtoPageApiResponse>(
    { url: `/api/users/${unref(userId)}/referrals`, method: 'get', params, signal },
    options
  )
}

export const getGetApiUsersUserIdReferralsQueryKey = (
  userId: MaybeRef<string>,
  params?: MaybeRef<GetApiUsersUserIdReferralsParams>
) => ['api', 'users', userId, 'referrals', ...(params ? [params] : [])] as const

export const getGetApiUsersUserIdReferralsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiUsersUserIdReferrals>>,
  TError = ApiError
>(
  userId: MaybeRef<string>,
  params?: MaybeRef<GetApiUsersUserIdReferralsParams>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiUsersUserIdReferrals>>, TError, TData>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getApiUsersUserIdReferrals>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiUsersUserIdReferralsQueryKey(userId, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUsersUserIdReferrals>>> = ({ signal }) =>
    getApiUsersUserIdReferrals(userId, params, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!userId, staleTime: 60000, ...queryOptions }
}

export type GetApiUsersUserIdReferralsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiUsersUserIdReferrals>>>
export type GetApiUsersUserIdReferralsQueryError = ApiError

export const useGetApiUsersUserIdReferrals = <
  TData = Awaited<ReturnType<typeof getApiUsersUserIdReferrals>>,
  TError = ApiError
>(
  userId: MaybeRef<string>,
  params?: MaybeRef<GetApiUsersUserIdReferralsParams>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiUsersUserIdReferrals>>, TError, TData>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiUsersUserIdReferralsQueryOptions(userId, params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}
