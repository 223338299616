<script setup lang="ts">
import { useUserInfoStore } from '@/store/user/userInfo'
import { computed } from 'vue'

const props = defineProps<{
  is: any
  className?: string
  authDependent?: boolean
}>()

const userInfoStore = useUserInfoStore()

const isAuthDependantFallback = computed(() => {
  return props.authDependent && !userInfoStore.isAuthenticated
})
</script>

<template>
  <Suspense>
    <div v-if="isAuthDependantFallback" :class="className">
      <slot />
    </div>

    <!-- main content -->
    <component v-else :is="is" :class="className">
      <slot />
    </component>

    <!-- loading state -->
    <template #fallback>
      <div :class="className">
        <slot />
      </div>
    </template>
  </Suspense>
</template>
