import { useEditorStickersStore } from '@/store/editor/editorStickers'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { useEditorMainStore } from '@/store/editor/editorMain'

/**
 * For more information see:
 * https://trac.ffmpeg.org/wiki/Encode/H.264
 * https://ffmpeg.org/ffmpeg.html#Advanced-Video-options
 * https://trac.ffmpeg.org/wiki/Scaling
 */
const ENCODER_CONTROL_RATE_FLOW = '17'
const ENCODER_PIXEL_FORMAT = 'yuv420p'
const ENCODER_PRESET = 'superfast'
const SCALING_ALG = 'lanczos'

export default {
  _getTrimArguments() {
    const editorMainStore = useEditorMainStore()
    const startTimeMs = editorMainStore.trimmedStartTime
    const endTimeMs = editorMainStore.trimmedEndTime
    const videoDuration = editorMainStore.videoDuration

    if (startTimeMs === 0 && (endTimeMs === videoDuration || endTimeMs === 0)) {
      // no trimmed needed
      return []
    }

    return [
      '-ss',
      (startTimeMs / 1000).toFixed(1).replace(',', '.'),
      '-to',
      (endTimeMs / 1000).toFixed(2).replace(',', '.'),
    ]
  },
  _getTrimData() {
    const editorMainStore = useEditorMainStore()
    const startTimeMs = editorMainStore.trimmedStartTime
    const endTimeMs = editorMainStore.trimmedEndTime
    const videoDuration = editorMainStore.videoDuration

    if (startTimeMs === 0 && (endTimeMs === videoDuration || endTimeMs === 0)) {
      // no trimmed needed
      return {}
    } else {
      return {
        StartTimeMs: startTimeMs,
        EndTimeMs: endTimeMs,
        VideoDuration: videoDuration,
      }
    }
  },
  _getEncoderArguments() {
    return [
      '-c:v',
      'libx264',
      '-pix_fmt',
      ENCODER_PIXEL_FORMAT,
      '-crf',
      ENCODER_CONTROL_RATE_FLOW,
      '-preset',
      ENCODER_PRESET,
    ]
  },
  _getInputArguments(include_circlemask) {
    const editorStickersStore = useEditorStickersStore()
    let output = ['-i', 'input.mp4']

    const editorCaptionsStore = useEditorCaptionsStore()
    if (editorCaptionsStore.hasCaptions) {
      output = output.concat(['-c:v', 'libvpx-vp9', '-i', 'stickerlayer'])
    } else if (editorStickersStore.hasStickers) {
      output = output.concat(['-i', 'stickerlayer'])
    }

    if (include_circlemask) output = output.concat(['-loop', '1', '-i', 'mask_360.png'])

    return output
  },
  _concatFfmpegCommand(filter_args, include_circlemask = false) {
    return [].concat(
      this._getTrimArguments(),
      this._getInputArguments(include_circlemask),
      this._getEncoderArguments(),
      filter_args,
      ['-c:a', 'aac', 'output.mp4']
    )
  },
  _appendStickerLayerFilter(filterCommand) {
    const editorStickersStore = useEditorStickersStore()
    const editorCaptionsStore = useEditorCaptionsStore()
    const editorMainStore = useEditorMainStore()

    const outputW = editorMainStore.outputWidth

    if (editorStickersStore.hasStickers || editorCaptionsStore.hasCaptions) {
      filterCommand += `[video_result];[1:v]scale=${outputW}:-2[stickerlayer];[video_result][stickerlayer]overlay`
    }

    return filterCommand
  },
  setSquareArguments() {
    const editorMainStore = useEditorMainStore()
    const editorFeedDataStore = useEditorFeedDataStore()
    const editorStickersStore = useEditorStickersStore()

    const fragment1 = editorFeedDataStore.fragment('square_fc')
    const fragment2 = editorFeedDataStore.fragment('square_gf')

    // Create copies as we are modifying them later
    const cropper1 = { ...fragment1.cropData }
    const cropper2 = { ...fragment2.cropData }

    const inputW = editorMainStore.videoWidth
    const inputH = editorMainStore.videoHeight
    const outputW = editorMainStore.outputWidth

    cropper1.x = Math.round(cropper1.x * inputW)
    cropper1.y = Math.round(cropper1.y * inputH)
    cropper1.w = Math.round(cropper1.w * inputW)
    cropper1.h = Math.round(cropper1.h * inputH)

    cropper2.x = Math.round(cropper2.x * inputW)
    cropper2.y = Math.round(cropper2.y * inputH)
    cropper2.w = Math.round(cropper2.w * inputW)
    cropper2.h = Math.round(cropper2.h * inputH)

    const facecamWidth = outputW / 2.5
    const facecamX = (outputW - facecamWidth) / 2

    let filterCommand = `[0:v]crop=${cropper1.w}:${cropper1.h}:${cropper1.x}:${cropper1.y}[croppedfc];[croppedfc]scale=${facecamWidth}:-2[top];[0:v]crop=${cropper2.w}:${cropper2.h}:${cropper2.x}:${cropper2.y}[croppedgf];[croppedgf]scale=${outputW}:-2[bottom];[bottom][top]overlay=x=${facecamX}`

    // add stickers (differs from other layouts because of the square)
    if (editorStickersStore.hasStickers) {
      filterCommand += `[video_result];[1:v]scale=${outputW}:-2[stickerlayer];[video_result][stickerlayer]overlay=(main_w-overlay_w)/2:(main_h-overlay_h)/2`
    }

    const ffmpegArgs = this._concatFfmpegCommand(['-filter_complex', filterCommand])
    editorMainStore.ffmpegStringArray = ffmpegArgs

    this._set2cropperArguments(cropper1, cropper2, outputW)
  },
  setSplitArguments(fragment1Key, fragment2Key) {
    const editorMainStore = useEditorMainStore()
    const editorFeedDataStore = useEditorFeedDataStore()

    const fragment1 = editorFeedDataStore.fragment(fragment1Key)
    const fragment2 = editorFeedDataStore.fragment(fragment2Key)

    // Create copies as we are modifying them later
    const cropper1 = { ...fragment1.cropData }
    const cropper2 = { ...fragment2.cropData }

    const inputW = editorMainStore.videoWidth
    const inputH = editorMainStore.videoHeight
    const outputW = editorMainStore.outputWidth

    cropper1.x = Math.round(cropper1.x * inputW)
    cropper1.y = Math.round(cropper1.y * inputH)
    cropper1.w = Math.round(cropper1.w * inputW)
    cropper1.h = Math.round(cropper1.h * inputH)

    cropper2.x = Math.round(cropper2.x * inputW)
    cropper2.y = Math.round(cropper2.y * inputH)
    cropper2.w = Math.round(cropper2.w * inputW)
    cropper2.h = Math.round(cropper2.h * inputH)

    // switchs feeds? put gamefeed top, facecam bottom
    const switchFeeds = editorMainStore.switchFeeds

    const stack = switchFeeds ? '[bottom][top]' : '[top][bottom]'

    let filterCommand = `[0:v]crop=${cropper1.w}:${cropper1.h}:${cropper1.x}:${cropper1.y}[croppedfc];[croppedfc]scale=${outputW}:-2:flags=${SCALING_ALG}[top];[0:v]crop=${cropper2.w}:${cropper2.h}:${cropper2.x}:${cropper2.y}[croppedgf];[croppedgf]scale=${outputW}:-2:flags=${SCALING_ALG}[bottom];${stack}vstack`
    filterCommand = this._appendStickerLayerFilter(filterCommand)

    const ffmpegArgs = this._concatFfmpegCommand(['-filter_complex', filterCommand])
    editorMainStore.ffmpegStringArray = ffmpegArgs

    this._set2cropperArguments(cropper1, cropper2, outputW, switchFeeds)
  },

  _set2cropperArguments(cropper1, cropper2, outputResolution, switchFeeds) {
    const editorMainStore = useEditorMainStore()

    editorMainStore.layoutArguments = {
      FaceCam: {
        Width: cropper1.w,
        Height: cropper1.h,
        Left: cropper1.x,
        Top: cropper1.y,
      },
      GameFeed: {
        Width: cropper2.w,
        Height: cropper2.h,
        Left: cropper2.x,
        Top: cropper2.y,
      },
      SwitchFeeds: switchFeeds,
      TrimmerData: this._getTrimData(),
      OutputResolution: outputResolution,
    }
  },
  setFullscreenArguments() {
    const editorMainStore = useEditorMainStore()
    const editorFeedDataStore = useEditorFeedDataStore()

    const fragment1 = editorFeedDataStore.fragment('fullscreen_gf')

    const cropper1 = fragment1.cropData

    const inputW = editorMainStore.videoWidth
    const inputH = editorMainStore.videoHeight
    const outputW = editorMainStore.outputWidth

    const cropperGfX = Math.round(cropper1.x * inputW)
    const cropperGfY = Math.round(cropper1.y * inputH)
    const cropperGfW = Math.round(cropper1.w * inputW)
    const cropperGfH = Math.round(cropper1.h * inputH)

    // const ffmpegArgs = this._concatFfmpegCommand(['-filter:v', `crop=${cropper1.w}:${cropper1.h}:${cropper1.x}:${cropper1.y}`])
    // const ffmpegArgs = this._concatFfmpegCommand(['-filter_complex', `[0:v]crop=${cropper1.w}:${cropper1.h}:${cropper1.x}:${cropper1.y}[croppedgf];[croppedgf]scale=${outputW}:-2:flags=${SCALING_ALG}`])

    let filterCommand = `[0:v]crop=${cropperGfW}:${cropperGfH}:${cropperGfX}:${cropperGfY}[croppedgf];[croppedgf]scale=${outputW}:-2`
    filterCommand = this._appendStickerLayerFilter(filterCommand)
    const ffmpegArgs = this._concatFfmpegCommand(['-filter_complex', filterCommand])

    editorMainStore.ffmpegStringArray = ffmpegArgs
    editorMainStore.layoutArguments = {
      GameFeed: {
        Width: cropperGfW,
        Height: cropperGfH,
        Left: cropperGfX,
        Top: cropperGfY,
      },
      TrimmerData: this._getTrimData(),
      OutputResolution: outputW,
    }
  },
  setBlurredBackgroundArguments() {
    const editorMainStore = useEditorMainStore()
    const editorFeedDataStore = useEditorFeedDataStore()

    const fragment1 = editorFeedDataStore.fragment('blurredbackground_gf')

    const cropper1 = fragment1.cropData

    const inputW = editorMainStore.videoWidth
    const inputH = editorMainStore.videoHeight
    const outputW = editorMainStore.outputWidth
    const outputH = editorMainStore.outputHeight

    const originalVideoHeight = editorMainStore.videoHeight
    const originalVideoWidth = editorMainStore.videoWidth

    const cropperGfX = Math.round(cropper1.x * inputW)
    const cropperGfY = Math.round(cropper1.y * inputH)
    const cropperGfW = Math.round(cropper1.w * inputW)
    const cropperGfH = Math.round(cropper1.h * inputH)

    const blurredCropWidth = Math.min(originalVideoWidth, Math.round(originalVideoHeight / 1.77))
    const blurredCropHeight = originalVideoHeight
    const blurredCropX = Math.max(0, Math.round((originalVideoWidth - blurredCropWidth) / 2))

    let filterCommand = `[0:v]crop=${blurredCropWidth}:${blurredCropHeight}:${blurredCropX}:0[croppedbg];[croppedbg]scale=${outputW}:${outputH},boxblur=10[background];[0:v]crop=${cropperGfW}:${cropperGfH}:${cropperGfX}:${cropperGfY}[croppedgf];[croppedgf]scale=${outputW}:-2[gameplay];[background][gameplay]overlay=y=(H-h)/2`
    filterCommand = this._appendStickerLayerFilter(filterCommand)

    const ffmpegArgs = this._concatFfmpegCommand(['-filter_complex', filterCommand])

    editorMainStore.ffmpegStringArray = ffmpegArgs
    editorMainStore.layoutArguments = {
      GameFeed: {
        Width: cropperGfW,
        Height: cropperGfH,
        Left: cropperGfX,
        Top: cropperGfY,
      },
      BlurredCrop: {
        Width: blurredCropWidth,
        Height: blurredCropHeight,
        Left: blurredCropX,
      },
      TrimmerData: this._getTrimData(),
      OutputResolution: outputW,
    }
  },
  setSmallfacecamArguments() {
    const editorMainStore = useEditorMainStore()
    const editorFeedDataStore = useEditorFeedDataStore()

    const fragment1 = editorFeedDataStore.fragment('smallfacecam_fc')
    const fragment2 = editorFeedDataStore.fragment('smallfacecam_gf')

    const cropper1 = fragment1.cropData
    const cropper2 = fragment2.cropData

    const fcFeed = fragment1.feedData
    const gfFeed = fragment2.feedData

    const inputW = editorMainStore.videoWidth
    const inputH = editorMainStore.videoHeight
    const outputW = editorMainStore.outputWidth
    const outputH = editorMainStore.outputHeight

    const originalVideoHeight = editorMainStore.videoHeight
    const originalVideoWidth = editorMainStore.videoWidth

    const blurredCropWidth = Math.min(originalVideoWidth, Math.round(originalVideoHeight / 1.77))
    const blurredCropHeight = originalVideoHeight
    const blurredCropX = Math.max(0, Math.round((originalVideoWidth - blurredCropWidth) / 2))

    const cropperFcX = Math.round(cropper1.x * inputW)
    const cropperFcY = Math.round(cropper1.y * inputH)
    const cropperFcW = Math.round(cropper1.w * inputW)
    const cropperFcH = Math.round(cropper1.h * inputH)

    const facecamPositionX = Math.round(fcFeed.x * outputW)
    const facecamPositionY = Math.round(fcFeed.y * outputH)

    const facecamWidthPixels = Math.round(fcFeed.w * outputW)
    const facecamHeightPixels = Math.round(fcFeed.h * outputH)

    const cropperGfX = Math.round(cropper2.x * inputW)
    const cropperGfY = Math.round(cropper2.y * inputH)
    const cropperGfW = Math.round(cropper2.w * inputW)
    const cropperGfH = Math.round(cropper2.h * inputH)

    const gamefeedPositionX = Math.round(gfFeed.x * outputW)
    const gamefeedPositionY = Math.round(gfFeed.y * outputH)

    let filterCommand = `[0:v]crop=${cropperFcW}:${cropperFcH}:${cropperFcX}:${cropperFcY}[croppedfc];[croppedfc]scale=${facecamWidthPixels}:${facecamHeightPixels}:flags=${SCALING_ALG}[fc];[0:v]crop=${blurredCropWidth}:${blurredCropHeight}:${blurredCropX}:0[croppedbg];[croppedbg]scale=${outputW}:${outputH},boxblur=10[background];[0:v]crop=${cropperGfW}:${cropperGfH}:${cropperGfX}:${cropperGfY}[croppedgf];[croppedgf]scale=${outputW}:-2:flags=${SCALING_ALG}[gameplay];[background][gameplay]overlay=y=${gamefeedPositionY}:x=${gamefeedPositionX}[blurred];[blurred][fc]overlay=y=${facecamPositionY}:x=${facecamPositionX}`
    filterCommand = this._appendStickerLayerFilter(filterCommand)
    const ffmpegArgs = this._concatFfmpegCommand(['-filter_complex', filterCommand])

    editorMainStore.ffmpegStringArray = ffmpegArgs
    editorMainStore.layoutArguments = {
      FaceCam: {
        Width: cropperFcW,
        Height: cropperFcH,
        Left: cropperFcX,
        Top: cropperFcY,
        OutputPositionX: facecamPositionX,
        OutputPositionY: facecamPositionY,
        OutputWidth: facecamWidthPixels,
        OutputHeight: facecamHeightPixels,
      },
      GameFeed: {
        Width: cropperGfW,
        Height: cropperGfH,
        Left: cropperGfX,
        Top: cropperGfY,
        OutputPositionX: gamefeedPositionX,
        OutputPositionY: gamefeedPositionY,
      },
      BlurredCrop: {
        Width: blurredCropWidth,
        Height: blurredCropHeight,
        Left: blurredCropX,
      },
      TrimmerData: this._getTrimData(),
      OutputResolution: outputW,
    }
  },
  setCircleFacecamArguments() {
    const editorMainStore = useEditorMainStore()
    const editorFeedDataStore = useEditorFeedDataStore()

    const fragment1 = editorFeedDataStore.fragment('circlefacecam_fc')
    const fragment2 = editorFeedDataStore.fragment('circlefacecam_gf')

    const cropper1 = fragment1.cropData
    const cropper2 = fragment2.cropData

    const fcFeed = fragment1.feedData
    const gfFeed = fragment2.feedData

    const originalVideoHeight = editorMainStore.videoHeight
    const originalVideoWidth = editorMainStore.videoWidth

    const inputW = editorMainStore.videoWidth
    const inputH = editorMainStore.videoHeight
    const outputW = editorMainStore.outputWidth
    const outputH = editorMainStore.outputHeight

    const blurredCropWidth = Math.min(originalVideoWidth, Math.round(originalVideoHeight / 1.77))
    const blurredCropHeight = originalVideoHeight
    const blurredCropX = Math.max(0, Math.round((originalVideoWidth - blurredCropWidth) / 2))

    const cropperFcX = Math.round(cropper1.x * inputW)
    const cropperFcY = Math.round(cropper1.y * inputH)
    const cropperFcW = Math.round(cropper1.w * inputW)
    const cropperFcH = Math.round(cropper1.h * inputH)

    const facecamPositionX = Math.round(fcFeed.x * outputW)
    const facecamPositionY = Math.round(fcFeed.y * outputH)

    const facecamWidthPixels = Math.round(fcFeed.w * outputW)
    const facecamHeightPixels = Math.round(fcFeed.h * outputH)

    const cropperGfX = Math.round(cropper2.x * inputW)
    const cropperGfY = Math.round(cropper2.y * inputH)
    const cropperGfW = Math.round(cropper2.w * inputW)
    const cropperGfH = Math.round(cropper2.h * inputH)

    const gamefeedPositionX = Math.round(gfFeed.x * outputW)
    const gamefeedPositionY = Math.round(gfFeed.y * outputH)

    // if theres a stickerlayer, theres an extra input, so the circlemask input is v:2 insteand of v:1
    const editorStickersStore = useEditorStickersStore()
    const circleMaskInputIndex = editorStickersStore.hasStickers ? 2 : 1

    let filterCommand = `[0:v]crop=${cropperFcW}:${cropperFcH}:${cropperFcX}:${cropperFcY}[croppedfc];[croppedfc]scale=${facecamWidthPixels}:${facecamHeightPixels}:flags=${SCALING_ALG}[fc];[${circleMaskInputIndex}:v]alphaextract[alfa];[alfa]scale=${facecamWidthPixels}:${facecamHeightPixels}[scaledalfa];[fc][scaledalfa]alphamerge[circle];[0:v]crop=${blurredCropWidth}:${blurredCropHeight}:${blurredCropX}:0[croppedbg];[croppedbg]scale=${outputW}:${outputH},boxblur=10[background];[0:v]crop=${cropperGfW}:${cropperGfH}:${cropperGfX}:${cropperGfY}[croppedgf];[croppedgf]scale=${outputW}:-2:flags=${SCALING_ALG}[gameplay];[background][gameplay]overlay=y=${gamefeedPositionY}:x=${gamefeedPositionX}[blurred];[blurred][circle]overlay=y=${facecamPositionY}:x=${facecamPositionX}:shortest=1`
    filterCommand = this._appendStickerLayerFilter(filterCommand)
    const ffmpegArgs = this._concatFfmpegCommand(['-filter_complex', filterCommand], true)

    editorMainStore.ffmpegStringArray = ffmpegArgs
    editorMainStore.layoutArguments = {
      FaceCam: {
        Width: cropperFcW,
        Height: cropperFcH,
        Left: cropperFcX,
        Top: cropperFcY,
        OutputPositionX: facecamPositionX,
        OutputPositionY: facecamPositionY,
        OutputWidth: facecamWidthPixels,
        OutputHeight: facecamHeightPixels,
      },
      GameFeed: {
        Width: cropperGfW,
        Height: cropperGfH,
        Left: cropperGfX,
        Top: cropperGfY,
        OutputPositionX: gamefeedPositionX,
        OutputPositionY: gamefeedPositionY,
      },
      BlurredCrop: {
        Width: blurredCropWidth,
        Height: blurredCropHeight,
        Left: blurredCropX,
      },
      TrimmerData: this._getTrimData(),
      OutputResolution: outputW,
    }
  },
  setGameUiArguments() {
    const editorMainStore = useEditorMainStore()
    const editorFeedDataStore = useEditorFeedDataStore()

    const fragment1 = editorFeedDataStore.fragment('gameui_fc')
    const fragment2 = editorFeedDataStore.fragment('gameui_gf')
    const fragment3 = editorFeedDataStore.fragment('gameui_ui')

    const cropper1 = fragment1.cropData
    const cropper2 = fragment2.cropData
    const cropper3 = fragment3.cropData

    const fcFeed = fragment1.feedData
    const gfFeed = fragment2.feedData
    const uiFeed = fragment3.feedData

    const originalVideoHeight = editorMainStore.videoHeight
    const originalVideoWidth = editorMainStore.videoWidth

    const inputW = editorMainStore.videoWidth
    const inputH = editorMainStore.videoHeight
    const outputW = editorMainStore.outputWidth
    const outputH = editorMainStore.outputHeight

    const blurredCropWidth = Math.min(originalVideoWidth, Math.round(originalVideoHeight / 1.77))
    const blurredCropHeight = originalVideoHeight
    const blurredCropX = Math.max(0, Math.round((originalVideoWidth - blurredCropWidth) / 2))

    const cropperFcX = Math.round(cropper1.x * inputW)
    const cropperFcY = Math.round(cropper1.y * inputH)
    const cropperFcW = Math.round(cropper1.w * inputW)
    const cropperFcH = Math.round(cropper1.h * inputH)

    const facecamPositionX = Math.round(fcFeed.x * outputW)
    const facecamPositionY = Math.round(fcFeed.y * outputH)

    const facecamWidthPixels = Math.round(fcFeed.w * outputW)
    const facecamHeightPixels = Math.round(fcFeed.h * outputH)

    const cropperGfX = Math.round(cropper2.x * inputW)
    const cropperGfY = Math.round(cropper2.y * inputH)
    const cropperGfW = Math.round(cropper2.w * inputW)
    const cropperGfH = Math.round(cropper2.h * inputH)

    const gamefeedPositionX = Math.round(gfFeed.x * outputW)
    const gamefeedPositionY = Math.round(gfFeed.y * outputH)

    const cropperUiX = Math.round(cropper3.x * inputW)
    const cropperUiY = Math.round(cropper3.y * inputH)
    const cropperUiW = Math.round(cropper3.w * inputW)
    const cropperUiH = Math.round(cropper3.h * inputH)

    const gameUiPositionX = Math.round(uiFeed.x * outputW)
    const gameUiPositionY = Math.round(uiFeed.y * outputH)
    const gameUiWidthPixels = Math.round(uiFeed.w * outputW)
    const gameUiHeightPixels = Math.round(uiFeed.h * outputH)

    let filterCommand = `
    [0:v]crop=${cropperFcW}:${cropperFcH}:${cropperFcX}:${cropperFcY}[croppedfc];[croppedfc]scale=${facecamWidthPixels}:${facecamHeightPixels}:flags=${SCALING_ALG}[fc];
    [0:v]crop=${cropperUiW}:${cropperUiH}:${cropperUiX}:${cropperUiY}[croppedui];[croppedui]scale=${gameUiWidthPixels}:${gameUiHeightPixels}:flags=${SCALING_ALG}[gameui];
    [0:v]crop=${blurredCropWidth}:${blurredCropHeight}:${blurredCropX}:0[croppedbg];[croppedbg]scale=${outputW}:${outputH},boxblur=10[background];
    [0:v]crop=${cropperGfW}:${cropperGfH}:${cropperGfX}:${cropperGfY}[croppedgf];[croppedgf]scale=${outputW}:-2:flags=${SCALING_ALG}[gameplay];
    [background][gameplay]overlay=y=${gamefeedPositionY}:x=${gamefeedPositionX}[blurred];[blurred][fc]overlay=y=${facecamPositionY}:x=${facecamPositionX}[gffc];
    [gffc][gameui]overlay=y=${gameUiPositionY}:x=${gameUiPositionX}`
    filterCommand = this._appendStickerLayerFilter(filterCommand)
    const ffmpegArgs = this._concatFfmpegCommand(['-filter_complex', filterCommand], true)

    editorMainStore.ffmpegStringArray = ffmpegArgs
    editorMainStore.layoutArguments = {
      FaceCam: {
        Width: cropperFcW,
        Height: cropperFcH,
        Left: cropperFcX,
        Top: cropperFcY,
        OutputPositionX: facecamPositionX,
        OutputPositionY: facecamPositionY,
        OutputWidth: facecamWidthPixels,
        OutputHeight: facecamHeightPixels,
      },
      GameFeed: {
        Width: cropperGfW,
        Height: cropperGfH,
        Left: cropperGfX,
        Top: cropperGfY,
        OutputPositionX: gamefeedPositionX,
        OutputPositionY: gamefeedPositionY,
      },
      GameUi: {
        Width: cropperUiW,
        Height: cropperUiH,
        Left: cropperUiX,
        Top: cropperUiY,
        OutputPositionX: gameUiPositionX,
        OutputPositionY: gameUiPositionY,
        OutputWidth: gameUiWidthPixels,
        OutputHeight: gameUiHeightPixels,
      },
      BlurredCrop: {
        Width: blurredCropWidth,
        Height: blurredCropHeight,
        Left: blurredCropX,
      },
      TrimmerData: this._getTrimData(),
      OutputResolution: outputW,
    }
  },
}
