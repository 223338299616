<script setup lang="ts">
import { Button } from '@/components/ui/button'
import IconSaxTickCircle from '@/components/Icons/iconsax/IconSaxTickCircle.vue'
import IconSaxArrowRight from '@/components/Icons/iconsax/IconSaxArrowRight.vue'
import { onMounted, ref } from 'vue'
import * as Sentry from '@sentry/browser'
import { accountsAxios } from '@/services/tokens'
import { useUserSubscriptionStore } from '@/store/user/userSubscription'
import logging from '@/logging'
import EventBus from '@/eventBus'
import mainEvents from '@/events/mainEvents'
import Spinner from '@/components/Icons/Spinner.vue'
import { useUserInfoStore } from '@/store/user/userInfo'

const subscriptionStore = useUserSubscriptionStore();
const userInfoStore = useUserInfoStore();

const isProcessing = ref(false)

onMounted(async () => {
  await cancelSubscription()
})

const cancelSubscription = async () => {

  isProcessing.value = true

  try {

    if (subscriptionStore.subscription?.type === 'paddle') {
      // For Paddle we need to pause the subscription, not cancel it
      await accountsAxios.put(`api/subscription/${subscriptionStore.subscription?.id}/pause`);
    } else if (subscriptionStore.subscription?.type === 'paypal') {
      await accountsAxios.put(`api/subscription/${subscriptionStore.subscription?.id}/cancel`, {
        reason: '',
      });
    }

    await subscriptionStore.fetchSubscriptionData(true);
    await userInfoStore.updateUserInfo();

    isProcessing.value = false;
    logging.trackEvent('Subscription Cancelled Frontend');
  } catch (e) {

    isProcessing.value = false;
    Sentry.captureException(e);
    EventBus.$emit(mainEvents.ERROR, "We couldn't cancel your subscription! Please contact our support!")

    return
  }
};
</script>

<template>
  <div class="flex flex-col gap-8 items-center text-center h-full">

    <div class="flex h-[500px]">
      <div class="w-full sm:w-2/3 p-4 gap-4 flex flex-col items-center justify-center">
        <h3 class="text-2xl sm:text-3xl lg:text-4xl">
          {{ isProcessing ? "Subscription canceling.." : "Subscription canceled." }}
        </h3>
        <p v-if="!isProcessing" class="font-light">
          Your subscription has been canceled. You won’t be billed again.
        </p>
        <div class="flex items-center justify-center w-full h-48">
          <Spinner
            v-if="isProcessing"
            class="h-32 w-32 animate-spin" />
          <IconSaxTickCircle
            v-else
            class="h-32 w-32 rounded-full fill-green-600 text-white dark:text-black"
          />
        </div>
        <Button variant="primary" as="a" href="/account/invoicing" :disabled="isProcessing">
          Go to my account
          <IconSaxArrowRight class="w-4 h-4" />
        </Button>
      </div>
      <div class="p-8 w-full sm:w-1/3 h-full bg-[#F0F0F3] dark:bg-zinc-400 hidden sm:flex flex-col items-center justify-center gap-8">
        <p class="font-light tracking-wide leading-8 story">
          Thank you for your support at StreamLadder! We're committed to improving what we build and hope to see you again in the future. Take care and happy streaming!
        </p>
        <p class="autograph text-4xl font-extralight">
          Lolke
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@font-face {
  font-family: autograph;
  src: url(/fonts/Dancing_Script/DancingScript-Regular.ttf);
}

.autograph {
  font-family: autograph, serif;
  cursor: default;
}

@font-face {
  font-family: story;
  src: url(/fonts/Aboreto/Aboreto-Regular.ttf);
}

.story {
  font-family: story, serif;
}
</style>