<script setup lang="ts">
import { posthog, type Survey, SurveyQuestionType } from 'posthog-js'
import { onMounted, ref } from 'vue'
import { Button } from '@/components/ui/button'
import { shuffle } from 'lodash-es'

const emit = defineEmits<{
  (event: 'next', selectedChoice: string): void
  (event: 'cancel'): void
  (event: 'previous'): void
}>()

const surveyName = 'Cancellation Survey - Why are you canceling?';
const survey = ref<Survey | null>(null);
const choices = ref<string[]>([]);

onMounted(() => {
  posthog.getActiveMatchingSurveys((allSurveys) => {

    const cancellationSurvey = allSurveys.find((survey) => survey.name === surveyName);

    if (cancellationSurvey) {

      survey.value = cancellationSurvey;

      if (cancellationSurvey.questions[0].type === SurveyQuestionType.MultipleChoice) {
        const allChoices = cancellationSurvey.questions[0]?.choices;
        choices.value = shuffle(allChoices);
      }
    }
  });
});

const selectedChoice = ref<string | null>(null);

const sendResponse = () => {

  if (survey.value && selectedChoice.value) {
    posthog.capture("survey sent", {
      $survey_id: survey.value.id,
      $survey_response: [selectedChoice.value]
    });
  }

  emit('next', selectedChoice.value!);
};
</script>

<template>
  <div class="flex p-4 lg:p-10">
    <div
      v-if="survey && survey.questions[0].type === SurveyQuestionType.MultipleChoice"
      class="relative flex flex-col gap-2 sm:gap-8 items-center text-center w-full">

      <h3 class="text-2xl sm:text-3xl lg:text-4xl max-w-xl">
        Why are you canceling?
      </h3>

      <div class="flex flex-wrap justify-center gap-3 max-w-lg">
        <div v-for="(choice, index) in choices" :key="`${choice}-${index}`" class="w-full sm:w-auto">
          <Button
            variant="outline"
            :class="{ 'bg-primary dark:bg-primary text-white cursor-default': selectedChoice === choice }"
            @click="selectedChoice = choice">
            {{ choice }}
          </Button>
        </div>
        <div class="w-full sm:w-auto">
          <Button
            variant="outline"
            :class="{ 'bg-primary dark:bg-primary text-white cursor-default': selectedChoice === 'Other' }"
            @click="selectedChoice = 'Other'">
            Other
          </Button>
        </div>
      </div>

      <div class="flex w-full justify-between absolute bottom-0">
        <Button
          variant="outline"
          @click="emit('previous')">
          Back
        </Button>
        <Button
          variant="primary"
          :disabled="!selectedChoice"
          @click="sendResponse">
          Next
        </Button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>