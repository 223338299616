<script setup lang="ts">
import CustomLayoutsModal from '@/modules/CustomLayouts/CustomLayoutsModal.vue'
import { useCropsStore, type Crop, useCropsStoreSetup } from '@/store/entity-system/useCropsStore'
import { toDictionary } from '@/store/entity-system/_toDictionary'
import { useLayoutsStore, useLayoutsStoreSetup, type Layout } from '@/store/entity-system/useLayoutsStore'
import { copyRef } from '@/store/entity-system/_copyRef'

const props = defineProps<{ id: string }>()

const cropsStore = useCropsStore()
const layoutsStore = useLayoutsStore()

const layout = layoutsStore.selectById(props.id)
const crops = cropsStore.whereLayoutIdIs(props.id)

const initialCropState = toDictionary(copyRef(crops.value as Crop[]))
const cropsContextStore = useCropsStoreSetup(initialCropState)
const layoutsContextStore = useLayoutsStoreSetup(copyRef({ [layout.id]: layout }))

const emit = defineEmits<{
  (event: 'save', layout: Layout, crops: Crop[]): void
  (event: 'close'): void
}>()

function save(layout: Layout, crops: Crop[]) {
  emit('save', layout, crops)
}
</script>

<template>
  <CustomLayoutsModal
    :id="layout.id"
    :layouts-store="layoutsContextStore"
    :crops-store="cropsContextStore"
    @save="save"
    @close="emit('close')"
    save-button-text="Save & continue to edit clip"
  />
</template>

<style scoped lang="scss">

</style>
