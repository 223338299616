<template>
  <div ref="element">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { useTimeLineAnimation } from '@/components-v2/modules/VideoPlayer/useVideoManager'
import type { AnimationStyle, AnimationTime, StickerData } from '@/store/editor/editorStickers'

const props = defineProps<{
  animationStyle: AnimationStyle
  animationTime: AnimationTime
  sticker: StickerData
  containerWidth: number
  duration?: number
  startTime?: number
  endTime?: number
}>()

const element = ref<HTMLElement | null>(null)

const start = computed(() => props.sticker.start)
const end = computed(() => props.sticker.end)

useTimeLineAnimation([element, start, end], (globalTimeline) => {
  if (start.value == undefined || end.value == undefined || !element.value) return
  const wordStart = Math.max(start.value / 1000, 0)
  const wordEnd = Math.max(end.value / 1000, 0)
  globalTimeline
    .set(
      element.value,
      {
        overflow: 'hidden',
        opacity: 0,
      },
      0
    )
    .fromTo(
      element.value,
      {
        opacity: 0,
      },
      {
        overflow: 'visible',
        opacity: 1,
        duration: 0,
      },
      wordStart
    )
    .fromTo(
      element.value,
      {
        opacity: 1,
      },
      {
        overflow: 'hidden',
        opacity: 0,
        duration: 0,
      },
      wordEnd
    )
})
</script>

<style lang="scss" scoped></style>
