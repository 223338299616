/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * StreamLadder.Api
 * OpenAPI spec version: 1.0
 */
import { useQuery } from '@tanstack/vue-query'
import type { UseQueryOptions, QueryFunction, QueryKey, UseQueryReturnType } from '@tanstack/vue-query'
import { unref } from 'vue'
import type { MaybeRef } from '@tanstack/vue-query/build/lib/types'
import type {
  GetApiTwitchClipsParams,
  GetApiTwitchClipsClipInfoParams,
  GetApiTwitchClipsStreamerParams,
} from '.././model'
import { streamLadderAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never

export const getApiTwitchClipsClipId = (
  clipId: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  return streamLadderAxiosInstance<void>({ url: `/api/TwitchClips/${unref(clipId)}`, method: 'get', signal }, options)
}

export const getGetApiTwitchClipsClipIdQueryKey = (clipId: MaybeRef<string>) => ['api', 'TwitchClips', clipId] as const

export const getGetApiTwitchClipsClipIdQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiTwitchClipsClipId>>,
  TError = unknown
>(
  clipId: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTwitchClipsClipId>>, TError, TData>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getApiTwitchClipsClipId>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiTwitchClipsClipIdQueryKey(clipId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTwitchClipsClipId>>> = ({ signal }) =>
    getApiTwitchClipsClipId(clipId, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!clipId, staleTime: 60000, ...queryOptions }
}

export type GetApiTwitchClipsClipIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTwitchClipsClipId>>>
export type GetApiTwitchClipsClipIdQueryError = unknown

export const useGetApiTwitchClipsClipId = <
  TData = Awaited<ReturnType<typeof getApiTwitchClipsClipId>>,
  TError = unknown
>(
  clipId: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTwitchClipsClipId>>, TError, TData>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiTwitchClipsClipIdQueryOptions(clipId, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}

export const getApiTwitchClips = (
  params?: MaybeRef<GetApiTwitchClipsParams>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  return streamLadderAxiosInstance<void>({ url: `/api/TwitchClips`, method: 'get', params, signal }, options)
}

export const getGetApiTwitchClipsQueryKey = (params?: MaybeRef<GetApiTwitchClipsParams>) =>
  ['api', 'TwitchClips', ...(params ? [params] : [])] as const

export const getGetApiTwitchClipsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiTwitchClips>>,
  TError = unknown
>(
  params?: MaybeRef<GetApiTwitchClipsParams>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTwitchClips>>, TError, TData>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getApiTwitchClips>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiTwitchClipsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTwitchClips>>> = ({ signal }) =>
    getApiTwitchClips(params, requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions }
}

export type GetApiTwitchClipsQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTwitchClips>>>
export type GetApiTwitchClipsQueryError = unknown

export const useGetApiTwitchClips = <TData = Awaited<ReturnType<typeof getApiTwitchClips>>, TError = unknown>(
  params?: MaybeRef<GetApiTwitchClipsParams>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTwitchClips>>, TError, TData>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiTwitchClipsQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}

/**
 * @deprecated
 */
export const getApiTwitchClipsClipInfo = (
  params?: MaybeRef<GetApiTwitchClipsClipInfoParams>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  return streamLadderAxiosInstance<void>({ url: `/api/TwitchClips/ClipInfo`, method: 'get', params, signal }, options)
}

export const getGetApiTwitchClipsClipInfoQueryKey = (params?: MaybeRef<GetApiTwitchClipsClipInfoParams>) =>
  ['api', 'TwitchClips', 'ClipInfo', ...(params ? [params] : [])] as const

export const getGetApiTwitchClipsClipInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiTwitchClipsClipInfo>>,
  TError = unknown
>(
  params?: MaybeRef<GetApiTwitchClipsClipInfoParams>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTwitchClipsClipInfo>>, TError, TData>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getApiTwitchClipsClipInfo>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiTwitchClipsClipInfoQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTwitchClipsClipInfo>>> = ({ signal }) =>
    getApiTwitchClipsClipInfo(params, requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions }
}

export type GetApiTwitchClipsClipInfoQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTwitchClipsClipInfo>>>
export type GetApiTwitchClipsClipInfoQueryError = unknown

/**
 * @deprecated
 */
export const useGetApiTwitchClipsClipInfo = <
  TData = Awaited<ReturnType<typeof getApiTwitchClipsClipInfo>>,
  TError = unknown
>(
  params?: MaybeRef<GetApiTwitchClipsClipInfoParams>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTwitchClipsClipInfo>>, TError, TData>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiTwitchClipsClipInfoQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}

/**
 * @deprecated
 */
export const getApiTwitchClipsStreamer = (
  params?: MaybeRef<GetApiTwitchClipsStreamerParams>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  return streamLadderAxiosInstance<void>({ url: `/api/TwitchClips/Streamer`, method: 'get', params, signal }, options)
}

export const getGetApiTwitchClipsStreamerQueryKey = (params?: MaybeRef<GetApiTwitchClipsStreamerParams>) =>
  ['api', 'TwitchClips', 'Streamer', ...(params ? [params] : [])] as const

export const getGetApiTwitchClipsStreamerQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiTwitchClipsStreamer>>,
  TError = unknown
>(
  params?: MaybeRef<GetApiTwitchClipsStreamerParams>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTwitchClipsStreamer>>, TError, TData>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getApiTwitchClipsStreamer>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiTwitchClipsStreamerQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiTwitchClipsStreamer>>> = ({ signal }) =>
    getApiTwitchClipsStreamer(params, requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions }
}

export type GetApiTwitchClipsStreamerQueryResult = NonNullable<Awaited<ReturnType<typeof getApiTwitchClipsStreamer>>>
export type GetApiTwitchClipsStreamerQueryError = unknown

/**
 * @deprecated
 */
export const useGetApiTwitchClipsStreamer = <
  TData = Awaited<ReturnType<typeof getApiTwitchClipsStreamer>>,
  TError = unknown
>(
  params?: MaybeRef<GetApiTwitchClipsStreamerParams>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiTwitchClipsStreamer>>, TError, TData>
    request?: SecondParameter<typeof streamLadderAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiTwitchClipsStreamerQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}
