import { useUserInfoStore } from '@/store/user/userInfo'
import { type ConnectionType } from '@/enums/connectionTypes'
import { accountsAxios } from '@/services/axios'
import EventBus from '@/eventBus'
import toastEvents from '@/events/toastEvents'
import { getPopupFeatures } from '@/helpers/PopupWindowHelper'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { useRouter } from 'vue-router'
import * as Sentry from '@sentry/browser'

export default function useCreateSocialConnection() {

  const userInfoStore = useUserInfoStore()
  const router = useRouter()

  const connect = async (platform: ConnectionType) => {

    const authWindow = await createAuthWindow(platform)
    const socialMedia = ({
      tiktok: 'TikTok',
      youtube: 'YouTube',
      instagram: 'Instagram',
    } as const)[platform]

    await userInfoStore.updateUserInfo()

    return new Promise<void>((resolve, reject) => {

      async function handleMessage(message: MessageEvent) {

        if (message.data === 'connection-success') {

          const beforeUpdate = userInfoStore.connections[platform]
          await userInfoStore.updateUserInfo()
          const afterUpdate = userInfoStore.connections[platform]

          authWindow.close()

          // In this case the user has connected a new account, but the account does not have access. Consult backend if this happens.
          if (afterUpdate.some(account => !account.hasAccess)) {
            EventBus.$emit(toastEvents.TOAST_ERROR, {
              title: `Failed to connect ${socialMedia} account.`,
              subtitle: "If this was not intentional, please try again or contact support.",
              view: () => {
                router.push({
                  name: dashboardRouteNames.support,
                })
              },
              viewTitle: 'Support',
            });

            console.error('User has connected a new account, but the account does not have access.');
            console.error(`Social account connection failed for ${platform}.`, {
              json: JSON.stringify(afterUpdate)
            });

            Sentry.captureException(new Error(`Social account connection failed for ${platform}`));

            return reject(`Could not connect ${socialMedia} account, if this was not intentional, please try again.`);
          }

          if (afterUpdate.length > beforeUpdate.length) {

            // The user has connected a new account.
            EventBus.$emit(toastEvents.TOAST_SUCCESS, {
              title: `${socialMedia} account connected successfully!`,
              timeout: 5000,
            });
          } else if (beforeUpdate.length === afterUpdate.length) {

            // The user has reconnected an account.
            const allBeforeIds = beforeUpdate.map(account => account.id);
            const allAfterIds = afterUpdate.map(account => account.id);

            if (allAfterIds.every(id => allBeforeIds.includes(id))) {
              EventBus.$emit(toastEvents.TOAST_SUCCESS, {
                title: `${socialMedia} account reconnected successfully!`,
                timeout: 5000,
              });

              // Some users have reported issues with TikTok, which requires a manual log out of TikTok, so we show a special message for them.
              if (platform === 'tiktok') {
                EventBus.$emit(toastEvents.TOAST, {
                  title: 'Are you still experiencing issues with TikTok? 🤕',
                  subtitle: 'Please try logging out of TikTok using the link on the right and try to connect your account again!',
                  timeout: 0,
                  viewTitle: 'Logout of TikTok',
                  view: () => {
                    const tikTokLogoutWindow = window.open(undefined, 'https://www.tiktok.com/logout', getPopupFeatures(800, 800))
                    if (tikTokLogoutWindow) {
                      tikTokLogoutWindow.location = 'https://www.tiktok.com/logout';
                      setTimeout(() => {
                        tikTokLogoutWindow.close();
                      }, 2000);
                    }
                  },
                });
              }
            } else {
              EventBus.$emit(toastEvents.TOAST_SUCCESS, {
                title: `${socialMedia} account connected successfully!`,
                timeout: 5000,
              });
            }
          } else {

            // In theory this should never happen, but if it does, we should still show a toast and log to Sentry.

            console.error(`Social account connection failed critically for ${platform}.`, {
              json: JSON.stringify(afterUpdate)
            });

            Sentry.captureException(new Error(`Social account connection failed for ${platform}`));

            EventBus.$emit(toastEvents.TOAST_ERROR, {
              title: `Failed to connect ${socialMedia} account.`,
              subtitle: `If you did not see a login screen, please manually log out on the ${socialMedia} website and try again.`,
            });

            window.removeEventListener('message', handleMessage);
            return reject(`Could not connect ${socialMedia} account, if this was not intentional, please try again.`);
          }

          window.removeEventListener('message', handleMessage);

          return resolve()
        }

        // The auth window was closed before the user could connect their account. This can be intentional or not.
        if (authWindow.closed) {
          window.removeEventListener('message', handleMessage);
          return reject(`Could not connect ${socialMedia} account, if this was not intentional, please try again.`);
        }
      }

      window.addEventListener('message', handleMessage);
    })
  }

  const connectUrls = {
    tiktok: `api/tiktok/v2/connect?returnUrl=${window.location.origin}/connection-success`,
    youtube: `api/youtube/connect?returnUrl=${window.location.origin}/connection-success`,
    instagram: `api/instagram/connect?returnUrl=${window.location.origin}/connection-success`,
  }

  async function fetchRedirectUrl(platform: ConnectionType): Promise<string> {
    const response = await accountsAxios.get(connectUrls[platform])
    if (response.status !== 200) throw new Error('Could not get redirect url')

    return response.data.redirectUrl
  }

  async function createAuthWindow(platform: ConnectionType): Promise<Window> {
    // Safari blocks popups not fired directly after user interaction. Async calls, such as `fetchRedirectUrl` should
    // occur *after* window creation.
    const authWindow = window.open(undefined, 'ConnectSocial', getPopupFeatures(800, 800))

    if (!authWindow) {
      throw new Error('Auth window is null')
    }

    authWindow.location = await fetchRedirectUrl(platform)
    return authWindow
  }

  return {
    connect,
  }
}