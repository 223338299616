<template>
  <div
    class="relative mb-4 flex w-full flex-col"
    v-click-outside="() => (this.showDropdown = false)"
    style="z-index: 299999"
  >
    <span class="mb-2 text-company-primary">Font</span>

    <div class="relative flex w-full">
      <div
        @click="showDropdown = !showDropdown"
        class="flex w-full cursor-pointer items-center space-x-4 rounded border border-company-primary-300 bg-white px-4 py-2 tracking-normal hover:bg-blue-50"
      >
        <div class="relative flex flex-1 flex-col">
          <p class="mb-0 text-company-primary">{{ displayable }}</p>
        </div>
        <caret-down-icon width="12" height="8" />
      </div>

      <div class="absolute top-0 w-full" :class="{ hidden: !showDropdown }">
        <div class="border-1 mt-12 w-full rounded border bg-white py-2 shadow-lg">
          <p class="mb-0 px-4 py-3 text-company-primary">{{ dropdownPlaceholder }}</p>

          <div class="-my-3 px-4">
            <stream-ladder-input v-if="hasSearch" type="text" v-model="search" placeholder="search" />
          </div>

          <div class="max-h-[300px] overflow-y-auto">
            <div
              class="flex w-full cursor-pointer items-center space-x-4 px-4 py-3 hover:bg-blue-50"
              :style="{ fontFamily: value.label }"
              @click="select(value)"
              :key="i"
              v-for="(value, i) in selectables"
            >
              {{ value.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StreamLadderInput from '../Form/StreamLadderInput.vue'
import CaretDownIcon from '../Icons/CaretDownIcon.vue'

export default {
  components: { CaretDownIcon, StreamLadderInput },
  props: {
    modelValue: { type: Object },
    data: { type: Array, required: true },
    hasSearch: { type: Boolean },
    dropdownPlaceholder: { type: String, default: 'Select your preference' },
  },
  data() {
    return {
      showDropdown: false,
      selected: null,
      search: '',
    }
  },
  computed: {
    displayable() {
      if (this.selected !== null && this.selected !== undefined) {
        return this.selected.label
      }

      return this.dropdownPlaceholder
    },
    selectables() {
      if (this.search === '') return this.data
      return this.data.filter((x) => x.label.toLowerCase().includes(this.search.toLowerCase()))
    },
  },
  methods: {
    select(value) {
      this.selected = value
      this.$emit('update:modelValue', this.selected)
      this.showDropdown = false
    },
  },
  mounted() {
    if (this.modelValue) this.selected = this.value
  },
}
</script>
