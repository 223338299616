<template>
  <div class="flex flex-col justify-center">
    <div
      :class="{
        'justify-evenly': block,
        'flex overflow-hidden rounded border bg-white': variant === 'box',
        'bg-button-group flex rounded-full p-2': variant === 'default',
      }"
      ref="setTikTokPublishMethod"
    >
      <template v-for="option in enhancedOptions" :key="`${id}-${option.value}`">
        <input
          :name="id"
          :id="`radio-${id}-${option.value}`"
          type="radio"
          :value="option.value"
          v-model="value"
          class="hidden"
        />
        <label
          :for="`radio-${id}-${option.value}`"
          tabindex="0"
          :data-tip="option.tooltip"
          class="flex cursor-pointer items-center justify-center py-1"
          :class="{
            'bg-company-primary-200 text-white': value === option.value,
            'text-company-primary-200': value !== option.value,
            'tooltip tooltip-secondary': !!option.tooltip,
            'flex-grow': block,
            'border-r px-3 py-2 text-sm last:border-r-0': variant === 'box',
            'hover:bg-blue-50': variant === 'box' && value !== option.value,
            'rounded-full px-3 py-1 text-sm': variant === 'default',
          }"
        >
          <Component v-if="option.component" :is="option.component" class="h-5 w-5" />
          <template v-else>
            {{ option.label }}
          </template>
        </label>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import type { Component } from 'vue'
import { v4 as uuid } from 'uuid'

const props = withDefaults(
  defineProps<{
    modelValue: string | number | boolean
    options: Option[]
    block?: boolean
    variant?: 'default' | 'box'
  }>(),
  {
    block: false,
    variant: 'default',
  }
)
const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number | boolean): void
}>()

const id = ref(uuid())

type Option = string | { label: string | Component; value: string | number | boolean; tooltip?: string }

const enhancedOptions = computed(() => {
  return props.options.map((option) => {
    // options is a simple string array
    if (typeof option === 'string') {
      return {
        label: option,
        value: option,
      }
    }
    if (typeof option.label === 'string') {
      return option
    }
    // this option has a component
    return {
      ...option,
      label: option.value,
      component: option.label,
    }
  })
})

const value = computed({
  get: () => {
    return props.modelValue
  },
  set: (value) => {
    emit('update:modelValue', value)
  },
})
</script>
