<template>
  <RadixErrorDialog v-model:open="open" @update:open="close">
    <template #title>Something went wrong</template>
    <template #description>
      <span v-if="message" v-html="message" />
    </template>
  </RadixErrorDialog>
</template>
<script>
import mainEvents from '../../events/mainEvents'
import EventBus from '../../eventBus'
import RadixErrorDialog from '@/components/Dialog/RadixErrorDialog.vue'

export default {
  components: { RadixErrorDialog },
  data() {
    return {
      message: null,
      open: false,
      events: {
        open: mainEvents.ERROR,
        close: mainEvents.CLOSE_ERROR,
      },
    }
  },
  methods: {
    close() {
      EventBus.$emit(this.events.close)
    },
    onOpen(message) {
      this.open = true
      this.message = message
    },
    onClose() {
      this.open = false
      this.message = null
    },
  },
  mounted() {
    EventBus.$on(this.events.open, this.onOpen)
    EventBus.$on(this.events.close, this.onClose)
  },
  beforeUnmount() {
    EventBus.$off(this.events.open, this.setMessage)
    EventBus.$off(this.events.close, this.onClose)
  },
}
</script>
