<script setup lang="ts">
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import IconSaxMinus from '@/components/Icons/iconsax/IconSaxMinus.vue'

const props = defineProps<{
  isSelected: boolean
  disabled?: boolean
}>()
</script>

<template>
  <GradientButton
    v-if="!props.isSelected"
    class="layer-2 !shadow-gradient-none mt-4 !h-[50px] w-full items-center justify-center gap-4 rounded-lg !px-4 !font-semibold tracking-wide transition-all"
    :class="{ 'pointer-events-none': disabled }"
    :disabled="disabled"
  >
    <span>Add</span>
    <IconSaxAdd class="shrink-0 [&>path]:stroke-2" />
  </GradientButton>
  <button
    v-else
    class="layer-2 mt-4 !h-[50px] w-full items-center justify-center gap-4 rounded-lg border-2 border-gray-200 p-2.5 !px-4 !font-semibold tracking-wide !shadow-none transition-all hover:border-gray-500"
  >
    <span>Remove</span>
    <IconSaxMinus class="shrink-0 [&>path]:stroke-2" />
  </button>
</template>

<style scoped lang="scss"></style>
