// Url parsing and data fetching
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { isArray } from 'lodash-es'
import { useGetApiRendersId } from '@/apis/streamladder-api/renders/renders'
import type { DeepPartial } from 'ts-essentials'
import {
  type ContentPublisherFormState,
  MarketingHashtags,
} from '@/areas/dashboard/pages/contentPublishing/ContentPublishHelpers'

export const useContentPublisherRenderData = () => {
  const route = useRoute()

  const renderId = computed(() => {
    if (isArray(route.params.renderId) || !route.params.renderId) {
      return undefined
    }
    return route.params.renderId
  })
  const hasRender = computed(() => {
    return !!renderId.value
  })

  const { data, isLoading } = useGetApiRendersId(renderId.value || '', {
    query: {
      enabled: hasRender,
    },
  })

  const renderData = computed(() => {
    return data.value?.value
  })

  const getFormData = (): DeepPartial<ContentPublisherFormState> => {
    return {
      youtube: {
        title: renderData.value?.title || undefined,
        description: MarketingHashtags,
      },
      instagram: {
        title: renderData.value?.title || undefined,
      },
      tikTok: {
        title: renderData.value?.title + ' ' + MarketingHashtags || MarketingHashtags,
      },
    }
  }

  return {
    renderId,
    hasRender,
    renderData,
    isLoading,
    getFormData,
  }
}
