import features from '@/assets/features.json'
import unwrap from '@/helpers/unwrap'

const Tiers = {
  free: 0,
  silver: 10,
  gold: 100,
} as const

function findFeaturesBy(...tiers: (keyof typeof Tiers)[]) {
  const allFeatures = features.categories.flatMap((c) => c.features)
  const foundFeatures: string[] = []

  for (const tier of tiers) {
    for (const feature of allFeatures) {
      const value = feature.values[tier]
      if (!isInLowerTier(tier, feature) && value !== false) {
        foundFeatures.push(typeof value === 'string' ? `${feature.name} (${value})` : feature.name)
      }
    }
  }

  return foundFeatures
}

function isInLowerTier(
  currentTier: keyof typeof Tiers,
  feature: (typeof features)['categories'][number]['features'][number]
) {
  for (const [tier, value] of unwrap.entries(feature.values)) {
    if (Tiers[tier] < Tiers[currentTier]) {
      if (value === feature.values[currentTier]) {
        return true
      }
    }
  }
  return false
}

export const allFeaturesByTier = Object.freeze({
  free: findFeaturesBy('free'),
  silver: findFeaturesBy('silver'),
  gold: findFeaturesBy('gold'),
} as const)
