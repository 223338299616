<script setup lang="ts">
import { computed } from 'vue'
import { useElementSize, useParentElement } from '@vueuse/core'

const props = defineProps<{
  duration: number
  stepSize: number
  numberInterval: number
}>()

const parent = useParentElement()
const { width } = useElementSize(parent)

const timelineDials = computed(() => {
  const dials = []
  const { duration, stepSize, numberInterval } = props

  const expectedLabelWidth = 50
  const maxDials = Math.floor(width.value / expectedLabelWidth)
  const totalSteps = Math.min((duration * 1000) / stepSize, maxDials)

  const _stepSize = (duration * 1000) / totalSteps

  for (let i = 0; i < totalSteps; i++) {
    const step = i * _stepSize
    if (i % numberInterval === 0) {
      if (Math.abs(step - duration * 1000) < _stepSize) continue
      dials.push({
        label: `${Math.floor(step / 1000)}s`,
        value: step,
      })
    } else {
      if (Math.abs(step - duration * 1000) < _stepSize) continue
      dials.push({
        label: '·',
        value: step,
      })
    }
  }
  dials.push({
    label: `${Math.round(duration)}s`,
    value: duration * 1000,
  })
  return dials
})
</script>

<template>
  <div class="relative select-none">
    <span
      class="step pointer-events-none absolute transform select-none first:translate-x-0 last:-translate-x-full"
      v-for="(step, index) in timelineDials"
      :key="index"
      :style="{
        left: `${step.value / (duration * 10)}%`,
      }"
    >
      {{ step.label }}
    </span>
  </div>
</template>

<style scoped lang="scss"></style>
