import { computed, ref, type Ref } from 'vue'
import type { VideoResultDto } from '@/apis/streamladder-api/model'
import { useContentPublisherStore } from '@/components-v2/content-publisher/_store'

const validOptions = ['scheduled', 'unscheduled', null] as const

type Filter = (typeof validOptions)[number]

export const videosFilter = ref<Filter>('unscheduled')
export const videosFilterOptions = [
  { label: 'None', value: null },
  { label: 'Scheduled', value: 'scheduled' },
  { label: 'Not scheduled', value: 'unscheduled' },
] as const satisfies Readonly<{ label: string; value: Filter }[]>

export function useFilterVideos(videos: Ref<VideoResultDto[]>) {
  const contentPublisherStore = useContentPublisherStore()
  const isLoadingPosts = computed(() => contentPublisherStore.isPerformingBackgroundAction)

  const scheduledVideos = computed(() => {
    return videos.value.filter((video) => {
      return contentPublisherStore.posts.some((post) => {
        return post.contentUrl === video.resultUrl
      })
    })
  })

  const unpublishedVideos = computed(() => {
    return videos.value.filter((video: VideoResultDto) => !scheduledVideos.value.some((v) => v.id === video.id))
  })

  const filteredVideos = computed(() => {
    if (videosFilter.value === 'scheduled') {
      return scheduledVideos.value
    } else if (videosFilter.value === 'unscheduled') {
      return unpublishedVideos.value
    } else if (videosFilter.value === null) {
      return videos.value
    }
    return videos.value
  })

  return { filteredVideos, isLoadingPosts }
}
