/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * StreamLadder Publisher Api
 * OpenAPI spec version: v1
 */

export type TargetState = (typeof TargetState)[keyof typeof TargetState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TargetState = {
  New: 'New',
  Queued: 'Queued',
  Scheduled: 'Scheduled',
  Processing: 'Processing',
  Publishing: 'Publishing',
  Failed: 'Failed',
  Published: 'Published',
  SentToInbox: 'SentToInbox',
} as const
