<script setup lang="ts">
import { useLayoutsStore, type Layout } from '@/store/entity-system/useLayoutsStore'
import { PopoverRoot, PopoverTrigger, PopoverContent } from 'radix-vue'
import { IconTrash, IconDotsVertical, IconEdit } from '@tabler/icons-vue'
import { ref, computed } from 'vue'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import EditCustomLayoutsModal from '@/modules/CustomLayouts/EditCustomLayoutsModal.vue'
import NewCustomLayoutsModal from '@/modules/CustomLayouts/NewCustomLayoutsModal.vue'
import type { Crop } from '@/store/entity-system/useCropsStore'
import GoldPlanButton from '@/components/Account/Upgrade/GoldPlanButton.vue'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { useConfirmDialog } from '@vueuse/core'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import { deleteCustomLayout, updateCustomLayoutById } from '@/store/entity-system/queries/customLayoutsQueries'
import { useScreen } from '@/Hooks/useScreen'
import { useGuard } from '@/Hooks/useGuard'
import { useUserInfoStore } from '@/store/user/userInfo'

const editorVideoStore = useEditorVideoStore()

const layoutsStore = useLayoutsStore()
const layouts = layoutsStore.sortByRecency()

const editDialogId = ref<string | null>(null)
function editLayout(layout: Layout) {
  editDialogId.value = layout.id
}

const { reveal, isRevealed: showConfirmDeleteDialog, cancel: cancelDelete, confirm: confirmDelete } = useConfirmDialog()

async function deleteLayout(layout: Layout) {
  const { isCanceled } = await reveal()
  if (!isCanceled) {
    await deleteCustomLayout(layout.id).then(() => {
      if (props.selected === layout.id) {
        emit('select', null)
      }
    })
  }
}

const newCustomLayoutModalOpen = ref(false)

async function saveNewTemplate(layout: Layout, crops: Crop[]) {
  await updateCustomLayoutById(layout.id, { layout, crops })
  newCustomLayoutModalOpen.value = false
  setTimeout(() => emit('save', layout.id), 0)
}

async function updateExistingTemplate(layout: Layout, crops: Crop[]) {
  await updateCustomLayoutById(layout.id, { layout, crops })
  editDialogId.value = null
  setTimeout(() => emit('save', layout.id), 0)
}

const props = defineProps<{
  selected: string | null | undefined
}>()

const emit = defineEmits<{
  (event: 'select', id: string | null): void
  (event: 'save', id: string): void
}>()

//grid-cols-2 gap-4 sm:grid-cols-3 xl:grid-cols-4
const sm = useScreen('sm')
const xl = useScreen('xl')
const amountOfSkeletons = computed(() => {
  if (xl.value) {
    return 3
  } else if (sm.value) {
    return 2
  } else {
    return 1
  }
})

const canUseCustomLayouts = useGuard('custom-layouts')
const userInfoStore = useUserInfoStore()
</script>

<template>
  <button
    @click="newCustomLayoutModalOpen = true"
    class="aspect-[202/373] relative flex h-full w-full cursor-pointer flex-col items-center justify-center gap-2 rounded-xl border-2 border-[#f9f6fe] p-4 transition-[border-color,_transform,_opacity] hover:border-company-secondary-200 active:scale-90"
  >
    <span class="grid h-8 w-8 place-items-center rounded-full bg-[#8833FF] text-white">
      <IconSaxAdd class="h-6 w-6 [&>path]:stroke-[2]" />
    </span>
    <span class="text-sm font-semibold text-[#8833FF]">Custom Layout</span>
    <span class="text-[13px] font-light text-[#8833FF]/75">Add as many crop areas as you need!</span>
    <GoldPlanButton feature="custom-layouts" class="absolute top-2 right-2" small />
    <span class="rounded-lg border-2 border-current px-1.5 py-0.5 text-xs font-bold text-green-600 absolute top-2 left-2">
      NEW
    </span>
  </button>

  <template v-if="layoutsStore.isLoading && userInfoStore.isLoggedIn">
    <article
      v-for="key in amountOfSkeletons"
      :key="key"
      class="flex flex-col h-full w-full rounded-xl p-4 aspect-[202/373] skeleton"
    />
  </template>
  <template v-else-if="canUseCustomLayouts">
    <article
      v-for="layout in layouts"
      :key="layout.id"
      class="flex flex-col h-full w-full cursor-pointer rounded-xl border-2 p-4 aspect-[202/373]"
      :class="{
        'border-2 border-[#6523c2]': layout.id === props.selected,
        'border-transparent hover:border-company-secondary-200': layout.id !== props.selected,
      }"
      :style="{ backgroundColor: layout.color }"
      @click="emit('select', layout.id)"
    >
      <h3 class="text-2xl font-bold text-gray-950">
        <span class="block text-4xl">{{ layout.emoji }}</span>
        <span class="break-words line-clamp-3 ellipse">{{ layout.name }}</span>
      </h3>
      <div class="mt-auto ml-auto" @click.stop>
        <PopoverRoot>
          <PopoverTrigger>
            <button class="btn-ghost btn-square btn grid h-8 min-h-0 w-8 place-items-center rounded text-gray-950">
              <IconDotsVertical :size="20" />
            </button>
          </PopoverTrigger>
          <PopoverContent side="bottom">
            <Transition appear name="fade">
              <ol class="layer-2 flex flex-col rounded-xl shadow-lg transition-all absolute z-[999] -translate-x-1/2" tabindex="0">
                <li>
                  <button
                    @click="editLayout(layout)"
                    class="flex w-full justify-start gap-2 whitespace-nowrap rounded-t-xl px-4 py-2 text-left hover:bg-zinc-200 dark:hover:bg-zinc-900"
                  >
                    <IconEdit /> Edit
                  </button>
                </li>
                <li>
                  <button
                    class="flex w-full items-center justify-start gap-2 whitespace-nowrap rounded-b-xl px-4 py-2 text-left text-rose-500 hover:bg-rose-500 hover:text-white"
                    @click="deleteLayout(layout)"
                  >
                    <IconTrash /> Delete
                  </button>
                </li>
              </ol>
            </Transition>
          </PopoverContent>
        </PopoverRoot>
      </div>
    </article>
  </template>

  <Teleport to="body">
    <template v-if="editorVideoStore.videoElement && editorVideoStore.videoSize">
      <EditCustomLayoutsModal v-if="editDialogId" :id="editDialogId" @close="editDialogId = null" @save="updateExistingTemplate" />
      <NewCustomLayoutsModal v-if="newCustomLayoutModalOpen" @close="newCustomLayoutModalOpen = false" @save="saveNewTemplate" />
      <article class="modal" :class="{ 'modal-open': showConfirmDeleteDialog }" @keydown.esc="cancelDelete">
        <div class="modal-box layer-1 flex flex-col gap-4">
          <h2 class="text-2xl font-bold">Delete Layout</h2>
          <p class="font-light">Are you sure you want to delete this layout? This action cannot be undone.</p>
          <div class="flex gap-2">
            <button @click="confirmDelete" class="btn btn-primary bg-rose-400 gap-2 shadow shadow-rose-200 border-rose-200 hover:bg-rose-500 hover:border-rose-300">
              <IconSaxTrash class="h-4 w-4" />
              Delete
            </button>
            <button @click="cancelDelete" class="btn btn-ghost">Cancel</button>
          </div>
        </div>
      </article>
    </template>
    <template v-else-if="editDialogId || newCustomLayoutModalOpen || showConfirmDeleteDialog">
      <div class="modal modal-open" @click="() => { editDialogId = null; newCustomLayoutModalOpen = false; showConfirmDeleteDialog = false; }">
        <span class="spinner spinner-border text-sky-400 !h-12 !w-12 !border-4 drop-shadow" />
      </div>
    </template>
  </Teleport>
</template>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 1;
}

.fade-enter-from,
.fade-leave {
  opacity: 0;
}
</style>
