<template>
  <div class="flex items-center space-x-8" :class="status">
    <div v-if="status === 'in-endless-progress'" class="flex h-16 w-16 flex-shrink-0 items-center justify-center">
      <div class="spinner-border !w-[50px] !h-[50px] text-secondary" role="status" />
    </div>
    <div v-else-if="status === 'in-progress'">
      <div class="flex h-16 w-16 justify-center">
        <radial-progress :progress="progressPercentage" />
      </div>
    </div>
    <IconCircleArrowRight v-else-if="status === 'idle'" class="flex h-16 w-16 flex-shrink-0 justify-center stroke-1" />
    <IconCircleCheck v-else-if="status === 'success'" class="flex h-16 w-16 flex-shrink-0 justify-center stroke-1 text-emerald-600" />
    <IconCircleXFilled v-else-if="status === 'error'" class="flex h-16 w-16 flex-shrink-0 justify-center stroke-1 text-red-600" />
    <div class="flex-1">
      <h5 class="text-lg">{{ statusText }}</h5>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import RadialProgress from '../Dialog/RadialProgress.vue'
import { IconCircleArrowRight, IconCircleXFilled, IconCircleCheck } from "@tabler/icons-vue";

export default {
  components: { RadialProgress, IconCircleArrowRight, IconCircleXFilled, IconCircleCheck },
  props: {
    statusText: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: 'in-progress',
    },
    progressPercentage: {
      type: Number,
      default: -1,
    },
  },
}
</script>

<style lang="scss" scoped>
.idle {
  opacity: 0.5;
}
</style>
