<script lang="ts" setup>
import { range } from 'lodash-es'
import { useTwitchClips, type Clip } from '@/Hooks/useTwitchClips'
import ClipPreviewCard from '@/components/Clips/ClipPreviewCard.vue'
import { computed, ref } from 'vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import TwitchClipPreviewDialog from '@/components-v2/modules/HomePage/TwitchClipPreviewDialog.vue'

const userInfoStore = useUserInfoStore()

const recentClips = useTwitchClips({ filter: 'recency' })

const isLoading = computed(() => {
  return userInfoStore.isAuthenticated ? recentClips.isLoading.value : false
})

const sampleClips : Clip[] = [
  {"id":"CrispyHeartlessAdminThisIsSparta-SYsnQDmG57Yr3Dyq","title":"family picture","thumbnailUrl":"https://clips-media-assets2.twitch.tv/o_Na8UmLJ0A5hue2K9w8qg/39981367973-offset-11096-preview-480x272.jpg","mp4Url":"https://sl-clips.b-cdn.net/o_Na8UmLJ0A5hue2K9w8qg/39981367973-offset-11096.mp4","viewCount":723,"languageCode":"EN","broadcasterName":"g_birb","broadcasterProfileImage":"https://static-cdn.jtvnw.net/jtv_user_pictures/c9ac3d3d-fe03-4e2c-8683-58856c8a55b5-profile_image-50x50.png","gameName":"Dead by Daylight","gameBoxArt":"https://static-cdn.jtvnw.net/ttv-boxart/491487-52x72.jpg","taskId":null,"createdAt":"2023-09-21T15:12:47.3007908Z","dateFromNow":null},
  {"id":"AnimatedMoralWalletMingLee-ODj2KlFT8ICSSGJs","title":"Murder","thumbnailUrl":"https://clips-media-assets2.twitch.tv/W_N7szg49ipRU_CAkkhibA/AT-cm%7CW_N7szg49ipRU_CAkkhibA-preview-480x272.jpg","mp4Url":"https://sl-clips.b-cdn.net/W_N7szg49ipRU_CAkkhibA/AT-cm%7CW_N7szg49ipRU_CAkkhibA.mp4","viewCount":123,"languageCode":"EN","broadcasterName":"JoeR247","broadcasterProfileImage":"https://static-cdn.jtvnw.net/jtv_user_pictures/c1ed810c-ad23-4a74-8864-ff4c58fa1ca4-profile_image-50x50.png","gameName":"EA Sports WRC","gameBoxArt":"https://static-cdn.jtvnw.net/ttv-boxart/1580396182_IGDB-52x72.jpg","taskId":null,"createdAt":"2023-10-14T21:03:28.9373782Z","dateFromNow":null},
  {"id":"ExuberantToughAnteaterTakeNRG-FDURk-g0tD3ue2Kq","title":"Slashing bodies!! !dixper !discord !tiktok !youtube |Team OP|","thumbnailUrl":"https://clips-media-assets2.twitch.tv/q9Nx3xQ1EZPoNLQ97ntiqg/41018220264-offset-1192-preview-480x272.jpg","mp4Url":"https://sl-clips.b-cdn.net/q9Nx3xQ1EZPoNLQ97ntiqg/41018220264-offset-1192.mp4","viewCount":91,"languageCode":"EN","broadcasterName":"hamsterlovin_","broadcasterProfileImage":"https://static-cdn.jtvnw.net/jtv_user_pictures/dcd22225-5445-45e7-af3a-6dc997dd523c-profile_image-50x50.png","gameName":"Dead by Daylight","gameBoxArt":"https://static-cdn.jtvnw.net/ttv-boxart/491487-52x72.jpg","taskId":null,"createdAt":"2023-08-24T23:50:34.8229111Z","dateFromNow":null}
];

const clips = computed(() => {
  return userInfoStore.isAuthenticated && recentClips.clips.value.length > 0
    ? recentClips.clips.value
    : sampleClips
})

const emit = defineEmits<{ (name: 'select', clip: Clip): void }>()

function select(clip: Clip) {
  emit('select', clip)
}

const previewId = ref<string | null>(null)

function preview(clip: Clip) {
  previewId.value = clip.id
}
</script>

<template>

  <h4 v-if="userInfoStore.isAuthenticated && recentClips.clips.value?.length > 0" class="m-0 p-0 text-lg font-normal lg:text-xl">
    Your most recent clips
  </h4>

  <h4 v-else class="text-lg font-normal lg:text-xl">
    Try out the editor with a sample clip
  </h4>

  <ol class="grid-single-row -m-4 grid grid-cols-2 gap-x-2 p-4 pb-0 md:grid-cols-3 md:gap-x-4">
    <li v-for="i in range(3)" :key="'sample-clip-' + i">
      <ClipPreviewCard
        :clip="clips[i]"
        :disable-preview="!userInfoStore.isAuthenticated"
        :is-loading="isLoading || !userInfoStore.hasLoadedUserInfo"
        overlay-scaling="scale-75"
        @select="select"
        @preview="preview"
      />
    </li>
  </ol>

  <teleport v-if="userInfoStore.isAuthenticated && clips" to="body">
    <TwitchClipPreviewDialog v-model="previewId" :clips="clips" @confirm="select" />
  </teleport>
</template>

<style lang="scss" scoped></style>
