<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
    <path
      d="M34.2249 14.7356H6.29199V26.2374H34.2249V14.7356Z"
      fill="transparent"
      stroke="currentColor"
      stroke-width="4.64312"
      stroke-linejoin="round"
    />
    <path
      d="M20.2583 5.69849V35.2746"
      stroke="currentColor"
      stroke-width="4.64312"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
