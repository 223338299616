<script setup lang="ts">
import { useCampaignComponent } from '@/data/campaigns'
import { useHead } from '@unhead/vue'
import { computed, onBeforeUnmount, onMounted, onUnmounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import FeatureShowcase from '@/components/Clip2TikTok/FeatureShowcase.vue'
import AsyncComponent from '@/components/Campaigns/AsyncComponent.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { Button } from '@/components/ui/button'
import DiscordLogo from '@/components/Icons/DiscordLogo.vue'
import settings from '@/data/settings'
import { VideoWorkerManager } from '@/webcodec-renderer/VideoWorkerManager'
import axios from 'axios'
import uploadService from '@/services/uploadService'
import { postApiVideos } from '@/apis/streamladder-api/videos/videos'
import * as Sentry from '@sentry/vue'
import { getApiRendersId, getApiRendersIdDetails } from '@/apis/streamladder-api/renders/renders'
import type { CreateRenderDto, RenderDetailsDto } from '@/apis/streamladder-api/model'
import { useUserInfoStore } from '@/store/user/userInfo'
import useLogin from '@/Hooks/useLogin'
import IconSaxRefresh2 from '@/components/Icons/iconsax/IconSaxRefresh2.vue'
import { useConfirmDialog } from '@/components/Dialog/Confirm/useConfirmDialog'
import { useToast } from '@/Hooks/useToast'
import toastEvents from '@/events/toastEvents'
import ArrowRightIcon from '@/components/Icons/ArrowRightIcon.vue'
import GoldPlanButton from '@/components/Account/Upgrade/GoldPlanButton.vue'
import { tiers } from '@/enums/tiers'
import { videoWorkerManagerQueue } from '@/webcodec-renderer/VideoWorkerManagerQueue'
import { watchImmediate } from '@vueuse/core'
import { useStreamLadderRender } from '@/areas/editor/hooks/useStreamLadderRender'
import { noop } from 'ts-essentials'
import { useIsMobile } from '@/Hooks/useIsMobile'
import IconSaxExportCurve from '@/components/Icons/iconsax/IconSaxExportCurve.vue'
import { editorRouteNames } from '@/areas/editor/routeNames'
import { runWebcodecTest } from '@/webcodec-renderer/worker/webcodec-test'
import RadialProgress from '@/components/Dialog/RadialProgress.vue'
import IconSaxArrowRight from '@/components/Icons/iconsax/IconSaxArrowRight.vue'
import DynamicPlanButtonWithTooltip from '@/components/Account/Upgrade/DynamicPlanButtonWithTooltip.vue'
import { canGuardWithPopup } from '@/Hooks/useGuard'
import OnboardingSurveyFlow from '@/components/Onboarding/OnboardingSurveyFlow.vue'

const LoaderComponent = useCampaignComponent('generate-loader')
const route = useRoute()
const router = useRouter()

const userInfo = useUserInfoStore()

const showRetryButton = ref(true)
const videoHasErrors = ref(false)
const errorMessage = ref("Can't render video")
const { awaitUserLogin } = useLogin()

const showFakeQueue = ref(false);
const fixToolDialogOpen = ref(false);

const confirmDialog = useConfirmDialog();

const { requestWebcodecRender } = useStreamLadderRender();

const isRendering = ref(true)
const renderingProgressMessage = ref<string | null>(null)
const downloadProgressMessage = ref<string | null>(null)
const localResultUrl = ref('')
const previewCanvas = ref<HTMLCanvasElement>()

const videoWorkerManagerId = ref<string | null>(null);

const renderId = route.query.renderId as string;
const hasRepaired = route.query.hasRepaired as string;

const webhookEventUrl = ref<string | null>(null);
const renderDataId = ref<string | null>(null);

const isOnRenderPage = ref(false);

const { showToast } = useToast();

const showToastsOnUnmount = ref(true);

const videoTitle = ref<string | null>(null);

const forcedRenderOnServer = ref(false);

const notifyRenderTaskWebhookWithError = async () => {
  if (webhookEventUrl.value && renderDataId.value) {
    await axios.post(webhookEventUrl.value, {
      taskId: renderDataId.value,
      status: 'error',
      message: 'Error rendering',
    });
  }
}

const videoIsAlreadyCreated = async () => {

  try {
    const response = await getApiRendersId(renderId || '');
    return !!(response.value && response.value.status === 'success' && response.value.resultUrl);
  } catch (e) {

    videoHasErrors.value = true;
    showRetryButton.value = false;
    errorMessage.value = '404 - Render not found';

    console.error({
      message: 'Error checking if video is already created',
      renderId: renderId,
      error: e,
    });

    Sentry.captureException(new Error('WebCodec Renderer Error: User tried to render a video with an invalid renderId'));

    return false;
  }
}

const startRender = async (onError = noop) => {

  console.log(`Starting render for renderId: ${renderId}`);

  await userInfo.updateUserInfo();

  const userIsAuthenticated = await awaitUserLogin('Please login to render your video')

  if (!userIsAuthenticated) {
    videoHasErrors.value = true;
    errorMessage.value = 'You need to be logged in to render videos.';
    useHead({ title: 'Error' })
    await notifyRenderTaskWebhookWithError();
    Sentry.captureException(new Error('WebCodec Renderer Error: User not authenticated'));
    return;
  }

  let isAlreadyCreated = false;
  try {
    isAlreadyCreated = await videoIsAlreadyCreated();
  } catch (e) {
    videoHasErrors.value = true;
  }

  if (isAlreadyCreated) {

    const confirmDialog = useConfirmDialog();

    const hasConfirmed = await confirmDialog.reveal({
      title: "This video has already been rendered",
      message: "Do you want to render it again?",
    });

    if (hasConfirmed) {
      confirmDialog.close();
    } else {
      showToastsOnUnmount.value = false;
      confirmDialog.close();
      await router.push({
        name: dashboardRouteNames.contentPublisher.render,
        params: { renderId: renderId },
      });
      return;
    }
  }

  useHead({
    title: 'Rendering..',
    bodyAttrs: {
      class: 'bg-base-100',
    },
  })

  const response = await getApiRendersIdDetails(route.query.renderId)

  if (!response.value) {
    videoHasErrors.value = true
    showRetryButton.value = false
    useHead({ title: 'Error' })
    Sentry.captureException(new Error('WebCodec Renderer: Render Details Not Found', {
      renderId: route.query.renderId,
    }));
    return
  } else {
    isRendering.value = true;
  }

  const renderData = response.value as RenderDetailsDto;
  webhookEventUrl.value = renderData.webhookEventUrl!;
  renderDataId.value = renderData.id!;
  videoTitle.value = renderData.title!;

  const isValidRenderDataObject = validateRenderDataObject(renderData);

  if (!isValidRenderDataObject) {
    videoHasErrors.value = true
    showRetryButton.value = false
    errorMessage.value = "There's an issue in your render data 🗿"
    useHead({ title: 'Error' })
    console.error(JSON.stringify(renderData))
    Sentry.captureException(new Error('WebCodec Renderer Error: Invalid render data object'))
    return
  }

  if (videoHasErrors.value) {

    if (webhookEventUrl.value) {
      await axios.post(webhookEventUrl.value, {
        taskId: renderDataId.value,
        status: 'error',
        message: 'Error rendering',
      });
    }

    // If there's an error, we don't want to continue rendering.
    return;
  }

  const outputSize = {
    width: renderData.outputResolution === 1080 ? 1080 : 720,
    height: renderData.outputResolution === 1080 ? 1920 : 1280,
  };

  const videoWorkerManager = new VideoWorkerManager({

    id: renderId,

    renderDetails: renderData,
    width: outputSize.width,
    height: outputSize.height,

    previewCanvas: previewCanvas.value,

    onLog: (message) => {
      console.log(message)
    },
    onProgress: (message) => {
      useHead({ title: message })
      renderingProgressMessage.value = message
    },
    onError: (message) => {

      if (hasRepaired === 'true') {
        attemptRender(true);
        return;
      }

      const item = videoWorkerManagerQueue.getItemById(videoWorkerManager.id!);
      if (item) {
        item.terminate();
      }

      onError(message)
    },
    onDownloadProgress: (progress) => {
      downloadProgressMessage.value = progress
    },
    onDownloadFinished: (url: string, blob: Blob, renderFps?: string, renderSpeed?: string) => {

      if (videoHasErrors.value) {
        return;
      }

      downloadProgressMessage.value = null

      isRendering.value = false
      localResultUrl.value = url
      useHead({ title: 'Uploading..' })
      renderingProgressMessage.value = 'Uploading..'

      uploadVideo(blob, renderFps, renderSpeed, renderData.title ?? '')
    },
  });

  // This will automatically start the export process when at the front of the queue. This is to prevent multiple renders from happening at the same time.
  videoWorkerManagerQueue.enqueue(videoWorkerManager);

  onUnmounted(() => {

    const item = videoWorkerManagerQueue.getItemById(videoWorkerManager.id!);

    if (item) {
      item.terminate();
    }
  })
}

const validateRenderDataObject = (renderData: RenderDetailsDto) => {
  return !!renderData?.sourceUrl
    && !!renderData?.outputResolution
    && !!renderData?.outputFps
    && !!renderData?.segments;
}

watchImmediate(videoHasErrors, (value) => {
  if (value) {
    isRendering.value = false;
    if (videoWorkerManagerId.value) {
      const item = videoWorkerManagerQueue.getItemById(videoWorkerManagerId.value);
      if (item) {
        item.terminate();
      }
    }
  }
});

const uploadVideo = async (blob: Blob, renderFps?: string, renderSpeed?: string, fileName?: string) => {

  const cancelSource = axios.CancelToken.source()

  // Start uploading the file.
  const result = await uploadService.getUploadResultSignedUrl()
  const res = await uploadService.uploadFileS3(
    result.signedUrl,
    blob,
    (p) => {
      //console.log(p);
    },
    'video/mp4',
    `streamladder-${encodeURIComponent(fileName ?? '')}.mp4`,
    { cancelToken: cancelSource.token }
  )

  if (res && res.status === 200) {

    const videoResponse = await postApiVideos({
      videoUrl: result.resultUrl,
      title: videoTitle.value || 'StreamLadder video',
    });

    if (videoResponse.resultUrl && webhookEventUrl.value) {

      renderingProgressMessage.value = 'Redirecting..'

      console.log({ renderFps, renderSpeed})

      await axios.post(webhookEventUrl.value!, {
        taskId: renderDataId.value,
        status: 'finished',
        message: 'Finished rendering',
        data: {
          fileUrl: videoResponse.resultUrl,
          renderFps,
          renderSpeed,
        }
      })

      showToastsOnUnmount.value = false;

      useHead({ title: 'App' })

      if (isOnRenderPage.value) {
        await router.push({
          name: dashboardRouteNames.contentPublisher.render,
          params: { renderId: renderId },
        })
      } else {
        showToast({
          type: toastEvents.TOAST_SUCCESS,
          title: 'Video finished rendering!',
          subtitle: `"${videoTitle.value}" has finished rendering.`,
          timeout: 10000,
          view: () => {
            router.push({
              name: dashboardRouteNames.contentPublisher.render,
              params: { renderId: renderId },
            })
          },
          viewTitle: 'View video',
        })
      }
    } else {
      Sentry.captureException(new Error('Failed to upload video.'))
      videoHasErrors.value = true
      await notifyRenderTaskWebhookWithError()
    }
  } else {
    Sentry.captureException(new Error('Failed to upload video.'))
    videoHasErrors.value = true
    console.error({
      message: 'Failed to upload video',
      response: res,
    })
    await notifyRenderTaskWebhookWithError()
  }

  if (videoHasErrors.value && webhookEventUrl.value && renderDataId.value) {
    Sentry.captureException(new Error('Error rendering video.'))
    await notifyRenderTaskWebhookWithError()
  }
}

const resetErrorState = () => {
  videoHasErrors.value = false
  errorMessage.value = ""
};

const queueProgress = ref(0);

const isMobile = useIsMobile();

// Server-side rendering will only be used as a fallback for mobile users when rendering client-side.
const serverRenderRequested = ref(false);

const hasAttemptedRender = ref(false);

const attemptRender = async (forceRenderOnServer = false) => {

  if (hasAttemptedRender.value) return;
  hasAttemptedRender.value = true;

  resetErrorState();

  const renderOnServerFallback = async () => {
    if (!isOnRenderPage.value || serverRenderRequested.value) return;
    serverRenderRequested.value = true;

    const response = await getApiRendersIdDetails(route.query.renderId)
    console.log('Render data from server:', JSON.stringify(response.value))
    const renderId = await requestWebcodecRender(true, {...response.value, contentUrl: response.value?.sourceUrl} as CreateRenderDto);
    if (renderId) {
      await router.push({
        name: 'GenerateServerSideQueue',
        query: {
          task: renderId,
        },
      })
    } else {
      videoHasErrors.value = true
      showRetryButton.value = false
      fixToolDialogOpen.value = false
      errorMessage.value = '404 - Render not found'
      Sentry.captureException(new Error('WebCodec Renderer Error: Cant render video on server'))
      useHead({ title: 'Error' })
    }
  }

  if (forceRenderOnServer) {
    await renderOnServerFallback();
    return;
  }

  const renderOnServerWithFakeQueue = async () => {

    const queueTime = userInfo.tier === tiers.FREE
      ? Math.floor(Math.random() * 60)
      : Math.floor(Math.random() * 20);

    if (userInfo.tier === tiers.GOLD || queueTime < 5) {
      await renderOnServerFallback();
      return;
    }

    showFakeQueue.value = true;

    useHead({ title: 'Queuing..' });

    queueProgress.value = 0;

    const interval = setInterval(async () => {

      queueProgress.value += 1;

      if (queueProgress.value === 100) {
        clearInterval(interval);
        await renderOnServerFallback();
      }
    }, (queueTime * 1000) / 100);
  };

  if (!route.query.renderId) {
    videoHasErrors.value = true
    showRetryButton.value = false
    errorMessage.value = '404 - Render not found'
    Sentry.captureException(new Error('WebCodec Renderer Error: User tried to render a video without a renderId'))
    useHead({ title: 'Error' })
  } else {

    const webcodecTestResults = await runWebcodecTest();
    if (!webcodecTestResults.hardwareAccelerationWorking && !webcodecTestResults.softwareEncoderWorking) {
      console.error('WebCodec Renderer Not Supported', webcodecTestResults);
      await renderOnServerWithFakeQueue();
      return;
    }

    try {
      await startRender(() => {
        if (isOnRenderPage.value) {
          if (isMobile.value) {
            renderOnServerWithFakeQueue();
          } else {
            window.location.replace(router.resolve({
              name: 'VideoRepairGeneratePage',
              query: {
                renderId: renderId,
              },
            }).href);
          }
        }
      })
    } catch (e) {
      if (isOnRenderPage.value) {
        if (isMobile.value) {
          await renderOnServerWithFakeQueue();
        } else {
          window.location.replace(router.resolve({
            name: 'VideoRepairGeneratePage',
            query: {
              renderId: renderId,
            },
          }).href);
        }
      }
    }
  }
}

onMounted(async () => {
  isOnRenderPage.value = true;
  await showOnboardingSurveyOnceOrRenderVideo();
})

onBeforeUnmount(async () => {
  isOnRenderPage.value = false;

  if (confirmDialog.isRevealed) {
    confirmDialog.close();
  } else {
    if (showToastsOnUnmount.value) {
      showToast({
        type: toastEvents.TOAST,
        title: 'Your video is rendering in the background',
        subtitle: 'You\'ll be notified when it\'s done!',
        timeout: 10000,
      });
    }
  }
})

// If all else fails, render on server.
watch([videoHasErrors, hasRepaired], () => {
  if (videoHasErrors.value && hasRepaired === 'true') {

    forcedRenderOnServer.value = true;
    if (!forcedRenderOnServer.value) {
      attemptRender(true);
    }
  }
});

const showOnboardingSurvey = ref(false);

const handleSurveyEnd = async () => {
  showOnboardingSurvey.value = false
  localStorage.setItem('hasAnsweredOnboardingSurvey', 'true');
  await attemptRender();
}

const checkIfUserHasAnsweredSurvey = async () => {
  const hasAnswered = localStorage.getItem('hasAnsweredOnboardingSurvey');
  return !!hasAnswered;
}

const showOnboardingSurveyOnceOrRenderVideo = async () => {

  if (userInfo.tier !== tiers.FREE || hasRepaired) {
    await attemptRender();
    return;
  }

  const userHasAlreadyAnsweredSurvey = await checkIfUserHasAnsweredSurvey();

  if (!userHasAlreadyAnsweredSurvey) {
    showOnboardingSurvey.value = true;
  } else {
    showOnboardingSurvey.value = false;
    await attemptRender();
  }
}
</script>

<template>

  <template v-if="showOnboardingSurvey">
    <div class="fixed z-50 flex items-center justify-center w-screen h-screen bg-black/80">
      <div class="bg-white rounded-xl shadow-2xl w-screen lg:w-[600px]">
        <OnboardingSurveyFlow @surveyEnd="handleSurveyEnd" />
      </div>
    </div>
  </template>

  <FeatureShowcase v-if="showFakeQueue && !videoHasErrors" :isWebcodecRenderer="true">

    <div class="flex flex-col items-center gap-4">

      <LottieAnimation url="/lottie/gears-turning.json" class="w-1/3" />

      <h2 class="text-center text-2xl font-bold">
        Your render is in the queue
      </h2>

      <p class="text-center text-base font-normal text-gray-600">
        Please don't close this page, your video is almost ready to start rendering.
      </p>

      <radial-progress :progress="queueProgress" />

      <p class="flex flex-col mt-6 gap-2 items-center justify-center font-light text-center bg-zinc-400 px-6 py-3 rounded-lg w-full">
        Don't want to wait in line?
        <Button variant="gradient" class="group-hover:shadow-gradient" @click="canGuardWithPopup('skip-the-queue')">
          <DynamicPlanButtonWithTooltip feature="skip-the-queue" />
          Upgrade now
          <IconSaxArrowRight class="transition-transform will-change-transform group-hover:translate-x-2" />
        </Button>
      </p>
    </div>
  </FeatureShowcase>

  <FeatureShowcase v-else :isWebcodecRenderer="true">
    <template v-if="!videoHasErrors">
      <div class="flex w-full items-center justify-center">
        <AsyncComponent :is="LoaderComponent || 'div'" class-name="w-3/5 md:w-1/3 lg:w-3/5">
          <lottie-animation url="./lottie/race-car.json" :loop="true" :autoPlay="true" />
        </AsyncComponent>
      </div>

      <div class="flex w-full flex-col text-center">
        <template v-if="renderingProgressMessage">
          <h1 class="text-2xl font-bold">
            Rendering your video
          </h1>
          <p class="text-company-primary text-opacity-75 font-light">
            Your video is being rendered. This might take a while.
          </p>
        </template>
        <template v-else>
          <h1 class="text-2xl font-bold">
            Preparing your video
          </h1>
          <p v-if="downloadProgressMessage" class="text-company-primary text-opacity-75 font-light">
            We're preparing your render {{ downloadProgressMessage }} ..
          </p>
          <p v-else class="text-company-primary text-opacity-75 font-light">
            We'll start your awesome video as soon as possible.
          </p>
        </template>
      </div>

      <div class="flex flex-col justify-center items-center relative">
        <div
          class="mx-auto max-w-min overflow-hidden rounded-2xl border-[5px] border-surface-inverse bg-surface-inverse"
          :class="{ 'skeleton': !renderingProgressMessage }"
        >
          <canvas ref="previewCanvas" class="max-h-[420px] h-full w-auto" width="720" height="1280" />
        </div>
        <div
          class="flex justify-center items-center absolute text-black font-light bg-slate-200 opacity rounded-lg p-2 text-center"
          :class="{ 'text-lg w-16': isRendering && !localResultUrl }"
        >
          <template v-if="renderingProgressMessage">
            {{ renderingProgressMessage }}
          </template>
          <template v-else>
            <LottieAnimation url="/lottie/rocketLaunch.json" class="w-8 h-8" />
          </template>
        </div>
      </div>

      <div class="flex flex-col items-center justify-center w-full">
        <p class="flex flex-col gap-2 items-center justify-center font-semibold text-center bg-blue-50 w-full px-16 pb-3 pt-4 rounded-lg border border-blue-200 max-w-[300px]">
          Got any questions or feedback?
          <Button as="a" :href="settings.discordInviteUrl" variant="discord" size="sm">
            <DiscordLogo class="w-3 h-3 fill-current" />
            <p class="text-sm">Join our Discord</p>
          </Button>
        </p>
      </div>
    </template>

    <template v-else-if="videoHasErrors">
      <div class="flex w-full items-center justify-center">
        <AsyncComponent :is="LoaderComponent || 'div'" class-name="w-3/5 md:w-1/3 lg:w-3/5">
          <lottie-animation url="./lottie/race-car-crash.json" :loop="true" :autoPlay="true" />
        </AsyncComponent>
      </div>

      <div class="flex w-full flex-col text-center">
        <h1 class="text-2xl font-bold">
          {{ errorMessage }}
        </h1>
        <p class="text-company-primary text-opacity-75 font-light">
          Our new rendering engine has crashed and burned while rendering your video.
        </p>
      </div>

      <p class="flex flex-col gap-2 items-center justify-center text-sm font-light text-center bg-zinc-400 w-full px-4 pb-3 pt-4 rounded-lg">
        <LottieAnimation url="/lottie/error.json" class="w-10 h-10" />
        <template v-if="showRetryButton">
          Please try again or contact support.
        </template>
        <template v-else>
          Please contact support or create a new project.
        </template>
        <RouterLink v-if="!showRetryButton" :to="{ name: editorRouteNames.root }" class="flex">
          <Button size="sm" @click="attemptRender">
            <IconSaxRefresh2 class="h-3 w-3" />
            Create new project
          </Button>
        </RouterLink>
        <Button v-if="showRetryButton" size="sm" @click="attemptRender">
          <IconSaxRefresh2 class="h-3 w-3" />
          Try again
        </Button>
        <Button as="a" :href="settings.discordInviteUrl" variant="discord" size="sm">
          <DiscordLogo class="w-3 h-3 fill-current" />
          <p class="text-sm">Join our Discord</p>
        </Button>
        <Button v-if="showRetryButton" size="sm" class="font-light" variant="link" @click="fixToolDialogOpen = true">
          <IconSaxExportCurve class="h-3 w-3" />
          Use Video Repair Tool
        </Button>
      </p>
    </template>
  </FeatureShowcase>
</template>
