import {computed, type Ref, ref, watch} from 'vue'
import {addDays, format, isSameDay, startOfWeek, subDays} from 'date-fns'
import {watchImmediate} from "@vueuse/core";

type CalenderItem = {
    id: string
    date: Date
}

// make generic version
type CalenderStateOptions<T extends CalenderItem = CalenderItem> = {
  getData?: (start: Date, end: Date) => Promise<T[]>
  initialData?: T[]
}

export const useCalenderState = <T extends CalenderItem>(startDate: Date, options: CalenderStateOptions<T>) => {

  const data = ref(options.initialData || [] as T[])

  const start = ref(startOfWeek(startDate,{weekStartsOn: 1})) // Monday

  const range = ref(7) // days

  const end = computed(() => {
    return addDays(start.value, range.value)
  })

  watchImmediate(start, async (newStart) => {
    if (options.getData) {
      data.value = await options.getData(newStart, end.value)
    }
  })

  const days = computed(() => {
    const days = []
    for (let i = 0; i < range.value; i++) {
      days.push({
        date: addDays(start.value, i),
        name: format(addDays(start.value, i), 'EEEE'),
        items: data.value.filter((item) => {
          return isSameDay(new Date(item.date), addDays(start.value, i))
        })
      })
    }
    return days
  })

  function next() {
    start.value = addDays(start.value, range.value)
  }

  function prev() {
    start.value = subDays(start.value, range.value)
  }

  return {
    start,
    end,
    range,
    data,
    days,
    next,
    prev
  }

}
