import { useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import { isArray } from 'lodash-es'
import { useContentPublisherStore } from '@/components-v2/content-publisher/_store'
import type { DeepPartial } from 'ts-essentials'
import type { ContentPublisherFormState } from '@/areas/dashboard/pages/contentPublishing/ContentPublishHelpers'

export const useContentPublisherPostData = () => {
  const route = useRoute()

  const postId = computed(() => {
    if (isArray(route.params.postId) || !route.params.postId) {
      return undefined
    }
    return route.params.postId
  })
  const hasPost = computed(() => {
    return !!route.params.postId
  })

  const contentPublisherStore = useContentPublisherStore()
  const postData = computed(() => (postId.value ? contentPublisherStore.entities[postId.value] : undefined))

  onMounted(async () => {
    if (postId.value) await contentPublisherStore.syncPost(postId.value)
  })

  const getFormData = (): DeepPartial<ContentPublisherFormState> | undefined => {
    const newPost = postData.value
    if (!newPost) return
    const selectedAccounts = newPost.targets?.map((target) => target.accountId).filter(Boolean) ?? []
    const youtubeTarget = newPost.targets?.find((target) => target.socialMedia?.toUpperCase() === 'YOUTUBE')
    const tikTokTarget = newPost.targets?.find((target) => target.socialMedia.toUpperCase() === 'TIKTOK')
    const instagramTarget = newPost.targets?.find((target) => target.socialMedia.toUpperCase() === 'INSTAGRAM')
    return {
      dateTime: newPost.scheduledAt ? new Date(newPost.scheduledAt) : undefined,
      selectedAccounts: selectedAccounts as string[],
      youtube: {
        title: youtubeTarget?.title ?? '',
        description: youtubeTarget?.description ?? '',
        publishPublic: youtubeTarget?.youtubeOptions?.visibility === 'Public',
        isChildFriendly: youtubeTarget?.youtubeOptions?.youTubeChildFriendly ?? false,
      },
      instagram: {
        title: instagramTarget?.title ?? '',
      },
      tikTok: {
        title: tikTokTarget?.title ?? '',
        allowDuet: !tikTokTarget?.tikTokOptions?.disableDuet,
        allowStitch: !tikTokTarget?.tikTokOptions?.disableStitch,
        allowComments: !tikTokTarget?.tikTokOptions?.disableComment,
        privacyLevel: tikTokTarget?.tikTokOptions?.privacyLevel ?? 'PUBLIC_TO_EVERYONE',
        isDraft: tikTokTarget?.tikTokOptions?.isDraft ?? false,
        brandContentToggle: tikTokTarget?.tikTokOptions?.brandContentToggle ?? false,
        brandOrganicToggle: tikTokTarget?.tikTokOptions?.brandOrganicToggle ?? false,
        videoCoverTimestampMs: tikTokTarget?.tikTokOptions?.videoCoverTimestampMs ?? 0,
      },
    }
  }

  return {
    postId,
    hasPost,
    postData,
    getFormData,
  }
}
