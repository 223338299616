<script setup lang="ts">
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import TimeLineTrackNode from '@/modules/SLTimeline/TimeLineTrackNode.vue'
import TimeLineTrack from '@/modules/SLTimeline/TimeLineTrack.vue'
import TimeLineAddNode from '@/components/Editor/Timeline/TimeLineAddNode.vue'
import IconSaxElement2 from '@/components/Icons/iconsax/IconSaxElement2.vue'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import IconSaxSearchZoomIn from '@/components/Icons/iconsax/IconSaxSearchZoomIn.vue'
import { computed } from 'vue'

const editorFocusStore = useEditorFocusStore()
const editorFeedStore = useEditorFeedDataStore()
const editorVideoStore = useEditorVideoStore()

const noEffects = computed(() => {
  return editorFeedStore.zooms.length === 0
})
</script>

<template>
  <TimeLineTrack class="group relative h-6 w-full">
    <TimeLineAddNode></TimeLineAddNode>
    <div
      v-if="noEffects"
      class="absolute inset-0 flex items-center justify-center gap-1.5 rounded-lg border border-blue-300 bg-blue-100 text-blue-500 group-hover:hidden peer-[]:hidden text-sm font-normal"
    >
      <IconSaxSearchZoomIn class="stroke- h-4 w-4" />
      <p class="mt-0.5">Add zoom</p>
    </div>
    <TimeLineTrackNode
      class="zoom-node cursor-grab rounded-[5px] border-none bg-pink-600 outline-white ring-pink-600 ring-offset-2 hover:bg-pink-500"
      v-for="(zoom, i) in editorFeedStore.zooms"
      :key="zoom.id"
      :start-ms="zoom.start"
      :end-ms="zoom.end"
      :min-start-ms="editorFeedStore.zooms[i - 1] ? editorFeedStore.zooms[i - 1].end : 0"
      :max-end-ms="
        editorFeedStore.zooms[i + 1] ? editorFeedStore.zooms[i + 1].start : editorVideoStore._duration * 1000
      "
      :height="25"
      :active="editorFocusStore.focus?.type === 'zoom' && editorFocusStore.focus?.key === zoom.id"
      @resizeStart="() => editorFocusStore.setFocus('zoom', zoom.id)"
      @resizeStop="
        (start, end) => {
          editorFeedStore.updateZoom(zoom.id, { start, end })
        }
      "
      @dragStart="() => editorFocusStore.setFocus('zoom', zoom.id)"
      @dragStop="
        (start, end) => {
          editorFeedStore.updateZoom(zoom.id, { start, end })
        }
      "
      @activated="
        () => {
          editorFocusStore.setFocus('zoom', zoom.id)
        }
      "
    >
      <div class="flex h-full w-full items-center overflow-hidden rounded-[5px] text-sm font-normal text-white">
        <span
          class="mx-3 max-h-full w-full select-none overflow-hidden whitespace-nowrap py-0.5 text-center align-middle font-normal"
        >
          <IconSaxSearchZoomIn class="h-4 w-4 mb-0.5" />
          Zoom
        </span>
      </div>
      <template #handles>
        <div class="flex h-full flex-row gap-0.5 px-1 py-1">
          <div class="h-full w-1 rounded bg-pink-700"></div>
          <div class="h-full w-1 rounded bg-pink-700"></div>
        </div>
      </template>
    </TimeLineTrackNode>
  </TimeLineTrack>
</template>
