<script setup lang="ts">
import { Button } from '@/components/ui/button'
import IconSaxTickCircle from '@/components/Icons/iconsax/IconSaxTickCircle.vue'
import IconSaxArrowRight from '@/components/Icons/iconsax/IconSaxArrowRight.vue'
import { onMounted } from 'vue'
import { postApiSubscriptionDiscounts } from '@/apis/streamladder-accounts/subscription/subscription'
import * as Sentry from '@sentry/browser'
import logging from '@/logging'
import { useUserSubscriptionStore } from '@/store/user/userSubscription'
import EventBus from '@/eventBus'
import mainEvents from '@/events/mainEvents'
import { RouterLink } from 'vue-router'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'

const emit = defineEmits<{
  (event: 'cancel'): void
}>()

const subscriptionStore = useUserSubscriptionStore();

onMounted(async () => {
  await applyDiscountAndContinue();
})

const applyDiscountAndContinue = async () => {
  try {
    await postApiSubscriptionDiscounts()
    logging.trackEvent('Subscription Cancel Discount Accepted', { plan: subscriptionStore.plan });
  } catch (e) {
    Sentry.captureException(e);
    emit('cancel');
    EventBus.$emit(mainEvents.ERROR, "We couldn't give you a free month! <br> Please contact our support!");
  }
};
</script>

<template>
  <div class="flex flex-col gap-8 items-center text-center">

    <div class="flex h-[500px]">
      <div class="w-full sm:w-2/3 gap-4 p-4 flex flex-col items-center justify-center">
        <h3 class="text-2xl sm:text-3xl lg:text-4xl">
          You Got It — Next Month is Free!
        </h3>
        <p class="font-light">
          Thank you for choosing to stay with us.
        </p>
        <div class="flex items-center justify-center w-full h-48">
          <IconSaxTickCircle
            class="h-32 w-32 rounded-full fill-green-600 text-white dark:text-black"
          />
        </div>
        <Button
          variant="primary"
          :as="RouterLink"
          :to="{ name: dashboardRouteNames.dashboard }"
          @click="emit('cancel')">
          Go to dashboard
          <IconSaxArrowRight class="w-4 h-4" />
        </Button>
      </div>
      <div class="p-8 w-1/3 h-full bg-[#F0F0F3] dark:bg-zinc-400 hidden sm:flex flex-col items-center justify-center gap-8">
        <p class="font-light tracking-wide leading-8 story">
          Thank you so much for being a part of StreamLadder! Your support has meant the world to us. We're passionate about what we build our goal is to create the best editor out there.
        </p>
        <p class="autograph text-4xl font-extralight">
          Lolke
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@font-face {
  font-family: autograph;
  src: url(/fonts/Dancing_Script/DancingScript-Regular.ttf);
}

.autograph {
  font-family: autograph, serif;
  cursor: default;
}

@font-face {
  font-family: story;
  src: url(/fonts/Aboreto/Aboreto-Regular.ttf);
}

.story {
  font-family: story, serif;
}
</style>