<template>
<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 24 24" fill="none" v-if="type === 'bold'">
    <path
      d="M16.75 3.56V2c0-.41-.34-.75-.75-.75s-.75.34-.75.75v1.5h-6.5V2c0-.41-.34-.75-.75-.75s-.75.34-.75.75v1.56c-2.7.25-4.01 1.86-4.21 4.25-.02.29.22.53.5.53h16.92c.29 0 .53-.25.5-.53-.2-2.39-1.51-4-4.21-4.25ZM20 9.84H4c-.55 0-1 .45-1 1V17c0 3 1.5 5 5 5h4.93c.69 0 1.17-.67.95-1.32-.2-.58-.37-1.22-.37-1.68 0-3.03 2.47-5.5 5.5-5.5.29 0 .58.02.86.07.6.09 1.14-.38 1.14-.98v-1.74A1.02 1.02 0 0 0 20 9.84ZM9.21 17.71c-.1.09-.21.16-.33.21-.12.05-.25.08-.38.08s-.26-.03-.38-.08-.23-.12-.33-.21c-.18-.19-.29-.45-.29-.71 0-.06.01-.13.02-.19.01-.07.03-.13.06-.19.02-.06.05-.12.09-.18.03-.05.08-.1.12-.15.1-.09.21-.16.33-.21a1 1 0 0 1 .76 0c.12.05.23.12.33.21.04.05.09.1.12.15.04.06.07.12.09.18.03.06.05.12.06.19.01.06.02.13.02.19 0 .26-.11.52-.29.71Zm0-3.5c-.1.09-.21.16-.33.21-.12.05-.25.08-.38.08s-.26-.03-.38-.08c-.13-.05-.23-.12-.33-.21-.18-.19-.29-.45-.29-.71 0-.26.11-.52.29-.71.1-.09.21-.16.33-.21a1 1 0 0 1 .76 0c.12.05.23.12.33.21.04.05.09.1.12.15.04.06.07.12.09.18.03.06.05.12.06.18.01.07.02.14.02.2 0 .26-.11.52-.29.71Zm3.5 0c-.19.18-.44.29-.71.29-.13 0-.26-.03-.38-.08-.13-.05-.23-.12-.33-.21-.18-.19-.29-.45-.29-.71 0-.06.01-.13.02-.2.01-.06.03-.12.06-.18.02-.06.05-.12.09-.18l.12-.15c.37-.37 1.04-.37 1.42 0l.12.15c.04.06.07.12.09.18.03.06.05.12.06.18.01.07.02.14.02.2 0 .26-.11.52-.29.71Z"
      :fill="color"
    ></path>
    <path
      d="M21.83 16.17a4.008 4.008 0 0 0-5.66 0 4.008 4.008 0 0 0 0 5.66 4.008 4.008 0 0 0 5.66 0 4.008 4.008 0 0 0 0-5.66Zm-.76 3.39c-.13.14-.32.22-.53.22h-.74v.78c0 .21-.08.39-.22.53s-.32.22-.53.22c-.41 0-.75-.34-.75-.75v-.78h-.75a.749.749 0 1 1 0-1.5h.75v-.71a.749.749 0 1 1 1.5 0v.71h.74c.42 0 .75.34.75.75 0 .21-.08.4-.22.53Z"
      :fill="color"
    ></path>
  </svg>
<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 24 24" fill="none" v-else-if="type === 'broken'">

    <path
      d="M3 13.01V8.5c0-3 1.5-5 5-5h8c3.5 0 5 2 5 5v7.86c-.73-.83-1.8-1.36-3-1.36-2.21 0-4 1.79-4 4 0 .75.21 1.46.58 2.06.21.36.48.68.79.94H8c-3.5 0-5-2-5-5M8 2v3M16 2v3M3.5 9.09h17"
      :stroke="color"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M18 23a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM19.49 19.05h-2.98M18 17.59v2.99"
      :stroke="color"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M11.995 13.7h.009M8.295 13.7h.01M8.295 16.7h.01"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 24 24" fill="none" v-else-if="type === 'bulk'">

    <path
      d="M16.75 3.56V2c0-.41-.34-.75-.75-.75s-.75.34-.75.75v1.5h-6.5V2c0-.41-.34-.75-.75-.75s-.75.34-.75.75v1.56c-2.7.25-4.01 1.86-4.21 4.25-.02.29.22.53.5.53h16.92c.29 0 .53-.25.5-.53-.2-2.39-1.51-4-4.21-4.25Z"
      :fill="color"
    ></path>
    <path
      opacity=".4"
      d="M21 10.838v1.74c0 .61-.54 1.08-1.14.98-.28-.04-.57-.07-.86-.07a5.51 5.51 0 0 0-5.5 5.5c0 .46.18 1.1.37 1.68a.998.998 0 0 1-.95 1.32H8c-3.5 0-5-2-5-5v-6.16c0-.55.45-1 1-1h16c.55.01 1 .46 1 1.01Z"
      :fill="color"
    ></path>
    <path
      d="M8.5 15c-.26 0-.52-.11-.71-.29-.18-.19-.29-.45-.29-.71 0-.26.11-.52.29-.71.23-.23.58-.34.91-.27.06.01.12.03.18.06.06.02.12.05.18.09l.15.12c.18.19.29.45.29.71 0 .26-.11.52-.29.71l-.15.12c-.06.04-.12.07-.18.09-.06.03-.12.05-.18.06-.07.01-.14.02-.2.02ZM12 15c-.26 0-.52-.11-.71-.29-.18-.19-.29-.45-.29-.71 0-.26.11-.52.29-.71.38-.37 1.05-.37 1.42 0 .18.19.29.45.29.71 0 .26-.11.52-.29.71-.19.18-.45.29-.71.29ZM8.5 18.499c-.26 0-.52-.11-.71-.29-.18-.19-.29-.45-.29-.71 0-.26.11-.52.29-.71.1-.09.2-.16.33-.21.37-.16.81-.07 1.09.21.18.19.29.45.29.71 0 .26-.11.52-.29.71-.19.18-.45.29-.71.29ZM21.83 16.17a4.008 4.008 0 0 0-5.66 0 4.008 4.008 0 0 0 0 5.66 4.008 4.008 0 0 0 5.66 0 4.008 4.008 0 0 0 0-5.66Zm-.76 3.39c-.13.14-.32.22-.53.22h-.74v.78c0 .21-.08.39-.22.53s-.32.22-.53.22c-.41 0-.75-.34-.75-.75v-.78h-.75a.749.749 0 1 1 0-1.5h.75v-.71a.749.749 0 1 1 1.5 0v.71h.74c.42 0 .75.34.75.75 0 .21-.08.4-.22.53Z"
      :fill="color"
    ></path>
  </svg>
<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 24 24" fill="none" v-else-if="type === 'linear'">

    <path
      d="M8 2v3M16 2v3M3.5 9.09h17M18 23a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM19.49 19.05h-2.98M18 17.59v2.99"
      :stroke="color"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M21 8.5v7.86c-.73-.83-1.8-1.36-3-1.36-2.21 0-4 1.79-4 4 0 .75.21 1.46.58 2.06.21.36.48.68.79.94H8c-3.5 0-5-2-5-5V8.5c0-3 1.5-5 5-5h8c3.5 0 5 2 5 5Z"
      :stroke="color"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M11.995 13.7h.01M8.294 13.7h.01M8.294 16.7h.01"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 24 24" fill="none" v-else-if="type === 'outline'">

    <path
      d="M8 5.75c-.41 0-.75-.34-.75-.75V2c0-.41.34-.75.75-.75s.75.34.75.75v3c0 .41-.34.75-.75.75ZM16 5.75c-.41 0-.75-.34-.75-.75V2c0-.41.34-.75.75-.75s.75.34.75.75v3c0 .41-.34.75-.75.75ZM8.5 14.498c-.13 0-.26-.02-.38-.08-.12-.05-.23-.12-.33-.21-.18-.19-.29-.45-.29-.71 0-.13.03-.26.08-.38s.12-.23.21-.33c.1-.09.21-.16.33-.21.36-.15.81-.07 1.09.21.09.1.16.21.21.33.05.12.08.25.08.38 0 .26-.11.52-.29.71-.19.18-.45.29-.71.29ZM12 14.499c-.26 0-.52-.11-.71-.29-.09-.1-.16-.21-.21-.33a.995.995 0 0 1-.08-.38c0-.26.11-.52.29-.71.28-.28.72-.37 1.09-.21.12.05.23.12.33.21.18.19.29.45.29.71 0 .13-.02.26-.08.38-.05.12-.12.23-.21.33-.1.09-.21.16-.33.21-.12.06-.25.08-.38.08ZM8.5 17.999c-.13 0-.26-.03-.38-.08s-.23-.12-.33-.21c-.18-.19-.29-.44-.29-.71 0-.13.03-.26.08-.38.05-.13.12-.23.21-.33.1-.09.21-.16.33-.21.36-.16.81-.07 1.09.21.09.1.16.2.21.33.05.12.08.25.08.38 0 .27-.11.52-.29.71-.19.18-.45.29-.71.29ZM20.5 9.84h-17c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h17c.41 0 .75.34.75.75s-.34.75-.75.75ZM18 23.75c-2.62 0-4.75-2.13-4.75-4.75s2.13-4.75 4.75-4.75 4.75 2.13 4.75 4.75-2.13 4.75-4.75 4.75Zm0-8c-1.79 0-3.25 1.46-3.25 3.25s1.46 3.25 3.25 3.25 3.25-1.46 3.25-3.25-1.46-3.25-3.25-3.25Z"
      :fill="color"
    ></path>
    <path
      d="M19.49 19.8H16.5c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h2.99a.749.749 0 1 1 0 1.5Z"
      :fill="color"
    ></path>
    <path
      d="M18 21.33c-.41 0-.75-.34-.75-.75v-2.99c0-.41.34-.75.75-.75s.75.34.75.75v2.99c0 .41-.34.75-.75.75Z"
      :fill="color"
    ></path>
    <path
      d="M15.37 22.75H8c-3.65 0-5.75-2.1-5.75-5.75V8.5c0-3.65 2.1-5.75 5.75-5.75h8c3.65 0 5.75 2.1 5.75 5.75v7.86c0 .31-.19.59-.49.7-.29.11-.62.03-.83-.21-.62-.7-1.51-1.1-2.44-1.1-1.79 0-3.25 1.46-3.25 3.25 0 .59.16 1.17.47 1.67.17.3.39.55.63.76.24.2.33.53.22.83-.09.29-.37.49-.69.49ZM8 4.25c-2.86 0-4.25 1.39-4.25 4.25V17c0 2.86 1.39 4.25 4.25 4.25h5.82a4.7 4.7 0 0 1-.57-2.25c0-2.62 2.13-4.75 4.75-4.75.79 0 1.57.2 2.25.57V8.5c0-2.86-1.39-4.25-4.25-4.25H8Z"
      :fill="color"
    ></path>
  </svg>
<svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 24 24" fill="none" v-else-if="type === 'twotone'">

    <path
      d="M8 2v3M16 2v3"
      :stroke="color"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      opacity=".4"
      d="M3.5 9.09h17"
      :stroke="color"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M18 23a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM19.49 19.05h-2.98M18 17.59v2.99"
      :stroke="color"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M21 8.5v7.86c-.73-.83-1.8-1.36-3-1.36-2.21 0-4 1.79-4 4 0 .75.21 1.46.58 2.06.21.36.48.68.79.94H8c-3.5 0-5-2-5-5V8.5c0-3 1.5-5 5-5h8c3.5 0 5 2 5 5Z"
      :stroke="color"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      opacity=".4"
      d="M11.995 13.7h.009M8.295 13.7h.01M8.295 16.7h.009"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>

</template>
<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
    props: {
        type: {
            type: String,
            default: "linear",
        },
        size: {
            type: [ String, Number ],
            default: 24,
        },
        color: {
            type: String,
            default: "currentColor",
        },
    },
})
</script>