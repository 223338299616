import EventBus from '../eventBus'
import authEvents from '../events/authEvents'
import { tiers } from '@/enums/tiers'
import upgradeEvents from '../events/upgradeEvents'
import { accountsAxios } from '@/services/axios'
import { useUserInfoStore } from '@/store/user/userInfo'
import { useSaleStore } from '@/store/sale'
import { getInterval, getTier, useProductsStore } from '@/store/products'
import { markRaw } from 'vue'
import { useCreateSubscription } from '@/Hooks/useCreateSubscription'

export default {
  methods: {
    upgradeable(tier) {
      const userInfoStore = useUserInfoStore()
      return tier > userInfoStore.tier
    },
    downgradable(tier) {
      const userInfoStore = useUserInfoStore()
      return tier < userInfoStore.tier
    },
    gold() {
      const productsStore = useProductsStore()
      return productsStore.productData.gold.tier
    },
    silver() {
      const productsStore = useProductsStore()
      return productsStore.productData.silver.tier
    },
    getBadgeComponent(key) {
      const productsStore = useProductsStore()

      if (typeof key === 'number') {
        key = this.tierNumberToDisplayString(key).toLowerCase()
      }

      if (!productsStore.productData[key]) {
        console.error(`${key} is not an available plan`)
        return null
      }
      return markRaw(productsStore.productData[key].badge)
    },
    tierNumberToDisplayString(num) {
      return tiers.toString(num)
    },
    async attemptCheckout(productId, discountCode, reason) {
      const userInfoStore = useUserInfoStore()
      if (userInfoStore.userId === undefined) {
        EventBus.$emit(authEvents.OPEN_LOGIN_DIALOG)
        return
      }

      const meta = {
        interval: getInterval(productId),
        tier: getTier(productId),
      }

      // If a user has no subscription yet, create one through paddle.
      if (userInfoStore.tier === 0) {
        let data = { product: productId, ...meta }

        //If the checkout happens during black friday and the product is a year plan, add the blackfriday coupon
        const saleStore = useSaleStore()
        if (saleStore.isBlackFriday && [794144, 777416].includes(productId)) {
          data = { ...data, coupon: 'BLACKFRIDAY22' }
        }

        if (discountCode) {
          data = { ...data, coupon: discountCode }
        }

        await this.createSubscription(data, reason)
        return
      }

      //At this point the user has a subscription, so we have to check if it is a legacy (PayPal) or paddle subscription
      const res = await accountsAxios.get('/api/subscription/billinginfo')

      //If the payment method is PayPal we have to override the URL
      if (res.data.type === 'paypal' || res.data.state === 'deleted') {
        const paymentLinkResult = await accountsAxios.get(
          `/api/subscription/generatepaymentlinkv2?productId=${productId}`
        )
        const link = paymentLinkResult.data.paymentLink
        await this.createSubscription({ override: link }, reason)
      }
      // If the payment method is Paddle and the subscription has been cancelled
      // We still have a tier, and thus we do not use the checkout from above
      else if (res.data.state === 'CANCELLED') {
        const data = { product: productId, ...meta }
        await this.createSubscription(data, reason)
      } else if (res.data.subscriptionPlanId == 'sl_trial_account') {
        // If the user was on a trial account,
        const data = { product: productId, ...meta }
        await this.createSubscription(data, reason)
      }
      // Else we just change the plan.
      else {
        await accountsAxios.post(`/api/subscription/ChangePlan?newPlanId=${productId}`)
        EventBus.$emit(upgradeEvents.UPGRADE_SUCCESS)
        await userInfoStore.updateUserInfo()
      }
    },
    async createSubscription(data, reasonOpened) {
      const { createSubscription } = useCreateSubscription()
      await createSubscription(data, reasonOpened)
    },
  },
}
