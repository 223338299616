<template>
  <div class="tabs">
    <template v-for="option in options" :key="`${id}-${getOptionLabel(option)}`">
      <input
        :name="id"
        :id="`radio-${id}-${getOptionLabel(option)}`"
        type="radio"
        :value="getOptionLabel(option)"
        v-model="value"
        class="hidden"
      />
      <label
        :for="`radio-${id}-${getOptionLabel(option)}`"
        tabindex="0"
        class="tab-lifted tab flex-grow gap-2"
        :data-tip="getOptionTooltip(option)"
        :class="{
          'tab-active': value === getOptionValue(option),
          'tooltip tooltip-secondary': !!getOptionTooltip(option),
        }"
      >
        <Component v-if="getOptionIcon(option)" :is="getOptionIcon(option)" class="w-6" />
        {{ getOptionLabel(option) }}
      </label>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import type { Component } from 'vue'
import { v4 as uuid } from 'uuid'

const id = ref(uuid())

type Option = string | { label: string; value: string | number | boolean; tooltip?: string; icon?: string | Component }

const getOptionValue = (option: Option) => (typeof option === 'string' ? option : option.value)

const getOptionLabel = (option: Option) => {
  return typeof option === 'string' ? option : option.label
}

const getOptionIcon = (option: Option) => {
  return typeof option === 'string' ? undefined : option.icon
}

const getOptionTooltip = (option: Option) => {
  return typeof option === 'string' ? undefined : option.tooltip
}

const props = defineProps<{
  modelValue: string | number | boolean
  options: Option[]
}>()
const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number | boolean): void
}>()

const value = computed({
  get: () => {
    return props.modelValue
  },
  set: (value) => {
    const newValue = getOptionValue(props.options.find((option) => getOptionLabel(option) === value))
    emit('update:modelValue', newValue)
  },
})
</script>
