<script setup lang="ts">
import { useHead } from '@unhead/vue'
import { Button } from '@/components/ui/button'
import IconSaxArrowRight from '@/components/Icons/iconsax/IconSaxArrowRight.vue'

useHead({
  title: 'Brand Partners',
  meta: [
    {
      name: 'description',
      content: 'Save on awesome services through StreamLadder!',
    },
  ],
})
</script>

<template>
  <main class="flex flex-col gap-4">
    <div class="flex flex-row flex-wrap items-end justify-between">
      <header class="flex flex-col">
        <h1 class="text-3xl lg:text-5xl">Brand partners</h1>
      </header>
    </div>

    <div class="max-w-md bg-white flex flex-col gap-4 rounded-xl shadow-md overflow-hidden md:max-w p-6">
      <div class="flex items-center">
        <img src="/images/brand-partners/throne.png" class="w-full" alt="logo" >
      </div>
      
      <p class="text-gray-600 mb-6 text-center">
        Join 500,000+ creators and curate your free privacy-first Wishlist! Let your fans support you by sending you gifts without sharing your personal information.
      </p>

      <Button size="lg" as="a" href="https://throne.com/signup?c=streamladder" target="_blank">
        Sign up now
        <IconSaxArrowRight class="h-5 w-5" />
      </Button>
      
      <p class="text-sm text-gray-500 text-center">Join now and receive 10% off of gift store items</p>
    </div>


  </main>
</template>
