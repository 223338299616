<script lang="ts" setup>
import type { EventContentArg } from '@fullcalendar/common'
import { computed, onMounted, ref, watch } from 'vue'
import { type PostDto } from '@/apis/streamladder-publisher/model'
import TikTokIcon from '@/components/Icons/SocialMedia/TikTokIcon.vue'
import InstagramSimpleIcon from '@/components/Icons/SocialMedia/InstagramSimpleIcon.vue'
import YoutubeIcon from '@/components/Icons/SocialMedia/YoutubeIcon.vue'
import { useContentPublisherStore } from '@/components-v2/content-publisher/_store'
import { useImage } from '@vueuse/core'
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area'

const emit = defineEmits<{ (name: 'click', id: string): void }>()
const props = defineProps<{ eventContent: EventContentArg; direction?: 'horizontal' }>()
const extendedProps = computed(() => props.eventContent.event.extendedProps)
const apiData = computed<Required<PostDto>>(() => extendedProps.value.apiData)
const status = computed(() => apiData.value.status.overallStatus)
// Uncomment for testing:
// const status = computed(() => {
//   const statuses = unwrap.values(OverallPostStatus)
//   return statuses[Math.floor(Math.random() * statuses.length)]
// })

const isPublished = computed(() => status.value === 'Published')
const isFailed = computed(() => status.value === 'Failed' || status.value === 'PartialFailure')
const isProcessing = computed(() => status.value === 'Publishing' || status.value === 'New')
const isPending = computed(() => !isPublished.value && !isFailed.value && !isProcessing.value)

const store = useContentPublisherStore()
const isUpdating = computed(() => store.backgroundActions.includes(apiData.value.id as string))

const thumbnail = computed(() => {
  for (const target of apiData.value.targets ?? []) {
    if (target.thumbnailUrl) {
      return target.thumbnailUrl
    }
  }
  return ''
})

const { isLoading, error } = useImage({ src: thumbnail })

const title = computed(() => {
  for (const target of apiData.value.targets ?? []) {
    if (target.title) {
      return target.title
    }
  }
  for (const target of apiData.value.targets ?? []) {
    if (target.description) {
      return target.description
    }
  }
  return 'New Clip'
})

const time = computed(() => {
  if (!apiData.value.scheduledAt) return ''
  const date = new Date(apiData.value.scheduledAt)
  return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
})

function onClick() {
  if (apiData.value.id) {
    emit(`click`, apiData.value.id)
  }
}

const row = computed(() => extendedProps.value.row)
const container = ref<HTMLElement | null>(null)

function updateHarness() {
  const harness = container.value?.closest('.fc-daygrid-event-harness') as HTMLElement
  if (harness) {
    harness.style.gridRowStart = row.value.toString()
  }
}

const isHorizontal = computed(() => props.direction === 'horizontal')

watch(row, updateHarness)
onMounted(updateHarness)

const imageFailed = ref(false)
</script>

<template>
  <article
    ref="container"
    :class="{
      'bg-rose-500 hover:bg-rose-600': isFailed,
      'bg-[#B3B3B3] hover:bg-[#979797]': isProcessing,
      'bg-emerald-500 hover:bg-emerald-600': isPublished,
      'bg-sky-600 hover:bg-sky-700': isPending,
      'opacity-75': isUpdating,
      'pointer-events-none': isUpdating,
      'flex-row': isHorizontal,
      'w-full flex-col': !isHorizontal,
    }"
    class="scale-animation group relative flex cursor-pointer flex-row gap-1 overflow-hidden rounded-lg p-1.5 text-white transition-all hover:shadow active:scale-90"
    @click="onClick"
  >
    <div v-if="thumbnail && !imageFailed" class="block w-14 flex-shrink-0 flex-row md:hidden">
      <img
        :src="thumbnail"
        alt="thumbnail"
        @error="imageFailed = true"
        class="rounded-lg aspect-[9/16] w-full bg-black object-cover"
      />
    </div>

    <div class="flex flex-col gap-1 overflow-hidden">
      <div class="flex-col" v-if="!isHorizontal">
        <p
          v-if="isPublished"
          class="self-start rounded bg-emerald-600 px-1 py-0.5 text-xs transition-all group-hover:bg-emerald-700"
        >
          Published
        </p>
        <p
          v-else-if="isFailed"
          class="self-start rounded bg-rose-600 px-1 py-0.5 text-xs transition-all group-hover:bg-rose-700"
        >
          Failed
        </p>
        <p
          v-else-if="isProcessing"
          class="self-start rounded bg-[#979797] px-1 py-0.5 text-xs transition-all group-hover:bg-[#7B7B7B]"
        >
          In progress
        </p>
        <p
          v-else-if="isPending"
          class="self-start rounded bg-sky-700 px-1 py-0.5 text-xs transition-all group-hover:bg-sky-800"
        >
          Scheduled
        </p>
        <div v-if="isUpdating" class="absolute right-1 top-1">
          <div class="h-4 w-4 animate-spin rounded-full border-2 border-white border-l-transparent" />
        </div>
      </div>

      <h4 class="hidden whitespace-normal text-sm font-bold !text-inherit sm:line-clamp-2">
        {{ title }}
      </h4>

      <p v-if="time" class="hidden text-xs sm:inline">{{ time }}</p>

      <ScrollArea class="w-full">
        <ol class="flex w-full gap-1">
          <li v-for="target in apiData.targets ?? []" :key="target.id!">
            <span v-if="target.socialMedia" class="grid h-6 w-6 place-items-center rounded-full bg-white">
              <TikTokIcon v-if="target.socialMedia === 'Tiktok'" class="h-3 w-3 text-black" />
              <InstagramSimpleIcon
                v-else-if="target.socialMedia === 'Instagram'"
                class="h-3 w-3 text-special-instagram"
              />
              <YoutubeIcon v-else-if="target.socialMedia === 'Youtube'" class="h-3 w-3 text-black" />
            </span>
          </li>
        </ol>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
      <div
        v-if="apiData.targets && apiData.targets.length > 3"
        class="fade-overlay transition-all group-hover:opacity-0"
        :style="`--bg-color: ${
          isFailed ? '#f43f5e' : isProcessing ? '#B3B3B3' : isPublished ? '#10b981' : isPending ? '#0369a1' : '#ffffff'
        };`"
      ></div>

      <h4 class="line-clamp-2 whitespace-normal text-sm font-bold !text-inherit sm:hidden">
        {{ title }}
      </h4>

      <p v-if="time" class="text-xs sm:hidden">{{ time }}</p>
    </div>
  </article>
</template>

<style lang="scss" scoped>
.scale-animation {
  animation: scale 0.2s ease-in-out;
}

@keyframes scale {
  0% {
    opacity: 0;
    transform-origin: bottom;
    transform: scale(0.9) translateY(0.5rem);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.fade-overlay::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 10px;
  background: linear-gradient(to left, var(--bg-color), transparent);
}
</style>
