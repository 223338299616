/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * StreamLadder.Accounts.Api
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/vue-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  QueryKey,
  UseQueryReturnType,
} from '@tanstack/vue-query'
import { unref } from 'vue'
import type { MaybeRef } from '@tanstack/vue-query/build/lib/types'
import type {
  TrialStateApiResponse,
  ApiError,
  ApplyDiscountResponseApiResponse,
  PostApiSubscriptionChangePlanParams,
  GetApiSubscriptionGeneratePaymentLinkV2Params,
  CancelSubscriptionRequest,
} from '.././model'
import { accountsAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never

export const postApiSubscriptionTrials = (options?: SecondParameter<typeof accountsAxiosInstance>) => {
  return accountsAxiosInstance<TrialStateApiResponse>({ url: `/api/Subscription/trials`, method: 'post' }, options)
}

export const getPostApiSubscriptionTrialsMutationOptions = <
  TError = ApiError,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiSubscriptionTrials>>, TError, TVariables, TContext>
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof postApiSubscriptionTrials>>, TError, TVariables, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiSubscriptionTrials>>, TVariables> = () => {
    return postApiSubscriptionTrials(requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiSubscriptionTrialsMutationResult = NonNullable<Awaited<ReturnType<typeof postApiSubscriptionTrials>>>

export type PostApiSubscriptionTrialsMutationError = ApiError

export const usePostApiSubscriptionTrials = <TError = ApiError, TVariables = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiSubscriptionTrials>>, TError, TVariables, TContext>
  request?: SecondParameter<typeof accountsAxiosInstance>
}) => {
  const mutationOptions = getPostApiSubscriptionTrialsMutationOptions(options)

  return useMutation(mutationOptions)
}
export const deleteApiSubscriptionTrials = (options?: SecondParameter<typeof accountsAxiosInstance>) => {
  return accountsAxiosInstance<TrialStateApiResponse>({ url: `/api/Subscription/trials`, method: 'delete' }, options)
}

export const getDeleteApiSubscriptionTrialsMutationOptions = <
  TError = ApiError,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiSubscriptionTrials>>, TError, TVariables, TContext>
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof deleteApiSubscriptionTrials>>, TError, TVariables, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiSubscriptionTrials>>, TVariables> = () => {
    return deleteApiSubscriptionTrials(requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteApiSubscriptionTrialsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiSubscriptionTrials>>
>

export type DeleteApiSubscriptionTrialsMutationError = ApiError

export const useDeleteApiSubscriptionTrials = <TError = ApiError, TVariables = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteApiSubscriptionTrials>>, TError, TVariables, TContext>
  request?: SecondParameter<typeof accountsAxiosInstance>
}) => {
  const mutationOptions = getDeleteApiSubscriptionTrialsMutationOptions(options)

  return useMutation(mutationOptions)
}
export const postApiSubscriptionDiscounts = (options?: SecondParameter<typeof accountsAxiosInstance>) => {
  return accountsAxiosInstance<ApplyDiscountResponseApiResponse>(
    { url: `/api/Subscription/discounts`, method: 'post' },
    options
  )
}

export const getPostApiSubscriptionDiscountsMutationOptions = <
  TError = ApiError,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiSubscriptionDiscounts>>, TError, TVariables, TContext>
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof postApiSubscriptionDiscounts>>, TError, TVariables, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiSubscriptionDiscounts>>, TVariables> = () => {
    return postApiSubscriptionDiscounts(requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiSubscriptionDiscountsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiSubscriptionDiscounts>>
>

export type PostApiSubscriptionDiscountsMutationError = ApiError

export const usePostApiSubscriptionDiscounts = <TError = ApiError, TVariables = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postApiSubscriptionDiscounts>>, TError, TVariables, TContext>
  request?: SecondParameter<typeof accountsAxiosInstance>
}) => {
  const mutationOptions = getPostApiSubscriptionDiscountsMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getApiSubscriptionBillingInfo = (
  options?: SecondParameter<typeof accountsAxiosInstance>,
  signal?: AbortSignal
) => {
  return accountsAxiosInstance<void>({ url: `/api/Subscription/BillingInfo`, method: 'get', signal }, options)
}

export const getGetApiSubscriptionBillingInfoQueryKey = () => ['api', 'Subscription', 'BillingInfo'] as const

export const getGetApiSubscriptionBillingInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiSubscriptionBillingInfo>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiSubscriptionBillingInfo>>, TError, TData>
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof getApiSubscriptionBillingInfo>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiSubscriptionBillingInfoQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiSubscriptionBillingInfo>>> = ({ signal }) =>
    getApiSubscriptionBillingInfo(requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions }
}

export type GetApiSubscriptionBillingInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiSubscriptionBillingInfo>>
>
export type GetApiSubscriptionBillingInfoQueryError = unknown

export const useGetApiSubscriptionBillingInfo = <
  TData = Awaited<ReturnType<typeof getApiSubscriptionBillingInfo>>,
  TError = unknown
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiSubscriptionBillingInfo>>, TError, TData>
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiSubscriptionBillingInfoQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}

export const postApiSubscriptionUpgradeToYearlySubscription = (
  options?: SecondParameter<typeof accountsAxiosInstance>
) => {
  return accountsAxiosInstance<void>({ url: `/api/Subscription/UpgradeToYearlySubscription`, method: 'post' }, options)
}

export const getPostApiSubscriptionUpgradeToYearlySubscriptionMutationOptions = <
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSubscriptionUpgradeToYearlySubscription>>,
    TError,
    TVariables,
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiSubscriptionUpgradeToYearlySubscription>>,
  TError,
  TVariables,
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiSubscriptionUpgradeToYearlySubscription>>,
    TVariables
  > = () => {
    return postApiSubscriptionUpgradeToYearlySubscription(requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiSubscriptionUpgradeToYearlySubscriptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiSubscriptionUpgradeToYearlySubscription>>
>

export type PostApiSubscriptionUpgradeToYearlySubscriptionMutationError = unknown

export const usePostApiSubscriptionUpgradeToYearlySubscription = <
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSubscriptionUpgradeToYearlySubscription>>,
    TError,
    TVariables,
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}) => {
  const mutationOptions = getPostApiSubscriptionUpgradeToYearlySubscriptionMutationOptions(options)

  return useMutation(mutationOptions)
}
export const postApiSubscriptionChangePlan = (
  params?: MaybeRef<PostApiSubscriptionChangePlanParams>,
  options?: SecondParameter<typeof accountsAxiosInstance>
) => {
  return accountsAxiosInstance<void>({ url: `/api/Subscription/ChangePlan`, method: 'post', params }, options)
}

export const getPostApiSubscriptionChangePlanMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSubscriptionChangePlan>>,
    TError,
    { params?: PostApiSubscriptionChangePlanParams },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiSubscriptionChangePlan>>,
  TError,
  { params?: PostApiSubscriptionChangePlanParams },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiSubscriptionChangePlan>>,
    { params?: PostApiSubscriptionChangePlanParams }
  > = (props) => {
    const { params } = props ?? {}

    return postApiSubscriptionChangePlan(params, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiSubscriptionChangePlanMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiSubscriptionChangePlan>>
>

export type PostApiSubscriptionChangePlanMutationError = unknown

export const usePostApiSubscriptionChangePlan = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSubscriptionChangePlan>>,
    TError,
    { params?: PostApiSubscriptionChangePlanParams },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}) => {
  const mutationOptions = getPostApiSubscriptionChangePlanMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getApiSubscriptionGeneratePaymentLinkV2 = (
  params?: MaybeRef<GetApiSubscriptionGeneratePaymentLinkV2Params>,
  options?: SecondParameter<typeof accountsAxiosInstance>,
  signal?: AbortSignal
) => {
  return accountsAxiosInstance<void>(
    { url: `/api/Subscription/GeneratePaymentLinkV2`, method: 'get', params, signal },
    options
  )
}

export const getGetApiSubscriptionGeneratePaymentLinkV2QueryKey = (
  params?: MaybeRef<GetApiSubscriptionGeneratePaymentLinkV2Params>
) => ['api', 'Subscription', 'GeneratePaymentLinkV2', ...(params ? [params] : [])] as const

export const getGetApiSubscriptionGeneratePaymentLinkV2QueryOptions = <
  TData = Awaited<ReturnType<typeof getApiSubscriptionGeneratePaymentLinkV2>>,
  TError = unknown
>(
  params?: MaybeRef<GetApiSubscriptionGeneratePaymentLinkV2Params>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiSubscriptionGeneratePaymentLinkV2>>, TError, TData>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getApiSubscriptionGeneratePaymentLinkV2>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiSubscriptionGeneratePaymentLinkV2QueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiSubscriptionGeneratePaymentLinkV2>>> = ({ signal }) =>
    getApiSubscriptionGeneratePaymentLinkV2(params, requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions }
}

export type GetApiSubscriptionGeneratePaymentLinkV2QueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiSubscriptionGeneratePaymentLinkV2>>
>
export type GetApiSubscriptionGeneratePaymentLinkV2QueryError = unknown

export const useGetApiSubscriptionGeneratePaymentLinkV2 = <
  TData = Awaited<ReturnType<typeof getApiSubscriptionGeneratePaymentLinkV2>>,
  TError = unknown
>(
  params?: MaybeRef<GetApiSubscriptionGeneratePaymentLinkV2Params>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiSubscriptionGeneratePaymentLinkV2>>, TError, TData>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiSubscriptionGeneratePaymentLinkV2QueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}

export const getApiSubscriptionIdPayments = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof accountsAxiosInstance>,
  signal?: AbortSignal
) => {
  return accountsAxiosInstance<void>({ url: `/api/Subscription/${unref(id)}/payments`, method: 'get', signal }, options)
}

export const getGetApiSubscriptionIdPaymentsQueryKey = (id: MaybeRef<string>) =>
  ['api', 'Subscription', id, 'payments'] as const

export const getGetApiSubscriptionIdPaymentsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiSubscriptionIdPayments>>,
  TError = unknown
>(
  id: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiSubscriptionIdPayments>>, TError, TData>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getApiSubscriptionIdPayments>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiSubscriptionIdPaymentsQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiSubscriptionIdPayments>>> = ({ signal }) =>
    getApiSubscriptionIdPayments(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, staleTime: 60000, ...queryOptions }
}

export type GetApiSubscriptionIdPaymentsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiSubscriptionIdPayments>>
>
export type GetApiSubscriptionIdPaymentsQueryError = unknown

export const useGetApiSubscriptionIdPayments = <
  TData = Awaited<ReturnType<typeof getApiSubscriptionIdPayments>>,
  TError = unknown
>(
  id: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiSubscriptionIdPayments>>, TError, TData>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiSubscriptionIdPaymentsQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}

export const putApiSubscriptionIdCancel = (
  id: MaybeRef<string>,
  cancelSubscriptionRequest: MaybeRef<CancelSubscriptionRequest>,
  options?: SecondParameter<typeof accountsAxiosInstance>
) => {
  return accountsAxiosInstance<void>(
    {
      url: `/api/Subscription/${unref(id)}/cancel`,
      method: 'put',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: cancelSubscriptionRequest,
    },
    options
  )
}

export const getPutApiSubscriptionIdCancelMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiSubscriptionIdCancel>>,
    TError,
    { id: string; data: CancelSubscriptionRequest },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiSubscriptionIdCancel>>,
  TError,
  { id: string; data: CancelSubscriptionRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiSubscriptionIdCancel>>,
    { id: string; data: CancelSubscriptionRequest }
  > = (props) => {
    const { id, data } = props ?? {}

    return putApiSubscriptionIdCancel(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutApiSubscriptionIdCancelMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiSubscriptionIdCancel>>
>
export type PutApiSubscriptionIdCancelMutationBody = CancelSubscriptionRequest
export type PutApiSubscriptionIdCancelMutationError = unknown

export const usePutApiSubscriptionIdCancel = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiSubscriptionIdCancel>>,
    TError,
    { id: string; data: CancelSubscriptionRequest },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}) => {
  const mutationOptions = getPutApiSubscriptionIdCancelMutationOptions(options)

  return useMutation(mutationOptions)
}
export const putApiSubscriptionIdPause = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof accountsAxiosInstance>
) => {
  return accountsAxiosInstance<void>({ url: `/api/Subscription/${unref(id)}/pause`, method: 'put' }, options)
}

export const getPutApiSubscriptionIdPauseMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiSubscriptionIdPause>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof putApiSubscriptionIdPause>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiSubscriptionIdPause>>, { id: string }> = (
    props
  ) => {
    const { id } = props ?? {}

    return putApiSubscriptionIdPause(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutApiSubscriptionIdPauseMutationResult = NonNullable<Awaited<ReturnType<typeof putApiSubscriptionIdPause>>>

export type PutApiSubscriptionIdPauseMutationError = unknown

export const usePutApiSubscriptionIdPause = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof putApiSubscriptionIdPause>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof accountsAxiosInstance>
}) => {
  const mutationOptions = getPutApiSubscriptionIdPauseMutationOptions(options)

  return useMutation(mutationOptions)
}
export const putApiSubscriptionIdResume = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof accountsAxiosInstance>
) => {
  return accountsAxiosInstance<void>({ url: `/api/Subscription/${unref(id)}/resume`, method: 'put' }, options)
}

export const getPutApiSubscriptionIdResumeMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiSubscriptionIdResume>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof putApiSubscriptionIdResume>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putApiSubscriptionIdResume>>, { id: string }> = (
    props
  ) => {
    const { id } = props ?? {}

    return putApiSubscriptionIdResume(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutApiSubscriptionIdResumeMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiSubscriptionIdResume>>
>

export type PutApiSubscriptionIdResumeMutationError = unknown

export const usePutApiSubscriptionIdResume = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiSubscriptionIdResume>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}) => {
  const mutationOptions = getPutApiSubscriptionIdResumeMutationOptions(options)

  return useMutation(mutationOptions)
}
export const postApiSubscriptionCancelPayPal = (
  cancelSubscriptionRequest: MaybeRef<CancelSubscriptionRequest>,
  options?: SecondParameter<typeof accountsAxiosInstance>
) => {
  return accountsAxiosInstance<void>(
    {
      url: `/api/Subscription/CancelPayPal`,
      method: 'post',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: cancelSubscriptionRequest,
    },
    options
  )
}

export const getPostApiSubscriptionCancelPayPalMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSubscriptionCancelPayPal>>,
    TError,
    { data: CancelSubscriptionRequest },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiSubscriptionCancelPayPal>>,
  TError,
  { data: CancelSubscriptionRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiSubscriptionCancelPayPal>>,
    { data: CancelSubscriptionRequest }
  > = (props) => {
    const { data } = props ?? {}

    return postApiSubscriptionCancelPayPal(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiSubscriptionCancelPayPalMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiSubscriptionCancelPayPal>>
>
export type PostApiSubscriptionCancelPayPalMutationBody = CancelSubscriptionRequest
export type PostApiSubscriptionCancelPayPalMutationError = unknown

export const usePostApiSubscriptionCancelPayPal = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSubscriptionCancelPayPal>>,
    TError,
    { data: CancelSubscriptionRequest },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}) => {
  const mutationOptions = getPostApiSubscriptionCancelPayPalMutationOptions(options)

  return useMutation(mutationOptions)
}
