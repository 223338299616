<script setup lang="ts">
import TimeLineTrack from '@/modules/SLTimeline/TimeLineTrack.vue'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import SegmentNode from '@/areas/editor/timeline/tracks/segments/SegmentNode.vue'

const segmentsStore = useSegmentsStore()
const segments = segmentsStore.whereIsNotZoom()
</script>

<template>
  <TimeLineTrack class="group relative h-full min-h-6 max-h-12 w-full">
    <div class="bg-[#fdefef] dark:bg-[#53232599] absolute w-full inset-y-0.5 rounded border border-red-100 dark:border-red-900" />
    <SegmentNode
      v-for="(segment, i) in segments" 
      :key="segment.id"
      :id="segment.id" 
      :index="i"
      :segments="segments"
    />
  </TimeLineTrack>
</template>
