<script setup lang="ts">
import { useStickersStore } from '@/store/entity-system/useStickersStore'
import MovableElement from '@/modules/SLMovable/MovableElement.vue'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useMovableContext } from '@/modules/SLMovable/useMovableContext'
import StickerElementToolbar from '@/components/Editor/Movable/StickerElementToolbar.vue'
import ConnectedStickerElement from '@/components/Editor/Movable/ConnectedStickerElement.vue'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import IconSaxArrowUp2 from '@/components/Icons/iconsax/IconSaxArrowUp2.vue'

const props = defineProps<{ id: string; snappingEnabled: boolean }>()

const stickersStore = useStickersStore()
const sticker = stickersStore.selectById(props.id)

const aspectLock = computed(() => {
  const sourceSize = { width: sticker.area.width, height: sticker.area.height }
  return {
    width: sourceSize.width * movableWidth.value,
    height: sourceSize.height * movableHeight.value,
  }
})

const { width: movableWidth, height: movableHeight } = useMovableContext()!
const focused = stickersStore.selectFocusById(props.id)

function setFocus() {
  // Ensure all other focus effects are applied before this one
  setTimeout(() => stickersStore.focus(props.id), 0)
}

function handleKeyPress(event: KeyboardEvent) {

  if (!focused.value || sticker.editing) return

  if (event.ctrlKey && event.key === 'd') {
    stickersStore.duplicateById(props.id)

    event.preventDefault()
    return false
  }

  if (event.ctrlKey || event.metaKey) {
    if (event.key === '}') {
      stickersStore.moveToForeground(props.id)

      event.preventDefault()
      return false
    } else if (event.key === '{') {
      stickersStore.moveToBackground(props.id)

      event.preventDefault()
      return false
    } else if (event.key === ']') {
      stickersStore.shift(props.id, +1)

      event.preventDefault()
      return false
    } else if (event.key === '[') {
      stickersStore.shift(props.id, -1)

      event.preventDefault()
      return false
    }
  }

  if (event.key === 'Escape') {
    if (focused.value) {
      useEditorFocusStore().unFocus()
      event.preventDefault()
      return false
    }
  }
}

onMounted(() => {
  window.addEventListener('keypress', handleKeyPress)
  window.addEventListener('keydown', handleKeyPress)
})

onUnmounted(() => {
  window.removeEventListener('keypress', handleKeyPress)
  window.removeEventListener('keydown', handleKeyPress)
})

const mouseOver = ref(false)

const style = computed(() => ({
  left: sticker.area.x * 100 + '%',
  top: sticker.area.y * 100 + '%',
  width: sticker.area.width * 100 + '%',
  height: sticker.area.height * 100 + '%',
}))
</script>

<template>
  <div
    @dblclick="stickersStore.setEditModeById(props.id, true)"
    :style="style"
    class="group absolute z-50 transition-opacity select-none"
    @click.stop
    @touchstart="setFocus"
    @mousedown.stop="setFocus"
    @mouseover="mouseOver = true"
    @mouseleave="mouseOver = false"
  >
    <MovableElement :id="sticker.id" move resize class="absolute inset-0"
      v-model="sticker.area"
      shape="rectangle"
      :disableArrowKeys="sticker.editing"
      :aspectLock="aspectLock"
      :bounds="{ top: -0.10, right: 1.10, bottom: 1.10, left: -0.10 }"
      :snap="snappingEnabled"
      :min-size="0"
      :resize-handle-class="focused ? 'z-[250]' : ''"
      :handles="['e', 'w']"
    >
      <Teleport to="#sticker-layer">
        <div class="absolute" :class="sticker.editing ? 'z-[250]' : ''" :style="style">
          <ConnectedStickerElement :id="id" ref="stickerElementRef" />
        </div>
      </Teleport>
      <template #move>
        <div
          class="absolute -inset-px border-2 transition-[opacity] border-brand-state-active-border box-content"
          :class="{
            'opacity-100': focused,
            'opacity-0 group-hover:opacity-75': !focused,
          }"
        />
      </template>
      <template #resize-direction="{ angle }">
        <div
          :class="focused ? 'opacity-100' : 'opacity-0'"
          class="w-5 h-5 grid place-items-center rounded-full layer-1 transition-[opacity] text-brand-state-active-border drop-shadow"
        >
          <IconSaxArrowUp2 class="w-3 h-3" :style="{ transform: `rotate(${angle}deg)`}" />
        </div>
      </template>
    </MovableElement>

    <StickerElementToolbar v-if="focused" :sticker="sticker" />
  </div>
</template>

<style scoped lang="scss"></style>
