<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
    <path
      d="M34.9516 14.7354H11.948V26.2372H34.9516V14.7354Z"
      fill="transparent"
      stroke="currentColor"
      stroke-width="4.64312"
      stroke-linejoin="round"
    />
    <path
      d="M5.37549 5.69824V35.2743"
      stroke="currentColor"
      stroke-width="4.64312"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
