<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 0 24 24" fill="none">
    <path
      transform="rotate(180 10.3235 12.1075)"
      :stroke="color"
      id="svg_1"
      d="m7.89349,6.03753l-6.07,6.07l6.07,6.07m10.93,-6.07l-16.83,0"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linejoin="round"
      stroke-linecap="round"
    />
    <line
      :stroke="color"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      id="svg_2"
      y2="18.93851"
      x2="22.11131"
      y1="4.71316"
      x1="22.11131"
      stroke-width="1.5"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
</script>
