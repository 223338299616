/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * StreamLadder Publisher Api
 * OpenAPI spec version: v1
 */

export type PrivacyLevel = (typeof PrivacyLevel)[keyof typeof PrivacyLevel]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrivacyLevel = {
  PUBLIC_TO_EVERYONE: 'PUBLIC_TO_EVERYONE',
  MUTUAL_FOLLOW_FRIENDS: 'MUTUAL_FOLLOW_FRIENDS',
  FOLLOWER_OF_CREATOR: 'FOLLOWER_OF_CREATOR',
  SELF_ONLY: 'SELF_ONLY',
} as const
