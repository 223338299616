<script lang="ts" setup>
import { ref, watch } from 'vue'
import { metadataService } from '@/services/metadataService'
import { round } from 'lodash-es'

const props = defineProps<{ src: string | null | undefined, alt: string }>()

const img = ref<HTMLImageElement | null>(null)
const aspectRatio = ref(0)

function setData() {
  if (img.value) {
    aspectRatio.value = img.value.naturalWidth / img.value.naturalHeight
  }
}

const src = ref(props.src)

const placeholder = metadataService.usePlaceholder(512)
async function applyPlaceholder() {
  src.value = await placeholder.then((placeholder) => placeholder.blobUrl)
}

watch(() => props.src, (value) => {
  src.value = value
  setData()
}, { immediate: true })
</script>

<template>
  <div class="min-w-[33%] bg-surface-panel-100 border border-surface-input-border overflow-hidden">
    <div class="relative rounded-[inherit] h-0 pb-[56.25%]">
      <Transition appear
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        enter-active-class="transition-opacity"
        leave-active-class="transition-opacity"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <img
          v-if="src" ref="img" :alt="alt" :src="src" draggable="false"
          class="max-w-full max-h-full object-contain rounded-[inherit] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          :class="{ 'scale-90 rounded-lg': round(aspectRatio, 2) !== round(16 / 9, 2) }"
          @load="setData"
          @error="applyPlaceholder"
        />
        <div v-else class="skeleton rounded-[inherit] !absolute w-full h-full"/>
      </Transition>
    </div>
    <slot />
  </div>
</template>

<style lang="scss" scoped>

</style>
