<script lang="ts" setup>
import IconSaxMagicpen from '@/components/Icons/iconsax/IconSaxMagicpen.vue'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { useAudioAnalyzerForVideoElement } from '@/modules/AudioAnalyser/useAudioAnalyzer'
import { computed, ref, watch } from 'vue'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { v4 } from 'uuid'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { useSplitterClone } from '@/components/Editor/RemoveParts/useSplitterClone'
import logging from '@/logging'
import { useGuard } from '@/Hooks/useGuard'
import { upgradeDialog } from '@/helpers/upgradeDialog'
import * as Sentry from '@sentry/vue'
import { Button } from '@/components/ui/button'
import { Dialog, DialogTrigger, DialogContent } from '@/components/ui/dialog'
import IconSaxAddCircle from '@/components/Icons/iconsax/IconSaxAddCircle.vue'
import { Tooltip, TooltipTrigger, TooltipContent } from '@/components/ui/tooltip'

const editorClipInfoStore = useEditorClipInfoStore()

const canSegment = useGuard('video-segmentation')

const editorVideo = useVideoStore()
const editorFeedDataStore = useEditorFeedDataStore()

const mp4Url = computed(() => editorClipInfoStore.mp4Url)

const audioData = computed(() => editorClipInfoStore.audioData || undefined)

const { loadAudio, getSilentParts, isLoading, loadingProgress, decodedData } = useAudioAnalyzerForVideoElement(mp4Url, {
  decodedData: audioData.value,
})

watch(decodedData, () => {
  if (decodedData.value) {
    editorClipInfoStore.audioData = decodedData.value
  }
})

const segments = computed(() => editorFeedDataStore.segments)
const { cloned, sync } = useSplitterClone(segments)

const noSilentParts = ref(false)

const isLoadingDialogOpen = ref(false)
const hasTriedToRemoveParts = ref(false)
const removeParts = async () => {

  if (!canSegment.value) {
    upgradeDialog.open('video-segmentation')
    return
  }
  
  isLoadingDialogOpen.value = true
  try {
    editorVideo.playing = false

    sync()

    await loadAudio()
    const skipZones = getSilentParts()

    if (skipZones.length === 0) {
      noSilentParts.value = true
      hasTriedToRemoveParts.value = true
      isLoadingDialogOpen.value = false
      return
    }

    // merge segments and skipZones
    const segments = editorFeedDataStore.segments
    const newSegments: { id: string; start: number; end: number }[] = []
    segments.forEach((segment) => {

      let start = segment.start
      let end = segment.end

      skipZones.forEach((skipZone) => {
        if (skipZone.start <= start && skipZone.end >= start) {
          start = skipZone.end
        }
        if (skipZone.start <= end && skipZone.end >= end) {
          end = skipZone.start
        }
        if (skipZone.start >= start && skipZone.end <= end) {
          newSegments.push({
            id: v4(),
            start,
            end: skipZone.start,
          })
          start = skipZone.end
        }
      })
      if (start < end) {
        newSegments.push({
          id: v4(),
          start,
          end,
        })
      }
    })

    editorFeedDataStore.segments = newSegments
    editorClipInfoStore.audioSkipped = true

    const skippedDuration = skipZones.reduce((acc, skipZone) => acc + skipZone.end - skipZone.start, 0) / 1000

    logging.trackEvent('Auto Splitting added', {
      cutsPlaced: skipZones.length,
      secondsRemoved: skippedDuration,
      averageCutLength: skippedDuration / skipZones.length,
      secondsTotal: editorVideo._duration,
    })

    hasTriedToRemoveParts.value = true
  } catch (e) {
    Sentry.captureException(e)
    isLoading.value = false
    noSilentParts.value = true
    hasTriedToRemoveParts.value = true
  }

  isLoadingDialogOpen.value = false
}

const reset = () => {
  editorFeedDataStore.segments = cloned.value
  editorClipInfoStore.audioSkipped = false
}
</script>
<template>
  <Tooltip v-if="!hasTriedToRemoveParts">
    <TooltipTrigger>
      <Button @click="removeParts" size="sm" variant="primary">
        <IconSaxMagicpen class="h-4 w-4" />
        AI auto split
      </Button>
    </TooltipTrigger>
    <TooltipContent>
      Remove parts of your clip where no sound is registered
    </TooltipContent>
  </Tooltip>
  <Tooltip v-else-if="noSilentParts">
    <TooltipTrigger>
      <Button size="sm" variant="depressed" disabled class="disabled:bg-transparent">
        <IconSaxAddCircle class="rotate-45 h-4 w-4" />
        No silences found
      </Button>
    </TooltipTrigger>
    <TooltipContent>
      No silences detected in your clip
    </TooltipContent>
  </Tooltip>
  <Tooltip v-else>
    <TooltipTrigger>
      <Button  @click="reset" variant="ghostDestructive" size="sm">
        <IconSaxMagicpen class="h-4 w-4" />
        Remove AI split
      </Button>
    </TooltipTrigger>
    <TooltipContent>
      Undo the removal of silent parts
    </TooltipContent>
  </Tooltip>

  <Dialog v-model:open="isLoadingDialogOpen">
    <DialogContent class="flex flex-col gap-2 items-center">
      <lottie-animation class="mx-auto w-60" url="/lottie/loading-cat.json" :loop="true" :autoPlay="true" />
      <div class="text-2xl font-bold">Removing silences</div>
      <div class="text-center font-light leading-relaxed">
        Our cat is busy removing silent parts from your video. Hang tight - duration depends on the
        clip's length. Just a few seconds more!
      </div>
      <div class="radial-progress mt-8" :style="{ ['--value']: loadingProgress }" role="progressbar">
        {{ loadingProgress }}%
      </div>
    </DialogContent>
  </Dialog>
</template>
