<script setup lang="ts">
import { computed, onMounted, type Ref, ref } from 'vue'
import { onClickOutside, useVModel } from '@vueuse/core'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import IconSaxAddSquare from '@/components/Icons/iconsax/IconSaxAddSquare.vue'
import { Input } from '@/components/ui/input'
import Spinner from '@/components/Icons/Spinner.vue'
import { useConfirmDialog } from '@/components/Dialog/Confirm/useConfirmDialog'
import TemplateItem from '@/components/SaveableTextarea/TemplateItem.vue'
import EditOrCreateTemplate from '@/components/SaveableTextarea/EditOrCreateTemplate.vue'
import {
  deleteApiSavedSocialTextFieldsId,
  getApiSavedSocialTextFields,
  postApiSavedSocialTextFields,
  putApiSavedSocialTextFieldsId
} from '@/apis/streamladder-api/saved-social-text-fields/saved-social-text-fields'
import type { SavedSocialTextField, SavedSocialTextFieldDto } from '@/apis/streamladder-api/model'
import { posthog } from 'posthog-js'

const props = defineProps<{
  class?: string
  defaultValue?: string
  modelValue?: string
  maxTextLength?: number
  placeholder?: string
  tag: string
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', payload: string | number): void
}>();

const modelValue = useVModel(props, 'modelValue', emits, {
  passive: true,
  defaultValue: props.defaultValue,
});

const inputRef = ref<string>('');
const overviewRef = ref<HTMLElement | null>(null);
const overviewOpen = ref(false);

const isLoadingText = ref('Loading templates..');
const isLoadingTemplates = ref(false);

const openOverview = async () => {
  inputRef.value = '';
  overviewOpen.value = true;
};

onClickOutside(overviewRef, () => {
  overviewOpen.value = false;
});

const allTemplateItems = ref<SavedSocialTextField[]>([]);

onMounted(async () => {
  await fetchTemplates()
});

const fetchTemplates = async () => {

  isLoadingTemplates.value = true;

  const response = await getApiSavedSocialTextFields() as SavedSocialTextField[];
  if (response) {
    allTemplateItems.value = response.filter(item => item.tag === props.tag) || [];
  }

  isLoadingTemplates.value = false;
};

const normalize = (str: string) => str.trim().toLowerCase();

const filteredTemplateItems: Ref<SavedSocialTextField[]> = computed(() => {

  // If active is currently applied, show it at the top of the list.
  if (activeTemplate.value) {

    const items = allTemplateItems.value
      .filter(item => item.id !== activeTemplate.value?.id)
      .filter(item => normalize(item.name!).includes(normalize(inputRef.value)));

    return [
      activeTemplate.value,
      ...items
    ];
  } else {
    return allTemplateItems.value
      .filter(item => normalize(item.name!).includes(normalize(inputRef.value)));
  }
});

const { reveal } = useConfirmDialog()

const applyTemplate = async (template: SavedSocialTextField) => {

  if (modelValue.value?.length === 0 || normalize(modelValue.value || '') === normalize(template.content || '')) {
    modelValue.value = template.content!.slice(0, props.maxTextLength);
    activeTemplate.value = template;
    overviewOpen.value = false;
  } else {

    const confirm = await reveal({
      title: 'Do you want to apply this template?',
      message: 'Your current text will be replaced with the template.',
    });

    if (confirm) {
      modelValue.value = template.content!.slice(0, props.maxTextLength);
      activeTemplate.value = template;
      overviewOpen.value = false;
    }
  }
};

const titleAlreadyInUse = computed(() => {
  return allTemplateItems.value.map(t => normalize(t.name!)).includes(normalize(inputRef.value));
});

const activeTemplate = ref<SavedSocialTextField | null>(null);

const templateToCreateOrEdit = ref<SavedSocialTextField | SavedSocialTextFieldDto | null>(null);

const editOrCreateTemplateOpen = ref(false);
const editOrCreateTemplateRef = ref<HTMLElement | null>(null);

onClickOutside(editOrCreateTemplateRef, () => {
  editOrCreateTemplateOpen.value = false;
});

const itemsContainerRef = ref<HTMLElement | null>(null);

const editTemplate = (item?: SavedSocialTextField) => {

  overviewOpen.value = false;

  if (item) {
    templateToCreateOrEdit.value = item;
  } else {
    templateToCreateOrEdit.value = {
      name: inputRef.value,
      content: modelValue.value || '',
      color: 'oklch(70% 0.15 0)',
      tag: props.tag,
    };
  }

  editOrCreateTemplateOpen.value = true;
};

const removeTemplate = async (item: SavedSocialTextField) => {

  editOrCreateTemplateOpen.value = false;

  const confirm = await reveal({
    title: `Do you want to remove "${item.name}"?`,
    message: 'This action cannot be undone.',
  });

  if (confirm) {

    isLoadingTemplates.value = true;

    if (activeTemplate.value?.id === item.id) {
      activeTemplate.value = null;
    }

    await deleteApiSavedSocialTextFieldsId(item.id);
    await fetchTemplates();
  }
};

const save = async (template: SavedSocialTextField) => {

  isLoadingText.value = 'Saving..';
  isLoadingTemplates.value = true;

  if (allTemplateItems.value.some(i => i.id === template.id)) {

    // Update the active template if it's the same as the one being saved.
    if (activeTemplate.value?.id === template.id) {
      activeTemplate.value = template;
    }

    // If template already exists, update it.
    await putApiSavedSocialTextFieldsId(template.id, template);
  } else {
    // If template is new, create it.
    await postApiSavedSocialTextFields(template);
  }

  await fetchTemplates();

  isLoadingTemplates.value = false;
  isLoadingText.value = 'Loading templates..';
};

const saveAndApplyTemplate = async (template?: SavedSocialTextFieldDto) => {

  const templateToApply = template || {
    name: inputRef.value,
    content: modelValue.value || '',
    color: `oklch(70% 0.15 ${360 * (Math.floor(Math.random() * 20) / 20)})`,
    tag: props.tag,
  };

  let confirmed: boolean;

  if (templateToApply.content !== modelValue.value) {

    confirmed = await reveal({
      title: 'Do you want to apply this template?',
      message: 'Your current text will be replaced with the template.',
    });

    editOrCreateTemplateOpen.value = false;

    if (confirmed) {
      modelValue.value = templateToApply.content!.slice(0, props.maxTextLength);
    }
  } else {
    confirmed = true;
    editOrCreateTemplateOpen.value = false;
    modelValue.value = templateToApply.content!.slice(0, props.maxTextLength);
  }

  await save(templateToApply);

  if (confirmed) {
    // Set the active template to the one that was just saved.
    activeTemplate.value = allTemplateItems.value.find(i => i.name === templateToApply.name)!;
  }
};

const saveTemplate = (template: SavedSocialTextField) => {
  save(template);
  editOrCreateTemplateOpen.value = false;
};

const submitTemplate = async () => {
  if (titleAlreadyInUse.value) {
    editTemplate(filteredTemplateItems.value[0]);
  } else if (modelValue?.value?.length === 0) {
    editTemplate();
  } else if (inputRef.value.length > 0) {
    await saveAndApplyTemplate();
    overviewOpen.value = false;
  } else {
    editTemplate();
  }
};

const saveOrEditButtonText = () => {
  if (titleAlreadyInUse.value) {
    return 'Edit';
  } else if ((modelValue?.value?.length || 0) > 0 && inputRef.value.length > 0) {
    return 'Save';
  } else {
    return 'Create';
  }
};
</script>

<template>
  <textarea
    v-model="modelValue"
    :maxlength="maxTextLength"
    :placeholder="props.placeholder"
    :class="cn('flex min-h-[80px] max-h-[500px] w-full rounded-t-md border border-surface-input-border bg-surface-panel-100 px-3 py-2 text-base ring-offset-background placeholder:text-brand-state-text-placeholder disabled:cursor-not-allowed disabled:opacity-50', props.class)"
  />

  <span v-if="maxTextLength" class="absolute -mt-7 right-12 text-brand-state-text-placeholder text-sm font-light pointer-events-none">
    {{ modelValue?.length || 0 }} / {{ maxTextLength }}
  </span>

  <div class="rounded-b-md bg-zinc-300 dark:bg-zinc-500 p-2">
    <Button
      variant="ghost"
      class="p-2 h-8 hover:bg-zinc-200"
      :class="{ 'bg-zinc-200 cursor-default': overviewOpen, 'hover:scale-105': activeTemplate }"
      :style="{ backgroundColor: activeTemplate?.color }"
      @click="openOverview"
    >
      <template v-if="activeTemplate">
        <p class="mt-0.5 text-sm font-light text-white">
          {{ activeTemplate.name }}
        </p>
      </template>
      <template v-else>
        <IconSaxAddSquare class="w-4 h-4" />
        <p class="mt-0.5 text-sm font-light">
          Save or select template
        </p>
      </template>
    </Button>
  </div>

  <Transition name="fade">
    <div
      v-if="overviewOpen"
      ref="overviewRef"
      class="absolute left-0 md:left-auto w-full md:w-80 z-[60] mt-2 ml-2 flex flex-col rounded-xl shadow-2xl bg-surface-panel-50 dark:bg-zinc-500 border-t-2 border-t-bg-zinc-300 dark:border-0 h-64 p-4"
    >
      <form
        class="flex flex-end w-full"
        @submit.prevent="submitTemplate"
      >
        <Input placeholder="Type your template name" class="transition-all w-full font-light text-sm " v-model="inputRef" maxlength="25" />
        <Button
          type="submit"
          variant="ghost"
          class="absolute right-4 h-12 rounded-lg text-brand-state-active-border text-sm"
        >
          {{ saveOrEditButtonText() }}
        </Button>
      </form>
      <template v-if="isLoadingTemplates">
        <div class="flex flex-col gap-4 justify-center items-center h-full w-full">
          <Spinner class="w-8 h-8 animate-spin text-company-primary-50" />
          <p class="font-light text-sm text-center"> {{ isLoadingText }}</p>
        </div>
      </template>
      <template v-else-if="filteredTemplateItems.length === 0">
        <div class="flex flex-col gap-1 justify-center items-center h-full">
          <p class="font-semibold text-xl text-center">Save Title Template</p>
          <p class="font-light text-sm text-center">Quickly save and reuse your favorite titles and hashtags</p>
        </div>
      </template>
      <template v-else>
        <div ref="itemsContainerRef" class="grid mt-2 overflow-y-auto">
          <TransitionGroup appear name="fade" tag="ol">
            <li v-for="item in filteredTemplateItems" :key="item.id!" class="w-full">
              <TemplateItem
                :item="item"
                :itemsContainerRef="itemsContainerRef"
                :activeTemplate="activeTemplate"
                @applyTemplate="applyTemplate"
                @editTemplate="editTemplate"
                @removeTemplate="removeTemplate"
              />
            </li>
          </TransitionGroup>
        </div>
      </template>
    </div>
  </Transition>

  <EditOrCreateTemplate
    v-model:is-open="editOrCreateTemplateOpen"
    :maxTextLength="maxTextLength!"
    :templateItem="templateToCreateOrEdit"
    :allTemplateItems="allTemplateItems"
    :tag="tag"
    @close="editOrCreateTemplateOpen = false"
    @save="saveTemplate"
    @saveAndApply="saveAndApplyTemplate"
  />
</template>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>