<script setup lang="ts">
import {
  ToolbarButton,
  ToolbarRoot,
} from 'radix-vue'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import { inject, computed, ref, onMounted } from 'vue'
import type { Ref } from 'vue'
import { useElementSize, useWindowSize, useResizeObserver } from '@vueuse/core'
import { Button } from '@/components/ui/button'
import { clamp } from 'lodash-es'
import CropToolbarTooltip from '@/modules/CustomLayouts/Crop/CropToolbar/CropToolbarTooltip.vue'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import type { Area } from '@/modules/SLMovable/@types/Movable'
import { useConfirmDialog } from '@/components/Dialog/Confirm/useConfirmDialog'

const props = defineProps<{ area: Area }>()
const editorWrapper = inject<Ref<HTMLElement>>('editorWrapper')!

const element = ref<HTMLElement | null>(null)
const { width, height } = useElementSize(element)

const { width: windowWidth, height: windowHeight } = useWindowSize()
const canvasBounding = ref({ width: 0, height: 0, top: 0, left: 0 })
useResizeObserver(editorWrapper, () => {
  canvasBounding.value = editorWrapper.value.getBoundingClientRect()
})

onMounted(() => {
  addEventListener('scroll', () => {
    canvasBounding.value = editorWrapper.value?.getBoundingClientRect()
  });
})

const offset = 80

const top = computed(() => {

  const topValue = (props.area.y + props.area.height) * canvasBounding.value.height + canvasBounding.value.top
  const topMin = offset
  const topMax = windowHeight.value - height.value - 2 * offset

  if (topValue <= topMin) {
    return topMin
  } else if (topValue > topMax) {
    return topMax - ((1 - (props.area.y + props.area.height)) * canvasBounding.value.height) - height.value
  } else {
    return topValue
  }
})

const left = computed(() => {

  const leftValue = (props.area.x + 0.5 * props.area.width) * canvasBounding.value.width + canvasBounding.value.left
  const leftMin = offset + 0.5 * width.value
  const leftMax = windowWidth.value - 0.5 * width.value

  return clamp(leftValue, leftMin, leftMax)
})

const editorCaptionsStore = useEditorCaptionsStore()
const confirm = useConfirmDialog()

async function handleDelete() {
  const confirmed = await confirm.reveal({
    title: 'Delete Captions',
    message: 'Are you sure you want to delete all captions?',
  })
  if (confirmed) {
    editorCaptionsStore.$reset()
  }
}
</script>

<template>
  <Teleport to="body">
    <ToolbarRoot
      ref="element"
      data-retain-focus
      :style="{ top: top + 'px', left: left - 0.5 * width + 'px' }"
      :class="{ '!opacity-0': top === offset }"
      class="layer-1 !fixed mt-1.5 flex !min-w-max max-w-full animate-[slideUpAndFade_150ms_cubic-bezier(0.4,_0,_0.2,_1)_forwards] items-center gap-0.5 p-0.5 rounded-md shadow-lg z-[250]"
      @click.stop
    >
      <ToolbarButton>
        <CropToolbarTooltip>
          <template #trigger="{ disabled }">
            <Button
              :disabled="disabled"
              @click="handleDelete"
              variant="ghost"
              size="sm"
              class="text-rose-500 hover:bg-rose-50 disabled:pointer-events-none rounded-sm"
            >
              <IconSaxTrash class="stoke-[1] h-4 w-4" />
            </Button>
          </template>
          <template #tip>
            Delete
          </template>
        </CropToolbarTooltip>
      </ToolbarButton>
    </ToolbarRoot>
  </Teleport>
</template>

<style lang="scss" scoped></style>
