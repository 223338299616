<script setup lang="ts">
import { type Component, markRaw, ref, toRef, watch } from 'vue'
import { useConditionalEventListener } from '@/Hooks/useConditionalEventListener'
import MissingOutOnFeaturesSummary from '@/areas/dashboard/components/CancellationFlow/MissingOutOnFeaturesSummary.vue'
import CancellationFeedback from '@/areas/dashboard/components/CancellationFlow/CancellationFeedback.vue'
import OfferFreeMonth from '@/areas/dashboard/components/CancellationFlow/OfferFreeMonth.vue'
import OfferFreeMonthAccepted from '@/areas/dashboard/components/CancellationFlow/OfferFreeMonthAccepted.vue'
import FoundAlternative from '@/areas/dashboard/components/CancellationFlow/FoundAlternative.vue'
import CancellationReason from '@/areas/dashboard/components/CancellationFlow/CancellationReason.vue'
import Other from '@/areas/dashboard/components/CancellationFlow/Other.vue'
import Cancelled from '@/areas/dashboard/components/CancellationFlow/Cancelled.vue'
import BuggySupport from '@/areas/dashboard/components/CancellationFlow/BuggySupport.vue'
import BuggyFeedback from '@/areas/dashboard/components/CancellationFlow/BuggyFeedback.vue'
import * as Sentry from '@sentry/browser'
import { useUserSubscriptionStore } from '@/store/user/userSubscription'
import { concat } from 'lodash-es'

const emit = defineEmits<{
  (event: 'confirm'): void
  (event: 'cancel'): void
  (event: 'update:modelValue', value: boolean): void
}>()

const props = defineProps<{ modelValue: boolean }>()

const subscriptionStore = useUserSubscriptionStore();

useConditionalEventListener(toRef(props, 'modelValue'), 'keydown', (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    emit('update:modelValue', false)
  }
})

const handleClose = () => {
  emit('update:modelValue', false)
  emit('cancel')
}

const surveyCrumbs = ref<Component[] | []>([]);
const currentSurveyComponent = ref<Component | null>(markRaw(MissingOutOnFeaturesSummary));
const shouldOfferFreeMonth = ref(false);

const handleClick = (data?: string | boolean) => {
  surveyCrumbs.value = concat(surveyCrumbs.value, currentSurveyComponent.value);
  currentSurveyComponent.value = getNextSurveyFromFlow(data);
};

const surveyFlow = [
  {
    component: MissingOutOnFeaturesSummary,
    nextSurveyComponent: () => CancellationReason
  },
  {
    component: CancellationReason,
    nextSurveyComponent: (response?: string) => {
      switch (response) {
        case 'Buggy':
          return BuggySupport;
        case 'Found alternative':
          return FoundAlternative;
        case 'Other':
          return Other;
        case 'Expensive':
          shouldOfferFreeMonth.value = !subscriptionStore?.subscription?.discountApplied;
          return CancellationFeedback;
        default:
          return CancellationFeedback;
      }
    }
  },
  {
    component: CancellationFeedback,
    nextSurveyComponent: () => {
      if (shouldOfferFreeMonth.value) {
        return OfferFreeMonth;
      } else {
        return Cancelled;
      }
    }
  },
  {
    component: OfferFreeMonthAccepted,
    nextSurveyComponent: () => null
  },
  {
    component: FoundAlternative,
    nextSurveyComponent: () => CancellationFeedback
  },
  {
    component: Other,
    nextSurveyComponent: () => CancellationFeedback
  },
  {
    component: BuggySupport,
    nextSurveyComponent: () => BuggyFeedback
  },
  {
    component: BuggyFeedback,
    nextSurveyComponent: () => OfferFreeMonth
  },
  {
    component: Cancelled,
    nextSurveyComponent: () => null
  },
  {
    component: OfferFreeMonth,
    nextSurveyComponent: (hasDeclined?: boolean) => {
      if (hasDeclined) {
        return Cancelled;
      } else {
        return OfferFreeMonthAccepted;
      }
    }
  }
];

const getNextSurveyFromFlow = (data?: string | boolean) => {
  const currentSurvey = surveyFlow.find((survey) => survey.component === currentSurveyComponent.value);
  if (currentSurvey) {
    return markRaw(currentSurvey.nextSurveyComponent(data) as Component);
  } else {
    Sentry.captureException(new Error(`Can't find cancellation survey: ${currentSurveyComponent.value}`));
    return markRaw(Cancelled);
  }
};

const goToPreviousComponent = () => {
  currentSurveyComponent.value = surveyCrumbs.value[surveyCrumbs.value.length - 1];
  surveyCrumbs.value = surveyCrumbs.value.slice(0, -1);
};

// Reset the survey flow when the dialog is reopened.
watch(() => props.modelValue, (newValue) => {
  if (newValue) {
    surveyCrumbs.value = [];
    currentSurveyComponent.value = markRaw(MissingOutOnFeaturesSummary);
    shouldOfferFreeMonth.value = false;
  }
});
</script>

<template>
  <article class="modal" :class="{ 'modal-open': modelValue }" @click="handleClose">
    <div class="layer-1 max-w-screen-lg rounded-2xl overflow-hidden" @click.stop>
      <component
        :is="currentSurveyComponent"
        class="min-h-[500px] lg:min-w-[1000px]"
        @cancel="emit('cancel')"
        @previous="goToPreviousComponent"
        @next="handleClick" />
    </div>
  </article>
</template>

<style scoped lang="scss"></style>
