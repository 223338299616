<script setup lang="ts">
withDefaults(defineProps<{ arrow: boolean; modelValue: boolean }>(), { arrow: false })
const emit = defineEmits<{
  (event: 'update:modelValue', value: boolean): void
}>()

function setOpen(value: boolean) {
  emit('update:modelValue', value)
}
</script>

<template>
  <div class="relative" v-click-outside="() => setOpen(false)">
    <slot name="button" type="button" @click="setOpen(!modelValue)" class="cursor-pointer" />

    <div
      class="relative w-full drop-shadow transition-all"
      :class="{ 'invisible -translate-y-2 opacity-0': !modelValue }"
    >
      <div
        v-if="arrow"
        class="absolute left-1/2 h-6 w-6 -translate-x-1/2 rotate-45 transform select-none rounded bg-white"
      />
      <slot
        name="menu"
        class="absolute -right-2 top-2 max-h-[400px] flex-col gap-2 overflow-y-auto rounded-xl bg-white p-2"
      />
    </div>
  </div>
</template>
