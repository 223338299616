<script setup lang="ts">
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import { IconDots } from '@tabler/icons-vue'
import { computed, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import IconSaxEdit from '@/components/Icons/iconsax/IconSaxEdit.vue'
import type { SavedSocialTextField } from '@/apis/streamladder-api/model'

const props = defineProps<{
  item: SavedSocialTextField
  itemsContainerRef: HTMLElement | null
  activeTemplate: SavedSocialTextField | null
}>();

const emit = defineEmits<{
  (e: 'applyTemplate', item: SavedSocialTextField): void
  (e: 'editTemplate', item: SavedSocialTextField): void
  (e: 'removeTemplate', item: SavedSocialTextField): void
}>();

const menuRef = ref<HTMLElement | null>(null);
const menuOpen = ref(false);

onClickOutside(menuRef, () => {
  menuOpen.value = false;
});

const onEditTemplate = (item: SavedSocialTextField) => {
  menuOpen.value = false;
  emit('editTemplate', item);
};

const onRemoveTemplate = (item: SavedSocialTextField) => {
  menuOpen.value = false;
  emit('removeTemplate', item);
};

const marginTopPositionOfItem = computed(() => {
  if (!props.itemsContainerRef) {
    return '0px';
  } else {
    return -30 - props.itemsContainerRef.scrollTop + 'px';
  }
});
</script>

<template>
  <div
    class="flex flex-row justify-between rounded-md bg-transparent hover:bg-blue-50 dark:hover:bg-zinc-600 transition-all p-1 w-full group"
    :class="{ '!bg-blue-50 dark:!bg-zinc-600': props.activeTemplate?.id === item.id }"
  >
    <div
      :title="item.name!"
      :style="{ backgroundColor: item.color! }"
      class="w-fit font-light text-sm text-white py-1 px-2 rounded-md hover:opacity-75 cursor-pointer transition-all"
      @click="() => emit('applyTemplate', item)"
    >
      {{ item.name }}
    </div>
    <div
      @click="menuOpen = true"
      class="opacity-0 group-hover:opacity-100 transition-all cursor-pointer hover:text-blue-500"
      :class="{ '!opacity-100 !text-blue-500': menuOpen }"
    >
      <IconDots class="w-5 h-5 rotate-90" />
    </div>
  </div>
  <Transition name="fade">
    <div
      ref="menuRef"
      v-if="menuOpen"
      class="flex flex-col absolute bg-surface-panel-50 rounded-lg shadow-xl right-0 md:right-auto left-auto md:left-full -translate-x-6 w-fit font-light border-t-2 border-t-bg-zinc-300 dark:border-0"
      :style="{ marginTop: marginTopPositionOfItem }"
    >
      <p
        @click="onEditTemplate(item)"
        class="cursor-pointer px-2 py-2.5 bg-surface-panel-50 dark:bg-zinc-500 hover:bg-blue-50 dark:hover:bg-zinc-600 rounded-t-lg whitespace-nowrap text-sm transition-all">
        <IconSaxEdit class="w-4 h-4 mr-1 -mt-0.5" />
        Edit Template
      </p>
      <p
        @click="onRemoveTemplate(item)"
        class="cursor-pointer px-2 py-2.5 bg-surface-panel-50 dark:bg-zinc-500 hover:bg-blue-50 dark:hover:bg-zinc-600 rounded-b-lg whitespace-nowrap text-sm transition-all text-red-500">
        <IconSaxTrash class="w-4 h-4 mr-1 -mt-0.5" />
        Remove Template
      </p>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(-40px)
}
</style>