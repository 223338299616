<script lang="ts" setup>
import settings from '@/data/settings'
import IconSaxArrowRight2 from '@/components/Icons/iconsax/IconSaxArrowRight2.vue'
import DiscordLogo from '@/components/Icons/DiscordLogo.vue'
import UserGuides from '@/components/Support/UserGuides.vue'
import Accordion from '@/components-v2/modules/Accordion/Accordion.vue'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import IconSaxArrowRight from '@/components/Icons/iconsax/IconSaxArrowRight.vue'

const breadcrumbs = [
  { breadcrumb: 'Home', name: 'Home' },
  { breadcrumb: 'Support', name: dashboardRouteNames.support },
]
</script>

<template>
  <div class="flex justify-center gap-8 p-4 lg:gap-16 lg:p-12">
    <div class="flex max-w-full shrink-0 flex-col items-start justify-center gap-6 md:max-w-[45ch]">
      <div class="flex flex-col items-start justify-start">
        <ol class="breadcrumbs flex items-center gap-2">
          <li
            v-for="({ name, breadcrumb }, i) in breadcrumbs"
            :key="name"
            class="breadcrumb flex items-center gap-2 font-light lowercase"
          >
            <router-link
              :to="{ name }"
              active-class="text-company-primary pointer-events-none cursor-default text-inherit no-underline"
              class="link-hover link text-indigo-500 underline hover:no-underline"
            >
              {{ breadcrumb }}
            </router-link>
            <IconSaxArrowRight2 v-if="i < breadcrumbs.length - 1" class="h-4 w-4 shrink-0 text-indigo-500" />
          </li>
        </ol>
        <h1 class="mb-4 mt-0 p-0 text-5xl font-bold leading-snug">We’re here to help.</h1>
        <p class="font-light">
          Our dedicated support team is here Monday to Friday, ready to assist with caption generation troubles, billing
          queries, and more.
        </p>
      </div>
      <div class="flex flex-col items-stretch justify-center gap-3">
        <router-link
          :to="{ name: 'VideoSupport' }"
          class="hover:shadow-gradient bg-gradient btn-lg btn relative flex-nowrap gap-2 self-center border-transparent px-12 text-lg font-bold text-white before:transition-all hover:border-transparent xl:h-10 xl:min-h-[2.5rem] xl:text-sm 2xl:h-12 2xl:min-h-[3rem] 2xl:text-lg"
        >
          Resolve common video issues

          <IconSaxArrowRight class="transition group-hover:translate-x-2" />
        </router-link>
        <a
          :href="settings.discordInviteUrl"
          class="btn-outline btn-primary btn-lg btn flex-nowrap gap-2 !text-indigo-500 hover:!border-indigo-500 hover:!bg-indigo-500 hover:!text-white dark:!text-white"
          target="_blank"
        >
          Contact us on discord
          <DiscordLogo class="h-6 w-6 fill-current" />
        </a>
      </div>
    </div>
    <div class="hidden md:block">
      <img
        alt="Customer support vector"
        class="h-full w-full shrink-[1] object-contain"
        height="394"
        src="/images/support-flavor.svg"
        type="image/svg+xml"
        width="460"
      />
    </div>
  </div>
  <UserGuides />
  <div class="p-4 lg:gap-16 lg:p-12">
    <h4 class="text-3xl">Common questions answered</h4>
    <ol class="mt-4 flex flex-col">
      <li>
        <Accordion class="layer-2 border-0" title="Does StreamLadder reduce the quality of my clips?">
          <p>
            If you feel like your clips don’t look as good as you expected them to be, check out our
            <a
              class="link font-bold"
              href="https://streamladder.com/user-guide/how-to-make-sure-you-get-the-highest-video-quality"
              >user guide</a
            >
            to find out what the reason for this might be.
          </p>
        </Accordion>
      </li>
      <li>
        <Accordion class="layer-2 border-0" title="Why aren’t my videos getting more views?">
          <p>
            TikTok and YouTube are pretty secretive about their algorithms and how to manipulate them to your advantage.
            Luckily, we’ve done some research and are happy to share our findings. Try
            <a
              class="link font-bold"
              href="https://streamladder.com/user-guide/how-to-make-the-tiktok-and-youtube-shorts-algorithm-work-for-you"
              >these steps</a
            >
            to get more views.
          </p>
        </Accordion>
      </li>
      <li>
        <Accordion class="layer-2 border-0" title="Why aren’t my videos getting published to TikTok?">
          <p>
            Using our Content Publisher, but your videos aren’t being published to TikTok? Check out what might be the
            cause
            <a class="link font-bold" href="https://streamladder.com/user-guide/publishing-to-tiktok-with-streamladder"
              >here</a
            >
          </p>
        </Accordion>
      </li>
      <li>
        <Accordion class="layer-2 border-0" title="Why won't my videos render?">
          <p>
            We support a wide variety of input videos, but sometimes unexpected errors do occur. Try our
            <a class="link font-bold" href="https://app.streamladder.com/video-support">video support tool</a> why your
            video might fail to render.
          </p>
        </Accordion>
      </li>
      <li>
        <Accordion class="layer-2 border-0" title="Is there an app that I can download?">
          <p>
            No, we currently see no added value in creating an app, but the StreamLadder website works very well on a
            mobile device.
          </p>
        </Accordion>
      </li>
      <li>
        <Accordion class="layer-2 border-0" title="How can I get access to all of the features?">
          <p>
            Some features are only available with a Silver or Gold subscription. If you do have a paid subscription and
            still don’t have access to certain features, please contact us on
            <a :href="settings.discordInviteUrl" class="link font-bold" target="_blank">Discord</a>.
          </p>
        </Accordion>
      </li>
    </ol>
  </div>
</template>

<style lang="scss" scoped></style>
