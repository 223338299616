<template>
  <Tile @mouseover="setHover(true)" @mouseleave="setHover(false)" @click="add">
    <div
      class="absolute right-2 top-2 flex"
      :class="{ 'lg:hidden': !hovering }"
      @click.stop
      v-click-outside="() => this.hideOptions()"
    >
      <div
        class="flex rounded bg-company-primary bg-opacity-75 p-2"
        ref="button"
        style="z-index: 199999"
        @click="showOptions()"
      >
        <img src="/images/svgs/three-dots.svg" width="13" height="3" />
      </div>

      <div
        class="absolute left-2 top-0 flex cursor-pointer flex-col rounded-lg bg-white p-2 shadow"
        ref="dropdown"
        style="z-index: 199999"
        :class="{ hidden: !showActions }"
      >
        <div
          class="flex items-center space-x-2 whitespace-nowrap px-4 py-2 hover:bg-company-primary hover:bg-opacity-10"
          @click="add()"
        >
          <img src="/images/icons/add-new.svg" alt="add new" loading="lazy" width="14" height="14" />
          <span>Add to video</span>
        </div>
        <div
          class="flex items-center space-x-2 whitespace-nowrap px-4 py-2 text-company-accent hover:bg-company-primary hover:bg-opacity-10"
          @click="destroy()"
        >
          <trashcan-icon class="h-4 w-4 fill-current" />
          <span class="text-company-accent">Delete from library</span>
        </div>
      </div>
    </div>

    <div class="aspect-square max-w-[80%]" v-if="!loading">
      <img
        class="h-full w-full cursor-pointer object-contain"
        :src="sticker.fileUrl"
        alt="custom sticker"
        loading="lazy"
      />
    </div>
    <div class="h-full animate-pulse bg-gray-300" v-else>&nbsp;</div>
  </Tile>
</template>
<script>
import EventBus from '../../../eventBus'
import customStickerEvents from '../../../events/customStickerEvents'
import customStickerService from '../../../services/customStickerService'
import { createPopper } from '@popperjs/core'
import TrashcanIcon from '../../Icons/TrashcanIcon.vue'
import Tile from '@/components/Tile.vue'

export default {
  components: { Tile, TrashcanIcon },
  props: {
    sticker: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
      hovering: false,
      showActions: false,
      pop: null,
    }
  },
  methods: {
    add() {
      EventBus.$emit(customStickerEvents.CUSTOM_STICKER_ADDED, this.sticker.fileUrl)
      this.showActions = false
    },
    showOptions() {
      this.showActions = true
      this.pop.update()
    },
    hideOptions() {
      if (this.showActions) {
        this.showActions = false
      }
    },
    async destroy() {
      this.showActions = false
      this.hovering = false
      this.loading = true
      await customStickerService.destroy(this.sticker.id)
      EventBus.$emit(customStickerEvents.STICKER_DELETED, this.sticker.id)
    },
    setHover(value) {
      if (this.loading) {
        return
      }

      this.hovering = value

      if (!value) {
        this.showActions = false
      }
    },
  },
  mounted() {
    this.pop = createPopper(this.$refs.button, this.$refs.dropdown, {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [100, 5],
          },
        },
      ],
    })
  },
}
</script>
