<script setup lang="ts">
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import { ref, computed, type Ref, watch } from 'vue'
import { useVModel } from '@vueuse/core'
import type { SavedSocialTextField, SavedSocialTextFieldDto } from '@/apis/streamladder-api/model'
import { range } from 'lodash-es'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from '@/components/ui/dialog'

const props = defineProps<{
  isOpen: boolean
  maxTextLength: number
  templateItem: SavedSocialTextField | null,
  allTemplateItems: SavedSocialTextField[],
  tag: string
}>();

const emit = defineEmits<{
  (e: 'update:isOpen', isOpen: boolean): void
  (e: 'close'): void
  (e: 'save', payload: SavedSocialTextField): void
  (e: 'saveAndApply', payload: SavedSocialTextField): void
}>();

const isOpen = useVModel(props, 'isOpen', emit);
const colors = range(0, 20).map(i => `oklch(70% 0.15 ${360 * (i / 20)})`)

const selectedColor = ref(colors[0]);
const text = ref('');
const title = ref('');

const editedTemplate: Ref<SavedSocialTextFieldDto> = computed(() => ({
  id: props.templateItem?.id,
  name: title.value,
  content: text.value,
  color: selectedColor.value,
  tag: props.tag
}));

watch(() => props.templateItem, () => {
  if (props.templateItem) {
    selectedColor.value = props.templateItem.color!;
    text.value = props.templateItem.content!;
    title.value = props.templateItem.name!;
  }
}, { deep: true });

const titleIsAlreadyUsed = computed(() => {
  // If user is editing a template, we don't do a check if the title is already used.
  return !props.templateItem?.id && props.allTemplateItems.some(item => item.name === title.value);
});

const showApplyButton = computed(() => {
  if (props.templateItem?.id) {
    return props.templateItem.content !== text.value;
  } else {
    return true;
  }
});
</script>

<template>
  <Dialog v-model:open="isOpen">
    <DialogContent class="p-0 layer-2 flex flex-col text-black dark:text-white">
      <DialogHeader class="flex justify-between relative border-b dark:border-zinc-500">
        <div class="flex flex-col gap-2 px-8 py-6 w-full">
          <template v-if="templateItem?.id">
            <DialogTitle class="text-2xl text-center">Edit your template</DialogTitle>
            <DialogDescription class="text-opacity-50 font-light text-sm text-center">Modify your saved titles to keep your templates current and relevant</DialogDescription>
          </template>
          <template v-else>
            <DialogTitle class="text-2xl text-center">Create a new template</DialogTitle>
            <DialogDescription class="text-opacity-50 font-light text-sm text-center">⚡ Become the ultimate power-user ⚡</DialogDescription>
          </template>
        </div>
      </DialogHeader>
      <div class="p-8 pt-2">
        <div class="flex flex-col gap-4">
          <div>
            <p class="font-semibold">Select your color</p>
            <div class="flex gap-[6px] my-2">
              <input
                name="color"
                v-for="color in colors"
                :key="color"
                type="radio"
                class="w-6 h-6 rounded-full cursor-pointer appearance-none hover:scale-125 transition-all checked:cursor-default checked:!scale-125 active:scale-105"
                :style="{ 'accentColor': color, 'backgroundColor': color }"
                :checked="selectedColor === color"
                @change="selectedColor = color"
              />
            </div>
          </div>

          <div class="flex flex-col gap-2">
            <p class="font-semibold">Template name</p>
            <Input
              v-model="title"
              :maxlength="25"
              placeholder="Half Life 3"
              class="font-light transition-all"
              :class="{ 'border-red-500': titleIsAlreadyUsed }"
            />
            <span v-if="titleIsAlreadyUsed" class="text-red-500 font-light text-xs">
              Title is already in use, please choose another one
            </span>
          </div>

          <div class="flex flex-col gap-2">
            <p class="font-semibold">Template text</p>
            <div class="flex flex-col w-full">
            <Textarea
              v-model="text"
              :maxlength="2000"
              placeholder="You won’t believe what happened here #beastmode #gaming #streamladder"
              class="font-light min-h-[100px] max-h-[300px]"
            />
              <span v-if="maxTextLength" class="absolute bottom-28 right-12 text-brand-state-text-placeholder text-sm font-light pointer-events-none">
                {{ text?.length || 0 }} / {{ maxTextLength }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex gap-2 justify-between mt-8">
          <Button
            type="button"
            variant="outline"
            @click="emit('close')"
          >
            Go back
          </Button>

          <span class="flex flex-row gap-2">
            <Button
              type="button"
              class="transition-all"
              variant="primary"
              :disabled="title.length < 3 || text.length < 3 || titleIsAlreadyUsed"
              @click="emit('save', editedTemplate);"
            >
              Save
            </Button>
            <Button
              v-if="showApplyButton"
              type="button"
              variant="outline"
              :disabled="title.length < 3 || text.length < 3 || titleIsAlreadyUsed"
              @click="emit('saveAndApply', editedTemplate);"
            >
              Save & Apply
            </Button>
          </span>
        </div>
      </div>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.25s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
