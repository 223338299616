<template>
  <svg width="40" height="29" viewBox="0 0 40 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="1.62646"
      y="1.92395"
      width="36.9071"
      height="25.452"
      rx="4"
      stroke="#47089E"
      stroke-width="2"
      stroke-dasharray="4 4"
    />
    <path
      d="M16.6792 18.0536V11.2475C16.6792 10.4733 17.5211 9.99346 18.1877 10.3872L23.9469 13.7886C24.6022 14.1756 24.6022 15.1236 23.9469 15.5107L18.1877 18.9122C17.5211 19.3059 16.6792 18.8278 16.6792 18.0536Z"
      stroke="#47089E"
      stroke-width="2"
    />
  </svg>
</template>
