<template>
  <form class="flex-gol form-control flex gap-2" @submit.prevent="searchClips">
    <div
      class="input-group rounded-full mt-4 outline outline-1 outline-offset-2 outline-transparent focus-within:outline-company-primary-50 dark:bg-white"
    >
      <input
        id="streamerName"
        v-model="searchInput"
        v-focus
        autocomplete="off"
        class="input-bordered input flex-grow text-zinc-900 !outline-none placeholder:text-gray-400"
        name="streamerName"
        placeholder="Streamer name"
        type="text"
      />
      <button
        :class="{ 'btn-disabled dark:bg-zinc-500 dark:text-gray-400': !searchInput }"
        :disabled="!searchInput"
        class="btn-primary btn block shadow-none dark:text-neutral-50"
      >
        Search
        <span class="hidden !bg-transparent p-0 md:inline">streamer clips</span>
      </button>
    </div>
    <div v-if="searchHistory && searchHistory.length > 0" class="flex flex-row justify-center pt-0.5 md:pt-2 text-sm md:text-base">
      <div class="hidden md:block">Recent search:&nbsp;</div>
      <div class="flex flex-row-reverse flex-wrap gap-1">
        <button
          v-for="history in searchHistory"
          :key="history"
          class="link-primary link"
          @click.prevent="searchByName(history)"
        >
          {{ history }}
        </button>
      </div>
    </div>
  </form>

  <div class="my-6 flex flex-col md:flex-row items-center justify-between">
    <header>
      <h5 class="text-xl font-thin">
        <template v-if="isLoading">
          Finding clips for <span class="font-bold">"{{ searchName }}"</span>
        </template>
        <template v-else>
          {{ clips.length }} results for <span class="font-bold">"{{ searchName }}"</span>
        </template>
      </h5>
    </header>
    <div class="flex items-center justify-center w-full md:w-auto">
      <SelectDropdown v-model="twitchClipFilterKey" class-name="self-start" :options="twitchClipsOptions" fixed />
    </div>
  </div>

  <template v-if="enabled">
    <section class="md:-m-4 md:-mr-6 mt-0 min-h-0 overflow-y-auto md:p-4 md:pr-6">
      <template v-if="hasClips || isLoading">
        <TransitionGroup
          :key="twitchClipFilterKey"
          class="grid grid-cols-2 gap-2 md:gap-6 lg:grid-cols-3"
          name="grid"
          tag="ol"
          appear
        >
          <li v-for="i in range(clipsToShow)" :key="i">
            <ClipPreviewCard :is-loading="isLoading" :clip="clips[i]" @preview="preview" @select="select" />
          </li>
        </TransitionGroup>
      </template>
    </section>

    <TwitchClipPreviewDialog @confirm="select" :clips="clips" v-model="previewDialogId" />
  </template>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { useTwitchClipsFilter } from '@/Hooks/useTwitchClipsFilter'
import SelectDropdown from '@/components-v2/data-input/SelectDropdown.vue'
import { type Clip, useTwitchClips } from '@/Hooks/useTwitchClips'
import TwitchClipPreviewDialog from '@/components-v2/modules/HomePage/TwitchClipPreviewDialog.vue'
import { range } from 'lodash-es'
import ClipPreviewCard from '@/components/Clips/ClipPreviewCard.vue'
import { useIsMobile } from '@/Hooks/useIsMobile'

const emit = defineEmits<{
  (name: 'child-dialog-toggled', value: boolean): void
  (name: 'clip-selected', clip: Clip): void
}>()
const userInfo = useUserInfoStore()

const isMobile = useIsMobile();

const vFocus = {
  mounted(input: HTMLElement) {
    if (!isMobile.value) {
      input.focus()
    }
  },
};

const searchHistory = ref<string[]>([])
const searchInput = ref('')
const searchName = ref(userInfo.userName)

const previewDialogId = ref<string | null>(null)
watch(previewDialogId, () => {
  emit('child-dialog-toggled', previewDialogId.value !== null)
})

function preview(clip: Clip) {
  previewDialogId.value = clip.id
}

function select(clip: Clip) {
  emit('clip-selected', clip)
}

onMounted(() => {
  searchHistory.value = JSON.parse(localStorage.getItem('searchHistory') || '[]').slice(-3)
})

const enabled = computed(() => !!searchName.value)

const { clips, hasClips, isLoading } = useTwitchClips({
  enabled,
  userName: searchName,
})

const clipsToShow = computed(() => (isLoading.value ? 12 : clips.value.length))

const { twitchClipFilterKey, twitchClipsOptions } = useTwitchClipsFilter()

const searchClips = () => {
  searchName.value = searchInput.value
  searchHistory.value = [...searchHistory.value.filter((s) => s !== searchInput.value), searchInput.value].slice(-3)
  localStorage.setItem('searchHistory', JSON.stringify(searchHistory.value))
}

const searchByName = (name: string) => {
  searchInput.value = name
  searchName.value = name
}
</script>

<style lang="scss">
.streamer-name-wrapper {
  background-color: #f9f6fe;
}

.clip-wrapper {
  --clip-gutter: 0.5em;

  padding-left: var(--clip-gutter);
  padding-right: var(--clip-gutter);
  margin-bottom: calc(var(--clip-gutter) * 2);

  .clip {
    border-radius: 5px;
    border: 2px solid #f9f6fe;
    cursor: pointer;
  }

  .clip-metadata {
    padding: 1em;

    .clip-date {
      color: #33cff2;
      font-size: 14px;
      font-weight: 600;
    }

    .clip-title {
      color: #4d287f;
      font-size: 18px;
      font-weight: 700;
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.search-form {
  padding: 3em;

  @media (max-width: 767px) {
    padding: 1em;
  }
}

.img-container {
  position: relative;
  cursor: pointer;
}

.clip-thumbnail {
  border-radius: 5px;
}

.thumbnail-overlay {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0.5rem;
  display: flex;
  width: 100%;
  height: 100%;

  .viewcount {
    bottom: 0.5em;
    position: absolute;
  }

  .createdAt {
    bottom: 0.5em;
    right: 0.5em;
    position: absolute;
  }
}

.clip-select-text {
  &:hover {
    cursor: pointer;
    color: var(--bs-primary);
  }
}

.clip-results {
  padding: 3em;
}

@media (max-width: 767px) {
  .clip-wrapper {
    --clip-gutter: 0.25em;

    .clip-metadata {
      padding: 0.5em;

      .clip-date {
        font-size: 0.7em;
      }

      .clip-title {
        font-size: 0.8em;
      }
    }
  }

  .clip-results {
    padding: 1em;
  }
}
</style>
