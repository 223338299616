<template>
  <div
    ref="container"
    data-retain-focus
    :class="[
      fragment.key,
      fragment.feedOptions.type,
      {
        active: editorFocusStore.focus?.key === fragment.key,
      },
    ]"
    class="feed-container preview-layer overflow-visible"
  />
</template>

<script lang="ts">
import Moveable from 'moveable'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { useEditorFocusStore, FocusTypes } from '@/store/editor/editorFocus'
import type { EditorFragment } from '@/modules/SLVideoplayer/types'
import { useCropsStore } from '@/store/entity-system/useCropsStore'

export default defineComponent({
  components: {},
  props: {
    fragment: {
      type: Object as PropType<EditorFragment>,
      required: true,
    },
    containerWidth: {
      type: Number,
      required: true,
    },
    containerHeight: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      translateX: 0,
      translateY: 0,
      width: 0,
      height: 0,
      moveables: {} as Record<string, Moveable>,
    }
  },
  setup() {
    const editorFocusStore = useEditorFocusStore()
    return { editorFocusStore }
  },
  async mounted() {
    await this.$nextTick()

    await this.$nextTick()

    this.setInitStyling()
    await this.$nextTick()
    this.initMoveable()

    this.moveableInstance.on('click', this.focus)
    this.moveableInstance.on('drag', this.handleDrag)
    this.moveableInstance.on('dragEnd', this.saveFeed)
    this.moveableInstance.on('resize', this.handleResize)
    this.moveableInstance.on('resizeEnd', this.saveFeed)
  },
  methods: {
    focus() {
      if (
        this.editorFocusStore.focus?.type === FocusTypes.FEED &&
        this.editorFocusStore.focus?.key === this.fragment.key
      )
        return
      this.editorFocusStore.setFocus(FocusTypes.FEED, this.fragment.key)
    },
    setInitStyling() {
      if (!this.fragment?.feedData) throw new Error('Feed did not have a proper feedData object')
      this.translateX = this.fragment?.feedData.x * this.containerWidth
      this.translateY = this.fragment?.feedData.y * this.containerHeight
      this.width = this.fragment?.feedData.w * this.containerWidth
      this.height = this.fragment?.feedData.h * this.containerHeight
      this.$refs.container.style.transform = `matrix(1, 0, 0, 1, 0, 0) translate(${this.translateX}px, ${this.translateY}px)`

      this.resizeVideo()
      this.saveFeed()
    },
    initMoveable() {
      if (!this.fragment.key) return
      this.moveables[this.fragment.key] = new Moveable(this.$refs.container.parentElement, {
        className: 'moveable-' + this.fragment?.feedOptions.type,
        target: this.$refs.container,
        // If the container is null, the position is fixed. (default: parentElement(document.body))
        draggable: this.fragment?.feedOptions.draggable,
        resizable: this.fragment?.feedOptions.resizable,
        scalable: false,
        rotatable: false,
        warpable: false,
        // Enabling pinchable lets you use events that
        // can be used in draggable, resizable, scalable, and rotateable.
        pinchable: false, // ["resizable", "scalable", "rotatable"]
        origin: false,
        keepRatio: true,
        renderDirections: ['se'],
        // Resize, Scale Events at edges.
        edge: false,
        throttleDrag: 0,
        throttleResize: 0,
        throttleScale: 0,
        throttleRotate: 0,
        hideDefaultLines: true,
        snappable: true,
        snapThreshold: 5,
        // snapElement: true,
        verticalGuidelines: [],
        horizontalGuidelines: [],
        snapDirections: { middle: true, center: true },
        bounds: {
          top: 0,
          left: 0,
          bottom: this.containerHeight,
          right: this.containerWidth,
        },
      })

      if (this.fragment.feedOptions.snapVertical) {
        this.moveableInstance.verticalGuidelines = [this.containerWidth / 2]
      }

      if (this.fragment.feedOptions.snapHorizontal) {
        this.moveableInstance.horizontalGuidelines = [Math.round(this.containerHeight / 2)]
      }

      this.setVisibility()
    },
    handleDrag({ target, transform, translate }: any) {
      this.focus()
      target.style.transform = transform
      this.translateX = translate[0]
      this.translateY = translate[1]
      this.saveFeed()
    },
    handleResize({ target, width, height, dist, delta, clientX, clientY }: any) {
      this.focus()
      delta[0] && (target.style.width = `${width}px`)
      delta[1] && (target.style.height = `${height}px`)

      this.width = width
      this.height = height
      this.resizeVideo()
      this.saveFeed()
    },
    resizeVideo() {
      if (!this.$refs.container) return
      this.$refs.container.style.height = this.height.toString() + 'px'
      this.$refs.container.style.width = this.width.toString() + 'px'
    },
    saveFeed() {
      if (!this.width && !this.height) return
      
      const editorFeedDataStore = useEditorFeedDataStore()
      editorFeedDataStore.updateFeed(this.fragment?.key, {
        x: this.translateX / this.containerWidth,
        y: this.translateY / this.containerHeight,
        w: this.width / this.containerWidth,
        h: this.height / this.containerHeight,
      })

      const cropsStore = useCropsStore()
      if (cropsStore.selectById(this.fragment.key)) {
        cropsStore.updateCropFeedDataById(this.fragment.key, {
          x: this.translateX / this.containerWidth,
          y: this.translateY / this.containerHeight,
          width: this.width / this.containerWidth,
          height: this.height / this.containerHeight,
        })
      }
    },
    setVisibility() {
      const element = this.moveableInstance?.getManager()?.controlBox?.getElement()
      if (element) {
        if (this.editorFocusStore.focus?.key !== this.fragment.key) {
          element.classList.add('opacity-0', 'pointer-events-none')
        } else {
          element.classList.remove('opacity-0', 'pointer-events-none')
        }
      }
    },
    updateRect() {

      this.setInitStyling()

      if (this.moveableInstance.bounds) {
        this.moveableInstance.bounds.right = this.containerWidth
        this.moveableInstance.bounds.bottom = this.containerHeight
      }

      if (this.fragment?.feedOptions.snapVertical) {
        this.moveableInstance.verticalGuidelines = [this.containerWidth / 2]
      }
      if (this.fragment?.feedOptions.snapHorizontal) {
        this.moveableInstance.horizontalGuidelines = [Math.round(this.containerHeight / 2)]
      }

      this.resizeVideo()

      this.moveableInstance.updateRect()
    },
  },
  beforeUnmount() {
    // TODO manager seems to be shared. Destroying it here will destroy it for all other components? Need to look into this.
    this.moveables[this.fragment.key]?.destroy()
    delete this.moveables[this.fragment.key]
  },
  computed: {
    FocusTypes() {
      return FocusTypes
    },
    isFocused() {
      return this.editorFocusStore.focus?.key === this.fragment.key
    },
    moveableInstance() {
      return this.moveables[this.fragment.key]
    },
  },
  watch: {
    isFocused() {
      this.setVisibility()
    },
    containerHeight() {
      this.updateRect()
    },
    containerWidth() {
      this.updateRect()
    },
    fragment: {
      handler() {

        if (!this.fragment?.feedData || !this.$refs.container || !this.moveableInstance) {
          return
        }

        this.translateX = this.fragment.feedData.x * this.containerWidth
        this.translateY = this.fragment.feedData.y * this.containerHeight
        this.width = this.fragment.feedData.w * this.containerWidth
        this.height = this.fragment.feedData.h * this.containerHeight
        this.$refs.container.style.transform = `matrix(1, 0, 0, 1, 0, 0) translate(${this.translateX}px, ${this.translateY}px)`

        this.$nextTick(() => {
          this.resizeVideo()
          this.moveableInstance.updateRect()
        })
      },
      deep: true
    }
  },
})
</script>

<style scoped lang="scss">
.feed-container {
  overflow: hidden;
  position: absolute;
  align-self: flex-end;
  z-index: 20;

  &:active {
    cursor: move;
  }

  &.circle {
    border-radius: 50%;
  }

  &.facecam {
    z-index: 21;
  }

  &.gameui {
    z-index: 22;
  }
}
</style>
<style lang="scss">
.moveable-control-box.moveable-facecam {
  z-index: 22;

  .moveable-line {
    height: 2px;
  }
}

.moveable-facecam {
  .moveable-control.moveable-direction.moveable-se {
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    height: 25px;
    width: 25px;
    margin-left: -13px;
    margin-top: -13px;

    &:before {
      content: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='7' height='11' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 7.53 7.53'%3E%3Cdefs%3E%3Cstyle%3E .cls-1 %7B fill: none; stroke: %2347089e; stroke-linecap: round; stroke-width: 2px; %7D %3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M1,6.53H6.53V1'/%3E%3C/svg%3E");
      margin-left: -2px;
    }
  }
}

.moveable-control-box.moveable-gamefeed {
  z-index: 21;
}

.moveable-control-box.moveable-gameui {
  z-index: 23;

  .moveable-line {
    height: 2px;
  }
}
</style>
