<script lang="ts" setup>
import { useUserSubscriptionStore, type UserSubscription } from '@/store/user/userSubscription'
import SubscriptionPlanPartial from '@/areas/dashboard/pages/account/partial/SubscriptionPlanPartial.vue'
import HighlightPartial from '@/areas/dashboard/pages/index/partial/HighlightPartial.vue'
import RecentPaymentsList from '@/areas/dashboard/components/RecentPayments/RecentPaymentsList.vue'
import PaymentMethod from '@/components/Account/Payment/PaymentMethod.vue'
import { upgradeDialog } from '@/helpers/upgradeDialog'
import { computed } from 'vue'
import { useHead } from '@unhead/vue'

const subscriptionStore = useUserSubscriptionStore()
subscriptionStore.fetchSubscriptionData()

const subscription = computed(() => subscriptionStore.subscription)
const plan = computed(() => subscriptionStore.plan)
const status = computed(() => subscription.value?.status?.toLowerCase())

const dateTimeFormatter = Intl.DateTimeFormat('en-US', { dateStyle: 'long' })
const nextPaymentDate = computed(() => {
  if (!subscription.value?.nextPayment?.date) return undefined
  return dateTimeFormatter.format(new Date(subscription.value.nextPayment.date))
})

function openUpdatePaymentInfo(subscription: UserSubscription) {
  window.open(subscription.updatePaymentDetailsUrl, '_blank')
}

useHead({
  title: 'Manage plan',
  meta: [
    {
      name: 'description',
      content: 'Manage your subscription and billing information.',
    },
  ],
})
</script>

<template>
  <main class="flex flex-col gap-6">
    <header class="flex flex-col gap-4">
      <h1 class="text-3xl lg:text-5xl">Plans & Billing</h1>
    </header>

    <section class="flex flex-col gap-4" v-if="subscriptionStore.isLoading">
      <h2 class="leading-[1]">Your plan</h2>
      <div class="layer-2 skeleton min-h-64 flex flex-col gap-4 overflow-hidden rounded-xl px-8 py-6" />
    </section>
    <template v-else>
      <SubscriptionPlanPartial />

      <!-- Upgrade Banners -->
      <HighlightPartial
        v-if="plan === 'free'"
        call-to-action="Upgrade to Silver"
        gradient="bg-gradient-to-r from-dodger-blue to-company-primary-300"
        subtitle="Upgrade to Silver for advanced features to take your stream to the next level"
        title="Upgrade now"
        @click="upgradeDialog.open({ reason: 'Dashboard Billing Page Silver Upgrade Banner Clicked' })"
      />

      <HighlightPartial
        v-else-if="plan === 'silver'"
        call-to-action="Upgrade to Gold"
        gradient="bg-gradient-to-r from-dodger-blue to-company-primary-300"
        subtitle="Upgrade to Gold and start using Automated captions with 5 times faster rendering!"
        title="Upgrade now"
        @click="upgradeDialog.open({ reason: 'Dashboard Billing Page Gold Upgrade Banner Clicked' })"
      />

      <section
        v-if="subscription && plan !== 'free' && subscription.type === 'paddle'"
        class="mt-8 flex w-full flex-col gap-2"
      >
        <h2 class="flex items-center justify-between gap-2 leading-[1]">Payments plan</h2>
        <p v-if="nextPaymentDate" class="font-thin opacity-50">Your next payment is due on {{ nextPaymentDate }}.</p>

        <div class="layer-2 flex flex-col gap-4 rounded-xl px-8 py-6">
          <RecentPaymentsList :subscription-id="subscription.id" />

          <div class="flex items-center justify-between">
            <PaymentMethod v-if="subscription.billingInfo" :billing-info="subscription.billingInfo" />
            <button
              v-if="status !== 'cancelled'"
              class="ml-auto flex items-center justify-center gap-2 rounded-xl border border-indigo-500 bg-indigo-500 px-6 py-2 text-white transition-all hover:border-indigo-600 hover:bg-indigo-600 active:scale-90"
              @click="openUpdatePaymentInfo(subscription)"
            >
              Update payment information
            </button>
          </div>
        </div>
      </section>
    </template>
  </main>
</template>

<style lang="scss" scoped></style>
