<script lang="ts" setup>
import { onMounted, computed, inject } from 'vue'
import type { Ref } from 'vue'
import MovableElement from '@/modules/SLMovable/MovableElement.vue'
import type { CropsStore } from '@/store/entity-system/useCropsStore'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { useEditorFocusStore } from '@/store/editor/editorFocus'

const props = defineProps<{
  id: string
  enableSnapping: boolean
}>()

const emit = defineEmits(['focus'])

const cropsStore = inject<CropsStore>('cropsStore')!
const crop = cropsStore.selectById(props.id)
const editorVideoStore = useEditorVideoStore()

onMounted(() => {
  // Ensure all other focus effects are applied before this one
  setTimeout(() => emit('focus'), 0)
})

const aspectLock = computed(() => {
  const sourceSize = { width: crop.width, height: crop.height }
  const { videoWidth, videoHeight } = editorVideoStore.videoElement!
  return {
    width: sourceSize.width * videoWidth,
    height: sourceSize.height * videoHeight,
  }
})

const editorFocusStore = useEditorFocusStore()
const focused = computed(() => {
  return editorFocusStore.focus && editorFocusStore.focus.type === 'crop' && editorFocusStore.focus.key === props.id
})

function setFocus() {
  // Ensure all other focus effects are applied before this one
  setTimeout(() => editorFocusStore.setFocus('crop', props.id), 0)
}

const mouseover = inject<Ref<string | null>>('mouseover')!
</script>

<template>
  <div
    :class="{
      'rounded-[100%]': crop.input.shape === 'circle',
    }"
    :style="{
      left: crop.feedData.x * 100 + '%',
      top: crop.feedData.y * 100 + '%',
      width: crop.feedData.width * 100 + '%',
      height: crop.feedData.height * 100 + '%',
    }"
    class="absolute transition-opacity group"
    @click.stop
    @mousedown.stop="setFocus"
    @mouseover="mouseover = crop.id"
    @mouseleave="mouseover = null"
  >
    <MovableElement :id="crop.id + '-feed'" move resize
      v-model="crop.feedData"
      :shape="crop.input.shape === 'circle' ? 'circle' : 'rectangle'"
      :aspectLock="aspectLock"
      :bounds="{ top: 0, right: 1, bottom: 1, left: 0 }"
      :snap="enableSnapping"
      :min-size="6"
      :resize-handle-class="focused ? 'z-[250]' : ''"
    >
      <template #move>
        <div
          class="absolute -inset-px border-2 transition-[opacity] border-current box-content"
          :class="{
            'rounded-full': crop.input.shape === 'circle',
            'opacity-100': focused || mouseover === crop.id,
            'opacity-0 group-hover:opacity-75': !focused,
          }"
          :style="{ color: crop.input.color }"
        />
      </template>
      <template #resize-direction>
        <div
          :style="{ backgroundColor: focused ? crop.input.color : 'transparent' }"
          class="h-3 w-3 transition-[background-color]"
        />
      </template>
    </MovableElement>
  </div>
</template>

<style scoped lang="scss"></style>
