<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
    <path
      d="M6.35938 2.66016C3.59938 2.66016 1.35938 4.90016 1.35938 7.66016C1.35938 10.4202 3.59938 12.6602 6.35938 12.6602C9.11938 12.6602 11.3594 10.4202 11.3594 7.66016C11.3594 4.90016 9.12438 2.66016 6.35938 2.66016ZM9.07437 9.87516C8.45937 10.7602 7.44938 11.2852 6.37437 11.2852C5.29937 11.2852 4.28938 10.7602 3.67438 9.87516C3.55438 9.70516 3.59937 9.47016 3.76937 9.35516C3.93937 9.23516 4.17437 9.28016 4.28937 9.45016C4.76437 10.1302 5.54437 10.5402 6.37437 10.5402C7.20437 10.5402 7.98438 10.1352 8.45937 9.45016C8.57937 9.28016 8.80937 9.24016 8.97937 9.35516C9.15437 9.47016 9.19437 9.70516 9.07437 9.87516Z"
      fill="currentColor"
    />
    <path
      d="M14.375 1.66016L14.375 13.6602"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script setup lang="ts"></script>
