import type { Area, Bounds } from '@/modules/SLMovable/@types/Movable'

export function keepAreaInsideBounds(source: Area, bounds: Bounds): Area {
  return {
    x: keepAreaInsideBoundsX(source, bounds),
    y: keepAreaInsideBoundsY(source, bounds),
    width: source.width,
    height: source.height,
  }
}

export function keepAreaInsideBoundsX(source: Area, bounds: Omit<NonNullable<Bounds>, 'top' | 'bottom'> | null): number {

  let result = source.x

  if (bounds !== null && bounds.left !== null) {
    result = Math.max(bounds.left, result)
  }

  if (bounds !== null && bounds.right !== null) {
    result = Math.min(bounds.right - source.width, result)
  }

  return result
}

export function keepAreaInsideBoundsY(source: Area, bounds: Omit<NonNullable<Bounds>, 'left' | 'right'> | null): number {

  let result = source.y

  if (bounds !== null && bounds.top !== null) {
    result = Math.max(bounds.top, result)
  }

  if (bounds !== null && bounds.bottom !== null) {
    result = Math.min(bounds.bottom - source.height, result)
  }

  return result
}
