import { useRoute } from 'vue-router'

export const useDiscountCode = () => {
  const route = useRoute()
  if (route.name !== 'Upgrade') {
    return null
  } else {
    return route.query.discountcode as string | null
  }
}
