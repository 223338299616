<script setup lang="ts">
import { upgradeDialog } from '@/helpers/upgradeDialog'
import LoginDialog from '@/pages/auth/LoginDialog.vue'
import { Button, type ButtonVariants } from '@/components/ui/button'
import { computed, ref, type HTMLAttributes, toRef } from 'vue'
import { useGuard } from '@/Hooks/useGuard'
import { useFileUploads } from '@/components/Dialog/MultiUploadDialog/file-uploads/useFileUploads'
import { useUserInfoStore, onUserInfoReady } from '@/store/user/userInfo'
import * as Sentry from '@sentry/vue'
import { updateUploadedVideo } from '@/components/Dialog/MultiUploadDialog/file-uploads/useUploadedVideos'

const props = defineProps<{
  id: string,
  callToAction: string,
  uploadInBackground: boolean,
  variant?: ButtonVariants['variant']
  size?: ButtonVariants['size']
  class?: HTMLAttributes['class']
}>()

const emit = defineEmits<{ (name: 'select', event: { id: string, title: string, videoUrl: string }): void }>()

const fileUploads = useFileUploads()
const userInfoStore = useUserInfoStore()

const id = toRef(props, 'id')
const upload = fileUploads.selectById(id)

const validationResult = computed(() => upload.value?.validationResult ?? undefined)
const guard = computed(() => validationResult.value?.guard ?? undefined)
const requiresUpgrade = useGuard(guard)

const requiresAuth = computed(() => Boolean(validationResult.value?.requiresAuth && !userInfoStore.isAuthenticated))
const loginDialogOpen = ref(false)

const isNavigating = ref(false)
async function navigate() {

  if (!upload.value) return
  
  const { file, validationResult, id, fileMeta, status, urls } = upload.value
  if (!validationResult || !file) return

  isNavigating.value = true

  try {

    const title = fileMeta?.fileName ?? file.name.split('.').slice(0, -1).join('.')
    onUserInfoReady(async ({ isAuthenticated }) => {
      if (isAuthenticated) {
        try {
          await updateUploadedVideo(id, { name: title })
        } catch (e) {
          console.error(e)
        }
      }
    })
    
    emit('select', {
      id: id,
      title: title,
      videoUrl: status === 'finished'
        ? urls!.videoSignedUploadRequest.resultUrl!
        : URL.createObjectURL(file)
    })
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
    isNavigating.value = false
  }
}

const canUpload = computed(() => {
  return (upload.value && !upload.value.error) 
    && (validationResult.value && !validationResult.value.error) 
    && (!userInfoStore.isLoggedIn || upload.value.status !== 'pending')
    && !isNavigating.value
    && !(!props.uploadInBackground && upload.value.status !== 'finished')
})
</script>

<template>
  <LoginDialog v-if="requiresAuth" v-model:open="loginDialogOpen">
    <Button :disabled="!upload || upload.error || upload.validationResult?.error || isNavigating" :variant="variant" :size="size" :class="props.class">
      <template v-if="isNavigating">
        <span class="w-4 h-4 shrink-0 rounded-full border-t-transparent border border-current animate-spin" />
        Preparing file...
      </template>
      <template v-else>
        {{ callToAction }}
      </template>
    </Button>
  </LoginDialog>
  <Button v-else-if="guard && requiresUpgrade" @click="upgradeDialog.open(guard)" :variant="variant" :size="size" :class="props.class">
    {{ callToAction }}
  </Button>
  <Button v-else :disabled="!canUpload" @click="navigate" :variant="variant" :size="size" :class="props.class">
    <template v-if="isNavigating">
      <span class="w-4 h-4 shrink-0 rounded-full border-t-transparent border border-current animate-spin" />
      Preparing file...
    </template>
    <template v-else>
      {{ callToAction }}
    </template>
  </Button>
</template>

<style scoped lang="scss">

</style>
