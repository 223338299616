/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * StreamLadder Publisher Api
 * OpenAPI spec version: v1
 */
import { useQuery, useMutation } from '@tanstack/vue-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  QueryKey,
  UseQueryReturnType,
} from '@tanstack/vue-query'
import { unref } from 'vue'
import type { MaybeRef } from '@tanstack/vue-query/build/lib/types'
import type {
  PostDtoPage,
  GetPostsParams,
  PostDto,
  CreatePostDto,
  GetPostsSearchParams,
  ReplacePostDto,
} from '.././model'
import { publisherAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never

export const getPosts = (
  params?: MaybeRef<GetPostsParams>,
  options?: SecondParameter<typeof publisherAxiosInstance>,
  signal?: AbortSignal
) => {
  return publisherAxiosInstance<PostDtoPage>({ url: `/posts`, method: 'get', params, signal }, options)
}

export const getGetPostsQueryKey = (params?: MaybeRef<GetPostsParams>) =>
  ['posts', ...(params ? [params] : [])] as const

export const getGetPostsQueryOptions = <TData = Awaited<ReturnType<typeof getPosts>>, TError = unknown>(
  params?: MaybeRef<GetPostsParams>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPosts>>, TError, TData>
    request?: SecondParameter<typeof publisherAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getPosts>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetPostsQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPosts>>> = ({ signal }) =>
    getPosts(params, requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions }
}

export type GetPostsQueryResult = NonNullable<Awaited<ReturnType<typeof getPosts>>>
export type GetPostsQueryError = unknown

export const useGetPosts = <TData = Awaited<ReturnType<typeof getPosts>>, TError = unknown>(
  params?: MaybeRef<GetPostsParams>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPosts>>, TError, TData>
    request?: SecondParameter<typeof publisherAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPostsQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}

export const postPosts = (
  createPostDto: MaybeRef<CreatePostDto>,
  options?: SecondParameter<typeof publisherAxiosInstance>
) => {
  return publisherAxiosInstance<void | PostDto>(
    { url: `/posts`, method: 'post', headers: { 'Content-Type': 'application/json' }, data: createPostDto },
    options
  )
}

export const getPostPostsMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postPosts>>, TError, { data: CreatePostDto }, TContext>
  request?: SecondParameter<typeof publisherAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof postPosts>>, TError, { data: CreatePostDto }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof postPosts>>, { data: CreatePostDto }> = (props) => {
    const { data } = props ?? {}

    return postPosts(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostPostsMutationResult = NonNullable<Awaited<ReturnType<typeof postPosts>>>
export type PostPostsMutationBody = CreatePostDto
export type PostPostsMutationError = unknown

export const usePostPosts = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof postPosts>>, TError, { data: CreatePostDto }, TContext>
  request?: SecondParameter<typeof publisherAxiosInstance>
}) => {
  const mutationOptions = getPostPostsMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getPostsSearch = (
  params?: MaybeRef<GetPostsSearchParams>,
  options?: SecondParameter<typeof publisherAxiosInstance>,
  signal?: AbortSignal
) => {
  return publisherAxiosInstance<PostDtoPage>({ url: `/posts/search`, method: 'get', params, signal }, options)
}

export const getGetPostsSearchQueryKey = (params?: MaybeRef<GetPostsSearchParams>) =>
  ['posts', 'search', ...(params ? [params] : [])] as const

export const getGetPostsSearchQueryOptions = <TData = Awaited<ReturnType<typeof getPostsSearch>>, TError = unknown>(
  params?: MaybeRef<GetPostsSearchParams>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPostsSearch>>, TError, TData>
    request?: SecondParameter<typeof publisherAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getPostsSearch>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetPostsSearchQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPostsSearch>>> = ({ signal }) =>
    getPostsSearch(params, requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions }
}

export type GetPostsSearchQueryResult = NonNullable<Awaited<ReturnType<typeof getPostsSearch>>>
export type GetPostsSearchQueryError = unknown

export const useGetPostsSearch = <TData = Awaited<ReturnType<typeof getPostsSearch>>, TError = unknown>(
  params?: MaybeRef<GetPostsSearchParams>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPostsSearch>>, TError, TData>
    request?: SecondParameter<typeof publisherAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPostsSearchQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}

export const getPostsId = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof publisherAxiosInstance>,
  signal?: AbortSignal
) => {
  return publisherAxiosInstance<PostDto>({ url: `/posts/${unref(id)}`, method: 'get', signal }, options)
}

export const getGetPostsIdQueryKey = (id: MaybeRef<string>) => ['posts', id] as const

export const getGetPostsIdQueryOptions = <TData = Awaited<ReturnType<typeof getPostsId>>, TError = unknown>(
  id: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPostsId>>, TError, TData>
    request?: SecondParameter<typeof publisherAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getPostsId>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetPostsIdQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPostsId>>> = ({ signal }) =>
    getPostsId(id, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!id, staleTime: 60000, ...queryOptions }
}

export type GetPostsIdQueryResult = NonNullable<Awaited<ReturnType<typeof getPostsId>>>
export type GetPostsIdQueryError = unknown

export const useGetPostsId = <TData = Awaited<ReturnType<typeof getPostsId>>, TError = unknown>(
  id: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getPostsId>>, TError, TData>
    request?: SecondParameter<typeof publisherAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetPostsIdQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}

export const putPostsId = (
  id: MaybeRef<string>,
  replacePostDto: MaybeRef<ReplacePostDto>,
  options?: SecondParameter<typeof publisherAxiosInstance>
) => {
  return publisherAxiosInstance<PostDto>(
    {
      url: `/posts/${unref(id)}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: replacePostDto,
    },
    options
  )
}

export const getPutPostsIdMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPostsId>>,
    TError,
    { id: string; data: ReplacePostDto },
    TContext
  >
  request?: SecondParameter<typeof publisherAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof putPostsId>>,
  TError,
  { id: string; data: ReplacePostDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof putPostsId>>, { id: string; data: ReplacePostDto }> = (
    props
  ) => {
    const { id, data } = props ?? {}

    return putPostsId(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutPostsIdMutationResult = NonNullable<Awaited<ReturnType<typeof putPostsId>>>
export type PutPostsIdMutationBody = ReplacePostDto
export type PutPostsIdMutationError = unknown

export const usePutPostsId = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putPostsId>>,
    TError,
    { id: string; data: ReplacePostDto },
    TContext
  >
  request?: SecondParameter<typeof publisherAxiosInstance>
}) => {
  const mutationOptions = getPutPostsIdMutationOptions(options)

  return useMutation(mutationOptions)
}
export const deletePostsId = (id: MaybeRef<string>, options?: SecondParameter<typeof publisherAxiosInstance>) => {
  return publisherAxiosInstance<void>({ url: `/posts/${unref(id)}`, method: 'delete' }, options)
}

export const getDeletePostsIdMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deletePostsId>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof publisherAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof deletePostsId>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deletePostsId>>, { id: string }> = (props) => {
    const { id } = props ?? {}

    return deletePostsId(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeletePostsIdMutationResult = NonNullable<Awaited<ReturnType<typeof deletePostsId>>>

export type DeletePostsIdMutationError = unknown

export const useDeletePostsId = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deletePostsId>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof publisherAxiosInstance>
}) => {
  const mutationOptions = getDeletePostsIdMutationOptions(options)

  return useMutation(mutationOptions)
}
export const patchPostsIdRetry = (id: MaybeRef<string>, options?: SecondParameter<typeof publisherAxiosInstance>) => {
  return publisherAxiosInstance<PostDto>({ url: `/posts/${unref(id)}/retry`, method: 'patch' }, options)
}

export const getPatchPostsIdRetryMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof patchPostsIdRetry>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof publisherAxiosInstance>
}): UseMutationOptions<Awaited<ReturnType<typeof patchPostsIdRetry>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof patchPostsIdRetry>>, { id: string }> = (props) => {
    const { id } = props ?? {}

    return patchPostsIdRetry(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PatchPostsIdRetryMutationResult = NonNullable<Awaited<ReturnType<typeof patchPostsIdRetry>>>

export type PatchPostsIdRetryMutationError = unknown

export const usePatchPostsIdRetry = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof patchPostsIdRetry>>, TError, { id: string }, TContext>
  request?: SecondParameter<typeof publisherAxiosInstance>
}) => {
  const mutationOptions = getPatchPostsIdRetryMutationOptions(options)

  return useMutation(mutationOptions)
}
