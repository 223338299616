import type { Area, Point, Directions, Bounds, Size } from '@/modules/SLMovable/@types/Movable'
import { keepPointInsideBoundsY, keepPointInsideBoundsX } from '@/modules/SLMovable/helpers/point'

export function resize(from: Area, to: Point, directions: Directions, bounds: Bounds, minSize: Size | null): Area {

  const isResizingX = directions.includes('e') || directions.includes('w')
  const isResizingY = directions.includes('n') || directions.includes('s')

  if (isResizingX && isResizingY) {
    return resizeBoth(from, to, directions, bounds, minSize)
  } else if (isResizingX) {
    return resizeX(from, to, directions, bounds, minSize)
  } else if (isResizingY) {
    return resizeY(from, to, directions, bounds, minSize)
  }

  throw new Error('At least one direction must be provided')
}

function resizeX(from: Area, to: Point, directions: Directions, bounds: Bounds, minSize: Size | null): Area {

  const anchorX = directions.includes('w')
    ? from.x + from.width
    : from.x

  const clampedX = keepPointInsideBoundsX(to.x, bounds)
  const deltaX = clampedX - anchorX

  const signX = Math.sign(deltaX) === 0 ? 1 : Math.sign(deltaX)
  const newWidth = minSize
    ? Math.max(minSize.width, Math.abs(deltaX)) * signX
    : deltaX

  const isCrossingLeftBound = bounds !== null && bounds.left !== null && anchorX + newWidth < bounds.left
  const isCrossingRightBound = bounds !== null && bounds.right !== null && anchorX + newWidth > bounds.right

  let newX = Math.min(anchorX, anchorX + newWidth)
  if (isCrossingLeftBound) {
    newX = anchorX
  } else if (isCrossingRightBound) {
    newX = anchorX - newWidth
  }

  return {
    x: newX,
    y: from.y,
    width: Math.abs(newWidth),
    height: from.height,
  }
}

function resizeY(from: Area, to: Point, directions: Directions, bounds: Bounds, minSize: Size | null): Area {

  const anchorY = directions.includes('n')
    ? from.y + from.height
    : from.y

  const clampedY = keepPointInsideBoundsY(to.y, bounds)
  const deltaY = clampedY - anchorY

  const signY = Math.sign(deltaY) === 0 ? 1 : Math.sign(deltaY)
  const newHeight = minSize
    ? Math.max(minSize.height, Math.abs(deltaY)) * signY
    : deltaY

  const isCrossingTopBound = bounds !== null && bounds.top !== null && anchorY + newHeight < bounds.top
  const isCrossingBottomBound = bounds !== null && bounds.bottom !== null && anchorY + newHeight > bounds.bottom

  let newY = Math.min(anchorY, anchorY + newHeight)
  if (isCrossingTopBound) {
    newY = anchorY
  } else if (isCrossingBottomBound) {
    newY = anchorY - newHeight
  }

  return {
    x: from.x,
    y: newY,
    width: from.width,
    height: Math.abs(newHeight),
  }
}

function resizeBoth(from: Area, to: Point, directions: Directions, bounds: Bounds, minSize: Size | null): Area {

  const anchorX = directions.includes('w')
    ? from.x + from.width
    : from.x
  const anchorY = directions.includes('n')
    ? from.y + from.height
    : from.y

  const clampedX = keepPointInsideBoundsX(to.x, bounds)
  const clampedY = keepPointInsideBoundsY(to.y, bounds)

  const deltaX = clampedX - anchorX
  const deltaY = clampedY - anchorY

  const signX = Math.sign(deltaX) === 0 ? 1 : Math.sign(deltaX)
  const newWidth = minSize
    ? Math.max(minSize.width, Math.abs(deltaX)) * signX
    : deltaX

  const signY = Math.sign(deltaY) === 0 ? 1 : Math.sign(deltaY)
  const newHeight = minSize
    ? Math.max(minSize.height, Math.abs(deltaY)) * signY
    : deltaY

  const isCrossingLeftBound = bounds !== null && bounds.left !== null && anchorX + newWidth < bounds.left
  const isCrossingRightBound = bounds !== null && bounds.right !== null && anchorX + newWidth > bounds.right
  const isCrossingTopBound = bounds !== null && bounds.top !== null && anchorY + newHeight < bounds.top
  const isCrossingBottomBound = bounds !== null && bounds.bottom !== null && anchorY + newHeight > bounds.bottom

  let newX = Math.min(anchorX, anchorX + newWidth)
  if (isCrossingLeftBound) {
    newX = anchorX
  } else if (isCrossingRightBound) {
    newX = anchorX - newWidth
  }

  let newY = Math.min(anchorY, anchorY + newHeight)
  if (isCrossingTopBound) {
    newY = anchorY
  } else if (isCrossingBottomBound) {
    newY = anchorY - newHeight
  }

  return {
    x: newX,
    y: newY,
    width: Math.abs(newWidth),
    height: Math.abs(newHeight),
  }
}
