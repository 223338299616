<template>
  <RadixErrorDialog :open="isOpen">
    <template #title>{{ title }}</template>
    <template #description>
      <slot />
    </template>
    <template #actions>
      <AppButton variant="ghost" @click="onClickHandler">
        {{ buttonText }}
      </AppButton>
    </template>
  </RadixErrorDialog>
</template>

<script>
import RadixErrorDialog from '@/components/Dialog/RadixErrorDialog.vue'
import { Button } from '@/components/ui/button/index'

export default {
  components: {
    AppButton: Button,
    RadixErrorDialog,
  },
  props: {
    title: {
      type: String,
      default: 'Error',
    },
    buttonText: {
      type: String,
      default: 'Ok, I understand',
    },
    action: {
      type: Function,
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    onClickHandler() {
      if (typeof this.action != 'undefined') {
        this.action()
      }

      this.isOpen = false
    },
    open() {
      this.isOpen = true
    },
  },
}
</script>

<style lang="scss">
.error-icon {
  color: rgba($color: red, $alpha: 0.8);
  font-size: 3em;
}
</style>
