import { getApiYouTubeClipsClipId } from '@/apis/streamladder-api/you-tube-clips/you-tube-clips'
import { useWebsocketChannel } from '@/modules/WebsocketService/WebSocketService'

export async function downloadYoutubeClip(clipId: string) {

  const response = await getApiYouTubeClipsClipId(clipId)

  // If we get an empty response from a YouTube clip, we have to listen to the pusher client to retrieve the info needed.
  if (response.mp4Url === '') {
    console.log('Empty response, listening to pusher client')
    return await getClipMetaByPusherTask(response.taskId).catch((error) => {
      return {
        error: error.status
      };
    })
  } else {
    return response
  }
}

async function getClipMetaByPusherTask(taskId: string) {

  return new Promise((resolve, reject) => {

    const channelName = `cache-task-status-${taskId}`;

    useWebsocketChannel(channelName, (eventName, data) => {
      if (eventName === 'progress') {
        if (data.status === 'finished') {
          resolve(data)
        }
        if (data.status === 'error') {
          if (data.message === 'blocked_video') {
            reject(new Error('blocked_video'));
          } else {
            reject(new Error(`Failed downloading YouTube clip: ${JSON.stringify(data)}`))
          }
        }
      }
    })
  })
}
