import { useDebugPopoverAction } from '@/components/Debug/useDebugStore'
import { useEditorMainStore } from '@/store/editor/editorMain'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { useEditorStickersStore } from '@/store/editor/editorStickers'

export const useDebugActionRenderOverlay = () => {
  const editorMainStore = useEditorMainStore()
  const editorCaptionsStore = useEditorCaptionsStore()
  const editorStickersStore = useEditorStickersStore()

  useDebugPopoverAction('Test overlay render', () => {
    const stickersData = editorStickersStore.selectedStickers.map(({ visible, component, ...properties }) => properties)
    const overlayData = {
      Stickers: stickersData,
      Captions: editorCaptionsStore.captions,
      CaptionsWrapper: editorCaptionsStore.captionsWrapper,
      DurationMs: editorMainStore.trimmedDurationMs,
      OffsetMs: editorMainStore.trimmedStartTime,
      CaptionsJson: JSON.stringify({
        CaptionStyleSettings: editorCaptionsStore.captionStyleSettings,
      }),
    }
    localStorage.setItem('test-overlay-data', JSON.stringify(overlayData))
    window.open('/sticker-render#debug', '_blank')
  })
}
