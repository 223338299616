<script setup lang="ts">
import { tiers } from '@/enums/tiers'
import { useUserInfoStore } from '@/store/user/userInfo'
import IconSaxCloseCircle from '@/components/Icons/iconsax/IconSaxCloseCircle.vue'
import { Button } from '@/components/ui/button'

const emit = defineEmits<{
  (event: 'next'): void
  (event: 'cancel'): void
}>()

const user = useUserInfoStore()

const silverAndGoldFeatures = [
  {
    title: '1080p 60fps',
    description: 'The best video quality',
  },
  {
    title: 'All caption styles',
    description: 'Keep viewers engaged',
  },
  {
    title: 'All stickers',
    description: 'Promote your channels',
  },
];

const goldOnlyFeatures = [
  {
    title: 'Zoom effects',
    description: 'Add zooms to your video',
  },
  {
    title: 'Content publisher',
    description: 'Post and schedule clips',
  },
  {
    title: 'Custom layouts & text',
    description: 'Miss out on all the styles',
  },
];

const summaryFeatures = user.tier === tiers.GOLD
  ? silverAndGoldFeatures.concat(goldOnlyFeatures)
  : silverAndGoldFeatures;
</script>

<template>
  <div class="flex p-4 lg:p-10">
    <div class="relative flex flex-col gap-2 w-full">

      <h3 class="text-center text-3xl lg:text-4xl w-full">
        Wait, look what you'll miss out on!
      </h3>

      <div class="flex flex-col gap-4">
        <div class="flex flex-col gap-2">
          <p class="font-extralight text-center">
            Before you go, take a moment to see what unique features you'll be losing.
          </p>
          <div class="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-4 py-2 sm:py-8">
            <div v-for="feature in summaryFeatures" :key="feature.title" class="flex p-2 sm:p-4 border border-red-500 rounded-xl bg-red-50 dark:bg-surface-bg">
              <div class="sm:flex flex-col items-center justify-center px-3 hidden">
                <div class="rounded-full p-0.5 bg-red-100 dark:bg-red-950">
                  <IconSaxCloseCircle class="text-red h-6 w-6 rounded-full fill-red-500 text-red-100 dark:text-red-950"/>
                </div>
              </div>
              <div class="flex flex-col items-center gap-0 sm:gap-1.5 justify-center sm:items-start text-center sm:text-left text-xs sm:text-[16px] w-full">
                <p class="font-bold">{{ feature.title }}</p>
                <p class="font-extralight">{{ feature.description }}</p>
              </div>
            </div>
          </div>
          <div class="w-full flex flex-col justify-center">
            <Button
              as="a"
              variant="link"
              class="underline underline-offset-4 hover:opacity-75 transition-all"
              href="/upgrade#compare"
              target="_blank">
              And all other {{ tiers.toString(user.tier) }} features
            </Button>
            <div class="flex items-center justify-center w-full h-16">
              <p class="max-w-lg text-xs text-center font-light">
                * All payments are non-refundable. We do not provide refunds if your subscription renews and you forget to cancel. You are responsible for the charges.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="flex w-full justify-between absolute bottom-0">
        <Button
          variant="outline"
          @click="emit('cancel')">
          Cancel
        </Button>
        <Button
          variant="primary"
          @click="emit('next')">
          Next
        </Button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>