<template>
  <section class="flex flex-col gap-4">
    <section class="flex h-full min-h-0 flex-col gap-4">
      <div class="layer-2 flex h-full flex-col rounded-lg border-2 border-gray-100 bg-white">
        <div v-if="canCustomText" class="p-4">
          <h2 class="text-xl font-semibold leading-snug">Custom Texts</h2>
          <p class="font-light">Add text to your clips and capture your audience's attention.</p>
        </div>
        <div v-else class="p-4">
          <h2 class="text-xl font-semibold leading-snug">Custom Texts</h2>
          <p class="font-light">Upgrade to add text to your clips and capture your audience's attention.</p>
        </div>

        <template v-if="!loadingBrandkit">
          <div class="flex flex-row gap-2 px-4">
            <ScrollArea>
              <button
                v-for="tag in tags"
                class="btn-ghost btn-sm btn rounded capitalize"
                :class="{
                  'btn-info': tag === selectedTag,
                  'font-normal': tag !== selectedTag,
                }"
                @click="selectedTag = tag"
                :key="tag"
              >
                {{ tag }}
              </button>
            </ScrollArea>
          </div>
          <div
            class="notranslate grid min-h-0 gap-2 overflow-auto p-4"
            :class="
              hasThreeColumnLayout
                ? 'grid-cols-2 lg:grid-cols-[repeat(auto-fill,_minmax(150px,_1fr))]'
                : 'grid-cols-2 xl:grid-cols-3'
            "
          >
            <Tile :aspect="0.6" @click="openBrandKitModal" feature="brandkit">
              <IconSquareRoundedPlus />
              <span>Custom style</span>
            </Tile>

            <brand-kit-style-wrapper
              v-for="sticker in brandKitstore.brandKitStyles"
              :key="sticker.id"
              ref="stickerWrappers"
              :sticker="sticker"
              :editable="false"
              :display-actions="true"
              :is-text-sticker="true"
              :has-editable-text="true"
              :should-preview-in-clip="true"
            />

            <sticker-wrapper
              v-for="(sticker, index) in textLibrary"
              :key="sticker.key + index + selectedTag + getHtmlContent(index)"
              :timing="selectedTag.toLowerCase()"
              ref="stickerWrappers"
              :sticker="sticker"
              :is-new="isNewText(sticker)"
              :has-editable-text="true"
              :editable="false"
              :is-text-sticker="true"
              :html-content="getHtmlContent(index)"
              @stickerPicked="onStickerPicked(sticker)"
            />
          </div>
        </template>
        <div v-else class="flex flex-col items-center p-4">
          <lottie-animation class="w-1/4 grayscale" url="/lottie/hour-glass-loading.json" :auto-play="true" />
          <p>Loading stickers & styles...</p>
        </div>
      </div>
    </section>
  </section>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, computed } from 'vue'
import textLibrary from './textLibrary'
import { useGuard } from '@/Hooks/useGuard'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { isNewText, markTextAsUsed } from '@/helpers/isNewOverlayElement'
import BrandKitStyleWrapper from '@/components/Brandkit/BrandKitStyleWrapper.vue'
import StickerWrapper from '@/components/Stickers/StickerWrapper.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import Tile from '@/components/Tile.vue'
import { useUserBrandkitStore } from '@/store/user/userBrandkit'
import { IconSquareRoundedPlus } from '@tabler/icons-vue'
import EventBus from '../../eventBus'
import brandKitEvents from '../../events/brandKitEvents'
import brandkitStyleTypes from '@/enums/brandkitStyleTypes'
import { ScrollArea } from '@/components/ui/scroll-area'

const tags = ['Default', 'Intro', 'Outro',] as const
const selectedTag = ref<typeof tags[number]>('Default')

const getHtmlContent = computed(() => (index: number) => {
  return previewTexts[selectedTag.value][index % previewTexts[selectedTag.value].length]
})

const previewTexts = {
  Default: ['Enter Text\n here'],
  Outro: [
    'Link in the\n description 👇',
    'What did you think?\n Let me know',
    'Did you agree?\n 👍',
    'Did you disagree? 👎',
    'Like, subscribe\n and hit that bell\n for more ❤️',
    'This was CRAZY\n 😨',
    'This was SO fun\n 😇',
    'Share this\n with your friends',
    'Follow for more!',
    'Subscribe for more!',
    'Comment below💬',
    'My mind\n was BLOWN🤯',
  ],
  Intro: [
    'Stay till the end\n for a surprise',
    'What happens next\n is crazy!',
    "That's what I call\n a plottwist",
    'How did\n this happen?!',
    'I did NOT\n expect this',
    'I was shocked\n after this',
    'This will\n change your\n perspective ',
    'Wait for it...',
  ],
  'Fill in': [
    'The top 5\n [...]',
    'Imagine if you could \n (DESIRED RESULT)',
    'Why doesn’t \n anyone talk about \n (RELATED TO NICHE)?',
    'What if you could\n (DESIRED RESULT) \nwithout\n (PAIN POINT OF TARGET AUDIENCE)?',
    'Are you struggling with \n(PAIN POINT OF TARGET AUDIENCE)? \nThen try this',
    'I tried every\n (HACK/TIP/TRICK),\n so you don’t have to',
    '(HACK/TIP/TRICK) \nI wish I knew earlier',
    'These 3 (HACK/TIP/TRICK)\n feel illegal to know',
    'Did you know that\n (FACT)',
    'Fun fact:\n (FACT)',
  ],
}

const loadingBrandkit = ref(false)

const canCustomText = useGuard('text')
const hasThreeColumnLayout = useFeatureFlagVariantEnabled('three-column-layout', 'wide')

const stickerText = ref('')

const brandKitstore = useUserBrandkitStore()

const rescaleStickers = () => {
  if (!stickerWrappers.value) {
    return
  }
  stickerWrappers.value.forEach((stickerWrapper) => {
    stickerWrapper.scaleSticker?.()
  })
}

const onStickerPicked = (sticker) => {
  emit('stickerPicked')
  markTextAsUsed(sticker)
}

const stickerWrappers = ref([])

const emit = defineEmits<{
  stickerPicked: () => void
}>()

onMounted(() => {
  rescaleStickers()
})

watch(stickerText, (newValue) => {
  if (newValue.length > 0) {
    rescaleStickers()
  }
})

const openBrandKitModal = () => {
  EventBus.$emit(brandKitEvents.OPEN_BRAND_KIT_EVENT, {
    brandKitType: brandkitStyleTypes.STICKERS,
  })
}
</script>

<style lang="scss" scoped>
.change-sticker-text-button {
  cursor: pointer;
  user-select: none;

  svg {
    transform: rotate(90deg);
  }

  &.open {
    svg {
      transform: rotate(-90deg);
    }
  }
}

.sticker-filter {
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  margin-top: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;

  .filter {
    font-size: 0.8em;
    padding: 0.3em 0.8em;
    border: 2px solid rgb(71, 8, 158, 0.2);
    border-radius: 40px;
    color: rgb(71, 8, 158, 0.6);

    &.active {
      background-color: #33cff2;
      color: white;
      border-color: #33cff2;
    }
  }
}
</style>
