<script lang="ts" setup>
import { useEditorMainStore } from '@/store/editor/editorMain'
import IconSaxMagicpen from '@/components/Icons/iconsax/IconSaxMagicpen.vue'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import { useAudioAnalyzerForVideoElement } from '@/modules/AudioAnalyser/useAudioAnalyzer'
import { computed, ref, watch } from 'vue'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import TwBaseDialog from '@/components/Dialog/TwBaseDialog.vue'
import { useEditorFeedDataStore } from '@/store/editor/editorFeedData'
import { v4 } from 'uuid'
import LottieAnimation from '@/components/LottieAnimation.vue'
import SimpleTooltip from '@/components-v2/data-display/SimpleTooltip.vue'
import { useSplitterClone } from '@/components/Editor/RemoveParts/useSplitterClone'
import IconSaxCloseCircle from '@/components/Icons/iconsax/IconSaxCloseCircle.vue'
import logging from '@/logging'
import { useGuard } from '@/Hooks/useGuard'
import { upgradeDialog } from '@/helpers/upgradeDialog'
import * as Sentry from '@sentry/vue'

const editorMainStore = useEditorMainStore()
const editorClipInfoStore = useEditorClipInfoStore()

const canSegment = useGuard('video-segmentation')

const editorVideo = useEditorVideoStore()
const editorFeedDataStore = useEditorFeedDataStore()

const mp4Url = computed(() => editorClipInfoStore.mp4Url)

const audioData = computed(() => editorClipInfoStore.audioData || undefined)

const { loadAudio, getSilentParts, isLoading, loadingProgress, decodedData } = useAudioAnalyzerForVideoElement(mp4Url, {
  decodedData: audioData.value,
})

watch(decodedData, () => {
  if (decodedData.value) {
    editorClipInfoStore.audioData = decodedData.value
  }
})

const segments = computed(() => editorFeedDataStore.segments)
const { cloned, sync } = useSplitterClone(segments)

const noSilentParts = ref(false)

const removeParts = async () => {
  if (!canSegment.value) {
    upgradeDialog.open('video-segmentation')
    return
  }

  try {
    editorVideo.playing = false

    sync()

    await loadAudio()
    const skipZones = getSilentParts()

    if (skipZones.length === 0) {
      noSilentParts.value = true
      return
    }

    // merge segments and skipZones
    const segments = editorFeedDataStore.segments
    const newSegments: { id: string; start: number; end: number }[] = []
    segments.forEach((segment) => {
      let start = segment.start
      let end = segment.end
      skipZones.forEach((skipZone) => {
        if (skipZone.start <= start && skipZone.end >= start) {
          start = skipZone.end
        }
        if (skipZone.start <= end && skipZone.end >= end) {
          end = skipZone.start
        }
        if (skipZone.start >= start && skipZone.end <= end) {
          newSegments.push({
            id: v4(),
            start,
            end: skipZone.start,
          })
          start = skipZone.end
        }
      })
      if (start < end) {
        newSegments.push({
          id: v4(),
          start,
          end,
        })
      }
    })
    editorFeedDataStore.segments = newSegments
    editorClipInfoStore.audioSkipped = true

    const skippedDuration = skipZones.reduce((acc, skipZone) => acc + skipZone.end - skipZone.start, 0) / 1000

    logging.trackEvent('Auto Splitting added', {
      cutsPlaced: skipZones.length,
      secondsRemoved: skippedDuration,
      averageCutLength: skippedDuration / skipZones.length,
      secondsTotal: editorVideo._duration,
    })
  } catch (e) {
    Sentry.captureException(e)
    isLoading.value = false
    noSilentParts.value = true
    return
  }
}

const reset = () => {
  editorFeedDataStore.segments = cloned.value
  editorClipInfoStore.audioSkipped = false
}
</script>
<template>
  <SimpleTooltip>
    <template v-slot:trigger>
      <button
        @click="() => (editorClipInfoStore.audioSkipped ? reset() : removeParts())"
        class="text-normal btn-ghost btn-sm btn flex flex-row gap-1 rounded-lg text-black md:flex"
        :class="{
          'btn-info': !editorClipInfoStore.audioSkipped,
          'btn-danger': editorClipInfoStore.audioSkipped || noSilentParts,
        }"
        :disabled="editorMainStore.videoDuration === 0"
      >
        <IconSaxMagicpen v-if="!editorClipInfoStore.audioSkipped" class="h-5 w-5" />
        <IconSaxCloseCircle v-if="editorClipInfoStore.audioSkipped" class="h-5 w-5" />
        <span v-if="!editorClipInfoStore.audioSkipped && !noSilentParts" class="">AI auto split</span>
        <span v-if="!editorClipInfoStore.audioSkipped && noSilentParts" class="">No silences found</span>
        <span v-if="editorClipInfoStore.audioSkipped" class="">Remove AI split</span>
        <tw-base-dialog :model-value="isLoading" styles="max-w-lg" :closable="false">
          <div class="p-10">
            <lottie-animation class="mx-auto w-60" url="/lottie/loading-cat.json" :loop="true" :autoPlay="true" />
            <div class="flex flex-col items-center justify-start gap-6">
              <div class="inline-flex items-center justify-start gap-3">
                <div class="inline-flex flex-col items-center justify-start gap-10">
                  <div class="flex flex-col items-center justify-start gap-10">
                    <div class="flex flex-col items-center justify-start gap-2">
                      <div class="text-[25px] font-bold leading-[35px] text-purple-900">Removing silences</div>
                      <div class="text-center text-lg font-medium leading-relaxed text-purple-900">
                        Our cat is busy removing silent parts from your video. Hang tight - duration depends on the
                        clip's length. Just a few seconds more!
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="radial-progress text-primary" :style="{ ['--value']: loadingProgress }" role="progressbar">
                {{ loadingProgress }}%
              </div>
            </div>
          </div>
        </tw-base-dialog>
      </button>
    </template>
    <template v-slot:content>
      <span v-if="noSilentParts">No silences detected in your clip</span>
      <span v-else-if="!editorClipInfoStore.audioSkipped">Remove parts of your clip where no sound is registered</span>
      <span v-else-if="editorClipInfoStore.audioSkipped">Undo the removal of silent parts</span>
    </template>
  </SimpleTooltip>
</template>
