<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
    <path
      d="M1.125 1.66113L1.125 13.6611"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.10938 2.66113C6.34938 2.66113 4.10938 4.90113 4.10938 7.66113C4.10938 10.4211 6.34938 12.6611 9.10938 12.6611C11.8694 12.6611 14.1094 10.4211 14.1094 7.66113C14.1094 4.90113 11.8744 2.66113 9.10938 2.66113ZM11.8244 9.87613C11.2094 10.7611 10.1994 11.2861 9.12437 11.2861C8.04937 11.2861 7.03938 10.7611 6.42438 9.87613C6.30438 9.70613 6.34937 9.47113 6.51937 9.35613C6.68937 9.23613 6.92437 9.28113 7.03937 9.45113C7.51437 10.1311 8.29437 10.5411 9.12437 10.5411C9.95437 10.5411 10.7344 10.1361 11.2094 9.45113C11.3294 9.28113 11.5594 9.24113 11.7294 9.35613C11.9044 9.47113 11.9444 9.70613 11.8244 9.87613Z"
      fill="currentColor"
    />
  </svg>
</template>
<script setup lang="ts"></script>
