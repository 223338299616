import { defineStore, acceptHMRUpdate } from 'pinia'
import productData from '@/data/plans'
import type { TierLevel } from '@/data/plans'
import type { ProductData } from '@/data/plans'
import { accountsAxios } from '@/services/axios'
import unwrap from '@/helpers/unwrap'
import type { SubscriptionIntervals } from '@/Hooks/useSubscriptionIntervals'
import { productIds } from '@/data/_productIds'
import axios from 'axios'

export const DEVELOPER_GOLD_PLAN = 'sl_developer_gold'
export const DEPRECATED_PLUS_PLAN = 'P-4WH75197W52695832MBTTNDQ'

interface ProductsState {
  isLoadingPrices: boolean
  // TODO types can be improved here, but its a bit too much for now
  products: PaddleProduct[]
  tiers: TierLevel[]
  productData: ProductData
}

export const useProductsStore = defineStore<'products', ProductsState>('products', {
  state: (): ProductsState => {
    return {
      isLoadingPrices: true,
      products: [],
      tiers: unwrap.keys(productData),
      productData,
    }
  },
  actions: {
    setProductPlanData(product: PaddleProduct) {
      unwrap.values(this.productData).forEach((tier) => {
        if (!tier.plans) {
          return tier
        }

        tier.plans = Object.values(tier.plans).map((plan) => {
          if (plan.paddle === product.productId) {
            plan.paddleData = product
          }

          return plan
        })
      })
    },
    async setRemainingPrice() {
      const upgradePriceData = await getUpgradeData()

      if (upgradePriceData === '') return

      // Loop over all the plans to add the remaining data.
      Object.values(this.productData).forEach((tier) => {
        if (!tier.plans) {
          return
        }

        tier.plans = Object.values(tier.plans).map((plan) => {
          const remaining = upgradePriceData.find((r) => {
            return r.planId === plan.paddle.toString()
          })

          const priceToShow = remaining.payNowAmount < 0 ? 0 : remaining.payNowAmount

          if (!plan.paddleData) {
            throw new Error('Make sure to load Paddle data before invoking `setRemainingPrice()`')
          }

          plan.paddleData = {
            ...plan.paddleData,
            remainingPrice: priceToShow,
            displayRemainingPrice: getDisplayPrice(priceToShow, plan.paddleData.currency, 2),
            nextPaymentDate: remaining.nextPaymentDate,
            hasRemainingPrice: true,
          }
          return plan
        })
      })
    },
    getPrices() {
      this.isLoadingPrices = true
      // Fetch the paddle IDs from the data.
      const productIds = this.tiers.reduce((arr, tier) => {
        if (this.productData[tier].plans) {
          arr.push(...this.productData[tier].plans.map((plan) => plan.paddle))
        }

        return arr
      }, [] as number[])

      axios.get<PaddleHttpResponse>(`https://paddle-classic-products.dev912.workers.dev/?product_ids=${productIds}`)
      .then((axiosResponse) => {
        this.products = axiosResponse.data.response.products.map((product) => {
          const paddleProduct: PaddleProduct = {
            currency: product.currency,
            hasRemainingPrice: false,
            remainingPrice: product.price,
            yearlyPricePerMonthRaw: getYearlyPricePerMonth(product),
            displayRemainingPrice: getDisplayPrice(product.price.gross, product.currency),
            displayPrice: getDisplayPrice(getYearlyPricePerMonth(product), product.currency),
            totalPriceRaw: product.price.gross,
            totalPrice: getDisplayPrice(product.price.gross, product.currency),
            interval: product.subscription.interval,
            showDiscountLabel: product.subscription.interval == 'year',
            intervalLabel: getIntervalLabel(product.subscription.interval),
            productId: product.product_id,
            productTitle: product.product_title,
          }

          this.setProductPlanData(paddleProduct)
          this.isLoadingPrices = false

          return paddleProduct
        })
      });
    },
  },
})

export function getYearlyPricePerMonth(product) {
  if (product == null) {
    return ''
  }

  let value = product.price.gross

  if (product.subscription.interval == 'year') {
    value = value / 12
  }

  // if ends with 9 (4.99), add 0.01 to make it a nice round number
  if (value.toString().match(/\.\d9/)) value = value + 0.01

  return value
}

export function findProduct(planId: number) {
  const productStore = useProductsStore()
  return productStore.products.find((x) => x.productId == planId)
}

export function getDisplayPrice(value: number, currency: string, decimals = 0) {
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: decimals,
  })
  let formattedValue = formatter.format(value)
  formattedValue = formattedValue.replace('US$', '$')
  return formattedValue
}

const legacyProductIds = [productIds.PLUS.MONTHLY, productIds.PLUS.YEARLY] as number[]
export function findPlans(interval: 'month' | 'year'): PaddleProduct[] {
  const productsStore = useProductsStore()
  return productsStore.products
    .filter((p) => !legacyProductIds.includes(p.productId))
    .filter((p) => p.interval === interval)
}

function getIntervalLabel(interval: string) {
  return interval == 'year' ? 'per month paid anually' : 'per month paid monthly'
}

async function getUpgradeData() {
  const data = await accountsAxios.get('api/pricing/upgrades')
  return data.data
}

export interface PaddleProduct {
  currency: string
  hasRemainingPrice: boolean
  remainingPrice: number
  yearlyPricePerMonthRaw: number
  displayRemainingPrice: string
  displayPrice: string
  totalPriceRaw: number
  totalPrice: string
  interval: SubscriptionIntervals
  showDiscountLabel: boolean
  intervalLabel: string
  productId: number
  productTitle: string
  nextPaymentDate: Date
}

export const isDeprecatedPlusPlan = (productId: number | string): boolean => {
  if (typeof productId === 'number') {
    return [productIds.PLUS.MONTHLY, productIds.PLUS.YEARLY].includes(productId as typeof productIds.PLUS.MONTHLY | typeof productIds.PLUS.YEARLY)
  } else {
    const parsedProductId = Number(productId as typeof DEPRECATED_PLUS_PLAN | typeof productIds.PLUS.MONTHLY | typeof productIds.PLUS.YEARLY)
    if (isNaN(parsedProductId)) {
      return [DEPRECATED_PLUS_PLAN].includes(productId)
    } else {
      return [productIds.PLUS.MONTHLY, productIds.PLUS.YEARLY].includes(parsedProductId as typeof productIds.PLUS.MONTHLY | typeof productIds.PLUS.YEARLY)
    }
  }
}

export const isMonthly = (productId: number): boolean => {
  const values = unwrap.values(productIds)

  for (const key in values) {
    if (values[key].MONTHLY === productId) {
      return true
    }
  }

  return false
}

export const isYearly = (productId: number): boolean => {
  const values = unwrap.values(productIds)

  for (const key in values) {
    if (values[key].YEARLY === productId) {
      return true
    }
  }

  return false
}

export const isGold = (productId: number): boolean => {
  const values = unwrap.values(productIds.GOLD)
  return values.includes(productId as (typeof values)[number])
}

export const isSilver = (productId: number): boolean => {
  const values = unwrap.values(productIds.SILVER)
  return values.includes(productId as (typeof values)[number])
}

export const isPlus = (productId: number): boolean => {
  const values = unwrap.values(productIds.PLUS)
  return values.includes(productId as (typeof values)[number])
}

export const getTier = (productId: number): TierLevel => {
  if (isGold(productId)) {
    return 'gold'
  } else if (isSilver(productId)) {
    return 'silver'
  } else if (isPlus(productId)) {
    return 'silver'
  } else {
    return 'free'
  }
}

export const getInterval = (productId: number): SubscriptionIntervals => {
  if (isMonthly(productId)) {
    return 'month'
  } else if (isYearly(productId)) {
    return 'year'
  } else {
    return 'month'
  }
}

type PaddleHttpResponse = {
  success: boolean
  response: PaddleResponse
}

type PaddleResponse = {
  products: {
    currency: string
    price: number & {
      gross: number
    }
    subscription: {
      interval: SubscriptionIntervals
    }
    product_id: number
    product_title: string
  }[]
}

// Allows hot-reloading of the store
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useProductsStore, import.meta.hot))
}
