<script setup lang="ts">
import AccountAndBilling from '@/assets/user-guides/account-and-billing.png'
import SocialIntegrations from '@/assets/user-guides/social-integrations.png'
import Editing from '@/assets/user-guides/editing.png'

const guides = [
  {
    url: 'https://streamladder.com/user-guides/account',
    img: AccountAndBilling,
    title: 'Account & Billing',
    description: 'Navigate your account settings and understand your billing options.',
  },
  {
    url: 'https://streamladder.com/user-guides/publishing',
    img: SocialIntegrations,
    title: 'Social integrations',
    description: 'Seamlessly connect and manage your social platforms.',
  },
  {
    url: 'https://streamladder.com/user-guides/editing',
    img: Editing,
    title: 'Editing',
    description: 'Get tips on rendering, adding captions, text, stickers, and more.',
  },
]
</script>
<template>
  <section class="layer-2 w-full px-12 pb-20 pt-14">
    <div class="container mx-auto max-w-screen-xl">
      <div class="flex flex-col items-center justify-start text-center">
        <div class="text-3xl font-bold capitalize">Explore Our User guides</div>
        <div class="text-lg font-normal leading-snug">
          Find solutions to common issues and enhance your streaming experience.
        </div>
      </div>
      <div class="mt-12 grid grid-cols-1 items-start justify-start gap-4 lg:grid-cols-3">
        <a
          v-for="guide in guides"
          :key="guide.url"
          :href="guide.url"
          target="_blank"
          class="layer-1 group flex flex-col flex-wrap items-center rounded-lg px-4 py-8 transition-all hover:shadow active:scale-90 md:flex lg:flex-col"
        >
          <img class="mb-6 h-12 w-12 rounded-full bg-white" :alt="guide.title" :title="guide.title" :src="guide.img" />
          <span class="mb-1 text-xl font-bold">{{ guide.title }}</span>
          <p class="mb-8 max-w-[20ch] text-center">{{ guide.description }}</p>
          <button class="group-hover:layer-2 rounded-2xl border border-current px-6 py-2 transition-all">
            Explore {{ guide.title }}
          </button>
        </a>
      </div>
    </div>
  </section>
</template>
