<script setup lang="ts">
import { useContentPublisherStore } from '@/components-v2/content-publisher/_store'
import { computed, ref, watch } from 'vue'
import { format, getDayOfYear, isFuture, isSameDay, isToday } from 'date-fns'
import { addGridRowProperty, toPostCalendarEvent } from '@/components-v2/content-publisher/_helpers'
import ContentPublisherEvent from '@/components-v2/content-publisher/ContentPublisherEvent.vue'
import { useRouter } from 'vue-router'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import FullCalendar from '@fullcalendar/vue3'
import { useCalenderState } from '@/Hooks/calendar/useCalenderState'
import { watchImmediate } from '@vueuse/core'
import { Button } from '@/components/ui/button'
import ContentPublisherPostDialog from '@/components-v2/content-publisher/ContentPublisherPostDialog.vue'
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area'
import IconSaxCalendarAdd from '@/components/Icons/iconsax/IconSaxCalendarAdd.vue'

const contentPublisherStore = useContentPublisherStore()

// const posts = computed(() => {
//
//   if (!props.date) return []
//
//   const timezoneOffsetInMs = new Date().getTimezoneOffset() * 60 * 1000
//   const currentDate = new Date(props.date).getTime() + timezoneOffsetInMs
//   const events = contentPublisherStore.posts
//     .map(toPostCalendarEvent)
//     .filter((event) => isSameDay(new Date(event.date), currentDate))
//
//   return addGridRowProperty(events)
// })

const router = useRouter()

const { data, start, end, days, next, prev } = useCalenderState(new Date(), {
  getData: async (start, end) => {
    await contentPublisherStore.fetchByDateRange(start, end)
    const events = contentPublisherStore.posts.map(toPostCalendarEvent)
    return addGridRowProperty(events)
  },
})

watch(
  () => contentPublisherStore.posts,
  () => {
    const events = contentPublisherStore.posts.map(toPostCalendarEvent)
    data.value = addGridRowProperty(events)
  }
)

watchImmediate(
  () => days.value,
  async (calendarData) => {
    console.log('days', calendarData)
  }
)

const emit = defineEmits<{
  (name: 'schedule', props: { postDate: string }): void
}>()

const openContentPublisherInNewTab = (id: string) => {
  window.open(router.resolve({ name: dashboardRouteNames.contentPublisher.root, query: { post: id } }).href, '_blank')
}

const managePostId = ref<string | null>(null)

const handleDayClick = (arg: { date: Date }) => {
  console.log('day click', arg)
  if (isFuture(arg.date)) {
    emit('schedule', { postDate: arg.date.toISOString() })
  }
}
</script>

<template>
  <div class="flex h-full flex-col gap-2 sm:hidden">
    <div class="flex flex-row items-center justify-between gap-2">
      <Button variant="primary" @click="prev">Prev</Button>
      <span>{{ format(start, 'MMM d') }} / {{ format(end, 'MMM d') }}</span>
      <Button variant="primary" @click="next">Next</Button>
    </div>
    <div class="grid auto-rows-fr grid-cols-1 gap-1">
      <div v-for="day in days" :key="day.name" class="relative flex min-h-[5.25rem] w-full flex-row gap-2 pl-6">
        <ScrollArea
          class="scroll-shadow w-full flex-grow rounded-sm border"
          :class="{
            'border-brand-state-hover-border bg-brand-state-hover-bg': isSameDay(day.date, new Date()),
            'bg-surface-panel-100': isFuture(day.date),
            'border-none bg-surface-bg': !isFuture(day.date) && !isSameDay(day.date, new Date()),
          }"
        >
          <div class="flex flex-row gap-2" @click.self="handleDayClick({ date: day.date })">
            <ContentPublisherEvent
              class="max-w-[60vw]"
              v-for="post in day.items"
              :key="post.id"
              :event-content="{ event: post }"
              direction="horizontal"
              @click="managePostId = post.id"
            />
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
        <span
          v-if="(isFuture(day.date) || isToday(day.date)) && day.items.length === 0"
          class="fc-daygrid-day-overlay absolute inset-6"
          @click="handleDayClick({ date: day.date })"
        >
          <span
            class="user-schedule-help-text flex h-full w-full flex-col items-center justify-center gap-1 text-center text-xs"
          >
            <IconSaxCalendarAdd />
            <span class="hidden md:inline">Schedule a video</span>
          </span>
        </span>
        <div
          class="absolute inset-y-0 -left-2.5 flex w-7 items-center justify-center text-center text-sm font-semibold text-brand-state-text-placeholder"
        >
          <span
            :class="{
              'font-bold text-sky-800': isSameDay(day.date, new Date()),
              'text-brand-state-text-primary': isFuture(day.date),
            }"
            >{{ format(day.date, 'EE d') }}</span
          >
        </div>
      </div>
    </div>
    <!--    <div v-if="posts.length === 0">-->
    <!--      <p class="text-center text-brand-state-text-placeholder">No posts scheduled for this day</p>-->
    <!--    </div>-->
    <ContentPublisherPostDialog v-model="managePostId" />
  </div>
</template>

<style scoped lang="scss"></style>
