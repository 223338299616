<script setup lang="ts">
import { useUserTemplatesStore, type Template } from '@/store/user/userTemplates'
import templateService from '@/services/templateService'
import { ref } from 'vue'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import { useHead } from '@unhead/vue'
import NoContentSection from '@/components-v2/data-display/NoContentSection.vue'
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import { useConfirmDialog } from '@/components/Dialog/Confirm/useConfirmDialog'

const userTemplatesStore = useUserTemplatesStore()

const dialog = useConfirmDialog()
const isRemoving = ref<string>()

async function revealConfirmDialog(template: Template) {
  isRemoving.value = template.id
  await dialog.reveal({
    title: `Are you sure you want to remove this template?`,
    message: 'This action cannot be undone.',
  })
}

dialog.onConfirm(async () => {
  await templateService.deleteTemplate(isRemoving.value)
  isRemoving.value = undefined
})

dialog.onCancel(() => {
  isRemoving.value = undefined
})

useHead({
  title: 'My Templates',
  meta: [
    {
      name: 'description',
      content: 'Create and manage your templates',
    },
  ],
})
</script>

<template>
  <main class="flex flex-col gap-4">
    <div class="flex flex-row flex-wrap items-end justify-between">
      <header class="flex flex-col">
        <h1 class="text-3xl lg:text-5xl">My Templates</h1>
      </header>
    </div>

    <TransitionGroup
      v-if="userTemplatesStore.savedTemplates.length > 0"
      appear
      name="grid"
      tag="ol"
      class="grid grid-cols-1 gap-4"
    >
      <li v-for="template in userTemplatesStore.savedTemplates" :key="template.id">
        <article class="layer-2 flex flex-col gap-4 rounded-xl px-6 py-4">
          <div class="grid grid-cols-2 items-center gap-1 lg:grid-cols-3">
            <div class="col-span-2 flex items-center gap-4 lg:col-span-1">
              <h3 class="m-0 p-0 text-lg font-normal">
                {{ template.templateName }}
              </h3>
            </div>

            <span class="lg:place-self-center">
              <span class="rounded bg-zinc-200 px-2 py-1 dark:bg-zinc-800">
                {{ template.displayName }}
              </span>
            </span>

            <div class="justify-self-end">
              <button
                :disabled="isRemoving === template.id"
                @click="revealConfirmDialog(template)"
                data-tip="Remove template"
                class="tooltip tooltip-left flex h-12 w-12 flex-col items-center justify-center rounded-xl text-red-400 transition-all hover:bg-zinc-100 active:scale-90 dark:hover:bg-zinc-800"
              >
                <span v-if="isRemoving === template.id" class="spinner spinner-border !h-6 !w-6 border-2" />
                <IconSaxTrash v-else />
              </button>
            </div>
          </div>
        </article>
      </li>
    </TransitionGroup>

    <NoContentSection v-else title="You don't have any saved Templates" subtitle="Read here how to save time by using Templates">
      <a href="https://streamladder.com/user-guide/how-to-create-a-template" target="_blank">
        <GradientButton>
          Learn about Templates
        </GradientButton>
      </a>
    </NoContentSection>
  </main>
</template>

<style scoped lang="scss"></style>
