<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
    <rect width="64" height="64" rx="32" fill="url(#paint0_radial_810_1711)" />
    <rect width="64" height="64" rx="32" fill="url(#paint1_radial_810_1711)" />
    <path
      d="M23.18 11H40.82C47.54 11 53 16.46 53 23.18V40.82C53 44.0503 51.7168 47.1484 49.4326 49.4326C47.1484 51.7168 44.0503 53 40.82 53H23.18C16.46 53 11 47.54 11 40.82V23.18C11 19.9497 12.2832 16.8516 14.5674 14.5674C16.8516 12.2832 19.9497 11 23.18 11ZM22.76 15.2C20.755 15.2 18.832 15.9965 17.4143 17.4143C15.9965 18.832 15.2 20.755 15.2 22.76V41.24C15.2 45.419 18.581 48.8 22.76 48.8H41.24C43.245 48.8 45.168 48.0035 46.5857 46.5857C48.0035 45.168 48.8 43.245 48.8 41.24V22.76C48.8 18.581 45.419 15.2 41.24 15.2H22.76ZM43.025 18.35C43.7212 18.35 44.3889 18.6266 44.8812 19.1188C45.3734 19.6111 45.65 20.2788 45.65 20.975C45.65 21.6712 45.3734 22.3389 44.8812 22.8312C44.3889 23.3234 43.7212 23.6 43.025 23.6C42.3288 23.6 41.6611 23.3234 41.1688 22.8312C40.6766 22.3389 40.4 21.6712 40.4 20.975C40.4 20.2788 40.6766 19.6111 41.1688 19.1188C41.6611 18.6266 42.3288 18.35 43.025 18.35ZM32 21.5C34.7848 21.5 37.4555 22.6062 39.4246 24.5754C41.3938 26.5445 42.5 29.2152 42.5 32C42.5 34.7848 41.3938 37.4555 39.4246 39.4246C37.4555 41.3938 34.7848 42.5 32 42.5C29.2152 42.5 26.5445 41.3938 24.5754 39.4246C22.6062 37.4555 21.5 34.7848 21.5 32C21.5 29.2152 22.6062 26.5445 24.5754 24.5754C26.5445 22.6062 29.2152 21.5 32 21.5ZM32 25.7C30.3291 25.7 28.7267 26.3637 27.5452 27.5452C26.3637 28.7267 25.7 30.3291 25.7 32C25.7 33.6709 26.3637 35.2733 27.5452 36.4548C28.7267 37.6363 30.3291 38.3 32 38.3C33.6709 38.3 35.2733 37.6363 36.4548 36.4548C37.6363 35.2733 38.3 33.6709 38.3 32C38.3 30.3291 37.6363 28.7267 36.4548 27.5452C35.2733 26.3637 33.6709 25.7 32 25.7Z"
      fill="white"
    />
    <defs>
      <radialGradient
        id="paint0_radial_810_1711"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(54.08 72.64) rotate(-115.776) scale(87.2757 129.813)"
      >
        <stop offset="0.24392" stop-color="#FF1B90" />
        <stop offset="0.436673" stop-color="#F80261" />
        <stop offset="0.688476" stop-color="#ED00C0" />
        <stop offset="0.776787" stop-color="#C500E9" />
        <stop offset="0.893155" stop-color="#7017FF" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_810_1711"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(23.2 61.792) rotate(-57.4396) scale(37.8157 39.3338)"
      >
        <stop stop-color="#FFD600" />
        <stop offset="0.484375" stop-color="#FF6930" />
        <stop offset="0.734375" stop-color="#FE3B36" />
        <stop offset="1" stop-color="#FE3B36" stop-opacity="0" />
      </radialGradient>
    </defs>
  </svg>
</template>
