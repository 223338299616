<script setup lang="ts">

import { ref, watch } from 'vue'
import type { SurveyQuestion } from 'posthog-js'

const props = defineProps<{
  question: SurveyQuestion
}>();

const emit = defineEmits<{
  (event: 'answered', value: string | null): void
  (event: 'invalidAnswer'): void
}>();

const textareaRef = ref<HTMLTextAreaElement | null>(null);

watch(() => props.question, () => {

  emit('answered', null);
  emit('invalidAnswer');

  if (textareaRef.value) {
    textareaRef.value.value = '';
  }
}, { immediate: true });

const onInput = (event: Event) => {
  const target = event.target as HTMLTextAreaElement;
  if (target.value.length > 3) {
    emit('answered', target.value);
  } else {
    emit('invalidAnswer');
  }
};
</script>

<template>
  <textarea
    ref="textareaRef"
    @input="onInput"
    class="w-full sm:w-3/4 min-h-[100px] max-h-[800px] p-4 rounded-lg border"
    maxlength="300"
    placeholder="Type your answer here..."
  />
</template>

<style scoped>

</style>