<script setup lang="ts">
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import './index.css'
import { useIsMobile } from '@/Hooks/useIsMobile'
import IndexMobile from '@/areas/editor/IndexMobile.vue'
import IndexDesktop from '@/areas/editor/IndexDesktop.vue'
import { useRouter } from 'vue-router'
import { editorRouteNames } from '@/areas/editor/routeNames'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import { usePresets } from '@/areas/editor/@data/layouts'

// Ensure history store is initialized before any other store
useHistoryStore()

const isMobile = useIsMobile()
const { resetEditorStep } = useEditorStep()
const { resetFetchStatus } = usePresets()

const router = useRouter()
router.beforeEach((to, from, next) => {
  if (to.name === editorRouteNames.root) {
    next()
  } else {
    resetEditorStep()
    resetFetchStatus()
    next()
  }
})
</script>

<template>
  <template v-if="isMobile">
    <IndexMobile />
  </template>
  <template v-else>
    <IndexDesktop />
  </template>
</template>

<style scoped lang="scss">

</style>
