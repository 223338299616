<script setup lang="ts">
import { useTimelineStore } from '@/modules/SLTimeline/useTimelineStore'
import { useEditorVideoStore } from '@/store/editor/editorVideo'

const { zoomLevel, zoomToLevel } = useTimelineStore()
const editorVideo = useEditorVideoStore()

const onZoom = (e: InputEvent) => {
  const zoomLevel = 1 - (e.target as HTMLInputElement).value
  zoomToLevel(Number(zoomLevel), editorVideo.getExactTime())
}
</script>

<template>
  <input
    type="range"
    :value="1 - zoomLevel"
    @input="onZoom"
    min="0"
    max="1"
    step=".025"
    class="zoom-range hidden h-[3px] w-12 cursor-pointer md:block"
  />
</template>

<style scoped>
.zoom-range {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  border: none;
  border-radius: 3px;
  background: #d3d3d3;
}
</style>
