<script setup lang="ts">
import LottieAnimation from '@/components/LottieAnimation.vue'
import { Button } from '@/components/ui/button'
import { onMounted } from 'vue'
import { posthog } from 'posthog-js'

const emit = defineEmits<{
  (event: 'next', hasDeclined?: boolean): void
  (event: 'cancel'): void
}>()

onMounted(async () => {
  posthog.capture('Free Month Offered');
});
</script>

<template>
  <div class="relative flex flex-col sm:flex-row gap-2">

    <div class="w-full sm:w-1/3 bg-gradient flex items-center justify-center">
      <LottieAnimation :loop="false" url="/lottie/gift.json" class="w-64 h-18 sm:h-64" />
    </div>

    <div class="w-full p-4 sm:pt-0 sm:w-2/3 flex flex-col items-center justify-center">
      <div class="max-w-md gap-8 flex flex-col items-center">
        <div class="flex flex-col gap-2">
          <h3 class="text-center text-3xl lg:text-4xl w-full">
            100% off for the next month?
          </h3>
          <p class="font-extralight text-center">
            We've been listening to feedback and making improvements every day. As a thank you, enjoy your next month on us! We're confident you'll love the changes.
          </p>
        </div>
        <div class="flex flex-col gap-2 w-1/2">
          <Button variant="primary" @click="emit('next', false)">
            Yes, please!
          </Button>
          <Button variant="ghost" @click="emit('next', true)">
            No, cancel my subscription
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>