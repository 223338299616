const nbsp = '\u00A0'

const tips = [
  `Remember, just one viral clip here can skyrocket your streaming fame. Let's create it!${nbsp}🔥`,
  `Remember to have fun! If you're having fun, your viewers will too.${nbsp}🎉`,
  `Engage with your audience—building a community is just as important as great content!${nbsp}💬`,
  `Consistency is key. Keep streaming, and the growth will follow!${nbsp}⏰`,
  `Don't be afraid to experiment, sometimes the unexpected becomes a hit!${nbsp}🚀`,
  `Use your unique personality to stand out, there’s only one you!${nbsp}🌟`,
  `Celebrate small wins. Every follower counts!${nbsp}🎊`,
  `Stay positive, even when the numbers are low. Your passion will shine through.${nbsp}💪`,
  `Keep an eye on trends, but always stay true to your style.${nbsp}🎯`,
  `Interacting with your viewers makes them feel valued, shout them out!${nbsp}📢`,
];

export function selectRandomTip() {
  return tips[Math.floor(Math.random() * tips.length)]
}
