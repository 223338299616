<script setup lang="ts">
import { useEditorFocusStore, FocusTypes } from '@/store/editor/editorFocus'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import TimeLineTrackNode from '@/modules/SLTimeline/TimeLineTrackNode.vue'
import TimeLineTrack from '@/modules/SLTimeline/TimeLineTrack.vue'
import { computed } from 'vue'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { useCurrentCaption } from '@/areas/editor/pages/captions/useCurrentCaption'
import type { StoredCaption, StoredWord } from '@/components/Captions/captionTypes'
import { useStripPunctuation } from '@/components/Captions/useStripPunctuation'

const editorCaptionStore = useEditorCaptionsStore()
const editorFocusStore = useEditorFocusStore()
const videoStore = useVideoStore()
const segmentsStore = useSegmentsStore()

function findSegmentByTimings(start: number, end: number) {
  return segmentsStore.entities.find((segment) => {
    return end >= segment.startMs && start <= segment.endMs
  })
}

const captionsWithInSegment = computed(() => {
  return editorCaptionStore.captions.map((caption) => ({
    ...caption,
    segmentId: findSegmentByTimings(caption.start, caption.end)?.id,
    words: caption.words.map(w => ({
      ...w,
      segmentId: findSegmentByTimings(w.start, w.end)?.id
    }))
  }))
})

const historyStore = useHistoryStore()
function minStartMs(captionIndex: number, wordIndex: number) {
  if (wordIndex > 0) {
    return editorCaptionStore.captions[captionIndex].words[wordIndex - 1].end
  } else {
    return editorCaptionStore.captions[captionIndex - 1]?.end ?? 0
  }
}

function maxEndMs(captionIndex: number, wordIndex: number) {
  if (wordIndex < editorCaptionStore.captions[captionIndex].words.length - 1) {
    return editorCaptionStore.captions[captionIndex].words[wordIndex + 1].start
  } else {
    return editorCaptionStore.captions[captionIndex + 1]?.start ?? videoStore._duration * 1000
  }
}

function updateWordTimings(captionId: string, wordIndex: number, { start, end }: { start: number; end: number }) {
  const caption = editorCaptionStore.captions.find((c) => c.id === captionId)
  if (caption) {
    const words = caption.words.map((w, i) => (i === wordIndex ? { ...w, start, end } : w))
    editorCaptionStore.updateCaption(caption.id, {
      ...caption,
      start: Math.min(...words.map((w) => w.start)),
      end: Math.max(...words.map((w) => w.end)),
      words: words,
    })
    historyStore.push()
  }
}

const { currentWordId } = useCurrentCaption()
const isCurrentWord = computed(() => (word: StoredWord) => word.id === currentWordId.value)

function focus(caption: StoredCaption, word: StoredWord) {
  editorFocusStore.setFocus(FocusTypes.CAPTION, caption.id)
  videoStore._currentTime = (word.start + 0.5 * (word.end - word.start)) / 1000 + 0.01
  setTimeout(() => {
    editorFocusStore.setFocus(FocusTypes.CAPTION, caption.id)
  }, 100)
}

const strip = useStripPunctuation()
</script>

<template>
  <TimeLineTrack class="relative h-full min-h-6 max-h-12 w-full" v-if="captionsWithInSegment.length > 0">
    <template v-for="(caption, captionIndex) in captionsWithInSegment" :key="caption.id">
      <TimeLineTrackNode
        class="group cursor-grab rounded-lg h-full z-10 border-2 ring-transparent transition-colors duration-75"
        :class="[
          caption.segmentId ? 'border-yellow-400 bg-yellow-400 hover:border-yellow-600' : 'border-gray-300 bg-gray-300 dark:bg-gray-600 dark:border-gray-600 hover:border-gray-600 hover:dark:border-gray-300',
          isCurrentWord(word) ? '!border-company-primary-200' : ''
        ]"
        v-for="(word, wordIndex) in caption.words"
        :key="word.id"
        :start-ms="word.start"
        :end-ms="word.end"
        :min-start-ms="minStartMs(captionIndex, wordIndex)"
        :max-end-ms="maxEndMs(captionIndex, wordIndex)"
        seek-behavior="seek"
        :active="editorFocusStore.focus?.type === 'caption' && editorFocusStore.focus?.key === caption.id && isCurrentWord(word)"
        @resizeStop="(start, end) => updateWordTimings(caption.id, wordIndex, { start, end })"
        @dragStop="(start, end) => updateWordTimings(caption.id, wordIndex, { start, end })"
        @activated="focus(caption, word)"
      >
          <div class="grid h-full w-full place-items-center overflow-hidden rounded-lg text-sm text-gray-950">
            <span class="truncate text-center -mx-1/2">{{ strip(word) }}</span>
          </div>
          <template #handles>
            <div class="flex h-full flex-row gap-0.5 px-1 py-1">
              <div class="h-full w-1 rounded" :class="caption.segmentId ? 'bg-yellow-600' : 'bg-gray-600 dark:bg-gray-400'" />
              <div class="h-full w-1 rounded" :class="caption.segmentId ? 'bg-yellow-600' : 'bg-gray-600 dark:bg-gray-400'" />
            </div>
          </template>
        </TimeLineTrackNode>
    </template>
  </TimeLineTrack>
</template>
