import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { useLocalStorage } from '@vueuse/core'

export function useDebugModeEnabled() {
  return useLocalStorage('dos', false)
}

export const useDebug = () => {
  const route = useRoute()
  const dos = useDebugModeEnabled()
  return computed(() => {
    const enabled = ((import.meta.env.DEV && dos.value) || dos.value)
    return enabled && !['StickerRender', 'VideoRender', 'ScreenshotPage'].includes(route.name as string)
  })
}
