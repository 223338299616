import type { Area, Bounds, Snap, Size, Directions, Point } from '@/modules/SLMovable/@types/Movable'
import { resizeAspectLockedFromCenter } from '@/modules/SLMovable/helpers/resize/resizeAspectLockedFromCenter'
import { resizeAspectLocked } from '@/modules/SLMovable/helpers/resize/resizeAspectLocked'
import { resizeFromCenter } from '@/modules/SLMovable/helpers/resize/resizeFromCenter'
import { resize } from '@/modules/SLMovable/helpers/resize/resize'

/**
 * Automatically resize an area based on the given configuration. The configuration can be used to lock the aspect
 * ratio, resize from the center, and provide bounds and snap settings.
 */
export function resizeAutomatically(from: Area, to: Point, directions: Directions, config: AutomaticResizeConfig) {
  if (config.aspectRatio && config.centerOrigin) {
    return resizeAspectLockedFromCenter(from, to, directions, config.bounds, config.aspectRatio, config.minSize)
  } else if (config.aspectRatio) {
    return resizeAspectLocked(from, to, directions, config.bounds, config.aspectRatio, config.minSize)
  } else if (config.centerOrigin) {
    return resizeFromCenter(from, to, directions, config.bounds, config.minSize)
  } else {
    return resize(from, to, directions, config.bounds, config.minSize)
  }
}

type AutomaticResizeConfig = {
  centerOrigin: boolean
  aspectRatio: Size | null
  bounds: Bounds | null
  snap: Snap | null
  minSize: Size | null
}
