<template>
  <div class="flex flex-row gap-2 px-4">
    <ScrollArea>
      <button
        v-for="tag in tags"
        class="btn-ghost btn-sm btn rounded capitalize"
        :class="{
          'btn-info': tag === selectedTag,
          'font-normal': tag !== selectedTag,
        }"
        @click="selectedTag = tag"
        :key="tag"
      >
        {{ tag }}
      </button>
    </ScrollArea>
  </div>
  <div
    id="captions-container"
    class="grid gap-2 p-4"
    :class="
      hasThreeColumnLayout
        ? 'grid-cols-2 lg:grid-cols-[repeat(auto-fill,_minmax(150px,_1fr))]'
        : 'grid-cols-2 md:grid-cols-3'
    "
  >
    <div v-for="(captionStyle, index) in styles" :key="captionStyle.type + index" class="relative">
      <CaptionStylePreview
        show-tier-badge
        @click="emit('generate-captions', captionStyle.type)"
        :settings="captionStyle"
        :selected="captionStyle.type === captionEditorStore.captionStyle"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import CaptionStylePreview from '@/components/Captions/CaptionStylePreview.vue'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { computed, ref } from 'vue'
import { captionStyleSettingsArray } from '@/components/Captions/styles/CaptionStyleManager'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { intersection } from 'lodash-es'
import { isBefore } from 'date-fns'
import { getLastUsedCaptionStyle } from '@/components/Captions/useLocalCaptionSettings'
import {ScrollArea} from "@/components/ui/scroll-area";

const hasThreeColumnLayout = useFeatureFlagVariantEnabled('three-column-layout', 'wide')

const captionEditorStore = useEditorCaptionsStore()

const emit = defineEmits<{
  (event: 'generate-captions', captionType: string): void
}>()

const tags = ['all', 'popular', 'playful', 'simple']

const selectedTag = ref('all')

const defaultCaptionStyle = ref(getLastUsedCaptionStyle())

const styles = computed(() => {
  const sortedArray = captionStyleSettingsArray
    .map((a) => {
      return {
        ...a,
        createdAt: a.createdAt || new Date(0),
      }
    })
    .sort((a, b) => {
      // if is defaultcaptionstyle, sort it to the top
      if (a.type === defaultCaptionStyle.value) return -1
      if (b.type === defaultCaptionStyle.value) return 1
      // if has tag seasonal, sort it to the bottom
      if (intersection(a?.tags, ['christmas', 'halloween']).length > 0) return 1
      if (intersection(b?.tags, ['christmas', 'halloween']).length > 0) return -1
      return isBefore(a.createdAt, b.createdAt) ? 1 : -1
    })
  if (selectedTag.value === 'all') return sortedArray
  if (selectedTag.value === 'seasonal')
    return sortedArray.filter((style) => intersection(style?.tags, ['christmas', 'halloween']).length > 0)
  return sortedArray.filter((style) => style?.tags?.includes(selectedTag.value))
})
</script>
