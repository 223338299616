import type { Area } from '@/modules/SLMovable/@types/Movable'
import type { Ref, MaybeRefOrGetter } from 'vue'
import { ref, watch, computed, toValue } from 'vue'
import { useElementSize } from '@vueuse/core'
import { useBoundingClientRect } from '@/Hooks/useBoundingClientRect'
import { useWorkspaceBoundingContext } from '@/areas/editor/context/workspaceSize'
import { clamp } from 'lodash-es'

export function useToolbarPosition(area: Ref<Area>, container: Ref<HTMLElement | null>, offset: MaybeRefOrGetter<number>) {

  const offsetRef = computed(() => toValue(offset))

  const element = ref<HTMLElement | null>(null)
  const { width, height } = useElementSize(element)

  const { x: canvasX, y: canvasY, width: canvasWidth, height: canvasHeight, forceUpdate: updateEditorPosition } = useBoundingClientRect(container)!

  const { x: workspaceX, y: workspaceY, width: workspaceWidth, height: workspaceHeight, scrollX, scrollY } = useWorkspaceBoundingContext()!
  watch([workspaceX, workspaceY, workspaceWidth, workspaceHeight, scrollX, scrollY], updateEditorPosition)

  const top = computed(() => {

    const topValue = (area.value.y + area.value.height) * canvasHeight.value + canvasY.value
    const topMin = workspaceY.value + offsetRef.value
    const topMax = workspaceY.value + workspaceHeight.value - height.value - 2 * offsetRef.value - 12

    if (topValue < topMin) {
      return topMin
    } else if (topValue > topMax) {
      return (area.value.y * canvasHeight.value) + canvasY.value - height.value - 2 * offsetRef.value - 12
    } else {
      return topValue
    }
  })

  const left = computed(() => {

    const leftValue = (area.value.x + 0.5 * area.value.width) * canvasWidth.value + canvasX.value
    const leftMin = workspaceX.value + offsetRef.value + 0.5 * width.value
    const leftMax = workspaceX.value + workspaceWidth.value - 0.5 * width.value - offsetRef.value

    return clamp(leftValue, leftMin, leftMax)
  })
  
  return { top, left, width, height, element }
}
