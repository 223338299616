import { type Ref, ref } from 'vue'
import { useIntersectionObserver } from '@vueuse/core'

export function useIsIntersecting<T extends HTMLElement | undefined>(target: Ref<T>): Ref<boolean> {
  const isIntersecting = ref(false)
  useIntersectionObserver(target, ([entry]) => {
    isIntersecting.value = entry.isIntersecting
  })
  return isIntersecting
}
