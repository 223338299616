<template>
  <div class="mb-4 flex w-full flex-col">
    <span class="mb-2 text-company-primary">{{ label }}</span>
    <div class="relative flex w-full">
      <input
        :type="type"
        :value="modelValue"
        @input="(e) => $emit('update:modelValue', e.target.value)"
        :class="errors === '' && !hasError ? 'border-company-primary-300' : 'focus:error-focus border-red-600'"
        :placeholder="placeholder"
        :readOnly="readOnly"
        class="focus:form-focus w-full rounded border px-4 py-2 tracking-normal placeholder-company-primary placeholder-opacity-50"
      />
      <div
        :class="errors === '' ? 'bg-company-primary-300' : 'bg-red-600'"
        class="input-progression-bar absolute bottom-0 rounded"
        :style="`width: ${progressionPercentage}%`"
      >
        &nbsp;
      </div>
    </div>
    <p class="mb-0 mt-1 text-red-600" v-if="errors">{{ errors }}</p>
  </div>
</template>
<script>
export default {
  props: {
    modelValue: { default: null },
    errors: { type: String, default: '' },
    label: { type: String, default: null },
    type: { type: String, default: 'text' },
    hasError: { type: Boolean, default: false },
    placeholder: { type: String, default: null },
    readOnly: { type: Boolean, default: false },
    progressionPercentage: { type: Number, default: 0 },
  },
}
</script>

<style scoped>
.input-progression-bar {
  height: 4px;
}
</style>
