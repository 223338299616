<template>
  <div class="flex min-h-screen flex-col">
    <div class="hero flex-1">
      <div class="hero-content text-center">
        <div class="max-w-md">
          <h1 class="text-5xl font-bold text-primary">Page not found</h1>
          <p class="py-6">Uh oh, somehow you ended up here.</p>
          <router-link :to="{ name: 'Home' }" class="btn-primary btn">Back to home </router-link>
        </div>
      </div>
    </div>
    <FooterApplication />
  </div>
</template>

<script>
import FooterApplication from '@/components-v2/navigation/FooterApplication.vue'

export default {
  components: { FooterApplication },
  head() {
    return {
      title: 'Page not found',
      bodyAttrs: {
        class: 'bg-base-100',
      },
    }
  },
}
</script>
