import { createInjectionState } from '@vueuse/core'
import { type Ref, type ComputedRef, ref, watch, nextTick } from 'vue'
import type { Area } from '@/modules/SLMovable/@types/Movable'
import { useBoundingClientRect } from '@/Hooks/useBoundingClientRect'

export const [createMovableContext, useMovableContext] = createInjectionState(
  (container: Ref<HTMLElement | null>, config: MovableConfig) => {

    const { 
      x, y, width, height,
      top, left,
      forceUpdate 
    } = useBoundingClientRect(container)

    const blackout = ref<boolean>(!!config.blackout.value)

    watch(config.blackout, (next, prev) => {
      // If blackout becomes enabled, we need to wait a tick before masks can be rendered, due to limitations of the
      // Vue Teleport API
      if (!!next && !prev) {
        nextTick(() => {
          blackout.value = Boolean(next)
        })
      } else if (!next) {
        blackout.value = Boolean(next)
      }
    })

    return {
      container: container,
      resize: () => forceUpdate(),
      areas: ref(new Map<string, Ref<Area>>()),

      x: x,
      y: y,
      width: width,
      height: height,
      
      top: top,
      left: left,

      snapGridId: config.snapGridId,
      maskId: config.blackout,
      blackout: blackout,
    }
  }
)

type MovableConfig = {
  blackout: ComputedRef<string | null>
  snapGridId: string
}
