<script lang="ts" setup>
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import type { CaptionGrouping } from '@/components/Captions/captionTypes'
import { type Component, markRaw, onMounted, onUnmounted, ref } from 'vue'
import TopEmoji from '@/components/Icons/Normalized/EmojiPlacement/TopEmoji.vue'
import BottomEmoji from '@/components/Icons/Normalized/EmojiPlacement/BottomEmoji.vue'
import { watchThrottled } from '@vueuse/core'
import { Button } from '@/components/ui/button'
import ButtonRadioGroup from '@/components-v2/data-input/ButtonRadioGroup.vue'
import LightSwitch from '@/components-v2/data-input/boolean/LightSwitch.vue'
import tinyColor from 'tinycolor2'
import IconSaxArrowLeft from '@/components/Icons/iconsax/IconSaxArrowLeft.vue'
import { v4 } from 'uuid'
import { useEditorMainStore } from '@/store/editor/editorMain'

const topIcon: Component = markRaw(TopEmoji)
const bottomIcon: Component = markRaw(BottomEmoji)

const captionEditorStore = useEditorCaptionsStore()
const mainStore = useEditorMainStore()

const showDebugColorSelector = ref(false)
const baseColor = ref('#FFFFFF')
watchThrottled(
  baseColor,
  (color) => {
    captionEditorStore.styleOptions.data.baseColor = color
  },
  { throttle: 700 }
)

const handleChangeGrouping = async (value: CaptionGrouping) => {
  captionEditorStore.baseOptions.grouping = value
  if (value === 'group') {
    setGroupedPreview()
  } else {
    setSinglePreview()
  }
}

const setGroupedPreview = () => {
  const previewCaptionDuration = 2000
  const videoDuration = mainStore.videoDuration
  const count = Math.ceil(videoDuration / previewCaptionDuration)

  const previewCaptionTemplate = {
    text: 'See the Magic unfold!',
    confidence: 1,
    color: captionEditorStore.styleOptions.data.baseColor,
    randomizer: Math.random(),
    emojis: ['✨'],
    words: [
      {
        text: 'See',
        start: 0,
        end: 600,
        Highlighted: false,
        speaker: 'A',
        confidence: 1,
      },
      {
        text: 'the',

        start: 601,
        end: 1200,
        Highlighted: false,
        speaker: 'A',
        confidence: 1,
      },
      {
        text: 'Magic',

        start: 1201,
        end: 1800,
        Highlighted: true,
        speaker: 'A',
        confidence: 1,
      },
      {
        text: 'unfold!',

        start: 1801,
        end: 2000,
        Highlighted: false,
        speaker: 'A',
        confidence: 1,
      },
    ],
  }

  captionEditorStore.captionOverride = Array.from({ length: count }, (_, i) => ({
    ...previewCaptionTemplate,
    id: v4(),
    start: i * previewCaptionDuration,
    end: i < count - 1 ? (i + 1) * previewCaptionDuration : videoDuration,
    words: previewCaptionTemplate.words.map((word) => ({
      ...word,
      start: word.start + i * previewCaptionDuration,
      end: i < count - 1 ? word.end + i * previewCaptionDuration : videoDuration,
    })),
  }))
}

const setSinglePreview = () => {
  const previewCaptionDuration = 2000
  const videoDuration = mainStore.videoDuration
  const count = Math.ceil(videoDuration / previewCaptionDuration)

  const previewCaptionTemplate = [
    {
      text: 'See',
      confidence: 1,
      color: captionEditorStore.styleOptions.data.baseColor,
      randomizer: Math.random(),
      emojis: [],
      start: 0,
      end: 600,
      words: [
        {
          confidence: 1,
          start: 0,
          end: 600,
          text: 'See',
          Highlighted: false,
          speaker: 'A',
        },
      ],
    },
    {
      text: 'the',
      confidence: 1,
      color: captionEditorStore.styleOptions.data.baseColor,
      randomizer: Math.random(),
      emojis: [''],
      start: 601,
      end: 1200,
      words: [
        {
          confidence: 1,
          start: 601,
          end: 1200,
          text: 'the',
          Highlighted: false,
          speaker: 'A',
        },
      ],
    },
    {
      text: 'Magic',
      confidence: 1,
      color: captionEditorStore.styleOptions.data.baseColor,
      randomizer: Math.random(),
      emojis: ['✨'],
      start: 1201,
      end: 1800,
      words: [
        {
          confidence: 1,
          start: 1201,
          end: 1800,
          text: 'Magic',
          Highlighted: true,
          speaker: 'A',
        },
      ],
    },
    {
      text: 'unfold!',
      confidence: 1,
      color: captionEditorStore.styleOptions.data.baseColor,
      randomizer: Math.random(),
      emojis: [''],
      start: 1801,
      end: 2000,
      words: [
        {
          confidence: 1,
          start: 1801,
          end: 2000,
          text: 'unfold!',
          Highlighted: false,
          speaker: 'A',
        },
      ],
    },
  ]
  captionEditorStore.captionOverride = Array.from({ length: count }, (_, i) =>
    previewCaptionTemplate.map((sentance, i2) => ({
      ...sentance,
      id: v4(),
      start: sentance.start + i * previewCaptionDuration,
      end:
        sentance.end + i * previewCaptionDuration > videoDuration
          ? videoDuration
          : sentance.end + i * previewCaptionDuration,
      words: sentance.words.map((word) => ({
        ...word,
        start: sentance.start + i * previewCaptionDuration,
        end:
          sentance.end + i * previewCaptionDuration > videoDuration
            ? videoDuration
            : sentance.end + i * previewCaptionDuration,
      })),
    }))
  ).flat()
}

onMounted(() => {
  if (captionEditorStore.baseOptions.grouping === 'group') {
    setGroupedPreview()
  } else {
    setSinglePreview()
  }
})

onUnmounted(() => {
  captionEditorStore.captionOverride = undefined
})

const canEmojify = captionEditorStore.hasFeature('emojis')

const emit = defineEmits<{
  (name: 'back'): void
  (name: 'done'): void
}>()
</script>

<template>
  <div
    class="layer-2 relative flex max-h-full flex-col gap-4 rounded-lg rounded-t-none border-2 border-t-0 border-gray-100 p-4"
  >
    <div class="flex w-full flex-row items-center justify-between">
      <Button variant="ghost" size="lg" @click="() => emit('back')">
        <IconSaxArrowLeft />
      </Button>
      <h2 class="text-xl font-semibold leading-snug">Caption Style</h2>
      <Button variant="primary" size="lg" @click="() => emit('done')">Next</Button>
    </div>
    <div class="flex h-full flex-col gap-4 overflow-auto">
      <div class="flex flex-col gap-2 rounded-xl border-2 border-gray-100 p-4">
        <div>
          <h4 class="font-bold">Size</h4>
          <p class="font-light">Change the base text size</p>
        </div>
        <button-radio-group
          :options="[
            { label: 'Large', value: 'large' },
            { label: 'Medium', value: 'medium' },
            { label: 'Small', value: 'small' },
          ]"
          block
          v-model="captionEditorStore.baseOptions.size"
        />
      </div>

      <div class="flex flex-col gap-2 rounded-xl border-2 border-gray-100 p-4">
        <div>
          <h4 class="font-bold">Caption animation style</h4>
          <p class="font-light">Choose your preferred animation</p>
        </div>
        <button-radio-group
          :options="[
            { label: 'None', value: '' },
            { label: 'Reveal', value: 'reveal' },
            { label: 'Fade', value: 'fade-in' },
            { label: 'Slide Right', value: 'slide-right' },
            { label: 'Slide Top', value: 'slide-top' },
          ]"
          block
          v-model="captionEditorStore.baseOptions.animation"
        />
      </div>

      <div
        v-if="captionEditorStore.baseOptions.grouping !== 'single'"
        class="flex flex-col gap-2 rounded-xl border-2 border-gray-100 p-4"
      >
        <div>
          <h4 class="font-bold">Animate per word or sentence</h4>
          <p class="font-light">Choose which part of the caption you want the animation to apply to</p>
        </div>
        <button-radio-group
          :options="[
            { label: 'Each word', value: 'word' },
            { label: 'Whole sentence', value: 'sentence' },
          ]"
          block
          v-model="captionEditorStore.baseOptions.animationTarget"
        />
      </div>

      <div class="flex flex-col gap-4 rounded-xl border-2 border-gray-100 p-4">
        <div @click.shift="showDebugColorSelector = true">
          <h4 class="font-bold">Color</h4>
          <p class="font-light">Select your preferred color</p>
        </div>
        <div v-if="showDebugColorSelector"><input type="color" v-model="baseColor" />{{ baseColor }}</div>
        <div class="flex flex-row flex-wrap gap-2">
          <button
            v-for="color in captionEditorStore.captionStyleSettings.colors"
            @click="captionEditorStore.styleOptions.data.baseColor = color"
            :key="color"
            class="flex h-9 w-9 items-center justify-center rounded-xl"
            :class="{
              'border-2 border-[#277DFF]': color === captionEditorStore.styleOptions.data?.baseColor,
            }"
          >
            <div
              class="h-5 w-5 rounded-full"
              :style="{
                background: captionEditorStore.captionStyleSettings.gradients?.[color] ?? color,
                borderColor: tinyColor(color).darken(40).toString(),
              }"
              :class="{
                border: tinyColor(color).getBrightness() > 200,
              }"
            ></div>
          </button>
        </div>
      </div>
      <div class="flex flex-col gap-2 rounded-xl border-2 border-gray-100 p-4">
        <label class="flex cursor-pointer justify-between gap-1">
          <div>
            <h4 class="font-bold">AI Keywords highlighter</h4>
            <p class="font-light">Quickly identify important keywords in your text</p>
          </div>
          <input
            v-model="captionEditorStore.baseOptions.highlight"
            type="checkbox"
            class="toggle-primary toggle"
            checked
          />
        </label>
        <div class="flex flex-row flex-wrap gap-2">
          <button
            v-for="color in captionEditorStore.captionStyleSettings.colors"
            @click="captionEditorStore.styleOptions.data.highlightColor = color"
            :key="color"
            class="flex h-9 w-9 items-center justify-center rounded-xl"
            :disabled="!captionEditorStore.baseOptions.highlight"
            :class="{
              'border-2 border-[#277DFF]': color === captionEditorStore.styleOptions.data?.highlightColor,
              'opacity-40': !captionEditorStore.baseOptions.highlight,
            }"
          >
            <div
              class="h-5 w-5 rounded-full"
              :style="{
                background: captionEditorStore.captionStyleSettings.gradients?.[color] ?? color,
                borderColor: tinyColor(color).darken(40).toString(),
              }"
              :class="{
                border: tinyColor(color).getBrightness() > 200,
              }"
            ></div>
          </button>
        </div>
      </div>
      <div class="flex flex-col gap-2 rounded-xl border-2 border-gray-100 p-4">
        <label v-if="canEmojify" class="flex cursor-pointer justify-between gap-1">
          <div>
            <h4 class="font-bold">
              AI emojis 😎
              <span class="badge-primary badge">English only</span>
            </h4>
            <p class="font-light">Automatically add relevant emojis to your captions.</p>
          </div>
          <input
            v-model="captionEditorStore.baseOptions.emojis"
            type="checkbox"
            class="toggle-primary toggle"
            checked
          />
        </label>
        <div v-if="canEmojify" class="mt-4 text-base font-bold leading-tight text-purple-900">Emoji Placement</div>
        <h4 v-else class="font-bold">Emoji Placement</h4>
        <ButtonRadioGroup
          class="self-start"
          :options="[
            { label: bottomIcon, value: 'bottom' },
            { label: topIcon, value: 'top' },
          ]"
          :variant="'box'"
          v-model="captionEditorStore.baseOptions.emojiLocation"
        />
      </div>
      <div class="flex flex-col gap-2 rounded-xl border-2 border-gray-100 p-4">
        <label class="flex cursor-pointer justify-between gap-1">
          <div>
            <h4 class="font-bold">Random rotation</h4>
            <p class="font-light">Rotate captions slightly randomly.</p>
          </div>
          <input
            v-model="captionEditorStore.baseOptions.rotate"
            type="checkbox"
            class="toggle-primary toggle"
            checked
          />
        </label>
      </div>
      <div class="flex flex-col gap-2 rounded-xl border-2 border-gray-100 p-4">
        <div>
          <h4 class="font-bold">Grouping</h4>
          <p class="font-light">Change how many words are shown per caption</p>
        </div>
        <LightSwitch
          :options="[
            { label: 'Single word', value: 'single' },
            { label: 'Group', value: 'group' },
            // { label: 'Sentence', value: 'sentence' },
          ]"
          block
          :value="captionEditorStore.baseOptions.grouping"
          @update:value="handleChangeGrouping"
        />
      </div>
      <div class="flex flex-col gap-2 rounded-xl border-2 border-gray-100 p-4">
        <label class="flex cursor-pointer justify-between gap-1">
          <div>
            <h4 class="font-bold">Remove punctuation</h4>
            <p class="font-light">Remove all ?,!. etc from captions.</p>
          </div>
          <input
            v-model="captionEditorStore.baseOptions.stripPunctuation"
            type="checkbox"
            class="toggle-primary toggle"
            checked
          />
        </label>
      </div>
    </div>
  </div>
</template>
