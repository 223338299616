/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * StreamLadder.Accounts.Api
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/vue-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  QueryKey,
  UseQueryReturnType,
} from '@tanstack/vue-query'
import { unref } from 'vue'
import type { MaybeRef } from '@tanstack/vue-query/build/lib/types'
import type { StringObjectDictionaryApiResponse, ApiError } from '.././model'
import { accountsAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never

export const getApiUsersUserIdPreferences = (
  userId: MaybeRef<string>,
  options?: SecondParameter<typeof accountsAxiosInstance>,
  signal?: AbortSignal
) => {
  return accountsAxiosInstance<StringObjectDictionaryApiResponse>(
    { url: `/api/users/${unref(userId)}/preferences`, method: 'get', signal },
    options
  )
}

export const getGetApiUsersUserIdPreferencesQueryKey = (userId: MaybeRef<string>) =>
  ['api', 'users', userId, 'preferences'] as const

export const getGetApiUsersUserIdPreferencesQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiUsersUserIdPreferences>>,
  TError = ApiError
>(
  userId: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiUsersUserIdPreferences>>, TError, TData>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
): UseQueryOptions<Awaited<ReturnType<typeof getApiUsersUserIdPreferences>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiUsersUserIdPreferencesQueryKey(userId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiUsersUserIdPreferences>>> = ({ signal }) =>
    getApiUsersUserIdPreferences(userId, requestOptions, signal)

  return { queryKey, queryFn, enabled: !!userId, staleTime: 60000, ...queryOptions }
}

export type GetApiUsersUserIdPreferencesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiUsersUserIdPreferences>>
>
export type GetApiUsersUserIdPreferencesQueryError = ApiError

export const useGetApiUsersUserIdPreferences = <
  TData = Awaited<ReturnType<typeof getApiUsersUserIdPreferences>>,
  TError = ApiError
>(
  userId: MaybeRef<string>,
  options?: {
    query?: UseQueryOptions<Awaited<ReturnType<typeof getApiUsersUserIdPreferences>>, TError, TData>
    request?: SecondParameter<typeof accountsAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiUsersUserIdPreferencesQueryOptions(userId, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}

export const putApiUsersUserIdPreferences = (
  userId: MaybeRef<string>,
  putApiUsersUserIdPreferencesBody: MaybeRef<unknown>,
  options?: SecondParameter<typeof accountsAxiosInstance>
) => {
  return accountsAxiosInstance<StringObjectDictionaryApiResponse>(
    {
      url: `/api/users/${unref(userId)}/preferences`,
      method: 'put',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: putApiUsersUserIdPreferencesBody,
    },
    options
  )
}

export const getPutApiUsersUserIdPreferencesMutationOptions = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiUsersUserIdPreferences>>,
    TError,
    { userId: string; data: unknown },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiUsersUserIdPreferences>>,
  TError,
  { userId: string; data: unknown },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiUsersUserIdPreferences>>,
    { userId: string; data: unknown }
  > = (props) => {
    const { userId, data } = props ?? {}

    return putApiUsersUserIdPreferences(userId, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutApiUsersUserIdPreferencesMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiUsersUserIdPreferences>>
>
export type PutApiUsersUserIdPreferencesMutationBody = unknown
export type PutApiUsersUserIdPreferencesMutationError = ApiError

export const usePutApiUsersUserIdPreferences = <TError = ApiError, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiUsersUserIdPreferences>>,
    TError,
    { userId: string; data: unknown },
    TContext
  >
  request?: SecondParameter<typeof accountsAxiosInstance>
}) => {
  const mutationOptions = getPutApiUsersUserIdPreferencesMutationOptions(options)

  return useMutation(mutationOptions)
}
