<script setup lang="ts">
import TwitchIcon from '@/components/Icons/SocialMedia/TwitchIcon.vue'
import YoutubeIcon from '@/components/Icons/SocialMedia/YoutubeIcon.vue'
import socialProof from './social-proof.json'
import { computed } from 'vue'

const props = withDefaults(defineProps<{ variant: 'dense' | 'loose' }>(), {
  variant: 'loose',
})

const users = socialProof.users as User[]
const usersToTake = computed(() => {
  return {
    dense: 5,
    loose: 7,
  }[props.variant]
})

type User = {
  handle: string
  avatar: string
  platform: 'twitch' | 'youtube'
  subscribers: number
  href: string
}

function toSingleDecimalPlace(number: number) {
  return number.toFixed(1)
}

function formatFollowerCount({ subscribers }: User) {
  if (subscribers < 1_000) {
    return subscribers
  } else if (subscribers < 1_000_000) {
    return toSingleDecimalPlace(subscribers / 1_000) + 'K'
  } else if (subscribers < 1_000_000_000) {
    return toSingleDecimalPlace(subscribers / 1_000_000) + 'M'
  } else {
    return toSingleDecimalPlace(subscribers / 1_000_000_000) + 'B'
  }
}
</script>

<template>
  <ol
    class="flex flex-row justify-center gap-2 md:gap-5 [&>*:nth-child(-n+3)]:flex sm:[&>*:nth-child(-n+4)]:flex md:[&>*:nth-child(-n+5)]:flex"
    v-if="variant === 'loose'"
  >
    <li v-for="user in (users as User[]).slice(0, usersToTake)" :key="user.handle" class="hidden lg:flex">
      <a
        target="_blank"
        :href="user.href"
        :title="`Go to ${user.handle}'s profile`"
        class="group flex w-32 flex-col items-center justify-center gap-2"
      >
        <span class="flex transition-all hover:drop-shadow-lg">
          <span class="relative h-20 w-20">
            <img
              class="absolute h-full w-full rounded-full bg-stone-50 object-contain"
              :src="user.avatar"
              alt="placeholder"
            />
            <span class="absolute bottom-1 right-1 flex h-5 w-5 items-center justify-center rounded-full bg-white">
              <TwitchIcon v-if="user.platform === 'twitch'" class="h-3 w-3 text-[#6441a5]" />
              <YoutubeIcon v-if="user.platform === 'youtube'" class="h-3 w-3 text-[#F00]" />
            </span>
          </span>
        </span>

        <span class="flex flex-col items-center justify-center">
          <span class="font-bold text-zinc-800">{{ user.handle }}</span>
          <span class="text-sm text-zinc-800/60">
            {{ formatFollowerCount(user) }}
          </span>
        </span>
      </a>
    </li>
  </ol>
  <ol class="flex flex-row justify-center" v-if="variant === 'dense'">
    <li
      v-for="user in users"
      :key="user.handle"
      class="relative -mx-1 flex h-12 w-12 flex-col items-center justify-center"
    >
      <img
        class="absolute h-full w-full rounded-full border-2 border-stone-50 bg-stone-50 object-contain"
        :title="user.handle"
        :src="user.avatar"
        :alt="`${user.handle}'s profile picture`"
      />
      <div class="absolute bottom-0 right-0 z-10 flex h-4 w-4 items-center justify-center rounded-full bg-stone-50">
        <TwitchIcon v-if="user.platform === 'twitch'" class="h-2 w-2 text-[#6441a5]" />
        <YoutubeIcon v-if="user.platform === 'youtube'" class="h-2 w-2 text-[#F00]" />
      </div>
    </li>
  </ol>
</template>

<style scoped lang="scss"></style>
