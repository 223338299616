import type { ClipInfo } from '@/store/editor/editorClipInfo'
import { toValue } from 'vue'
import {
  getClipsVideoIdCreateClip,
} from '@/apis/streamladder-clipgpt/clips'
import { useWebsocketChannel } from '@/modules/WebsocketService/WebSocketService'

type TwitchVodIdInfo = {
  vodId: string
  startTime: number
  endTime: number
}

export function encodeTwitchVodIdInfo(info: TwitchVodIdInfo): string {
  // Concatenate properties into a single string
  const binaryString = `${info.vodId},${info.startTime},${info.endTime}`

  // Convert the binary string to a Uint8Array
  const uint8Array = new TextEncoder().encode(binaryString)

  // Convert the Uint8Array to a base64 string
  const base64String = btoa(String.fromCharCode(...uint8Array))

  // console.log('base64String',encodeURIComponent(base64String),base64String);

  // return base64String;
  // Make the base64 string URL-safe
  return base64String
}

export function decodeTwitchVodIdInfo(encodedString: string): TwitchVodIdInfo {
  // Decode the URL-safe base64 string
  const decodedString = encodedString

  // Convert the base64 string back to a binary string
  const binaryString = atob(decodedString)

  // Convert the binary string to a Uint8Array
  const uint8Array = new Uint8Array([...binaryString].map((char) => char.charCodeAt(0)))

  // Convert the Uint8Array back to a string
  const decodedBinaryString = new TextDecoder().decode(uint8Array)

  // Split the string to extract properties
  const [vodId, startTime, endTime] = decodedBinaryString.split(',')

  return {
    vodId,
    startTime: Number(startTime),
    endTime: Number(endTime),
  }
}

// take the hash from the url
// extract the vod id and time from the hash
// request the clip info from the server
// wait for the download to finish
// return the clip info
export const getTwitchVodClipInfo = async (urlString: string): Promise<ClipInfo> => {
  const vodInfo = decodeTwitchVodIdInfo(toValue(urlString))

  if (!vodInfo) {
    throw new Error('Invalid URL')
  }

  let clipInfo = await getClipsVideoIdCreateClip(vodInfo.vodId, {
    startTime: vodInfo.startTime,
    endTime: vodInfo.endTime
  })

  console.log(vodInfo, clipInfo)

  if (!clipInfo.mp4Url) {
    await getClipMetaByPusherTask(clipInfo.taskId)
    clipInfo = await getClipsVideoIdCreateClip(vodInfo.vodId, {
      startTime: vodInfo.startTime,
      endTime: vodInfo.endTime
    })
  }

  return clipInfo
}

async function getClipMetaByPusherTask(taskId: string) {
  return new Promise((resolve, reject) => {
    const channelName = `cache-task-status-${taskId}`
    const channel = useWebsocketChannel(channelName, (eventName, data) => {
      if (eventName === 'progress') {
        if (data.status === 'finished') {
          resolve(data)
        }
        if (data.status === 'error') {
          if (data.message === 'blocked_video') {
            reject(new Error('blocked_video'))
          } else {
            reject(new Error(`Failed downloading YouTube clip: ${data}`))
          }
        }
      }
    })
  })
}
