import type { Notification, NotificationAdapter } from '@/modules/NotificationService/NotificationAdapter'
import type { MontageDto } from '@/apis/streamladder-api/model'
import { truncate } from 'lodash-es'
import type { RouteLocationRaw } from 'vue-router'
import { getApiMontages } from '@/apis/streamladder-api/montages/montages'

export class MontageAdapter implements NotificationAdapter {
  public readonly name = 'montage'
  private identifier = 'montage-notification-'

  public getIdentifier(id?: string): string {
    return this.identifier + (id || '')
  }

  public async getUnreadNotifications(): Promise<Notification<MontageDto>[]> {

    const montages = (await getApiMontages()) ?? []

    return montages
      .filter((montage) => montage.status === 'processing')
      .map((montage) => {
        let to = {} as RouteLocationRaw | undefined
        if (montage.status === 'processing') {
          to = {
            name: 'montage-maker',
            query: {
              task: montage.id,
            },
          }
        }

        return {
          id: `${this.identifier}${montage.id}`,
          type: 'montage',
          title: truncate(montage.title ?? 'New montage', { length: 22 }),
          description: montage.statusMessage,
          to,
          data: montage,
        } as Notification<MontageDto>
      })
  }
}
