<script setup lang="ts">
import IconSaxPause from '@/components/Icons/iconsax/IconSaxPause.vue'
import IconSaxPlay from '@/components/Icons/iconsax/IconSaxPlay.vue'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
import VolumeButton from '@/components/Editor/VolumeButton.vue'
import { Button } from '@/components/ui/button'
import { reactive } from 'vue'
import { storeToRefs } from 'pinia'

const store = useEditorVideoStore()
const { playing } = storeToRefs(store)

const togglePlay = () => {
  store.preservedPaused = !store.preservedPaused
  store.playing = !store.playing
};

const inputBindings = reactive(store.currentTimeBindings)
</script>

<template>
  <div class="flex w-full items-center gap-4 rounded-xl border-2 border-zinc-200 px-6 py-3">
    <Button
      v-if="!playing"
      variant="ghost"
      class="rounded-full w-10 p-2"
      @click="togglePlay"
    >
      <IconSaxPlay class="h-6 w-6 fill-current [&>path]:stroke-[0]" />
    </Button>
    <Button
      v-else
      variant="ghost"
      class="rounded-full w-10 p-2"
      @click="togglePlay"
    >
      <IconSaxPause class="h-6 w-6 fill-current [&>path]:stroke-[0]" />
    </Button>

    <div class="relative flex h-4 w-full flex-col justify-center">
      <input
        type="range" class="opacity-0"
        v-bind="inputBindings"
        v-model="inputBindings.model"
      />
      <div class="pointer-events-none absolute inset-auto h-2 w-full rounded-full bg-zinc-300" />
      <div
        class="pointer-events-none absolute inset-auto left-0 h-2 rounded-full bg-primary"
        :style="{ width: inputBindings.progress + '%' }"
      />
    </div>

    <VolumeButton />
  </div>
</template>

<style scoped lang="scss"></style>
