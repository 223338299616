<script setup lang="ts">
import IconSaxWarning2 from '@/components/Icons/iconsax/IconSaxWarning2.vue'
import { computed } from 'vue'
import ConnectionTypes from '@/enums/connectionTypes'
import { AccountState } from '@/apis/streamladder-accounts/model'
import type { TypedOutboundSocialAccount } from '@/store/user/userInfo'
import { Button } from '@/components/ui/button'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { RouterLink } from 'vue-router'

const props = defineProps<{
  account: TypedOutboundSocialAccount
  showLinkToSocialsPage?: boolean
}>()

const mustReconnectTikTokAccount = computed(() => {
  return props.account.type === ConnectionTypes.TIKTOK && !props.account.scopes.includes('user.info.stats')
})

const mustUpgradeToInstagramBusinessAccount = computed(() => {
  return props.account.type === ConnectionTypes.INSTAGRAM && props.account.state !== AccountState.active
})

const accountRequiresAuthorisation = computed(() => {
  return !props.account.hasAccess || props.account.state !== AccountState.active
})

const requiresAction = computed(() => mustReconnectTikTokAccount.value || accountRequiresAuthorisation.value || mustUpgradeToInstagramBusinessAccount.value)
</script>

<template>
  <div v-if="requiresAction" class="alert alert-warning mb-3 w-full bg-yellow-100 shadow-lg">
    <div class="flex w-full flex-row items-center gap-4">
      <IconSaxWarning2 class="shrink-0" />

      <p class="w-full max-w-full text-sm">
        <template v-if="mustReconnectTikTokAccount">
          <Button v-if="showLinkToSocialsPage" :as="RouterLink" :to="{name: dashboardRouteNames.socials}" variant="link" class="text-black whitespace-normal">
            Your account needs to be reconnected to keep publishing with TikTok. Click me to reconnect!
          </Button>
          <p v-else>Reconnect your TikTok account to keep publishing with TikTok.</p>
        </template>

        <template v-if="accountRequiresAuthorisation">
          <Button
            v-if="showLinkToSocialsPage"
            :as="RouterLink"
            :to="{name: dashboardRouteNames.socials}"
            variant="link"
            class="text-black whitespace-normal">
            Your account needs to be reconnected to keep publishing and posting. Click to investigate.
          </Button>
          <p v-else>Reconnect your account to keep publishing.</p>
        </template>

        <template v-if="mustUpgradeToInstagramBusinessAccount">
          To post or schedule from StreamLadder to Instagram, you need an Instagram Business account.
        </template>
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>