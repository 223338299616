import { isBefore } from 'date-fns'
import { createGlobalState } from '@vueuse/core'
import { useQuery } from '@tanstack/vue-query'
import { queryClient } from '@/services/QueryClient'

export const useDeploymentVersion = createGlobalState(() => useQuery({
  queryKey: ['deploymentVersion'],
  queryFn: getDeploymentVersion,
  queryClient: queryClient,
  refetchOnWindowFocus: true,
}))

export const getDeploymentVersion = async () => {

  const currentJsSha = import.meta.env.VITE_PAGES_COMMIT_SHA || 'local'
  const currentJsBuildDate = new Date(import.meta.env.VITE_BUILD_DATE)

  const latestVersionData = await getLatestDeployedVersion()
  const latestSha = latestVersionData?.commit_hash
  const latestBuildDate = latestVersionData?.timestamp ? new Date(latestVersionData?.timestamp) : undefined

  const shaMatch = currentJsSha === latestSha
  const hasUpdateReady = latestBuildDate && currentJsBuildDate && !shaMatch && import.meta.env.VITE_DEPLOYMENT_ENV === 'production'
    ? isBefore(currentJsBuildDate, latestBuildDate) 
    : false

  return {
    currentJsSha,
    currentJsBuildDate,
    latestSha,
    latestBuildDate,
    shaMatch,
    hasUpdateReady,
  }
}

export const getLatestDeployedVersion = async () => {
  const url = import.meta.env.VITE_VERSION_URL

  try {
    if (url) {
      const versionResponse = await fetch(url)
      return (await versionResponse.json()) as { commit_hash: string; timestamp: string }
    }
  } catch (e) {
    console.error('Failed to fetch version data', e)
  }
  return undefined
}

export const updateVersion = async () => {
  location.reload(true)
}
