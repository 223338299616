<script setup lang="ts">
import settings from '@/data/settings'
import { ref } from 'vue'
import IconSaxMessageQuestion from '@/components/Icons/iconsax/IconSaxMessageQuestion.vue'
import IconSaxNotepad2 from '@/components/Icons/iconsax/IconSaxNotepad2.vue'
import IconSaxArrowRight2 from '@/components/Icons/iconsax/IconSaxArrowRight2.vue'
import { useRoute } from 'vue-router'
import IconSaxHeadphone from '@/components/Icons/iconsax/IconSaxHeadphone.vue'
import DiscordLogo from '@/components/Icons/DiscordLogo.vue'
import NavigationFlyout from '@/areas/dashboard/components/NavigationFlyout.vue'
import DashboardNavigationItem from '@/areas/dashboard/layout/nav/DashboardNavigationItem.vue'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'

const isOpen = ref(false)
const route = useRoute()
</script>

<template>
  <NavigationFlyout v-model="isOpen" class="flex w-full flex-col" arrow>
    <template #button="{ ...props }">
      <DashboardNavigationItem
        v-bind="props"
        :class="{ 'overflow-visible': isOpen }"
        :is-active="isOpen"
        class="hover:!bg-zinc-200 dark:hover:!bg-zinc-700"
      >
        <IconSaxMessageQuestion class="shrink-0" />
        <span>Support & Feedback</span>
      </DashboardNavigationItem>
    </template>

    <template #menu="{ ...props }">
      <div v-bind="props" class="flex flex-col gap-6 p-6 lg:max-w-[600px]">
        <div class="flex flex-col items-center justify-center">
          <h5 class="text-xl font-bold">Got questions or feedback?</h5>
          <p>We're here to help and listen</p>
        </div>
        <nav class="flex flex-col items-center justify-start gap-4">
          <ol class="flex flex-col items-stretch justify-start gap-3">
            <li>
              <a
                href="https://streamladder.com/user-guides"
                target="_blank"
                class="layer-2 flex cursor-pointer items-center justify-center gap-5 rounded-xl p-3 transition-all hover:bg-zinc-600/10 active:scale-90 active:bg-zinc-600/25"
              >
                <IconSaxNotepad2 class="h-6 w-6 shrink-0" />
                <span class="flex flex-col">
                  <span class="font-bold">Browse FAQ and Knowledge Base</span>
                  <span class="text-sm font-light">
                    Browse our most common FAQ and find solutions in our knowledge base center.
                  </span>
                </span>
                <IconSaxArrowRight2 class="ml-auto shrink-0" />
              </a>
            </li>

            <li>
              <RouterLink
                @click="isOpen = false"
                :to="{ name: dashboardRouteNames.support, query: { referrer: route.name } }"
                class="layer-2 flex cursor-pointer items-center justify-center gap-5 rounded-xl p-3 transition-all hover:bg-zinc-600/10 active:scale-90 active:bg-zinc-600/25"
              >
                <IconSaxHeadphone class="h-6 w-6 shrink-0" />
                <span class="flex flex-col">
                  <span class="font-bold">Contact Support</span>
                  <span class="text-sm font-light"> Reach out monday to friday for assistance and answers. </span>
                </span>
                <IconSaxArrowRight2 class="ml-auto shrink-0" />
              </RouterLink>
            </li>

            <li>
              <a
                :href="settings.discordInviteUrl"
                target="_blank"
                class="layer-2 flex cursor-pointer items-center justify-center gap-5 rounded-xl p-3 transition-all hover:bg-zinc-600/10 active:scale-90 active:bg-zinc-600/25"
              >
                <DiscordLogo class="h-6 w-6 shrink-0 fill-current" />
                <span class="flex flex-col">
                  <span class="font-bold">Share Feedback & Suggestions</span>
                  <span class="text-sm font-light">
                    Join our Discord for support, feedback, and feature suggestions.
                  </span>
                </span>
                <IconSaxArrowRight2 class="ml-auto shrink-0" />
              </a>
            </li>
          </ol>
        </nav>
      </div>
    </template>
  </NavigationFlyout>
</template>

<style scoped lang="scss"></style>
