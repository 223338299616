<script setup lang="ts">
import type { Payment } from '@/areas/dashboard/components/RecentPayments/usePayments'
import { computed } from 'vue'
import { getDisplayPrice } from '@/store/products'
import IconSaxReceipt from '@/components/Icons/iconsax/IconSaxReceipt.vue'
import format from 'date-fns/format'
import IconSaxTickCircle from '@/components/Icons/iconsax/IconSaxTickCircle.vue'

const props = defineProps<{ payment: Payment | undefined }>()

const dateTimeFormatter = Intl.DateTimeFormat('en-US', { dateStyle: 'long' })
const date = computed(() => {
  return props.payment ? dateTimeFormatter.format(new Date(props.payment.payoutDate)) : undefined
})

const fullDateFormatter = Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'full' })
const fullDate = computed(() => {
  return props.payment ? fullDateFormatter.format(new Date(props.payment.payoutDate)) : undefined
})

const displayPrice = computed(() => {
  return props.payment ? getDisplayPrice(props.payment.amount, props.payment.currency) : undefined
})
</script>

<template>
  <article class="layer-1 min-h-[50px] rounded-2xl p-3" :class="{ 'skeleton overflow-hidden': !payment }">
    <div class="grid grid-cols-12 items-center gap-4">
      <header class="col-span-8 md:col-span-5">
        <h4 class="tooltip inline-block text-base font-normal" :data-tip="fullDate">{{ date }}</h4>
      </header>
      <p class="col-span-4 flex items-center gap-2 md:col-span-1">
        {{ displayPrice }}
        <span v-if="payment?.isPaid" class="tooltip block" data-tip="Payment received">
          <IconSaxTickCircle class="text-emerald-400" />
        </span>
      </p>
      <div class="col-span-12 flex justify-end md:col-span-6">
        <a
          v-if="payment && payment.receiptUrl"
          :href="payment.receiptUrl"
          :download="`StreamLadder-Invoice-${format(new Date(payment.payoutDate), 'yyyy-MM-dd')}.pdf`"
          target="_blank"
          class="flex items-center justify-center gap-2 whitespace-nowrap rounded-xl border border-current px-3 py-1 transition-all hover:border-indigo-600 hover:bg-indigo-600 hover:text-white active:scale-90"
        >
          <IconSaxReceipt class="h-4 w-4 shrink-0" />
          Download receipt
        </a>
      </div>
    </div>
  </article>
</template>

<style scoped lang="scss"></style>
