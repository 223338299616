<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import LoginForm from '@/pages/auth/LoginForm.vue'

const router = useRouter();
const route = useRoute();

const navigateToSite = () => {
  router.push(route.query.redirect as string || '/');
};
</script>

<template>
  <div class="flex min-h-screen w-full flex-col">
    <div class="container flex w-full max-w-5xl flex-grow flex-col items-center justify-items-center space-y-2 self-center justify-self-center px-4 pb-20 pt-6 md:flex-row md:space-y-0">
      <div class="fixed left-1/2 top-1/2 z-50 max-h-[90vh] overflow-y-auto -translate-x-1/2 -translate-y-1/2 gap-4 layer-1 duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] app-bg grid md:grid-cols-2 w-full p-0 min-w-screen min-h-screen max-w-[1920px] min-[1920px]:shadow-2xl min-[1920px]:rounded-lg">
        <LoginForm @loggedIn="navigateToSite" />
      </div>
    </div>
  </div>
</template>
