<script setup lang="ts">
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import { useMovableContext } from '@/modules/SLMovable/useMovableContext'
import StickerElement from '@/areas/editor/workspaces/preview/layers/stickers/StickerElement.vue'
import { computed, watch, ref } from 'vue'
import { downloadStickerScreenshot } from '@/lib/downloadStickerImage'
import * as Sentry from '@sentry/vue'
import type TextSticker from '@/components/Stickers/TextSticker.vue'
import { clsx } from 'clsx'
import { useIsMobile } from '@/Hooks/useIsMobile'
import { useEditorFocusStore } from '@/store/editor/editorFocus'
import { retryAsync } from '@/helpers/retry'

const props = defineProps<{ id: string, class?: string, focus?: boolean }>()

const stickersStore = useStickersStore()
const sticker = stickersStore.selectById(props.id)
const focusType = stickersStore.selectFocusTypeById(props.id)

let controller = new AbortController()
async function updateStickerUrl() {

  if (sticker.type === 'custom' || sticker.type === 'giphy') {
    return
  }

  controller.abort()
  controller = new AbortController()
  sticker.imageUrl = ''

  try {
    const previewQuality = Math.min(1080, 720 * Math.min(window.devicePixelRatio, 2))
    const absoluteScale = (1.6 * previewQuality) / sticker.naturalWidth
    const scale = absoluteScale / previewQuality
    
    const arrayBuffer = await retryAsync(() => downloadStickerScreenshot(
      sticker, scale, previewQuality, controller.signal))

    sticker.imageUrl = URL.createObjectURL(new Blob([arrayBuffer]))
  } catch (e) {
    Sentry.captureException(e)
    sticker.imageUrl = ''
  }
}

const { width: movableWidth, height: movableHeight } = useMovableContext()!

const hide = computed(() => {
  return !!sticker.imageUrl && !sticker.editing
})

watch(() => sticker.editing, () => {

  if (sticker.type === 'custom' || sticker.type === 'giphy') {
    return
  }
})

watch(() => sticker.naturalWidth, (width) => {
  if (width) {
    updateStickerUrl()
  }
})

const editorFocusStore = useEditorFocusStore()

const componentRef = ref<InstanceType<TextSticker>>()
function setEditMode() {
  editorFocusStore.setFocus(focusType.value, props.id)
  setTimeout(() => componentRef.value?.setEditMode?.(), 0)
}

const isMobile = useIsMobile()
function onLoad() {
  if (sticker.editing) {
    if (isMobile.value) {
      sticker.editing = false
    } else {
      setEditMode()
    }
  }
}

watch(() => sticker.editing, () => {
  if (sticker.editing) {
    setEditMode()
  }
})
</script>

<template>
  <StickerElement ref="componentRef" v-model="sticker"
    :key="'textContent' in sticker ? sticker.textContent : sticker.id"
    :render-width="movableWidth" :render-height="movableHeight"
    :class="clsx({ 'invisible': hide }, 'transition-opacity', props.class)"
    :focus="focus" @ready="onLoad"
  />
</template>

<style scoped lang="scss">

</style>

