<script lang="ts" setup>
import { useTwitchClips, type Clip } from '@/Hooks/useTwitchClips'
import { computed, ref, toRef } from 'vue'
import { useTwitchClipsFilter } from '@/Hooks/useTwitchClipsFilter'
import { range } from 'lodash-es'
import ClipPreviewCard from '@/components/Clips/ClipPreviewCard.vue'
import TwitchClipPreviewDialog from '@/components-v2/modules/HomePage/TwitchClipPreviewDialog.vue'
import SelectDropdown from '@/components-v2/data-input/SelectDropdown.vue'
import NoContentSection from '@/components-v2/data-display/NoContentSection.vue'
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import { useClipFormManager } from '@/Hooks/clip-form/useClipFormManager'
import ClipUrlError from '@/Hooks/clip-form/ClipUrlError.vue'
import ClipEditorMultiUploadDialog from '@/components/Dialog/MultiUploadDialog/ClipEditorMultiUploadDialog.vue'

const emit = defineEmits<{
  (name: 'select', clip: Clip): void
}>()

const props = defineProps<{
  userName: string | undefined
}>()

const userName = toRef(props, 'userName')
const { isLoading, clips } = useTwitchClips({ userName })

const clipsToShow = computed(() => (isLoading.value ? 12 : clips.value.length))

const { twitchClipFilterKey, twitchClipsOptions } = useTwitchClipsFilter()

const previewId = ref<string | null>(null)

function preview(clip: Clip) {
  previewId.value = clip.id
}

function select(clip: Clip) {
  emit('select', clip)
}

const { error } = useClipFormManager()
</script>

<template>
  <section class="flex h-full md:max-h-full flex-col">
    <div class="layer-1 sticky -top-6 z-[1] -mx-6 flex flex-wrap items-center justify-between px-6 py-4 md:-top-12 md:-mx-12 md:px-12">
      <header>
        <h5 class="text-lg md:text-xl font-thin dark:text-white py-0.5 md:py-4 md:p-0">
          <template v-if="isLoading">
            Finding clips for <span class="font-bold">"{{ userName }}"</span>
          </template>
          <template v-else>
            {{ clips.length }} results for <span class="font-bold">"{{ userName }}"</span>
          </template>
        </h5>
      </header>
      <SelectDropdown v-model="twitchClipFilterKey" :options="twitchClipsOptions" fixed />
    </div>

    <section v-if="isLoading || clips.length" class="m-0 md:-m-4 min-h-0 max-h-auto overflow-y-auto p-4">
      <TransitionGroup
        :key="twitchClipFilterKey + userName"
        appear
        class="grid grid-cols-1 gap-2 md:grid-cols-3 md:gap-4"
        name="grid"
        tag="ol"
      >
        <li v-for="i in range(clipsToShow)" :key="i">
          <ClipPreviewCard
            :clip="clips[i]"
            :is-loading="isLoading"
            overlay-scaling="scale-75"
            @preview="preview"
            @select="select"
          />
        </li>
      </TransitionGroup>
    </section>
    <NoContentSection
      v-else-if="twitchClipFilterKey === 'views-all'"
      subtitle="Try uploading a local file"
      title="The selected channel does not have any clips yet"
    >
      <ClipEditorMultiUploadDialog>
        <GradientButton>Upload a local file</GradientButton>
      </ClipEditorMultiUploadDialog>
      <ClipUrlError :error="error" />
    </NoContentSection>

    <NoContentSection
      v-else
      subtitle="Check out all clips from this channel instead"
      title="No clips were created in this time period"
    >
      <GradientButton @click="twitchClipFilterKey = 'views-all'">Show all</GradientButton>
    </NoContentSection>
  </section>

  <teleport v-if="clips" to="body">
    <TwitchClipPreviewDialog v-model="previewId" :clips="clips" @confirm="select" />
  </teleport>
</template>

<style lang="scss" scoped></style>
