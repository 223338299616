<script setup lang="ts">
import { useContentPublisherStore } from '@/components-v2/content-publisher/_store'
import { computed } from 'vue'
import { isSameDay } from 'date-fns'
import { addGridRowProperty, toPostCalendarEvent } from '@/components-v2/content-publisher/_helpers'
import ContentPublisherEvent from '@/components-v2/content-publisher/ContentPublisherEvent.vue'
import { useRouter } from 'vue-router'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'

const props = defineProps<{ date: string | undefined }>()

const contentPublisherStore = useContentPublisherStore()

const posts = computed(() => {

  if (!props.date) return []

  const timezoneOffsetInMs = new Date().getTimezoneOffset() * 60 * 1000
  const currentDate = new Date(props.date).getTime() + timezoneOffsetInMs
  const events = contentPublisherStore.posts
    .map(toPostCalendarEvent)
    .filter((event) => isSameDay(new Date(event.date), currentDate))

  return addGridRowProperty(events)
})

const router = useRouter();

const openContentPublisherInNewTab = (id: string) => {
  window.open(router.resolve({ name: dashboardRouteNames.contentPublisher.root, query: { post: id } }).href, '_blank');
};
</script>

<template>
  <div class="flex h-full flex-col gap-2 overflow-y-auto">
    <p class="text-style-base-bold text-center">Scheduled this day</p>
    <ContentPublisherEvent v-for="post in posts" :key="post.id" direction="horizontal" :event-content="{ event: post }" @click="openContentPublisherInNewTab" />
    <div v-if="posts.length === 0">
      <p class="text-center text-brand-state-text-placeholder">No posts scheduled for this day</p>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
