import { ref } from 'vue'
import { useClipUrlValidator } from '@/Hooks/clip-form/useClipUrlValidator'
import { useResetEditor } from '@/Hooks/useResetEditor'
import { useStartup } from '@/areas/editor/startup'

export function useClipFormManager() {
  
  const error = ref<{ message: string; title: string } | null>(null)
  const resetEditor = useResetEditor()
  const validateClipUrl = useClipUrlValidator()
  const { startup, downloadError } = useStartup()

  const youtubeVideoId = ref<string | null>(null)

  async function submitClipUrl(clipUrl: string | undefined) {
    const validationResult = validateClipUrl(clipUrl)
    error.value = validationResult.error
    youtubeVideoId.value = validationResult.youtubeVideoId ?? null

    if (validationResult.route) {
      resetEditor()
      const { clipId, source } = validationResult.route.params
      await startup(clipId, source)
    }
  }

  return { error, downloadError, youtubeVideoId, submitClipUrl }
}
