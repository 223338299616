<script setup lang="ts">
import { Button } from '@/components/ui/button'
import ThumbnailContainer from '@/components/Dialog/MultiUploadDialog/ThumbnailContainer.vue'
import { useDeleteUploadedVideo, type EnhancedUploadedVideo } from '@/components/Dialog/MultiUploadDialog/file-uploads/useUploadedVideos'
import { PopoverTrigger, PopoverContent, Popover } from '@/components/ui/popover'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import {useFileUploads} from "@/components/Dialog/MultiUploadDialog/file-uploads/useFileUploads";
import {computed} from "vue";

const props = defineProps<{ upload: EnhancedUploadedVideo }>()
const emit = defineEmits<{ (event: 'click', id: string): void }>()
const { remove, isRemoving } = useDeleteUploadedVideo(props.upload.id)

const fileUploads = useFileUploads()

const justUploaded = computed(() => fileUploads.ids.includes(props.upload.id))

const msInHour = 60 * 60 * 1000
const msInDay = 24 * msInHour
</script>

<template>
<li :id="upload.id">
  <article
    @click="emit('click', upload.id)" 
    class="rounded-lg bg-surface-panel-50 relative group border border-surface-input-border hover:border-brand-state-hover-border hover:bg-brand-state-hover-bg cursor-pointer transition-[color,border-color,background-color] group"
  >
    <ThumbnailContainer 
      class="rounded-t-lg -m-px group-hover:border-brand-state-hover-border group-hover:bg-brand-state-hover-bg transition-[color,border-color,background-color]" 
      :src="upload.thumbnailUrl"
      :alt="'Thumbnail of ' + upload.name" 
    />
    <span v-if="justUploaded" class="absolute top-0 right-0 bg-primary text-background text-xs font-semibold rounded-bl-lg px-1.5 py-1">New</span>
    <div class="flex gap-2 items-center justify-between p-2">
      <header class="flex flex-col">
        <h4 class="text-foreground text-sm line-clamp-1 break-all">{{ upload.name }}</h4>
        <span class="text-foreground opacity-50 text-xs font-light" :title="new Date(upload.createdAt).toLocaleDateString()">
          <template v-if="upload.msRemaining > msInDay">
            {{ Math.ceil(upload.msRemaining / msInDay) }} days left
          </template>
          <template v-else-if="upload.msRemaining > msInHour">
            {{ Math.ceil(upload.msRemaining / msInHour) }} hours left
          </template>
          <template v-else>
            Less than an hour left
          </template>
        </span>
      </header>
      <Button variant="outline" size="sm" class="text-zinc-900 ml-auto group-hover:bg-primary/90 group-hover:text-background dark:group-hover:text-white dark:group-hover:bg-secondary-foreground/10" aria-label="More options">
        Select
      </Button>
      <Popover>
        <PopoverTrigger @click.stop class="active:scale-90 transition-transform">
          <Button variant="ghost" size="square" class="w-4 h-8 text-zinc-900 flex-col justify-center gap-0.5" aria-label="More options">
            <span class="rounded-full w-[3px] h-[3px] bg-current" />
            <span class="rounded-full w-[3px] h-[3px] bg-current" />
            <span class="rounded-full w-[3px] h-[3px] bg-current" />
          </Button>
        </PopoverTrigger>
        <PopoverContent class="flex flex-col gap-1 p-1 w-auto rounded-xl" @click.stop>
          <Button variant="ghostDestructive" size="sm" aria-label="Delete" @click.stop="remove" class="w-auto justify-start" :disabled="isRemoving">
            <template  v-if="isRemoving">
              <div class="w-4 h-4 rounded-full border border-t-transparent border-current animate-spin" />
              Deleting...
            </template>
            <template v-else>
              <IconSaxTrash class="w-4 h-4" />
              Delete
            </template>
          </Button>
        </PopoverContent>
      </Popover>
    </div>
  </article>
</li>
</template>

<style scoped lang="scss">

</style>
