<script setup lang="ts">
import { ref, toRefs, onMounted, onUnmounted } from 'vue'
import { createMovableElementContext } from '@/modules/SLMovable/useMovableElementContext'
import MovableElementMoveHandle from '@/modules/SLMovable/MovableElementMoveHandle.vue'
import MovableElementResizeHandles from '@/modules/SLMovable/MovableElementResizeHandles.vue'
import { useMovableContext } from '@/modules/SLMovable/useMovableContext'
import { isEqual } from 'lodash-es'
import type { Directions, Area, Point } from '@/modules/SLMovable/@types/Movable'
import { watchImmediate, watchPausable } from '@vueuse/core'
import unwrap from '@/helpers/unwrap'
import { copyRef } from '@/store/entity-system/_copyRef'

const props = withDefaults(
  defineProps<{
    
    id: string,

    resize?: boolean
    snap?: boolean
    move?: boolean

    shape: 'rectangle' | 'circle'

    minSize: number

    aspectLock?: { width: number; height: number } | null
    bounds?: {
      top: number
      right: number
      bottom: number
      left: number
    } | null

    handles?: ('n' | 'ne' | 'e' | 'se' | 's' | 'sw' | 'w' | 'nw')[]
    resizeHandleClass?: string
    moveHandleClass?: string

    disableArrowKeys?: boolean
  }>(),
  { snap: null, aspectLock: null, bounds: null, resize: false, move: false }
)

const { maskId, areas } = useMovableContext()!

const movingFrom = ref<{ x: number; y: number } | null>(null)
const resizingFrom = ref<Directions | null>(null)

const { resize: resizeEnabled, move: moveEnabled, snap: snapEnabled, aspectLock, bounds, minSize } = toRefs(props)

const area = defineModel<Area>({ required: true })
const clone = ref(copyRef(area))

const { localArea, pullFocus } = createMovableElementContext({

  local: area,
  source: clone,

  resize: resizeEnabled,
  snap: snapEnabled,
  move: moveEnabled,

  minSize: minSize,

  aspectRatio: aspectLock,

  bounds: bounds,

  movingFrom: movingFrom,
  resizingFrom: resizingFrom,
})

const emit = defineEmits<{
  (event: 'resizeStart'): void
  (event: 'resize', area: { x: number; y: number; width: number; height: number }, directions: Directions): void
  (event: 'resizeEnd'): void
  (event: 'moveStart'): void
  (event: 'move', area: { x: number; y: number }): void
  (event: 'moveEnd'): void
}>()

const { pause, resume } = watchPausable(
  area,
  () => (clone.value = copyRef(area)),
  { immediate: true, deep: true })

function start() {
  pause()
  forceToTop.value = true
}

function commit() {
  clone.value = copyRef(area)
  resume()
  forceToTop.value = false
}

const forceToTop = ref(false)

function resize(newArea: Area) {
  area.value = newArea
}

function move(position: { x: number; y: number }) {
  area.value = {
    width: area.value.width,
    height: area.value.height,
    x: position.x,
    y: position.y,
  }
}

function startMove(point: Point) {
  document.body.classList.add('block-interaction', 'cursor-grabbing')
  movingFrom.value = point
  start()
}

function onMove(position: Point) {
  move(position)
  emit('move', position)
}

function endMove() {
  document.body.classList.remove('block-interaction', 'cursor-grabbing')
  if (localArea.value.x !== clone.value.x || localArea.value.y !== clone.value.y) {
    emit('moveEnd')
  }
  movingFrom.value = null
  commit()
}

const resizeCursorValidators = {
  'cursor-n-resize': () => isEqual(resizingFrom.value, ['n']),
  'cursor-ne-resize': () => isEqual(resizingFrom.value, ['n', 'e']),
  'cursor-e-resize': () => isEqual(resizingFrom.value, ['e']),
  'cursor-se-resize': () => isEqual(resizingFrom.value, ['s', 'e']),
  'cursor-s-resize': () => isEqual(resizingFrom.value, ['s']),
  'cursor-sw-resize': () => isEqual(resizingFrom.value, ['s', 'w']),
  'cursor-w-resize': () => isEqual(resizingFrom.value, ['w']),
  'cursor-nw-resize': () => isEqual(resizingFrom.value, ['n', 'w']),
}

const resizeCursors = unwrap.keys(resizeCursorValidators)

function findResizeCursor() {
  return resizeCursors.find(key => {
    const isCurrentCursor = resizeCursorValidators[key]
    return isCurrentCursor()
  })
}

function startResize(directions: Directions) {
  const resizeCursor = findResizeCursor()
  if (resizeCursor) {
    document.body.classList.add('block-interaction', resizeCursor)
  } else {
    document.body.classList.add('block-interaction')
  }

  resizingFrom.value = directions
  start()
}

function onResize(area: Area, directions: Directions) {
  resize(area)
  emit('resize', area, directions)
}

function endResize() {
  document.body.classList.remove('block-interaction', ...resizeCursors)
  resizingFrom.value = null
  commit()
  emit('resizeEnd')
}

const blackout = ref(false)
watchImmediate(maskId, () => {
  setTimeout(() => {
    blackout.value = maskId.value !== null
  }, 0)
})

onMounted(() => areas.value.set(props.id, area))
onUnmounted(() => areas.value.delete(props.id))
</script>

<template>
  <div
    @click.stop="pullFocus"
    :class="forceToTop ? 'z-[250]' : ''"
  >
    <slot />

    <MovableElementMoveHandle v-if="moveEnabled" :id="id" @move-start="startMove" @move="onMove" @move-end="endMove" :handle-class="moveHandleClass" :disableArrowKeys="disableArrowKeys">
      <slot name="move" />
    </MovableElementMoveHandle>

    <MovableElementResizeHandles v-if="resizeEnabled" :id="id" @resize-start="startResize" @resize="onResize" @resize-end="endResize" :handle-class="resizeHandleClass" :include="handles">
      <template #direction="{ angle }">
        <slot name="resize-direction" :angle="angle" />
      </template>
    </MovableElementResizeHandles>

    <Teleport :to="`#${maskId}`" v-if="blackout">
      <ellipse
        v-if="shape === 'circle'"
        :cx="(localArea.x + 0.5 * localArea.width) * 100 + '%'"
        :cy="(localArea.y + 0.5 * localArea.height) * 100 + '%'"
        :rx="0.5 * localArea.width * 100 + '%'"
        :ry="0.5 * localArea.height * 100 + '%'"
        fill="black"
      />
      <rect
        v-else
        :x="localArea.x * 100 + '%'"
        :y="localArea.y * 100 + '%'"
        :width="localArea.width * 100 + '%'"
        :height="localArea.height * 100 + '%'"
        fill="black"
      />
    </Teleport>
  </div>
</template>

<style lang="scss">
@media (pointer: coarse) {
  .block-interaction,
  .block-interaction::before,
  .block-interaction::after,
  .block-interaction .modal-open .modal-box,
  .block-interaction .modal-open .modal-box::before,
  .block-interaction .modal-open .modal-box::after {
    overflow: hidden !important;
    touch-action: none !important;
    user-select: none !important;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
