<template>
  <caption-container
    v-if="editorCaptionsStore.hasCaptions"
    ref="container"
    :enable-snapping="enableSnapping"
    :containerWidth="containerWidth"
    :containerHeight="containerHeight"
    :videoDuration="editorMainStore.videoDuration"
    :captions="editorCaptionsStore.captions"
    :captionStyleSettings="editorCaptionsStore.captionStyleSettings"
    :captionsWrapper="editorCaptionsStore.captionsWrapper"
    isEditable
    style="position: absolute"
  />
</template>

<script>
import EventBus from '@/eventBus'
import CaptionContainer from '@/components/Captions/CaptionContainer.vue'
import { useEditorMainStore } from '@/store/editor/editorMain'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'

export default {
  components: { CaptionContainer },
  setup() {
    const editorMainStore = useEditorMainStore()
    const editorCaptionsStore = useEditorCaptionsStore()

    return { editorMainStore, editorCaptionsStore }
  },
  props: {
    containerWidth: {
      type: Number,
      default: 1,
    },
    containerHeight: {
      type: Number,
      default: 1,
    },
    enableSnapping: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    // EventBus.$on('editor/currentTimeUpdated', this.updateWordsTimer)
  },
  beforeUnmount() {
    // EventBus.$off('editor/currentTimeUpdated', this.updateWordsTimer)
  },
  methods: {},
}
</script>
