<script setup lang="ts">
import { Button } from '@/components/ui/button'
import { useScriptTag } from '@vueuse/core'
import { onBeforeMount } from 'vue'

useScriptTag(
  'https://widget.senja.io/js/collector.js',
)

onBeforeMount(() => {
  window.SenjaCollectorConfig = {
    project: 'streamladder',
    form: 's4sr0p',
    trigger: { type: 'none' },
  }
})

const openTestimonialForm = () => {
  window.SenjaCollector.open()
}
</script>

<template>
  <button
    @click="openTestimonialForm"
    class="group relative mt-12 cursor-pointer transition-all hover:scale-[1.015] active:scale-95"
  >
    <img
      src="/images/images/heart-comment.png"
      class="absolute -top-12 left-1/2 z-10 h-24 -translate-x-1/2 transition"
      alt="heart-comment"/>
    <div
      class="relative flex h-full w-full flex-col items-center gap-4 overflow-hidden rounded-xl bg-gradient-to-br from-[#FF91ED] to-[#F97D48] p-8 shadow-2xl"
    >
      <div class="ellipse !bottom-1/3 translate-x-2/3" />
      <div class="ellipse -translate-x-2/3 translate-y-2/3 rotate-[180deg]" />
      <h3 class="text-style-lg py-0 text-center leading-[1] !text-white">Do you love using our product?</h3>
      <p class="max-w-[50ch] text-center font-light leading-[1.5] text-white">We'd love to hear about it!</p>
      <Button variant="dashboardOverlay" class="w-full"> Share a testimonial</Button>
    </div>
  </button>
</template>
