import type { Ref } from 'vue'
import { computed, onMounted, ref } from 'vue'
import WaveSurfer from 'wavesurfer.js'
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo'
import { useEditorVideoStore } from '@/store/editor/editorVideo'
// import { useVideoElement } from '@/components-v2/modules/VideoPlayer/useVideoManager'

export const useWaveFormAnalysis = (target: Ref<HTMLElement>) => {
  const clipStore = useEditorClipInfoStore()
  const editorVideoStore = useEditorVideoStore()
  const videoElement = computed(() => editorVideoStore.videoElement)

  const loadingProcess = ref(0)
  const loadingDone = ref(false)

  const waveform = ref<WaveSurfer | null>(null)

  onMounted(() => {
    waveform.value = WaveSurfer.create({
      container: target.value,
      waveColor: 'blue',
      progressColor: 'gray',
      cursorColor: 'red',
      height: 100,
      barWidth: 3,
      sampleRate: 3000,
      normalize: true,
      peaks: clipStore.audioPeaks,
      cursorWidth: 4,
      url: clipStore.mp4Url,
      media: videoElement.value,
    })
    waveform.value.on('decode', () => {
      loadingDone.value = true
      if (clipStore.audioData === null) {
        clipStore.audioPeaks = waveform.value?.exportPeaks()
        clipStore.audioData = waveform.value?.getDecodedData() || null
      }
    })
    waveform.value.on('loading', (progress: number) => {
      loadingProcess.value = progress
    })
  })

  return {
    waveform,
    loadingProcess,
    loadingDone,
  }
}

function calculateRMS(buffer: Float32Array): number {
  const n = buffer.length
  let square = 0

  // Calculate square.
  for (let i = 0; i < n; i++) {
    square += Math.pow(buffer[i], 2)
  }

  // Calculate Mean.
  const mean = square / n

  // Calculate Root.
  return Math.sqrt(mean)
}

export function extractRegionsByRMS2(
  audioBuffer: AudioBuffer,
  frameLengthInSeconds: number,
  stepSizeInSeconds: number,
  threshold: number,
  padding: number,
  minSilenceDuration: number
) {
  const sampleRate = audioBuffer.sampleRate
  const frameLengthInSamples = Math.round(sampleRate * frameLengthInSeconds)
  const stepSizeInSamples = Math.round(sampleRate * stepSizeInSeconds)
  const totalSamples = audioBuffer.length

  const slices: {
    start: number
    end: number
  }[] = []
  let currentPosition = 0

  let lastSlice = null

  while (currentPosition + frameLengthInSamples <= totalSamples) {
    const bufferSlice = audioBuffer.getChannelData(0).subarray(currentPosition, currentPosition + frameLengthInSamples)

    const rms = calculateRMS(bufferSlice)

    const remove = rms < threshold

    if (remove) {
      const startTime = Math.round(currentPosition / sampleRate + padding)
      const endTime = Math.round((currentPosition + frameLengthInSamples) / sampleRate - padding)
      if (lastSlice === null) {
        lastSlice = {
          start: startTime,
          end: endTime,
        }
      }
    } else {
      if (lastSlice !== null) {
        lastSlice.end = Math.round((currentPosition + frameLengthInSamples) / sampleRate - padding)
        if (lastSlice.end - lastSlice.start > minSilenceDuration) {
          slices.push(lastSlice)
        }
        lastSlice = null
      }
    }

    currentPosition += stepSizeInSamples
  }

  // check if we have a last slice
  if (lastSlice !== null) {
    lastSlice.end = Math.round((currentPosition + frameLengthInSamples) / sampleRate - padding)
    if (lastSlice.end - lastSlice.start > minSilenceDuration) {
      slices.push(lastSlice)
    }
    lastSlice = null
  }

  return slices
}
