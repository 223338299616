const invalidCharsRegex = /[^a-z0-9-]/g;
const maxAliasLength = 28

export function guessBranchPreviewUrl() {
  const branch = 'VITE_BRANCH_NAME' in import.meta.env ? import.meta.env.VITE_BRANCH_NAME : ''
  const alias = generateBranchAlias(branch)

  if (alias) {
    return `https://${alias}.streamladder-frontend.pages.dev/`
  } else {
    return null
  }
}

// C/O https://community.cloudflare.com/t/algorithm-to-generate-a-preview-dns-subdomain-from-a-branch-name/477633

function generateBranchAlias(branch: string) {

  const normalised = trim(branch.toLowerCase().replace(invalidCharsRegex, '-'), '-');

  if (normalised.length > maxAliasLength) {
    return normalised.substring(0, maxAliasLength);
  }

  return normalised;
}

function trim(str: string, char: string): string {
  while (str.charAt(0) === char) {
    if (str.length === 1) {
      return "";
    }
    str = str.substring(1);
  }

  while (str.charAt(str.length - 1) === char) {
    if (str.length === 1) {
      return "";
    }
    str = str.substring(0, str.length - 1);
  }

  return str;
}
