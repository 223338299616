<script setup lang="ts">
import GoogleIcon from '@/components/Icons/GoogleIcon.vue'
import TwitchIcon from '@/components/Icons/SocialMedia/TwitchIcon.vue'
import useLogin from '@/Hooks/useLogin'

const emit = defineEmits<{
  (name: 'close'): void
}>()

const { login } = useLogin()
</script>

<template>
  <div class="absolute inset-0 flex items-center justify-center backdrop-blur-sm" @click="emit('close')">
    <article class="layer-2 z-10 flex max-w-md cursor-default flex-col gap-8 rounded-xl p-12 shadow-[0_0_16px_0_#0004]" @click.stop>
      <header class="flex flex-col items-center gap-2 text-center">
        <h4 class="text-light m-0 p-0 text-xl lg:text-2xl">Login in to display your clips!</h4>
        <p class="font-light opacity-75">Access and edit your personal clip collection</p>
      </header>

      <div class="flex flex-col gap-4">
        <button
          class="flex items-center justify-center gap-2 rounded-xl px-6 py-4 bg-white text-black shadow-[0_6px_20px_0_#0002] outline outline-2 outline-offset-2 outline-transparent transition-all hover:shadow-[0_6px_20px_0_#0004] focus:shadow-[0_6px_20px_0_#0003] focus:outline-blue-600 active:scale-90"
          @click="login('google')"
        >
          <GoogleIcon class="h-6 w-6" />
          Login with Google
        </button>
        <button
          class="flex items-center justify-center gap-2 rounded-xl bg-[#9147FF] px-6 py-4 text-white shadow-[0_6px_20px_0_#9147FF5a] outline outline-2 outline-offset-2 outline-transparent transition-all hover:shadow-[0_6px_20px_0_#9147FFaf] focus:shadow-[0_6px_20px_0_#9147FFaf] focus:outline-blue-600 active:scale-90"
          @click="login('twitch')"
        >
          <TwitchIcon class="h-6 w-6 text-white" />
          Login with Twitch
        </button>
      </div>
    </article>
  </div>
</template>

<style scoped lang="scss"></style>
