<script lang="ts" setup>
import { IconExclamationCircle } from '@tabler/icons-vue'
import { type PostDto, OverallPostStatus } from '@/apis/streamladder-publisher/model'
import { computed } from 'vue'
import YoutubeIcon from '@/components/Icons/SocialMedia/YoutubeIcon.vue'
import InstagramIcon from '@/components/Icons/SocialMedia/InstagramIcon.vue'
import TikTokIcon from '@/components/Icons/SocialMedia/TikTokIcon.vue'

const emit = defineEmits<{ (event: 'click', id: string | null): void }>()
const props = defineProps<{ post: PostDto | undefined }>()
const skeleton = computed(() => !props.post)

const title = computed(() => {
  const targets = props.post?.targets
  if (!targets) return null

  const title = targets.find((t) => t.title !== null)?.title
  if (title) return title

  const description = targets.find((t) => t.description !== null)?.description
  if (description) return description

  return null
})

const date = computed(() => {
  const date = props.post?.scheduledAt
  if (date)
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    })

  return null
})

const status = computed<(typeof OverallPostStatus)[keyof typeof OverallPostStatus]>(() => {
  return props.post?.status?.overallStatus ?? 'Scheduled'
})

const statusLabel = computed(() => {
  return {
    [OverallPostStatus.New]: 'New',
    [OverallPostStatus.PartialFailure]: 'Failed',
    [OverallPostStatus.Failed]: 'Failed',
    [OverallPostStatus.Published]: 'Published',
    [OverallPostStatus.Publishing]: 'Publishing',
    [OverallPostStatus.Scheduled]: 'Scheduled',
  }[status.value]
})

function manage() {
  if (props.post) {
    emit('click', props.post.id ?? null)
  }
}
</script>

<template>
  <article
    :class="{ 'skeleton overflow-hidden': skeleton }"
    class="layer-2 flex flex-wrap items-center justify-between gap-2 rounded-xl p-6 group cursor-pointer transition-all hover:scale-[1.015] hover:shadow-xl"
    @click="manage"
  >
    <div class="flex flex-col gap-1 max-md:w-full lg:w-1/3 lg:grow-0">
      <h3 :class="{ 'w-full rounded layer-1': skeleton }" class="p-0 text-xl font-semibold line-clamp-1 overflow-ellipsis">
        <template v-if="!skeleton">{{ title ?? 'Untitled StreamLadder Project' }}</template>
      </h3>
      <p
        :class="{ 'min-h-[1em] w-2/3 rounded layer-1': skeleton }"
        class="text-sm font-light text-opacity-50"
      >
        <template v-if="!skeleton">{{ date }}</template>
      </p>
    </div>

    <span
      v-if="!skeleton"
      :class="{
        'bg-rose-400 text-white': status === 'Failed' || status === 'PartialFailure',
        'bg-emerald-500 text-white': status === 'Published',
        'bg-zinc-400 text-white': ['Processing', 'Queued', 'New'].includes(status),
        'bg-indigo-500 text-white': status === 'Scheduled' || status === 'Publishing',
      }"
      class="flex h-8 items-center gap-2 rounded px-3 py-1 font-semibold leading-none"
    >
      <IconExclamationCircle v-if="status === 'Failed'" :size="20" />
      {{ statusLabel }}
    </span>
    <span v-else>
      <span class="h-8 w-16 rounded bg-current opacity-50" />
    </span>
    <ol v-if="post && post.targets && post.targets.length > 0" class="flex flex-row justify-center gap-4">
      <li v-for="target in post.targets">
        <TikTokIcon
          v-if="target.socialMedia === 'Tiktok'"
          class="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-black p-2 text-white"
        />
        <YoutubeIcon
          v-else-if="target.socialMedia === 'Youtube'"
          class="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-black p-2 text-white"
        />
        <InstagramIcon
          v-else-if="target.socialMedia === 'Instagram'"
          class="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-black p-2 text-white"
        />
      </li>
    </ol>
    <div class="flex justify-end">
      <button
        class="flex items-center justify-center rounded-full bg-indigo-50 px-3 py-2 text-sm font-bold text-indigo-600 transition-all group-hover:bg-indigo-500 group-hover:text-indigo-50 group-active:scale-90"
      >
        View Clip
      </button>
    </div>
  </article>
</template>

<style lang="scss" scoped></style>
