<template>
  <svg
    width="25"
    height="28"
    viewBox="0 0 25 28"
    class="fill-current stroke-current"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.98 16.25C19.26 16.25 19.5 16.01 19.48 15.73C19.21 12.1 16.19 9.25 12.5 9.25C8.81003 9.25 5.79003 12.1 5.52003 15.73C5.50003 16.01 5.74003 16.25 6.02003 16.25H18.98Z"
    />
    <path
      d="M22.5 17H22.42C21.87 17 21.42 16.55 21.42 16C21.42 15.45 21.87 15 22.42 15C22.97 15 23.46 15.45 23.46 16C23.46 16.55 23.05 17 22.5 17ZM2.58 17H2.5C1.95 17 1.5 16.55 1.5 16C1.5 15.45 1.95 15 2.5 15C3.05 15 3.54 15.45 3.54 16C3.54 16.55 3.13 17 2.58 17ZM19.51 9.99C19.25 9.99 19 9.89 18.8 9.7C18.41 9.31 18.41 8.68 18.8 8.29L18.93 8.16C19.32 7.77 19.95 7.77 20.34 8.16C20.73 8.55 20.73 9.18 20.34 9.57L20.21 9.7C20.02 9.89 19.77 9.99 19.51 9.99ZM5.49 9.99C5.23 9.99 4.98 9.89 4.78 9.7L4.65 9.56C4.26 9.17 4.26 8.54 4.65 8.15C5.04 7.76 5.67 7.76 6.06 8.15L6.19 8.28C6.58 8.67 6.58 9.3 6.19 9.69C6 9.89 5.74 9.99 5.49 9.99ZM12.5 7.04C11.95 7.04 11.5 6.63 11.5 6.08V6C11.5 5.45 11.95 5 12.5 5C13.05 5 13.5 5.45 13.5 6C13.5 6.55 13.05 7.04 12.5 7.04Z"
    />
    <path
      d="M20.5 19.75H4.5C4.09 19.75 3.75 19.41 3.75 19C3.75 18.59 4.09 18.25 4.5 18.25H20.5C20.91 18.25 21.25 18.59 21.25 19C21.25 19.41 20.91 19.75 20.5 19.75Z"
    />
    <path
      d="M18.5 22.75H6.5C6.09 22.75 5.75 22.41 5.75 22C5.75 21.59 6.09 21.25 6.5 21.25H18.5C18.91 21.25 19.25 21.59 19.25 22C19.25 22.41 18.91 22.75 18.5 22.75Z"
    />
    <path
      d="M15.5 25.75H9.5C9.09 25.75 8.75 25.41 8.75 25C8.75 24.59 9.09 24.25 9.5 24.25H15.5C15.91 24.25 16.25 24.59 16.25 25C16.25 25.41 15.91 25.75 15.5 25.75Z"
    />
    <path
      d="M14.5234 3.19L12.5 1.16667L10.4767 3.19"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5 6.83333V1.22333"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
