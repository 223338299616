import { useRouter } from 'vue-router'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import * as Sentry from '@sentry/browser'

export const useExternalApiErrorCodes = () => {
  const router = useRouter()
  const referrer = router.currentRoute.value.name
  const supportUrl = router.resolve({ name: dashboardRouteNames.support, query: { referrer } }).href

  const apiErrorMessages = {
    spam_risk_too_many_posts: {
      title: 'Daily post limit reached',
      description: 'TikTok allows only a certain amount of posts each day.',
      link: {
        text: 'For more details, check our User Guide.',
        href: 'https://streamladder.com/user-guide/tiktok-post-limit-reached',
      },
    },
    spam_risk_too_many_pending_share: {
      title: 'Daily draft limit reached',
      description: 'TikTok allows only 5 clips to be sent as drafts each day.',
      link: {
        text: 'For more details, check our User Guide.',
        href: 'https://streamladder.com/user-guide/tiktok-post-limit-reached',
      },
    },
    spam_risk_user_banned_from_posting: {
      title: 'Posting restricted',
      description:
        "Your posting privileges are temporarily restricted. Please review TikTok's community guidelines to ensure compliance.",
      link: {
        text: 'Check out TikTok support for more information.',
        href: 'https://support.tiktok.com/',
      },
    },
    access_token_invalid: {
      title: 'Reconnect your TikTok account',
      description: 'Your login session has expired. Please reconnect your TikTok account and try again.',
      link: {
        text: 'Reconnect TikTok account',
        href: router.resolve({ name: dashboardRouteNames.socials }).href,
      },
    },
    scope_not_authorized: {
      title: 'Reconnect your TikTok account',
      description: "Reconnect your TikTok account and ensure you've granted permission for video publishing.",
      link: {
        text: 'Reconnect TikTok account',
        href: router.resolve({ name: dashboardRouteNames.socials }).href,
      },
    },
    rate_limit_exceeded: {
      title: 'Posting speed limit reached',
      description: "You're posting too fast. Please wait a while and post again.",
    },
    privacy_level_option_mismatch: {
      title: 'Privacy settings error',
      description:
        "Your chosen posting or scheduling options don't match your TikTok settings. Please review your TikTok settings inside the app.",
      link: {
        text: 'Contact support',
        href: supportUrl,
      },
    },
    duration_check: {
      title: 'Video Too Long or Too Short for TikTok',
      description:
        "TikTok allows videos up to 10-minutes depending on your account. Trim your video to 10 minutes or below and try it again.",
    },
    internal_error: {
      title: 'Internal error 👽',
      description:
        "We're experiencing issues connecting to TikTok or YouTube. The problem seems to be on their end. Please check back later.",
    },
    token_expired: {
      title: 'Reconnect your account',
      description: 'Access to your account has expired. Please reconnect your account and try again.',
      link: {
        text: 'Reconnect your account',
        href: router.resolve({ name: dashboardRouteNames.socials }).href,
      },
    },
    insufficient_scopes: {
      title: 'Reconnect your account',
      description: "Reconnect your account and ensure you've granted permission for video publishing.",
      link: {
        text: 'Reconnect your account',
        href: router.resolve({ name: dashboardRouteNames.socials }).href,
      },
    },
    youtubeSignupRequired: {
      title: 'YouTube channel not found',
      description: "YouTube could not find a channel connected to this Google account. You may need to create a channel and try again.",
        link: {
          text: 'Create a YouTube channel',
          href: 'https://youtube.com/create_channel',
        },
    },
    uploadLimitExceeded: {
      title: 'Upload limit reached',
      description: "YouTube limits the number of videos you can post each day based on your region, channel age, and copyright strikes. You can try again after 24 hours.",
    },
    quotaExceeded: {
      title: 'YouTube is experiencing issues 🗿',
      description: 'We\'re aware of this and working on it. Please try again in a few minutes or contact support.',
    },
    unknown_youtube_api_error: {
      title: 'Reconnect your account',
      description: 'Please try reconnecting your account in a few minutes or contact support.',
      link: {
        text: 'Reconnect',
        href: router.resolve({ name: dashboardRouteNames.socials }).href,
      },
    },
  } satisfies Record<string, ApiErrorMessage>;

  return (errorCode: string | null | undefined): ApiErrorMessage | null => {

    if (!errorCode) {
      return null;
    }

    const message = errorCode ? apiErrorMessages[errorCode as keyof typeof apiErrorMessages] : null;

    if (errorCode === 'quotaExceeded') {
      Sentry.captureException(new Error('YouTube quota exceeded'));
    } else if (errorCode === 'unknown_youtube_api_error') {
      Sentry.captureException(new Error('Unknown YouTube API error, please consult backend'));
    }

    if (!message) {

      Sentry.captureException(new Error(`Unknown or unhandled API error code: ${errorCode}`));

      return {
        title: 'Something went wrong.',
        description: 'An unknown error occurred.',
        link: {
          text: 'Please contact support.',
          href: supportUrl,
        },
      };
    } else {
      return message;
    }
  }
}

export const reconnectionRequiredErrorCodes = [
  'unknown_youtube_api_error',
  'token_expired',
  'access_token_invalid',
  'scope_not_authorized',
  'insufficient_scopes'
];

export type ApiErrorMessage = {
  title: string
  description: string
  link?: {
    text: string
    href: string
  }
}