<template>
  <div class="flex w-full flex-col items-center gap-4">
    <!-- Success on the downgrade -->
    <div class="mb-6 mt-12 flex flex-col items-center space-y-1 text-center md:flex-row md:text-left">
      <div class="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-green-600 text-white">
        <check-mark-icon class="h-3 w-3 fill-current" />
      </div>
      <p class="my-0 ml-2 font-normal">Success! You have Downgraded to Silver Subscription Plan</p>
    </div>

    <!-- From Gold to Silver -->
    <div
      class="relative mb-8 flex w-full flex-col items-center rounded bg-white px-8 pt-8 text-center lg:px-16 lg:pt-16"
    >
      <lottie-animation url="/lottie/thankyou.json" class="w-1/5 self-center" />

      <h2 class="text-3xl font-bold">From Gold to Silver: your bank account thanks you</h2>
      <p class="mb-8 max-w-screen-md font-thin text-[#4D287F]">
        You’ll be able to use {{ userInfoStore.plan.ucfirst() }} until {{ userInfoStore.plan.ucfirst() }}. If you change
        your mind you can upgrade anytime: Regain full access to premium features with ease.
      </p>

      <div class="my-4 flex self-center">
        <router-link
          :to="{ name: 'Home' }"
          class="w-full rounded bg-company-primary-300 px-8 py-2 text-white hover:bg-company-primary-500"
        >
          <p class="mb-0">Back to homepage</p>
        </router-link>
      </div>
    </div>

    <!-- Socials -->
    <div class="mb-8 flex max-w-screen-md flex-col px-8 text-center lg:px-16">
      <p class="mb-0 text-2xl font-thin">
        In the future, would you be interested in keeping up to date with StreamLadder new features?
      </p>
      <p class="text-sm font-thin">
        We’re constantly working on improving StreamLadder and will launch new features pretty soon.
      </p>
    </div>
  </div>
</template>
<script>
import CheckMarkIcon from '../../Icons/CheckMarkIcon.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { format } from 'date-fns'
import LottieAnimation from '../../LottieAnimation.vue'

export default {
  components: { LottieAnimation, CheckMarkIcon },
  props: {
    subscriptionInfo: { type: Object, required: true },
  },
  setup() {
    const userInfoStore = useUserInfoStore()
    return { userInfoStore }
  },
  computed: {
    endOfSubscriptionDate() {
      return format(Date.parse(this.subscriptionInfo?.nextPaymentDateString), 'MMM dd, yyyy')
    },
  },
  methods: {
    async redirectHome() {
      await this.$router.push('/')
    },
  },
}
</script>
