<template>
  <Card>
    <CardHeader class="cursor-pointer flex-row gap-2 p-8" @click="open = !open">
      <div class="flex aspect-square h-12 w-12 items-center justify-center rounded bg-zinc-200 text-special-tiktok">
        <TikTokIcon class="h-5 w-5 fill-special-tiktok" />
      </div>
      <div class="flex flex-col">
        <CardTitle>TikTok settings</CardTitle>
        <CardDescription>Decide how your TikTok post should look.</CardDescription>
      </div>
      <div class="flex-grow" />
      <Button variant="primary" size="circle" class="h-6 w-6 self-center p-1">
        <IconSaxArrowDown2
          class="transform transition-all"
          :class="{
            'rotate-180': open,
          }"
        />
      </Button>
    </CardHeader>
    <CardContent v-show="open" class="flex flex-col gap-2">
      <div class="group relative flex w-full flex-col justify-between gap-2 sm:min-h-fit">
        <span class="label-text font-bold text-foreground">Who can view this video</span>

        <Select v-model="_privacyLevel">
          <SelectTrigger class="w-full">
            <SelectValue placeholder="Select an option" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem v-for="label of availableOptions.privacyLevels" :key="label" :value="label" class="cursor-pointer">
                {{ toLabel(label) }}
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>

      <template v-if="availableOptions.comments || availableOptions.duets || availableOptions.stitch">
        <hr class="my-2" />

        <span class="label-text font-bold text-foreground">Allow users to</span>
        <div class="group relative flex flex-col gap-2 sm:min-h-fit sm:flex-row sm:text-left">
          <div class="form-control" v-if="availableOptions.comments">
            <label class="label cursor-pointer justify-start">
              <input v-model="_allowComments" class="checkbox rounded-sm h-5 w-5 mr-2 dark:border-white" type="checkbox" />
              <span class="label-text text-foreground mt-0.5">Comment</span>
            </label>
          </div>

          <div class="form-control" v-if="availableOptions.duets">
            <label class="label cursor-pointer justify-start">
              <input v-model="_allowDuet" class="checkbox rounded-sm mr-2 h-5 w-5 dark:border-white" type="checkbox" />
              <span class="label-text text-foreground mt-0.5">Duet</span>
            </label>
          </div>

          <div class="form-control" v-if="availableOptions.stitch">
            <label class="label cursor-pointer justify-start">
              <input v-model="_allowStitch" class="checkbox rounded-sm mr-2 h-5 w-5 dark:border-white" type="checkbox" />
              <span class="label-text text-foreground mt-0.5">Stitch</span>
            </label>
          </div>
        </div>
      </template>

      <hr class="my-2" />
      <div class="flex w-full flex-row items-center justify-between gap-8">
        <div class="flex flex-col">
          <span class="label-text mt-1 mb-1 font-bold text-foreground">Disclose post content</span>
          <span class="mb-1 font-light text-xs">Let others know this post promotes a brand, product or service.</span>
        </div>
        <input v-model="_disclosePostContent" type="checkbox" class="toggle-primary toggle" />
      </div>
      <div v-if="_disclosePostContent" class="">
        <div class="form-control">
          <label class="label cursor-pointer justify-start">
            <input v-model="_brandOrganicToggle" class="checkbox rounded-sm mr-2 h-5 w-5 dark:border-white" type="checkbox" />
            <span class="label-text text-foreground translate-y-[1px]">Your brand</span>
          </label>
          <span class="mb-2 font-light text-xs"> You are promoting yourself or your own business. </span>
        </div>

        <div class="form-control">
          <label class="label cursor-pointer justify-start">
            <input v-model="_brandContentToggle" class="checkbox rounded-sm mr-2 h-5 w-5 dark:border-white" type="checkbox" />
            <span class="label-text text-foreground translate-y-[1px]">Branded content</span>
          </label>
          <span class="mb-2 font-light text-xs max-w-xl">
            You are in a paid partnership with a brand. After posting the video, open your TikTok mobile app and link
            the campaign under the video’s “Ad settings”.
          </span>
        </div>
      </div>

      <hr class="my-2" />
      <span class="label-text font-bold text-foreground">Publishing options</span>
      <LightSwitch
        :options="tikTokPublishingOptions"
        class="text-sm"
        :value="tikTokPublishingOption"
        @update:value="(val: TikTokPublishMode) => tikTokPublishingOption = val"
        labelClasses=""
      />
    </CardContent>
    <CardFooter v-show="open" />
  </Card>
</template>
<script lang="ts" setup>
import { useVModel, watchImmediate } from '@vueuse/core'
import TikTokIcon from '@/components/Icons/SocialMedia/TikTokIcon.vue'
import LightSwitch from '@/components-v2/data-input/boolean/LightSwitch.vue'
import type { LightSwitchOption } from '@/components-v2/data-input/boolean/LightSwitch.vue'
import type { PrivacyLevel } from '@/apis/streamladder-publisher/model'
import { computed, ref, watch } from 'vue'
import connectionTypes from '@/enums/connectionTypes'
import { useAvailableTiktokOptionsToUser } from '@/Hooks/useAvailableTiktokOptionsToUser'
import type { OutboundSocialAccount } from '@/store/user/userInfo'

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { Button } from '@/components/ui/button'
import YoutubeIcon from '@/components/Icons/SocialMedia/YoutubeIcon.vue'
import { Card, CardContent, CardDescription, CardHeader, CardTitle, CardFooter } from '@/components/ui/card'
import IconSaxArrowDown2 from '@/components/Icons/iconsax/IconSaxArrowDown2.vue'

const props = defineProps<{
  accounts: OutboundSocialAccount
  privacyLevel: PrivacyLevel
  allowComments: boolean
  allowDuet: boolean
  allowStitch: boolean
  disclosePostContent: boolean
  brandOrganicToggle: boolean
  brandContentToggle: boolean
  isDraft: boolean
  errors?: Record<string, { code: string; message: string }[]>
}>()
const emit = defineEmits([
  'update:privacyLevel',
  'update:allowComments',
  'update:allowDuet',
  'update:allowStitch',
  'update:disclosePostContent',
  'update:brandOrganicToggle',
  'update:brandContentToggle',
  'update:isDraft',
])

const _privacyLevel = useVModel(props, 'privacyLevel', emit)
const _allowComments = useVModel(props, 'allowComments', emit)
const _allowDuet = useVModel(props, 'allowDuet', emit)
const _allowStitch = useVModel(props, 'allowStitch', emit)
const _disclosePostContent = useVModel(props, 'disclosePostContent', emit)
const _brandOrganicToggle = useVModel(props, 'brandOrganicToggle', emit)
const _brandContentToggle = useVModel(props, 'brandContentToggle', emit)
const _isDraft = useVModel(props, 'isDraft', emit)

const accounts = computed(() => props.accounts)

const open = ref(false)

function toLabel(enumValue: PrivacyLevel) {
  const labels = {
    PUBLIC_TO_EVERYONE: 'Everyone',
    FOLLOWER_OF_CREATOR: 'Only your followers',
    MUTUAL_FOLLOW_FRIENDS: 'Mutual follows & friends',
    SELF_ONLY: 'Only yourself',
  } as const satisfies Record<PrivacyLevel, string>
  return labels[enumValue]
}

const allowTikTokDirectPublish = computed(() => {
  return !!accounts.value.find(
    (account) => account.type === connectionTypes.TIKTOK && account.scopes.includes('video.publish')
  )
})
type TikTokPublishMode = 'directly' | 'notification'

const tikTokPublishingOption = computed({
  get: () => (_isDraft.value ? 'notification' : 'directly'),
  set: (val: TikTokPublishMode) => {
    _isDraft.value = !(val === 'directly')
  },
})

const tikTokPublishingOptions: LightSwitchOption<TikTokPublishMode>[] = [
  {
    label: 'Auto publish',
    value: 'directly',
    tooltip: allowTikTokDirectPublish.value
      ? "This will directly post it towards your TikTok."
      : "You didn't give us permission to publish directly to TikTok. Reconnect your account to enable this option.",
    disabled: !allowTikTokDirectPublish.value,
  },
  {
    label: 'Send as draft',
    value: 'notification',
    tooltip:
      "This allows you to make final edits within the TikTok app before publishing.",
  },
]

const selectedTiktokAccountIds = computed(() =>
  accounts.value.filter((a) => a.type === connectionTypes.TIKTOK).map((a) => a.id)
)
const availableOptions = useAvailableTiktokOptionsToUser(selectedTiktokAccountIds)

watch(availableOptions, () => {
  if (!availableOptions.value.privacyLevels.includes(_privacyLevel.value)) {
    _privacyLevel.value = availableOptions.value.privacyLevels[0] as PrivacyLevel
  }
});
</script>
