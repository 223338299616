<script setup lang="ts">
import { Button } from "@/components/ui/button";
import { ref, watch } from 'vue'
import type { MultipleSurveyQuestion } from 'posthog-js'

const props = defineProps<{
  question: MultipleSurveyQuestion
}>();

const emit = defineEmits<{
  (event: 'answered', value: string[] | null): void
  (event: 'invalidAnswer'): void
}>();

watch(() => props.question, () => {
  selectedChoiceAnswers.value = null;
  emit('answered', null);
  emit('invalidAnswer');
});

const selectedChoiceAnswers = ref<string[] | null>(null);

const clickedChoice = (answer: string) => {

  if (selectedChoiceAnswers.value && selectedChoiceAnswers.value.includes(answer)) {
    selectedChoiceAnswers.value = selectedChoiceAnswers.value.filter((i) => i !== answer);
  } else {
    if (selectedChoiceAnswers.value) {
      selectedChoiceAnswers.value = [...selectedChoiceAnswers.value, answer];
    } else {
      selectedChoiceAnswers.value = [answer];
    }
  }

  emit('answered', selectedChoiceAnswers.value);
};
</script>

<template>
  <div class="flex flex-row flex-wrap gap-4 justify-center">
    <Button
      v-for="(choice, index) in question.choices"
      :key="choice + index"
      variant="outline"
      class="font-light"
      :class="{ 'bg-company-primary-50 text-white': selectedChoiceAnswers && selectedChoiceAnswers.includes(choice) }"
      @click="() => clickedChoice(choice)"
    >
      {{ choice }}
    </Button>
  </div>
</template>

<style scoped>

</style>