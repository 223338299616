/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * StreamLadder.Api
 * OpenAPI spec version: 1.0
 */
import { useQuery, useMutation } from '@tanstack/vue-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  QueryKey,
  UseQueryReturnType,
} from '@tanstack/vue-query'
import { unref } from 'vue'
import type { MaybeRef } from '@tanstack/vue-query/build/lib/types'
import type {
  SavedSocialTextFieldApiResponse,
  SavedSocialTextFieldDto,
  SavedSocialTextFieldListApiResponse,
} from '.././model'
import { streamLadderAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (config: any, args: infer P) => any ? P : never

export const postApiSavedSocialTextFields = (
  savedSocialTextFieldDto: MaybeRef<SavedSocialTextFieldDto>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  return streamLadderAxiosInstance<SavedSocialTextFieldApiResponse>(
    {
      url: `/api/SavedSocialTextFields`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: savedSocialTextFieldDto,
    },
    options
  )
}

export const getPostApiSavedSocialTextFieldsMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSavedSocialTextFields>>,
    TError,
    { data: SavedSocialTextFieldDto },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiSavedSocialTextFields>>,
  TError,
  { data: SavedSocialTextFieldDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiSavedSocialTextFields>>,
    { data: SavedSocialTextFieldDto }
  > = (props) => {
    const { data } = props ?? {}

    return postApiSavedSocialTextFields(data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PostApiSavedSocialTextFieldsMutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiSavedSocialTextFields>>
>
export type PostApiSavedSocialTextFieldsMutationBody = SavedSocialTextFieldDto
export type PostApiSavedSocialTextFieldsMutationError = void

export const usePostApiSavedSocialTextFields = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiSavedSocialTextFields>>,
    TError,
    { data: SavedSocialTextFieldDto },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}) => {
  const mutationOptions = getPostApiSavedSocialTextFieldsMutationOptions(options)

  return useMutation(mutationOptions)
}
export const getApiSavedSocialTextFields = (
  options?: SecondParameter<typeof streamLadderAxiosInstance>,
  signal?: AbortSignal
) => {
  return streamLadderAxiosInstance<SavedSocialTextFieldListApiResponse>(
    { url: `/api/SavedSocialTextFields`, method: 'get', signal },
    options
  )
}

export const getGetApiSavedSocialTextFieldsQueryKey = () => ['api', 'SavedSocialTextFields'] as const

export const getGetApiSavedSocialTextFieldsQueryOptions = <
  TData = Awaited<ReturnType<typeof getApiSavedSocialTextFields>>,
  TError = void
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiSavedSocialTextFields>>, TError, TData>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseQueryOptions<Awaited<ReturnType<typeof getApiSavedSocialTextFields>>, TError, TData> => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetApiSavedSocialTextFieldsQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiSavedSocialTextFields>>> = ({ signal }) =>
    getApiSavedSocialTextFields(requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions }
}

export type GetApiSavedSocialTextFieldsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getApiSavedSocialTextFields>>
>
export type GetApiSavedSocialTextFieldsQueryError = void

export const useGetApiSavedSocialTextFields = <
  TData = Awaited<ReturnType<typeof getApiSavedSocialTextFields>>,
  TError = void
>(options?: {
  query?: UseQueryOptions<Awaited<ReturnType<typeof getApiSavedSocialTextFields>>, TError, TData>
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetApiSavedSocialTextFieldsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey as QueryKey

  return query
}

export const putApiSavedSocialTextFieldsId = (
  id: MaybeRef<string>,
  savedSocialTextFieldDto: MaybeRef<SavedSocialTextFieldDto>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  return streamLadderAxiosInstance<SavedSocialTextFieldApiResponse>(
    {
      url: `/api/SavedSocialTextFields/${unref(id)}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: savedSocialTextFieldDto,
    },
    options
  )
}

export const getPutApiSavedSocialTextFieldsIdMutationOptions = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiSavedSocialTextFieldsId>>,
    TError,
    { id: string; data: SavedSocialTextFieldDto },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof putApiSavedSocialTextFieldsId>>,
  TError,
  { id: string; data: SavedSocialTextFieldDto },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putApiSavedSocialTextFieldsId>>,
    { id: string; data: SavedSocialTextFieldDto }
  > = (props) => {
    const { id, data } = props ?? {}

    return putApiSavedSocialTextFieldsId(id, data, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type PutApiSavedSocialTextFieldsIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof putApiSavedSocialTextFieldsId>>
>
export type PutApiSavedSocialTextFieldsIdMutationBody = SavedSocialTextFieldDto
export type PutApiSavedSocialTextFieldsIdMutationError = void

export const usePutApiSavedSocialTextFieldsId = <TError = void, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putApiSavedSocialTextFieldsId>>,
    TError,
    { id: string; data: SavedSocialTextFieldDto },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}) => {
  const mutationOptions = getPutApiSavedSocialTextFieldsIdMutationOptions(options)

  return useMutation(mutationOptions)
}
export const deleteApiSavedSocialTextFieldsId = (
  id: MaybeRef<string>,
  options?: SecondParameter<typeof streamLadderAxiosInstance>
) => {
  return streamLadderAxiosInstance<void>({ url: `/api/SavedSocialTextFields/${unref(id)}`, method: 'delete' }, options)
}

export const getDeleteApiSavedSocialTextFieldsIdMutationOptions = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiSavedSocialTextFieldsId>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteApiSavedSocialTextFieldsId>>,
  TError,
  { id: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteApiSavedSocialTextFieldsId>>, { id: string }> = (
    props
  ) => {
    const { id } = props ?? {}

    return deleteApiSavedSocialTextFieldsId(id, requestOptions)
  }

  return { mutationFn, ...mutationOptions }
}

export type DeleteApiSavedSocialTextFieldsIdMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteApiSavedSocialTextFieldsId>>
>

export type DeleteApiSavedSocialTextFieldsIdMutationError = unknown

export const useDeleteApiSavedSocialTextFieldsId = <TError = unknown, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteApiSavedSocialTextFieldsId>>,
    TError,
    { id: string },
    TContext
  >
  request?: SecondParameter<typeof streamLadderAxiosInstance>
}) => {
  const mutationOptions = getDeleteApiSavedSocialTextFieldsIdMutationOptions(options)

  return useMutation(mutationOptions)
}
