import { onMounted, onUnmounted, ref } from 'vue'
import eventBus from '@/eventBus'
import { onKeyStroke } from '@vueuse/core'

type Payload = Record<string, unknown>

export const useModal = <T>(options: { openEvent?: string; closeEvent?: string }) => {
  const isShowing = ref(false)
  const payload = ref<T>({})

  const open = (_payload: Payload) => {
    document.body.classList.add('overflow-y-hidden')
    payload.value = _payload
    isShowing.value = true
  }

  const close = (_payload: T) => {
    document.body.classList.remove('overflow-y-hidden')
    payload.value = _payload
    isShowing.value = false
  }

  onKeyStroke(['Escape'], () => {
    if (isShowing.value) close()
  })

  onMounted(() => {
    if (options.openEvent) eventBus.$on(options.openEvent, open)
    if (options.closeEvent) eventBus.$on(options.closeEvent, close)
  })

  onUnmounted(() => {
    if (options.openEvent) eventBus.$off(options.openEvent, open)
    if (options.closeEvent) eventBus.$off(options.closeEvent, close)
  })

  return {
    open,
    close,
    payload,
    isShowing,
  }
}
