import { defineStore, acceptHMRUpdate } from 'pinia'

interface SaleState {
  blackFridayDates: number[][]
}

export const useSaleStore = defineStore('sale', {
  state: (): SaleState => {
    return {
      blackFridayDates: [
        [22, 11],
        [23, 11],
        [24, 11],
        [25, 11],
        [26, 11],
        [27, 11],
        [28, 11],
      ],
    }
  },
  getters: {
    isBlackFriday: (state) => {
      return state.blackFridayDates.some((x) => x[0] == new Date().getDate() && x[1] == new Date().getMonth() + 1)
    },
  },
})

// Allows hot-reloading of the store
// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(acceptHMRUpdate(useSaleStore, import.meta.hot))
}
