<script setup lang="ts">
import IconSaxArrowRight from '@/components/Icons/iconsax/IconSaxArrowRight.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { tiers } from '@/enums/tiers'
import {computed} from "vue";
import { Button } from '@/components/ui/button'

const userInfoStore = useUserInfoStore()

const emit = defineEmits<{
  (event: 'openUpgradeDialog'): void
}>()

const showBanner = computed(() => {
  return userInfoStore.isLoggedIn 
    && userInfoStore.hasLoadedUserInfo
    && userInfoStore.tier !== tiers.GOLD 
    && userInfoStore.tier !== tiers.SILVER
})
</script>

<template>
  <div
    v-if="showBanner"
    @click="emit('openUpgradeDialog')"
    class="sticky -mb-[1px] flex w-full items-center justify-center bg-[#ffc22d] p-1.5 cursor-pointer"
  >
    <div class="flex h-full items-center justify-center pl-4 gap-4 text-black/75 leading-[0]">
      <p class="hidden text-sm font-bold sm:block">
        You're using the <span class="capitalize">{{ tiers.toString(userInfoStore.tier) }}</span> Plan of StreamLadder with limited features.
      </p>
      <p class="font-bold text-sm sm:hidden">
        Unlock more features.
      </p>
      <button class="btn-success btn-sm btn h-6 rounded-md border-none bg-white px-2 text-[11px] uppercase text-black/75 drop-shadow-md hover:bg-white/90 md:h-9 md:px-4 md:text-[13px]">
        Upgrade <IconSaxArrowRight class="ml-1 h-4 w-4" />
      </Button>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
